import { handleEnv } from '@vfit/shared/data-access';

export const encryptSimSerialNumber = async (serialNumber: string) => {
  const textAsBuffer = new TextEncoder().encode(serialNumber);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const digest = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return digest;
};

export const getRedirectUrl = (id: string, encryptedSim: string) => {
  // PRE URL
  // const baseUrl = 'https://attivasim-pre.vodafone.it';
  const baseUrl = handleEnv('NEXT_PUBLIC_VR_URL') || '';
  return baseUrl.concat('/ec/', `${id}`, '?', `param=${encryptedSim}&icmp=cbu_web_landingIT`);
};
