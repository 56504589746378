import { useEffect } from 'react';
import { checkIsGoBackSIA, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyFixed.context';

export const useCartAsyncInformation = () => {
  const { isLoadingCart } = useCheckout();

  useEffect(() => {
    if (!isLoadingCart && !checkIsGoBackSIA()) {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      if (shoppingCart?.id) {
        // TODO: Add all APIs FROM CART ID
      }
    }
  }, [isLoadingCart]);

  return null;
};
