import { ButtonSlide, Map } from '@vfit/shared/atoms';
import {
  CMS_CONFIG,
  useCmsConfig,
  ICoverageToolCongifg,
  IGlobalConfigCMS,
  checkIsApp,
  IProduct,
  getRedirectCoverage,
  ALTERNATIVEOFFERIDKEY,
  usePage,
} from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { Player } from '@lottiefiles/react-lottie-player';
import { VfModal } from '@vfit/shared/components';
import { useEffect, useState } from 'react';
import { CoverageErrorType } from '@vfit/shared/models';
import {
  Address,
  AddressDesc,
  MapDescription,
  CoverageToolMapContainer,
  CoverageToolMapContent,
  CoverageToolMapButton,
  TextLoader,
} from './coverageToolMap.style';
import { ICoverageToolMap } from './coverageToolMap.models';
import CoverageToolError from '../CoverageToolError/coverageToolError';
import { checkFwaRedirect } from './coverageToolMap.utils';

const CoverageToolMap = ({
  address,
  onEditAddress,
  onConfirm,
  isLoading,
  lottieFile,
  onCloseError,
  errorType,
  isError,
  product,
}: ICoverageToolMap) => {
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    formattedAddress: string;
    productToRedirect?: IProduct;
  }>({
    isOpen: false,
    formattedAddress: '',
    productToRedirect: product,
  });
  const [productToRedirect, setProductToRedirect] = useState<IProduct>();
  const [isFWARedirect, setIsFWARedirect] = useState(false);
  const { products: allProducts } = usePage();
  const isRedirectFWAEnabled =
    product?.redirectFWA?.alternativeOfferRedirect?.enablePopUp === 'true';
  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;

  const globalConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  const isButtonStickyConfig = globalConfig?.globalconfig?.modals?.enableButtonSticky === 'true';

  const { city, latitude, longitude, postalCode, stateOrProvince, street, streetNumber } = address;

  const onEdit = () => {
    onEditAddress(address);
  };

  const handleCloseModal = () => {
    if (onCloseError) onCloseError();
    setModalConfig({ isOpen: false, formattedAddress: '', productToRedirect: product });
    setIsFWARedirect(false);
  };

  useEffect(() => {
    const dataToDisplay = [street || ' ', streetNumber || '2', '-', city || ' '];
    if (isError) {
      setModalConfig({ isOpen: true, formattedAddress: dataToDisplay.join(' ') });
    }
    if (!isError && isRedirectFWAEnabled) {
      const { fwaRedirect, productRedirect } = checkFwaRedirect(product, allProducts || []);
      if (fwaRedirect && productRedirect !== undefined) {
        setProductToRedirect(productRedirect);
        setIsFWARedirect(true);
        setModalConfig({
          isOpen: true,
          formattedAddress: dataToDisplay.join(' '),
          productToRedirect: productRedirect,
        });
      }
    }
  }, [isError, isLoading]);

  const handleFWARedirect = () => {
    localStorage.setItem('tProd', JSON.stringify(productToRedirect));
    localStorage.setItem(ALTERNATIVEOFFERIDKEY, '1');
    window.location.href = getRedirectCoverage();
  };

  return (
    <>
      <CoverageToolMapContainer>
        <div>
          <Map
            latitude={latitude ? latitude.toString() : ''}
            longitude={longitude ? longitude.toString() : ''}
          />
          <CoverageToolMapContent>
            <MapDescription>
              {isLoading && lottieFile && (
                <Player src={lottieFile} className="player" loop autoplay />
              )}
              {isLoading && !lottieFile && (
                <>
                  <TextLoader />
                  <TextLoader />
                </>
              )}
              {!isLoading && (
                <>
                  <Address>{`${street}, ${streetNumber}`}</Address>
                  <AddressDesc>{`${postalCode} ${city} ${stateOrProvince}`}</AddressDesc>
                </>
              )}
            </MapDescription>
          </CoverageToolMapContent>
        </div>
        <CoverageToolMapButton>
          <ButtonSlide
            label={coverageToolConfig?.coveragetool?.confirmationMap?.buttonEdit || ' '}
            buttonColor="#fff"
            isApp={checkIsApp()}
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            borderColor="#0d0d0d"
            disabled={isLoading}
            onClick={onEdit}
            name="action_edit_address"
          />
          <br />
          <ButtonSlide
            label={coverageToolConfig?.coveragetool?.confirmationMap?.buttonSubmit || ' '}
            onClick={onConfirm}
            disabled={isLoading}
            isApp={checkIsApp()}
            buttonColor="#e60000"
            hoverColor="#bd0000"
            clickColor="#a10000"
            labelColor="#fff"
            name="action_goToCart"
          />
        </CoverageToolMapButton>
      </CoverageToolMapContainer>
      <VfModal isOpen={modalConfig.isOpen} handleClose={handleCloseModal}>
        <CoverageToolError
          onEdit={handleCloseModal}
          onClose={handleCloseModal}
          onRedirectClick={handleFWARedirect}
          coverageErrorType={isFWARedirect ? CoverageErrorType.FWA : errorType || 0}
          formattedAddress={modalConfig.formattedAddress}
          product={product}
          isButtonSticky={isButtonStickyConfig}
          isAlternativeFWA={isRedirectFWAEnabled && isFWARedirect}
        />
      </VfModal>
    </>
  );
};

export default CoverageToolMap;
