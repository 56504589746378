import { useEffect, useState } from 'react';
import {
  ErrorCodes,
  IAPIError,
  ICheckPortInFeasibilityIIResponse,
  useCheckPortInFeasibilityIIFixed,
  useModifyPhoneNumberFixedProduct,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyFixed.context';
import { IPortabilityObject } from '../../checkout.models';

export const usePortabilityFlow = () => {
  const { portability, setPortability, portabilityStatus, setPortabilityStatus } = useCheckout();
  const [modifiedNumber, setModifiedNumber] = useState<string>('');
  const [firstCode, setFirstCode] = useState('');
  const [firstOperator, setFirstOperator] = useState('');
  const [secondCode, setSecondCode] = useState('');
  const [secondOperator, setSecondOperator] = useState('');
  const [operators, setOperators] = useState<string>('');
  const isStartFinalPortIn = portabilityStatus?.isStartFinalPortIn;

  const {
    mutate: mutatePortIn,
    data: checkPortInData,
    isError: isErrorPortIn,
    isLoading: isLoadingPortIn,
    isSuccess: isSuccessPortIn,
    reset: resetPortIn,
  } = useCheckPortInFeasibilityIIFixed();

  const {
    mutate: mutateModify,
    isError: isErrorModify,
    isSuccess: isSuccessModify,
  } = useModifyPhoneNumberFixedProduct();

  const checkIsValidRestart = (portabilityObject: IPortabilityObject): boolean => {
    const checkIsDifferent = (): boolean => {
      const { portabilityMigration } = portabilityObject;
      const isMultipleOperators = portabilityObject?.portabilityOperators === 'double' || false;
      const {
        firstOperator: fOperator,
        firstCode: fCode,
        secondOperator: sOperator,
        secondCode: sCode,
        noCode: noMigCode,
      } = portabilityMigration || {};
      if (noMigCode) {
        if (isMultipleOperators) {
          return firstOperator !== fOperator || secondOperator !== sOperator;
        }
        return firstOperator !== fOperator;
      }
      if (isMultipleOperators) {
        return (
          firstOperator !== fOperator ||
          secondOperator !== sOperator ||
          firstCode !== fCode ||
          secondCode !== sCode
        );
      }
      return firstOperator !== fOperator || firstCode !== fCode;
    };
    return checkIsDifferent();
  };

  useEffect(() => {
    if (
      portabilityStatus?.isStartPortIn &&
      (portability?.portabilityNumber !== modifiedNumber ||
        portability?.portabilityOperators !== operators ||
        checkIsValidRestart(portability))
    ) {
      setPortabilityStatus({
        ...portabilityStatus,
        isStartPortIn: false,
        isLoadingCheck: true,
        isErrorCheck: false,
        isSuccessCheck: false,
      });
      setPortability({
        ...portability,
      });
      mutatePortIn({
        ...portability,
        isMultipleOperator: portability?.portabilityOperators === 'double' || false,
        hasInternetService: portability?.portabilityInfo?.toLowerCase() === 'telint',
      });
    }
  }, [portability, portabilityStatus]);

  useEffect(() => {
    if (isSuccessPortIn && !isLoadingPortIn && !isErrorPortIn) {
      setPortabilityStatus({
        ...portabilityStatus,
        isSuccessCheck: true,
        isLoadingCheck: false,
        isErrorCheck: false,
      });
    }
  }, [isSuccessPortIn]);

  useEffect(() => {
    const dataPortIn = getFromLocalStorageByKey('checkPortInFeasibilityIIQuery');
    if (
      ((isSuccessPortIn && checkPortInData) ||
        (dataPortIn as ICheckPortInFeasibilityIIResponse).resultDescription?.toLowerCase() ===
          'success') &&
      dataPortIn &&
      !isErrorPortIn &&
      (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultDescription?.toLowerCase() !==
        'failure' &&
      !(dataPortIn as unknown as IAPIError)?.error &&
      isStartFinalPortIn &&
      !portabilityStatus?.isLoading &&
      !portabilityStatus?.isError &&
      !portabilityStatus?.isSuccess
    ) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoadingCheck: false,
        isSuccessCheck: true,
        isLoading: true,
        isError: false,
        isSuccess: false,
      });
      mutateModify({
        ...portability,
        isMultipleOperator: portability?.portabilityOperators === 'double' || false,
        hasInternetService: portability?.portabilityInfo?.toLowerCase() === 'telint',
      });
    }
    if ((dataPortIn as unknown as IAPIError)?.error) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoadingCheck: false,
        isSuccessCheck: false,
        isErrorCheck: true,
        errorMessage: (dataPortIn as unknown as IAPIError)?.errorMessage,
      });
      setPortability({
        ...portability,
      });
    }
    if (
      isSuccessPortIn &&
      (!dataPortIn ||
        (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultDescription?.toLowerCase() ===
          'failure')
    ) {
      resetPortIn();
      let errorText = '';
      if (
        (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]?.messageCode ===
        '9000054'
      ) {
        errorText = ErrorCodes.MIGRATION_CODE_ERROR;
      } else if (
        (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]?.messageDescription
      ) {
        errorText = ErrorCodes.NUMBER_ERROR;
      } else {
        errorText = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
      }
      setPortabilityStatus({
        ...portabilityStatus,
        isLoadingCheck: false,
        isSuccessCheck: false,
        isErrorCheck: true,
        errorMessage: errorText,
      });
      setPortability({
        ...portability,
      });
    }
  }, [isLoadingPortIn, isSuccessPortIn, isErrorPortIn, portabilityStatus]);

  useEffect(() => {
    if (isSuccessModify) {
      setModifiedNumber(portability?.portabilityNumber);
      setFirstCode(portability?.portabilityMigration?.firstCode || '');
      setFirstOperator(portability?.portabilityMigration?.firstOperator || '');
      setSecondCode(portability?.portabilityMigration?.secondCode || '');
      setSecondOperator(portability?.portabilityMigration?.secondOperator || '');
      setOperators(portability?.portabilityOperators || '');
      setPortabilityStatus({
        ...portabilityStatus,
        isLoading: false,
        isSuccess: true,
        isError: false,
        errorMessage: '',
      });
      setPortability({
        ...portability,
      });
    }
    if (isErrorModify) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoading: false,
        isSuccess: false,
        isError: true,
        errorMessage: 'modifyPhoneNumberProductQuery ERROR',
      });
      setPortability({
        ...portability,
      });
    }
  }, [isSuccessModify, isErrorModify]);

  return null;
};
