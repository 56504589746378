import CoverageToolAutoCard from './components/CoverageToolAutoCard/coverageToolAutoCard';
import CoverageToolMapCard from './components/CoverageToolMapCard/coverageToolMapCard';
import CoverageToolCheckNumberCard from './components/CoverageToolCheckNumberCard/coverageToolCheckNumberCard';
import CoverageToolOtpCard from './components/CoverageToolOtpCard/coverageToolOtpCard';
import { IStepCard } from '../StepperStateCards/stepperStateCards.models';
import CoverageToolManualCard from './components/CoverageToolManualCard/coverageToolManualCard';
import CoverageToolChooseContact from './components/CoverageToolChooseContact/coverageToolChooseContact';

export interface ICoverageToolFlow {
  DEFAULT: IStepCard[];
  LOCKIN: IStepCard[];
}

export const ID_FLOWS = {
  AUTO: 'auto',
  MANUAL: 'Manual',
  MAP: 'map',
  CHECK_USER: 'checkuser',
  CHOOSE_CONTACT: 'chooseContact',
  OTP: 'otp',
};

export const AUTO_FLOW = {
  AUTO_CARD: [
    {
      title: ID_FLOWS.AUTO,
      component: CoverageToolAutoCard,
    },
  ],
};

export const MANUAL_FLOW = {
  MANUAL_CARD: [
    {
      title: ID_FLOWS.MANUAL,
      component: CoverageToolManualCard,
    },
  ],
};

export const MAP_FLOW = {
  MAP_CARD: [
    {
      title: ID_FLOWS.MAP,
      component: CoverageToolMapCard,
    },
  ],
};

export const LOCKIN_FLOW = {
  LOCKIN_CARDS: [
    {
      title: ID_FLOWS.CHECK_USER,
      component: CoverageToolCheckNumberCard,
    },
    {
      title: ID_FLOWS.CHOOSE_CONTACT,
      component: CoverageToolChooseContact,
    },
    {
      title: ID_FLOWS.OTP,
      component: CoverageToolOtpCard,
    },
  ],
};

export const EMPTY_FLOWS = {
  EMPTY: [
    {
      title: '',
      component: undefined,
    },
    {
      title: '',
      component: undefined,
    },
  ],
};

const COVERAGE_TOOL_FLOW: ICoverageToolFlow = {
  DEFAULT: [...AUTO_FLOW.AUTO_CARD, ...MAP_FLOW.MAP_CARD, ...EMPTY_FLOWS.EMPTY],
  LOCKIN: [
    ...AUTO_FLOW.AUTO_CARD,
    ...MAP_FLOW.MAP_CARD,
    ...LOCKIN_FLOW.LOCKIN_CARDS,
    ...EMPTY_FLOWS.EMPTY,
  ],
};

export { COVERAGE_TOOL_FLOW };
