import {
  useGetDataFromStorage,
  IAPIError,
  ITechInfo,
  organizeTechnology,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { IOfferingServiceabilityResponse } from '@vfit/shared/models';

export const useTechnologyCardsFlow = (): {
  serviceability: IOfferingServiceabilityResponse | IAPIError | undefined;
  tecnology: ITechInfo | undefined;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [tecnology, setTecnology] = useState<ITechInfo>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [serviceability, setServiceability] = useState<
    IOfferingServiceabilityResponse | IAPIError | undefined
  >(undefined);

  /*
   Getting all the query related o check the responses data
   */
  const offeringServiceability =
    useGetDataFromStorage<IOfferingServiceabilityResponse>('offeringServiceability');

  /*
   Setting technology and serviceabili
  */
  useEffect(() => {
    if (offeringServiceability as IOfferingServiceabilityResponse) {
      setIsLoading(false);
      const organizedTechnology = organizeTechnology(
        offeringServiceability as IOfferingServiceabilityResponse
      );
      setTecnology(organizedTechnology);
      setIsSuccess(true);
      setServiceability(offeringServiceability as IOfferingServiceabilityResponse);
    } else if ((offeringServiceability as IAPIError)?.error) {
      setIsLoading(false);
      setIsError(true);
    }
  }, [!!offeringServiceability]);

  return { serviceability, tecnology, isSuccess, isLoading, isError };
};
