import { ICheckPortInFeasibilityIIResponse } from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';

export const alreadyDonePortability = () => {
  const checkPortInFeasibility: ICheckPortInFeasibilityIIResponse = getFromLocalStorageByKey(
    'checkPortInFeasibilityIIQuery'
  );
  return (
    checkPortInFeasibility?.resultDescription?.toLowerCase() === 'success' ||
    (checkPortInFeasibility && Object.keys(checkPortInFeasibility).length === 0)
  );
};
