import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import { LoggerInstance, INextError, nextClient, CustomOptions } from '@vfit/shared/data-access';
import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IMultiPlayOfferingConfigurationResponse } from './multiPlayOfferingConfiguration.models';
import { overrideOperatorsListFixed } from './portabilityNumber.utils';
import { ICMSOperatorsList } from './getPortabilityInfoMobile.models';

/**
 * API service multiPlayOfferingConfiguration to retrieve user consents
 * @param cartItemId
 * @param cartId
 * @param customOptions
 * @returns
 */
export const multiPlayOfferingConfigurationService = async (
  cartItemId: string,
  cartId: string,
  customOptions?: CustomOptions
): Promise<IMultiPlayOfferingConfigurationResponse> =>
  nextClient.get(
    `${API.MULTI_PLAYING_OFFERING_CONFIGURATION}/${cartItemId}?salesChannel=selfService&context=shoppingCart.Id==${cartId}&configurationType=descriptiveCharacteristics&levelOfData=portIn`,
    {
      searchParams: undefined,
      ...(customOptions?.headers && { headers: customOptions.headers }),
      ...(customOptions?.silentLoginHeaders && {
        silentLoginHeaders: customOptions.silentLoginHeaders,
      }),
    }
  );

export const useMultiPlayOfferingConfiguration = (
  CMSOperatorsList?: ICMSOperatorsList,
  customOptions?: CustomOptions
): UseQueryResult<IMultiPlayOfferingConfigurationResponse, INextError> => {
  const queryClient = useQueryClient();
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const associateCustomer = getFromLocalStorageByKey('associateCustomer');
  const cartId = shoppingCart?.id;
  const cartItemId = shoppingCart?.cartItem?.[0]?.id;
  const enabler = Boolean(cartItemId) && Boolean(cartId) && Boolean(associateCustomer);
  return useQuery(
    ['multiPlayOfferingConfiguration'],
    () => multiPlayOfferingConfigurationService(cartItemId as string, cartId as string),
    {
      enabled: customOptions?.enabled !== undefined ? customOptions.enabled : enabler,
      onSuccess: (data: IMultiPlayOfferingConfigurationResponse) => {
        const overridenData = overrideOperatorsListFixed(data, CMSOperatorsList);
        localStorage.setItem('multiPlayOfferingConfiguration', JSON.stringify(overridenData));
        queryClient.setQueryData('multiPlayOfferingConfigurationQuery', overridenData);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - multiPlayOfferingConfiguration: `, error);
        localStorage.setItem(
          `multiPlayOfferingConfiguration`,
          JSON.stringify(errorMock(`multiPlayOfferingConfiguration`, error))
        );
      },
    }
  );
};
