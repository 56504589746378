import Bowser from 'bowser';

export const APP_VERSION = '13.11.0';

const getScreenScale = () => window.devicePixelRatio || 1;

export const retrieveUserAgent = (appId = 'MyVodafoneWeb') => {
  const bowser = Bowser.getParser(navigator.userAgent);
  const os = bowser.getOS();
  const platform = bowser.getPlatform();
  const isTablet = bowser.getPlatformType() === 'tablet';
  return `${appId}/${os.name}/${isTablet ? 't' : 's'}/${
    os.version
  }/${APP_VERSION}/${getScreenScale()}/store/"${platform.vendor}"/"${
    platform?.model || 'desktop'
  }"`;
};
