import * as S from './titleDescription.style';
import { ITitleDescription } from './titleDescription.models';

export const TitleDescription: React.FC<ITitleDescription> = ({ title, description }) => {
  if (!title && !description) return null;
  return (
    <S.Container>
      {title && <S.Title>{title}</S.Title>}
      {description && <S.Description dangerouslySetInnerHTML={{ __html: description }} />}
    </S.Container>
  );
};
