import { useEffect, useState } from 'react';
import {
  errorMock,
  useCampaignAccept,
  useCampaignsInfo,
  useGetShoppingCartSilentUpdate,
  usePage,
} from '@vfit/consumer/data-access';
import { useCheckout } from '../../../iBuyFixed.context';
import { getEligibilityProduct } from './useLockInMMFlow.utils';

/**
 * Lockin Flow
 * campaigns
 * accept
 * getCart
 */
export const useLockInMMFlow = () => {
  const { products: allProducts } = usePage();
  const [acceptOrderId, setAcceptOrderId] = useState<string>('');
  const { product, isStartLockInMMFlow, lockInMMFlow, setLockInMMFlow, setIsStartLockInMMFlow } =
    useCheckout();
  // if the product is lockin, calls do not need to be repeated
  const alreadyFetchedInfo = product?.isLockInMMProduct;
  const {
    data: campaignsData,
    error: campaignsError,
    isLoading: isLoadingCampaigns,
    isSuccess: isSuccessCampaigns,
    isError: isErrorCampaigns,
    refetch: refetchCampaigns,
  } = useCampaignsInfo();
  const {
    data: acceptData,
    error: acceptError,
    isLoading: isLoadingAccept,
    isSuccess: isSuccessAccept,
    isError: isErrorAccept,
    mutate: mutateAccept,
  } = useCampaignAccept();
  const {
    data: getShoppingCartData,
    error: getShoppingCartError,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
    refetch: refetchShoppingCart,
  } = useGetShoppingCartSilentUpdate(acceptOrderId, {
    enabled: false,
    keyDependency: ['getShoppingCartLockIn'],
  });

  useEffect(() => {
    if (isLoadingShoppingCart) {
      setLockInMMFlow({
        ...lockInMMFlow,
        shoppingCart: {
          ...lockInMMFlow.shoppingCart,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorShoppingCart) {
      setLockInMMFlow({
        ...lockInMMFlow,
        shoppingCart: {
          ...lockInMMFlow.shoppingCart,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('shoppingCart', getShoppingCartError),
        },
      });
    } else if (isSuccessShoppingCart) {
      setLockInMMFlow({
        ...lockInMMFlow,
        shoppingCart: {
          ...lockInMMFlow.shoppingCart,
          data: getShoppingCartData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
    }
  }, [isSuccessShoppingCart, isErrorShoppingCart, isLoadingShoppingCart]);

  useEffect(() => {
    if (acceptOrderId) refetchShoppingCart();
  }, [acceptOrderId]);

  useEffect(() => {
    if (isLoadingAccept) {
      setLockInMMFlow({
        ...lockInMMFlow,
        accept: {
          ...lockInMMFlow.accept,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorAccept) {
      setLockInMMFlow({
        ...lockInMMFlow,
        accept: {
          ...lockInMMFlow.accept,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('accept', acceptError),
        },
      });
    } else if (isSuccessAccept) {
      setLockInMMFlow({
        ...lockInMMFlow,
        ...(!alreadyFetchedInfo && {
          shoppingCart: {
            isLoading: true,
            isSuccess: false,
            isError: false,
            data: undefined,
            error: undefined,
          },
        }),
        accept: {
          ...lockInMMFlow.accept,
          data: acceptData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      setAcceptOrderId(acceptData?.orderId || '');
    }
  }, [isSuccessAccept, isErrorAccept, isLoadingAccept]);

  useEffect(() => {
    if (isLoadingCampaigns) {
      setLockInMMFlow({
        ...lockInMMFlow,
        campaigns: {
          ...lockInMMFlow.campaigns,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorCampaigns) {
      setLockInMMFlow({
        ...lockInMMFlow,
        campaigns: {
          ...lockInMMFlow.campaigns,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('campaigns', campaignsError),
        },
      });
    } else if (isSuccessCampaigns && !alreadyFetchedInfo) {
      const { campaignEligibility, lockInProduct } = getEligibilityProduct(
        allProducts || [],
        campaignsData
      );
      const isEligibleOffer =
        campaignEligibility?.eligible && campaignEligibility?.lockInOffer && lockInProduct;
      setLockInMMFlow({
        ...lockInMMFlow,
        ...(isEligibleOffer && {
          accept: {
            isLoading: true,
            data: undefined,
            error: undefined,
            isError: false,
            isSuccess: false,
          },
        }),
        campaigns: {
          ...lockInMMFlow.campaigns,
          data: campaignsData,
          error: undefined,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
        checkEligibility: {
          ...campaignEligibility,
          ...(lockInProduct && {
            lockInProduct: {
              ...lockInProduct,
              isLockInMMProduct: true,
            },
          }),
        },
      });
      if (isEligibleOffer) {
        mutateAccept({
          campaignId: campaignEligibility?.lockInOffer?.id || '',
          sessionId: campaignEligibility?.lockInOffer?.sessionId || '',
        });
      }
    }
  }, [isSuccessCampaigns, isErrorCampaigns, isLoadingCampaigns]);

  useEffect(() => {
    if (isStartLockInMMFlow) {
      setIsStartLockInMMFlow(false);
    }
  }, [isStartLockInMMFlow]);

  useEffect(() => {
    if (isStartLockInMMFlow && !alreadyFetchedInfo) {
      refetchCampaigns();
    }
  }, [isStartLockInMMFlow]);

  return null;
};
