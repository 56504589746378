import {
  handleFirstCharacterOfSentence,
  IDeliveryInformationMobile,
  IOwningIndividual,
} from '@vfit/consumer/data-access';
import { IAddressDataObject, IEditAddressConfig } from '@vfit/shared/models';

export const getDataOrganized = (
  deliveryData: IDeliveryInformationMobile,
  owningIndividual: IOwningIndividual,
  billingAddress: IAddressDataObject,
  shippingAddress: IAddressDataObject,
  editAddressConfig: IEditAddressConfig,
  isEsim?: boolean,
  isEsimFD?: boolean,
  isCourier?: boolean
) => {
  const simInformation = deliveryData?.deliveryinformationm?.selfService?.sim;
  const esimInformation = deliveryData?.deliveryinformationm?.selfService?.eSim;
  const esimFullyDigitalInformation =
    deliveryData?.deliveryinformationm?.selfService?.eSimFullyDigital;
  const courierInformation = deliveryData?.deliveryinformationm?.courrier;

  let topInformation: { topTitle: string; description?: string } = {
    topTitle: '',
    description: '',
  };
  if (isEsim) {
    if (!isEsimFD) {
      topInformation = {
        ...topInformation,
        topTitle: esimInformation?.title || 'I tuoi indirizzi',
        description:
          esimInformation?.description ||
          'Controlla la correttezza dell’indirizzo di spedizione e fatturazione. Ti invieremo per posta le istruzioni necessarie per installare la tua nuova eSIM.',
      };
    } else {
      topInformation = {
        ...topInformation,
        topTitle:
          esimFullyDigitalInformation?.title || esimInformation?.title || 'I tuoi indirizzi',
        description:
          esimFullyDigitalInformation?.description ||
          esimInformation?.description ||
          'Controlla la correttezza dell’indirizzo di spedizione e fatturazione.',
      };
    }
  } else if (isCourier) {
    topInformation = {
      ...topInformation,
      topTitle: courierInformation?.title || simInformation?.title || 'I tuoi indirizzi',
      description:
        courierInformation?.description ||
        simInformation?.description ||
        'Controlla la correttezza dell’indirizzo di spedizione e fatturazione.',
    };
  } else {
    topInformation = {
      ...topInformation,
      topTitle: simInformation?.title || 'I tuoi indirizzi',
      description:
        simInformation?.description ||
        'Controlla la correttezza dell’indirizzo di spedizione e fatturazione.',
    };
  }

  return {
    ...topInformation,
    userInfoTitle: '',
    userData: {
      lastName: owningIndividual?.lastName,
      firstName: owningIndividual?.firstName,
      address: handleFirstCharacterOfSentence(shippingAddress?.street || '').concat(
        ' ',
        shippingAddress?.streetNumber || ''
      ),
      city: handleFirstCharacterOfSentence(shippingAddress?.city || ''),
      zipCode: shippingAddress?.postalCode || '',
      province: shippingAddress?.stateOrProvince || '',
    },
    billingData: {
      title:
        deliveryData?.deliveryinformationm?.addressBilling?.bottomTitle ||
        'Indirizzo di fatturazione',
      matchAddress:
        deliveryData?.deliveryinformationm?.addressBilling?.title ||
        'L’indirizzo di fatturazione corrisponde all’indirizzo di installazione.',
      noMatch:
        deliveryData?.deliveryinformationm?.addressBilling?.description || 'Non corrisponde?',
      labelEdit:
        deliveryData?.deliveryinformationm?.addressBilling?.linkLabelEdit || 'Modifica Indirizzo',
      changeAddress:
        deliveryData?.deliveryinformationm?.addressBilling?.linkLabel ||
        "Cambia l'indirizzo di fatturazione",
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(billingAddress?.street).concat(
          ' ',
          billingAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(billingAddress?.city),
        zipCode: billingAddress?.postalCode || '',
        province: billingAddress?.stateOrProvince || '',
      },
    },
    shippingData: {
      title:
        deliveryData?.deliveryinformationm?.addressShipping?.title || 'Indirizzo di spedizione',
      matchAddress:
        deliveryData?.deliveryinformationm?.addressShipping?.subtitle ||
        "L'indirizzo di spedizione corrisponde all'indirizzo di installazione.",
      noMatch: deliveryData?.deliveryinformationm?.addressShipping?.address || 'Non corrisponde?',
      labelEdit:
        deliveryData?.deliveryinformationm?.addressShipping?.linkLabelEdit || 'Modifica Indirizzo',
      changeAddress:
        deliveryData?.deliveryinformationm?.addressShipping?.linkLabel ||
        "Cambia l'indirizzo di spedizione",
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(shippingAddress?.street || '').concat(
          ' ',
          shippingAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(shippingAddress?.city),
        zipCode: shippingAddress?.postalCode || '',
        province: shippingAddress?.stateOrProvince || '',
        note: shippingAddress?.note || '',
        postalOfficeDescription: shippingAddress?.postalOfficeDescription || '',
      },
    },
    billingDataEsim: {
      title:
        deliveryData?.deliveryinformationm?.addressBillingEsim?.bottomTitle ||
        'Indirizzo di fatturazione Esim',
      matchAddress:
        deliveryData?.deliveryinformationm?.addressBillingEsim?.title ||
        'L’indirizzo di fatturazione corrisponde all’indirizzo di installazione ESIM.',
      noMatch:
        deliveryData?.deliveryinformationm?.addressBillingEsim?.description ||
        'Non corrisponde ESIM?',
      labelEdit:
        deliveryData?.deliveryinformationm?.addressBillingEsim?.linkLabelEdit ||
        'Modifica Indirizzo ESIM',
      changeAddress:
        deliveryData?.deliveryinformationm?.addressBillingEsim?.linkLabel ||
        'Modifica Indirizzo ESIM',
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(billingAddress?.street).concat(
          ' ',
          billingAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(billingAddress?.city),
        zipCode: billingAddress?.postalCode || '',
        province: billingAddress?.stateOrProvince || '',
      },
    },
    shippingDataEsim: {
      title:
        deliveryData?.deliveryinformationm?.addressShippingEsim?.title ||
        'Indirizzo di spedizione ESIM',
      matchAddress:
        deliveryData?.deliveryinformationm?.addressShippingEsim?.subtitle ||
        "L'indirizzo di spedizione corrisponde all'indirizzo di fatturazione ESIM.",
      noMatch:
        deliveryData?.deliveryinformationm?.addressShippingEsim?.address || 'Non corrisponde ESIM?',
      labelEdit:
        deliveryData?.deliveryinformationm?.addressShippingEsim?.linkLabelEdit ||
        'Modifica Indirizzo ESIM',
      changeAddress:
        deliveryData?.deliveryinformationm?.addressShippingEsim?.linkLabel ||
        "Cambia l'indirizzo di spedizione ESIM",
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(shippingAddress?.street || '').concat(
          ' ',
          shippingAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(shippingAddress?.city),
        zipCode: shippingAddress?.postalCode || '',
        province: shippingAddress?.stateOrProvince || '',
        note: shippingAddress?.note || '',
        postalOfficeDescription: shippingAddress?.postalOfficeDescription || '',
      },
    },
    deliveryCta: '',
    afterEditAddress: '',
    editAddressConfig,
    noteLabels: {
      noteTitle:
        deliveryData?.deliveryinformationm?.commonLabel?.descriptionNote ||
        'Aggiungi note per la consegna',
      noteTitleAfterEdit:
        deliveryData?.deliveryinformationm?.commonLabel?.linkLabelEdit ||
        'Modifica note per la consegna',
    },
    noteModalConfig: {
      title: deliveryData?.deliveryinformationm?.commonLabel?.linkLabel || 'Note di consegna',
      description:
        deliveryData?.deliveryinformationm?.commonLabel?.modalTextLabel ||
        'Inserisci le note per la consegna',
      textLabel:
        deliveryData?.deliveryinformationm?.commonLabel?.placeholderNotes || 'Note per la consegna',
      ctaLabel: deliveryData?.deliveryinformationm?.commonLabel?.modalCtaLabel || 'Salva note',
      titleMod:
        deliveryData?.deliveryinformationm?.commonLabel?.linkLabelEdit ||
        'Modifica note per la consegna',
      descriptionMod:
        deliveryData?.deliveryinformationm?.commonLabel?.linkLabel || 'Note di consegna',
    },
    disableAddNotes: deliveryData?.deliveryinformationm?.disableAddNotes === 'true',
  };
};

