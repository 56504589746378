import { useEffect, useState } from 'react';
import { ICommonData, ISIAFlowType, IUpdateProductPriceType } from '@vfit/shared/models';
import { WarningInfo } from '@vfit/shared/components';
import {
  CMS_CONFIG,
  IPayments,
  useCmsConfig,
  useUPCAutomaticRecharge,
  IS_SELECTED_WALLET,
  IS_AUTOMATIC_RECHARGE_SELECTED,
  IGenericErrorCMS,
  errorMock,
} from '@vfit/consumer/data-access';
import { API, PAGES, openPopup, setTrackLink } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { configCMS } from './warningCard.utils';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';
import { useUpdatePaymentMethodFlow } from '../../hooks/UseUpdatePaymentMethodFlow/useUpdatePaymentMethodFlow';

const WarningCard = ({ handleGoNextSlide, handleGoPrevSlide }: ICommonData) => {
  const [isEnabledFlow, setIsEnabledFlow] = useState(false);
  const {
    paymentsmobile: { warningPriceChange },
  } = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PAYMENTS_MOBILE) as IPayments;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const cms = configCMS(warningPriceChange);
  const taggingMobile = getTagging('info box');
  const {
    mutate: mutateUPC,
    isSuccess: isSuccessUPC,
    isError: isErrorUPC,
    error: errorUpdateProductCharacteristic,
    isLoading: isLoadingUPC,
  } = useUPCAutomaticRecharge();

  const { trackView } = useTracking({
    event: ['checkout_step9.1'],
    event_label: 'credito residuo',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'credito residuo');

  const {
    checkoutErrors,
    availablePaymentMethods,
    product,
    customerFlow,
    isModalButtonSticky,
    setProduct,
    setHideStickyBar,
    setIsDisabledBackButton,
  } = useCheckout();

  const paymentData = customerFlow?.payment?.data;
  const {
    isLoading: isLoadingUpdatePaymentMethod,
    isSuccess: isSuccessUpdatePaymentMethod,
    isError: isErrorUpdatePaymentMethod,
    error: errorUpdatePaymentMethod,
  } = useUpdatePaymentMethodFlow(
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderItemOnBillPayment
      ?.billingOfferIdForChangePaymentMethod || [''],
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderActionId || '',
    isEnabledFlow,
    availablePaymentMethods?.find((availablePayment) => availablePayment.configured),
    ISIAFlowType.RECURRENT
  );

  useEffect(() => {
    if (isEnabledFlow) setIsEnabledFlow(false);
  }, [isSuccessUpdatePaymentMethod]);

  useEffect(() => {
    if (isErrorUPC && !isLoadingUPC) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleGoPrevSlide,
        errorMock('updateProductCharacteristics', errorUpdateProductCharacteristic),
        undefined,
        undefined,
        handleGoPrevSlide,
        genericError?.genericerror?.buttonLabel || '',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', handleGoPrevSlide),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true
      );
    } else if (isSuccessUPC && !isLoadingUPC) {
      setIsEnabledFlow(true);
    }
  }, [isSuccessUPC, isErrorUPC, isLoadingUPC]);

  useEffect(() => {
    if (isErrorUpdatePaymentMethod && !isLoadingUpdatePaymentMethod) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleGoPrevSlide,
        errorUpdatePaymentMethod || undefined,
        undefined,
        undefined,
        handleGoPrevSlide,
        genericError?.genericerror?.buttonLabel || '',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', handleGoPrevSlide),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorUpdatePaymentMethod, isLoadingUpdatePaymentMethod]);

  useEffect(() => {
    setIsDisabledBackButton(true);
    setHideStickyBar(true);
    mutateUPC('No');
    localStorage.removeItem(IS_AUTOMATIC_RECHARGE_SELECTED);
    return () => {
      setIsDisabledBackButton(false);
      setHideStickyBar(false);
    };
  }, []);

  useEffect(() => {
    if (!isLoadingUpdatePaymentMethod && isSuccessUpdatePaymentMethod) {
      setProduct({
        ...product,
        ...updateProductPrice(product, IUpdateProductPriceType.EDIT_WALLET),
      });
      localStorage.setItem(IS_SELECTED_WALLET, '1');
      setTimeout(() => {
        handleGoNextSlide?.();
      }, 800);
    }
  }, [isSuccessUpdatePaymentMethod, isLoadingUpdatePaymentMethod]);

  return <WarningInfo {...cms} />;
};

export default WarningCard;

