import React from 'react';
import { ICommonData } from '@vfit/shared/models';
import { useVoucherSuccessCard } from './voucherSuccessCard.hook';
import * as S from './voucherSuccessCard.style';

export const VoucherSuccessCard: React.FC<ICommonData> = (props) => {
  const { title, voucherCms, isLoading, discountTitle, message } = useVoucherSuccessCard(props);
  return (
    <S.CardFrame>
      {title && <S.Title dangerouslySetInnerHTML={{ __html: title }} />}
      {voucherCms?.voucherstwilight.success?.subtitle && (
        <S.Subtitle>{voucherCms?.voucherstwilight.success?.subtitle}</S.Subtitle>
      )}
      <S.DisplayPriceBox>
        {discountTitle && <S.TitlePriceDisplay>{discountTitle}</S.TitlePriceDisplay>}
        {message && (
          <S.PricesBox>
            <div dangerouslySetInnerHTML={{ __html: message }} />
          </S.PricesBox>
        )}
      </S.DisplayPriceBox>
      {isLoading && voucherCms?.voucherstwilight.success?.loadingMessage && (
        <S.Message
          dangerouslySetInnerHTML={{
            __html: voucherCms?.voucherstwilight?.success?.loadingMessage,
          }}
        />
      )}
    </S.CardFrame>
  );
};

export default VoucherSuccessCard;
