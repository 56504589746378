import {
  getCurrentUserType,
  getIsStartFromLockin,
  IProduct,
  isLockInIdServiceable,
  IUserType,
} from '@vfit/consumer/data-access';
import { ENCODE_VALUE_PREFIX, getFromLocalStorageByKey, isJSON } from '@vfit/shared/data-access';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { isAppLockin } from './ShoppingCart/shoppingCart.utils';
import { CONTEXT_KEY } from './iBuyFixed.context.data';

export const OVERRIDE_DEBUG_MODE = process.env?.['NEXT_PUBLIC_ENABLE_IBUY_FIXED_DEBUG'] === 'TRUE' || false;
export const ENABLE_DEBUG = OVERRIDE_DEBUG_MODE || process.env['NODE_ENV'] === 'development';
export const IS_ENABLED_WAITING_CART = false;
export const DISABLE_RESET = 'disableReset';

const IMMEDIATE_ACTIVATION = 'yes';
export const OPTION_TO_RECONSIDER_FROM_CMS =
  IMMEDIATE_ACTIVATION && (IMMEDIATE_ACTIVATION == 'yes' ? 'no' : 'yes');

const retrieveProduct = (isReset = false): IProduct | null => {
  const product: IProduct = getFromLocalStorageByKey('tProd');
  const product_backUp: IProduct = getFromLocalStorageByKey('tProd_backUp');
  if (isReset && product_backUp) {
    localStorage.setItem('tProd', JSON.stringify(product_backUp));
    localStorage.removeItem('tProd_backUp');
    return product_backUp || null;
  }
  return product || null;
};

const getProduct = (productInPage: IProduct | undefined, isReset = false) =>
  retrieveProduct(isReset) || productInPage || {};

const isEnabledWaitingCart = () => {
  const isLogged = getCurrentUserType() === IUserType.LOGGED_USER;
  if (isLogged || isAppLockin() || (getIsStartFromLockin() && !isLockInIdServiceable()))
    return true;
  return IS_ENABLED_WAITING_CART;
};

/**
 * This method is used to get the serialized context in local storage in base64
 */
const getSerializeContext = (): any => {
  const serializedContext = localStorage.getItem(CONTEXT_KEY);
  if (!serializedContext) return null;
  const valueSplitted = serializedContext?.split(ENCODE_VALUE_PREFIX);
  const valueToAnalyze =
    valueSplitted?.length > 1 ? decodeURIComponent(atob(valueSplitted?.[1])) || '' : null;
  if (valueToAnalyze && isJSON(valueToAnalyze)) return JSON.parse(valueToAnalyze);
  return valueToAnalyze;
};

const productNotFound = () => {
  errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
    title: 'Ops!',
    message: 'Si è verificato un errore durante il recupero del prodotto. Riprova più tardi',
    actionEvent: () => {
      localStorage.removeItem('shoppingCart');
      window.location.reload();
    },
    actionText: 'Riprova',
    disableTrack: true,
  });
};

export { getProduct, isEnabledWaitingCart, getSerializeContext, retrieveProduct, productNotFound };
