import { useEffect, useState } from 'react';
import {
  ErrorCodes,
  GetCustomerResponse,
  errorMock,
  getIsStartFromLockin,
  useAssociateCustomer,
  useCampaignAccept,
  useCampaignsInfo,
  useGetShoppingCartSilentUpdate,
  LOCK_IN_IS_ACCEPTED_FROM_HUB,
} from '@vfit/consumer/data-access';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyFixed.context';
import { getEligibilityCampaign } from './useLockInFlow.utils';
import { getErrorStatusText } from '../UseCustomerFlow/useCustomerFlow.utils';

/**
 * Lockin Flow
 * campaigns
 * accept
 * getCart
 * associate customer
 */
export const useLockInFlow = () => {
  const queryClient = useQueryClient();
  const [acceptOrderId, setAcceptOrderId] = useState<string>('');
  const {
    product,
    isStartLockInFlow,
    lockInFlow,
    customerFlow,
    setIsStartDeliveryBase,
    setCustomerFlow,
    setCustomerId,
    setLockInFlow,
    setIsStartLockInFlow,
  } = useCheckout();
  const {
    data: campaignsData,
    error: campaignsError,
    isLoading: isLoadingCampaigns,
    isSuccess: isSuccessCampaigns,
    isError: isErrorCampaigns,
    refetch: refetchCampaigns,
  } = useCampaignsInfo({
    keyDependency: ['campaignsLockIn'],
  });
  const {
    data: acceptData,
    error: acceptError,
    isLoading: isLoadingAccept,
    isSuccess: isSuccessAccept,
    isError: isErrorAccept,
    mutate: mutateAccept,
    reset: resetCampaignAccept,
  } = useCampaignAccept();
  const {
    data: getShoppingCartData,
    error: getShoppingCartError,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
    refetch: refetchShoppingCart,
  } = useGetShoppingCartSilentUpdate(acceptOrderId, {
    enabled: false,
    keyDependency: ['getShoppingCartLockIn'],
  });
  const {
    data: associateCustomerData,
    error: associateCustomerError,
    mutate: associateCustomerMutate,
    isSuccess: isSuccessAssociateCustomer,
    isError: isErrorAssociateCustomer,
    isLoading: isLoadingAssociateCustomer,
    reset: resetAssociateCustomer,
  } = useAssociateCustomer();

  const resetLockInFlow = () => {
    sessionStorage.removeItem('uxfauthorization');
    resetAssociateCustomer();
    resetCampaignAccept();
    resetData(queryClient, ['getShoppingCartLockIn', 'campaignsLockIn']);
    setLockInFlow({
      ...lockInFlow,
      resetLockInFlow,
      associateCustomer: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
      shoppingCart: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
      accept: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
      campaigns: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
    });
  };

  useEffect(() => {
    if (getIsStartFromLockin()) {
      setCustomerFlow({ ...customerFlow, associateCustomer: { ...lockInFlow.associateCustomer } });
    }
  }, [lockInFlow]);

  useEffect(() => {
    if (isLoadingAssociateCustomer) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        associateCustomer: {
          ...lockInFlow.associateCustomer,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorAssociateCustomer) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        associateCustomer: {
          ...lockInFlow.associateCustomer,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('associateCustomer', associateCustomerError),
        },
      });
    } else if (isSuccessAssociateCustomer) {
      if ((associateCustomerData as any)?.cartValidation?.status === 'blocked') {
        setLockInFlow({
          ...lockInFlow,
          resetLockInFlow,
          associateCustomer: {
            ...lockInFlow.associateCustomer,
            data: associateCustomerData,
            isLoading: false,
            isSuccess: false,
            isError: true,
            error: errorMock('associateCustomer', {
              status: '500',
              statusText: getErrorStatusText('500', ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED),
              url: '',
            }),
          },
        });
      } else {
        setLockInFlow({
          ...lockInFlow,
          resetLockInFlow,
          associateCustomer: {
            ...lockInFlow.associateCustomer,
            data: associateCustomerData,
            isLoading: false,
            isSuccess: true,
            isError: false,
            error: undefined,
          },
        });
        const customerLocal: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
        if (customerLocal?.[0]) setCustomerId(customerLocal?.[0].id);
        setIsStartDeliveryBase(true);
      }
    }
  }, [isSuccessAssociateCustomer, isErrorAssociateCustomer, isLoadingAssociateCustomer]);

  useEffect(() => {
    if (isLoadingShoppingCart) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        shoppingCart: {
          ...lockInFlow.shoppingCart,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorShoppingCart) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        shoppingCart: {
          ...lockInFlow.shoppingCart,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('shoppingCart', getShoppingCartError),
        },
      });
    } else if (isSuccessShoppingCart) {
      const alreadyDoneAssociateCustomer = getFromLocalStorageByKey('associateCustomer');
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        shoppingCart: {
          ...lockInFlow.shoppingCart,
          data: getShoppingCartData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      if (!alreadyDoneAssociateCustomer && getIsStartFromLockin()) associateCustomerMutate();
    }
  }, [isSuccessShoppingCart, isErrorShoppingCart, isLoadingShoppingCart]);

  useEffect(() => {
    if (acceptOrderId) refetchShoppingCart();
  }, [acceptOrderId]);

  useEffect(() => {
    if (isLoadingAccept) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        accept: {
          ...lockInFlow.accept,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorAccept) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        accept: {
          ...lockInFlow.accept,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('accept', acceptError),
        },
      });
    } else if (isSuccessAccept) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        shoppingCart: {
          isLoading: true,
          isSuccess: false,
          isError: false,
          data: undefined,
          error: undefined,
        },
        accept: {
          ...lockInFlow.accept,
          data: acceptData,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      setAcceptOrderId(acceptData?.orderId || '');
    }
  }, [isSuccessAccept, isErrorAccept, isLoadingAccept]);

  useEffect(() => {
    if (isLoadingCampaigns) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        campaigns: {
          ...lockInFlow.campaigns,
          isLoading: true,
          isSuccess: false,
          isError: false,
          error: undefined,
        },
      });
    } else if (isErrorCampaigns) {
      setLockInFlow({
        ...lockInFlow,
        resetLockInFlow,
        campaigns: {
          ...lockInFlow.campaigns,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('campaigns', campaignsError),
        },
      });
    } else if (isSuccessCampaigns) {
      const lockInOfferCampaign = getEligibilityCampaign(product, campaignsData);
      if (!lockInOfferCampaign) {
        setLockInFlow({
          ...lockInFlow,
          campaigns: {
            ...lockInFlow.campaigns,
            data: undefined,
            error: undefined,
            isLoading: false,
            isSuccess: false,
            isError: true,
          },
        });
      } else {
        setLockInFlow({
          ...lockInFlow,
          resetLockInFlow,
          accept: {
            isLoading: true,
            data: undefined,
            error: undefined,
            isError: false,
            isSuccess: false,
          },
          campaigns: {
            ...lockInFlow.campaigns,
            data: campaignsData,
            error: undefined,
            isLoading: false,
            isSuccess: true,
            isError: false,
          },
        });
        mutateAccept({
          campaignId: lockInOfferCampaign?.id || '',
          sessionId: lockInOfferCampaign?.sessionId || '',
        });
      }
    }
  }, [isSuccessCampaigns, isErrorCampaigns, isLoadingCampaigns]);

  useEffect(() => {
    if (isStartLockInFlow) {
      setIsStartLockInFlow(false);
    }
  }, [isStartLockInFlow]);

  useEffect(() => {
    if (isStartLockInFlow && !lockInFlow?.accept?.isSuccess) {
      const lockInIsAcceptedFromHub = localStorage.getItem(LOCK_IN_IS_ACCEPTED_FROM_HUB);
      if (!lockInIsAcceptedFromHub) refetchCampaigns();
    }
  }, [isStartLockInFlow]);

  useEffect(() => {
    setLockInFlow({
      ...lockInFlow,
      resetLockInFlow,
    });
  }, []);

  return null;
};
