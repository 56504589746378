import { IWarningInfo } from '@vfit/shared/models';

export const configCMS = (obj: IWarningInfo) => ({
  title: obj.title || 'Grazie!',
  subtitle: obj.subtitle || 'Stiamo elaborando il tuo ordine',
  description:
    obj.description ||
    "Per completare l'operazione potrebbe volerci qualche istante. Per favore attendi qui,senza lasciare la pagina.",
});

