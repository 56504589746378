import { useCheckout } from '../../iBuyMobile.context';

const EnableDebug = () => {
  const { printContext } = useCheckout();
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      style={{
        position: 'absolute',
        top: 0,
        zIndex: 9999,
        backgroundColor: 'white',
        borderRadius: 5,
      }}
      onClick={printContext}
    >
      Print State
    </button>
  );
};

export default EnableDebug;
