import {
  IOfferData,
  IProduct,
} from '@vfit/consumer/data-access';
import { ITracking } from '@vfit/shared/data-access';

export enum IFallbackActionType {
  FALLBACK_ACCEPT = '1',
  FALLBACK_CONTINUE = '2',
  FALLBACK_CTC = '3',
  FALLBACK_REDIRECT = '4',
}

export interface IFallbackAction {
  label: string;
  url: string;
  type: IFallbackActionType;
}

export interface IConvergenceModalProps {
  isOpen: boolean;
  product: IProduct;
  handleClose: () => void;
  handleAccept: () => void;
  buildTrackingObject: (step: ETrackConvergenceModal) => ITracking;
  offerData: IOfferData;
  taggingPageName?: string;
}

export enum ETrackConvergenceModal {
  OFFER_STEP = 'offerStep',
  DETAILS_STEP = 'detailsStep',
}

