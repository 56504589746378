import { colors, purify } from '@vfit/shared/themes';
import * as S from './banner.style';
import { IBanner } from './banner.models';

const Banner = ({ color = colors.$00697c, textColor = colors.$ffffff, text }: IBanner) => {
  const [pText] = purify([text]);
  return (
    <S.Container color={color} textColor={textColor}>
      {pText && (
        <div
          dangerouslySetInnerHTML={{
            __html: pText,
          }}
        />
      )}
    </S.Container>
  );
};

export default Banner;
