import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  color: ${colors.$262626};
  width: fit-content;
  margin: 32px 20px 0;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 60px 40px;
    width: auto;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 60px 55px;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(30, 38)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Subtitle = styled.div`
  div {
    font-family: ${fonts.regular};
    font-weight: 400;
    text-align: center;
    ${pxToCssFont(20, 26)}
    margin-bottom: 30px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    div {
      ${pxToCssFont(20, 30)}
      margin-bottom: 35px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin-bottom: 40px;
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

export const TextLoader = styled.div`
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;
  border-radius: 5px;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 162px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;
