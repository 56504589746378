import * as yup from 'yup';

export const IEditNumberSchema = yup.object({
  phoneNumber: yup
    .string()
    .required('Campo obbligatorio')
    .min(10, 'Campo errato')
    .max(11, 'Campo errato')
    .matches(/^[1-9][0-9]*$/, 'Inserire un numero valido'),
});
