import { useEffect } from 'react';
import {
  checkIsGoBackSIA,
  COOKIE_CART,
  IShoppingCartResponse,
  useDeleteShoppingCartMutation,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey, getItemCookie, setItemCookie } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';

export const useCookieDeleteCart = () => {
  const { isLoadingCart } = useCheckout();
  const {
    mutate: deleteShoppingCartMutation,
    isLoading: isLoadingDeleteCart,
    isSuccess: isSuccessDeleteCart,
  } = useDeleteShoppingCartMutation();

  const createCartCookie = () => {
    const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
    const cartCookie = {
      cartId: shoppingCart?.id,
      token: sessionStorage.getItem('uxfauthorization'),
    };
    setItemCookie(COOKIE_CART, cartCookie, 2, false, 'privati.vodafone.it');
  };

  const checkCartCookie = () => {
    const cookie = getItemCookie(COOKIE_CART);
    if (!cookie || (cookie && !cookie?.cartId) || (cookie && !cookie?.token)) {
      createCartCookie();
    } else {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const currentCartId = shoppingCart?.id;
      if (
        cookie?.cartId &&
        currentCartId &&
        cookie?.cartId !== currentCartId &&
        !isSuccessDeleteCart &&
        !isLoadingDeleteCart
      ) {
        deleteShoppingCartMutation({
          shoppingCartId: cookie.cartId,
          customOptions: {
            headers: {
              Authorization: cookie?.token,
            },
          },
        });
        setItemCookie(COOKIE_CART, '', 0, false, 'privati.vodafone.it');
        createCartCookie();
      }
    }
  };

  useEffect(() => {
    if (!isLoadingCart && !checkIsGoBackSIA()) {
      checkCartCookie();
    }
  }, [isLoadingCart]);

  return null;
};
