import { Dispatch } from 'react';
import { IPersonalDataObject } from '@vfit/shared/models';
import { IContactInfo } from '../../../../checkout.models';

export enum IOperationType {
  ERROR = 'error',
  ADD = 'add',
  EDIT = 'edit',
}

export enum IContactType {
  EMAIL = 'email',
  MSISDN = 'phone',
}

export interface IModalConfig {
  isOpen: boolean;
  type?: IContactType;
  operationType?: IOperationType;
  title?: string;
  message?: string;
  actionText?: string;
}

export type IContactData = {
  defaultData?: IContact;
  data: IPersonalDataObject;
  checkInfo: IContactInfo;
  onChangeCheck: (data: IContactInfo) => void;
  onSubmit?: Dispatch<IContact>;
  onChangeData?: (data: IPersonalDataObject, isValid: boolean) => void;
  isReadOnly?: boolean;
  setIsValidForm?: (valid: boolean) => void;
};

export type IContact = {
  emailAddress?: string;
  phoneNumber?: string;
};

// TODO: Refactor in a single file once NameFiscalCode, DocumentId & Contact are in the same directory

export interface IContactMedium {
  type: string;
  preferred: boolean;
  emailAddress?: string;
  phoneNumber?: string;
}

export interface IIdentification {
  identificationType: string;
  nationality: string;
  identificationNumber: string;
  expirationDate: string;
}

export interface IOwningIndividual {
  nation: string;
  fiscalCode: string;
  firstName: string;
  lastName: string;
  contactMedium?: IContactMedium[];
  identification: IIdentification[];
}
