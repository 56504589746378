import React, { useEffect, useState } from 'react';
import { SlideCarousel } from '@vfit/shared/components';
import { CMS_CONFIG, getRedirectCoverage, ISlides, useCmsConfig } from '@vfit/consumer/data-access';
import { CoverageToolModal } from '@vfit/consumer/components';
import { API, IGetAllCmsForm, openLeadModal, openPopup, PAGES } from '@vfit/shared/data-access';
import { IActionType, IClickToCallMeData, ISlideCarouelProps } from '@vfit/shared/models';
import { LoggerInstance, volaModalManager } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';

const SlideCarouselWithCoverage = (slideCarouselProps: ISlideCarouelProps) => {
  const { slide } = slideCarouselProps;
  const { push } = useRouter();
  const queryClient = useQueryClient();
  const [isShowCoverageTool, setIsShowCoverageTool] = useState(false);
  const volaClickToCallData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_CLICK_TO_CALL_CONF
  ) as IClickToCallMeData;

  useEffect(() => {
    LoggerInstance.debug(isShowCoverageTool);
  }, [isShowCoverageTool]);

  const handleClickButton = () => {
    const { action } = slide;
    const buttonType = action?.type ? +action.type : 0;
    let allLeads;
    let refLead;
    let lead;
    switch (buttonType) {
      case IActionType.CUSTOM_URL:
        if (action?.url) {
          window.open(action.url, action?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.COVERAGE_TOOL:
        setIsShowCoverageTool(true);
        break;
      case IActionType.LOCAL_URL:
        if (action?.url) {
          push(`/${action.url}`, undefined, { shallow: true });
        }
        break;
      case IActionType.CALL_ME_NOW:
        if (action?.callMeNow) openPopup(action.callMeNow);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (action?.title && action?.url)
          volaModalManager.handleVolaModal({
            url: action?.url || '',
            cmsClickToCallData: { ...volaClickToCallData },
          });
        break;
      case IActionType.LEAD_MODAL:
        allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        refLead = action?.localUrl || '';
        lead = allLeads[refLead];
        if (lead?.forms) {
          openLeadModal({
            form: lead.forms,
          });
        }
        break;
      default:
        break;
    }
  };

  const handleRedirectCoverageTool = () => {
    if (slide.offerType && slide.slug) {
      const redirectUrl = getRedirectCoverage();
      window.open(redirectUrl, '_self');
    }
  };

  return (
    <>
      <SlideCarousel {...slideCarouselProps} handleClickButton={handleClickButton} />
      <CoverageToolModal
        product={(slide as ISlides).product || undefined}
        backgroundImage={slide.image as string}
        isOpen={isShowCoverageTool}
        customRedirect={handleRedirectCoverageTool}
        handleClose={() => {
          setIsShowCoverageTool(false);
        }}
      />
    </>
  );
};

export default SlideCarouselWithCoverage;

