import { useMutation, useQueryClient } from 'react-query';
import {
  errorMock,
  IAPIError,
  IShoppingCartResponse,
  GetCustomerResponse,
  isMobileProduct,
  IFindCallerResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  IPartyPrivacyItem,
  IRetrieveMultiLinesPayload,
  IRetrieveMultiLinesSearchParams,
} from '@vfit/shared/models';

/**
 * This method calls the Retrieve multiline service.
 * SearchParams: relatedFlow, relatedEntityType, partyPrivacyType
 * @param individualId
 * @param payload
 * @param customOptions
 * @returns
 */
export const retrieveMultiLinesService = (
  individualId: string,
  payload: IRetrieveMultiLinesPayload,
  customOptions?: CustomOptions
): Promise<{ partyPrivacyItems: IPartyPrivacyItem[] }> =>
  nextClient.post(`${API.INDIVIDUAL}/${individualId}/${API.RETRIEVE_MULTILINES}`, payload, {
    searchParams: {
      relatedFlow: 'Provide',
      relatedEntityType: 'Subscriber',
      partyPrivacyType: 'VF',
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  } as IRetrieveMultiLinesSearchParams);

/**
 * Mutation for saving into the key 'retrieveMultiLinesQuery'
 * the response coming from the API service retrieveMultiLinesService.
 * @returns
 */
export const useRetrieveMultiLines = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'retrieveMultiLinesMutation',
    () => {
      const customerData: GetCustomerResponse[] | IAPIError | undefined =
        getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const shoppingCart: IShoppingCartResponse | IAPIError | undefined =
        getFromLocalStorageByKey('shoppingCart');

      const dataSimCartItemId = (shoppingCart as IShoppingCartResponse)?.cartItem?.[0]?.dataSim?.[0]
        ?.product?.id as string;
      const internetCartItemId = (shoppingCart as IShoppingCartResponse)?.cartItem?.[0]
        ?.internet?.[0]?.product?.id;
      const fixedVoiceCartItemId = (shoppingCart as IShoppingCartResponse)?.cartItem?.[0]
        ?.fixedVoice?.[0]?.product?.id;
      const productId = (shoppingCart as IShoppingCartResponse)?.cartItem?.[0]?.product?.id;

      const individualId =
        (customerData?.[0] as GetCustomerResponse)?.owningIndividual?.id ||
        findCaller?.individualRef?.contactId;

      let subscribers = null;
      if (isMobileProduct()) {
        subscribers = [
          {
            productId,
          },
        ];
      } else {
        subscribers = [
          {
            productId: dataSimCartItemId,
          },
          {
            productId: internetCartItemId,
          },
          {
            productId: fixedVoiceCartItemId,
          },
        ];
      }

      const payload: IRetrieveMultiLinesPayload = {
        subscribers,
      };

      return retrieveMultiLinesService(individualId, payload);
    },
    {
      onSuccess: (data: { partyPrivacyItems: IPartyPrivacyItem[] }) => {
        // save new retrieveMultiLines object into storage
        localStorage.setItem('retrieveMultiLines', JSON.stringify(data));
        localStorage.setItem('oldPrivacyProfile', JSON.stringify(data));
        queryClient.setQueryData('retrieveMultiLines', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - retrieveMultiLines: ', error);
        queryClient.setQueryData('retrieveMultiLines', errorMock('retrieveMultiLines', error));
        localStorage.setItem(
          'retrieveMultiLines',
          JSON.stringify(errorMock('retrieveMultiLines', error))
        );
      },
    }
  );
};
