import { IFooter, IPageNavigation } from '@vfit/shared/models';

const organizeFooter = (footer: IFooter): IFooter => ({
  app: footer?.app,
  footerLinks: footer?.footerLinks,
  copyright: footer?.copyright,
  socials: { ...footer?.socials, elements: footer?.socials?.elements },
  mobileLinks: footer?.mobileLinks,
  footerTopBanner: footer?.footerTopBanner,
});

export const useFooter = (cmsJson: IPageNavigation | undefined) => {
  const footer = organizeFooter(cmsJson?.footer);
  return { footer };
};
