import { useState, useEffect } from 'react';
import { API, openPopup, PAGES, setTrackLink } from '@vfit/shared/data-access';
import { ICMSApiError, ICMSError, ICommonData, IOptionalText } from '@vfit/shared/models';
import {
  CMS_CONFIG,
  ErrorCodes,
  IPortabilityCMS,
  useCmsConfig,
  productSpecificError,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { useTracking } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { organizePortabilityLineCard } from './portabilityLineCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { checkErrorCustomer, getTagging, getUserInfo } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { IEditorialErrorConfig } from '../../checkout.models';
import { retrieveProduct } from '../../../iBuyFixed.utils';
import LoadingCard from '../LoadingCard/loadingCard';

const PortabilityLineCard = ({ handleOnChangeEnableGoNext, handleOnClose }: ICommonData) => {
  const product = retrieveProduct();
  const {
    checkoutErrors,
    portability,
    isModalButtonSticky,
    customerFlow,
    setIsStartCustomerFlow,
    setPortability,
    setCurrentStepKey,
    setSlidesGoBack,
    setIsDisabledBackButton,
  } = useCheckout();
  const { urlGenericErrorCallMeNow } = productSpecificError(product);
  const taggingFixed = getTagging('portability', 'fixed');
  const [selectedItem, setSelectedItem] = useState<IOptionalText | undefined>(
    portability?.portabilityLine
      ? {
          title: '',
          value: portability?.portabilityLine || '',
        }
      : undefined
  );
  const isLoadingCustomer =
    customerFlow?.postCustomer?.isLoading ||
    customerFlow?.patchCustomer?.isLoading ||
    customerFlow?.customerInfo?.isLoading ||
    customerFlow?.associateCustomer?.isLoading;
  const isErrorCustomer =
    customerFlow?.postCustomer?.isError ||
    customerFlow?.patchCustomer?.isError ||
    customerFlow?.customerInfo?.isError ||
    customerFlow?.associateCustomer?.isError;
  const errorCustomer =
    customerFlow?.postCustomer?.error ||
    customerFlow?.patchCustomer?.error ||
    customerFlow?.associateCustomer?.error;
  const isSuccessAssociate = customerFlow?.associateCustomer?.isSuccess;

  const { trackView, trackWithNewDatalayer } = useTracking({
    event: ['portability'],
    disableInitialTrack: true,
  });
  setTrackLink(trackView, 'portability');

  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { cardData, errorOnPortability } = organizePortabilityLineCard(dataFromCms);
  const { errorcomponent: errorComponentOrder } =
    (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ORDER_ERROR) as ICMSApiError) || {};
  const orderErrors = errorComponentOrder?.errors || [];

  const { errorcomponent: errorComponentCustomer } =
    (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_CUSTOMER_ERROR_FIXED) as ICMSApiError) ||
    {};
  const customerErrors = errorComponentCustomer?.errors || [];

  const errors: ICMSError[] = [...orderErrors, ...customerErrors];

  const handleSelect = (item: IOptionalText | undefined) => {
    setPortability({
      ...portability,
      portabilityLine: item?.value || '',
      portabilityWant: false,
    });
    localStorage.setItem('portability', item?.value === 'yes' ? '1' : '0');
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const trackGoNext = () => {
      let event_label: string;
      if (selectedItem?.value === 'yes') {
        event_label = 'yes with phone';
      } else if (selectedItem?.value === 'internetOnly') {
        event_label = 'yes internet only';
      } else {
        event_label = 'no portability';
      }
      trackView({
        event_name: 'ui_interaction',
        event_label_track: event_label,
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [selectedItem]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(!isLoadingCustomer && !!selectedItem);
  }, [isLoadingCustomer, selectedItem]);

  useEffect(() => {
    if (isErrorCustomer) {
      const errorDetail: IEditorialErrorConfig = checkErrorCustomer(
        errors,
        errorCustomer?.errorCode || '',
        {
          title: genericError?.genericerror?.title || 'Ops',
          description:
            genericError?.genericerror?.description ||
            errorOnPortability?.erroMessage?.genericError?.title ||
            '',
        }
      );
      const getAction = (isPopup?: boolean) => {
        customerFlow?.resetPatchAndPost?.(customerFlow);
        switch (errorCustomer?.errorCode) {
          case ErrorCodes.CREATE_CUSTOMER_FISCAL_CODE_ALREADY_PRESENT:
            setSlidesGoBack(4);
            break;
          case ErrorCodes.CREATE_CUSTOMER_DOCUMENT_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_MISSING_NATION_ADDRESS:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else {
              setSlidesGoBack(2);
            }
            break;
          case ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT:
          case ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT:
            setSlidesGoBack(3);
            break;
          case ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED:
            if (errorDetail?.isPopup && isPopup) {
              openPopup(errorDetail?.url || urlGenericErrorCallMeNow, () => {
                if (handleOnClose) {
                  handleOnClose();
                }
              });
            } else if (handleOnClose) {
              handleOnClose();
            }
            break;
          default:
            if (handleOnClose) {
              handleOnClose();
            }
            break;
        }
      };
      checkoutErrors?.showAndTrackError?.(
        {
          genericerror: {
            title: errorDetail?.title || '',
            description: errorDetail?.message || '',
            buttonLabel: errorDetail?.actionText || 'Chiudi',
          },
        },
        getAction,
        errorCustomer,
        '',
        '',
        () => getAction(true),
        '',
        undefined,
        '',
        isModalButtonSticky
      );
    }
  }, [isErrorCustomer]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomer);
  }, [isLoadingCustomer]);

  useEffect(() => {
    if (isSuccessAssociate) {
      trackWithNewDatalayer({
        event: ['checkout_step4'],
        event_label: 'portability',
        opts: taggingFixed.opts,
        cartProduct: {
          ...taggingFixed.trackingProduct,
          cart_total: product?.price && removeCurrency(product.price),
        },
        pageProduct: taggingFixed?.pageProductsInfo,
        visitorTrackingOpts: getUserInfo(),
      });
    }
  }, [isSuccessAssociate]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_LINE);
    setIsStartCustomerFlow(true);
  }, []);

  if (!isSuccessAssociate) {
    return <LoadingCard />;
  }

  return (
    <CheckboxCards
      title={cardData.title}
      items={cardData.items}
      value={portability.portabilityLine}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      onSelectItem={handleSelect}
    />
  );
};

export default PortabilityLineCard;

