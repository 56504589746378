import { ICarouselCMS } from '../../cms.models';

export const getExperiencesSlider = (widget: ICarouselCMS) => ({
  experiences: {
    ...widget,
    slides: widget?.elements?.map((s) => ({
      ...s,
      withMask: true,
      maskIconSize: s.maskIconSize || 24,
    })),
  },
  action: {
    title: widget?.customAction?.title || '',
    // @ts-ignore
    // eslint-disable-next-line
    type: +widget?.customAction?.type,
    url: widget?.customAction?.url || '',
    callMeNow: widget?.customAction?.callMeNow || '',
    localUrl: widget?.customAction?.localUrl || '',
  },
});

