import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { ButtonSlide, CheckboxList, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { yupResolver } from '@hookform/resolvers/yup';
import { checkIsApp, useCheckRefund } from '@vfit/consumer/data-access';
import {
  ErrorDetail,
  errorManager,
  ErrorService,
  LoggerInstance,
  resetData,
} from '@vfit/shared/data-access';
import { Form, GenericDetail, VfModal } from '@vfit/shared/components';
import { useDeviceType } from '@vfit/shared/hooks';
import { useForm } from 'react-hook-form';
import {
  Container,
  FormContainer,
  InputContainer,
  ButtonContainer,
  H1,
  ImageDiv,
  ImageSection,
  RadioListContainer,
} from './billingPage.style';
import { IBillingInputs, IProps } from './billingPage.models';
import { validationSchema } from './billingPage.validation';

const BillingPage = ({ fatturazione2830, languagelist }: IProps) => {
  const { modals, pageTexts, topImage, formLabels } = fatturazione2830 || {};
  const { elements: options } = languagelist || {};
  const queryClient = useQueryClient();
  const { isTablet, isDesktop, isMobile } = useDeviceType();
  const { success, error } = modals;
  const [fixedLine, setFixedLine] = useState<string>('');
  const [reqType, setReqType] = useState<string>(options?.[0]?.id || '');
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    text: string;
    content: string;
    buttonLabel: string;
  }>({
    isOpen: false,
    text: '',
    content: '',
    buttonLabel: '',
  });
  const {
    isSuccess,
    isError,
    data: checkRefundResponse,
    error: checkRefundError,
    remove,
  } = useCheckRefund(fixedLine, reqType);

  const errorGeneric: ErrorDetail = {
    message: 'Si è verificato un errore.',
    title: 'Ops',
    actionEvent: () => {},
    actionText: 'Riprova',
  };

  const setModalHeight = () => (isTablet && 331) || (isDesktop && 370) || undefined;

  const {
    setValue,
    register,
    getValues,
    formState: { isValid, errors },
  } = useForm<IBillingInputs>({
    defaultValues: {
      fixedLineNumber: '',
    },
    resolver: yupResolver(validationSchema()),
    mode: 'all',
  });

  const handleCloseModal = () => {
    //   setValue('fixedLineNumber', '');
    //   setFixedLine('');
    setModalConfig({
      isOpen: false,
      text: '',
      content: '',
      buttonLabel: '',
    });
  };

  const handleResetQuery = () => {
    resetData(queryClient, ['checkRefund']);
    remove();
    // setValue('fixedLineNumber', '');
    // resetField('fixedLineNumber');
    // setFixedLine('');
  };

  const onChange = (e: { title: string; value: string }) => {
    setReqType(e?.value || '');
  };

  useEffect(() => {
    if (!modalConfig.isOpen) {
      handleResetQuery();
    }
  }, [modalConfig.isOpen]);

  useEffect(() => {
    if (isSuccess) {
      switch (checkRefundResponse.eligible) {
        case true:
          setValue('fixedLineNumber', '');
          setFixedLine('');
          setModalConfig({
            isOpen: true,
            text: success?.title || '',
            content: success?.description || '',
            buttonLabel: success?.buttonLabel || '',
          });
          break;
        case false:
          if (!!checkRefundResponse.key && checkRefundResponse.key === 'already_requested') {
            setValue('fixedLineNumber', '');
            setFixedLine('');
            setModalConfig({
              isOpen: true,
              text: error?.title || '',
              content: error?.descriptionAlreadyRequested || '',
              buttonLabel: success?.buttonLabel || '',
            });
          }
          if (!!checkRefundResponse.key && checkRefundResponse.key === 'not_found') {
            setModalConfig({
              isOpen: true,
              text: error?.title || '',
              content: error?.descriptionNotFound || '',
              buttonLabel: error?.buttonLabel || '',
            });
          }
          break;
        default:
          break;
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      LoggerInstance.error(isError, checkRefundError.statusText);
      errorManager.handleError(ErrorService.getSeverityErrorMedium(), errorGeneric);
      handleResetQuery();
    }
  }, [isError]);

  const handleSubmit = () => {
    const inputs = getValues();
    setFixedLine(inputs.fixedLineNumber);
  };

  return (
    <>
      <Container>
        <ImageDiv>
          <ImageSection>
            <ImageAtoms
              nameCard="billingPageImg"
              image={topImage?.image || ''}
              imageMobile={topImage?.imageMobile || ''}
              isMobile={isMobile}
            />
          </ImageSection>
        </ImageDiv>
        <FormContainer>
          <CustomText
            text={pageTexts?.top || ''}
            sizeMobile={28}
            size={28}
            lineHeightMobile={36}
            lineHeight={36}
            textAlign="start"
          />
          <H1>{formLabels?.label || ''}</H1>
          <InputContainer>
            <div className="container">
              <Form.MaskInput
                mask={'+3\\9 99999999999'}
                label={formLabels?.input || ''}
                placeholder=" "
                error={errors.fixedLineNumber?.message}
                {...register('fixedLineNumber', {
                  setValueAs: (v: string) => v.trim().substring(4),
                })}
              />
            </div>
          </InputContainer>
          {options && options?.length > 0 && (
            <InputContainer>
              <RadioListContainer>
                <CheckboxList
                  passedValueIndex={0}
                  onChangeItem={onChange}
                  items={options?.map((e) => ({
                    title: e.value || '',
                    value: e.id || '',
                  }))}
                />
              </RadioListContainer>
            </InputContainer>
          )}
          <ButtonContainer>
            <ButtonSlide
              onClick={handleSubmit}
              disabled={!isValid}
              disabledButtonColor="#bebebe"
              disabledBorderColor="#bebebe"
              disabledTextColor="#fff"
              isApp={checkIsApp()}
              label={formLabels?.bottonLabel || ''}
              buttonColor="#e60000"
              labelColor="#fff"
              hoverColor="#bd0000"
              clickColor="#a10000"
              name="action_billingPage"
            />
          </ButtonContainer>
          <CustomText
            text={pageTexts?.bottom || ''}
            sizeMobile={28}
            lineHeightMobile={36}
            size={16}
            lineHeight={18}
            textAlign="center"
          />
        </FormContainer>
      </Container>
      <VfModal height={setModalHeight()} isOpen={modalConfig.isOpen} handleClose={handleCloseModal}>
        <GenericDetail
          title={modalConfig.text}
          description={modalConfig.content}
          secondButtonLabel={modalConfig.buttonLabel}
          options={{
            size: 18,
            lineHeight: 24,
            sizeMobile: 20,
            lineHeightMobile: 26,
            isModal: true,
            textAlign: 'left',
          }}
        />
      </VfModal>
    </>
  );
};

export default BillingPage;
