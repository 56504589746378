import {
  checkBypassApiCallFromSIA,
  errorMock,
  useDeliveryAdvanceV2,
  useDeliveryBaseV2,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { IIdentificationDetails } from '@vfit/shared/models';
import { INextError, ITrackError } from '@vfit/shared/data-access';
import {
  IUseCustomerDeliveryAdvanceResponse,
  IUseDelivery,
  IUseDeliveryProps,
} from './useDeliveryBaseAdvance.models';
import { useCheckout } from '../../../iBuyFixed.context';
import { getDeliveryCode, getIdentificationMethod } from './useDeliveryBaseAdvance.utils';

const DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO: IUseCustomerDeliveryAdvanceResponse = {
  deliveryAdvance: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    error: undefined,
    deliveryOptions: [],
    identificationDetails: [],
  },
};

export const useDeliveryBaseAdvanceFlow = ({ enabled }: IUseDeliveryProps): IUseDelivery => {
  const { shippingAddress, deliveryAdvance, setDeliveryAdvance } = useCheckout();
  const [useCustomerDeliveryAdvanceResult, setUseCustomerDeliveryAdvanceResult] =
    useState<IUseCustomerDeliveryAdvanceResponse>(DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<ITrackError | undefined>(undefined);
  const [deliveryOptions, setDeliveryOptions] = useState<[]>([]);
  const [lastDeliveryAddress, setLastDeliveryAddress] = useState<string>('');
  const [identificationDetails, setIdentificationDetails] = useState<
    IIdentificationDetails | IIdentificationDetails[]
  >([]);

  const {
    data: deliveryBaseData,
    error: deliveryBaseError,
    isSuccess: deliveryBaseIsSuccess,
    isLoading: deliveryBaseIsLoading,
    isError: deliveryBaseIsError,
    refetch: refetchDeliveryBaseV2,
  } = useDeliveryBaseV2({
    enabled: false,
    keyDependency: ['deliveryDetailsIsBase'],
  });

  const {
    refetch: fetchDeliveryAdvance,
    data: deliveryAdvanceData,
    error: deliveryAdvanceError,
    isSuccess: isSuccessDeliveryAdvance,
    isLoading: isLoadingDeliveryAdvance,
    isError: isErrorDeliveryAdvance,
  } = useDeliveryAdvanceV2(
    getIdentificationMethod(identificationDetails),
    shippingAddress?.id || '',
    getDeliveryCode(deliveryOptions) || '',
    {
      enabled: false,
      keyDependency: ['deliveryDetailsIsAdvance'],
    }
  );

  // region DELIVERY ADVANCE

  useEffect(() => {
    if (isLoadingDeliveryAdvance) {
      setUseCustomerDeliveryAdvanceResult((prevState) => ({
        ...prevState,
        deliveryAdvance: {
          ...prevState.deliveryAdvance,
          isLoading: true,
        },
      }));
      setDeliveryAdvance({
        ...deliveryAdvance,
        isLoading: true,
        isSuccess: false,
        isError: false,
        error: undefined,
        deliveryOptions: [],
        identificationDetails: [],
      });
    } else if (isErrorDeliveryAdvance) {
      setUseCustomerDeliveryAdvanceResult((prevState) => ({
        ...prevState,
        deliveryAdvance: {
          ...prevState.deliveryAdvance,
          isLoading: false,
          isError: true,
        },
      }));
      setDeliveryAdvance({
        ...deliveryAdvance,
        isLoading: false,
        isSuccess: false,
        isError: true,
        error: errorMock('deliveryAdvance', deliveryAdvanceError as INextError),
      });
    } else if (isSuccessDeliveryAdvance) {
      setUseCustomerDeliveryAdvanceResult((prevState) => ({
        ...prevState,
        deliveryAdvance: {
          ...prevState.deliveryAdvance,
          isLoading: false,
          isSuccess: true,
        },
      }));
      setDeliveryAdvance({
        ...deliveryAdvance,
        isLoading: false,
        isSuccess: true,
        isError: false,
        error: undefined,
        deliveryOptions: Array.isArray(deliveryAdvanceData)
          ? deliveryAdvanceData?.[0]?.deliveryItems || []
          : deliveryAdvanceData?.deliveryItems || [],
        identificationDetails: Array.isArray(deliveryAdvanceData)
          ? deliveryAdvanceData?.[0]?.identificationDetails || []
          : deliveryAdvanceData?.identificationDetails || [],
      });
    }
  }, [isLoadingDeliveryAdvance, isSuccessDeliveryAdvance, isErrorDeliveryAdvance]);

  useEffect(() => {
    if (shippingAddress?.id && lastDeliveryAddress !== shippingAddress?.id && isSuccess) {
      setLastDeliveryAddress(shippingAddress.id);
      fetchDeliveryAdvance();
    }
  }, [shippingAddress, isSuccess]);

  // endregion

  // region DELIVERY BASE

  useEffect(() => {
    if (deliveryBaseIsLoading) {
      setIsSuccess(false);
      setIsLoading(true);
      setIsError(false);
    } else if (deliveryBaseIsSuccess && !isSuccess && !checkBypassApiCallFromSIA()) {
      setDeliveryOptions(
        Array.isArray(deliveryBaseData)
          ? deliveryBaseData?.[0]?.deliveryDetails || []
          : deliveryBaseData?.deliveryDetails || []
      );
      setIdentificationDetails(
        Array.isArray(deliveryBaseData)
          ? deliveryBaseData?.[0]?.identificationDetails || {}
          : deliveryBaseData?.identificationDetails || {}
      );
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [deliveryBaseIsSuccess, deliveryBaseIsLoading, deliveryOptions]);

  useEffect(() => {
    if (deliveryBaseIsError) {
      setIsLoading(false);
      setIsError(true);
      setError(errorMock('deliveryBase', deliveryBaseError as INextError));
      setErrorMessage(deliveryBaseData?.errorMessage);
    }
  }, [deliveryBaseIsError, deliveryBaseData]);

  // endregion

  useEffect(() => {
    if (enabled && !checkBypassApiCallFromSIA() && !isError) {
      refetchDeliveryBaseV2();
    }
  }, [enabled]);

  return {
    isSuccess,
    isLoading,
    isError,
    errorMessage,
    deliveryOptions,
    identificationDetails,
    error,
    useCustomerDeliveryAdvanceResult,
  };
};
