import { useEffect } from 'react';
import {
  CMS_CONFIG,
  IWinBackCampaignConfig,
  IWinBackCampaignConfigDetail,
  IWinBackCMS,
  overrideProductList,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { useTracking, errorManager, ErrorService } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { API, openPopup, PAGES } from '@vfit/shared/data-access';
import { IProductsWinbackProps } from './productsWinback.models';
import ProductsSolutions from '../ProductsSolutions/productsSolutions';
import { Widgets } from '../../index';
import { organizeWinBackErrorPopup } from './productsWinback.utils';
import { WinbackContainer } from './productsWinback.style';

const ProductsWinBack = ({
  productsAll,
  description,
  indexSlider,
  onTrack,
  divider,
  disclaimer,
  panicModeConfiguration,
}: IProductsWinbackProps) => {
  const client = useQueryClient();
  const urlParams = new URLSearchParams(window.location.search);
  const clientCode = urlParams?.get('cC') || urlParams?.get('cc') || '';
  const targetCampaignId = urlParams?.get('tC') || urlParams?.get('tc') || '';
  const products = (overrideProductList(productsAll, panicModeConfiguration)?.filter((p) => p.campaignId === clientCode) || []).map(
    (product) => ({
      ...product,
      isWinback: true,
      cc: clientCode,
      tc: targetCampaignId,
    })
  );

  const pageProduct = SoftManagerService(client).getSoftProductsByProducts(products);
  const winbackCampaignsList = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_WINBACK_CONFIG
  ) as IWinBackCampaignConfig;

  const { widgets, campaigndetails: campaignDetails }: IWinBackCampaignConfigDetail =
    winbackCampaignsList?.[clientCode] || {};

  const expirationDate = campaignDetails?.expirationDate;
  const isExpiredCampaign = () => {
    if (!expirationDate) {
      return true;
    }
    return new Date() > new Date(expirationDate);
  };

  const solution = !isExpiredCampaign() ? { products, description } : { products: [] };

  const cmsData = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_WINBACK_DATA) as IWinBackCMS;

  const cmsConfig = organizeWinBackErrorPopup(cmsData);

  const actionPopup = () => {
    openPopup(cmsConfig?.urlClickToCall, () => {
      window.location.href = cmsConfig?.popupButtonRedirect;
    });
  };

  const { trackView, isReadyTrackView } = useTracking({
    event: ['view', 'product_view', 'journey_start'],
    opts: {
      event_category: 'sales',
      page_section: 'campaign',
      page_subsection: 'landing',
      journey_name: 'winback',
      journey_type: 'journey',
    },
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: true,
  });

  const showExpiredCampaign = () => {
    trackView({
      event_name: 'page_error',
      event_label_track: 'error',
      journey_name: 'winback',
      journey_type: 'journey',
      page_section: 'campaign',
      page_subsection: 'error pop up',
      page_type: 'error page',
      page_error: `campaign_expired_${cmsConfig?.popupMessage}`,
      page_error_code: '404',
    });
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
      title: cmsConfig?.popupTitle,
      message: cmsConfig?.popupMessage,
      actionText: cmsConfig?.popupCtaText,
      actionEvent: actionPopup,
      secondButtonText: cmsConfig?.popupButtonText,
      secondActionEvent: () => {
        window.location.href = cmsConfig?.popupButtonRedirect;
      },
      onClose: () => {
        window.location.href = cmsConfig?.popupButtonRedirect;
      },
      disableTrack: true,
    });
  };

  const manageErrorWinback = () => {
    const redirectHomePage = () => {
      window.location.href = '/';
    };
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: cmsData?.winbacknumber?.errorPopup?.title || 'Ops',
      message:
        cmsData?.winbacknumber?.errorPopup?.message ||
        'Si è verificato un problema. Riprovare in seguito',
      actionText: 'Chiudi',
      fullscreen: true,
      disableCloseModalOnClickAction: true,
      onClose: redirectHomePage,
      actionEvent: () => {
        redirectHomePage();
      },
    });
  };

  const checkWinback = () => {
    if (!clientCode || !targetCampaignId) {
      manageErrorWinback();
    } else {
      targetCampaignId && localStorage.setItem('targetCampaignId', targetCampaignId);
      if (isExpiredCampaign()) {
        showExpiredCampaign();
      } else {
        trackView({
          event_name: '',
          event_category: 'sales',
          page_section: 'campaign',
          page_subsection: 'landing',
          journey_name: 'winback',
          journey_type: 'journey',
        });
      }
    }
  };

  useEffect(() => {
    if(isReadyTrackView) checkWinback();
  }, [isReadyTrackView]);

  return (
    <WinbackContainer>
      <ProductsSolutions
        classKey="solutions"
        solutions={solution}
        showCase={indexSlider}
        onTrack={onTrack}
        divider={divider}
        disclaimer={disclaimer}
      />
      {widgets && <Widgets widgets={widgets.sliders} />}
    </WinbackContainer>
  );
};

export default ProductsWinBack;
