import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';

interface IReservePhoneNumberData {
  logicalResourceCategory: string;
  status: string;
  type: string;
  value: string;
}

/**
 * Methods to invoke the POST /reserve service
 * @param payload
 * @param customOptions
 */
const reservePhoneNumberService = (
  payload: any,
  customOptions?: CustomOptions
): Promise<IReservePhoneNumberData[]> =>
  nextClient.post(API.RESERVE, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Mutation reserve phone number
 */
export const useReservePhoneNumber = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'reservePhoneNumber',
    () => {
      const payload = {
        type: 'msisdn',
        phoneNumberCategory: 'regular',
        resourceReqCount: 6,
      };
      LoggerInstance.debug('reservePhoneNumber payload ', payload);

      return reservePhoneNumberService(payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('reservePhoneNumber', JSON.stringify(data));
        queryClient.setQueryData('reservePhoneNumber', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - reservePhoneNumber: `, error);
        queryClient.setQueryData('reservePhoneNumber', errorMock(`reservePhoneNumber`, error));
        localStorage.setItem(
          `reservePhoneNumber`,
          JSON.stringify(errorMock(`reservePhoneNumber`, error))
        );
      },
    }
  );
};
