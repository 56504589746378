import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  color: ${colors.$262626};
  font-weight: 400;

  .text-input {
    margin-bottom: 24px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 32px;
      width: 328px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: 40px;
      width: 406px;
    }
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Subtitle = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(18, 24)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const Div = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonDiv = styled.div`
  margin: 0 auto;

  @media (min-width: ${breakpoints.tablet}) {
    width: 246px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 300px;
  }
`;

