import { ICarouselCMS } from '../../cms.models';

export const getLargeSmallSlider = (widget: ICarouselCMS) => ({
  slides: widget?.elements?.map((s, indexSlide) => {
    if (indexSlide === 0) {
      return {
        ...s,
        action: {
          ...s.action,
          callMeNow: s.action?.callMeNow || s.action?.url || '',
          // @ts-ignore
          small: s.action?.small ? s.action.small === 'true' : true,
        },
      };
    }
    return {
      ...s,
      withMask: true,
      ...(s.maskIcon && { maskIcon: s.maskIcon }),
      ...(s.maskIconSize && { maskIconSize: s.maskIconSize }),
    };
  }),
  topText: widget.title || '',
});
