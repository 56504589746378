import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';
import { ICSSProps } from './checkboxMultiline.models';

export const Parent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -16px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: -24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: -32px;
  }
`;

export const CheckboxDiv = styled.div<ICSSProps>`
  width: 100%;
  margin-bottom: 16px;

  :last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(50% - 12px);
    margin-bottom: 24px;

    :nth-last-child(2) {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: calc(50% - 16px);
    margin-bottom: 32px;
  }
`;

