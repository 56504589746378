import { TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { IProduct } from '@vfit/consumer/data-access';
import { setTrackLink, setTrackView } from '@vfit/shared/data-access';

interface ITaggingBlank {
  taggingOptions?: TrackingOptions;
  isDisabledInitialTrack?: boolean;
  productsAll?: IProduct[];
}

const TaggingBlank = ({ taggingOptions, productsAll, isDisabledInitialTrack }: ITaggingBlank) => {
  const client = useQueryClient();
  const pageProduct = SoftManagerService(client).getSoftProductsByProducts(
    productsAll?.filter((el) => el.isShowedInHub) || []
  );
  const { trackView } = useTracking({
    event: [taggingOptions?.event || 'view'],
    event_label: taggingOptions?.eventLabel || '',
    opts: {
      ...taggingOptions,
      product_category: pageProduct?.product_category || '',
    },
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
  });
  setTrackView(trackView);
  setTrackLink(trackView);

  return null;
};

export default TaggingBlank;
