import { useGetFindCaller } from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import { getFromLocalStorageByKey, resetData } from '@vfit/shared/data-access';
import { ICommonData, IFindCallerObject } from '@vfit/shared/models';
import { Skeleton } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import ConfirmIdentityApp from './components/ConfirmIdentityApp/confirmIdentityApp';
import { SKELETON_SHAPE } from './confirmIdentityAppCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';

const ConfirmIdentityAppCard = (props: ICommonData) => {
  const queryClient = useQueryClient();
  const { handleOnClose } = props;
  const { owningData, findCaller, setOwningData, setFindCaller } = useCheckout();
  const appSession = getFromLocalStorageByKey('appSession');
  const {
    isError: isErrorGetFindCaller,
    isSuccess: isSuccessGetFindCaller,
    data: findCallerData,
    refetch: refetchFindCaller,
  } = useGetFindCaller(
    {
      fiscalCode: appSession?.fiscalcode || '',
    },
    {
      enabled: false,
    }
  );

  const onErrorFindCaller = () => {
    handleOnClose?.();
  };

  useEffect(() => {
    if (isErrorGetFindCaller) {
      onErrorFindCaller();
    }
  }, [isErrorGetFindCaller]);

  useEffect(() => {
    const backButton = document.getElementById('back-button');
    if (isSuccessGetFindCaller && findCallerData) {
      if (!findCallerData?.individualRef) {
        onErrorFindCaller();
      } else {
        setOwningData({
          ...owningData,
          owningIndividual: {
            ...owningData.owningIndividual,
            firstName: appSession?.firstname || '',
            lastName: appSession?.lastname || '',
            fiscalCode: appSession?.fiscalcode || '',
          },
          isValid: true,
        });
        setFindCaller({
          ...(findCallerData as unknown as IFindCallerObject),
          showOtp: true,
          verifyOtp: '',
          isVerified: false,
          selectedContactMedium: undefined,
        });
      }
    }
    const reset = () => {
      resetData(queryClient, ['findCaller']);
    };
    backButton?.addEventListener('click', reset);
    return () => {
      backButton?.removeEventListener('click', reset);
    };
  }, [isSuccessGetFindCaller]);

  useEffect(() => {
    if (appSession?.fiscalcode) refetchFindCaller();
  }, []);

  if (!isSuccessGetFindCaller || !findCaller?.showOtp) {
    return <Skeleton {...SKELETON_SHAPE} />;
  }

  return <ConfirmIdentityApp {...props} />;
};

export default ConfirmIdentityAppCard;
