import { HeroSlider } from '@vfit/shared/components';
import { IActionType } from '@vfit/shared/models';
import { ITopHomeProps } from './heroSliderConsumer.models';
import useHeroSliderConsumer from './heroSliderConsumer.hook';

const HeroSliderConsumer = ({ slides }: ITopHomeProps) => {
  const { showCookieCart, cmsData, redirectUrl, onTrack } = useHeroSliderConsumer();

  const getSlides = () =>
    !showCookieCart
      ? slides
      : slides.map((slide, indexSlide) => {
          const pillsOverrides = cmsData?.specs?.map((el) => ({
            keyword: el.value,
          }));
          if (indexSlide === 0) {
            return {
              ...slide,
              pills:
                cmsData?.specs.length > 0
                  ? { pills: pillsOverrides, isDark: 'false' }
                  : slide.pills,
              video: '',
              videoMobile: '',
              image: cmsData?.image || slide?.image,
              imageMobile: cmsData?.imageMobile || slide?.imageMobile,
              category: cmsData?.category || slide?.category || '',
              title: cmsData?.title || slide.title || '',
              description: cmsData?.description || slide.description || '',
              action: {
                title: cmsData?.ctaLabel || slide?.action?.title || '',
                type: IActionType.CUSTOM_URL,
                url: showCookieCart ? redirectUrl : slide?.action?.url || '',
              },
            };
          }
          return {
            ...slide,
          };
        });

  return <HeroSlider slides={getSlides()} onTrack={onTrack} />;
};

export default HeroSliderConsumer;
