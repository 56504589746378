import { breakpoints, colors } from '@vfit/shared/themes';
import styled, { css } from 'styled-components';

const fixedCss = css`
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
`;

export const HeaderWrapper = styled.div<{ fixed: boolean }>`
  background-color: ${colors.$ffffff};
  ${({ fixed }) => (fixed ? fixedCss : '')}

  @media (min-width: ${breakpoints.desktop}) {
    box-shadow: 0px 3px 10px 0px ${colors.$000000_16};
  }
`;

