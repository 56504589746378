import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { errorMock, IShoppingCartResponse, IShoppingCartPayload } from '@vfit/consumer/data-access';

/**
 * Shopping cart service
 * @param shoppingCart
 * @param customOptions
 * @returns
 */
export const shoppingCartService = (
  shoppingCart: IShoppingCartPayload,
  customOptions?: CustomOptions
): Promise<IShoppingCartResponse> =>
  nextClient.post(API.SHOPPING_CART, shoppingCart, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * This method handle the create cart service.
 * @returns
 */
export const useShoppingCartMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'shoppingCart',
    (payload: IShoppingCartPayload) => shoppingCartService(payload),
    {
      onSuccess: (data: IShoppingCartResponse) => {
        if (data) {
          // save shoppingCart object into storage
          localStorage.setItem('shoppingCart', JSON.stringify(data));
          queryClient.setQueryData('shoppingCart', data);

          // Cookie.set('sunrise-flow', 'true', { domain: '.vodafone.it' });
          // if (data?.id) Cookie.set('sunrise-cart-id', data.id as string);
        } else {
          queryClient.setQueryData('shoppingCart', errorMock('shoppingCart'));
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - shoppingCart: `, error);
        queryClient.setQueryData('shoppingCart', errorMock('shoppingCart', error));
        localStorage.setItem(`shoppingCart`, JSON.stringify(errorMock(`shoppingCart`, error)));
      },
    }
  );
};
