import { ICoverageToolCongifg, getCompleteAddressValidation } from '@vfit/consumer/data-access';
import * as yup from 'yup';

export const contactSchema = (coverageToolConfig: ICoverageToolCongifg) => {
  const yupContactSchema = yup.object({
    phoneNumber: yup
      .string()
      .required('Campo obbligatorio')
      .min(9, 'Inserire un numero valido')
      .max(10, 'Inserire un numero valido')
      .matches(/^(3)+[0-9]*$/, 'Inserire un numero valido'),
    emailAddress: yup
      .string()
      .required('Campo obbligatorio')
      .email('Inserire email nel formato corretto'),
  });

  const schema = getCompleteAddressValidation(coverageToolConfig);

  return yupContactSchema.concat(schema);
};
