import { IAnchors } from '@vfit/shared/models';

export enum IWidgetType {
  PRODUCTS_SLIDER = 'SLIDER_PRODUCTS',
  DEFAULT_SLIDER = 'SLIDER_DEFAULT',
  EXP_SLIDER = 'SLIDER_EXPERIENCES',
  QR_MODULE = 'QR_MODULE',
  LARGE_SMALL_SLIDER = 'SLIDER_LARGE_SMALL',
  LARGE_LARGE_SLIDER = 'SLIDER_LARGE_LARGE',
  ANCHORS = 'ANCHORS',
  IMAGE_TITLE_DESCRIPTION = 'SLIDER_IMAGE_TITLE_DESCRIPTION',
  ICON_TITLE_DESCRIPTION = 'SLIDER_ICON_TITLE_DESCRIPTION',
  FAQ = 'FAQ',
  SPLITTED_IMAGE_TEXT = 'SPLITTED_IMAGE_TEXT',
  HUB = 'HUB',
  HUB_DEVICES = 'HUB_DEVICES',
  HUB_CUSTOM = 'HUB_CUSTOM',
  HUB_SOLUTIONS = 'HUB_SOLUTIONS',
  HUB_SOLUTIONS_CUSTOM = 'HUB_SOLUTIONS_CUSTOM',
  HUB_WINBACK = 'HUB_WINBACK',
  SHOPPING_CART_TECHNOLOGIES = 'SHOPPING_CART_TECHNOLOGIES',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  FREE_HTML = 'FREE_HTML',
  TOP_HERO = 'TOP_HERO',
  DOCUMENTS = 'DOCUMENTS',
  TEXT_ACTION = 'TEXT_ACTION',
  TABS_WIDGETS = 'TABS',
  FORMS = 'FORMS',
  DROPPED_CART = 'DROPPED_CART',
  STICKY_BANNER_FOOTER = 'FLOATING_BANNER',
  TABLE_CHARACTERISTICS = 'TABLE_CHARACTERISTICS',
}

export interface IHomeLinksProps {
  anchors: IAnchors[];
  text?: string;
  description?: string;
  onTrack?: string;
  anchorScroll?: boolean;
}
