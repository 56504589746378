import { useState } from 'react';
import { VfModal } from '@vfit/shared/components';
import { Path } from '@vfit/shared/models';
import { getHome } from '@vfit/shared/data-access';

const DebugSIACard = () => {
  const [isOpen, setIsOpen] = useState(true);

  const goToMode = (path: string) => () => {
    window.location.href = `${getHome()}/checkOrder?${path}`;
  };

  return (
    <div>
      <VfModal wrapperId="siaDebug" isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <div>
          <h2>SIA DEBUG MODE</h2>
          <button type="button" onClick={goToMode(Path.URL_BACK)}>
            CHECK ORDER MODE URL BACK
          </button>
          <button type="button" onClick={goToMode(Path.URL_FORWARD)}>
            CHECK ORDER MODE URL FORWARD
          </button>
        </div>
      </VfModal>
    </div>
  );
};

export default DebugSIACard;
