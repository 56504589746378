import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const MapContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  height: 300px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    height: 370px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
    height: 540px;
  }

  .gmnoprint.gm-bundled-control {
    margin: 0 !important;
  }

  #location-control {
    display: block !important;
  }
`;

export const Banner = styled.div`
  position: absolute;
  width: 100%;
  height: 64px;

  @media (min-width: ${breakpoints.tablet}) {
    height: 72px;
  }
`;

export const LocationIcon = styled.div`
  z-index: 3;
  position: absolute;
  width: fit-content;
  margin-left: calc(100% - 48px);
  padding-bottom: 64px;
  display: flex;
  flex-direction: row-reverse;

  @media (min-width: ${breakpoints.tablet}) {
    padding-bottom: 72px;
  }

  svg {
    margin: -4px;
  }
`;

export const Current = styled.div`
  width: 100%;
  background-color: rgba(38, 38, 38, 0% 9);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 16px;
  height: 53px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 17px 16px;
    height: 40px;
  }

  .posteIcon,
  .vodafoneStoreIcon {
    margin-right: 16px;
    width: 27px;
    height: 27px;

    @media (min-width: ${breakpoints.tablet}) {
      width: 35px;
      height: 35px;
    }
  }
`;

export const Detail = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$ffffff};
  ${pxToCssFont(14, 19)}
  text-transform: capitalize;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(16, 22)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(18, 24)}
  }

  p {
    margin: 0 auto;
  }
`;

