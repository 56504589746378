import {
  CMS_CONFIG,
  ErrorCodes,
  errorMock,
  getCurrentUserType,
  IGenericErrorCMS,
  IUserType,
  useCmsConfig,
  useGetCustomerReference,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { API, getFromLocalStorageByKey, openPopup, PAGES } from '@vfit/shared/data-access';
import { resetData } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { ICMSApiError, ICMSError, ICommonData, ICountryObject } from '@vfit/shared/models';
import { retrieveOwningIndividual } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { isAppLockin, updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';

export const usePostOtpFlow = (props: ICommonData) => {
  const { handleOnClose } = props;
  const {
    product,
    findCaller,
    customerFlow,
    customerAsyncInfo,
    owningData,
    checkoutErrors,
    isModalButtonSticky,
    lockInMMFlow,
    setIsShowCheckout,
    setProduct,
    setIsStartLockInMMFlow,
    setIsDisabledBackButton,
    setCustomerId,
    setOwningData,
    setIsStartCustomerFlow,
    setFindCaller,
  } = useCheckout();
  // if the product is lockin, calls do not need to be repeated
  const alreadyFetchedInfo = product?.isLockInMMProduct;
  const queryClient = useQueryClient();
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const otpVerifyResponse = { id: findCaller.verifyOtp };
  const {
    isSuccess: isReferenceSuccess,
    isLoading: isReferenceLoading,
    isError: isReferenceError,
    error: errorCustomerReference,
  } = useGetCustomerReference();
  const { associateCustomer } = customerFlow;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const {
    errorcomponent: { errors },
  } = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_ORDER_ERROR) as ICMSApiError;
  const blockedCustomer = errors?.find(
    (item: ICMSError) => item?.error === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
  );
  const isLoadingLockin =
    lockInMMFlow?.campaigns?.isLoading ||
    lockInMMFlow?.accept?.isLoading ||
    lockInMMFlow?.shoppingCart?.isLoading;
  const isErrorLockin =
    lockInMMFlow?.campaigns?.isError ||
    (lockInMMFlow?.campaigns?.isSuccess && !lockInMMFlow?.checkEligibility?.eligible) ||
    (lockInMMFlow?.campaigns?.isSuccess && !lockInMMFlow?.checkEligibility?.lockInProduct) ||
    lockInMMFlow?.accept?.isError ||
    lockInMMFlow?.shoppingCart?.isError;
  const isSuccessLockin =
    lockInMMFlow?.campaigns?.isSuccess &&
    lockInMMFlow?.accept?.isSuccess &&
    lockInMMFlow?.shoppingCart?.isSuccess;
  const isLoadingCustomerInfo =
    isReferenceLoading ||
    !isReferenceSuccess ||
    (!!otpVerifyResponse?.id && !associateCustomer.isSuccess) ||
    customerFlow?.billingAccount?.isLoading ||
    customerAsyncInfo?.customerInfo?.isLoading ||
    customerFlow?.paymean?.isLoading ||
    customerFlow?.payment?.isLoading ||
    customerAsyncInfo?.termsAndConditions?.isLoading;

  const preconditionCustomer = () => {
    if (associateCustomer.isSuccess || associateCustomer.isError || isReferenceError) {
      setFindCaller({
        ...findCaller,
        ...(customerFlow?.findcaller?.data?.paymean && {
          paymean: customerFlow?.findcaller?.data?.paymean,
        }),
        showOtp: false,
        verifyOtp: '',
        isVerified: true,
      });
      if (
        associateCustomer.isError &&
        associateCustomer.error?.error?.statusText === ErrorCodes.ASSOCIATE_CUSTOMER_BLOCKED
      ) {
        const handleClose = () => {
          if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        const goBackAndReset = () => {
          if (blockedCustomer?.button?.urlForRedirect) {
            openPopup(blockedCustomer?.button?.urlForRedirect || '', () => {
              if (handleOnClose) {
                resetData(queryClient);
                handleOnClose();
              }
            });
          } else if (handleOnClose) {
            resetData(queryClient);
            handleOnClose();
          }
        };
        checkoutErrors?.showAndTrackError?.(
          genericError,
          () => handleClose(),
          associateCustomer?.isError ? associateCustomer.error : undefined,
          blockedCustomer?.title || 'Attenzione',
          blockedCustomer?.message ||
            'I seguenti articoli non sono attivabili dal cliente selezionato',
          () => goBackAndReset(),
          blockedCustomer?.button?.title || 'Ti richiamiamo',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', handleClose),
          product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      } else if (associateCustomer.isError || isReferenceError) {
        let error;
        if (isReferenceError && errorCustomerReference) {
          error = errorMock(ErrorCodes.REFERENCE_CUSTOMER, errorCustomerReference);
        }
        if (associateCustomer.isError && customerAsyncInfo?.customerInfo?.isError) {
          error = customerAsyncInfo?.customerInfo?.error;
        }
        checkoutErrors?.showAndTrackError?.(
          genericError,
          handleOnClose,
          { ...error, isBlocking: true },
          '',
          '',
          handleOnClose,
          genericError?.genericerror?.buttonLabel || '',
          () => openPopup(product?.genericErrorCallMeNow?.url || '', () => handleOnClose),
          product?.genericErrorCallMeNow?.label,
          isModalButtonSticky
        );
      }
    }
  };

  const checkSuccessLockIn = () => {
    if (
      isSuccessLockin &&
      !isLoadingCustomerInfo &&
      !isLoadingLockin &&
      !alreadyFetchedInfo &&
      lockInMMFlow.checkEligibility.lockInProduct
    ) {
      localStorage.setItem('tProd_backUp', JSON.stringify(product));
      setProduct({
        ...lockInMMFlow.checkEligibility.lockInProduct,
        ...updateProductPrice(lockInMMFlow.checkEligibility.lockInProduct),
        slug: product?.slug,
        shoppingCartUrl: product?.shoppingCartUrl,
        isLockInMMProduct: true,
      });
      setIsShowCheckout(false);
    }
  };

  const checkStartLockInMMFlow = () => {
    const userType = getCurrentUserType();
    return (
      !isAppLockin() &&
      (userType === IUserType.NEXT_USER_ACTIVE || userType === IUserType.LOGGED_USER) &&
      product.enableLockInFlow
    );
  };

  useEffect(() => {
    preconditionCustomer();
    if (associateCustomer.isSuccess) {
      const customer = customerAsyncInfo.customerInfo.data?.[0];
      if (customer) {
        const retrivedIndividual = retrieveOwningIndividual(
          customer,
          countries as ICountryObject[]
        );
        setOwningData({
          ...owningData,
          owningIndividual: {
            ...owningData.owningIndividual,
            ...retrivedIndividual,
          },
        });
        setCustomerId(customer.id);
      }
    }
  }, [associateCustomer, isReferenceError, customerAsyncInfo]);

  useEffect(() => {
    if ((isErrorLockin || isSuccessLockin) && customerFlow.findcaller?.data?.customerRef?.id) {
      setCustomerId(customerFlow.findcaller?.data?.customerRef?.id);
    }
  }, [isErrorLockin, isSuccessLockin]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingCustomerInfo || isLoadingLockin);
    checkSuccessLockIn();
  }, [isLoadingLockin, isLoadingCustomerInfo]);

  useEffect(() => {
    const userType = getCurrentUserType();
    const isActiveUser =
      customerFlow.findcaller &&
      customerFlow.findcaller?.data?.customerHasActiveSubs &&
      customerFlow.findcaller?.data?.customerRef?.id;
    if (checkStartLockInMMFlow() && (userType === IUserType.LOGGED_USER || isActiveUser)) {
      setIsStartLockInMMFlow(true);
    } else if (isActiveUser && customerFlow.findcaller?.data?.customerRef?.id) {
      setCustomerId(customerFlow.findcaller?.data?.customerRef?.id);
    }
  }, [customerFlow.findcaller]);

  useEffect(() => {
    setFindCaller({
      ...findCaller,
      showOtp: false,
    });
    // If Is not already lockin start customer flow
    if (!alreadyFetchedInfo) setIsStartCustomerFlow(true);
  }, []);

  return {
    isLoading: alreadyFetchedInfo ? false : isLoadingLockin || isLoadingCustomerInfo,
  };
};
