import React, { useState } from 'react';
import { EditAddress, StepperAddressModal } from '@vfit/consumer/components';
import { VfModal } from '@vfit/shared/components';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  CMS_CONFIG,
  IDeliveryInformation,
  IMapCms,
  isDifferentAddress,
  organizeEditAddressManual,
  organizeEditAddressFixed,
  organizeEditAddressOptionFixed,
  organizeMap,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, PAGES, trackLink } from '@vfit/shared/data-access';
import {
  IAddressDataObjectExtended,
  IAddressType,
  IDeliveryOptions,
  IEditAddressCmsConfig,
  IPickUpLocation,
} from '@vfit/shared/models';
import {
  Container,
  Title,
  Description,
  TopContent,
  UserInfo,
  DeliveryCta,
  Divider,
  BottomTitle,
  LabelCta,
  BillingCta,
  InstallationAddress,
} from './yourAddress.style';
import { IOtherInformation, IYourAddress } from './yourAddress.model';
import NoteModalDelivery from './components/NoteModalDelivery/noteModalDelivery';
import { useCheckout } from '../../../../../iBuyFixed.context';
import { getShippingAddressDescription } from './youAddress.utils';
import { isTechFWA } from '../../../../checkout.utils';
import { usePickupPointLocateStore } from './yourAddress.hook';

const YourAddress: React.FC<IYourAddress> = ({
  userInfoTitle,
  billingData,
  shippingData,
  installationData,
  editAddressConfig,
  noteModalConfig,
  noteLabels,
  description,
  topTitle,
  disableAddNotes,
  showTechAddress,
}) => {
  const {
    installationAddress,
    shippingAddress,
    billingAddress,
    owningData,
    deliveryAdvance,
    pickupLocationStore,
    setShippingAddress,
    setBillingAddress,
    setInvoice,
    setPickupPoint,
  } = useCheckout();
  const { isTablet, isDesktop } = useDeviceType();
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [isOpenEditShipping, setIsOpenEditShipping] = useState(false);
  const [isOpenEditBilling, setIsOpenEditBilling] = useState(false);
  const { postalOffices, vodafoneStores, pickupPoints } = usePickupPointLocateStore({
    getData: pickupLocationStore.global.isSuccess,
  });
  const setModalHeight = (): number => (isTablet && 647) || (isDesktop && 676) || 1200;
  const { firstName, lastName } = owningData?.owningIndividual || {};
  const deliveryOptions: IDeliveryOptions[] = Array.isArray(deliveryAdvance?.deliveryOptions)
    ? deliveryAdvance?.deliveryOptions?.[0]?.details || []
    : deliveryAdvance?.deliveryOptions?.details;
  const hasShippingAddress = !!shippingAddress?.postalCode;
  const hasBillingAddress = !!billingAddress?.postalCode;
  const isDifferentAdd = isDifferentAddress(
    shippingAddress,
    billingAddress as IAddressDataObjectExtended
  );
  const isDifferentAddShippingInstallation = isDifferentAddress(
    shippingAddress,
    installationAddress as IAddressDataObjectExtended
  );
  const isDifferentAddBillingInstallation = isDifferentAddress(
    billingAddress,
    installationAddress as IAddressDataObjectExtended
  );

  // region CMS
  const mapData = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_MAP) as IMapCms;
  const deliveryInformation = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_DELIVERY_INFORMATION
  ) as IDeliveryInformation;
  const editManual = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_EDIT_ADDRESS
  ) as IEditAddressCmsConfig;
  const editAddressStepper = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_EDIT_ADDRESS_STEPPER
  ) as IEditAddressCmsConfig;
  const multipleOptions = deliveryInformation?.deliveryinformation?.addressChange?.options;
  const editAddressStepperModalConfig = organizeEditAddressFixed(
    editAddressStepper?.editaddressconfig
  );
  const { mapConfig, mapEditConfig } = organizeMap(mapData);
  const editAddressOptionConfig = organizeEditAddressOptionFixed(deliveryInformation, isTechFWA());
  const editManualAddressConfig = organizeEditAddressManual(editManual);
  // endregion

  const toggleModalNote = (link?: string) => {
    if (link) {
      trackLink(link);
    }
    setIsOpenNote((prevState) => !prevState);
  };

  const onChangePickupPoint = (pickupPointLocal: IPickUpLocation) => {
    setPickupPoint(pickupPointLocal);
  };

  const onClickEditShipping = () => {
    pickupLocationStore?.startDeliveryLocateAndPickupPoint?.();
    trackLink(!hasShippingAddress ? shippingData?.labelEdit : shippingData?.changeAddress);
    setIsOpenEditShipping(true);
  };

  const onClickEditBilling = () => {
    trackLink(!hasBillingAddress ? billingData?.changeAddress : billingData?.labelEdit);
    setIsOpenEditBilling(true);
  };

  const renderStepperModal = () => (
    <StepperAddressModal
      address={shippingAddress}
      shippingAddress={shippingAddress}
      setShippingAddress={setShippingAddress}
      postalOffices={(postalOffices as any) || []}
      pickupPoints={(pickupPoints as any) || []}
      vodafoneStores={(vodafoneStores as any) || []}
      onChangePickupPoint={onChangePickupPoint}
      isOpen={isOpenEditShipping}
      handleClose={() => setIsOpenEditShipping(false)}
      deliveryOptions={
        deliveryOptions && deliveryOptions?.length > 0
          ? deliveryOptions
          : [
              {
                code: IAddressType.S,
                description: 'CASA',
                isSelected: false,
              },
            ]
      }
      userData={{ lastName, firstName }}
      setPickupPoint={setPickupPoint}
      billingAddress={billingAddress}
      setBillingAddress={setBillingAddress}
      setInvoice={setInvoice}
      multipleOptions={multipleOptions || []}
      editAddressStepperModalConfig={editAddressStepperModalConfig}
      mapConfig={mapConfig}
      mapConfigEdit={mapEditConfig}
      editAddressOptionsConfig={editAddressOptionConfig}
      manualAddressConfig={editManualAddressConfig}
      isLoading={
        pickupLocationStore.global.isLoading ||
        pickupLocationStore?.searchLocateStore.isLoading ||
        pickupLocationStore?.searchPickupLocations.isLoading ||
        pickupLocationStore?.searchPostalOffice.isLoading ||
        deliveryAdvance?.isLoading
      }
    />
  );

  const renderEditModals = () => (
    <>
      <VfModal height={setModalHeight()} isOpen={isOpenNote} handleClose={toggleModalNote}>
        <NoteModalDelivery
          handleClose={toggleModalNote}
          shippingAddress={shippingAddress}
          setShippingAddress={setShippingAddress}
          noteModalConfig={noteModalConfig}
        />
      </VfModal>
      <VfModal
        height={setModalHeight()}
        isOpen={isOpenEditBilling}
        handleClose={() => setIsOpenEditBilling(false)}
      >
        <EditAddress
          firstName={firstName}
          lastName={lastName}
          handleClose={() => setIsOpenEditBilling(false)}
          origin={false}
          billingAddress={billingAddress}
          setBillingAddress={setBillingAddress}
          setShippingAddress={setShippingAddress}
          setInvoice={setInvoice}
          setPickupPoint={setPickupPoint}
          shippingAddress={shippingAddress}
          cmsConfig={editAddressConfig}
          manualAddressConfig={editManualAddressConfig}
        />
      </VfModal>
    </>
  );

  const renderDifferentShippingBilling = () => (
    <>
      <BottomTitle>
        {!hasBillingAddress ? billingData?.matchAddress : billingData?.title}
      </BottomTitle>
      {!hasBillingAddress && <LabelCta>{billingData?.noMatch}</LabelCta>}
      {hasBillingAddress && !!billingAddress && (
        <UserInfo>
          <p className="username">{`${firstName} ${lastName}`}</p>
          <p>{billingData?.addressData?.address}</p>
          <p>{billingData?.addressData?.city}</p>
          <p>{`${billingData?.addressData?.zipCode}-${billingData?.addressData?.province}`}</p>
        </UserInfo>
      )}
      <BillingCta onClick={onClickEditBilling}>
        {!hasBillingAddress ? billingData?.changeAddress : billingData?.labelEdit}
      </BillingCta>
    </>
  );

  const renderSameShippingBilling = (address: IOtherInformation, isBillingAddress?: boolean) => (
    <>
      <BottomTitle>{address?.matchAddress}</BottomTitle>
      <LabelCta>{address?.noMatch}</LabelCta>
      <BillingCta
        onClick={() => {
          trackLink(`${address?.changeAddress}`);
          if (isBillingAddress) setIsOpenEditBilling(true);
          else setIsOpenEditShipping(true);
        }}
      >
        {address?.changeAddress}
      </BillingCta>
    </>
  );

  const renderNotes = () => {
    if (disableAddNotes) return null;
    return (
      <DeliveryCta
        onClick={() => {
          toggleModalNote?.(
            !shippingAddress.note ? noteLabels?.noteTitle : noteLabels?.noteTitleAfterEdit
          );
        }}
      >
        {!shippingAddress.note ? noteLabels?.noteTitle : noteLabels?.noteTitleAfterEdit}
      </DeliveryCta>
    );
  };

  // region Render WITH TECH ADDRESS / WITHOUT TECH ADDRESS

  const renderAddressWithTechAddress = () => (
    <>
      {/* INSTALLATION ADDRESS */}
      <InstallationAddress>{userInfoTitle || ''}</InstallationAddress>
      {installationAddress && (
        <UserInfo style={{ textTransform: 'capitalize' }}>
          <p>{getShippingAddressDescription(installationAddress)}</p>
          <p>{installationData?.addressData?.address}</p>
          <p>{installationData?.addressData?.city}</p>
          <p>{`${installationData?.addressData?.zipCode}-${installationData?.addressData?.province}`}</p>
          {installationAddress.note ? (
            <p>
              Note: <span className="note">{`${installationAddress.note}`}</span>
            </p>
          ) : (
            ''
          )}
        </UserInfo>
      )}
      {renderNotes()}
      {/* SHIPPING ADDRESS */}
      <>
        <Divider />
        <BottomTitle>
          {!isDifferentAddShippingInstallation ? shippingData?.matchAddress : shippingData?.title}
        </BottomTitle>
        {!isDifferentAddShippingInstallation && <LabelCta>{shippingData?.noMatch}</LabelCta>}
        {isDifferentAddShippingInstallation && !!shippingAddress && (
          <UserInfo style={{ textTransform: 'capitalize' }}>
            <p>
              {getShippingAddressDescription(
                shippingAddress,
                shippingData,
                owningData?.owningIndividual
              )}
            </p>
            <p>{shippingData?.addressData?.address}</p>
            <p>{shippingData?.addressData?.city}</p>
            <p>{`${shippingData?.addressData?.zipCode}-${shippingData?.addressData?.province}`}</p>
          </UserInfo>
        )}
        <BillingCta onClick={onClickEditShipping}>
          {!hasShippingAddress ? shippingData?.labelEdit : shippingData?.changeAddress}
        </BillingCta>
        <Divider />
      </>
      {/* BILLING ADDRESS */}
      {!isDifferentAddBillingInstallation && renderSameShippingBilling(billingData, true)}
      {isDifferentAddBillingInstallation && renderDifferentShippingBilling()}
      {renderEditModals()}
      {deliveryOptions && renderStepperModal()}
    </>
  );

  const renderAddressWithoutTechAddress = () => (
    <>
      {/* <BottomTitle>{!hasShippingAddress ? shippingData?.title : shippingData?.title}</BottomTitle>
      {!hasShippingAddress && <LabelCta>{shippingData?.noMatch}</LabelCta>}
      {hasShippingAddress && !!shippingAddress && (
        <UserInfo style={{ textTransform: 'capitalize' }}>
          <p>{getShippingAddressDescription(shippingAddress)}</p>
          <p>{shippingData?.addressData?.address}</p>
          <p>{shippingData?.addressData?.city}</p>
          <p>{`${shippingData?.addressData?.zipCode}-${shippingData?.addressData?.province}`}</p>
          {shippingAddress.note ? (
            <p>
              Note: <span className="note">{`${shippingAddress.note}`}</span>
            </p>
          ) : (
            ''
          )}
        </UserInfo>
      )} */}
      {/* INSTALLATION ADDRESS */}
      <InstallationAddress>{userInfoTitle || ''}</InstallationAddress>
      {installationAddress && (
        <UserInfo style={{ textTransform: 'capitalize' }}>
          <p>{getShippingAddressDescription(installationAddress)}</p>
          <p>{installationData?.addressData?.address}</p>
          <p>{installationData?.addressData?.city}</p>
          <p>{`${installationData?.addressData?.zipCode}-${installationData?.addressData?.province}`}</p>
          {installationAddress.note ? (
            <p>
              Note: <span className="note">{`${installationAddress.note}`}</span>
            </p>
          ) : (
            ''
          )}
        </UserInfo>
      )}
      {renderNotes()}
      {/* <BillingCta onClick={onClickEditShipping}>
        {!hasShippingAddress ? shippingData?.labelEdit : shippingData?.changeAddress}
      </BillingCta> */}
      <Divider />
      {!isDifferentAdd && renderSameShippingBilling(billingData, true)}
      {isDifferentAdd && renderDifferentShippingBilling()}
      {renderEditModals()}
      {deliveryOptions && renderStepperModal()}
    </>
  );

  // endregion

  const checkRender = () => {
    if (showTechAddress) {
      return renderAddressWithTechAddress();
    }
    return renderAddressWithoutTechAddress();
  };

  return (
    <Container>
      <TopContent>
        <Title>{topTitle}</Title>
        <Description>{description}</Description>
      </TopContent>
      {checkRender()}
    </Container>
  );
};

export default YourAddress;
