import type { SVGProps } from 'react';
import { memo } from 'react';

const VisaLight = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H44C46.2091 32 48 30.2091 48 28V4C48 1.79086 46.2091 0 44 0Z"
      fill="#2A2A6C"
    />
    <path
      d="M23.8602 11.39L21.8602 20.64H19.4602L21.4602 11.39H23.8602ZM33.9202 17.39L35.1802 13.92L35.9102 17.39H33.9202ZM36.5902 20.67H38.8102L36.8702 11.42H34.8702C34.4301 11.424 34.0357 11.6922 33.8702 12.1L30.2802 20.67H32.8002L33.2902 19.29H36.3002L36.5902 20.67ZM30.3502 17.67C30.3602 15.18 27.0002 15 27.0002 14C27.0002 13.67 27.3202 13.32 28.0002 13.23C28.8095 13.1499 29.6253 13.2916 30.3602 13.64L30.7802 11.64C30.0638 11.3713 29.3053 11.2325 28.5402 11.23C26.1802 11.23 24.5402 12.49 24.5402 14.29C24.5402 15.62 25.7302 16.36 26.6302 16.8C27.5302 17.24 27.8802 17.55 27.8702 17.95C27.8702 18.57 27.1302 18.85 26.4402 18.86C25.589 18.8709 24.7492 18.6644 24.0002 18.26L23.5702 20.26C24.4176 20.5908 25.3204 20.7571 26.2302 20.75C28.7402 20.75 30.3902 19.51 30.4002 17.59L30.3502 17.67ZM20.4902 11.36L16.6102 20.61H14.0002L12.0902 13.22C12.0566 12.8682 11.8399 12.5603 11.5202 12.41C10.7694 12.0518 9.97649 11.7897 9.16016 11.63L9.22016 11.36H13.2902C13.841 11.3577 14.3116 11.7563 14.4002 12.3L15.4002 17.65L17.8902 11.36H20.4902Z"
      fill="white"
    />
  </svg>
);

export default memo<SVGProps<SVGSVGElement>>(VisaLight);
