import { ICMSWidgetType, IPageProps, ITaggingCMS } from '@vfit/business-data-access';
import {
  PageLoadingContextProvider,
  RetargetContextProvider,
  TrackingPageContextProvider,
} from '@vfit/business-data-access';
import { Footer, Header } from '@vfit/shared/components';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { withProtected } from './hoc/withProtected';
import { PageLayout } from './layout/pageLayout.style';
import Widgets from '../Widgets/widgets';
import { IBusinessProductHeroBanner } from '../WidgetProductHeroBanner/widgetProductHeroBanner.models';
import { IBusinessSliderProductDSMultiple } from '../WidgetSliderProductDSMultiple/widgetSliderProductDSMultiple.models';

const Product = ({ page, isApp }: IPageProps) => {
  const { elements, haveHeader, isHeaderSmall, haveFooter } = page?.params || {};
  const { header, tagging } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);

  const productWidget = Object.values(elements ?? {}).find(
    (widget) =>
      widget.type === ICMSWidgetType.PRODUCT_HERO_BANNER ||
      widget.type === ICMSWidgetType.DS_HERO_BANNER
  ) as IBusinessProductHeroBanner;

  // widget necessario esclusivamente per le DS multiple
  const productDSMultipleWidget = Object.values(elements ?? {}).find(
    (widget) => widget.type === ICMSWidgetType.SLIDER_PRODUCT_DS_PDP
  ) as IBusinessSliderProductDSMultiple;

  const dsMultipleProducts = productDSMultipleWidget ? { multipleDS: productDSMultipleWidget } : {};

  return (
    // questo div serve per aggiungere padding a fondo pagina quando presente lo sticky
    <div>
      {haveHeader && !isApp && <Header onTrack={tagging} header={header} lite={isHeaderSmall} />}
      <PageLayout className="noPadding">
        <PageLoadingContextProvider>
          <TrackingPageContextProvider
            selectedProduct={{ ...productWidget?.product, ...dsMultipleProducts }}
            tagging={elements?.tagging as ITaggingCMS}
            layer="pdpDataLayer"
            type={page?.params?.pageType}
          >
            <RetargetContextProvider versionAEM={productWidget?.product?.version}>
              <Widgets aemElements={elements ?? {}} />
            </RetargetContextProvider>
          </TrackingPageContextProvider>
        </PageLoadingContextProvider>
      </PageLayout>
      {haveFooter && !isApp && <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />}
    </div>
  );
};

const ProductProtected = withProtected()(Product);

export default ProductProtected;
