import {IPortabilityCMS} from "@vfit/consumer/data-access";

const organizePortabilityNumberCard = (
  portabilityNumberCardCMS: IPortabilityCMS
) => ({
  title: portabilityNumberCardCMS?.portability?.telephone?.title || '',
  description:
    portabilityNumberCardCMS?.portability?.telephone?.description || '',
  label: portabilityNumberCardCMS?.portability?.selectInput?.telephone || '',
});

const organizePortabilityError = (
  portabilityNumberCardCMS: IPortabilityCMS
) => ({
  titleError: portabilityNumberCardCMS?.portability?.errorModal?.title || '',
  messageError: portabilityNumberCardCMS?.portability?.errorModal?.message || ''
});

export { organizePortabilityError, organizePortabilityNumberCard };
