import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';
import { ICSSProps } from './checkboxItem.models';

const circleWithSize = (size: number) => `
  width: ${size}px;
  height: ${size}px;
  border-radius: 50%;
`;

export const Container = styled.div<ICSSProps>`
  border-radius: 6px;
  padding: 20px 16px;
  display: flex;
  cursor: pointer;
  ${(props) =>
    props.index === props.colorIndex
      ? `border: 2px solid ${colors.$00697c};`
      : `border: 1px solid ${colors.$bebebe};`}

  input[type='radio'] {
    ${circleWithSize(20)};
    appearance: none;
    border: 1px solid ${colors.$bebebe};
    outline: none;
  }

  input[type='radio']::before {
    ${circleWithSize(12)};
    content: '';
    display: block;
    border-color: ${colors.$00697c};
  }

  input[type='radio']:checked {
    border-color: ${colors.$00697c};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type='radio']:checked::before {
    background: ${colors.$00697c};
    flex-shrink: 0;
  }
`;

export const CheckboxContainer = styled.div<ICSSProps>`
  display: flex;
  align-items: center;
  ${(props) => `align-self: ${props.align}`}
`;
