import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IFindCallerResponse,
  IIndividualResponse,
  ILinkContactAndCustomerPayload,
} from '@vfit/consumer/data-access';
import { IAddressResponse, IPickUpLocation } from '@vfit/shared/models';

/**
 * This method is used to invoke the POST of linkContactAndCustomer service request.
 * @param payload
 * @param customOptions
 */
const linkContactAndCustomerService = (
  payload: ILinkContactAndCustomerPayload,
  customOptions?: CustomOptions
): Promise<{}> =>
  nextClient.post(API.LINK_CONTACT_CUSTOMER, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run linkContactAndCustomerService using the IPickUpLocation.
 * @param pickupPoint
 * @returns
 */
export const useLinkContactAndCustomer = (pickupPoint?: IPickUpLocation) => {
  const queryClient = useQueryClient();

  return useMutation(
    'linkContactAndCustomer',
    (addressIdPayload?: string) => {
      // Get addressID from deliveryCreatedAddress data saved into local storage.
      let addressId: string;
      if (addressIdPayload) {
        addressId = addressIdPayload;
      } else if (pickupPoint?.storeId) {
        addressId = pickupPoint?.locationAddress?.id;
      } else {
        const createdAddress: IAddressResponse | boolean =
          getFromLocalStorageByKey('deliveryCreatedAddress');
        addressId = createdAddress ? (createdAddress as IAddressResponse)?.id : '';
      }

      // Get contactID from individual data saved into local storage.
      const individual: IIndividualResponse = getFromLocalStorageByKey('individual');
      const contactObjId = (individual as IIndividualResponse)?.contactObjId;

      // Get customerID from customerData data saved into local storage.
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCallerInactive: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      LoggerInstance.debug('customerData: ', customerData);
      const customerId =
        (customerData as GetCustomerResponse[])?.[0]?.id ||
        findCallerInactive?.customerRef?.id ||
        '';

      const payload: ILinkContactAndCustomerPayload = {
        addressId,
        customerId,
        contactObjId,
      };

      return linkContactAndCustomerService(payload);
    },
    {
      onSuccess: (data: {}) => {
        localStorage.setItem('linkContactAndCustomer', JSON.stringify(data));
        queryClient.setQueryData('linkContactAndCustomer', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - linkContactAndCustomer: `, error);
        queryClient.setQueryData(
          'linkContactAndCustomer',
          errorMock(`linkContactAndCustomer`, error)
        );
        localStorage.setItem(
          `linkContactAndCustomer`,
          JSON.stringify(errorMock(`linkContactAndCustomer`, error))
        );
      },
    }
  );
};
