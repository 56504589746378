import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ButtonSlide } from '@vfit/shared/atoms';
import {
  CMS_CONFIG,
  IPersonalInfoCMS,
  checkIsApp,
  retrieveProduct,
  retrieveProductTechnology,
  useCmsConfig,
  useGetFindCaller,
  LOCKIN_OFFER_COOKIE,
  START_FROM_LOCKIN,
  deleteStartFromLockinId,
  getSelectedOfferIds,
  usePage,
} from '@vfit/consumer/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { SoftManagerService } from '@vfit/consumer/providers';
import { VfModal } from '@vfit/shared/components';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { API, PAGES, setItemCookie, AppMobile } from '@vfit/shared/data-access';
import { IFindCallerObject, IPersonalDataObject } from '@vfit/shared/models';
import { ICommonData } from '../../../StepperStateCards/stepperStateCards.models';
import { DEFAULT_FIND_CALLER, useCoverageToolModalProvider } from '../../coverageToolModal.context';
import NameFiscalCode from '../../../PersonalDataNewGraphic/nameSurnameFiscalCode';
import {
  ButtonWrapper,
  ModalDescription,
  ModalTitle,
  ModalWrap,
  ButtonDiv,
} from './coverageToolCheckNumberCard.style';
import { IModalConfig } from '../../coverageToolModal.models';
import {
  organizeCoverageToolCheckNumberCard,
  organizeNameFiscalCode,
  organizeRetriveFiscalCode,
} from './coverageToolCheckNumberCard.utils';

const CoverageToolCheckNumberCard = ({ handleGoNextSlide, handleOnClose }: ICommonData) => {
  const queryClient = useQueryClient();
  const { products: allProducts } = usePage();
  const { coverageLockInUser, setFindCaller, setCoverageLockInUser } =
    useCoverageToolModalProvider();
  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const INIT_MODAL_CONFIG: IModalConfig = {
    isOpen: false,
    title: '',
    description: '',
    subDesc: undefined,
    firstActionLabel: '',
    firstAction: () => {},
    secondActionLabel: '',
    secondAction: () => {},
  };
  const product = retrieveProduct();
  const [modalConfig, setModalConfig] = useState<IModalConfig>(INIT_MODAL_CONFIG);
  const { modalError, checkNumberProps } = organizeCoverageToolCheckNumberCard(dataFromCms);
  const { legacyPopup } = organizeNameFiscalCode(dataFromCms, product);
  const fiscalCodeConfigCms = organizeRetriveFiscalCode(dataFromCms);

  const {
    data: getFindCallerByNameAndSurnameResponse,
    isLoading: isLoadingGetFindCaller,
    refetch,
  } = useGetFindCaller(
    {
      fiscalCode: coverageLockInUser?.owningIndividual?.fiscalCode || '',
    },
    {
      enabled: false,
    }
  );

  const pageProduct = {
    product_category: 'fixed',
    product_id: `${product?.offerId}`,
    product_name: product?.slug,
    product_price: product?.price && removeCurrency(product.price),
    product_quantity: '1',
  };

  const opts = {
    event_category: 'journey',
    page_subsection: 'lock-in user data',
    page_section: 'fixed offers',
    page_type: 'form',
    journey_name: 'verify lock-in',
    product_technology: retrieveProductTechnology(),
  };

  const { trackView } = useTracking({
    event: ['journey_start'],
    event_label: 'lock-in user data',
    opts,
    pageProduct,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
  });

  const onClickEdit = () => {
    resetData(queryClient, ['findCaller']);
    sessionStorage.removeItem('uxfauthorization');
    setModalConfig(INIT_MODAL_CONFIG);
  };

  const onClickDiscover = () => {
    setItemCookie(
      LOCKIN_OFFER_COOKIE,
      {
        firstName: coverageLockInUser?.owningIndividual?.firstName || '',
        lastName: coverageLockInUser?.owningIndividual?.lastName || '',
        fiscalCode: coverageLockInUser?.owningIndividual?.fiscalCode || '',
      },
      0.021,
      false,
      'privati.vodafone.it'
    );
    localStorage.removeItem(START_FROM_LOCKIN);
    deleteStartFromLockinId();
    setModalConfig(INIT_MODAL_CONFIG);
    if (handleGoNextSlide) {
      handleGoNextSlide({}, true);
    }
  };

  const checkUser = () => {
    if (getFindCallerByNameAndSurnameResponse?.resultInfo === 'existingLegacyCustomer') {
      trackView({
        event_name: 'journey_end',
        event_label_track: 'coexistence pop up',
        event_category: 'journey',
        page_type: 'ko',
        tracking_type: 'view',
        journey_name: 'verify lock-in',
        page_subsection: 'lock-in match result',
      });
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        title: legacyPopup?.legacyPopupTitle,
        description: legacyPopup?.legacyPopupMessage,
        onCloseAction: () => {
          handleOnClose?.();
        },
        secondActionLabel: legacyPopup?.legacyPopupActionText,
        secondAction: () => {
          if (legacyPopup?.legacyPopupUrl) {
            window.location.href = legacyPopup?.legacyPopupUrl;
          }
        },
      });
      return;
    }
    const { offerId: filterdOfferId, cmsId } = getSelectedOfferIds();
    const isAlternativeOffer: boolean = product?.offerId != filterdOfferId;
    const mmProductRedirect = allProducts?.find(
      (productForSlug) => productForSlug?.cmsId == cmsId && !productForSlug?.isLockInProduct
    );
    const individualRef = getFindCallerByNameAndSurnameResponse?.individualRef;
    const isOTPNeeded =
      getFindCallerByNameAndSurnameResponse &&
      getFindCallerByNameAndSurnameResponse.customerHasActiveSubs &&
      individualRef &&
      Object.keys(individualRef).length > 0;
    if (isOTPNeeded && handleGoNextSlide) {
      handleGoNextSlide();
    } else if (
      product?.bypassLockInMM &&
      isAlternativeOffer &&
      mmProductRedirect &&
      !AppMobile.checkIsApp()
    ) {
      onClickDiscover();
    } else {
      trackView({
        event_name: 'journey_end',
        event_label_track: 'lock-in not available for number',
        event_category: 'journey',
        page_type: 'ko',
        tracking_type: 'view',
        journey_name: 'verify lock-in',
        page_subsection: 'lock-in match result',
      });
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        title: modalError.title || '',
        description: modalError.description || '',
        firstActionLabel: modalError.editLabel || '',
        onCloseAction: () => handleOnClose?.(),
        firstAction: () => onClickEdit(),
        secondActionLabel: modalError.submitLabel || '',
        secondAction: () => onClickDiscover(),
      });
    }
  };

  useEffect(() => {
    resetData(queryClient, ['findCaller']);
    setFindCaller(DEFAULT_FIND_CALLER);
  }, []);

  useEffect(() => {
    const findCaller =
      localStorage.getItem('findCaller') &&
      JSON.parse(localStorage.getItem('findCaller') as string);
    if (getFindCallerByNameAndSurnameResponse && findCaller) {
      setFindCaller({ ...getFindCallerByNameAndSurnameResponse } as unknown as IFindCallerObject);
      checkUser();
    }
  }, [getFindCallerByNameAndSurnameResponse]);

  const onSubmit = () => {
    refetch();
  };

  const onChangeData = (data: IPersonalDataObject, isValid?: boolean) => {
    const { owningIndividual: owningIndividualData } = data;
    const {
      firstName: firstNameData,
      lastName: lastNameData,
      fiscalCode: fiscalCodeData,
    } = owningIndividualData;
    const { owningIndividual } = coverageLockInUser;
    const { firstName, lastName, fiscalCode } = owningIndividual;
    if (coverageLockInUser) {
      setCoverageLockInUser({
        ...data,
      });
    }
    if (firstNameData !== firstName || lastNameData !== lastName || fiscalCodeData !== fiscalCode) {
      if (!isValid) {
        setCoverageLockInUser({
          ...data,
          isValid: false,
          isLogged: false,
        });
      }
    }
  };

  return (
    <>
      <NameFiscalCode
        data={coverageLockInUser}
        onChangeData={onChangeData}
        isLoading={isLoadingGetFindCaller}
        cmsConfig={fiscalCodeConfigCms}
      />
      <ButtonWrapper>
        <ButtonSlide
          disabled={isLoadingGetFindCaller || !coverageLockInUser.isValid}
          label={checkNumberProps.goNext}
          onClick={onSubmit}
          borderColor="#262626"
          isApp={checkIsApp()}
          labelColor="#262626"
          hoverColor="#262626"
          hoverTextColor="#fff"
          clickColor="#7e7e7e"
          clickTextColor="#fff"
          name="action_checkNumber"
        />
      </ButtonWrapper>
      <VfModal
        isOpen={modalConfig.isOpen}
        handleClose={() => {
          if (modalConfig.onCloseAction) {
            modalConfig.onCloseAction();
            return;
          }
          setModalConfig(INIT_MODAL_CONFIG);
        }}
      >
        <ModalWrap>
          <div>
            <ModalTitle>{modalConfig.title}</ModalTitle>
            <ModalDescription>{modalConfig.description}</ModalDescription>
          </div>
          <ButtonDiv>
            {modalConfig.firstActionLabel && modalConfig.firstAction && (
              <ButtonSlide
                label={modalConfig.firstActionLabel}
                onClick={modalConfig.firstAction}
                borderColor="#262626"
                isApp={checkIsApp()}
                labelColor="#262626"
                hoverColor="#262626"
                hoverTextColor="#fff"
                clickColor="#7e7e7e"
                clickTextColor="#fff"
                name="action_first_coverageToolCheckNumber"
              />
            )}
            {modalConfig.secondActionLabel && modalConfig.secondAction && (
              <ButtonSlide
                label={modalConfig.secondActionLabel}
                onClick={modalConfig.secondAction}
                isApp={checkIsApp()}
                buttonColor="#e60000"
                labelColor="#fff"
                hoverColor="#bd0000"
                hoverTextColor="#fff"
                clickColor="#a10000"
                clickTextColor="#fff"
                name="action_second_coverageToolCheckNumber"
              />
            )}
          </ButtonDiv>
        </ModalWrap>
      </VfModal>
    </>
  );
};

export default CoverageToolCheckNumberCard;
