import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';

const FullPageLayout = styled.div`
  min-height: 100vh;
  padding-top: 50px;
  background-color: ${colors.$ffffff};

  @media (min-width: ${breakpoints.desktop}) {
    background-color: ${colors.$f2f2f2};
    padding-top: 160px;
  }
`;

export { FullPageLayout };

