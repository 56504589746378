import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import {
  IPatchCustomerPayloadItem,
  GetCustomerResponse,
  errorMock,
  ErrorCodes,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IPatchItem } from './patchCustomer.models';

/**
 * This method is used to retrieve the correct path to update the customerData.
 * @param index
 * @param type
 * @returns
 */
const createPath = (index: number, type: string): string => {
  switch (type) {
    case 'email':
      return `/owningIndividual/contactMedium/${index}/emailAddress`;
    case 'number':
      return `/owningIndividual/contactMedium/${index}/phoneNumber`;
    case 'identificationNumber':
      return `/owningIndividual/identification/${index}/identificationNumber`;
    case 'identificationType':
      return `/owningIndividual/identification/${index}/identificationType`;
    case 'expirationDate':
      return `/owningIndividual/identification/${index}/expirationDate`;
    case 'nationality':
      return `/owningIndividual/identification/${index}/nationality`;
    case 'formattedAddress1':
      return `/owningIndividual/contactMedium/${index}/formattedAddress1`;
    case 'formattedAddress3':
      return `/owningIndividual/contactMedium/${index}/formattedAddress3`;
    case 'streetType':
      return `/owningIndividual/contactMedium/${index}/streetType`;
    case 'streetName':
      return `/owningIndividual/contactMedium/${index}/streetName`;
    case 'city':
      return `/owningIndividual/contactMedium/${index}/city`;
    case 'postalCode':
      return `/owningIndividual/contactMedium/${index}/postalCode`;
    case 'streetNumber':
      return `/owningIndividual/contactMedium/${index}/streetNumber`;
    case 'externalId':
      return `/owningIndividual/contactMedium/${index}/externalId`;
    case 'stateOrProvince':
      return `/owningIndividual/contactMedium/${index}/stateOrProvince`;
    case 'street':
      return `/owningIndividual/contactMedium/${index}/street`;
    case 'istatCode':
      return `/owningIndividual/contactMedium/${index}/istatCode`;
    case 'homeZone':
      return `/owningIndividual/contactMedium/${index}/homeZone`;
    case 'longitude':
      return `/owningIndividual/contactMedium/${index}/longitude`;
    case 'latitude':
      return `/owningIndividual/contactMedium/${index}/latitude`;
    default:
      return '';
  }
};

/**
 * Organize the payload items
 * @param updatedValue
 * @param modify
 * @param operation
 * @param index
 * @returns
 */
const payloadOrganizer = (
  updatedValue: string,
  modify: string,
  operation: string,
  index: number
): IPatchCustomerPayloadItem => {
  let payload: IPatchCustomerPayloadItem;

  payload = {
    op: operation,
    path: createPath(index, modify),
  };

  switch (modify) {
    case 'email': {
      if (operation === 'replace') {
        payload = { ...payload, value: updatedValue };
      }
      break;
    }
    case 'number': {
      if (operation === 'replace') {
        payload = { ...payload, value: updatedValue };
      }
      break;
    }
    case 'identificationType': {
      if (operation === 'replace') {
        payload = { ...payload, value: updatedValue };
      }
      break;
    }
    case 'identificationNumber': {
      if (operation === 'replace') {
        payload = { ...payload, value: updatedValue };
      }
      break;
    }
    case 'expirationDate': {
      if (operation === 'replace') {
        payload = { ...payload, value: updatedValue };
      }
      break;
    }
    case 'nationality': {
      if (operation === 'replace') {
        payload = { ...payload, value: updatedValue };
      }
      break;
    }
    case 'formattedAddress1':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'formattedAddress3':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'streetType':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'streetName':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'city':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'postalCode':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'streetNumber':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'externalId':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'stateOrProvince':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'street':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'istatCode':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'homeZone':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'longitude':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    case 'latitude':
      payload = {
        ...payload,
        ...(operation === 'replace' && { value: updatedValue }),
      };
      break;
    default:
      break;
  }

  return payload;
};

/**
 * This method calls the service which patch the customer contacts
 * email and mobile phone
 * @param customerId
 * @param payload
 * @param customOptions
 * @returns
 */
const patchCustomerService = (
  customerId: string,
  payload: Array<IPatchCustomerPayloadItem>,
  customOptions?: CustomOptions
) =>
  nextClient.patch(`${API.CUSTOMER}/${customerId}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<GetCustomerResponse>;

/**
 * This method edit the customer data.
 * @returns
 */
export const usePatchCustomer = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['patchCustomer'],
    (patchItems: IPatchItem[]) => {
      const findCaller = getFromLocalStorageByKey('findCaller');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      let customerId =
        findCaller &&
        (findCaller?.resultInfo === 'existingNextCustomer' ||
          findCaller?.customerHasActiveSubs === false) &&
        findCaller?.customerRef &&
        findCaller?.customerRef?.id;
      if (!customerId && customerData) customerId = customerData?.[0]?.id;
      const payload = [];
      patchItems.forEach((item: IPatchItem) => {
        payload.push(payloadOrganizer(item.updatedValue, item.type, item.operation, item.index));
      });
      LoggerInstance.debug('patchCustomerService payload :', payload);
      return patchCustomerService(customerId, payload);
    },
    {
      onSuccess: (data: GetCustomerResponse) => {
        localStorage.setItem('patchCustomer', JSON.stringify(data));
        queryClient.setQueryData('patchCustomer', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - patchCustomerService:', error);
        queryClient.setQueryData('patchCustomer', error);
        localStorage.setItem(
          'patchCustomer',
          JSON.stringify(errorMock('patchCustomer', error, ErrorCodes.PATCH_CUSTOMER))
        );
      },
    }
  );
};
