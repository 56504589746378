import { useEffect } from 'react';
import {
  CMS_CONFIG,
  COOKIE_CART,
  errorMock,
  IGenericErrorCMS,
  IPersonalInfoCmsMobile,
  IPortabilityMobileCMS,
  IShoppingCartResponse,
  useCmsConfig,
  useDeleteShoppingCartMutation,
} from '@vfit/consumer/data-access';
import {
  API,
  getFromLocalStorageByKey,
  PAGES,
  setItemCookie,
  trackView,
} from '@vfit/shared/data-access';
import {
  errorManager,
  ErrorService,
  ITrackError,
  LoggerInstance,
  toastManager,
} from '@vfit/shared/data-access';
import { organizeSimTypeCard } from '../../components/SimTypeSelectionCard/simTypeSelectionCard.utils';
import { ISimTypeCardData } from '../../components/SimTypeSelectionCard/simTypeSelection.models';
import { IUseCheckoutErrors } from './useCheckoutErrors.models';
import { getTrackError } from '../../checkout.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { ENABLE_DEBUG } from '../../../iBuyMobile.utils';

export const useCheckoutErrors = ({
  handleClose,
  replaceBillingAddress,
  createBillingAddress,
}: IUseCheckoutErrors) => {
  const {
    product,
    currentStepKey,
    customerDeliveryBaseInfo,
    familyPlus,
    cartAsyncInfo,
    customerAsyncInfo,
    customerFlow,
    deliveryAdvance,
    authorizationAsyncInfo,
    checkoutErrors,
    setCheckoutErrors,
  } = useCheckout();
  const {
    mutate: deleteCartMutation,
    isSuccess: isSuccessDeleteCart,
    isLoading: isLoadingDeleteCart,
    isError: isErrorDeleteCart,
  } = useDeleteShoppingCartMutation();
  const personalDataMobileCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const errorPortability = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY_MOBILE
  ) as IPortabilityMobileCMS;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const organizedDataForSim = organizeSimTypeCard(
    personalDataMobileCMS,
    errorPortability
  ) as ISimTypeCardData;

  const showAndTrackError = (
    _genericError: IGenericErrorCMS,
    _handleClose?: () => void,
    error?: ITrackError,
    title?: string,
    message?: string,
    actionEvent?: () => void,
    actionText?: string,
    secondActionEvent?: () => void,
    secondActionText?: string,
    isButtonSticky = false,
    disableDeleteCart = false,
    disableTrack = false,
    invertButtons = false,
    icon?: string
  ) => {
    const trackErrorRes = error ? getTrackError(error, currentStepKey?.toLowerCase()) : undefined;

    const deleteCartClose = () => {
      _handleClose?.();
      window.location.reload();
    };

    const STATUS_DELETE_CART = ['500', '403', '404'];
    const actualCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
    const cartId = actualCart?.id;
    const ENABLE_DELETE_CART =
      (!disableDeleteCart &&
        cartId &&
        error?.error?.status &&
        STATUS_DELETE_CART.includes(error?.error?.status)) ||
      error?.error?.status === 'timeout';
    if (ENABLE_DELETE_CART && !isSuccessDeleteCart && !isLoadingDeleteCart && !isErrorDeleteCart) {
      deleteCartMutation({
        shoppingCartId: cartId,
        customOptions: {
          headers: {
            Authorization: sessionStorage.getItem('uxfauthorization') || '',
          },
        },
      });
    }

    if (trackErrorRes) {
      if (ENABLE_DEBUG) {
        toastManager.handleToast({
          title: trackErrorRes.page_error || 'no error found',
        });
      }
      LoggerInstance.log('Track', trackErrorRes);
      trackView(trackErrorRes);
    }
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: title || _genericError?.genericerror?.title || '',
      message: message || _genericError?.genericerror?.description || '',
      onClose: ENABLE_DELETE_CART ? deleteCartClose : _handleClose,
      actionEvent: ENABLE_DELETE_CART ? deleteCartClose : actionEvent || _handleClose,
      actionText: actionText || _genericError?.genericerror?.buttonLabel || '',
      disableTrack: !!trackErrorRes || disableTrack,
      secondActionEvent,
      secondButtonText: secondActionText,
      isButtonSticky,
      invertButtons,
      ...(icon && { icon }),
      ...(trackErrorRes && {
        trackError: {
          ...trackErrorRes,
        },
      }),
      opts: {
        product_name: product?.slug || '',
      },
    });
  };

  // region ERRORS

  useEffect(() => {
    if (customerDeliveryBaseInfo?.modifySimDevice.isError) {
      LoggerInstance.log('Error:modifysimdevice_error');
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleClose,
        { ...customerDeliveryBaseInfo?.modifySimDevice?.error, isBlocking: true },
        organizedDataForSim?.genericsError?.title,
        organizedDataForSim?.genericsError?.description,
        handleClose,
        organizedDataForSim.genericsError.buttonLabel
      );
    }
  }, [customerDeliveryBaseInfo?.modifySimDevice]);

  useEffect(() => {
    if (customerDeliveryBaseInfo?.deliveryBase.isError) {
      LoggerInstance.log('Error:deliverybase_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...customerDeliveryBaseInfo?.deliveryBase?.error,
        isBlocking: true,
      });
    }
  }, [customerDeliveryBaseInfo?.deliveryBase]);

  useEffect(() => {
    if (customerFlow?.billingAccount.isError) {
      LoggerInstance.log('Error: billingaccount_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...customerFlow?.billingAccount?.error,
        isBlocking: true,
      });
    }
  }, [customerFlow?.billingAccount]);

  useEffect(() => {
    if (customerAsyncInfo?.termsAndConditions.isError) {
      LoggerInstance.log('Error: termsandcondition_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...customerAsyncInfo?.termsAndConditions?.error,
        isBlocking: true,
      });
    }
  }, [customerAsyncInfo?.termsAndConditions]);

  useEffect(() => {
    if (customerAsyncInfo?.retrieveMultilines.isError) {
      LoggerInstance.log('Error: retrieveMultilines_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...customerAsyncInfo?.retrieveMultilines?.error,
        isBlocking: true,
      });
    }
  }, [customerAsyncInfo?.retrieveMultilines]);

  useEffect(() => {
    if (authorizationAsyncInfo?.payMean?.isError) {
      LoggerInstance.log('Error: paymean_error');
      checkoutErrors?.showAndTrackError?.(
        {
          ...genericError,
          genericerror: {
            ...genericError.genericerror,
            title: 'Ops!',
            description:
              'Si è verificato un errore durante il recupero dei pagamenti. Riprovare in seguito',
          },
        },
        handleClose,
        {
          ...authorizationAsyncInfo?.payMean?.error,
          isBlocking: true,
        }
      );
    }
  }, [authorizationAsyncInfo?.payMean]);

  useEffect(() => {
    if (deliveryAdvance?.isError) {
      LoggerInstance.log('Error: deliveryadvance_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...deliveryAdvance?.error,
        isBlocking: true,
      });
    }
  }, [deliveryAdvance]);

  useEffect(() => {
    if (cartAsyncInfo?.simTypeSelection?.isError) {
      LoggerInstance.log('Error: simtypeselection_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...cartAsyncInfo?.simTypeSelection?.error,
        isBlocking: true,
      });
    }
  }, [cartAsyncInfo]);

  useEffect(() => {
    if (familyPlus?.updateCart?.isError) {
      LoggerInstance.log('Error: familyPlus_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...familyPlus?.updateCart?.error,
        isBlocking: true,
      });
    }
  }, [familyPlus]);

  useEffect(() => {
    if (replaceBillingAddress?.isError) {
      LoggerInstance.log('Error: replacebillingaddress_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...errorMock('replacebilling', {
          url: 'replacebilling',
          status: '500',
          statusText: '500',
        }),
        isBlocking: true,
      });
    }
  }, [replaceBillingAddress]);

  useEffect(() => {
    if (createBillingAddress?.isError) {
      LoggerInstance.log('Error: createbilling_error');
      checkoutErrors?.showAndTrackError?.(genericError, handleClose, {
        ...errorMock('createbilling', {
          url: 'createbilling',
          status: '500',
          statusText: '500',
        }),
        isBlocking: true,
      });
    }
  }, [createBillingAddress]);

  // endregion

  // if is success delete cart from popup, remove cookie for delete cart on reload
  useEffect(() => {
    if (isSuccessDeleteCart) setItemCookie(COOKIE_CART, '', 0, false, 'privati.vodafone.it');
  }, [isSuccessDeleteCart]);

  useEffect(() => {
    setCheckoutErrors({
      ...checkoutErrors,
      showAndTrackError,
    });
  }, [currentStepKey]);

  return null;
};
