import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  errorMock,
  getLastPaymentMethod,
  IBillingAccountResponse,
  IShoppingCartResponse,
} from '@vfit/consumer/data-access';
import { IAuthorizationResponse, IPayMeanServiceResponse } from '@vfit/shared/models';
import {
  IOrderItemPaymentsValidateIbans,
  ISelectedPayMeanValidateIbans,
  IValidateIbansPayload,
  IValidateIbansRespose,
} from './validateIbans.models';

/**
 * API service to validate user's payment with IBAN
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
export const validateIbansService = (
  cartId: string,
  payload: IValidateIbansPayload,
  customOptions?: CustomOptions
): Promise<IValidateIbansRespose> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.VALIDATE_IBANS}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Method to add objectItems to the payload array
 * @param array
 * @param ids
 * @param payMean
 */
const addPayloadObject = (array: any, ids: string[], payMean: ISelectedPayMeanValidateIbans) => {
  // eslint-disable-next-line array-callback-return
  ids.map((el: any, index: number) => {
    if (!el) return;
    if (index === 0)
      array.push({
        orderActionId: el,
        orderItemOnBillPayment: {
          payMeanPurpose: 'onBill', // todo --> TO BE RETRIEVE FROM AUTHORIZATION RESPONSE
          selectedPayMean: payMean,
        },
        lob: 'WL',
      });
    if (index > 0) {
      array.push({
        ...array[index - 1],
        orderActionId: `${array[index - 1].orderActionId},${el}`,
      });
    }
  });
};

/**
 * This method is a custom Mutation hook to handle the validateIbans Service.
 * @returns
 */
export const useValidateIbansFixed = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'validateIbans',
    () => {
      let payload: IValidateIbansPayload;
      // Get the shoppingCart obj from storage
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const payMeanData: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');
      const authorization: IAuthorizationResponse =
        getFromLocalStorageByKey('authorizationRecurring');
      const cartId = shoppingCart?.id;
      const cartItems = shoppingCart?.cartItem?.[0];
      const payMeans = getLastPaymentMethod(payMeanData);
      const dataSimId: string = cartItems?.dataSim?.[0]?.id;
      const fixedVoiceId = cartItems?.fixedVoice?.[0]?.id;
      const internetId = cartItems?.internet?.[0]?.id;

      if (authorization && payMeans) {
        const selectedPayMean = {
          id: payMeans?.id,
          bankCode: payMeans?.bankCode,
          branchCode: payMeans?.branchCode,
          holderName: payMeans?.holderName,
          ibanNumber: payMeans?.ibanNumber,
          accountNumber: payMeans?.accountNumber,
          isVerified: false,
          ppbId: authorization.authorization?.payMean?.ppbId,
          object_type: authorization.authorization?.payMean?.object_type,
          type: authorization.authorization?.payMean?.type,
        };

        const orderItemPayments: IOrderItemPaymentsValidateIbans[] = [];
        addPayloadObject(orderItemPayments, [dataSimId, fixedVoiceId, internetId], selectedPayMean);

        payload = {
          orderPayment: {
            barId: billingAccount.id,
            orderItemPayments,
          },
        };
      }

      return !!payload && validateIbansService(cartId, payload);
    },
    {
      onSuccess: (data: IValidateIbansRespose) => {
        LoggerInstance.debug('SUCCESS', data);
        queryClient.setQueryData('validateIbansQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - validateIbansQuery: `, error);
        queryClient.setQueryData('validateIbansQuery', errorMock(`validateIbansQuery`));
        localStorage.setItem(`validateIbansQuery`, JSON.stringify(errorMock(`validateIbansQuery`)));
      },
    }
  );
};

/**
 * This method is a custom Mutation hook to handle the validateIbans Service.
 * @returns
 */
export const useValidateIbansMobile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'validateIbans',
    () => {
      let payload: IValidateIbansPayload;
      // Get the shoppingCart obj from storage
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const payMeanData: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');
      const authorization: IAuthorizationResponse =
        getFromLocalStorageByKey('authorizationRecurring');
      const cartId = shoppingCart?.id;
      const cartItems = shoppingCart?.cartItem?.[0];
      const cartItemId = cartItems?.id;
      const payMeans = getLastPaymentMethod(payMeanData);

      if (authorization && payMeans) {
        const selectedPayMean = {
          id: payMeans?.id,
          bankCode: payMeans?.bankCode,
          branchCode: payMeans?.branchCode,
          holderName: payMeans?.holderName,
          ibanNumber: payMeans?.ibanNumber,
          accountNumber: payMeans?.accountNumber,
          isVerified: false,
          ppbId: authorization.authorization?.payMean?.ppbId,
          object_type: authorization.authorization?.payMean?.object_type,
          type: authorization.authorization?.payMean?.type,
        };

        const orderItemPayments: IOrderItemPaymentsValidateIbans[] = [];
        addPayloadObject(orderItemPayments, [cartItemId], selectedPayMean);

        payload = {
          orderPayment: {
            barId: billingAccount.id,
            orderItemPayments: [
              {
                orderActionId: cartItemId,
                orderItemOnBillPayment: {
                  payMeanPurpose: 'onBill',
                  selectedPayMean,
                },
              },
            ],
          },
        };
      }

      return !!payload && validateIbansService(cartId, payload);
    },
    {
      onSuccess: (data: IValidateIbansRespose) => {
        queryClient.setQueryData('validateIbansQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - validateIbansQuery: `, error);
        queryClient.setQueryData('validateIbansQuery', errorMock(`validateIbansQuery`, error));
        localStorage.setItem(`validateIbansQuery`, JSON.stringify(errorMock(`validateIbansQuery`)));
      },
    }
  );
};
