import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  padding: 0 20px;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 74px;
  }
`;

export const InnerContainer = styled.div`
  margin-top: 159px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 234px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 244px;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(35, 38)}
  color: ${colors.$e60000};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }
`;

export const Subtitle = styled(Paragraph)`
  margin-top: 16px;
  font-family: ${fonts.exbold};
  ${pxToCssFont(24, 28)}

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 24px;
    ${pxToCssFont(24, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 20px;
  }
`;

export const Description = styled(Paragraph)`
  margin-top: 16px;
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 8px;
    ${pxToCssFont(20, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(20, 26)}
  }
`;

