import { ILoadingError } from './loadingError.models';
import { Container, Title, Description, InnerContainer, Subtitle } from './loadingError.style';

const LoadingError = ({ loadingErrorConfig }: ILoadingError) => {
  return (
    <Container>
      <InnerContainer>
        <Title>{loadingErrorConfig?.title || 'Ops!'}</Title>
        <Subtitle>{loadingErrorConfig?.subtitle || 'Si è verificato un errore'}</Subtitle>
        <Description>
          {loadingErrorConfig?.description ||
            "Si è verificato un errore durante l'elaborazione del tuo ordine. Ci scusiamo per il disagio"}
        </Description>
      </InnerContainer>
    </Container>
  );
};

export default LoadingError;

