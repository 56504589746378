import { useQueryClient } from 'react-query';
import { TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { IDevice } from '@vfit/consumer/data-access';

interface ITaggingHomepage {
  taggingOptions?: TrackingOptions;
  allDevices?: IDevice[];
  isDisabledInitialTrack?: boolean;
}

const TaggingHubDevice = ({
  taggingOptions,
  allDevices,
  isDisabledInitialTrack,
}: ITaggingHomepage) => {
  const client = useQueryClient();
  const pageDevices = SoftManagerService(client).getSoftDevicesByDevices(
    allDevices?.filter((device) => device?.devicedetail?.isSellable) || []
  );
  useTracking({
    event: ['product_view'],
    opts: {
      ...taggingOptions,
      product_category: pageDevices.product_category || '',
    },
    pageProduct: pageDevices,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
  });

  return null;
};

export default TaggingHubDevice;
