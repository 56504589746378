import { IPersonalInfoCMS, IProduct } from '@vfit/consumer/data-access';

export const organizeCoverageToolCheckNumberCard = (props: IPersonalInfoCMS) => {
  const prefix = props?.personalinfo?.lockIn;
  const modalError = {
    title: prefix?.modalNewUser?.title || 'Non sei ancora un nostro cliente?',
    description:
      prefix?.modalNewUser?.description ||
      'Il tuo numero non sembra essere di un nostro cliente, ma non ti preoccupare, abbiamo altre offerte pronte a stupirti!',
    editLabel: prefix?.modalNewUser?.buttonModifyNumber || 'MODIFICA NUMERO',
    submitLabel: prefix?.modalNewUser?.action?.title || "SCOPRI L'OFFERTA MIGLIORE PER TE",
  };

  const checkNumberProps = {
    title: prefix?.personalInfo?.title || 'Ciao!',
    description:
      prefix?.personalInfo?.description ||
      'Inserisci qui il tuo nome, cognome e numero di telefono Vodafone, per scoprire l’offerta che ti abbiamo riservato.',
    firstNameLabel: prefix?.personalInfo?.nameLabel || 'Nome',
    lastNameLabel: prefix?.personalInfo?.surnameLabel || 'Cognome',
    phoneNumberLabel: 'Numero di telefono',
    ctaLabel: prefix?.personalInfo?.buttonLabel || 'verifica il numero di telefono',
    goNext: 'Prosegui',
  };

  return { modalError, checkNumberProps };
};

export const organizeNameFiscalCode = (
  nameFiscalCodeCms: IPersonalInfoCMS,
  product?: IProduct | null
) => ({
  title: nameFiscalCodeCms?.personalinfo?.fiscalCode?.title || '',
  description: nameFiscalCodeCms?.personalinfo?.fiscalCode?.description || '',
  firstNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.firstName || '',
  lastNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.lastName || '',
  fiscalCodeLable: nameFiscalCodeCms?.personalinfo?.selectInput?.fiscalCode || '',
  buttonLabel: nameFiscalCodeCms?.personalinfo?.generalInfo?.fcMissingLabel || '',
  legacyPopup: {
    legacyPopupIsOpen: true,
    legacyPopupTitle:
      product?.commonOverride?.legacyCtc?.title ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.title ||
      '',
    legacyPopupMessage:
      product?.commonOverride?.legacyCtc?.description ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.description ||
      '',
    legacyPopupActionText:
      product?.commonOverride?.legacyCtc?.buttonLabel ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.buttonLabel ||
      '',
    legacyPopupUrl:
      product?.commonOverride?.legacyCtc?.url ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.url ||
      '',
  },
});

export const organizeRetriveFiscalCode = (nameFiscalCodeCms: IPersonalInfoCMS) => ({
  retriveFiscalCode: {
    title: nameFiscalCodeCms?.personalinfo?.fiscalCode?.title || '',
    description: nameFiscalCodeCms?.personalinfo?.fiscalCode?.description || '',
    firstName: nameFiscalCodeCms?.personalinfo?.selectInput?.firstName || '',
    lastName: nameFiscalCodeCms?.personalinfo?.selectInput?.lastName || '',
    birthDate: nameFiscalCodeCms?.personalinfo?.selectInput?.birthdate || '',
    gender: nameFiscalCodeCms?.personalinfo?.selectInput?.gender || '',
    nationality: nameFiscalCodeCms?.personalinfo?.selectInput?.country || '',
    birthPlace: nameFiscalCodeCms?.personalinfo?.selectInput?.city || '',
    errorMessage: nameFiscalCodeCms?.personalinfo?.generalInfo?.errorMessage?.error2 || '',
    birthNation: nameFiscalCodeCms?.personalinfo?.selectInput?.birthNation || 'Nazione di nascita',
    buttonLabel: nameFiscalCodeCms?.personalinfo?.fiscalCode?.buttonLabel || '',
  },
  nameFiscalCodeCard: {
    title: nameFiscalCodeCms?.personalinfo?.generalInfo?.title || '',
    titleReadOnly:
      nameFiscalCodeCms?.personalinfo?.generalInfo?.titleReadOnly ||
      nameFiscalCodeCms?.personalinfo?.generalInfo?.title ||
      '',
    description: nameFiscalCodeCms?.personalinfo?.generalInfo?.description || '',
    descriptionReadOnly:
      nameFiscalCodeCms?.personalinfo?.generalInfo?.descriptionReadOnly ||
      nameFiscalCodeCms?.personalinfo?.generalInfo?.description ||
      '',
    firstNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.firstName || '',
    lastNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.lastName || '',
    fiscalCodeLable: nameFiscalCodeCms?.personalinfo?.selectInput?.fiscalCode || '',
    buttonLabel: nameFiscalCodeCms?.personalinfo?.generalInfo?.fcMissingLabel || '',
  },
});
