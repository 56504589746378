import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { useMutation, useQueryClient } from 'react-query';
import { errorMock, IShoppingCartResponse, isMobileProduct } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IReserveEquipmentPayload, IReserveEquipmentResponse } from './reserveEquipment.models';

/**
 * reserveEquipmentService method to reserve the router modem
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
export const reserveEquipmentService = (
  cartId: string,
  payload: IReserveEquipmentPayload,
  customOptions?: CustomOptions
): Promise<IReserveEquipmentResponse> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.RESERVE_EQUIPMENT}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * This is a custom hook to manage the reserveEquipmentService.
 * //TODO Rework
 * @returns
 */
export const useReserveEquipmentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'reserveEquipmentMutation',
    () => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      const cartType = isMobileProduct()
        ? shoppingCart?.cartItem?.[0]?.device?.type
        : shoppingCart?.cartItem?.[0]?.type;
      const deviceOfferingId = isMobileProduct()
        ? shoppingCart?.cartItem?.[0]?.device?.offering?.id
        : shoppingCart?.cartItem?.[0]?.offering?.id;

      const payload: IReserveEquipmentPayload = {
        cartType,
        deviceOfferingId,
      };
      return reserveEquipmentService(cartId, payload);
    },
    {
      onSuccess: (data: IReserveEquipmentResponse) => {
        localStorage.setItem('reserveEquipment', JSON.stringify(data));
        queryClient.setQueryData('reserveEquipment', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - reserveEquipment: `, error);
        queryClient.setQueryData('reserveEquipment', errorMock(`reserveEquipment`, error));
        localStorage.setItem(
          `reserveEquipment`,
          JSON.stringify(errorMock(`reserveEquipment`, error))
        );
      },
    }
  );
};
