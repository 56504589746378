import { IError } from "@vfit/consumer/data-access";

export enum IOfferCategorizationType {
  ATL = 'atl',
  BTL = 'btl',
}

export interface ISimTypeCardData {
  cardTitle: string;
  cardDescription: string;
  modalInfo: {
    textLink: string;
    titleModal?: string;
    descriptionModal?: string;
  };
  migrationCodeError: IError;
  genericsError: IError;
  numberError: IError;
}
