import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-bottom: 40px;
`

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 80%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    div {
      width: 300px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row-reverse;
    justify-content: center;
  }
`

export const SpecsDiv = styled.div`
  margin-top: 16px;
`

export const ParagraphContainer = styled.p`
  margin: 0;
`;

export const Title = styled(ParagraphContainer)`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const OfferPrice = styled(Title)`
  .rec {
    ${pxToCssFont(24, 28)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 8px;

    .rec {
      ${pxToCssFont(24, 30)}
    }
  }
`;

export const OfferDescription = styled.div`
  div {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;

    p,
    span {
      ${pxToCssFont(16, 22)}
      color: ${colors.$262626};
      margin:0;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 4px;
    }
  }
`;

export const ProductCardSpecContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: baseline;
  justify-content: space-between;
`;

export const ProductCardSpecTitle = styled.p`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFontSize(18)}
  color: ${colors.$262626};
  text-transform: uppercase;
`;

export const ProductCardSpecItem = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0;
  margin-bottom: 8px;

  div {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(20, 30)}
    color: ${colors.$262626};
    margin: 0;

    img {
      width: 19px;
    }
  }

  svg {
    margin-right: 16px;
    width: 16px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 16px;
  }
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      ${pxToCssFont(36, 45)}
    }
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;
