import { ITermsAndCondition } from '@vfit/consumer/data-access';

export const organizeTermsCard = (cmsData: ITermsAndCondition) => {
  const cmsCard= cmsData?.termsandconditionsmo?.card || undefined;
  return(
    {
      title:cmsCard?.title || '',
      description: cmsCard?.description || '',
      documents: cmsData.termsandconditionsmo.modals?.map((modals) => ({
        text: modals?.title || '',
        content: modals?.description || '',
        pdfUrl: modals?.url || '',
        pdfLabel: modals?.buttonLabel || '',
      })),
      documentsCheck: cmsCard.conditions,
      titleModal: cmsCard?.modalError?.title || '',
      messageModal: cmsCard?.modalError?.message || '',
    }
  )
};
