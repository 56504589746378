import { useQueryClient } from 'react-query';
import { PageProduct, TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import {setTrackLink} from "@vfit/shared/data-access";

interface ITaggingHomepage {
  taggingOptions?: TrackingOptions;
  pageProduct?: PageProduct;
  isDisabledInitialTrack?: boolean;
}

const TaggingHub = ({ taggingOptions, pageProduct, isDisabledInitialTrack }: ITaggingHomepage) => {
  const client = useQueryClient();
  const {trackView} = useTracking({
    event: ['product_view'],
    opts: {
      ...taggingOptions,
      product_category: pageProduct?.product_category || '',
      product_target_segment: Array.isArray(pageProduct?.product_id) ? pageProduct?.product_id.map(() => "consumer") : "consumer"
    },
    pageProduct,
    visitorTrackingOpts: SoftManagerService(client).getUser(),
    disableInitialTrack: isDisabledInitialTrack || false,
  });
  setTrackLink(trackView);
  return null;
};

export default TaggingHub;
