import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { IProductCharacteristicLightRequestPayload } from './updateProductCharacteristicLight.models';
import { IShoppingCartResponse } from '../shopping-cart';

/**
 * Recall service POST /updateProductCharacteristicLight
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
const updateProductCharacteristicLightService = (
  cartId: string,
  payload: IProductCharacteristicLightRequestPayload,
  customOptions?: CustomOptions
): Promise<{}> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.UPDATE_PRODUCT_CHARACTERISTIC_LIGHT}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run updateProductCharacteristicLightService.
 * @returns
 */
export const useUpdateProductCharacteristicLight = () => {
  const queryClient = useQueryClient();

  // const consensus: any | undefined = queryClient.getQueryData('consensusMutation');

  const shoppingCartQuery: IShoppingCartResponse | undefined =
    queryClient.getQueryData('shoppingCart');
  const shoppingCartSaved = localStorage.getItem('shoppingCart');
  const shoppingCart: IShoppingCartResponse =
    (!!shoppingCartSaved && JSON.parse(shoppingCartSaved as string)) || shoppingCartQuery;

  const cartId = shoppingCart && shoppingCart?.id;

  return useMutation(
    ['doUpdateProductCharacteristicLight'],
    (optionToReconsider: string) => {
      const payload: IProductCharacteristicLightRequestPayload = {
        attributeMap: [
          {
            key: 'IS_CONTRACT_SIGNED',
            value: 'Y',
          },
        ],
        optionToReconsider: optionToReconsider.replace(/^./, optionToReconsider[0].toUpperCase()),
        runRules: false,
      };
      return updateProductCharacteristicLightService(cartId, payload);
    },
    {
      onSuccess: (data: {}) => {
        localStorage.setItem('updateProductCharacteristicLight', JSON.stringify(data));
        queryClient.setQueryData('updateProductCharacteristicLight', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - updateProductCharacteristicLight: `, error);
        queryClient.setQueryData(
          'updateProductCharacteristicLight',
          errorMock(`updateProductCharacteristicLight`, error)
        );
        localStorage.setItem(
          `updateProductCharacteristicLight`,
          JSON.stringify(errorMock(`updateProductCharacteristicLight`, error))
        );
      },
    }
  );
};
