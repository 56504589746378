import { UseQueryResult } from 'react-query';
import {
  errorMock,
  GetCustomerResponse,
  IFindCallerResponse,
  IUserService,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  INextError,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { ICampaignResponse } from './campaigns.models';

const getCampaigns = (audienceId: string, audienceType: string, customOptions?: CustomOptions) =>
  nextClient.get(`${API.CAMPAIGNS}`, {
    searchParams: {
      salesChannel: 'selfService',
      audienceId,
      audienceType,
      limit: 9,
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<ICampaignResponse>;

export const useCampaignsInfo = (
  customOptions?: CustomOptions
): UseQueryResult<ICampaignResponse[], INextError> => {
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'campaignsData';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const user: IUserService = getFromLocalStorageByKey('user');
      const customerSaved = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const customerData: GetCustomerResponse[] = user?.customer || customerSaved;
      const audienceId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const audienceType = 'CUSTOMER_ID';
      return getCampaigns(audienceId as string, audienceType, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: customOptions?.enabled !== undefined ? customOptions.enabled : false,
      onSuccess: (data: ICampaignResponse[]) => {
        localStorage.setItem('campaignsData', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        localStorage.setItem(`campaignsData`, JSON.stringify(errorMock(`campaignsData`, error)));
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<ICampaignResponse[], INextError>;
};

export const useCampaignsInfoMobile = (
  customOptions?: CustomOptions
): UseQueryResult<ICampaignResponse[], INextError> => {
  const customer = getFromLocalStorageByKey('customerData');
  const audienceId = customer?.[0]?.id;
  const audienceType = 'CUSTOMER_ID';

  const keysDependency: string | string[] = customOptions.keyDependency
    ? customOptions.keyDependency
    : 'campaignsData';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => getCampaigns(audienceId as string, audienceType, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: customOptions?.enabled !== undefined ? customOptions.enabled : false,
      onSuccess: (data: ICampaignResponse[]) => {
        localStorage.setItem('campaignsData', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        localStorage.setItem(`campaignsData`, JSON.stringify(errorMock(`campaignsData`, error)));
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<ICampaignResponse[], INextError>;
};
