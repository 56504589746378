import { Container } from './switchToggle.style';
import { ISwitchToggle } from './switchToggle.model';

const SwitchToggle: React.FC<ISwitchToggle> = ({ onSwitch, disabled, isActive }) => (
  <Container>
    <input disabled={disabled} onChange={onSwitch} type="checkbox" checked={isActive} />
    <span />
  </Container>
);

export default SwitchToggle;
