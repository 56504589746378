import { breakpoints, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const VoucherInsertionManagmentCard = styled.div`
  display: flex;
  flex-direction: row;
  padding: 72px 32px 32px;

  @media (max-width: ${breakpoints.desktop}) {
    flex-direction: column-reverse;
    padding: 72px 40px 64px;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 48px 24px 28px;
  }
`;

export const StyledDivider = styled.div`
  margin: 0 2rem 0 0;
`;
export const VoucherLeftCard = styled.div`
  border: 0.5px grey solid;
  border-radius: 20px;
  padding: 0 20px 20px;

  h5 {
    color: red;
    ${pxToCssFont(20, 30)}
    margin: 24px 0 8px;

    @media (max-width: ${breakpoints.desktop}) {
      ${pxToCssFont(20, 26)};
    }
  }

  h3 {
    ${pxToCssFont(36, 45)};

    @media (max-width: ${breakpoints.desktop}) {
      ${pxToCssFont(24, 30)};
    }

    @media (max-width: ${breakpoints.tablet}) {
      ${pxToCssFont(24, 28)};
    }

    margin: 0 0 16px;

    p {
      margin: 0 0 16px;
    }
  }
`;
export const StyledProductName = styled.span`
  ${pxToCssFont(18, 24)}
  font-weight: 700;

  @media (max-width: ${breakpoints.desktop}) {
    ${pxToCssFont(14, 24)};
  }
`;

export const VoucherRightTitle = styled.h3`
  ${pxToCssFont(36, 45)};
  margin: 0 0 8px;

  @media (max-width: ${breakpoints.desktop}) {
    ${pxToCssFont(30, 38)};
  }
`;

export const StyledVoucherApplyDescription = styled.div`
  ${pxToCssFont(18, 24)};
  margin: 0;
  padding-bottom: ${pxToRem(16)};

  > p:first-child {
    margin: 0;
  }
`;

export const FieldsContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    display: flex;
    flex-direction: column;

    button {
      width: 65%;
      align-self: center;
    }
  }
`;

