import { useTracking } from '@vfit/shared/data-access';
import { setTrackLink } from '@vfit/shared/data-access';
import { getUserInfo, getTagging } from '../../../../checkout.utils';

export const useVoucherInsertionCardTagging = () => {
  const taggingMobile = getTagging('voucher input');
  const { trackView } = useTracking({
    event: ['checkout_step8.2'],
    event_label: 'insert voucher',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'voucher input');

  return null;
};
