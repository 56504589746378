import { useEffect, useState } from 'react';
import {
  useSearchPickUpLocations,
  useLocateStore,
  hasPostalOfficeOption,
  hasPickupStoreOption,
} from '@vfit/consumer/data-access';
import { IAddressType, IDeliveryOptions } from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IUseDeliveryLocateAndPickupPoint } from './useDeliveryLocateAndPickupPoint.models';

const DEFAULT_DELIVERY_LOCATE_STORE: IUseDeliveryLocateAndPickupPoint = {
  startDeliveryLocateAndPickupPoint: () => {},
  global: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  searchPickupLocations: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
  searchLocateStore: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
  searchPostalOffice: {
    isLoading: false,
    isError: false,
    isSuccess: false,
  },
};

export const useDeliveryLocateAndPickupPoint = (): IUseDeliveryLocateAndPickupPoint => {
  const [useDeliveryLocateAndPickupPointResult, setUseDeliveryLocateAndPickupPointResult] =
    useState<IUseDeliveryLocateAndPickupPoint>(DEFAULT_DELIVERY_LOCATE_STORE);
  const [isSuccessPickupPoint, setIsSuccessPickupPoint] = useState(false);
  const [isSuccessPostalOffice, setIsSuccessPostalOffice] = useState(false);
  const [isSuccessLocateStore, setIsSuccessLocateStore] = useState(false);
  const {
    mutate: searchPickupLocationMutation,
    isSuccess: isSuccessSearchPickupLocationApi,
    isLoading: isLoadingSearchPickupLocation,
    isError: isErrorSearchPickupLocation,
  } = useSearchPickUpLocations();
  const {
    mutate: searchPostalOfficeMutation,
    isSuccess: isSuccessPostalOfficeApi,
    isLoading: isLoadingPostalOffice,
    isError: isErrorPostalOffice,
  } = useSearchPickUpLocations();
  const {
    mutate: locateStoreMutation,
    isSuccess: isSuccessLocateStoreApi,
    isLoading: isLoadingLocateStore,
    isError: isErrorLocateStore,
  } = useLocateStore();

  const initRetrieveData = () => {
    const deliveryAdvance = getFromLocalStorageByKey('deliveryDetailsIsAdvance');
    const deliveryDetails: IDeliveryOptions[] =
      deliveryAdvance?.[0]?.deliveryItems?.[0]?.details || [];
    const searchPostalOfficeData = getFromLocalStorageByKey('searchPickUpLocations');
    const searchPickUpPointData = getFromLocalStorageByKey('searchPickUpStoreLocations');
    const locateStoreData = getFromLocalStorageByKey('locateStore');

    if (!deliveryDetails || deliveryDetails?.length === 0) {
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        global: {
          ...prevState.global,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      }));
    }

    if (hasPostalOfficeOption(deliveryDetails) && !searchPostalOfficeData) {
      if (!isSuccessPostalOfficeApi) {
        searchPostalOfficeMutation({ options: IAddressType.PO });
      }
    } else {
      setIsSuccessPostalOffice(true);
    }

    if (hasPickupStoreOption(deliveryDetails) && !searchPickUpPointData) {
      if (!isSuccessSearchPickupLocationApi) {
        searchPickupLocationMutation({ options: IAddressType.PP });
      }
    } else {
      setIsSuccessPickupPoint(true);
    }

    if (hasPickupStoreOption(deliveryDetails) && !locateStoreData) {
      if (!isSuccessLocateStoreApi) {
        locateStoreMutation(undefined);
      }
    } else {
      setIsSuccessLocateStore(true);
    }
  };

  useEffect(() => {
    if (isSuccessPickupPoint && isSuccessLocateStore && isSuccessPostalOffice) {
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        global: {
          ...prevState.global,
          isSuccess: true,
          isLoading: false,
          isError: false,
        },
      }));
    }
  }, [isSuccessPickupPoint, isSuccessPostalOffice, isSuccessLocateStore]);

  useEffect(() => {
    if (isLoadingPostalOffice) {
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchPostalOffice: {
          ...prevState.searchPostalOffice,
          isLoading: true,
        },
      }));
    } else if (isErrorPostalOffice) {
      setIsSuccessPostalOffice(true);
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchPostalOffice: {
          ...prevState.searchPostalOffice,
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessPostalOfficeApi) {
      setIsSuccessPostalOffice(true);
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchPostalOffice: {
          ...prevState.searchPostalOffice,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      }));
    }
  }, [isErrorPostalOffice, isLoadingPostalOffice, isSuccessPostalOfficeApi]);

  useEffect(() => {
    if (isLoadingSearchPickupLocation) {
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchPickupLocations: {
          ...prevState.searchPickupLocations,
          isLoading: true,
        },
      }));
    } else if (isErrorSearchPickupLocation) {
      setIsSuccessPickupPoint(true);
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchPickupLocations: {
          ...prevState.searchPickupLocations,
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessSearchPickupLocationApi) {
      setIsSuccessPickupPoint(true);
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchPickupLocations: {
          ...prevState.searchPickupLocations,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      }));
    }
  }, [
    isErrorSearchPickupLocation,
    isLoadingSearchPickupLocation,
    isSuccessSearchPickupLocationApi,
  ]);

  useEffect(() => {
    if (isLoadingLocateStore) {
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchLocateStore: {
          ...prevState.searchLocateStore,
          isLoading: true,
        },
      }));
    } else if (isErrorLocateStore) {
      setIsSuccessLocateStore(true);
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchLocateStore: {
          ...prevState.searchLocateStore,
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessLocateStoreApi) {
      setIsSuccessLocateStore(true);
      setUseDeliveryLocateAndPickupPointResult((prevState) => ({
        ...prevState,
        searchLocateStore: {
          ...prevState.searchLocateStore,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      }));
    }
  }, [isErrorLocateStore, isLoadingLocateStore, isSuccessLocateStoreApi]);

  useEffect(() => {
    if (useDeliveryLocateAndPickupPointResult.global.isLoading) {
      initRetrieveData();
    }
    if (useDeliveryLocateAndPickupPointResult.global.isSuccess) {
      setIsSuccessLocateStore(false);
      setIsSuccessPickupPoint(false);
      setIsSuccessPostalOffice(false);
    }
  }, [useDeliveryLocateAndPickupPointResult.global]);

  const startDeliveryLocateAndPickupPoint = () => {
    setUseDeliveryLocateAndPickupPointResult({
      ...useDeliveryLocateAndPickupPointResult,
      global: {
        isError: false,
        isSuccess: false,
        isLoading: true,
      },
    });
  };

  return {
    ...useDeliveryLocateAndPickupPointResult,
    startDeliveryLocateAndPickupPoint,
  };
};
