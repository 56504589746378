import { breakpoints, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: ${pxToRem(150)}; 
  display: flex;
  flex-direction: column;
  // border: 2px solid red;
  // border-radius: ${pxToRem(20)};
  align-items: center;
  height: ${pxToRem(389)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-top:  ${pxToRem(100)}; 
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-top:  ${pxToRem(20)}; 
  }

  h1 {
    ${pxToCssFont(36, 45)}
    font-family: ${fonts.exbold}
    font-weight: 400;
    margin-bottom: 0px;
  }

  p {
    ${pxToCssFont(18, 24)}
    font-family: ${fonts.regular}
    text-align: center;
    margin: ${pxToRem(15)}; 
  }

  span
  {
    font-family: ${fonts.vodafone}
    ${pxToCssFont(18, 24)}
    font-weight: 800;
    text-align: center;

  }
`;

export const ImageStyle = styled.img`
  height: auto;
  width: ${pxToRem(50)};
  margin-top: ${pxToRem(20)};
`;

export const DiscountAppliedBox = styled.div`
  width: ${pxToRem(400)};
  height: ${pxToRem(112)};
  padding: ${pxToRem(15)};
  margin: ${pxToRem(5, 20, 50, 20)};
  border-radius: ${pxToRem(5)};
  border: 1px solid #bebebe;

  @media (max-width: ${breakpoints.tablet}) {
    width: 90%;
    height: auto;
    margin: ${pxToRem(0, 15, 0, 15)};
  }

  h1 {
    ${pxToCssFont(18, 24)}
    font-family: ${fonts.vodafone};
    font-weight: 700;
    margin-left: ${pxToRem(9)};
  }
`;

export const PriceDiscount = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;

  p:nth-child(1) {
    ${pxToCssFont(22, 32)}
    font-family: ${fonts.vodafone};
    text-decoration: line-through;
    font-weight: 400;
    width: ${pxToRem(80)};
    height: ${pxToRem(32)};
    margin: ${pxToRem(15, 0, 15, 0)};
  }
  p:nth-child(3) {
    ${pxToCssFont(28, 36)}
    font-family: ${fonts.exbold};
    font-weight: 800;
    width: ${pxToRem(80)};
    height: ${pxToRem(36)};
    margin: ${pxToRem(11, 0, 10, 0)};
  }

  p:nth-child(2) {
    ${pxToCssFont(30, 48)}
    font-family: ${fonts.exbold};
    font-weight: 800;
    width: ${pxToRem(107)};
    height: ${pxToRem(48)};
    margin: ${pxToRem(5, 0, 10, 0)};
  }
`;

