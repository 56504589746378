import { CustomText } from '@vfit/shared/atoms';
import { Title, Description, DetailsContainer, Content } from '../detailsModals.style';
import { IModemProps } from './modemDetails.models';

const ModemDetails = ({ title, content }: IModemProps) => (
  <DetailsContainer>
    <Content>
      <Title>
        <CustomText text={title} />
      </Title>
      <Description>
        <CustomText text={content} />
      </Description>
    </Content>
  </DetailsContainer>
);

export default ModemDetails;
