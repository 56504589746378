import styled from 'styled-components';
import { colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div<{ color?: string; textColor?: string }>`
  width: 100%;
  background-color: ${({ color }) => color || colors.$00697c};
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 28)};
  color: ${({ textColor }) => textColor || colors.$ffffff};
`;
