import styled from 'styled-components';
import { breakpoints, fonts, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  flex-grow: 1;

  @media (min-width: ${breakpoints.desktop}) {
    width: 50%;
    align-items: flex-start;
  }
`;

export const Title = styled.h2`
  line-height: ${pxToRem(32.2)};
  margin: 0;
  font-family: ${fonts.exbold};
  font-weight: 500;
  font-size: ${pxToRem(28)};

  @media (min-width: ${breakpoints.desktop}) {
    line-height: ${pxToRem(48.6)};
    font-size: ${pxToRem(36)};
  }
`;

export const Description = styled.div`
  line-height: ${pxToRem(28)};
  font-family: ${fonts.light};
  font-size: ${pxToRem(20)};
  margin: 0;
  text-align: center;

  p {
    margin: 0;
    font-size: ${pxToRem(20)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    text-align: left;
    font-size: ${pxToRem(22)};
    line-height: 1.55;
  }
`;
