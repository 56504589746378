import styled from "styled-components";
import {breakpoints} from "@vfit/shared/themes";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: auto;
  padding: 32px 24px 32px 24px;
  background: linear-gradient(179.94deg, rgba(255, 255, 255, 0.8) 0.05%,
  rgba(255, 255, 255, 0.6) 23.01%, rgba(255, 255, 255, 0) 30.46%,
  rgba(255, 255, 255, 0.1) 30.46%);

  @media(min-width: ${breakpoints.tablet}){
    background: linear-gradient(89.69deg, rgba(255, 255, 255, 0.9) 0.28%,
    rgba(255, 255, 255, 0) 50.01%);
    padding: 40px 32px 40px 32px;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 80px;
    gap: 24px;
  };

  @media(min-width: ${breakpoints.desktop}) {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0) 50%);
    padding: 48px 80px 48px 80px;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
    margin-bottom: 72px;
  }
`;
