import React from 'react';
import { IRetrieveHowToModalCard } from '../../nameSurnameFiscalCode.models';
import { Title, Text, Wrapper } from './howToCard.style';

const HowToCard = ({ data }: { data: IRetrieveHowToModalCard }) => (
  <Wrapper>
    <Title>{data?.title}</Title>
    <Text 
    dangerouslySetInnerHTML={{
      __html: data?.description
    }}
    />
  </Wrapper>
);

export default HowToCard;

