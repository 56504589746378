import { useEffect, useState } from 'react';
import { IPartyPrivacyItem } from '@vfit/shared/models';

export const useConsentsFlow = () => {
  const [partyPrivacyItems, setPartyPrivacyItems] = useState<IPartyPrivacyItem[]>([]);

  const pdfSource = [
    'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf',
    'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf',
    'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf',
    'https://myvfapp-aem.vodafone.it/content/dam/myvf/mva10/cce-pdf/3556298.pdf',
  ];

  // TOFIX: Edit  this part and move ALL on CMS @Vign. (by code VFHAPPYLIGHT...)
  const mapData = () => {
    const retrieveMultilines = localStorage.getItem('retrieveMultiLines');
    const retrivedItems = retrieveMultilines ? JSON.parse(retrieveMultilines) : [];
    const mappedData: IPartyPrivacyItem[] = retrivedItems?.partyPrivacyItems?.map(
      (item: IPartyPrivacyItem, index: number) => ({
        ...item,
        selectedValue: item?.selectedValue || false,
        pdf: pdfSource[index],
        name: item.title || '',
        description: `${item.longDescription ? item.description : ''}`,
        longDescription: item.longDescription || item.description,
      })
    );

    return mappedData;
  };

  useEffect(() => {
    if (localStorage.getItem('retrieveMultiLines')) {
      setPartyPrivacyItems(mapData());
    }
  }, [localStorage.getItem('retrieveMultiLines')]);

  return { partyPrivacyItems };
};
