import { QueryClient } from 'react-query';
import { NextRouter } from 'next/router';
import {
  formatQueryParam,
  ICharacteristicDevice,
  IDevice,
  IGenericErrorCMS,
  ISku,
} from '@vfit/consumer/data-access';
import { getButtonActionByActionType, getHome } from '@vfit/shared/data-access';
import { IActionCustom, IActionType } from '@vfit/shared/models';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { IDeviceColor } from './devicesList.models';

/**
 *
 * @param devices
 */
const getDeviceCards = (devices: IDevice[]) =>
  devices.map((device) => {
    const { devicedetail } = device;
    const {
      image,
      deviceBrand,
      isAvailable,
      deviceName,
      lowestPrice,
      hub,
      haveMarketingLabel,
      tagLine,
      offerLabel,
    } = devicedetail;
    return {
      device,
      config: {
        image,
        brand: deviceBrand,
        title: deviceName,
        isOutOfStock: !isAvailable,
        outOfStockLabel: hub?.outOfStockLabel,
        price: lowestPrice,
        action: hub?.action,
        offerLabel,
        haveMarketingLabel,
        tagLine,
      },
    };
  });

/**
 * Method to compose url search params
 * @param model
 * @param characteristics
 */
const getDeviceUrlByModel = (model: ISku, characteristics: ICharacteristicDevice): string => {
  if (!model) return '';
  const { idColor, idSize, displayNameColor } = model;
  const { colors, sizes } = characteristics || {};
  if (!idColor || !idSize || colors?.elements?.length === 0 || sizes?.elements?.length === 0)
    return '';
  const foundedColor = colors?.elements?.find((c) => c.id == idColor);
  const foundedSize = sizes?.elements?.find((c) => c.id == idSize);
  if (!foundedSize?.value || !foundedColor?.value) return '';
  return new URLSearchParams({
    color: formatQueryParam(displayNameColor || foundedColor?.value),
    size: formatQueryParam(foundedSize?.value),
  }).toString();
};

/**
 * Method called on click action in device card
 * @param action
 * @param device
 * @param deviceCharacteristics
 * @param genericError
 * @param router
 * @param queryClient
 */
const clickDeviceButtonAction = (
  action: IActionCustom,
  device: IDevice,
  deviceCharacteristics: ICharacteristicDevice,
  genericError: IGenericErrorCMS,
  router: NextRouter,
  queryClient?: QueryClient
): boolean => {
  const { push } = router;

  const error = () => {
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
      title: genericError?.genericerror?.title || 'Attenzione',
      message:
        genericError?.genericerror?.description || 'Il prodotto selezionato non è disponibile',
      actionText: genericError?.genericerror?.buttonLabel || 'Capito',
      event: 'page_error',
      event_label: 'Generic Error',
    });
  };

  if (action.type != IActionType.SHOW_DETAIL) {
    getButtonActionByActionType(action, push, queryClient);
    return false;
  }
  const defaultDevice =
    device?.devicedetail?.models?.find((el) => el.isDefault) || device?.devicedetail?.models[0];
  if (!defaultDevice) {
    error();
    return false;
  }
  const deviceUrl = getDeviceUrlByModel(defaultDevice, deviceCharacteristics);
  if (!deviceUrl) {
    error();
    return false;
  }
  const basePath = window?.location?.pathname?.split('?')?.[0] || '';
  const url = `${getHome()}${basePath}/${device.devicedetail.slug}?${deviceUrl}`;
  // Router next push slow
  window.location.href = url;
  return false;
};

/**
 * Method to get all colors by device and char
 * @param device
 * @param deviceCharacteristics
 */
const getAllColors = (
  device: IDevice,
  deviceCharacteristics?: ICharacteristicDevice
): IDeviceColor[] => {
  if (!deviceCharacteristics || !deviceCharacteristics?.colors || !deviceCharacteristics?.sizes)
    return [];
  const { devicedetail } = device;
  return (
    devicedetail?.models?.map((dd) => ({
      exColor: dd.exColor,
      color: deviceCharacteristics?.colors?.elements?.find((el) => el.id == dd.idColor),
    })) || []
  );
};

/**
 * Method to update all cards height
 * @param ref
 */
const updateAllCardsHeight = (ref: HTMLDivElement) => {
  // eslint-disable-next-line no-param-reassign
  (ref as HTMLElement).style.gap = '32px';
  const allSlides = ref?.getElementsByClassName('card-info-container');
  const allHeight: number[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allSlides.length; i++) {
    if (allSlides[i]) {
      allHeight.push((allSlides[i] as HTMLElement)?.clientHeight);
    }
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allSlides.length; i++) {
    (allSlides[i] as HTMLElement).style.minHeight = `${Math.max(...allHeight)}px`;
  }
};

export {
  getDeviceCards,
  getDeviceUrlByModel,
  clickDeviceButtonAction,
  getAllColors,
  updateAllCardsHeight,
};
