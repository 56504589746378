import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ParagraphContainer = styled.p`
  margin: 0;
`;

export const Title = styled(ParagraphContainer)`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const OffersList = styled.ul`
  margin-top: 32px;
  margin-bottom: 40px;
  padding: 0;

  li {
    list-style: none;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 24px;
    margin-bottom: 52px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 32px;
    margin-bottom: 48px;
  }
`;

export const Paragraph = styled(ParagraphContainer)`
  margin-top: 16px;
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(20, 26)}
  color: ${colors.$0d0d0d};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

export const ParagraphBold = styled(Paragraph)`
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  color: #333;
  margin-top: 18px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 32px;
  }
`;

export const Spacer = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 16px;
  }
`;

export const ButtonSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;

  div {
    width: 100%;
    max-width: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 40px;
    flex-direction: row;
    gap: 30px;

    div {
      width: 100%;
      max-width: 258px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      width: 100%;
      max-width: 296px;
    }
  }
`;

