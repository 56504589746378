import { SoftManagerService } from '@vfit/consumer/providers';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export function useSoftManager() {
  const client = useQueryClient();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window['softManager'] = {
        ...SoftManagerService(client),
      };
    }
  }, []);

  return null;
}
