import { useEffect } from 'react';
import { CMS_CONFIG, IGenericErrorCMS, IPayments, useCmsConfig } from '@vfit/consumer/data-access';
import { API, openPopup, PAGES, setTrackLink } from '@vfit/shared/data-access';
import { EMethodCode, ICommonData, ISIAFlowType } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { PaymentMethod } from '@vfit/consumer/components';
import {
  DEFAULT_CREDIT_CARD,
  organizePaymentMethod,
  organizePaymentMethodError,
  organizeUpfrontSelectionCard,
} from '../upfrontPaymentCard.utils';
import { useCheckout } from '../../../../iBuyFixed.context';
import { getUserInfo, getTagging } from '../../../checkout.utils';
import { useAuthorizeAuthorizationFlow } from '../../../hooks/UseAuthorizeAuthorizationFlow/useAuthorizeAuthorizationFlow';

const UpfrontPayment = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const goNextButton = document.getElementById('sticky-offer-next');
  const dataFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PAYMENTS) as IPayments;
  const { title, buttonLabel } = organizePaymentMethod(dataFromCms);
  const { titlePaymentError, messagePaymentError } = organizePaymentMethodError(dataFromCms);
  const { descriptionCC, paymentMethod } = organizeUpfrontSelectionCard(dataFromCms);
  const { checkoutErrors, owningData, upfrontPayment, isModalButtonSticky, product } =
    useCheckout();
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const selectedItem = paymentMethod?.find(
    (el) => el.value.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase()
  );

  const tagging = getTagging('checkout', 'fixed');
  const { trackView } = useTracking({
    event: ['checkout_step10.1'],
    event_label: 'upfront payment',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'upfront payment');

  const { authorize } = useAuthorizeAuthorizationFlow(
    selectedItem?.value,
    ISIAFlowType.INSTANT,
    'verify'
  );

  const isSuccesAuthorize = authorize?.isSuccess;
  const isErrorAuthorize = authorize?.isError;

  const showError = () => {
    const action = () => {
      if (handleGoPrevSlide) handleGoPrevSlide();
    };
    checkoutErrors?.showAndTrackError?.(
      genericError,
      action,
      authorize?.error,
      titlePaymentError,
      messagePaymentError,
      action,
      'Chiudi',
      () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
      product?.genericErrorCallMeNow?.label || '',
      isModalButtonSticky,
      true
    );
  };

  useEffect(() => {
    if (isErrorAuthorize) {
      showError();
    }
  }, [isErrorAuthorize]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isSuccesAuthorize);
  }, [isSuccesAuthorize]);

  useEffect(() => {
    if (!selectedItem) {
      showError();
    }
    const trackGoNext = () => {
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'Credit Card',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, []);

  return (
    <PaymentMethod
      owningIndividual={owningData.owningIndividual}
      handleGoPrevSlide={handleGoPrevSlide}
      payMean={upfrontPayment || DEFAULT_CREDIT_CARD}
      showLoader={!isSuccesAuthorize}
      config={{
        title,
        subtitle: descriptionCC,
        buttonLabel,
        titlePaymentError,
        messagePaymentError,
      }}
    />
  );
};

export default UpfrontPayment;
