import { memo, SVGProps } from 'react';

const Location = (): JSX.Element => {
  return (
    <svg width="55" height="57" viewBox="0 0 55 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6147_76123)">
        <rect x="4" y="3" width="46.7778" height="48.892" rx="2" fill="white" />
      </g>
      <path
        d="M36.5673 27.4427C36.5673 32.7743 32.4375 37.0583 27.3891 37.0583C22.3407 37.0583 18.2109 32.7743 18.2109 27.4427C18.2109 22.1111 22.3407 17.8271 27.3891 17.8271C32.4375 17.8271 36.5673 22.1111 36.5673 27.4427Z"
        stroke="#D1D1D1"
      />
      <ellipse cx="27.3896" cy="27.442" rx="6.45211" ry="6.74373" fill="#999" stroke="white" />
      <rect x="25.7773" y="13.9551" width="3.22605" height="3.37186" fill="#D1D1D1" />
      <rect x="25.7773" y="37.5615" width="3.22605" height="3.37186" fill="#D1D1D1" />
      <rect x="37.0664" y="25.7568" width="3.22605" height="3.37186" fill="#D1D1D1" />
      <rect x="14.4844" y="25.7568" width="3.22605" height="3.37186" fill="#D1D1D1" />
      <defs>
        <filter
          id="filter0_d_6147_76123"
          x="0"
          y="0"
          width="54.7773"
          height="56.8916"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6147_76123" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6147_76123"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(Location);
