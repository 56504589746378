import { ImageAtoms } from '@vfit/shared/atoms';
import { CMS_CONFIG, IBrightSkyCms, useCmsConfig } from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import Script from 'next/script';
import { BannerContainer, Link, Text, ImageContainer } from './brightSky.style';
import { evaluateCmsData } from './brightSky.utils';
import { PropsWithChildren, useRef } from 'react';
import { useIntersectionObserver } from '@vfit/shared/hooks';

const ObserverWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const entry = useIntersectionObserver(ref, {
    rootMargin: '-1%',
    threshold: 0.7,
    freezeOnceVisible: true,
  });
  const isVisible = !!entry?.isIntersecting;
  return <div ref={ref}>{isVisible ? children : null}</div>;
};


const BrightSky = () => {
  const brightSkyCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_BRIGHT_SKY_CONF
  ) as IBrightSkyCms;
  const { text, redirectUrl, redirectLabel, imagePath, isVisible, srcScript, marketId, mode } =
    evaluateCmsData(brightSkyCms);
  const isVisibleBanner: boolean = isVisible === 'true';
  const openBrightSkyWidget = () => {
    if (mode === 'redirect') return;
    const bsWidgetDiv = document.querySelector('.brightsky-widget') as HTMLDivElement;
    if (bsWidgetDiv) bsWidgetDiv.setAttribute('data-modal-open', 'true');
  };
  const anchorTarget = mode === 'redirect' ? '_blank' : '_self';
  const anchorHref = mode === 'redirect' ? redirectUrl : '#';
  const isDevLocal = process.env?.['NODE_ENV'] === 'development';

  if (!isDevLocal && isVisibleBanner && text && redirectUrl) {
    return (
      <ObserverWrapper>
        {mode === 'popup' && srcScript && marketId && (
          <div
            className="brightsky-widget"
            data-market="IT"
            data-marketid={marketId}
            style={{ display: 'none' }}
          >
            <Script defer id="widget-script" strategy="afterInteractive" src={srcScript} />
          </div>
        )}
        <BannerContainer>
          <ImageContainer>
            <ImageAtoms nameCard="bright-sky" image={imagePath} sizes="48px" />
          </ImageContainer>
          <Text>
            {' '}
            {text} {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link target={anchorTarget} href={anchorHref} onClick={openBrightSkyWidget}>
              {' '}
              {redirectLabel}
            </Link>
          </Text>
        </BannerContainer>
      </ObserverWrapper>
    );
  }
  return null;
};
export default BrightSky;

