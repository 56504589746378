import { CART_TYPE, IProduct } from '@vfit/consumer/data-access';
import { IGetCostsFWA } from './upfrontSelectionCard.models';
import { getInstallmentOptionsFWA } from '../../checkout.utils';

const getCostsFWA = (): IGetCostsFWA => {
  const installmentOptions = getInstallmentOptionsFWA();
  const amountOption = installmentOptions?.options?.find((el) => el.id == '0');
  const recurringOption = installmentOptions?.options?.find((el) => el.id == '24');
  const { installmentPrice: installmentPriceAmount } = amountOption || {};
  const { installmentPrice: installmentPriceRecurring } = recurringOption || {};
  const oneTimeCost =
    installmentPriceAmount?.find((el) => el.type === CART_TYPE.ONE_TIME)?.originalprice
      ?.taxIncludedAmount || 0;
  const recurringMonths = 24;
  const recurringCost =
    installmentPriceRecurring?.find((el) => el.type === CART_TYPE.RECURRING)?.originalprice
      ?.taxIncludedAmount || 0;
  return {
    oneTimeCost,
    recurringMonths,
    recurringCost,
  };
};

const getCheckoutFWA = (product: IProduct) => {
  const costsFWA = getCostsFWA();
  return {
    ...product,
    checkoutFWA: {
      ...product.checkoutFWA,
      title: product?.checkoutFWA?.title
        ?.replace('$MONTHLY', `${costsFWA?.recurringMonths || ''}`)
        ?.replace('$ONETIME', `${costsFWA?.oneTimeCost?.toString() || ''}`)
        ?.replace('$PAYMENTS', `${costsFWA?.recurringCost || ''}`),
      description: product?.checkoutFWA?.description
        ?.replace('$MONTHLY', `${costsFWA?.recurringMonths || ''}`)
        ?.replace('$ONETIME', `${costsFWA?.oneTimeCost?.toString() || ''}`)
        ?.replace('$PAYMENTS', `${costsFWA?.recurringCost || ''}`),
      items: product?.checkoutFWA?.items?.map((el) => ({
        ...el,
        title: el.title
          ?.replace('$MONTHLY', `${costsFWA?.recurringMonths || ''}`)
          ?.replace('$ONETIME', `${costsFWA?.oneTimeCost?.toString() || ''}`)
          ?.replace('$PAYMENTS', `${costsFWA?.recurringCost || ''}`),
        description: el.description
          ?.replace('$MONTHLY', `${costsFWA?.recurringMonths || ''}`)
          ?.replace('$ONETIME', `${costsFWA?.oneTimeCost?.toString() || ''}`)
          ?.replace('$PAYMENTS', `${costsFWA?.recurringCost || ''}`),
      })),
    },
  };
};

export { getCheckoutFWA };
