import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  checkIsGoBackSIA,
  useAuthorizeMutation,
  useGetShoppingCartSilentUpdate,
  usePaymentMethod,
} from '@vfit/consumer/data-access';
import { EMethodCode, IAvailablePaymentMethods, ISIAFlowType } from '@vfit/shared/models';
import { ITrackError, LoggerInstance, resetData } from '@vfit/shared/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';

type IPaymentMethodFlow = {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  error: ITrackError | null;
  handleResetState: () => void;
};

export const useUpdatePaymentMethodFlow = (
  billingOfferIdForChangePaymentMethod: Array<string>,
  orderActionId: string,
  flowEnabler = false,
  selectedPaymentMethod?: IAvailablePaymentMethods,
  siaFlowType?: ISIAFlowType,
  authorizationOperation?: 'capture' | 'verify'
): IPaymentMethodFlow => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<ITrackError | null>(null);

  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;

  const {
    isSuccess: paymentMethodPutIsSuccess,
    isLoading: paymentMethodPutIsLoading,
    isError: paymentMethodPutIsError,
    mutate: paymentMethodPutMutate,
    reset: paymentMethodPutReset,
  } = usePaymentMethod();

  const {
    isSuccess: getShoppingCartIsSuccess,
    isLoading: getShoppingCartIsLoading,
    isError: getShoppingCartIsError,
    refetch: getShoppingCartRefetch,
  } = useGetShoppingCartSilentUpdate(cartId, {
    enabled: false,
    keyDependency: ['getShoppingCartUpdatePayment'],
  });

  const {
    mutate: mutateAuthorize,
    isSuccess: isSuccessAuthorize,
    isError: isErrorAuthorize,
    isLoading: isLoadingAuthorize,
  } = useAuthorizeMutation();

  useEffect(() => {
    if (
      flowEnabler &&
      orderActionId &&
      !paymentMethodPutIsLoading &&
      !paymentMethodPutIsSuccess &&
      !paymentMethodPutIsError &&
      !isLoadingAuthorize &&
      !getShoppingCartIsLoading &&
      selectedPaymentMethod
    ) {
      paymentMethodPutMutate({
        billingOfferIdForChangePaymentMethod,
        orderActionId,
        selectedPaymentMethod,
      });
      setIsLoading(true);
    }
  }, [
    flowEnabler,
    selectedPaymentMethod,
    billingOfferIdForChangePaymentMethod,
    orderActionId,
    paymentMethodPutIsLoading,
    paymentMethodPutIsSuccess,
  ]);

  const handleResetState = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    setError(null);
    resetData(queryClient, ['getShoppingCartUpdatePayment']);
    paymentMethodPutReset();
  };

  useEffect(() => {
    if (!paymentMethodPutIsLoading && paymentMethodPutIsSuccess && !checkIsGoBackSIA()) {
      if (
        selectedPaymentMethod &&
        selectedPaymentMethod?.methodCode !== EMethodCode.WALLET &&
        !isErrorAuthorize
      ) {
        mutateAuthorize({
          payMeanType: selectedPaymentMethod.methodCode,
          ...(siaFlowType && { siaFlowType }),
          ...(authorizationOperation && { authorizationOperation }),
        });
      } else {
        getShoppingCartRefetch();
      }
    }
    if (!paymentMethodPutIsLoading && paymentMethodPutIsError) {
      const errorPut: ITrackError | undefined = queryClient.getQueryData('paymentMethod');
      LoggerInstance.debug('paymentMethodPut error!!');
      setIsLoading(false);
      setIsError(true);
      setError(errorPut || null);
    }
    if (isErrorAuthorize) {
      const errorAuthorize: ITrackError | undefined = queryClient.getQueryData('authorize');
      LoggerInstance.debug('authorize error!!');
      setIsLoading(false);
      setIsError(true);
      setError(errorAuthorize || null);
    }
  }, [
    paymentMethodPutIsLoading,
    paymentMethodPutIsSuccess,
    paymentMethodPutIsError,
    isErrorAuthorize,
  ]);

  useEffect(() => {
    if (isErrorAuthorize && !isLoadingAuthorize) {
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
    } else if (!isLoadingAuthorize && isSuccessAuthorize) {
      getShoppingCartRefetch();
    }
  }, [isSuccessAuthorize, isLoadingAuthorize, isErrorAuthorize]);

  useEffect(() => {
    if (getShoppingCartIsError && !getShoppingCartIsLoading) {
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
    } else if (getShoppingCartIsSuccess && !getShoppingCartIsLoading) {
      setIsError(false);
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [getShoppingCartIsSuccess, getShoppingCartIsError, getShoppingCartIsLoading]);

  return {
    isSuccess,
    isLoading,
    isError,
    error,
    handleResetState,
  };
};
