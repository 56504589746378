import { IPortabilityMobileCMS } from '@vfit/consumer/data-access';

export const organizePortabilityCard = (portabilityCardCms: IPortabilityMobileCMS) => ({
  errorOnPortability: {
    errorMessage: {
      genericError: {
        title: portabilityCardCms?.portabilitymobile?.errorOnPortability?.message || '',
      },
    },
  },
  title: portabilityCardCms?.portabilitymobile?.general?.title || '',
  description: portabilityCardCms?.portabilitymobile?.general?.description || '',
  bottomText: portabilityCardCms?.portabilitymobile?.general?.bottomText || '',
  items: [
    {
      title:
        portabilityCardCms?.portabilitymobile?.general?.choicePortability?.option1?.title || 'Sì',
      value: 'yes',
    },
    {
      title:
        portabilityCardCms?.portabilitymobile?.general?.choicePortability?.option2?.title || 'No',
      value: 'no',
    },
  ],
});
