import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 48px 44px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 60px 40px 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 72px 109px 40px;
  }

  .body {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: 328px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 405px;
    }
  }
`;

export const ModalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    gap: 32px;
  }
`;

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 35px;
    margin-bottom: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 31px;
  }
`;

export const ModalTitle = styled.p`
  text-align: left;
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 8px;
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 8px;
    ${pxToCssFont(36, 45)}
  }
`;

export const ModalDescription = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  margin: 0;
  text-align: left;
  font-weight: 400;
  color: ${colors.$262626};

  p {
    margin: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .button-slide-container {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: 258px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 296px;
    }
  }
`;

export const LoadingContainer = styled.div`
  position: relative;
  left: 40px;
  top: 10px;
  
  svg {
    width: 28px;
    height: 25px;
  }
`;