import { useEffect } from 'react';
import { ShadowsGroup } from '@vfit/shared/components';
import { ICheckoutData } from '../../checkout.models';
import { useCheckout } from '../../../iBuyMobile.context';
import NameFiscalCodeCard from '../NameFiscalCodeCard/nameFiscalCodeCard';
import { Skeleton } from './nameFiscalCodeCardWinback.style';

const NameFiscalCodeCardWinback = (props: ICheckoutData) => {
  const { setIsDisabledBackButton, cartAsyncInfo } = useCheckout();
  const isLoadingSim = cartAsyncInfo?.simTypeSelection?.isLoading || false;

  useEffect(() => {
    setIsDisabledBackButton(isLoadingSim);
  }, [isLoadingSim]);

  if (isLoadingSim) {
    return (
      <Skeleton>
        <ShadowsGroup quantity={4} heights={75} space={40} />
      </Skeleton>
    );
  }

  return <NameFiscalCodeCard {...props} />;
};

export default NameFiscalCodeCardWinback;
