import { useMutation, useQueryClient } from 'react-query';
import { errorMock, GetCustomerResponse, IFindCallerResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { IAddressResponse, IOfferingServiceabilityResponse } from '@vfit/shared/models';
import { IAcceptMutationPayload, IAcceptPayload, IAcceptResponse } from './accept.models';

/**
 * Method to POST campaign accept api service
 * @param payload
 * @param campaignId
 * @param customOptions
 */
const campaignAcceptService = (
  payload: IAcceptPayload,
  campaignId: string,
  customOptions?: CustomOptions
): Promise<IAcceptResponse> =>
  nextClient.post(`${API.CAMPAIGN}/${campaignId}/${API.ACCEPT}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * This method confirm the campaign order
 * @returns
 */
export const useCampaignAccept = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ['campaignAccept'],
    ({ campaignId, sessionId }: IAcceptMutationPayload) => {
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const createdAddress: IAddressResponse = getFromLocalStorageByKey('createdAddress');
      const installationAddressId = createdAddress?.id;
      const offeringServiceability: IOfferingServiceabilityResponse =
        getFromLocalStorageByKey('offeringServiceability');
      const serviceabilityId = offeringServiceability?.id;
      const vtvIndicator = (
        offeringServiceability as IOfferingServiceabilityResponse
      )?.serviceabilityCharacteristic?.find(
        (item) => item?.displayName.toLowerCase() === 'vtv'
      )?.displayValue;

      const payload: IAcceptPayload = {
        audienceType: 'CUSTOMER_ID',
        audienceId: customerId,
        campaignId,
        sessionId,
        vtvIndicator,
        installationAddressId,
        serviceabilityId,
      };

      return campaignAcceptService(payload, campaignId);
    },
    {
      onSuccess: (data: IAcceptResponse) => {
        localStorage.setItem('campaignAccept', JSON.stringify(data));
        queryClient.setQueryData('campaignAccept', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - campaignAccept: `, error);
        localStorage.setItem(`campaignAccept`, JSON.stringify(errorMock(`campaignAccept`, error)));
      },
    }
  );
};
