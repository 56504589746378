import styled from 'styled-components';

export const Container = styled.div``;

export const ContainerButtons = styled.div`
  button {
    margin-right: 10px;
    margin-top: 50px;
    cursor: pointer;
  }
`;

export const Fab = styled.div`
  z-index: 9;
  width: 70px;
  height: 70px;
  background-color: red;
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 #666;
  transition: all 0.1s ease-in-out;
  font-size: 50px;
  color: white;
  text-align: center;
  line-height: 70px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  cursor: pointer;
  span {
    position: fixed;
    right: 63px;
    font-size: 10px;
  }
  :hover {
    box-shadow: 0 6px 14px 0 #666;
  }
`;
