import { IModalICCID } from '@vfit/consumer/data-access';
import { Section, SectionTitle, SubTitle, Title, Wraper } from './modalContentICCID.style';

export const ModalContentICCID = ({ cms }: { cms: IModalICCID }) => {
  return (
    <Wraper>
      <Title>{cms.title}</Title>
      {cms.paragraphs.map((e) => (
        <div key={e.title}>
          <SubTitle>{e.title}</SubTitle>
          {!!e.text && <p>{e.text}</p>}
          {!!e.subParagraphs &&
            e.subParagraphs.map((el) => (
              <div key={el.text}>
                <SectionTitle>{el.title}</SectionTitle>
                <Section>{el.text}</Section>
              </div>
            ))}
        </div>
      ))}
    </Wraper>
  );
};

export default ModalContentICCID;
