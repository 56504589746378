export const ID_FLOWS = {
  PERSONAL_INFO: 'personal information',
  DOCUMENT_ID: 'document',
  CONTACT_CARD: 'contacts',
  CONTACT_CARD_LOCKIN: 'contacts lockin',
  CONTACT_CARD_LOCKIN_PROSPECT: 'contacts lockin prospect',
  CONFIRM_IDENDITY: 'verify id',
  CONFIRM_IDENDITY_APP: 'verify id app',
  OTP: 'otp',
  PORTABILITY_LINE: 'portability line',
  PORTABILITY_LINE_APP: 'Portability line app',
  PORTABILITY_INFORMATIONS: 'portability informations',
  PORTABILITY_INTERNET: 'portability internet',
  OPERATORS_INFORMATIONS: 'operators informations',
  PORTABILITY_MIGRATIONS: 'Migration Codes',
  INVOICE: 'Invoice',
  UPFRONT_SELECTION: 'Upfront selection',
  PAYMENT_METHOD: 'Payment method',
  PAYMENT_METHOD_WITHOUT_RECURRING: 'Payment method without recurring',
  RECURRENT_PAYMENT_CARD: 'Recurrent payment card',
  PORTABILITY: 'Portability',
  PORTABILITY_NUMBER: 'Portability Number',
  PORTABILITY_NUMBER_PORTABILITY_REQUIRED: 'Portability Number portability required',
  DELIVERY_ADDRESS: 'Delivery',
  PORTABILITY_SIM_SERIAL: 'Portability Sim Serial',
  PORTABILITY_OTP: 'Portability Otp',
  PORTABILITY_DATA: 'Portability Data',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  CONSENTS: 'Consents',
  UPFRONT_PAYMENT: 'Upfront payment',
  VOUCHER: 'Voucher',
};

export const DEFAULT_CURRENT_STEP_KEY = ID_FLOWS.PERSONAL_INFO;
