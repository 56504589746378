import { RegisterOptions } from 'react-hook-form';

export const voucherCodeFieldName = 'voucherCode' as const;

export const voucherSchema = {
  [voucherCodeFieldName]: {
    minLength: {
      value: 3,
      message: 'Il codice deve avere almeno 3 cifre',
    },
  },
};
