import { useEffect, useState } from 'react';
import {
  getCurrentUserType,
  IUserType,
  LOCKIN_OFFER_COOKIE,
  recognizedUser,
  updateContactProblemDataLocal,
} from '@vfit/consumer/data-access';
import { ICommonData, IPersonalDataObject } from '@vfit/shared/models';
import { getItemCookie, trackLink } from '@vfit/shared/data-access';
import { IContactInfo } from '../../checkout.models';
import { updateContactDataLocal } from './contactLockinProspectCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import Contact from './components/Contact/contact';
import { usePostOtpFlow } from '../../hooks/UsePostOtpFlow/usePostOtpFlow.hook';
import ContactTagging from './contactLockinProspectCard.tagging';
import LoadingCard from '../LoadingCard/loadingCard';

const ContactLockinProspectCard = (props: ICommonData) => {
  const { handleOnChangeEnableGoNext } = props;
  const {
    owningData,
    contactInfo,
    setOwningData,
    setContactInfo,
    setCurrentStepKey,
    setIsDisabledBackButton,
  } = useCheckout();
  const { isLoading } = usePostOtpFlow(props);
  const [isValidForm, setIsValidForm] = useState(false);

  const getLockinProspectOfferData = (): IPersonalDataObject => {
    const lockinProspectOffer = getItemCookie(LOCKIN_OFFER_COOKIE);
    if (
      !lockinProspectOffer?.firstName ||
      !lockinProspectOffer?.lastName ||
      !lockinProspectOffer?.fiscalCode
    ) {
      return owningData;
    }
    return {
      ...owningData,
      owningIndividual: {
        ...owningData?.owningIndividual,
        firstName: lockinProspectOffer?.firstName || '',
        lastName: lockinProspectOffer?.lastName || '',
        fiscalCode: lockinProspectOffer?.fiscalCode || '',
      },
    };
  };

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      setIsDisabledBackButton(false);
      if (recognizedUser()) {
        setTimeout(() => {
          handleOnChangeEnableGoNext(!isLoading && (isValidForm || (recognizedUser() as boolean)));
        }, 800);
      } else {
        setTimeout(() => {
          handleOnChangeEnableGoNext(!isLoading && isValidForm);
        }, 800);
      }
    }
  }, [isValidForm, isLoading]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.CONTACT_CARD);
    setOwningData({
      ...getLockinProspectOfferData(),
    });
    updateContactProblemDataLocal(contactInfo);
  }, []);

  const onChangeData = (data: IPersonalDataObject) => {
    updateContactDataLocal(data);
    setOwningData(data);
  };

  const onChangeCheck = (data: IContactInfo) => {
    let trackData = 'off';

    if (data.checkContactProblem) {
      trackData = 'on';
    }
    trackLink(`re-contact checkbox ${trackData}`);
    updateContactProblemDataLocal(data);
    setContactInfo(data);
  };

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <>
      {!isLoading && <ContactTagging />}
      <Contact
        data={owningData}
        checkInfo={contactInfo}
        onChangeData={onChangeData}
        onChangeCheck={onChangeCheck}
        isReadOnly={
          getCurrentUserType() === IUserType.NEXT_USER_ACTIVE ||
          getCurrentUserType() === IUserType.LOGGED_USER
        }
        setIsValidForm={(valid: boolean) => setIsValidForm(valid)}
      />
    </>
  );
};

export default ContactLockinProspectCard;
