import { Children, useState } from 'react';
import { ITabsWidget } from './tabsWidget.models';
import { Wrapper, TabsDiv, TabBox, TabLine } from './tabsWidget.style';
import Widgets from '../Widgets/widgets';

const TabsWidget = ({ tabs }: ITabsWidget) => {
  const [selIndex, setSelIndex] = useState<number>(0);

  const onClickTab = (index: number) => {
    setSelIndex(index);
  };

  return (
    <>
      <Wrapper>
        <TabsDiv>
          {Children.toArray(
            tabs.map((tab, index) => (
              <TabBox selected={index === selIndex} onClick={() => onClickTab(index)}>
                {tab.label}
              </TabBox>
            ))
          )}
        </TabsDiv>
      </Wrapper>
      <TabLine />
      {tabs[selIndex]?.widgets && <Widgets widgets={tabs[selIndex]?.widgets} />}
    </>
  );
};

export default TabsWidget;
