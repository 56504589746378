import {
  errorMock,
  IProduct,
  IUserIdentityField,
  useCampaignsInfo,
  useGenerateOTP,
  useGetCustomer,
  useGetFindCaller,
  usePage,
  useVerifyOTP,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { IFindCallerObject } from '@vfit/shared/models';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';
import { ICheckEligibility, IUseCoverageToolOtpFlow } from './coverageToolOtpCard.models';
import { getEligibilityProduct } from './coverageToolOtpCard.utils';

/**
 * Flow:
 * generate OTP
 * verify OTP
 * findCaller
 * getCustomer
 * campaigns
 */
export const useCoverageToolOtp = (): IUseCoverageToolOtpFlow => {
  const { products } = usePage();
  const queryClient = useQueryClient();
  const { findCaller, coverageLockInUser } = useCoverageToolModalProvider();
  const [customerId, setCustomerId] = useState<string>('');
  const [isDelayCompensation, setDelayCompensation] = useState(false);
  const [checkEligibility, setCheckEligibility] = useState<
    ICheckEligibility & { lockInProduct?: IProduct }
  >({
    eligible: false,
  });
  const {
    data: generateOtpData,
    error: generateOtpError,
    mutate: generateOtpMutate,
    isError: isErrorGenerateOtp,
    isLoading: isLoadingGenerateOtp,
    isSuccess: isSuccessGenerateOtp,
    reset: resetGenerateOtp,
  } = useGenerateOTP();
  const {
    mutate: verifyOtpMutate,
    data: verifyOtpData,
    error: verifyOtpError,
    isSuccess: isSuccessVerifyOtp,
    isError: isErrorVerifyOtp,
    isLoading: isLoadingVerifyOtp,
    reset: resetVerifyOtp,
  } = useVerifyOTP();
  const {
    data: findCallerData,
    error: findCallerError,
    isError: isErrorFindCaller,
    isLoading: isLoadingFindCaller,
    isSuccess: isSuccessFindCaller,
    refetch: refetchFindCaller,
  } = useGetFindCaller(
    {
      fiscalCode: coverageLockInUser?.owningIndividual?.fiscalCode || '',
    },
    {
      enabled: false,
    }
  );
  const {
    data: customerData,
    isLoading: isLoadingCustomer,
    isError: isErrorCustomer,
    isSuccess: isSuccessCustomer,
    error: customerError,
    refetch: refetchGetCustomer,
  } = useGetCustomer(customerId, { enabled: false });
  const {
    data: campaignsData,
    error: campaignsError,
    isLoading: isLoadingCampaigns,
    isSuccess: isSuccessCampaigns,
    isError: isErrorCampaigns,
    refetch: refetchCampaigns,
  } = useCampaignsInfo({
    keyDependency: ['campaignsLockInFromHub'],
  });

  const resetCoverageToolOtpCardFlow = () => {
    resetGenerateOtp();
    resetVerifyOtp();
    resetData(queryClient, []);
  };

  useEffect(() => {
    if (isSuccessCampaigns) {
      const { campaignEligibility, lockInProduct } = getEligibilityProduct(
        products || [],
        campaignsData
      );
      setCheckEligibility({
        ...campaignEligibility,
        lockInProduct,
      });
    }
  }, [isSuccessCampaigns]);

  useEffect(() => {
    if (isSuccessCustomer) refetchCampaigns();
  }, [isSuccessCustomer]);

  useEffect(() => {
    if (customerId) {
      refetchGetCustomer();
    }
  }, [customerId]);

  useEffect(() => {
    if (isSuccessFindCaller && findCallerData?.customerRef?.id) {
      setCustomerId(findCallerData.customerRef.id);
    }
  }, [isSuccessFindCaller, findCallerData]);

  useEffect(() => {
    if (isSuccessVerifyOtp) {
      if (verifyOtpData?.id) refetchFindCaller();
    }
  }, [isSuccessVerifyOtp]);

  const onVerifyOtp = (otp: string) => {
    verifyOtpMutate(otp);
  };

  const onGenerateOtp = () => {
    const { field, fieldType } =
      ((findCaller as IFindCallerObject)?.selectedContactMedium as IUserIdentityField) || {};
    generateOtpMutate({
      identifier: field,
      identifierType: fieldType,
    });
  };

  useEffect(() => {
    onGenerateOtp();
  }, []);

  useEffect(() => {
    if(isSuccessVerifyOtp) setDelayCompensation(true);
    if(isLoadingFindCaller) setDelayCompensation(false);
  }, [isSuccessVerifyOtp, isLoadingFindCaller]);

  return {
    resetCoverageToolOtpCardFlow,
    onVerifyOtp,
    onGenerateOtp,
    generateOtp: {
      isLoading: isLoadingGenerateOtp,
      isError: isErrorGenerateOtp,
      isSuccess: isSuccessGenerateOtp,
      data: generateOtpData,
      ...(generateOtpError && { error: errorMock('generateOtpError', generateOtpError) }),
    },
    verifyOtp: {
      isLoading: isLoadingVerifyOtp,
      isError: isErrorVerifyOtp,
      isSuccess: isSuccessVerifyOtp,
      data: verifyOtpData,
      ...(verifyOtpError && { error: errorMock('verifyOtp', verifyOtpError) }),
    },
    findCaller: {
      isLoading: isLoadingFindCaller || isDelayCompensation,
      isError: isErrorFindCaller,
      isSuccess: isSuccessFindCaller,
      data: findCallerData,
      ...(findCallerError && { error: errorMock('findCaller', findCallerError) }),
    },
    customer: {
      isLoading: isLoadingCustomer,
      isError: isErrorCustomer,
      isSuccess: isSuccessCustomer,
      data: customerData,
      ...(customerError && { error: errorMock('customer', customerError) }),
    },
    campaigns: {
      isLoading: isLoadingCampaigns,
      isError: isErrorCampaigns,
      isSuccess: isSuccessCampaigns,
      data: campaignsData,
      ...(campaignsError && { error: errorMock('campaigns', campaignsError) }),
    },
    checkEligibility,
  };
};
