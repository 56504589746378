export const enum Path {
  URL_BACK = 'mode=urlback',
  URL_FORWARD = 'mode=urldoneonbillcapture',
}

export enum FLOW_TYPE_DYNAMIC_SHOPPING_CART {
  SHOPPING_CART_FIXED = '1',
  SHOPPING_CART_MOBILE = '2',
}

export enum ERROR_CODES_DYNAMIC_SHOPPING_CART {
  KO,
  FWA,
  VALIDATE_ADDRESS_ERROR,
  CREATED_ADDRESS_ERROR,
  SERVICE_ABILITY_ERROR,
  ERROR_DXL_CAMPAIGNS,
}
