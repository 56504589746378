import { IProduct } from '@vfit/consumer/data-access';
import { useMemo } from 'react';
import { useLocation } from 'react-use';

export interface ICmsConfigAutoDesc {
  enableAutoDesc: string;
  url?: string;
  customString?: string;
}

export const useAutoDesc = (product: IProduct, cmsConfig: ICmsConfigAutoDesc) => {
  const { enableAutoDesc, url, customString = '' } = cmsConfig || {};
  const location = useLocation();

  const ctcAutoUrl = useMemo(() => {
    if (!(enableAutoDesc === 'true') && url) {
      if (!customString) return url;

      // Calculating Url fallback + customString
      const urlParams = new URLSearchParams(url);
      const desc = urlParams.get('desc') || urlParams.get('DESC');
      const customDesc = `${desc}_${customString}`;
      if (urlParams.has('DESC')) {
        urlParams.set('DESC', customDesc);
      } else {
        urlParams.set('desc', customDesc);
      }
      let queryParams = '';
      urlParams?.forEach((value, key) => {
        queryParams += `${key}=${value}&`;
      });
      const customUrl = `${url.split('?')[0]}?${queryParams.split('?')?.[1]?.slice(0, -1)}`;
      return customUrl;
    }

    // Auto mode calc
    const env = 'App'; // Web -> to be added
    const icmp = new URLSearchParams(location.search).get('icmp') || 'landing-page';
    const userType = 'NEXT'; // 'LEGACY' -> to be added
    const origin = icmp ? 'ORG' : 'ORG'; // // 'ADV' -> to be added if no icmp
    const productLocation = location.pathname.replace(/^\//g, '').replace(/\//g, '-');
    const productName = product?.title.replace(/\s+/g, '-');
    const productId = product?.offerId;
    const productPrice = product?.price.replace(/[^0-9,]/g, '').replace(',', '');

    const autoUrl = `${env}_${icmp}_${userType}_${origin}_${productLocation}_${productName}-${productId}-${productPrice}`;

    if (url) {
      const urlParams = new URLSearchParams(url);
      if (urlParams.has('DESC')) {
        urlParams.set('DESC', customString ? `${autoUrl}_${customString}` : autoUrl);
      } else {
        urlParams.set('desc', customString ? `${autoUrl}_${customString}` : autoUrl);
      }
      let queryParams = '';
      urlParams?.forEach((value, key) => {
        queryParams += `${key}=${value}&`;
      });
      const customUrl = `${url.split('?')[0]}?${queryParams.split('?')[1].slice(0, -1)}`;
      return customUrl;
    } else {
      // returning empty if no desc in CMS config or no url -> fallback
      return '';
    }
  }, [product, cmsConfig, location.search, location.pathname]);

  return {
    ctcAutoUrl,
  };
};
