import { IPersonalInfoCmsMobile } from '@vfit/consumer/data-access';

export const organizeRecognitionEsim = (data: IPersonalInfoCmsMobile) => ({
  data: {
    title: data?.personalinfomobile?.recognitionEsim?.title || '',
    description: data?.personalinfomobile?.recognitionEsim?.description || '',
    textBold: data?.personalinfomobile?.recognitionEsim?.textBold || '',
    text: data?.personalinfomobile?.recognitionEsim?.text || '',
  },
  list: {
    title: data?.personalinfomobile?.recognitionEsim?.list?.[0].title || '',
    items: data?.personalinfomobile?.recognitionEsim?.list?.[0]?.elements || [
      {
        text: '',
        icon: '',
      },
    ],
    portability: data?.personalinfomobile?.recognitionEsim?.list?.[0]?.portability || [
      {
        text: '',
        icon: '',
      },
    ],
  },
});
