import React from 'react';
import { IProduct } from '@vfit/consumer/data-access';
import { IDivider } from '@vfit/shared/models';

export const ACTIVE_TAB = 'at';

export interface IProductsListProps {
  classKey: string;
  containerStyle?: React.CSSProperties;
  zoomIn?: boolean;
  slideLock?: boolean;
  products: IProduct[];
  showCase?: number;
  onTrack?: string;
  disclaimer?: string;
  title?: string;
  divider?: IDivider;
  hideTabs?: boolean;
  isR02?: () => boolean;
}
