import { useEffect, useState } from 'react';
import {
  BYPASS_CALL_API_SIA,
  CMS_CONFIG,
  getBackAndBlockinErrors,
  IGenericErrorCMS,
  IPayments,
  PAYMENT_ERROR_MESSAGE,
  PAYMENT_ERROR_TITLE,
  PAYMENT_SHOW_CTC,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, getHome, openPopup, PAGES, setTrackLink } from '@vfit/shared/data-access';
import { ICMSApiError } from '@vfit/shared/models';
import { LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { WarningInfo } from '@vfit/shared/components';
import { IUpfrontPaymentSubmitCard } from './upfrontPaymentSubmitCard.models';
import { getUserInfo, getTagging, getTrackError } from '../../../checkout.utils';
import { useCheckout } from '../../../../iBuyMobile.context';
import { useCheckoutFlow } from '../../../hooks/UseCheckoutFlow/useCheckoutFlow';
import { ID_FLOWS } from '../../../checkout.constants';

const UpfrontPaymentSubmitCard = ({ onErrorRetry }: IUpfrontPaymentSubmitCard) => {
  const [loadingForFinalPortIn, setLoadingForFinalPortIn] = useState(true);
  const { checkoutErrors, isModalButtonSticky, product, portabilityStatus } = useCheckout();
  const shoppingCartUrl = product?.shoppingCartUrl;
  const { isLoading, isSuccess, isError, error } = useCheckoutFlow({
    ignoreQueryString: true,
  });
  const redirectUrl = `${getHome()}`;
  const {
    paymentsmobile: { warningWaitingPayment, paymentError },
  } = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PAYMENTS_MOBILE) as IPayments;
  const orderErrors = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_INSTANT_ORDER_ERROR_MOBILE
  ) as ICMSApiError;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const taggingMobile = getTagging('checkout');
  const isDisabledRedirect = localStorage.getItem('disableRed');
  const {
    goBackErrorOnPaymentFormattedErrors,
    goBackErrorOnPayment,
    blockingFormattedError,
    blockingErrors,
  } = getBackAndBlockinErrors(orderErrors);

  const handleError = () => {
    if (!isDisabledRedirect) {
      localStorage.removeItem(BYPASS_CALL_API_SIA);
      window.open(shoppingCartUrl || redirectUrl, '_self');
    }
  };

  const { trackView } = useTracking({
    event: ['order_loading'],
    event_label: 'verifying_payment_information',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'verifying_payment_information');

  const cmsWarningPayment = {
    title: warningWaitingPayment?.title || 'Grazie!',
    subtitle: warningWaitingPayment?.subtitle || 'Stiamo elaborando il tuo ordine',
    description:
      warningWaitingPayment?.description ||
      "Per completare l'operazione potrebbe volerci qualche istante. Per favore attendi qui, senza lasciare la pagina.",
  };

  const goToError = () => {
    if (goBackErrorOnPaymentFormattedErrors.includes(error?.errorType?.toLowerCase() as string)) {
      const foundedErrors = goBackErrorOnPayment?.filter(
        (el) => el.error?.toLowerCase() === error?.errorType?.toLowerCase()
      );
      const foundedError = error?.errorCode
        ? foundedErrors?.find((el) => el.errorCode == error.errorCode)
        : foundedErrors?.find((el) => !el.errorCode) || foundedErrors?.[0];
      trackView(
        getTrackError(
          { ...error, isBlocking: foundedError?.isBlocking === 'true' },
          ID_FLOWS.UPFRONT_PAYMENT
        )
      );
      localStorage.setItem(PAYMENT_ERROR_TITLE, foundedError?.title || '');
      localStorage.setItem(PAYMENT_ERROR_MESSAGE, foundedError?.message || '');
      if (foundedError?.isClickToCall === 'true') {
        localStorage.setItem(PAYMENT_SHOW_CTC, '1');
      } else {
        localStorage.removeItem(PAYMENT_SHOW_CTC);
      }
      localStorage.setItem(BYPASS_CALL_API_SIA, '1');
      setTimeout(() => {
        onErrorRetry?.(foundedError);
      }, 200);
    } else {
      trackView(getTrackError(error, ID_FLOWS.UPFRONT_PAYMENT));
      if (!isDisabledRedirect) {
        localStorage.removeItem(BYPASS_CALL_API_SIA);
        window.open(shoppingCartUrl || redirectUrl, '_self');
      }
    }
  };

  useEffect(() => {
    LoggerInstance.debug('CheckOrderMobile', isSuccess, isLoading);
    if (!isLoading && isSuccess && !isError) {
      if (!isDisabledRedirect) {
        localStorage.removeItem(BYPASS_CALL_API_SIA);
        window.open(`${redirectUrl}/${PAGES.THANK_YOU}`, '_self');
      }
    } else if (!isLoading && isError) {
      if (blockingFormattedError.includes(error?.errorType?.toLowerCase() as string)) {
        const foundedErrors = blockingErrors.filter(
          (el) => el.error?.toLowerCase() === error?.errorType?.toLowerCase()
        );
        const foundedError = error?.errorCode
          ? foundedErrors?.find((el) => el.errorCode == error.errorCode)
          : foundedErrors?.find((el) => !el.errorCode) || foundedErrors?.[0];
        trackView(
          getTrackError(
            { ...error, isBlocking: foundedError?.isBlocking === 'true' },
            ID_FLOWS.UPFRONT_PAYMENT
          )
        );
        checkoutErrors?.showAndTrackError?.(
          genericError,
          handleError,
          undefined,
          foundedError?.title,
          foundedError?.message,
          () => openPopup(urlGenericErrorCallMeNow),
          product?.removeCTC ? undefined : buttonGenericError,
          undefined,
          undefined,
          isModalButtonSticky,
          false,
          false,
          false,
          paymentError?.icon || undefined
        );
      } else {
        goToError();
      }
    }
  }, [isLoading, isError, isSuccess, loadingForFinalPortIn]);

  useEffect(() => {
    if (portabilityStatus?.isSuccess) {
      setLoadingForFinalPortIn(false);
    }
  }, [portabilityStatus]);

  return <WarningInfo {...cmsWarningPayment} />;
};

export default UpfrontPaymentSubmitCard;

