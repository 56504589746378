import {
  getFormattedAddress,
  IDroppedCartHomepageCms,
  Placeholders,
} from '@vfit/consumer/data-access';
import { IAddressData, ITechnologyData } from '@vfit/shared/models';
import { checkWindow, purifyTextWithLogo } from '@vfit/shared/data-access';
import { purify } from '@vfit/shared/themes';

const evaluateCmsData = (
  rawCmsData: IDroppedCartHomepageCms,
  address?: IAddressData,
  technology?: ITechnologyData,
  userType?: string,
  price?: string,
  originalPrice?: string,
  isLockIn?: boolean
) => {
  const droppedCartData = rawCmsData?.droppedcarthomepage;
  const droppedCartTitle = isLockIn ? droppedCartData?.lockIn?.titleLockIn : droppedCartData?.title;
  const droppedCartDescription = isLockIn ? droppedCartData?.lockIn?.descriptionLockIn : droppedCartData?.description;
  const addressFromCookie =
    address?.displayedAddress === 'undefined' ? '' : address?.displayedAddress;
  const formattedAddress = getFormattedAddress(addressFromCookie || '');
  const formattedTechnology = technology?.displayedTecnology || '';
  const isPanicModeEnabled = checkWindow() && (window as any).isPanicModeFixed;
  const ctaLabelByUserType =
    userType === 'legacy' || isPanicModeEnabled
      ? droppedCartData?.ctaLabelAlternative
      : droppedCartData?.ctaLabel;

  const handlePlaceholders = (text: string) =>
    purifyTextWithLogo(
      text
        .replace(Placeholders.TECHNOLOGY, formattedTechnology)
        .replace(Placeholders.ADDRESS, formattedAddress)
        .replace(Placeholders.PRICE, price || '')
        .replace(Placeholders.ORIGINAL_PRICE, originalPrice || '')
    );

  const handleSpecs = (specs: { value: string }[]) =>
    specs?.map((spec) => ({
      value: handlePlaceholders(spec?.value || ''),
    }));

  return {
    image: droppedCartData?.image,
    imageMobile: droppedCartData?.imageMobile,
    category: droppedCartData?.category || '',
    title: droppedCartTitle || '',
    description: purify([handlePlaceholders(droppedCartDescription || '') || ''])?.[0],
    specs: handleSpecs(droppedCartData?.specs) || [{ value: '' }],
    ctaLabel: ctaLabelByUserType || '',
  };
};

export { evaluateCmsData };
