import { useQueryClient } from 'react-query';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  IAPIError,
  useCreateAddress,
  useLocateStore,
  useSearchPickUpLocations,
  useValidateAddress,
} from '@vfit/consumer/data-access';
import {
  IAddressResponse,
  IAddressType,
  ILocateStoreResponse,
  IPickUpLocation,
  IValidateAddressRequest,
  IValidateAddressResponse,
} from '@vfit/shared/models';

export interface PickupLocationsStepper extends IPickUpLocation {
  isSelected?: boolean;
  title: string;
  value: string;
  description?: string;
  detail?: string;
}

export interface ILocateStoreStepper extends ILocateStoreResponse {
  isSelected?: boolean;
  title: string;
  value: string;
  description?: string;
  detail?: string;
}

export interface ISearchPickupPoints {
  pickupPoints: PickupLocationsStepper[] | ILocateStoreStepper[] | undefined;
  isAddressObjectFul: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  startingSearchAddress: IAddressResponse;
  setStartingSearchAddress: Dispatch<SetStateAction<IAddressResponse>>;
}

export const useSearchPickUpPoints = (
  initialAddress?: IAddressResponse,
  address?: IValidateAddressRequest,
  currentStep?: number,
  deliveryType?: IAddressType
): ISearchPickupPoints => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [mutationIsNotFull, setMutationIsNotFull] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [pickupPointsList, setPickupPointsList] = useState<
    PickupLocationsStepper[] | ILocateStoreStepper[]
  >([]);
  const [startingSearchAddress, setStartingSearchAddress] =
    useState<IAddressResponse>(initialAddress);
  const shippingAddressObject = {
    ...address,
    displayStateOrProvince: address?.stateOrProvince,
  };

  const validatedPickupAddress: IValidateAddressResponse | IAPIError | undefined =
    queryClient.getQueryData('pickupAddress');
  const createdPickupAddress: IAddressResponse | IAPIError | undefined =
    queryClient.getQueryData('pickupCreatedAddress');
  const searchPostalOfficeDataQuery: IPickUpLocation[] | IAPIError | undefined =
    queryClient.getQueryData(
      `searchPickUpLocations${(createdPickupAddress as IAddressResponse)?.id}`
    );
  const searchPickupPointsDataQuery: IPickUpLocation[] | IAPIError | undefined =
    queryClient.getQueryData(
      `searchPickUpStoreLocations${(createdPickupAddress as IAddressResponse)?.id}`
    );
  const locateStoreDataQuery: ILocateStoreResponse[] | IAPIError | undefined =
    queryClient.getQueryData(
      `searchPickUpStoreLocations${(createdPickupAddress as IAddressResponse)?.id}`
    );

  const shippingFlowEnabler =
    !!shippingAddressObject?.city &&
    !!shippingAddressObject?.postalCode &&
    !!shippingAddressObject?.displayStateOrProvince &&
    !!shippingAddressObject?.stateOrProvince &&
    !!shippingAddressObject?.street &&
    !!shippingAddressObject?.streetNumber &&
    (shippingAddressObject?.city.toUpperCase() !==
      (createdPickupAddress as IAddressResponse)?.city?.toUpperCase() ||
      shippingAddressObject?.postalCode.toUpperCase() !==
        (createdPickupAddress as IAddressResponse)?.postalCode?.toUpperCase() ||
      shippingAddressObject?.stateOrProvince.toUpperCase() !==
        (createdPickupAddress as IAddressResponse)?.stateOrProvince?.toUpperCase() ||
      shippingAddressObject?.street.toUpperCase() !==
        (createdPickupAddress as IAddressResponse)?.street?.toUpperCase() ||
      shippingAddressObject?.streetNumber.toUpperCase() !==
        (createdPickupAddress as IAddressResponse)?.streetNumber?.toUpperCase());

  // These are vars for requests.
  const {
    mutate: validateAddressMutation,
    isLoading: validateAddressIsLoading,
    isSuccess: validateAddressIsSuccess,
    reset: resetValidateAddress,
  } = useValidateAddress();
  const {
    mutate: createAddressMutation,
    isLoading: createdAddressIsLoading,
    isSuccess: createAddressIsSuccess,
    reset: resetCreateAddress,
  } = useCreateAddress();
  const {
    mutate: searchPostalOfficeMutation,
    data: searchPostalOfficeData,
    isError: searchPostalOfficeIsError,
    isSuccess: searchPostalOfficeIsSuccess,
    reset: resetSearchPostalOffice,
  } = useSearchPickUpLocations();
  const {
    mutate: searchPickupPointsMutation,
    data: searchPickupPointsData,
    isError: searchPickupPointsIsError,
    isSuccess: searchPickupPointsIsSuccess,
    reset: resetSearchPickupPoints,
  } = useSearchPickUpLocations();
  const {
    mutate: locateStoreMutation,
    data: locateStoreData,
    isError: locateStoreIsError,
    isSuccess: locateStoreIsSuccess,
    reset: resetLocateStore,
  } = useLocateStore();

  useEffect(() => {
    if (!shippingFlowEnabler && !!shippingAddressObject) {
      setMutationIsNotFull(true);
    }

    if (
      !validateAddressIsLoading &&
      !!shippingAddressObject &&
      shippingFlowEnabler &&
      !validatedPickupAddress
    ) {
      setIsLoading(true);
      validateAddressMutation({
        placeObject: shippingAddressObject as IValidateAddressRequest,
        isPickup: true,
      });
    }
  }, [validateAddressIsLoading, shippingAddressObject, shippingFlowEnabler]);

  /*
   * Checking ValidateAddress error and success. Triggering CreateAddress Mutation.
   * Using again the flag "isBilling: true"
   */
  useEffect(() => {
    if ((validatedPickupAddress as IAPIError)?.error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage((validatedPickupAddress as IAPIError).errorMessage);
    }

    if (
      shippingFlowEnabler &&
      validatedPickupAddress &&
      validateAddressIsSuccess &&
      !createdAddressIsLoading &&
      !(validatedPickupAddress as IAPIError)?.error &&
      !createdPickupAddress
    ) {
      createAddressMutation({
        address: (validatedPickupAddress as any)?.validAddressList?.[0],
        isPickup: true,
      });
    }
  }, [validatedPickupAddress, validateAddressIsSuccess]);

  /**
   * Check if createdAddress request success or error & triggering new requests.
   */

  useEffect(() => {
    if ((createdPickupAddress as IAPIError)?.error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage((createdPickupAddress as IAPIError).errorMessage);
    }
    if (
      createAddressIsSuccess &&
      createdPickupAddress &&
      !(createdPickupAddress as IAPIError)?.error &&
      !(createdPickupAddress as IAPIError)?.error
    ) {
      setStartingSearchAddress(createdPickupAddress as IAddressResponse);
      switch (deliveryType) {
        case IAddressType.PO:
          searchPostalOfficeMutation({ id: (createdPickupAddress as IAddressResponse)?.id });
          break;
        case IAddressType.PP:
          searchPickupPointsMutation({
            id: (createdPickupAddress as IAddressResponse)?.id,
            options: IAddressType.PP,
          });
          break;
        case IAddressType.P:
          locateStoreMutation((createdPickupAddress as IAddressResponse)?.id);
          break;
        default:
          searchPostalOfficeMutation({ id: (createdPickupAddress as IAddressResponse)?.id });
      }
    }
  }, [deliveryType, createdPickupAddress, createAddressIsSuccess]);

  // Checking createdAddress error and success. Triggering CreateAddress Mutation

  useEffect(() => {
    if (
      searchPostalOfficeIsSuccess &&
      searchPostalOfficeData &&
      !(searchPostalOfficeDataQuery as IAPIError)?.errorMessage
    ) {
      setIsLoading(false);
      setIsSuccess(true);
      setPickupPointsList(searchPostalOfficeData as PickupLocationsStepper[]);
    }
    if (searchPostalOfficeIsError || (searchPostalOfficeDataQuery as IAPIError)?.errorMessage) {
      setPickupPointsList([]);
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
      setErrorMessage((searchPostalOfficeDataQuery as IAPIError)?.errorMessage);
    }
  }, [
    searchPostalOfficeIsSuccess,
    searchPostalOfficeIsError,
    searchPostalOfficeData,
    searchPostalOfficeDataQuery,
  ]);

  useEffect(() => {
    if (
      searchPickupPointsIsSuccess &&
      searchPickupPointsData &&
      !(searchPickupPointsDataQuery as IAPIError)?.errorMessage
    ) {
      setIsLoading(false);
      setIsSuccess(true);
      setPickupPointsList(searchPickupPointsData as PickupLocationsStepper[]);
    }
    if (searchPickupPointsIsError || (searchPickupPointsDataQuery as IAPIError)?.errorMessage) {
      setPickupPointsList([]);
      setIsError(true);
      setIsLoading(false);
      setIsSuccess(false);
      setErrorMessage((searchPickupPointsDataQuery as IAPIError)?.errorMessage);
    }
  }, [
    searchPickupPointsIsSuccess,
    searchPickupPointsIsError,
    searchPickupPointsData,
    searchPickupPointsDataQuery,
  ]);

  useEffect(() => {
    if (
      locateStoreIsSuccess &&
      locateStoreData &&
      !(locateStoreDataQuery as IAPIError)?.errorMessage
    ) {
      setIsLoading(false);
      setIsSuccess(true);
      setPickupPointsList(locateStoreData as ILocateStoreStepper[]);
    }
    if (locateStoreIsError || (locateStoreDataQuery as IAPIError)?.errorMessage) {
      setPickupPointsList([]);
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(true);
      setErrorMessage((locateStoreDataQuery as IAPIError)?.errorMessage);
    }
  }, [locateStoreIsSuccess, locateStoreIsError, locateStoreData, locateStoreDataQuery]);

  useEffect(() => {
    if (currentStep === 0) {
      setPickupPointsList([]);
      setIsSuccess(false);
      setIsError(false);
      setErrorMessage('');
      resetValidateAddress();
      resetCreateAddress();
      resetSearchPostalOffice();
      resetSearchPickupPoints();
      resetLocateStore();
    }
  }, [currentStep]);

  return {
    pickupPoints: pickupPointsList,
    isAddressObjectFul: mutationIsNotFull,
    isSuccess,
    isLoading,
    isError,
    errorMessage,
    startingSearchAddress,
    setStartingSearchAddress,
  };
};
