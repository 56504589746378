import React, { useEffect } from 'react';
import { API, PAGES, trackLink } from '@vfit/shared/data-access';
import { GenericDetail, VfModal } from '@vfit/shared/components';
import { useDeviceType } from '@vfit/shared/hooks';
import { CMS_CONFIG, ITermsAndCondition, useCmsConfig } from '@vfit/consumer/data-access';
import { ICommonData } from '@vfit/shared/models';
import { getWithdrawalLabels } from '../../termsCard.utils';
import { useCheckout } from '../../../../../iBuyFixed.context';

const WithdrawalPopup = ({
  handleGoNextSlide,
  handleOnChangeEnableGoNext,
  isOpenWithdrawalPopUp,
  onChangePopupWithdrawal,
}: ICommonData & {
  isOpenWithdrawalPopUp?: boolean;
  onChangePopupWithdrawal: (isOpen: boolean) => void;
}) => {
  const { termsAndConditions, setTermsAndConditions } = useCheckout();
  const { isDesktop, isTablet } = useDeviceType();
  const cmsData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_TERMS_AND_CONDITIONS
  ) as ITermsAndCondition;
  const { titlePopup, descriptionPopup, imagePopup, buttonActiveLabel, buttonWaitLabel } =
    getWithdrawalLabels(cmsData);
  const setModalHeight = () => (isTablet && 563) || (isDesktop && 563) || 563;

  const enablePopUpListener = (enable: boolean) => {
    const reconsider = termsAndConditions?.optionToReconsider === 'yes';
    const nextButton = document.querySelector('#sticky-offer-next');
    const callBack = () => {
      if (!nextButton?.hasAttribute('data-popup')) return;
      onChangePopupWithdrawal?.(true);
    };
    if (enable && reconsider) {
      nextButton?.setAttribute('data-popup', 'true');
      nextButton?.addEventListener('click', callBack);
    }
    if (!reconsider || !enable) {
      nextButton?.removeAttribute('data-popup');
    }
  };

  const actionPopup = (changeFlag: boolean) => {
    const nextButton = document.querySelector('#sticky-offer-next');
    if (changeFlag) {
      setTermsAndConditions({ ...termsAndConditions, optionToReconsider: 'no' });
    }
    const labelToTrack = `${changeFlag ? buttonActiveLabel : buttonWaitLabel}`;
    trackLink(labelToTrack);
    onChangePopupWithdrawal?.(false);
    nextButton?.removeAttribute('data-popup');
    handleGoNextSlide?.();
  };

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      if (termsAndConditions.isSuccessTermsAndConditions && termsAndConditions.consensus) {
        enablePopUpListener(true);
      }
    }
  }, [termsAndConditions]);

  useEffect(() => {
    if (isOpenWithdrawalPopUp) trackLink('conferma 14giorni');
  }, [isOpenWithdrawalPopUp]);

  useEffect(
    () => () => {
      enablePopUpListener(false);
    },
    []
  );

  return (
    <VfModal
      isOpen={isOpenWithdrawalPopUp || false}
      handleClose={() => onChangePopupWithdrawal?.(false)}
      height={setModalHeight()}
    >
      <GenericDetail
        title={titlePopup}
        invertButtons
        titleAlwaysCenter
        description={descriptionPopup}
        options={{ isModal: true, textAlign: 'center' }}
        imageUrl={imagePopup}
        submitButtonLabel={buttonWaitLabel}
        submitButtonAction={() => {
          actionPopup(false);
        }}
        secondButtonAction={() => {
          actionPopup(true);
        }}
        secondButtonLabel={buttonActiveLabel}
      />
    </VfModal>
  );
};

export default WithdrawalPopup;
