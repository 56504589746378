import { IPortabilityCMS } from '@vfit/consumer/data-access';

export const organizePortabilityLineCard = (dataFromCms: IPortabilityCMS) => {
  const prefix = dataFromCms?.portability?.activationFixedLine;

  const cardData = {
    title: prefix?.title || '',
    items: [
      {
        title: prefix?.choice?.option1 || '',
        value: 'yes',
      },
      {
        title: prefix?.choice?.option2 || '',
        value: 'internetOnly',
      },
      {
        title: prefix?.choice?.option3 || '',
        value: 'no',
      },
    ],
  };

  const errorOnPortability = {
    erroMessage: {
      genericError: {
        title: dataFromCms?.portability?.errorOnPortability?.message || '',
      },
    },
  };

  return { cardData, errorOnPortability };
};
