import { IProduct } from "@vfit/consumer/data-access";

export interface IConvergenceModalProps {
  isOpen: boolean;
  product?: IProduct;
  handleClose: () => void;
  handleContinue: () => void;
  handleAccept: () => void;
}

export enum ETrackConvergenceModal {
  OFFER_STEP="offerStep",
  DETAILS_STEP = "detailsStep"
}
