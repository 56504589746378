import React from 'react';
import { getButtonActionByActionType } from '@vfit/shared/data-access';
import { Faq as FaqWidget } from '@vfit/shared/components';
import { useRouter } from 'next/router';
import { IFaqWidget } from './faq.models';
import { Container } from './faq.style';

const Faq = ({ faq, containerStyle, topText, divider, disclaimer }: IFaqWidget) => {
  const { push } = useRouter();
  return (
    <Container>
      <FaqWidget
        faq={faq}
        containerStyle={containerStyle}
        topText={topText}
        divider={divider}
        disclaimer={disclaimer}
        handleButtonClick={() => getButtonActionByActionType(faq.showMore, push)}
      />
    </Container>
  );
};

export default Faq;
