import React, { useEffect } from 'react';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import { Player } from '@lottiefiles/react-lottie-player';
import { datadogRum } from '@datadog/browser-rum';
import { Container, TextLoaderEmpty } from '../TermsCard/components/PostAddress/postAddress.style';
import { ID_FLOWS } from '../../checkout.constants';
import { useCheckout } from '../../../iBuyFixed.context';

const LoadingCard = () => {
  const { product, currentStepKey } = useCheckout();

  const getLoader = (): string => {
    switch (currentStepKey) {
      case ID_FLOWS.TERMS_AND_CONDITIONS:
      case ID_FLOWS.DELIVERY_ADDRESS:
        return product?.loaders?.yourAddressCard || '';
      case ID_FLOWS.PAYMENT_METHOD_WITHOUT_RECURRING:
      case ID_FLOWS.PAYMENT_METHOD:
      case ID_FLOWS.RECURRENT_PAYMENT_CARD:
      case ID_FLOWS.UPFRONT_PAYMENT:
      case ID_FLOWS.UPFRONT_SELECTION:
        return product?.loaders?.choosePayment || product?.loaders?.shoppingCart || '';
      default:
        return (
          product?.loaders?.shoppingCart ||
          product?.loaders?.yourAddressCard ||
          product?.loaders?.choosePayment ||
          ''
        );
    }
  };

  const checkStuckTimeout = () => {
    datadogRum.addError({
      step: currentStepKey,
      message:
        'La pagina sembra non rispondere in questo momento. Attendi o premi il tasto in basso per essere ricontattato.',
      error: 'stuck',
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkStuckTimeout();
    }, 120000);

    return () => clearInterval(intervalId);
  }, []);

  if (!getLoader()) return <ShadowsGroup quantity={2} heights={90} />;

  return (
    <Container>
      <TextLoaderEmpty />
      <Player
        src={requireStaticImageAtomComponent(getLoader())?.src || ''}
        className="player"
        loop
        autoplay
      />
    </Container>
  );
};

export default LoadingCard;

