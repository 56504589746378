import { useMutation, useQueryClient } from 'react-query';
import {
  errorMock,
  getCustomerCountry,
  getCustomerDocument,
  getCustomerIdentification,
} from '@vfit/consumer/data-access';
import { IFiscalCodeReverse, API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import {
  IAddressResponse,
  ICreateCustomerPayload,
  IOfferingServiceabilityResponse,
  IRequest,
  IUseCustomerParams,
  PostCustomerResponse,
} from '@vfit/shared/models';

/**
 * This method calls the service that creates the customer.
 * Method: POST
 * @param req
 * @param cfData
 * @param customOptions
 */
const createCustomer = (
  req: IRequest,
  cfData: IFiscalCodeReverse,
  customOptions?: CustomOptions
): Promise<PostCustomerResponse> => {
  const day = cfData && cfData.day > 9 ? cfData.day : `0${cfData.day}`;
  const month = cfData && cfData.month > 9 ? cfData.month : `0${cfData.month}`;
  const year = cfData && cfData.year;
  const gender = cfData && cfData.gender;
  const identification = getCustomerIdentification(req.owningIndividual);

  const payload: ICreateCustomerPayload = {
    owningIndividual: {
      nation: req.address.country,
      fiscalCode: req.owningIndividual.fiscalCode.toUpperCase(),
      firstName: req.owningIndividual.firstName,
      lastName: req.owningIndividual.lastName,
      identification: [
        {
          identificationType: req?.documentObj?.id,
          nationality: req?.countryObj?.id,
          identificationNumber: identification?.identificationNumber,
          expirationDate: identification?.expirationDate.split('/').reverse().join('-'),
        },
      ],
      birthDate: `${year}-${month}-${day}`,
      birthPlace: req.owningIndividual.fiscalCode.substring(11, 15).toUpperCase(),
      gender: gender === 'M' ? 'male' : 'female',
      contactMedium: [
        ...req.owningIndividual.contactMedium,
        {
          type: 'postalAddress',
          preferred: false,
          city: req.address.city,
          country: req.address.country,
          postalCode: req.address.postalCode,
          streetNumber: req.address.streetNumber,
          externalId: req.address.externalId,
          stateOrProvince: req.address.stateOrProvince,
          street: req.address.street,
          istatCode: req.address.istatCode,
          homeZone: req.address.homeZone,
          ...(req.address?.latitude && { latitude: req.address.latitude?.toString() || '' }),
          ...(req.address?.longitude && { longitude: req.address.longitude?.toString() || '' }),
        },
      ],
      preferredContactMethod: {
        name: 'C',
      },
      preferredContactTime: {
        name: 'Morning',
      },
      preferredLanguage: {
        name: 'it-IT',
      },
    },
    displayBirthDate: `${day}/${month}/${year}`,
    displayGender: gender === 'M' ? 'Maschio' : 'Femmina',
    subtype: 'I',
    type: 'individual',
    marketTag: 'VF',
  };
  LoggerInstance.debug(payload, 'HERE CREATE CUSTOMER PAYLOAD');

  return nextClient.post(API.CUSTOMER, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });
};

/**
 * Custom mutation hook for run createCustomer using the createCustomer, the customerData and the shoppingCart.
 * Call this after the customer and the shoppingCart have just been created.
 * @returns
 */
export const useCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['creationCustomer'],
    (data: IUseCustomerParams) => {
      const { owningData, cfInverse } = data;
      const serviceability: IOfferingServiceabilityResponse =
        getFromLocalStorageByKey('offeringServiceability');
      const location: IAddressResponse = getFromLocalStorageByKey('createdAddress');
      const selectedCountry = getCustomerCountry(owningData);
      const selectedDocument = getCustomerDocument(owningData);
      const req: IRequest = {
        owningIndividual: {
          ...owningData.owningIndividual,
          nation: selectedCountry?.[0]?.displayName || '',
        },
        address: {
          ...(serviceability?.serviceabilityAddress || location),
        },
        countryObj: selectedCountry?.[0],
        documentObj: selectedDocument?.[0],
      };
      return createCustomer(req, cfInverse);
    },
    {
      onSuccess: (data: PostCustomerResponse) => {
        localStorage.setItem('postCustomer', JSON.stringify(data));
        queryClient.setQueryData('postCustomer', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - postCustomer: `, error);
        queryClient.setQueryData('postCustomer', error);
        localStorage.setItem(`postCustomer`, JSON.stringify(errorMock(`postCustomer`, error)));
      },
    }
  );
};
