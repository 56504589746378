import type { SVGProps } from 'react';
import { memo } from 'react';

const MasterCardLight = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M44 0H4C1.79086 0 0 1.79086 0 4V28C0 30.2091 1.79086 32 4 32H44C46.2091 32 48 30.2091 48 28V4C48 1.79086 46.2091 0 44 0Z"
      fill="#F2F0EC"
    />
    <path d="M29.1104 7.65991H18.9004V24.3399H29.1104V7.65991Z" fill="#F26122" />
    <path
      d="M20.0007 16.0001C19.9839 12.7536 21.4584 9.67911 24.0007 7.66006C19.651 4.23935 13.4027 4.73608 9.64802 8.80106C5.89337 12.866 5.89337 19.1341 9.64802 23.1991C13.4027 27.264 19.651 27.7608 24.0007 24.3401C21.4584 22.321 19.9839 19.2465 20.0007 16.0001Z"
      fill="#EA1D25"
    />
    <path
      d="M40.1395 22.5699V22.2299H40.2895V22.1599H39.9395V22.2299H40.0795V22.5699H40.1395ZM40.8195 22.5699V22.1599H40.7195L40.5995 22.4499L40.4695 22.1599H40.3695V22.5699H40.4395V22.2599L40.5595 22.5299H40.6395L40.7495 22.2599V22.5699H40.8195Z"
      fill="#F69E1E"
    />
    <path
      d="M41.16 16C41.1574 20.0593 38.8389 23.7614 35.1879 25.5358C31.5369 27.3102 27.1935 26.846 24 24.34C28.6001 20.7179 29.3967 14.0543 25.78 9.44999C25.2616 8.78326 24.6638 8.18216 24 7.65999C27.1935 5.15398 31.5369 4.68973 35.1879 6.46416C38.8389 8.23859 41.1574 11.9406 41.16 16Z"
      fill="#F69E1E"
    />
  </svg>
);

export default memo<SVGProps<SVGSVGElement>>(MasterCardLight);
