import { CustomText } from '@vfit/shared/atoms';
import { AddressManual } from '@vfit/shared/components';
import {
  CMS_CONFIG,
  useCmsConfig,
  ICoverageToolCongifg,
  retrieveProduct,
} from '@vfit/consumer/data-access';
import { fonts } from '@vfit/shared/themes';
import { API, PAGES } from '@vfit/shared/data-access';
import { IEditAddressCmsConfig, IPikedForMutation } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { Title } from './coverageTool.style';
import {
  IAddressObject,
  ICoverageToolProps,
  ICoverageToolType,
  IInputs,
} from './coverageTool.models';
import {
  CoverageToolContainer,
  CoverageToolContent,
  ManualContainer,
} from './coverageToolContainers.style';
import { organizeEditAddressManual } from './coverageTool.utils';
import { useCoverageToolModalProvider } from '../CoverageToolModal/coverageToolModal.context';

const CoverageToolManual = ({ state, onSubmit }: ICoverageToolProps) => {
  const { isForcedManual } = useCoverageToolModalProvider();

  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;

  const trimValue = (price: string) => price?.replace(/[^0-9.,]/g, '');

  const product = retrieveProduct();
  const pageProduct = {
    product_name: product?.slug,
    product_id: `${product?.offerId}`,
    product_price: product?.price && trimValue(product.price),
    product_category: 'fixed',
    product_quantity: '1',
  };

  const { trackView } = useTracking({
    event: ['tool_start'],
    event_label: 'manual address input',
    opts: {
      event_category: 'tools',
      page_type: 'address',
      page_section: 'fixed offers',
      page_subsection: 'coverage tool',
    },
    pageProduct,
  });

  const handleTrackError = (fieldName: string) => {
    trackView({
      event_name: 'tool_error',
      event_label_track: 'error',
      event_category: `${fieldName} field not found`,
    });
  };

  const editManualFixed = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_EDIT_ADDRESS
  ) as IEditAddressCmsConfig;

  const cmsConfig = organizeEditAddressManual(editManualFixed);

  const forceManualSubmit = (data: IAddressObject, pikedForMutation: IPikedForMutation) => {
    const dataToSubmit = {
      ...data,
      ...{
        ...pikedForMutation,
        latitude: parseFloat(pikedForMutation.latitude),
        longitude: parseFloat(pikedForMutation.longitude),
      },
    } as IInputs;
    const coverageType = ICoverageToolType.MAPS;
    onSubmit?.(dataToSubmit, coverageType);
  };

  return (
    <CoverageToolContainer>
      <CoverageToolContent>
        <Title>{coverageToolConfig.coveragetool.addressInput.title}</Title>
        <CustomText
          text={
            isForcedManual
              ? coverageToolConfig.coveragetool.manualInput.description
              : coverageToolConfig.coveragetool.manualInput.descriptionOnError
          }
          textAlign="center"
          size={20}
          lineHeight={26}
          fontFamily={fonts.regular}
          modal
        />
      </CoverageToolContent>
      <ManualContainer>
        <AddressManual
          state={state}
          onSubmit={forceManualSubmit}
          cmsConfig={cmsConfig}
          handleTrackError={handleTrackError}
        />
      </ManualContainer>
    </CoverageToolContainer>
  );
};

export default CoverageToolManual;

