import {ErrorCodes, IGenericErrorCMS, IPersonalInfoCMS} from '@vfit/consumer/data-access';
import { ErrorDetail } from '@vfit/shared/data-access';
import {ICMSError} from "@vfit/shared/models";

export const CMS_LABELS = {
  roSubtitle:
    'Ecco un riepilogo dei tuoi contatti personali per essere ricontattato al telefono e per ricevere via mail le comunicazioni sull’attivazione. Verifica che siano corretti o modificali prima di proseguire.',
  roTelephoneLable: 'Il tuo numero di telefono',
  roEmailLable: 'Il tuo indirizzo email',
  roCtaTelephone: 'Modifica numero',
  roCtaEmail: 'Modifica email',
};

export const organizeContact = (ContactCMS: IPersonalInfoCMS) => ({
  title: ContactCMS?.personalinfo?.clientRegistered?.title || '',
  subtitle: ContactCMS?.personalinfo?.generalInfo?.subtitle || '',
  telephoneLable: ContactCMS?.personalinfo?.selectInput?.telephone || '',
  emailLable: ContactCMS?.personalinfo?.selectInput?.email || '',
  footerInfo: ContactCMS?.personalinfo?.clientRegistered?.smallInfo || '',
});

export const handleCRMCodes = (crmCode : string) => {
  switch(crmCode) {
    case "CRM-0020":
    case "CRM-0024":
      return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
    case "CRM-0021":
    case "CRM-0023":
      return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
    case "CRM-0022":
    case "CRM-0025":
      return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
    default:
      return ''
  }
}

export const handleErrorCustomer = (errorCode : string, errorList: ICMSError[], genericError? : IGenericErrorCMS) : ErrorDetail => {
  const errorDetails = errorList.find((item : ICMSError) => item.error === errorCode);
  const errorGeneric = genericError?.genericerror || null;
  return {
    title: errorDetails?.title || errorGeneric?.title || '',
    message: errorDetails?.message || errorGeneric?.description || '',
    actionText: errorDetails?.button?.title || errorGeneric?.buttonLabel || '',
    url: errorDetails?.button?.urlForRedirect || ''
  }

}
