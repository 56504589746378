import { IPortabilityCMS } from '@vfit/consumer/data-access';
import { IPortabilityObject } from '@vfit/shared/models';
import { IPortabilityData } from './components/Portability/portability.model';

const organizePortabilityData = (
  portabilityCMSData: IPortabilityCMS,
  portability: IPortabilityObject
): IPortabilityData => ({
  title: portabilityCMSData?.portability?.provider?.title || '',
  migrationCodeCtaLabel:
    portabilityCMSData?.portability?.provider?.operatorSelect?.findMigrationLabel || '',
  skipMigrationCodeCheckboxText:
    portabilityCMSData?.portability?.provider?.operatorSelect?.skipMigrationCodeCheckboxText || '',
  otherInformationText: portabilityCMSData?.portability?.provider?.operatorSelect?.smallInfo || '',
  operatorsList: portabilityCMSData?.portability?.operatorList || null,
  modalMigrationCode: {
    title: portabilityCMSData?.portability?.modal?.title || '',
    description: portabilityCMSData?.portability?.modal?.description || '',
    addMigrationCodeLabel: portabilityCMSData?.portability?.modal?.buttonLabel || '',
    noCode: portabilityCMSData?.portability?.modal?.noCode || '',
    migrationCodeLabel: portabilityCMSData?.portability?.modal?.codeNotFound || '',
  },
  isMultipleOperators: portability?.portabilityOperators === 'double' || false,
  findmigrationlabel: '',
});

export { organizePortabilityData };
