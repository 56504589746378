import { pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const OverlayContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  width: 600px;
  padding: 0 16px;

  p {
    margin: 0;
  }
`

export const Title = styled.div`
  margin: 0;
  text-align: center;
  ${pxToCssFont(40, 48)};
`;

export const QRCode = styled.div`
  width: 150px;
  height: 150px;
  object-fit: cover;
`;

export const Image = styled.div`
  height: 120px;
`;

export const Description = styled.p`
  margin: 0;
  text-align: center;
  ${pxToCssFont(18, 26)};
`;

export const ButtonContainer = styled.div`
  width: 84px;
`
