import React, { useEffect } from 'react';
import { ImageAtoms, CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { Player } from '@lottiefiles/react-lottie-player';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import {
  ProductSolutionsProduct,
  ProductSolutionsContainer,
  ProductSolutionsText,
  Container,
} from './summaryOffer.style';
import { getSlideSize } from './summaryOffer.utils';
import { ISummaryOfferProps } from './summaryOffer.models';
import ProductCard from '../ProductCard/productCard';
import ProductSolutionLoader from './components/ProductSolutionLoader/productSolutionLoader';

const SummaryOffer = ({
  product,
  lottie,
  image,
  title,
  message,
  containerStyle,
  onActivateSolution,
  onEditAddress,
  isLoading,
}: ISummaryOfferProps) => {
  const { isMobile, isTablet, isDesktop } = useDeviceType();

  useEffect(() => {
    const editAddressEl = document.getElementById('edit-address');
    if (editAddressEl && onEditAddress) {
      editAddressEl.addEventListener('click', onEditAddress);
    }
  });

  const renderProductCard = () => {
    const customSize = getSlideSize(0, isMobile, isTablet);
    return (
      <div
        style={{
          ...customSize,
          margin: isMobile ? '0 auto' : 'inherit',
        }}
      >
        <ProductSolutionsProduct>
          <ProductCard
            hideSpecs={!product?.isLockInMMProduct}
            hideActions
            noMargin
            product={product}
            onActive={onActivateSolution}
            isLoading={isLoading}
            large={isDesktop}
            isShoppingCart
          />
        </ProductSolutionsProduct>
      </div>
    );
  };

  const renderHeader = () => (
    <Container>
      {lottie && (
        <Player
          className="Player"
          src={requireStaticImageAtomComponent(lottie).src}
          loop
          autoplay
        />
      )}
      {!lottie && image && (
        <div className="Image">
          <ImageAtoms image={image} nameCard="Icon" />
        </div>
      )}
      {title && <div className="Title">{title}</div>}
      {message && (
        <div className="Description">
          <CustomText text={message} />
        </div>
      )}
    </Container>
  );

  return (
    <ProductSolutionsContainer style={{ ...containerStyle }}>
      <ProductSolutionsText>
        {isLoading && <ProductSolutionLoader lottieFile={product.loaders?.shoppingCart || ''} />}
        {!isLoading && renderHeader()}
      </ProductSolutionsText>
      {renderProductCard()}
    </ProductSolutionsContainer>
  );
};

export default SummaryOffer;
