import { useEffect, useState } from 'react';
import { useAuthorizeMutation, errorMock } from '@vfit/consumer/data-access';
import { ISIAFlowType } from '@vfit/shared/models';
import { DEFAULT_AUTHORIZE_AUTHORIZATION_FLOW } from '../../../iBuyFixed.context.data';
import { IUseAuthorizeAuthorizationFlowResponse } from './useAuthorizeAuthorizationFlow.models';

export const useAuthorizeAuthorizationFlow = (
  selectedItem: string | undefined,
  siaFlowType?: ISIAFlowType,
  authorizationOperationMethod?: 'capture' | 'verify'
): IUseAuthorizeAuthorizationFlowResponse => {
  const [authorizeAuthorizationFlow, setAuthorizeAuthorizationFlow] =
    useState<IUseAuthorizeAuthorizationFlowResponse>(DEFAULT_AUTHORIZE_AUTHORIZATION_FLOW);
  const [isStartAuthorizeAuthorization, setIsStartAuthorizeAuthorization] = useState(false);
  const {
    error: authorizeError,
    isLoading: isLoadingAuthorize,
    isError: isErrorAuthorize,
    isSuccess: isSuccessAuthorize,
    mutate: authorizeMutation,
    reset: resetAuthorize,
  } = useAuthorizeMutation();

  useEffect(() => {
    if (isLoadingAuthorize) {
      setAuthorizeAuthorizationFlow({
        ...authorizeAuthorizationFlow,
        authorize: {
          ...authorizeAuthorizationFlow.authorize,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorAuthorize) {
      setAuthorizeAuthorizationFlow({
        ...authorizeAuthorizationFlow,
        authorize: {
          ...authorizeAuthorizationFlow.authorize,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('authorize', authorizeError),
        },
      });
    } else if (isSuccessAuthorize) {
      setAuthorizeAuthorizationFlow({
        ...authorizeAuthorizationFlow,
        authorize: {
          ...authorizeAuthorizationFlow.authorize,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
    }
  }, [isSuccessAuthorize, isLoadingAuthorize, isErrorAuthorize]);

  const startFlow = () => {
    authorizeMutation({
      payMeanType: selectedItem || '',
      ...(authorizationOperationMethod && {
        authorizationOperation: authorizationOperationMethod,
      }),
      ...(siaFlowType && { siaFlowType }),
    });
  };

  useEffect(() => {
    if (isStartAuthorizeAuthorization) {
      setIsStartAuthorizeAuthorization(false);
      startFlow();
    }
  }, [isStartAuthorizeAuthorization]);

  useEffect(() => {
    if (selectedItem && !isStartAuthorizeAuthorization) {
      resetAuthorize();
      setIsStartAuthorizeAuthorization(true);
    }
  }, [selectedItem]);

  return authorizeAuthorizationFlow;
};
