import { useEffect, useState } from 'react';
import { checkWindow } from '@vfit/shared/data-access';
import { IN_ACTIVATION } from '@vfit/consumer/data-access';

export function useRedirectCoverageToolFlow(cmsId: string) {
  const [isInActivation, setIsInActivation] = useState(false);

  const checkIsInActivation = () => {
    if (!checkWindow()) {
      return;
    }
    const queryString = new URLSearchParams(window?.location?.search);
    const isActivation = queryString?.get(IN_ACTIVATION);
    if (isActivation && isActivation === cmsId.toString()) {
      setIsInActivation(true);
      setTimeout(() => {
        window.history.pushState({}, document.title, window.location.pathname);
      }, 1500);
    }
  };

  useEffect(() => {
    checkIsInActivation();
  }, [cmsId]);

  return [isInActivation];
}
