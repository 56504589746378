import { IListRecognitionSimMethodsSelections, IPersonalInfoCmsMobile, replaceShippingCost } from '@vfit/consumer/data-access';
import { ECodeIdentification } from '@vfit/shared/models';

export const organizeRecognitionSimMethodSelection = (
  data: IPersonalInfoCmsMobile,
  isMNP: boolean,
  isEsim: boolean,
  price = ''
) => {
  let itemsToReturn : Array<IListRecognitionSimMethodsSelections> = [];
  if (!isEsim && !isMNP) {
     itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elements?.[0],
        value: ECodeIdentification.VR,
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elements?.[1],
        value: ECodeIdentification.CO,
      },
    ]
  } 
  if (!isEsim && isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[0],
        value: ECodeIdentification.VR,
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelection?.elementsMnp?.[1],
        value: ECodeIdentification.CO,
      },
    ];
  } 
  if (isEsim && !isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[0],
        value: ECodeIdentification.VR,
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elements?.[1],
        value: ECodeIdentification.CO,
      },
    ]
  } 
  if (isEsim && isMNP) {
    itemsToReturn = [
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[0],
        value: ECodeIdentification.VR,
      },
      {
        ...data?.personalinfomobile?.recognitionMethodSelectionEsim?.elementsMnp?.[1],
        value: ECodeIdentification.CO,
      },
    ]
  }

  return {
    title: replaceShippingCost(
      data?.personalinfomobile?.recognitionMethodSelection?.title || '',
      price
    ),
    description: replaceShippingCost(
      data?.personalinfomobile?.recognitionMethodSelection?.description || '',
      price
    ),
    items: itemsToReturn,
  };
};
