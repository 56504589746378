import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError } from '@vfit/shared/data-access';
import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IUpdateShoppingCartActivationCostPayload } from './updateShoppingCartActivationCost.models';

/**
 * Shopping cart Activation Cost Update for FWA
 * @param cartId
 * @param internetCartItemId
 * @param payload
 * @returns
 */
const updateShoppingCartActivationCostService = (
  cartId: string,
  internetCartItemId: string,
  payload: IUpdateShoppingCartActivationCostPayload[]
) =>
  nextClient.patch(
    `${API.SHOPPING_CART}/${cartId}/shoppingCartItem/${internetCartItemId}`,
    payload
  ) as Promise<IShoppingCartResponse>;

/**
 * This method is used to handle the updateShoppingCartActivationCostService
 * @returns
 */
export const useShoppingCartActivationCostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'updateShoppingCartPayment',
    (isOneTimePayment?: boolean) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      const internetCartItemId = shoppingCart?.cartItem?.[0]?.internet?.[0]?.id;
      const installmentInfo = shoppingCart?.cartItem?.[0]?.internet?.[0]?.itemPrice?.find(
        (item) => item?.type === 'activation'
      )?.installmentInfo;
      const productId = installmentInfo?.installmentExtention?.productID;
      const productCharacteristicId = installmentInfo?.installmentCharacteristicId;
      const value = isOneTimePayment ? '0' : '24';
      const payload: IUpdateShoppingCartActivationCostPayload[] = [
        {
          op: 'replace',
          productType: 'containedProduct',
          productId,
          productCharecteristic: [
            {
              id: productCharacteristicId,
              value,
            },
          ],
        },
      ];
      return updateShoppingCartActivationCostService(cartId, internetCartItemId, payload);
    },
    {
      onSuccess: (data: IShoppingCartResponse) => {
        localStorage.setItem('shoppingCart', JSON.stringify(data));
        queryClient.setQueryData('shoppingCart', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - updateShoppingCartActivationCost: `, error);
        queryClient.setQueryData(
          'shoppingCart',
          errorMock(`updateShoppingCartActivationCost`, error)
        );
        localStorage.setItem(
          `shoppingCart`,
          JSON.stringify(errorMock(`updateShoppingCartActivationCost`, error))
        );
      },
    }
  );
};
