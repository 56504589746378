import { useEffect } from 'react';
import { API, PAGES, trackLink } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  IGenericErrorCMS,
  IVoucherCMS,
  organizeVoucherInsertionCard,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { ICommonData, IUpdateProductPriceType } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import { useCheckout } from '../../../../../iBuyFixed.context';
import { useVoucherSuccessCardTagging } from './voucherSuccessCard.tagging';
import { isNotFirstTime, setIsNotFirstTime } from './voucherSuccessCard.utils';
import { updateProductPrice } from '../../../../../ShoppingCart/shoppingCart.utils';

export const useVoucherSuccessCard = ({
  handleOnChangeEnableGoNext,
  handleGoNextSlide,
}: ICommonData) => {
  const {
    checkoutErrors,
    voucher,
    product,
    setConfiguredStickyStepperLabel,
    setVoucher,
    setProduct,
    setIsDisabledBackButton,
  } = useCheckout();
  useVoucherSuccessCardTagging();
  const voucherApi = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_FIXED_VOUCHERS
  ) as IVoucherCMS;
  const voucherCms = organizeVoucherInsertionCard(voucherApi, voucher.voucherId);
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const message = purify([product?.vouchers?.message || ''])?.[0] || '';
  const voucherMessage = message || '';

  const applyVoucher = (event: Event) => {
    event.stopPropagation();
    if (voucher.validateVoucher.isSuccess && !voucher?.isAppliedVoucher) {
      handleOnChangeEnableGoNext?.(false);
      setIsDisabledBackButton(true);
      setVoucher({ ...voucher, isApplyVoucher: true });
    }
  };

  const resetVoucherValidation = (event: Event) => {
    if (!voucher?.isAppliedVoucher) {
      event.stopPropagation();
      voucher?.resetData?.();
    }
  };

  const checkVoucher = () => {
    const isErrorVoucher = voucher.voucher.isError || voucher.shoppingCart.isError;
    if (isErrorVoucher) {
      const error = voucher?.voucher?.error || voucher?.shoppingCart?.error;
      const action = () => voucher?.resetData?.();
      checkoutErrors?.showAndTrackError?.(genericError, action, error, '', '', action);
    } else if (voucher?.isAppliedVoucher) {
      handleOnChangeEnableGoNext?.(true);
      setIsDisabledBackButton(false);
      if (!isNotFirstTime()) {
        localStorage.setItem('tProd_backUp', JSON.stringify(product));
        setProduct({
          ...product,
          ...updateProductPrice(product, IUpdateProductPriceType.EDIT),
          voucherPromoLabel: product?.vouchers?.codeApplied?.replace(
            '$VOUCHER',
            voucher?.voucherId || ''
          ),
          voucherPromoMessage: voucherMessage,
          voucherPromoDetail: purify([product?.vouchers?.description || ''])?.[0],
          ...(product?.offerDetailsVoucher &&
            product?.offerDetailsVoucher?.length > 0 && {
              offerDetails: [...product.offerDetailsVoucher],
            }),
          ...(product?.conditionsVoucher &&
            product?.conditionsVoucher?.length > 0 && {
              conditions: [...product.conditionsVoucher],
            }),
          ...(product?.priceVoucher && { price: product?.priceVoucher }),
          ...(product?.originalPriceVoucher && { originalPrice: product?.originalPriceVoucher }),
          ...(product?.priceActivationRecurringVoucher && {
            priceActivationRecurring: product?.priceActivationRecurringVoucher,
          }),
          ...(product?.priceActivationVoucher && {
            priceActivation: product?.priceActivationVoucher,
          }),
          ...(product?.priceRecurringVoucher && { priceRecurring: product?.priceRecurringVoucher }),
          ...(product?.subpriceActivationRecurringVoucher && {
            subpriceActivationRecurring: product?.subpriceActivationRecurringVoucher,
          }),
          ...(product?.subpriceActivationVoucher && {
            subpriceActivation: product?.subpriceActivationVoucher,
          }),
          ...(product?.subpriceActivationVoucher && {
            subpriceRecurring: product?.subpriceRecurringVoucher,
          }),
          ...(product?.recurrenceVoucher && { recurrence: product?.recurrenceVoucher }),
          ...(product?.subpriceVoucher && { subprice: product?.subpriceVoucher }),
          ...(product?.descriptionVoucher && { description: product?.descriptionVoucher }),
          ...(product?.hasEmptySpaceVoucher && {
            hasEmptySpace: product?.hasEmptySpaceVoucher,
          }),
          ...(product?.descriptionRecurringVoucher && {
            descriptionRecurring: product?.descriptionRecurring,
          }),
          ...(product?.labelVoucher && {
            label: product?.labelVoucher,
          }),
          ...(product?.topLabelStickyCheckout && {
            label: product?.topLabelStickyCheckoutVoucher,
          }),
        });
        setIsNotFirstTime();
        handleGoNextSlide?.();
      }
    }
  };

  useEffect(() => {
    checkVoucher();
  }, [voucher]);

  useEffect(() => {
    setConfiguredStickyStepperLabel('');
    const goNextButton = document.getElementById('sticky-offer-next');
    const backButton = document.getElementById('back-button');
    if (!isNotFirstTime()) goNextButton?.addEventListener('click', applyVoucher);
    backButton?.addEventListener('click', resetVoucherValidation);
    if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
    trackLink(
      voucherCms?.voucherstwilight?.insertion?.buttonLabel,
      'users',
      'click',
      'ui interaction',
      'link',
      voucher?.voucherId || '',
      voucher?.validateVoucher?.data?.finalPrice
    );
    return () => {
      goNextButton?.removeEventListener('click', applyVoucher);
      backButton?.removeEventListener('click', resetVoucherValidation);
    };
  }, []);

  return {
    title: isNotFirstTime()
      ? voucherCms?.voucherstwilight?.success?.backTitle || ''
      : voucherCms?.voucherstwilight?.success?.title || '',
    voucherCms,
    isLoading: voucher.voucher.isLoading || voucher.shoppingCart.isLoading,
    discountTitle: product?.vouchers?.discountTitle || '',
    message: voucherMessage,
  };
};
