import { IPersonalInfoCMS } from '@vfit/consumer/data-access';
import { ISkeleton } from '@vfit/shared/components';

export const organizeConfirmIdentityCard = (dataFromCms: IPersonalInfoCMS) => ({
  titleCms: dataFromCms?.personalinfo?.clientRecognition?.title || '',
  descriptionCms: dataFromCms?.personalinfo?.clientRecognition?.description || '',
  placeholderCms: dataFromCms?.personalinfo?.selectInput?.contactPlaceholder || '',
  ctaLabelCms: dataFromCms?.personalinfo?.clientRecognition?.codeNotAccessLabel || '',
});

export const SKELETON_SHAPE: ISkeleton = {
  margins: [60, 30],
  tabletMargins: [32, 15],
  groups: [
    { heights: 60, marginBottom: 32 },
    { heights: 60, marginBottom: 15 },
    { heights: 75, repeat: 4, gap: 15 },
  ],
};
