import { UseQueryResult } from 'react-query';
import {
  CustomOptions,
  handleUseQuery,
  INextError,
  LoggerInstance,
  serverlessClient,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { errorMock } from '../../utils/utils';
import { IRefundResponse } from './getCheckRefun.models';

/**
 * This method is used retrieve the availability for the refund of gthe fixed line number from ApicWeb.
 * This in used in the BillingPage component
 * Method: GET
 * SearchParams: salesChannel: selfService
 * @returns
 */
const checkRefundService = (fixedLine: string, reqType: string, customOptions?: CustomOptions) =>
  serverlessClient.get(API.CHECK_REFUND, {
    searchParams: { fixedLine, tipo_richiesta: reqType },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IRefundResponse>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the checkRefundService
 * @param fixedLine
 * @param reqType
 * @param customOptions
 * @returns
 */
export const useCheckRefund = (
  fixedLine: string,
  reqType: string,
  customOptions?: CustomOptions
): UseQueryResult<IRefundResponse, INextError> => {
  const getCheckRefundSaved = localStorage.getItem('checkRefund');
  const getRefundDataFromStorage: boolean | IRefundResponse =
    !!getCheckRefundSaved &&
    getCheckRefundSaved !== 'undefined' &&
    JSON.parse(getCheckRefundSaved as string);

  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'checkRefund';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const result = checkRefundService(fixedLine, reqType, customOptions);
      return result;
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: !getRefundDataFromStorage && !!fixedLine,
      onSuccess: (data: IRefundResponse) => {
        localStorage.setItem('checkRefund ', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - checkRefund: `, error);
        localStorage.setItem(`checkRefund`, JSON.stringify(errorMock(`checkRefund`, error)));
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<any, INextError>;
};
