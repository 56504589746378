import { UseQueryResult } from 'react-query';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { errorMock, GetCustomerResponse, IFindCallerResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IIndividualResponse } from './individual.models';

/**
 * This method calls the individualService looking for a specific constactID (or OwningIndividualId)
 * Method: GET
 * @param contactId
 * @param customOptions
 * @returns
 */
const individualService = (contactId: string, customOptions?: CustomOptions) =>
  nextClient.get(`${API.INDIVIDUAL}/${contactId}`, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IIndividualResponse>;

/**
 * This method calls the individualService
 * @param customOptions
 * @returns
 */
export const useIndividual = (
  customOptions?: CustomOptions
): UseQueryResult<IIndividualResponse, INextError> => {
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const contactId = customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId;

  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'individual';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => individualService(contactId, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      onSuccess: (data: IIndividualResponse) => {
        localStorage.setItem('individual', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - individual: `, error);
        localStorage.setItem(`individual`, JSON.stringify(errorMock(`individual`, error)));
        return errorMock(`individual`, error);
      },
    },
  };

  return handleUseQuery(options) as UseQueryResult<IIndividualResponse, INextError>;
};
