import { IProductCharacteristicsTable } from '@vfit/consumer/data-access';
import { organizeProductCharacteristicsTable } from './productCharacteristicsTable.organize';

export const useProductCharacteristicsTable = (
  widgetsCharacteristics?: IProductCharacteristicsTable
) => {
  const { table, ...rest } = organizeProductCharacteristicsTable(widgetsCharacteristics);

  return {
    data: { table, texts: rest },
  };
};
