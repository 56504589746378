import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { IOptions } from '@vfit/shared/models';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* stylelint-disable-next-line value-no-vendor-prefix */
  height: -webkit-fill-available;
  justify-content: space-between;
  font-weight: 400;
  font-family: ${fonts.exbold};
  color: ${colors.$262626};
  text-align: left;
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  height: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 8px 0 0;
  }
`;

export const Title = styled.div<IOptions>`
  ${pxToCssFont(30, 38)}
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    text-align: ${({ textAlign }) => textAlign || 'center'};
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    text-align: left;
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const TopContainer = styled.div<{ isButtonSticky?: boolean }>`
  overflow: ${({ isButtonSticky }) => (isButtonSticky ? 'auto' : 'unset')};
`;

export const TitleCentered = styled.div<IOptions>`
  ${pxToCssFont(30, 38)}
  margin-bottom: 32px;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Description = styled.div`
    margin: 0 auto;
    font-family: ${fonts.regular};
    ${pxToCssFont(20, 26)}
    text-align: left;

    p {
        margin: 0;
    }

    @media (min-width: ${breakpoints.tablet}) {
      text-align: center;
      ${pxToCssFont(18, 24)}
    }

    @media (min-width: ${breakpoints.desktop}) {
      text-align: left;
    }
`;

export const ButtonContainer = styled.div<{ isSticky?: boolean }>`
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  
  // position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};

  button {
    width: 100%;
    max-width: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 40px;
    flex-direction: row;
    gap: 30px;

    button {
      width: 100%;
      max-width: 258px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    button {
      width: 100%;
      max-width: 296px;
    }
  }
`;