import styled, { createGlobalStyle } from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

const PageLayout = styled.div`
  // max-width: 1440px;
  margin: 0 auto;
  padding-top: 14px !important;
  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 5px !important;
  }
  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 48px !important;
  }
`;

const MainContainer = styled.div`
  height: inherit;
  transform-style: inherit;
  perspective: inherit;
  perspective-origin: inherit;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;
  overflow-y: inherit;
  overflow-x: inherit;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const WidgetWrapper = styled.div<{ padding: string }>`
  padding-bottom: ${({ padding }) => `${padding}px`};
`;

const GlobalWrapper = createGlobalStyle`
  body {
    overscroll-behavior-y: none;
    margin: 0;
    padding: 0;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }
  }
`;

export { PageLayout, MainContainer, GlobalWrapper, WidgetWrapper };

