import { getFormattedAddress, IDroppedCartCms, Placeholders } from '@vfit/consumer/data-access';
import { IAddressData, ITechnologyData } from '@vfit/shared/models';
import { checkWindow, purifyTextWithLogo } from '@vfit/shared/data-access';
import { ISpec } from './droppedCart.models';

export const evaluateCmsData = (
  rawCmsData: IDroppedCartCms,
  address?: IAddressData,
  technology?: ITechnologyData,
  userType?: string,
  isLockIn?: boolean,
) => {
  const droppedCartData = rawCmsData?.droppedcart;
  const droppedCartTitle = isLockIn ? droppedCartData?.lockIn?.titleLockIn : droppedCartData?.title;
  const droppedCartDescription = isLockIn ? droppedCartData?.lockIn?.descriptionLockIn : droppedCartData?.description;
  const addressFromCookie =
    address?.displayedAddress === 'undefined' ? '' : address?.displayedAddress;
  const formattedAddress = getFormattedAddress(addressFromCookie || '');
  const formattedTechnology = technology?.displayedTecnology || '';
  const isPanicModeEnabled = checkWindow() && (window as any).isPanicMode;
  const ctaLabelByUserType =
    userType === 'legacy' || isPanicModeEnabled
      ? droppedCartData?.ctaLabelAlternative
      : droppedCartData?.ctaLabel;
  // const titleByUserType = userType === 'next' ? droppedCartData?.titleAlternative : droppedCartData?.title

  const handlePlaceholders = (text: string) =>
    purifyTextWithLogo(
      text
        .replace(Placeholders.TECHNOLOGY, formattedTechnology)
        .replace(Placeholders.ADDRESS, formattedAddress)
    );

  const handleSpecs = (specs: { value: string }[]) =>
    specs?.map((spec) => ({
      value: handlePlaceholders(spec?.value || ''),
    }));

  return {
    title: droppedCartTitle || '',
    description: handlePlaceholders(droppedCartDescription || '') || '',
    specs: handleSpecs(droppedCartData?.specs) || [{ value: '' }],
    ctaLabel: ctaLabelByUserType || '',
  };
};

export const overrideSpecs = (specs: ISpec[], globalPromoSpec: ISpec) => {
  const newSpecs = specs;
  if (newSpecs.length > 0 && newSpecs.length < 3) {
    newSpecs.push(globalPromoSpec)
  } else if (specs.length >= 3) {
    newSpecs[2].value = globalPromoSpec.value
  }

  return newSpecs;
}
