import { useEffect, useState } from 'react';
import { API, openPopup, PAGES } from '@vfit/shared/data-access';
import { CMS_CONFIG, IGenericErrorCMS, IPayments, useCmsConfig } from '@vfit/consumer/data-access';
import { IWarningInfo } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import UpdateCartCard from './components/UpdatingCartCard/updatingCartCard';
import ConvergenceModal from './components/ConvergenceModal/convergenceModal';
import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import { getTagging, getUserInfo } from '../../checkout.utils';

const FamilyPlusCard = ({ handleOnClose }: ICheckoutData) => {
  const {
    familyPlus,
    product,
    checkoutErrors,
    isModalButtonSticky,
    setFamilyPlus,
    setIsStartAcceptFamilyPlus,
  } = useCheckout();
  const { campaign, updateCart } = familyPlus || {};
  const [isChoosedAction, setIsChoosedAction] = useState(false);
  const taggingData = getTagging('ko');
  const { trackWithNewDatalayer } = useTracking({
    event: ['view'],
    disableInitialTrack: true,
  });
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const showConvergenceModal =
    !product?.isFamilyPlusProduct &&
    familyPlus?.campaign.isSuccess &&
    familyPlus?.campaign?.isEligible &&
    !isChoosedAction;
  const showNotEligibleModal =
    familyPlus?.campaign.isSuccess &&
    !familyPlus?.campaign?.isEligible &&
    product?.isFamilyPlusProduct;
  const isFamilyPlusProductAndEligible =
    familyPlus?.campaign.isSuccess &&
    familyPlus?.campaign?.isEligible &&
    product?.isFamilyPlusProduct;

  const onAcceptCampaign = () => {
    setIsStartAcceptFamilyPlus(true);
    setIsChoosedAction(true);
  };

  const onRefuseCampaign = () => {
    setFamilyPlus({
      ...familyPlus,
      campaign: {
        ...familyPlus?.campaign,
        isRejected: true,
      },
    });
    setIsChoosedAction(true);
  };

  useEffect(() => {
    if (isFamilyPlusProductAndEligible) {
      onAcceptCampaign();
    }
  }, [isFamilyPlusProductAndEligible]);

  useEffect(() => {
    if (showNotEligibleModal) {
      const showCtc =
        product?.familyPlus?.noCampaign?.actionCtcLabel &&
        product?.familyPlus?.noCampaign?.actionCtcUrl;
      trackWithNewDatalayer({
        event: ['journey_end'],
        event_label: 'family plus not available for client',
        opts: {
          ...taggingData?.opts,
          event_category: 'journey',
          page_browser_system: 'browser',
        },
        cartProduct: taggingData?.trackingProduct,
        pageProduct: taggingData?.pageProductsInfo,
        visitorTrackingOpts: getUserInfo(),
      });
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleOnClose,
        undefined,
        product?.familyPlus?.noCampaign?.title || 'Al momento non abbiamo offerte riservate a te',
        product?.familyPlus?.noCampaign?.message ||
          'Ma non ti preoccupare, abbiamo altre offerte pronte a stupirti! ',
        showCtc
          ? () => openPopup(product?.familyPlus?.noCampaign?.actionCtcUrl || '', handleOnClose)
          : undefined,
        showCtc && !product?.removeCTC
          ? product?.familyPlus?.noCampaign?.actionCtcLabel
          : undefined,
        handleOnClose,
        product?.familyPlus?.noCampaign?.actionLabel || 'Scopri le altre offerte',
        isModalButtonSticky,
        undefined,
        true,
        true
      );
    }
  }, [showNotEligibleModal]);

  const { paymentsmobile } = (useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_FAMILY_PLUS_PAYMENT
  ) as IPayments) || {
    paymentsmobile: {
      warningPriceChange: {
        title: '',
        subtitle: '',
        description: '',
      },
    },
  };

  const cmsWarningUpdateOffer: IWarningInfo = {
    title: paymentsmobile?.warningPriceChange?.title || '',
    subtitle: paymentsmobile?.warningPriceChange?.subtitle || '',
    description: paymentsmobile?.warningPriceChange?.description || '',
  };

  return (
    <>
      {updateCart?.isLoading && <UpdateCartCard {...cmsWarningUpdateOffer} />}
      {showConvergenceModal && (
        <ConvergenceModal
          isOpen
          handleClose={() => {
            window.open(product?.hubPath, '_self');
          }}
          handleContinue={onRefuseCampaign}
          product={campaign?.familyPlusProduct}
          handleAccept={onAcceptCampaign}
        />
      )}
    </>
  );
};

export default FamilyPlusCard;

