import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ImageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 24px;
  padding-top: 16px;

  .textInput {
    width: 100%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .textInput {
      width: 400px;
    }
  }
`;

export const ModalTitle = styled.p`
  display: flex;
  align-self: flex-start;
  margin: 0 0 24px;
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 30)};
  text-align: start;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 0 48px;
    ${pxToCssFont(40, 48)};
  }
`;

export const ModalDescription = styled.p`
  margin: 0;
  font-family: ${fonts.regular};
  ${pxToCssFont(16, 22)};
  text-align: start;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)};
  }
`;

export const Title = styled.p`
  margin: 0;
  text-align: center;
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(20, 24)};
`;

export const Subtitle = styled.p`
  margin: 0;
  text-align: center;
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)};
`;

export const Description = styled.p`
  margin: 0;
  text-align: center;
  font-family: ${fonts.regular};
  ${pxToCssFont(14, 16)};
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div {
    width: 100%;
  }

  button {
    border-radius: 4px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 280px;
  }
`;
