import { IPersonalInfoCMS } from '@vfit/consumer/data-access';

export const organizeEditField = (dataFromCms: IPersonalInfoCMS) => ({
  titleNumber: dataFromCms?.personalinfo?.clientLogged?.editNumber?.title || '',
  titleEmail: dataFromCms?.personalinfo?.clientLogged?.newEmail?.title || '',
  subtitleNumber: dataFromCms?.personalinfo?.clientLogged?.newNumber?.description || '',
  subtitleEmail: dataFromCms?.personalinfo?.clientLogged?.newEmail?.subtitle || '',
  buttonLableNumber: dataFromCms?.personalinfo?.clientLogged?.newNumber?.buttonLabel || '',
  buttonLableEmail: dataFromCms?.personalinfo?.clientLogged?.newEmail?.buttonLabel || '',
  lableNumber: dataFromCms?.personalinfo?.selectInput?.telephone || '',
  lableEmail: dataFromCms?.personalinfo?.selectInput?.email || '',
});
