import { SVGProps, memo } from 'react';

const AlertSuccess = (props: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>{'Alert Success'}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM18.842 6.78223C18.5377 6.50128 18.0632 6.52026 17.7822 6.82462L9.10246 16.2276L5.55109 12.3803C5.27013 12.076 4.79564 12.057 4.49127 12.3379C4.18691 12.6189 4.16793 13.0934 4.44889 13.3977L8.55136 17.842C8.84833 18.1638 9.35659 18.1638 9.65356 17.842L18.8844 7.84205C19.1654 7.53768 19.1464 7.06319 18.842 6.78223Z"
        fill="white"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(AlertSuccess);
