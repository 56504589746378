import { useEffect, useState } from 'react';
import { ILocateStoreResponse, IPickUpLocation } from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IUsePickupPointLocateStore } from './yourAddress.model';

export const usePickupPointLocateStore = ({
  getData,
}: IUsePickupPointLocateStore): {
  postalOffices: IPickUpLocation[];
  pickupPoints: IPickUpLocation[];
  vodafoneStores: ILocateStoreResponse[];
} => {
  const [postalOffices, setPostalOffices] = useState<IPickUpLocation[]>([]);
  const [pickupPoints, setPickupPoints] = useState<IPickUpLocation[]>([]);
  const [vodafoneStores, setVodafoneStores] = useState<ILocateStoreResponse[]>([]);

  const retrieveData = () => {
    const postalOfficesData: IPickUpLocation[] = getFromLocalStorageByKey('searchPickUpLocations');
    const pickupPointsData: IPickUpLocation[] = getFromLocalStorageByKey(
      'searchPickUpStoreLocations'
    );
    const vodafoneStoresData: ILocateStoreResponse[] = getFromLocalStorageByKey('locateStores');
    setPostalOffices(postalOfficesData);
    setPickupPoints(pickupPointsData);
    setVodafoneStores(vodafoneStoresData);
  };

  useEffect(() => {
    if (getData) {
      retrieveData();
    }
  }, [getData]);

  return { postalOffices, pickupPoints, vodafoneStores };
};
