import { CustomText } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { Container, Title } from './genericCard.style';
import { IGenericProps } from './genericCard.models';

const GenericCard = ({ title, description }: IGenericProps) => (
  <Container>
    <Title>{title}</Title>
    <CustomText
      text={description}
      fontFamily={fonts.regular}
      sizeMobile={20}
      size={20}
      lineHeightMobile={26}
      lineHeight={30}
      textAlign="center"
      modal
    />
  </Container>
);

export default GenericCard;

