import { IDeliveryItem } from '@vfit/consumer/data-access';
import { ECodeIdentification, IIdentificationDetails } from '@vfit/shared/models';

const getIdentificationMethod = (
  identificationDetails: IIdentificationDetails | IIdentificationDetails[]
): ECodeIdentification => {
  let selectedMethod: IIdentificationDetails | undefined;
  if (Array.isArray(identificationDetails)) {
    selectedMethod = identificationDetails?.find((item: IIdentificationDetails) => item.isSelected);
  } else {
    selectedMethod = identificationDetails;
  }
  return selectedMethod?.code || ECodeIdentification.NA;
};

/**
 * A getter method to retrieve the delivery code based on the deliveryItems
 * @param deliveryItems
 * @returns
 */
const getDeliveryCode = (deliveryItems: IDeliveryItem[]): string => {
  if (deliveryItems?.[0]?.details) {
    const objToFilter = deliveryItems?.[0]?.details;
    const filteredItem = objToFilter?.filter(
      (itemToFilter: any) => itemToFilter?.code == 'T' || itemToFilter?.code == 'S'
    );
    return filteredItem?.[0]?.code;
  }
  return 'T';
};

export { getIdentificationMethod, getDeliveryCode };
