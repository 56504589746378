import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IFindCallerResponse,
  IShoppingCartResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IUseValidateVoucherMutation,
  IValidateVoucherPayload,
  IValidateVoucherResponse,
} from './validateVoucher.models';

/**
 * Recall service POST /validateVoucher
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
export const validateVoucherService = (
  cartId: string,
  payload: IValidateVoucherPayload,
  customOptions?: CustomOptions
): Promise<IValidateVoucherResponse> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.VALIDATE_VOUCHER}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run validateVoucherService.
 * @returns
 */
export const useValidateVoucher = () => {
  const queryClient = useQueryClient();
  const keyString = 'validateVoucher';
  return useMutation(
    ['doValidateVoucher'],
    ({ voucherId }: IUseValidateVoucherMutation) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const cartId = shoppingCart?.id;
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const payload: IValidateVoucherPayload = {
        voucherId,
        customerId,
      };
      return validateVoucherService(cartId as string, payload);
    },
    {
      onSuccess: (data: IValidateVoucherResponse) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
