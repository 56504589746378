import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { setItemCookie } from '@vfit/shared/data-access';
import { ECMP, ECMP_MAIN, getParamsInUrl } from '@vfit/consumer/data-access';

export function useEcmpCookie() {
  const { asPath } = useRouter();

  const checkEcmpCookie = () => {
    const paramInUrl = getParamsInUrl(ECMP);
    if (paramInUrl) {
      setItemCookie(ECMP_MAIN, paramInUrl, 21);
    }
  };

  useEffect(() => {
    checkEcmpCookie();
  }, [asPath]);
}
