import React from 'react';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import { Player } from '@lottiefiles/react-lottie-player';
import { IProduct } from '@vfit/consumer/data-access';
import { Container, TextLoaderEmpty } from './genericLoading.style';

const GenericLoading = ({ product }: { product: IProduct }) => {
  const { loaders } = product || {};

  const getLoader = (): string =>
    loaders?.shoppingCart || loaders?.yourAddressCard || loaders?.choosePayment || '';

  if (!getLoader()) return <ShadowsGroup quantity={2} heights={90} />;

  return (
    <Container>
      <TextLoaderEmpty />
      <Player
        src={requireStaticImageAtomComponent(getLoader())?.src || ''}
        className="player"
        loop
        autoplay
      />
    </Container>
  );
};

export default GenericLoading;
