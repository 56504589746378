import {
  check,
  getForeignCountries,
  getItalianMunicipalities,
  isValidDate,
  nameCode,
  surnameCode,
} from '@vfit/shared/data-access';

const normalizeCF = (cf: string) =>
  cf
    .toUpperCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const isValidCFLength = (fiscalCode?: string): boolean =>
  typeof fiscalCode === 'string' && fiscalCode.length === 16;

const hasCFValidDate = (cf: string): boolean => {
  const yearPart = normalizeCF(cf).substring(6, 8);
  const monthCode = cf.charAt(8).toUpperCase();
  let dayPart = parseInt(cf.substring(9, 11), 10);

  const months: { [key: string]: number } = {
    A: 1,
    B: 2,
    C: 3,
    D: 4,
    E: 5,
    H: 6,
    L: 7,
    M: 8,
    P: 9,
    R: 10,
    S: 11,
    T: 12,
  };

  const year = parseInt(yearPart, 10);
  const fullYear = year < 40 ? 2000 + year : 1900 + year;

  const month = months[monthCode];
  if (!month) return false;

  if (dayPart > 40) dayPart -= 40;

  return isValidDate(dayPart, month, fullYear);
};

const hasCFValidPlace = (cf: string): boolean =>
  [...getItalianMunicipalities(), ...getForeignCountries()].some(
    (m) => m.code === normalizeCF(cf).substring(11, 15)
  );

const matchNameCF = (name: string, lastName: string, cf: string) =>
  normalizeCF(`${surnameCode(lastName).substring(0, 3)}${nameCode(name).substring(0, 3)}`) ===
  normalizeCF(cf).substring(0, 6);

const isValidCF = (firstName: string, lastName: string, fiscalCode: string) =>
  matchNameCF(firstName, lastName, fiscalCode) &&
  check(fiscalCode) && // check if checksum is correct
  hasCFValidDate(fiscalCode) &&
  hasCFValidPlace(fiscalCode);

export { isValidCF, isValidCFLength, normalizeCF };
