import {ISkeleton} from "@vfit/shared/components";

export const skeletonShape: ISkeleton = {
  margins: [60, 30],
  tabletMargins: [32, 15],
  groups: [
    { heights: 45, marginBottom: 15},
    { heights: 60, marginBottom: 32},
    { heights: 60, marginBottom: 15},
    { heights: 75, repeat: 4, gap: 15 },
    { heights: 45, marginBottom: 15 },
  ],
};
