import { breakpoints, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    overflow: hidden;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    overflow: hidden;
  }
`;

export const ImageStyle = styled.img`
  height: 40%;
  object-fit: cover;

  @media (min-width: ${breakpoints.desktop}) {
    width: 100%;
    height: 100%;
  }
`;

export const PillContainer = styled.div`
  position: absolute;
  left: 25px;
  padding: 23px 0px;
`;

export const VoucherContainer = styled.div`
  padding: ${pxToRem(24, 35, 40, 15)};
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    width: 100%;
    margin: ${pxToRem(30)};
  }
  @media (min-width: ${breakpoints.tablet}) {
    margin: ${pxToRem(0, 35, 40)};
    width: 90%;
  }
`;

export const FirstSection = styled.div`
  h1 {
    ${pxToCssFont(27, 45)}
    font-family: ${fonts.exbold}
    font-weight: 400;
    text-align: left;
  }

  p {
    width: 100%;
    padding-bottom: 12px;
  }
`;
export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row-reverse;
    gap: 30px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-top: ${pxToRem(25)};
    flex-direction: column;
    gap: 27px;
  }
`;

