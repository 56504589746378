import * as yup from 'yup';

export const addressSchema = yup
  .object({
    placeId: yup.string().required(''), //  This is handled in AddressInput
    city: yup
      .string()
      .min(1, 'Campo obbligatorio')
      .matches(/^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, 'Campo errato'),
    //  .required('Campo obbligatorio'),
    postalCode: yup.string().min(5, 'Campo obbligatorio').max(5, 'Campo errato').matches(/^\d+$/, 'Campo errato'),
    // .required('Campo obbligatorio'),
    stateOrProvince: yup
      .string()
      .min(1, 'Campo obbligatorio')
      .matches(/^[a-zA-Z+"àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, 'Campo errato'),
    //  .required('Campo obbligatorio'),
    street: yup
      .string()
      .min(1, 'Campo obbligatorio')
      .matches(/^[a-zA-Z+"-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ"+\s]*$/g, 'Campo errato'),
    //  .required('Campo obbligatorio'),
    streetNumber: yup
      .string()
      .min(1, 'Campo obbligatorio')
      .test('rangeTest', 'Campo errato.', (value) => {
        if (value?.match(/^[1-9]([0-9]{0,5})[a-zA-Z]?$/)) {
          return true;
        }
        return false;
      }),
  })
  .required();
