import { IPersonalDataObject } from '@vfit/shared/models';
import { ISkeleton } from '@vfit/shared/components';
import { EMAIL_CUSTOMER, PHONE_CUSTOMER } from '../../checkout.models';

const updateContactDataLocal = (data: IPersonalDataObject) => {
  const contactMedium = data?.owningIndividual?.contactMedium;
  if (contactMedium && contactMedium.length > 0) {
    const email = contactMedium.find((c) => c.type === 'email');
    const phone = contactMedium.find((c) => c.type === 'phone');
    if (email?.emailAddress) {
      localStorage.setItem(EMAIL_CUSTOMER, email.emailAddress);
    } else {
      localStorage.removeItem(EMAIL_CUSTOMER);
    }
    if (phone?.phoneNumber) {
      localStorage.setItem(PHONE_CUSTOMER, phone.phoneNumber);
    } else {
      localStorage.removeItem(PHONE_CUSTOMER);
    }
  }
};

export const formatDate = (date: Date) => {
  const retrievedDate = date.toString();
  const year = retrievedDate.substring(0, 4);
  const month = retrievedDate.substring(5, 7);
  const day = retrievedDate.substring(8);
  return day.concat('/', month, '/', year);
};

const SKELETON_SHAPE: ISkeleton = {
  margins: [60, 30],
  tabletMargins: [32, 15],
  groups: [
    { heights: 45, marginBottom: 15 },
    { heights: 60, marginBottom: 32 },
    { heights: 60, marginBottom: 15 },
    { heights: 75, repeat: 4, gap: 15 },
    { heights: 45, marginBottom: 15 },
  ],
};

export { updateContactDataLocal, SKELETON_SHAPE };
