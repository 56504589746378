import { LinkWithIcon } from '@vfit/shared/atoms';
import { BottomText, Container, Description, Lable, TextLoader, Title } from './personalInfo.style';
import { IPersonalInfo } from './personalInfo.models';

const PersonalInfo = ({ data, isLoading, cmsConfig }: IPersonalInfo) => (
  <Container>
    {isLoading && (
      <>
        <TextLoader />
        <TextLoader />
      </>
    )}
    {!isLoading && (
      <>
        <Title>{cmsConfig.title}</Title>
        <Description>{cmsConfig.description}</Description>
        <Lable>
          {cmsConfig.lableName}
          <span>
            {data.owningIndividual.firstName} {data.owningIndividual.lastName}
          </span>
          <div />
          {cmsConfig.lableFiscalCode}
          <span>{data.owningIndividual.fiscalCode}</span>
        </Lable>
        <BottomText>{cmsConfig.bottomText}</BottomText>
        {cmsConfig.url && (
          <LinkWithIcon
            onClick={() => window.open(cmsConfig.url, '_self')}
            text={cmsConfig.action}
          />
        )}
      </>
    )}
  </Container>
);

export default PersonalInfo;

