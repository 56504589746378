import { IOfferingServiceabilityResponse } from '@vfit/shared/models';
import { ICampaignResponse, IProduct } from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ICheckEligibility } from './useLockInAppFlow.models';

const LOCKIN_ERROR_CODES = {
  EMPTY_CAMPAIGNS_LIST: 'EMPTY_CAMPAIGNS_LIST',
  NO_USEFUL_CAMPAIGNS_FOUND: 'NO_USEFUL_CAMPAIGNS_FOUND',
  CAMPAIGNS_GENERIC_ERROR: 'CAMPAIGNS_GENERIC_ERROR',
};

const checkCampaignEligibility = (
  offeringServiceability: IOfferingServiceabilityResponse,
  campaignsData: ICampaignResponse[]
): ICheckEligibility => {
  if (campaignsData && campaignsData.length <= 0) {
    return { eligible: false, errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST };
  }
  const serviceabilityIds = offeringServiceability?.offeringServiceabilityItem?.map(
    (item) => item?.offering?.id
  );

  // FixedLine type filter campaignsOffer
  const fixedCampaignsList =
    campaignsData?.filter((item: ICampaignResponse) => item?.type?.toLowerCase() === 'fixed') || [];

  if (fixedCampaignsList?.length === 0) {
    return { eligible: false, errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST };
  }

  const usefulFixedCampaignsList: ICampaignResponse[] =
    fixedCampaignsList?.filter((item: ICampaignResponse) =>
      serviceabilityIds.includes(item?.fixedBundle?.productOfferId)
    ) || [];

  if (usefulFixedCampaignsList?.length === 0) {
    return { eligible: false, errorCode: LOCKIN_ERROR_CODES.EMPTY_CAMPAIGNS_LIST };
  }
  const lockInOffersFound: ICampaignResponse[] = usefulFixedCampaignsList?.sort(
    (item1: ICampaignResponse, item2: ICampaignResponse) =>
      parseInt(item2.score, 10) - parseInt(item1.score, 10)
  );

  if (Object.keys(lockInOffersFound).length !== 0) {
    return { eligible: true, lockInOffer: lockInOffersFound[0], errorCode: '' };
  }
  return { eligible: false, errorCode: LOCKIN_ERROR_CODES.NO_USEFUL_CAMPAIGNS_FOUND };
};

const getEligibilityProduct = (allProducts: IProduct[], campaignsData: ICampaignResponse[]) => {
  const offeringServiceAbility: IOfferingServiceabilityResponse =
    getFromLocalStorageByKey('offeringServiceability');
  const campaignEligibility = checkCampaignEligibility(offeringServiceAbility, campaignsData);
  let lockInProduct;
  if (campaignEligibility?.lockInOffer?.fixedBundle?.productOfferId)
    lockInProduct = allProducts.find(
      (el) =>
        el.offerId.toString() ==
        campaignEligibility?.lockInOffer?.fixedBundle.productOfferId.toString()
    );
  return {
    campaignEligibility,
    lockInProduct,
  };
};

export { LOCKIN_ERROR_CODES, getEligibilityProduct };
