import { ShadowsGroup } from '@vfit/shared/components';
import { useEffect } from 'react';
import { ICommonData } from '@vfit/shared/models';
import { useCheckout } from '../../../iBuyMobile.context';
import { Skeleton } from './recognitionCard.style';
import RecognitionCardMain from './components/RecognitionCardMain/recognitionCardMain';

const RecognitionCard = (props: ICommonData) => {
  const { handleOnChangeEnableGoNext } = props;
  const {
    setIsDisabledBackButton,
    setIsStartRecognition,
    isStartRecognition,
    customerDeliveryBaseInfo,
  } = useCheckout();

  const isSuccessDeliveryInfo =
    customerDeliveryBaseInfo?.deliveryBase?.isSuccess &&
    customerDeliveryBaseInfo?.modifySimDevice?.isSuccess;

  useEffect(() => {
    handleOnChangeEnableGoNext?.(isSuccessDeliveryInfo);
    setIsDisabledBackButton(!isSuccessDeliveryInfo);
  }, [isSuccessDeliveryInfo]);

  useEffect(() => {
    if (isStartRecognition) setIsStartRecognition(false);
  }, [isStartRecognition]);

  useEffect(() => {
    setIsStartRecognition(true);
  }, []);

  if (!isSuccessDeliveryInfo) {
    return (
      <Skeleton>
        <ShadowsGroup quantity={4} heights={75} space={40} />
      </Skeleton>
    );
  }

  return <RecognitionCardMain {...props} />;
};

export default RecognitionCard;
