import { useEffect, useState } from 'react';
import { checkIsGoBackSIA, errorMock, useDeliveryAdvanceV1 } from '@vfit/consumer/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { IUseCustomerDeliveryAdvanceResponse } from './useCustomerDeliveryAdvance.models';
import { INextError } from '@vfit/shared/data-access';

const DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO: IUseCustomerDeliveryAdvanceResponse = {
  deliveryAdvance: {
    isLoading: false,
    isError: false,
    isSuccess: false,
    error: undefined,
    deliveryOptions: [],
    identificationDetails: [],
  },
};

export const useCustomerDeliveryAdvance = (): IUseCustomerDeliveryAdvanceResponse => {
  const [useCustomerDeliveryAdvanceMobileResult, setUseCustomerDeliveryAdvanceMobileResult] =
    useState<IUseCustomerDeliveryAdvanceResponse>(DEFAULT_CUSTOMER_DELIVERY_ADVANCE_INFO);
  const {
    isStartAddress,
    deliveryAdvance,
    recognitionCode,
    setIsStartAddress,
    setDeliveryAdvance,
  } = useCheckout();
  const [recognitionCodePosted, setRecognitionCodePosted] = useState<string>('');
  const {
    refetch: fetchDeliveryAdvance,
    data: deliveryAdvanceData,
    error: deliveryAdvanceError,
    isSuccess: isSuccessDeliveryAdvance,
    isLoading: isLoadingDeliveryAdvance,
    isError: isErrorDeliveryAdvance,
  } = useDeliveryAdvanceV1(recognitionCode, {
    enabled: false,
    keyDependency: ['deliveryDetailsIsAdvance'],
  });

  const saveDeliveryState = () => {
    setDeliveryAdvance({
      ...deliveryAdvance,
      isLoading: isLoadingDeliveryAdvance,
      isSuccess: isSuccessDeliveryAdvance,
      isError: isErrorDeliveryAdvance,
      error: errorMock('deliveryAdvance', deliveryAdvanceError as INextError),
      deliveryOptions: deliveryAdvanceData?.deliveryDetails || [],
      identificationDetails: deliveryAdvanceData?.identificationDetails || [],
    });
  };

  useEffect(() => {
    if (isStartAddress) setIsStartAddress(false);
  }, [isStartAddress]);

  useEffect(() => {
    saveDeliveryState();
  }, [isErrorDeliveryAdvance, isSuccessDeliveryAdvance, isLoadingDeliveryAdvance]);

  useEffect(() => {
    if (isLoadingDeliveryAdvance) {
      setUseCustomerDeliveryAdvanceMobileResult((prevState) => ({
        ...prevState,
        deliveryAdvance: {
          ...prevState.deliveryAdvance,
          isLoading: true,
        },
      }));
    } else if (isErrorDeliveryAdvance) {
      setUseCustomerDeliveryAdvanceMobileResult((prevState) => ({
        ...prevState,
        deliveryAdvance: {
          ...prevState.deliveryAdvance,
          isLoading: false,
          isError: true,
        },
      }));
    } else if (isSuccessDeliveryAdvance) {
      setUseCustomerDeliveryAdvanceMobileResult((prevState) => ({
        ...prevState,
        deliveryAdvance: {
          ...prevState.deliveryAdvance,
          isLoading: false,
          isSuccess: true,
        },
      }));
    }
  }, [isLoadingDeliveryAdvance, isSuccessDeliveryAdvance, isErrorDeliveryAdvance]);

  useEffect(() => {
    if (
      isStartAddress &&
      recognitionCode &&
      recognitionCodePosted !== recognitionCode &&
      !checkIsGoBackSIA()
    ) {
      setRecognitionCodePosted(recognitionCode);
      fetchDeliveryAdvance();
    }
  }, [isStartAddress]);

  return useCustomerDeliveryAdvanceMobileResult;
};

