import { IFormat } from '@vfit/shared/models';
import { ISimOptions } from '../../checkout.models';

const getEsimItem = (orderItems: ISimOptions): IFormat | undefined => {
  if (!orderItems || !orderItems?.simOptions || orderItems?.simOptions?.length === 0)
    return undefined;
  return orderItems?.simOptions?.find((simOption) =>
    simOption?.value?.toLowerCase()?.includes('esim')
  );
};

const isAlreadySelectedEsim = (orderItems: ISimOptions): boolean =>
  orderItems?.simOptions?.some((el) => el.selection && el.name?.toLowerCase()?.includes('esim')) ||
  false;

export { getEsimItem, isAlreadySelectedEsim };
