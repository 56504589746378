import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 0 auto;
  text-align: left;
  font-family: ${fonts.exbold};
  font-weight: 400;
  color: ${colors.$262626};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const Title = styled.div`
  ${pxToCssFont(30, 38)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 40px;
  }
`;

export const Subtitle = styled.div`
  ${pxToCssFont(20, 26)}
  margin-bottom: 8px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
    margin-bottom: 12px;
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(16, 22)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const CheckboxStyle = styled.div`
  .checkbox-container {
    margin-bottom: 8px;
    padding: 15px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 12px;
    }
  }
`;

export const ButtonStyle = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 38px;
    width: 258px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 26px;
    width: 296px;
  }
`;

