import React from 'react';
import { ISliderCarousel } from '@vfit/shared/models';
import { SlideCarouselWithCoverage } from '@vfit/consumer/components';
import { SliderCarousel } from '@vfit/shared/components';

const SliderCarouselWithCoverage = (sliderCarouselProps: ISliderCarousel) => (
  <SliderCarousel {...sliderCarouselProps} SlideCarouselComponent={SlideCarouselWithCoverage} />
);
export default SliderCarouselWithCoverage;

