import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFontSize, pxToRem } from '@vfit/shared/themes';

export const CoverageToolMapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${pxToRem(560)};

  @media (min-width: ${breakpoints.tablet}) {
    min-height: 737px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: 737px;
  }
`;

export const CoverageToolMapContent = styled.div`
  margin-top: 32px;
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 40px;
    margin-left: 54px;
    margin-right: 54px;
  }
`;

export const CoverageToolMapButton = styled.div`
  margin-bottom: 32px;
  margin-left: 20px;
  margin-right: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 64px;
    margin-left: 40px;
    margin-right: 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 64px;
    margin-left: 54px;
    margin-right: 54px;
  }
`;

export const Address = styled.div`
  font-family: ${fonts.exbold};

  @media (min-width: ${breakpoints.mobile}) {
    font-weight: 400;
    ${pxToCssFontSize(24)}
  }

  @media (min-width: ${breakpoints.tablet}) {
    font-weight: 400;
    ${pxToCssFontSize(30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-weight: 400;
    ${pxToCssFontSize(36)}
  }
`;

export const AddressDesc = styled.div`
  font-family: ${fonts.light};

  @media (min-width: ${breakpoints.mobile}) {
    font-weight: 400;
    ${pxToCssFontSize(24)}
  }

  @media (min-width: ${breakpoints.tablet}) {
    font-weight: 400;
    ${pxToCssFontSize(30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-weight: 400;
    ${pxToCssFontSize(36)}
  }
`;

export const MapDescription = styled.div`
  text-align: left;
`;

export const TextLoader = styled.div`
  width: inherit;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 58px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

