import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const CheckDiv = styled.div`
  .check-size {
    height: 12px;
    width: 17px;
    align-self: center;
    margin-right: 16px;
    margin-top: -7px;
  }

  button {
    padding: 0;
  }

  display: flex;
  margin-bottom: 20px;
`;

export const Container = styled.div`
  color: ${colors.$262626};
  font-style: normal;
  margin: 32px 20px 41px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 57px 37px 33px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 60px 55px 52px;
  }

  button {
    background: transparent;
    border: none;
    text-align: start;
    font-family: ${fonts.regular};
    font-weight: 700;
    text-decoration: underline;
    text-underline-position: from-font;
    ${pxToCssFont(16, 22)}
    cursor: pointer;

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(18, 26)}
    }

    &,
    &:active,
    &:visited {
      color: ${colors.$262626};
    }

    &:hover {
      color: ${colors.$e60000};
    }
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: center;
  margin-top: ${pxToRem(32)};
  margin-bottom: ${pxToRem(16)};
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(20, 26)}
  margin-bottom: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
    margin-bottom: ${pxToRem(27.17)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${pxToRem(40)};
  }
`;

export const DocsDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 22px;
  margin-right: 10px;

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    margin-top: 2px;
  }
`;

export const Checkbox = styled.div`
  input {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 3;
  }

  .checkmark::after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    border: 1px solid ${colors.$bebebe};
  }

  &:hover input ~ .checkmark {
    border: 1px solid ${colors.$bebebe};
  }

  input:checked ~ .checkmark {
    background-color: ${colors.$00697c};
    border: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
`;

export const DocCheck = styled.div`
  text-align: start;
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(16, 22)}
  width: 256px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 334px;
    ${pxToCssFont(18, 26)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 374px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background: ${colors.$bebebe};
  margin: 32px 0;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 40px 0;
  }
`;

export const SubTitle = styled.div`
  text-align: start;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(20, 26)}
  margin-bottom: 16px;
  position: relative;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
    margin-bottom: 24px;
  }
`;

export const LoaderIcon = styled.div`
  position: absolute;
  right: 0;
  top: 16px;

  div {
    svg {
      width: 20px;
    }
  }
`;

export const SubDescription = styled.div`
  text-align: start;
  font-family: ${fonts.regular};
  font-weight: 400;
  align-self: stretch;
  margin-top: 20px;
  margin-bottom: 16px;
  ${pxToCssFont(16, 22)}

  @media (min-width: ${breakpoints.tablet}) {
    margin: 24px 0;
    ${pxToCssFont(18, 24)}
  }
`;

export const Button = styled.button`
  display: flex;
  padding: 0;
`;

export const CheckboxStyle = styled.div`
  div {
    border: none;
    padding: 0;
  }

  .checkbox-container {
    margin-bottom: 0;

    & input {
      margin: 0;
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: 12px;
    }
  }

  .title {
    padding: 10px 0;
    ${pxToCssFont(16, 22)}

    @media (min-width: ${breakpoints.tablet}) {
      padding: 4px 0;
      ${pxToCssFont(20, 30)}
    }
  }
`;

export const TextLoader = styled.div`
  width: inherit;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 58px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

