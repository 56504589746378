import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const ButtonWrapper = styled.div`
  margin: 32px 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 64px 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 64px 55px;
  }

  .button-slide-container {
    @media (min-width: ${breakpoints.tablet}) {
      position: absolute;
      bottom: 64px;
      left: 40px;
      right: 40px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      left: 55px;
      right: 55px;
    }
  }
`;
