import {
  checkIsGoBackSIA,
  CMS_CONFIG,
  deleteCartCookie,
  ErrorCodes,
  getHubPath,
  getParamsInUrl,
  IGenericErrorCMS,
  IGlobalConfigCMS,
  IProduct,
  isPersistData,
  resetKeyListAfterSubmit,
  resetKeyListOnRecharge,
  setCartCookie,
  updateProduct,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import {
  resetData,
  errorManager,
  ErrorService,
  useTracking,
  tracking,
  retrieveBasePageName,
} from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import {
  API,
  getFromLocalStorageByKey,
  isAcceptedCookieLawGroup,
  PAGES,
} from '@vfit/shared/data-access';
import { StickyBannerHeader } from '@vfit/consumer/components';
import { removeCurrency } from '@vfit/consumer/hooks';
import CheckoutModal from '../Checkout/checkout.modal';
import {
  checkPopupVisibility,
  getLocalAddress,
  getTechnology,
  isAppLockin,
  updateProductPrice,
  updateProductShoppingCartUrl,
} from './shoppingCart.utils';
import { useCheckout } from '../iBuyFixed.context';
import { ENABLE_DEBUG, getProduct } from '../iBuyFixed.utils';
import { useShoppingCartInfo } from './hooks/useShoppingCartInfo';
import ShoppingCartContent from './components/ShoppingCartContent/shoppingCartContent';
import { getTagging, getTrackError, getUserInfo } from '../Checkout/checkout.utils';
import { IIBuyFixed } from '../iBuyFixed.models';
import EnableDebug from '../Debug/enableDebug';

const ShoppingCart = ({ isSuccessDynamicCart, isErrorDynamicCart }: IIBuyFixed) => {
  const {
    product,
    isShowCheckout,
    isLoadingCart,
    setInstallationAddress,
    setShippingAddress,
    setBillingAddress,
    setIsLoadingCart,
    setIsShowCheckout,
    setProduct,
  } = useCheckout();
  const queryString = window.location.search;
  const hub = product ? getHubPath(product) : '';
  localStorage.setItem('hubPath', hub);
  const queryClient = useQueryClient();
  const tagging = getTagging('cart', 'fixed');
  const [isErrorOnPayment, setIsErrorOnPayment] = useState(false);
  const [isErrorOnPaymentShowErrorModal, setIsErrorOnPaymentShowErrorModal] = useState(false);
  const [isOnResetUser, setIsOnResetUser] = useState(false);
  const alreadySubmitterOrder = getFromLocalStorageByKey('submitOrder');
  const isNotFirstFlow = getFromLocalStorageByKey('shoppingCart');
  const voucherId = getParamsInUrl('voucherId');

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const cookieSettingsCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;

  const {
    isLoading: isLoadingShoppingCartInfo,
    isError: isErrorShoppingCart,
    isErrorServiceability,
    isCompletedSilentLogin,
  } = useShoppingCartInfo(product, isSuccessDynamicCart);

  const { trackWithNewDatalayer } = useTracking({
    event: ['cart_view'],
    opts: tagging?.opts,
    cartProduct: {
      ...tagging?.trackingProduct,
      cart_total: product?.price && removeCurrency(product.price),
    },
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
    disableInitialTrack: false,
  });

  const checkErrorOnPayment = () => {
    if (
      queryString.includes(ErrorCodes.MODE_BACK_SIA) ||
      queryString.includes(ErrorCodes.ERROR_ON_PAYMENT) ||
      queryString.includes(ErrorCodes.MODE_RESET_USER)
    ) {
      const isErrPayment = queryString.includes(ErrorCodes.ERROR_ON_PAYMENT);
      const isRstUser = queryString.includes(ErrorCodes.MODE_RESET_USER);
      setIsErrorOnPayment(isErrPayment);
      setIsErrorOnPaymentShowErrorModal(isErrPayment);
      setIsOnResetUser(isRstUser);
      if (isRstUser) {
        resetData(
          queryClient,
          resetKeyListOnRecharge.filter((key) => key !== 'ctxk')
        );
      }
      setIsLoadingCart(false);
    } else if (isPersistData()) {
      const keyNotRemoved = [
        'findCaller',
        'customerData',
        'getCountry',
        'ctxk',
        'shoppingCart',
        'authorize',
        'billingAccount',
        'paymentData',
        'payMean',
        'portability',
        'postDeliveryServiceDetails',
      ];
      resetData(
        queryClient,
        resetKeyListOnRecharge.filter((key) => !keyNotRemoved.includes(key))
      );
    } else if (alreadySubmitterOrder) {
      resetData(queryClient, [...resetKeyListAfterSubmit, ...resetKeyListOnRecharge]);
    } else if (isAppLockin()) {
      resetData(queryClient, [...resetKeyListOnRecharge, 'shoppingCart']);
    } else if (isNotFirstFlow) {
      resetData(queryClient, resetKeyListOnRecharge);
    }
  };

  const setShippingBilling = () => {
    const postalAddress = getLocalAddress();
    setInstallationAddress(postalAddress);
    setShippingAddress(postalAddress);
    setBillingAddress(postalAddress);
  };

  const trackCart = () => {
    const pageType =
      product?.isLockInMMProduct || product?.isLockInProduct ? 'cart lock-in' : 'cart';
    const taggingCart = getTagging(pageType, 'fixed');
    trackWithNewDatalayer({
      event: ['cart_view'],
      opts: taggingCart?.opts,
      cartProduct: {
        ...taggingCart?.trackingProduct,
        cart_total: product?.price && removeCurrency(product.price),
      },
      pageProduct: taggingCart?.pageProductsInfo,
      visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
    });
  };

  const trackErrorCart = (isIAddError?: boolean) => {
    const errorObj = getTrackError(
      {
        error: {
          url: isIAddError ? 'activableproducts' : 'shoppingCart',
          status: isIAddError ? '403' : '500',
          statusText: isIAddError ? '403' : '500',
        },
        isBlocking: true,
      },
      'shopping cart'
    );
    const taggingProduct = getTagging('');
    const pageName = `${retrieveBasePageName(
      taggingProduct?.trackingProduct,
      taggingProduct?.trackingProduct?.cart_product_name
    )}`;
    tracking(
      {
        ...errorObj,
        event_name: ['page_error'],
        event_category: 'error',
        page_name: `${pageName}:page_error`,
        page_type: 'error_page',
        product_name: taggingProduct?.pageProductsInfo.product_name,
      },
      'view'
    );
  };

  useEffect(() => {
    if (isErrorShoppingCart || isErrorDynamicCart) {
      const closeModalAction = () => {
        resetData(queryClient, ['shoppingCart']);
        window.location.href = hub;
      };
      const isIAddError = isAppLockin() && isErrorDynamicCart;
      trackErrorCart(isIAddError);
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: genericError?.genericerror?.title || '',
        message: genericError?.genericerror?.description || '',
        actionText: genericError?.genericerror?.buttonLabel || '',
        disableTrack: true,
        onClose: closeModalAction,
        actionEvent: closeModalAction,
        trackError: getTrackError(
          {
            error: {
              url: isIAddError ? 'activableproducts' : 'shoppingCart',
              status: isIAddError ? '403' : '500',
              statusText: isIAddError ? '403' : '500',
            },
            isBlocking: true,
          },
          'shopping cart'
        ),
        ...(isAppLockin() &&
          isErrorDynamicCart && {
            secondActionEvent: () => {
              window.location.reload();
            },
            secondButtonText: 'Riprova',
          }),
        opts: {
          product_name: product?.slug || '',
        },
      });
    }
  }, [isErrorShoppingCart, isErrorDynamicCart]);

  useEffect(() => {
    if (isErrorServiceability) {
      window.location.href = hub;
    }
  }, [isErrorServiceability]);

  useEffect(() => {
    if (product?.isLoaded && !isLoadingShoppingCartInfo) {
      setIsLoadingCart(false);
      setShippingBilling();
    }
    if (product?.isLockInMMProduct) {
      trackCart();
      deleteCartCookie();
    }
  }, [product]);

  useEffect(() => {
    if (isSuccessDynamicCart) {
      const isAcceptedPrivacyPolicy = isAcceptedCookieLawGroup('4');
      const { isNoCookie } = product;
      if (isAcceptedPrivacyPolicy && !isNoCookie && !product?.isLockInMMProduct) {
        const cartCookieHoursDuration: number = cookieSettingsCms?.globalconfig?.cookies?.cartCookie
          ?.durationHours
          ? parseFloat(cookieSettingsCms?.globalconfig?.cookies?.cartCookie?.durationHours)
          : 48;
        setCartCookie(product, getLocalAddress(), getTechnology(), cartCookieHoursDuration / 24);
      }
      if (isNoCookie || product?.isLockInMMProduct) deleteCartCookie();
      if (isAppLockin()) trackCart();
    }
  }, [isSuccessDynamicCart]);

  useEffect(() => {
    if (!isLoadingShoppingCartInfo && isSuccessDynamicCart) {
      const prod = getProduct(product, true) as IProduct;
      setProduct({
        ...prod,
        ...updateProductPrice(prod),
        shoppingCartUrl: window.location.href,
        isLoaded: true,
      });
      setTimeout(() => {
        updateProductShoppingCartUrl(product);
      }, 1000);
    }
  }, [isLoadingShoppingCartInfo, isSuccessDynamicCart]);

  useEffect(() => {
    checkErrorOnPayment();
    if (checkIsGoBackSIA()) setIsShowCheckout(true);
    if (voucherId) {
      localStorage.setItem('voucherId', voucherId);
      window.history.pushState(null, '', window.location.pathname);
    }
    if (product) {
      checkPopupVisibility(product);
      updateProduct({
        ...product,
        ...(hub && { hubPath: hub }),
      });
    }
  }, []);

  if (!product || Object.keys(product)?.length === 0) return null;

  return (
    <div id="shoppingCartOffer">
      {ENABLE_DEBUG && <EnableDebug />}
      {product?.shoppingCartStickyBanner && (
        <StickyBannerHeader bannerCustomConfig={{ ...product.shoppingCartStickyBanner }} />
      )}
      <ShoppingCartContent isSuccessDynamicCart={isSuccessDynamicCart} />
      <CheckoutModal
        isOpen={isShowCheckout}
        handleClose={() => setIsShowCheckout(false)}
        backgroundImage={product?.image || ''}
        isLoadingCart={isLoadingCart || !isCompletedSilentLogin}
        isErrorOnPayment={isErrorOnPayment}
        isErrorOnPaymentShowErrorModal={isErrorOnPaymentShowErrorModal}
        isOnResetUser={isOnResetUser}
        hideCards={checkIsGoBackSIA()}
      />
    </div>
  );
};

export default ShoppingCart;
