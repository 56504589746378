import { useEffect, useState } from 'react';
import { IProduct, usePage } from '@vfit/consumer/data-access';
import EditAppSession from '../EditAppSession/editAppSession';
import CoverageToolModal from '../CoverageToolModal/coverageToolModal';

export const DEFAULT_CMS_ID = '51';

const CoverageToolFromApp = () => {
  const { products: allProducts } = usePage();
  const [product, setProduct] = useState<IProduct>();

  useEffect(() => {
    if (allProducts && allProducts?.length > 0) {
      const localCmsId = localStorage.getItem('cmsIdAppMock') || '';
      const foundedProd = allProducts?.find((el) => el.cmsId === (localCmsId || DEFAULT_CMS_ID));
      if (foundedProd) {
        setProduct(foundedProd);
      } else {
        alert(
          `Product with cmsId ${
            localCmsId || DEFAULT_CMS_ID
          } was not found. Change the cms id from the button at the bottom`
        );
      }
    }
  }, [allProducts]);

  return (
    <>
      <CoverageToolModal
        isOpen
        backgroundImage={product?.image || ''}
        product={product}
        handleClose={() => {}}
      />
      <EditAppSession />
    </>
  );
};

export default CoverageToolFromApp;
