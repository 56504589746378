import { Children } from 'react';
import { Accordion, ButtonSlide, CustomText } from '@vfit/shared/atoms';
import {
  ButtonSlideContainer,
  OffersList,
  Paragraph,
  ParagraphBold,
  Spacer,
  Title,
} from './modalWithAccordion.style';
import { IModalWithAccordionProps } from './modalWithAccordion.models';
import { checkIsApp } from '@vfit/consumer/data-access';

const ModalWithAccordion = ({ title, itemList, accordions, button }: IModalWithAccordionProps) => (
  <>
    <Title>{title}</Title>
    <OffersList>
      {Children.toArray(
        itemList?.map((offer) => (
          <li>
            <ParagraphBold>{offer.subtitle}</ParagraphBold>
            <Paragraph>{offer.paragraph}</Paragraph>
          </li>
        ))
      )}
    </OffersList>
    {Children.toArray(
      accordions?.map((accordion) => (
        <Accordion
          title={accordion.title || ''}
          content={[
            <CustomText
              modal
              sizeMobile={16}
              lineHeightMobile={22}
              size={16}
              lineHeight={22}
              textAlign="start"
              text={accordion.text || ''}
            />,
          ]}
          iconColor="#e60000"
        />
      ))
    )}
    <Spacer />
    {button && (
      <ButtonSlideContainer>
        <ButtonSlide
          isApp={checkIsApp()}
          onClick={button.onClick}
          label={button.label}
          name="action_modal_accordion"
        />
      </ButtonSlideContainer>
    )}
  </>
);

export default ModalWithAccordion;
