import { UseQueryResult } from 'react-query';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { checkCartValidation } from '../../utils/shoppingCart';

/**
 * API service retrieve the shopping cart.
 * Method: GET
 * searchParams: {
 * salesChannel: 'selfService',
 * filterSystemChanges: 'TRUE',},
 * @param cartId
 * @param customOptions
 * @returns
 */
export const getShoppingCartService = (cartId: string, customOptions?: CustomOptions) =>
  nextClient.get(`${API.SHOPPING_CART}/${cartId}`, {
    searchParams: {
      salesChannel: 'selfService',
      filterSystemChanges: 'TRUE',
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IShoppingCartResponse>;

/**
 * Mutation for saving into the key 'getShoppingCartQuery'
 * the shoppingCart response coming from the API service getShoppingCartService
 * @param cartId
 * @param customOptions
 * @returns
 */
export const useGetShoppingCart = (
  cartId: string,
  customOptions?: CustomOptions
): UseQueryResult<IShoppingCartResponse, INextError> => {
  LoggerInstance.debug('useGetShoppingCart ^^^ ');
  LoggerInstance.debug('useGetShoppingCart cartId : ', cartId);
  const queryEnabler = customOptions ? customOptions.enabled && !!cartId : !!cartId;
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'getShoppingCart';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => getShoppingCartService(cartId, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled:
        queryEnabler && (customOptions?.enabled !== undefined ? customOptions?.enabled : true),
      onSuccess: (data: IShoppingCartResponse) => {
        // save customerData object into storage
        localStorage.setItem('shoppingCart', JSON.stringify(data));
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - getShoppingCart: `, error);
        localStorage.setItem(`shoppingCart`, JSON.stringify(errorMock(`getShoppingCart`)));
        customOptions?.onError?.(error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IShoppingCartResponse, INextError>;
};

/**
 * Mutation for saving into the key 'getShoppingCartQuery'
 * Is the silent update. In error not update Scart response
 * the shoppingCart response coming from the API service getShoppingCartService
 * @param cartId
 * @param customOptions
 * @returns
 */
export const useGetShoppingCartSilentUpdate = (
  cartId?: string,
  customOptions?: CustomOptions
): UseQueryResult<IShoppingCartResponse, INextError> => {
  const queryEnabler = !!cartId;
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'getShoppingCart';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      let cartIdService = cartId;
      if (!cartIdService) {
        const shoppingCart = getFromLocalStorageByKey('shoppingCart');
        cartIdService = shoppingCart?.id;
      }
      return getShoppingCartService(cartIdService, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: customOptions?.enabled !== undefined ? customOptions.enabled : queryEnabler,
      onSuccess: (data: IShoppingCartResponse) => {
        if (data) {
          localStorage.setItem('shoppingCart', JSON.stringify(data));
          checkCartValidation();
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - getShoppingCart: `, error);
        customOptions?.onError?.(error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IShoppingCartResponse, INextError>;
};
