import {IPortabilityCMS} from "@vfit/consumer/data-access";

export const organizeCheckboxCardsPortability = (portabilityCms: IPortabilityCMS, portability: any) => {
  const lineInfo = portabilityCms?.portability?.lineInfo;
  return {
    title: lineInfo?.title || '',
    description: lineInfo?.description?.replace('PHONENUMBER', `${portability?.portabilityNumber || ''}`) || '',
    items: [
      {title: lineInfo?.choice?.option1 || '', value: 'TelInt'},
      {title: lineInfo?.choice?.option2 || '', value: 'TelOnly'}
    ]
  };
}
