/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { CoverageToolMap } from '@vfit/consumer/components';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { VfModal } from '@vfit/shared/components';
import { useQueryClient } from 'react-query';
import { removeCurrency } from '@vfit/consumer/hooks';
import { API, PAGES, openPopup, requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import {
  checkIsApp,
  CMS_CONFIG,
  getSelectedOfferIds,
  handleFirstCharacterOfSentence,
  ICampaignResponse,
  ICoverageToolCongifg,
  LOGGED_USER_KEYS,
  retrieveLockInOfferFromStorage,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { ButtonSlide } from '@vfit/shared/atoms';
import { CoverageErrorType, IOfferingServiceabilityResponse } from '@vfit/shared/models';
import { ICommonData } from '../../../StepperStateCards/stepperStateCards.models';
import {
  DEFAULT_ERROR_COVERAGE,
  useCoverageToolModalProvider,
} from '../../coverageToolModal.context';
import { IInputs } from '../../../CoverageTool/coverageTool.models';
import { IModalConfig } from './coverageToolMapCard.models';
import { ButtonDiv, ModalDescription, ModalTitle, ModalWrap } from './coverageToolMapCard.style';
import { getAddress } from '../../coverageToolModal.utils';
import { EditAddress } from '../CoverageToolOtpCard/coverageToolOtpCard.style';

const CoverageToolMapCard = ({
  handleGoNextSlide,
  handleGoPrevSlide,
  handleOnClose,
}: ICommonData) => {
  const queryClient = useQueryClient();
  // const { errorNoCoverage } = coverageToolLockInMessage();
  const [onConfirmAddress, setOnConfirmAddress] = useState<boolean>(false);
  const [isOnFinish, setIsOnFinish] = useState<boolean>(false);
  const {
    address,
    setAddress,
    product,
    setSlidesGoBack,
    isManual,
    isLoading,
    isError,
    errorCoverage,
    setIsAddressConfirmed,
    setIsError,
    setIsLoading,
    setIsSuccess,
  } = useCoverageToolModalProvider();
  const { errorType } = errorCoverage;
  const silentLogin: any = queryClient.getQueryData('silentLogin');
  const INIT_MODAL_CONFIG: IModalConfig = {
    isOpen: false,
    title: '',
    description: '',
    firstActionLabel: '',
    firstAction: () => {},
    secondActionLabel: '',
    secondAction: () => {},
  };
  const [modalConfig, setModalConfig] = useState<IModalConfig>(INIT_MODAL_CONFIG);
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;

  const isAppRetrieved = window.localStorage.getItem('isApp');
  const isApp: boolean = !!isAppRetrieved && JSON.parse(isAppRetrieved as string);

  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;

  // Product for tracking.
  const pageProduct = {
    product_name: product?.slug,
    product_price: product?.price && removeCurrency(product.price),
    product_id: `${product?.offerId}`,
    product_quantity: '1',
    product_category: ['fixed'],
  };

  const opts = {
    event_category: 'tools',
    page_subsection: 'coverage tool',
    page_section: 'fixed offers',
    page_type: 'map',
    journey_name: 'coverage tool',
  };

  const { trackView } = useTracking({
    event: ['tool_end'],
    opts,
    pageProduct,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
  });

  /**
   * Check on offering serviceability if is logged and have a campaign accepted
   */
  const checkLockInLoggedOfferingServiceAbility = () => {
    const offeringServiceabilitySaved = localStorage.getItem('offeringServiceability');
    const offeringServiceability: IOfferingServiceabilityResponse | undefined =
      !!offeringServiceabilitySaved &&
      offeringServiceabilitySaved !== 'undefined' &&
      JSON.parse(offeringServiceabilitySaved as string);
    const lockInOfferFound = localStorage.getItem('lockinOfferFound');
    const lockInOffer: ICampaignResponse = !!lockInOfferFound && JSON.parse(lockInOfferFound);
    const lockInProductOfferId = lockInOffer?.fixedBundle?.productOfferId;
    return offeringServiceability?.offeringServiceabilityItem?.find(
      (o) => o?.offering?.id == lockInProductOfferId
    );
  };

  const checkCoverageLockIn = (): boolean => {
    const campaignsData = localStorage.getItem('campaignsData');
    const campaignsDataSaved =
      !!campaignsData && campaignsData !== 'undefined' && JSON.parse(campaignsData as string);
    const offeringServiceabilitySaved = localStorage.getItem('offeringServiceability');
    const offeringServiceability: IOfferingServiceabilityResponse | boolean =
      !!offeringServiceabilitySaved && JSON.parse(offeringServiceabilitySaved as string);
    if (campaignsDataSaved && campaignsDataSaved?.length > 0 && offeringServiceability) {
      const lockInOfferFound = retrieveLockInOfferFromStorage();
      return !lockInOfferFound;
    }
    return false;
  };

  const onClickEditAddress = () => {
    setModalConfig({ ...INIT_MODAL_CONFIG });
    setTimeout(() => {
      setSlidesGoBack(isManual ? 2 : 1);
    }, 200);
  };

  const onClickCallMeNow = () => {
    if (product?.callMeNowHelp?.url) openPopup(product?.callMeNowHelp?.url);
  };

  const modalErrorNoCoverage = () => {
    const addressLocal = getAddress();
    const composedAddress = `${address?.street} ${address?.streetNumber} - ${address?.city}`;
    const koCoverageLabel = {
      title: coverageToolConfig?.coveragetool?.koCoverageTool?.title || '',
      description:
        coverageToolConfig?.coveragetool?.koCoverageTool?.description?.replace(
          'PLACEHOLDER',
          `${composedAddress}` || ''
        ) || '',
      buttonList: [
        {
          text: coverageToolConfig?.coveragetool?.koCoverageTool?.buttonEditAddress || '',
        },
        {
          text: coverageToolConfig?.coveragetool?.koCoverageTool?.buttonCallsYou || '',
        },
      ],
    };
    trackView({
      event_name: 'journey_end',
      event_label_track: 'no coverage',
      event_category: 'journey',
      page_type: 'ko',
      tracking_type: 'view',
      journey_name: 'verify lock-in',
      page_subsection: 'lock-in',
    });

    setModalConfig({
      isOpen: true,
      onCloseAction: handleOnClose,
      title: koCoverageLabel.title,
      ...(addressLocal?.displayedAddress && {
        subDesc: (
          <div>
            <h4>
              <b>Indirizzo</b>
            </h4>
            <p>{handleFirstCharacterOfSentence(addressLocal?.displayedAddress || '')}</p>
          </div>
        ),
      }),
      description: koCoverageLabel.description,
      firstActionLabel: koCoverageLabel?.buttonList?.[0]?.text,
      firstAction: onClickEditAddress,
      secondActionLabel: koCoverageLabel?.buttonList?.[1]?.text,
      secondAction: onClickCallMeNow,
    });
  };

  useEffect(() => {
    if (handleGoNextSlide && onConfirmAddress && !isLoading && !isError) {
      if (product?.isLockInProduct && !isApp) {
        const { offerId: filterOfferId } = getSelectedOfferIds();
        if (!filterOfferId) {
          return modalErrorNoCoverage();
        }
        if (!isLoggedUser) {
          handleGoNextSlide();
        } else if (checkLockInLoggedOfferingServiceAbility()) {
          setIsOnFinish(true);
          handleGoNextSlide({}, true);
        } else if (checkCoverageLockIn() && isApp) {
          modalErrorNoCoverage();
        } else {
          trackView({
            event_name: 'journey_end',
            event_label_track: 'lock-in not available for logged user',
            event_category: 'journey',
            page_type: 'ko',
            tracking_type: 'view',
            journey_name: 'verify lock-in',
            page_subsection: 'lock-in',
          });
          setIsOnFinish(true);
          handleGoNextSlide({}, true);
        }
      } else {
        setIsOnFinish(true);
        handleGoNextSlide({}, true);
      }
    }
  }, [onConfirmAddress, isLoading]);

  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem('region_cus', address.region || '');
      localStorage.setItem(
        'address_cus',
        address.street?.concat(
          ' ',
          address.streetNumber || '',
          ', ',
          address.postalCode || '',
          ' ',
          address.city || ''
        ) || ''
      );
    }
  }, [isLoading]);

  const goPrevSlide = (data: IInputs) => {
    setAddress(data);
    setIsAddressConfirmed(false);
    setOnConfirmAddress(false);
    setIsOnFinish(false);
    setIsError(false);
    setIsLoading(false);
    setIsSuccess(false);
    resetData(queryClient);
    if (handleGoPrevSlide) {
      handleGoPrevSlide();
    }
  };

  const confirmAddress = () => {
    const isDisableReset = localStorage.getItem('disableReset');
    if (isDisableReset) setIsAddressConfirmed(true);
    setTimeout(() => {
      setOnConfirmAddress(true);
    }, 200);
  };

  const handleOnCloseError = () => {
    resetData(queryClient, ['validatedAddress', 'offeringServiceability', 'createdAddress']);
    switch (errorType) {
      case CoverageErrorType.R2_LOGGED:
        window.location.href = '/';
        break;
      default:
        goPrevSlide(DEFAULT_ERROR_COVERAGE);
    }
  };

  return (
    <>
      <CoverageToolMap
        onConfirm={confirmAddress}
        onEditAddress={goPrevSlide}
        address={address}
        isLoading={isLoading || (isError && !product?.isLockInProduct) || isOnFinish}
        lottieFile={
          product?.loaders?.coverageTool &&
          requireStaticImageAtomComponent(product.loaders.coverageTool).src
        }
        errorType={errorType}
        isError={isError && !product?.isLockInProduct}
        onCloseError={handleOnCloseError}
        product={product || undefined}
      />
      <VfModal
        isOpen={modalConfig.isOpen}
        handleClose={() => {
          setModalConfig({ ...INIT_MODAL_CONFIG });
          if (modalConfig.onCloseAction) {
            modalConfig.onCloseAction();
          }
        }}
      >
        <ModalWrap>
          <div>
            <ModalTitle>{modalConfig.title}</ModalTitle>
            {modalConfig?.description && (
              <ModalDescription dangerouslySetInnerHTML={{ __html: modalConfig.description }} />
            )}
            {modalConfig.subDesc && (
              <div>
                {modalConfig.subDesc}
                <EditAddress onClick={modalConfig.firstAction}>Modifica indirizzo</EditAddress>
              </div>
            )}
          </div>
          <ButtonDiv>
            {!modalConfig.subDesc && modalConfig.firstActionLabel && modalConfig.firstAction && (
              <ButtonSlide
                label={modalConfig.firstActionLabel}
                onClick={modalConfig.firstAction}
                isApp={checkIsApp()}
                borderColor="#262626"
                labelColor="#262626"
                hoverColor="#262626"
                hoverTextColor="#fff"
                clickColor="#7e7e7e"
                clickTextColor="#fff"
                name="action_first_coverageToolMap"
              />
            )}
            {modalConfig.secondActionLabel && modalConfig.secondAction && (
              <ButtonSlide
                label={modalConfig.secondActionLabel}
                onClick={modalConfig.secondAction}
                isApp={checkIsApp()}
                borderColor={modalConfig.subDesc ? '#262626' : ''}
                buttonColor={modalConfig.subDesc ? '#fff' : '#e60000'}
                labelColor={modalConfig.subDesc ? '#262626' : '#fff'}
                hoverColor={modalConfig.subDesc ? '#262626' : '#bd0000'}
                hoverTextColor={modalConfig.subDesc ? '#fff' : '#fff'}
                clickColor={modalConfig.subDesc ? '#7e7e7e' : '#a10000'}
                clickTextColor={modalConfig.subDesc ? '#fff' : '#fff'}
                name="action_second_coverageToolMap"
              />
            )}
          </ButtonDiv>
        </ModalWrap>
      </VfModal>
    </>
  );
};

export default CoverageToolMapCard;

