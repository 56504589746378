import { CustomText } from '@vfit/shared/atoms';
import { colors, fonts, purify } from '@vfit/shared/themes';
import { Pills } from '@vfit/shared/components';
import {
  DeviceBrand,
  DeviceInfo,
  DeviceOfferWrapper,
  DeviceTitle,
  DeviceSubTitle,
  DeviceFrontLabel,
  DevicePrice,
  DeviceShortDescription,
  DeviceDetailOffer,
} from '../topDevice.style';
import { IDeviceOffer } from '../topDevice.models';

const DeviceOffer = ({
  brand,
  title,
  subtitle,
  frontLabel,
  price,
  offerLabel,
  detail,
  onClickTypeInText,
}: IDeviceOffer) => {
  const [pSubtitle] = purify([subtitle || '']);
  return (
    <DeviceOfferWrapper>
      <DeviceInfo>
        <DeviceBrand>{brand}</DeviceBrand>
        <DeviceTitle>{title}</DeviceTitle>
        <DeviceSubTitle>
          <div dangerouslySetInnerHTML={{ __html: pSubtitle || '' }} />
        </DeviceSubTitle>
      </DeviceInfo>
      <DeviceInfo>
        <DeviceFrontLabel>{frontLabel}</DeviceFrontLabel>
        <DevicePrice>
          <CustomText
            text={price || ''}
            fontFamily={fonts.exbold}
            textAlign="left"
            size={28}
            lineHeight={36}
            onClickType={onClickTypeInText}
          />
          <div className="discount">
            {false && <Pills pills={['null']} variant={colors.$00697c} opacity={0.8} />}
          </div>
        </DevicePrice>
        <DeviceShortDescription>{offerLabel}</DeviceShortDescription>
        <DeviceDetailOffer>
          <CustomText
            text={detail}
            textAlign="left"
            size={18}
            lineHeight={24}
            onClickType={onClickTypeInText}
          />
        </DeviceDetailOffer>
      </DeviceInfo>
    </DeviceOfferWrapper>
  );
};

export default DeviceOffer;
