import { Children, useEffect, useState, useMemo, useRef } from 'react';
import { ImageDivider, CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { EffectCards, FreeMode, Keyboard, Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Fade } from 'react-awesome-reveal';
import { fonts } from '@vfit/shared/themes';
import { useProductListTagging } from '@vfit/consumer/data-access';
import { ITab } from './components/Tabs/tabs.model';
import { Tabs } from './components';
import { ACTIVE_TAB, IProductsListProps } from './productsList.models';
import {
  ProductListContainer,
  MainContainer,
  DisclaimerContainer,
  Title,
  TabsContainer,
} from './productsList.style';
import {
  getAllHeight,
  getSlideSize,
  getTabFilters,
  updateAllMobileHeight,
} from './productsList.utils';
import ProductCard from '../ProductCard/productCard';

const defaultSelectedTabName = 'Vedi tutte';

const ProductsList = ({
  title,
  products,
  zoomIn = false,
  slideLock = false,
  containerStyle,
  classKey,
  showCase,
  onTrack,
  divider,
  disclaimer,
  hideTabs = false,
  isR02,
}: IProductsListProps) => {
  const searchParams =
    window.location && window.location.href ? new URL(window.location.href).searchParams : '';
  const activeTabInUrl = searchParams ? searchParams?.get(ACTIVE_TAB) || null : null;
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [allMobileHeight, setAllMobileHeight] = useState<number[]>([]);
  const [tabFilter, setTabFilter] = useState<ITab[]>([]);
  const productListsElements = useRef<HTMLDivElement>(null);
  const [filterTabName, setFilterTabName] = useState(defaultSelectedTabName);
  const haveMarketingLabel = !!products.find((p) => !!p.marketingLabel);
  const { trackProductLink } = useProductListTagging();
  const divRef = useRef<HTMLDivElement | null>(null);
  const haveHeaderLight =
    (divRef?.current?.parentNode?.previousSibling as HTMLElement)?.getAttribute('id') ===
      'header-lite' || !!document.getElementById('header-lite');

  const toggleFilter = (index: number) => {
    const tabFilterCopy = tabFilter.map((tab, indexTab) => {
      if (indexTab === index) {
        return {
          ...tab,
          isActive: true,
        };
      }
      return {
        ...tab,
        isActive: false,
      };
    });
    setFilterTabName(tabFilterCopy[index].value);
    setTabFilter(tabFilterCopy);
  };

  const setMobileHeight = () => {
    if (productListsElements?.current) {
      const allHeight = getAllHeight(productListsElements.current);
      setAllMobileHeight(allHeight);
      if (isMobile) {
        updateAllMobileHeight(productListsElements.current, allHeight, 0);
      }
    }
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        setMobileHeight();
      }, 100);
    }
  }, [isMobile]);

  const filteredProducts = useMemo(() => {
    if (filterTabName === 'Vedi tutte') {
      return products;
    }
    return products.filter((product) => product?.tab?.value === filterTabName);
  }, [products, filterTabName]);

  useEffect(() => {
    const tabs = getTabFilters(products, defaultSelectedTabName);
    setTabFilter(tabs);
  }, []);

  useEffect(() => {
    if (tabFilter?.length > 0 && isFirstTime) {
      setIsFirstTime(false);
      if (activeTabInUrl) {
        const parsedActiveTabInUrl = +activeTabInUrl;
        if (parsedActiveTabInUrl && !Number.isNaN(parsedActiveTabInUrl)) {
          const toggleIndex = parsedActiveTabInUrl - 1;
          toggleFilter(tabFilter[toggleIndex] ? toggleIndex : 0);
        }
      } else if (tabFilter.length > 0) {
        toggleFilter(0);
      }
    }
    setMobileHeight();
  }, [tabFilter]);

  const swiperProduct = (type: 'slide' | 'cards', spaceBetween = 33) => (
    <Swiper
      slidesPerView="auto"
      effect={type}
      cardsEffect={{
        rotate: true,
        slideShadows: false,
      }}
      mousewheel={{
        forceToAxis: true,
      }}
      spaceBetween={spaceBetween}
      freeMode={false}
      centeredSlides={isMobile}
      keyboard
      pagination={{
        clickable: true,
      }}
      onSlideChange={(swiper) => {
        if (isMobile && productListsElements?.current) {
          updateAllMobileHeight(productListsElements.current, allMobileHeight, swiper.activeIndex);
          trackProductLink(products?.[swiper.activeIndex]);
        }
      }}
      modules={[Pagination, FreeMode, Mousewheel, Keyboard, EffectCards]}
    >
      {Children.toArray(
        filteredProducts?.map((product, index) => {
          let marginLeft = 0;
          if (index === 0) {
            if (isDesktop) marginLeft = 80;
            else if (isTablet) marginLeft = 32;
          }
          if (index === 0 && isMobile) marginLeft = 0;
          const customSize = getSlideSize(index, isMobile, isTablet);
          return (
            <SwiperSlide
              style={{
                marginLeft,
                ...customSize,
              }}
            >
              <div>
                <ProductCard
                  onTrack={onTrack}
                  showCase={showCase}
                  cardIndex={index}
                  product={product}
                  zoomIn={zoomIn}
                  classKey={classKey}
                  noMargin={isMobile}
                  haveMarketingLabel={haveMarketingLabel}
                  isR02={isR02}
                  tabName={filterTabName}
                />
              </div>
            </SwiperSlide>
          );
        })
      )}
    </Swiper>
  );

  return (
    <>
      {title && (
        <Title haveHeaderLight = {!!haveHeaderLight}>
          <CustomText
            text={title}
            size={30}
            lineHeight={38}
            sizeMobile={30}
            lineHeightMobile={38}
            margin={[0, 0, 0, 0]}
          />
        </Title>
      )}
      <ProductListContainer style={{ ...containerStyle }} ref={productListsElements}>
        <MainContainer>
          {!hideTabs && tabFilter?.length > 1 && (
            <TabsContainer enablePaddingTop={!!title}>
              <Tabs action={toggleFilter} tabs={tabFilter} />
            </TabsContainer>
          )}
          {isMobile && swiperProduct(slideLock ? 'slide' : 'cards', slideLock ? 22 : 33)}
          {!isMobile && swiperProduct('slide')}
          {disclaimer && (
            <DisclaimerContainer>
              <CustomText
                text={disclaimer}
                lineHeight={22}
                size={16}
                textAlign="left"
                fontFamily={`${fonts.regular}`}
              />
            </DisclaimerContainer>
          )}
        </MainContainer>
      </ProductListContainer>
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default ProductsList;
