import { RegisterOptions } from 'react-hook-form';
import { IVoucherForm } from './voucherInsertionCard.models';

export const voucherCodeFieldName = 'voucherCode' as const;

export const voucherSchema: Record<typeof voucherCodeFieldName, RegisterOptions<IVoucherForm, typeof voucherCodeFieldName> | undefined> = {
  [voucherCodeFieldName]: {
    minLength: {
      value: 3,
      message: 'Il codice deve avere almeno 3 cifre',
    },
  },
};
