import { Children, useEffect, useState } from 'react';
import { Check } from '@vfit/shared-icons';
import { GenericDetail, VfModal } from '@vfit/shared/components';
import { downloadFileFunction } from '@vfit/consumer/data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { fonts } from '@vfit/shared/themes';
import { trackLink } from '@vfit/shared/data-access';
import { IDocuments, ITermsProps } from './termsAndConditions.models';
import {
  Container,
  Checkbox,
  CheckboxDiv,
  CheckDiv,
  Description,
  DocCheck,
  DocsDiv,
  Title,
} from './termsAndConditions.style';

const TermsAndConditions = ({
  setNextAble,
  isLoading,
  value,
  pdfContractSummary,
  editorialLabels,
}: ITermsProps) => {
  const textContractualSummary = 'Sintesi';
  const { isDesktop, isTablet } = useDeviceType();
  const [docCheck, setDocCheck] = useState<boolean>(value?.consensus || false);
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    text: string;
    content: string;
    pdfUrl: string;
    pdfLabel: string;
  }>({
    isOpen: false,
    text: '',
    content: '',
    pdfUrl: '',
    pdfLabel: '',
  });

  const handleClick = (doc: IDocuments) => {
    setModalConfig({
      isOpen: true,
      text: doc.text,
      content: doc.content,
      pdfUrl: doc.pdfUrl || '',
      pdfLabel: doc.pdfLabel || '',
    });
    trackLink(doc.text);
  };
  const handleCloseModal = () => {
    setModalConfig({
      isOpen: false,
      text: '',
      content: '',
      pdfUrl: '',
      pdfLabel: '',
    });
  };
  const handleCheckBox = () => {
    setDocCheck(!docCheck);
  };
  useEffect(() => {
    setNextAble?.(docCheck);
  }, [docCheck]);
  const setModalHeight = () => (isTablet && 331) || (isDesktop && 370) || undefined;
  return (
    <>
      <Container>
        <Title>{editorialLabels.title}</Title>
        <Description>{editorialLabels.description}</Description>
        {Children.toArray(
          editorialLabels.documents?.map((doc: IDocuments) => {
            let document = doc;
            if (
              doc?.text?.trim()?.toLowerCase().includes(textContractualSummary.trim().toLowerCase())
            ) {
              document = {
                ...doc,
                pdfUrl: pdfContractSummary?.pdfUrl || doc.pdfUrl,
                pdfLabel: pdfContractSummary?.pdfLabel || doc.pdfLabel || '',
              };
            }
            return (
              <CheckDiv>
                <div className="check-size">
                  <Check />
                </div>
                <button type="button" disabled={isLoading} onClick={() => handleClick(document)}>
                  {doc.text}
                </button>
              </CheckDiv>
            );
          })
        )}
        <DocsDiv>
          <CheckboxDiv>
            <Checkbox>
              <input
                type="checkbox"
                disabled={isLoading}
                checked={docCheck}
                onClick={handleCheckBox}
                onChange={() => {}}
              />
              <span className="checkmark" />
            </Checkbox>
          </CheckboxDiv>
          <DocCheck>{editorialLabels.documentsCheck}</DocCheck>
        </DocsDiv>
      </Container>
      <VfModal height={setModalHeight()} isOpen={modalConfig.isOpen} handleClose={handleCloseModal}>
        <GenericDetail
          title={modalConfig.text}
          description={modalConfig.content}
          secondButtonLabel={modalConfig.pdfLabel}
          secondButtonAction={() => {
            downloadFileFunction(modalConfig.pdfUrl);
            trackLink(modalConfig.pdfLabel);
          }}
          options={{
            size: 18,
            lineHeight: 24,
            sizeMobile: 20,
            lineHeightMobile: 26,
            isModal: true,
            textAlign: 'left',
            fontFamily: fonts.regular,
          }}
        />
      </VfModal>
    </>
  );
};

export default TermsAndConditions;
