import {useState} from "react";
import {IDatalayer, ITracking, tracking, retrieveBasePageName} from "@vfit/shared/data-access";
import {IProduct} from "@vfit/consumer/data-access";
import {useQueryClient} from "react-query";

export const useProductListTagging = () => {
  const queryClient = useQueryClient();
  const pageDatalayer = queryClient.getQueryData("pageDatalayer") as ITracking;
  const initialDatalayer = datalayerAdapter(pageDatalayer) || DEFAULT_DATALAYER;
  const [currentDatalayer, setCurrentDatalayer] = useState<IDatalayer>(initialDatalayer);

  const trackProductLink = (currentProduct: IProduct) => {
    const linkName = `${retrieveBasePageName(undefined , currentProduct?.slug)}:swipe`
    const pageName = `${retrieveBasePageName(undefined , currentProduct?.slug)}:${currentDatalayer.event_name}`
    tracking({
      ...currentDatalayer,
      event_name: ["ui_interaction"],
      page_name: pageName,
      link_name: linkName,
      event_action: "swipe",
      event_category: "sales",
      product_id: currentProduct?.cmsId,
      product_category: currentProduct?.category,
      product_name: currentProduct?.slug,
      product_price: currentProduct?.price,
      product_quantity: "1",
      product_target_segment: "consumer"
    }, "link")

  }

  return{
    currentDatalayer,
    setCurrentDatalayer,
    trackProductLink
  }
}

const datalayerAdapter = (trackObj: ITracking) : IDatalayer => (
  {
    event_name: trackObj.event,
    product_id: trackObj.pageProduct?.product_id,
    product_category: trackObj.pageProduct?.product_category,
    ...trackObj.opts
  }
)


const DEFAULT_DATALAYER : IDatalayer = {
  event_name: []
}

