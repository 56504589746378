import { VfModal } from '@vfit/shared/components';
import { ButtonSlide, Checkbox, Divider, LinkWithIcon } from '@vfit/shared/atoms';
import React, { useCallback, useState } from 'react';
import { LoggerInstance } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  useCmsConfig,
  IPortabilityCMS,
  checkIsApp,
  IMultiPlayOfferingConfigurationResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, PAGES } from '@vfit/shared/data-access';
import * as S from './portability.style';
import ChooseOperator from './ChooseOperator/chooseOperator';
import { IPortability } from './portability.model';
import { organizePortabilityCard } from './portability.utils';
import { IFormData } from '../../portabilityMigrationCard.models';

const Portability: React.FC<IPortability> = ({
  portabilityData,
  data,
  handleGoNextSlide,
  onChangeFirst,
  onChangeSecond,
  onNoCode,
}) => {
  const {
    isMultipleOperators,
    title,
    migrationCodeCtaLabel,
    findmigrationlabel,
    skipMigrationCodeCheckboxText,
    otherInformationText,
    modalMigrationCode,
  } = portabilityData;
  const { firstOperator, firstCode, secondOperator, secondCode, noCode } = data;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const multiPlayOfferingConfiguration: IMultiPlayOfferingConfigurationResponse =
    getFromLocalStorageByKey('multiPlayOfferingConfiguration');
  const serviceProviders =
    multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
      ?.r1MissingMigrationFixedPortInSpecification?.r1FirstDonatingNetworkOperator
      ?.availableValues || [];
  const providers = serviceProviders.map((item) => item.displayValue);

  const portabilityCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PORTABILITY
  ) as IPortabilityCMS;

  const { chooseOperator } = organizePortabilityCard(portabilityCms);

  const toggleModal = useCallback(() => setIsOpenModal((prevState) => !prevState), []);

  const onChangeFirstOperatorData = (firstData: IFormData, isFirstValid: boolean) => {
    if (onChangeFirst) onChangeFirst(firstData, isFirstValid);
  };

  const onChangeSecondOperatorData = (secondData: IFormData, isSecondValid: boolean) => {
    LoggerInstance.debug(isSecondValid, secondData);
    if (onChangeSecond) onChangeSecond(secondData, isSecondValid);
  };

  const choseNoMigrationCodeFromModal = () => {
    if (firstOperator) {
      handleGoNextSlide?.();
    } else {
      onNoCode?.(true);
      toggleModal();
    }
  };

  return (
    <S.Container isMultipleOperators={isMultipleOperators || false}>
      <S.Title>{title}</S.Title>
      <Divider marginBottom={2} />
      <ChooseOperator
        isMultipleOperators={isMultipleOperators || false}
        title={isMultipleOperators ? 'Linea telefonica' : 'Linea telefonica e internet'}
        operators={providers}
        onChangeData={onChangeFirstOperatorData}
        textInputPlaceholder={chooseOperator[0].textInput}
        dropdownPlaceholder={chooseOperator[0].dropDown}
        hiddenMigrationCode={!!noCode}
        passedData={{ operator: firstOperator || '', migrationCode: firstCode || '' }}
      />
      {isMultipleOperators && (
        <>
          <Divider marginBottom={2} />
          <ChooseOperator
            isMultipleOperators={isMultipleOperators || false}
            title={chooseOperator[1].title}
            operators={providers}
            onChangeData={onChangeSecondOperatorData}
            textInputPlaceholder={chooseOperator[1].textInput}
            dropdownPlaceholder={chooseOperator[1].dropDown}
            hiddenMigrationCode={!!noCode}
            passedData={{
              operator: secondOperator || '',
              migrationCode: secondCode || '',
            }}
          />
        </>
      )}
      <S.LinkWithIconStyle>
        <S.findmigrationlabel>{findmigrationlabel}</S.findmigrationlabel>
        <LinkWithIcon onClick={toggleModal} text={migrationCodeCtaLabel} />
        <S.CheckboxDiv>
          <Checkbox checked={noCode} onChange={() => onNoCode?.(!noCode)}>
            <span className="checkmark">{skipMigrationCodeCheckboxText}</span>
          </Checkbox>
        </S.CheckboxDiv>
      </S.LinkWithIconStyle>
      <S.OtherInformation>{otherInformationText}</S.OtherInformation>
      <VfModal isOpen={isOpenModal} handleClose={toggleModal} height={526}>
        <S.MigrationModalContainer>
          <S.ModalTitle>{modalMigrationCode?.title}</S.ModalTitle>
          <S.ModalDescription>{modalMigrationCode?.description}</S.ModalDescription>
          <S.CtaContainer>
            <ButtonSlide
              borderColor="#262626"
              labelColor="#262626"
              isApp={checkIsApp()}
              label={modalMigrationCode?.addMigrationCodeLabel}
              onClick={() => {
                onNoCode?.(false);
                toggleModal();
              }}
              name="action_migration"
            />
            <ButtonSlide
              borderColor="#262626"
              labelColor="#262626"
              isApp={checkIsApp()}
              label={modalMigrationCode?.noCode}
              onClick={choseNoMigrationCodeFromModal}
              name="action_migration"
            />
          </S.CtaContainer>
          <S.MigrationLabel>{modalMigrationCode?.migrationCodeLabel}</S.MigrationLabel>
        </S.MigrationModalContainer>
      </VfModal>
    </S.Container>
  );
};

export default Portability;
