import { openPopup } from "@vfit/shared/data-access";

const handleFWA = (linkFwa: string) => {
    if (linkFwa) openPopup(linkFwa);
  };

  const handleKo = (linkKO: string) => {
    if (linkKO) openPopup(linkKO);
  };

  const handleR2Logged = (linkR2: string) => {
    if (linkR2) openPopup(linkR2);
  };

export { handleFWA, handleR2Logged, handleKo };