import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  margin: 12px 44px 32px;
  height: calc(100% - 66px);

  @media (min-width: ${breakpoints.tablet}) {
    margin: 21.5px 40px 40px;
    height: auto;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 29.5px 109px 64px;
  }
`;

export const TextLoader = styled.div`
  width: inherit;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 58px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const ContentSpinner = styled.div`
  svg {
    width: 25px;
    height: 25px;
    margin: -10px 0 0 -25px;
  }
`;
