import { IPersonalInfoCmsMobile, IPortabilityMobileCMS } from '@vfit/consumer/data-access';
import { ISimTypeCardData } from './simTypeSelectionCardWinback.models';

export const organizeSimTypeCard = (
  dataFromCms: IPersonalInfoCmsMobile,
  errorPortability: IPortabilityMobileCMS
): ISimTypeCardData => ({
  cardTitle: dataFromCms?.personalinfomobile?.simType?.card?.title || 'eSim',
  cardDescription: dataFromCms?.personalinfomobile?.simType?.card?.description || '',
  modalInfo: {
    textLink: dataFromCms?.personalinfomobile?.simType?.modalInfo?.textLink || '',
    titleModal: dataFromCms?.personalinfomobile?.simType?.modalInfo?.title || '',
    descriptionModal: dataFromCms?.personalinfomobile?.simType?.modalInfo?.description || '',
  },
  migrationCodeError: {
    title: errorPortability?.portabilitymobile?.errorOnPortability?.title || 'Ops',
    description:
      errorPortability?.portabilitymobile?.errorOnPortability?.message ||
      'Il numero non può essere portato in Vodafone',
    buttonLabel: errorPortability?.portabilitymobile?.errorOnPortability?.buttonLabel || 'Riprova',
  },
  genericsError: {
    title: errorPortability?.portabilitymobile?.errorModal?.title || 'Ops',
    description:
      errorPortability?.portabilitymobile?.errorModal?.message || 'Si è verificato un errore.',
    buttonLabel: errorPortability?.portabilitymobile?.errorModal?.buttonLabel || 'Riprova',
  },
  numberError: {
    title: errorPortability?.portabilitymobile?.errorNumber?.title || 'Ops',
    description:
      errorPortability?.portabilitymobile?.errorNumber?.message ||
      'Numero errato per la portabilità',
    buttonLabel: errorPortability?.portabilitymobile?.errorNumber?.buttonLabel || 'Riprova',
  },
});
