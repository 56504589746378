import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.$ffffff};
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(30, 38)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(18, 24)}
  margin-top: 24px;
  margin-bottom: 32px;
`;

export const CtaContainer = styled.div`
  width: 100%;
  height: 45px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 258px;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 296px;
    margin: 0 auto;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }

  div {
    .textAreaInputFieldset {
      height: 100px;

      @media (min-width: ${breakpoints.tablet}) {
        height: 120px;
      }
    }
  }
`;

