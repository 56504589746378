export enum ISIAFlowType {
  RECURRENT = 1,
  INSTANT = 2,
}

export enum IUpdateProductPriceType {
  NONE = 0,
  EDIT = 1,
  EDIT_WALLET = 2,
  RESET = 3,
}

export interface IPaymentServiceResponse {
  orderPayment: {
    planId?: string;
    orderItemPayments: IOrderItem[];
    orderOnBillPayment: IOrderItemOnBillPayment;
    orderImmediatePayment?: IOrderImmediatePayment;
  };
  invoicePayment?: boolean;
}

interface IOrderItem {
  msisdn: string;
  orderActionId: string;
  mainComponentProductId?: string;
  depositAmountCharacteristicId?: string;
  orderItemOnBillPayment: IOrderItemOnBillPayment;
}

interface IOrderItemOnBillPayment {
  billingOfferIdForDistribution: string[];
  billingOfferIdForChangePaymentMethod?: string[];
  payMeanPurpose: string;
  totalPrice: [IPriceBase, ITotalPriceChargePeriod];
  currentOrderPrice: [IPriceBase, ICurrentPriceChargePeriod];
  isIBANValidationRequired: boolean;
  availablePaymentMethods?: [IAvailablePaymentMethods];
}

interface IOrderImmediatePayment {
  payMeanPurpose: string;
  totalPrice: [ITotalPrice];
  isIBANValidationRequired: boolean;
}

interface ITotalPrice {
  type: string;
  price: {
    currencyCode: string;
    taxRate: number;
    taxIncludedAmount: number;
    amount: number;
    taxAmount: number;
  };
}

export enum EMethodCode {
  CREDIT_CARD = 'creditCard',
  BANK_ACCOUNT = 'bankAccount',
  WALLET = 'wallet',
  PAYPAL = 'payPal',
  DEBIT_CARD = 'debitCard',
  PREPAID_CARD = 'prepaidCard',
}

export interface IAvailablePaymentMethods {
  methodName: string;
  methodValue: string;
  configured: boolean;
  methodCode: EMethodCode;
  productSpecCharacteristicId: string;
  type: EMethodCode;
  selection?: boolean;
}

interface IPriceBase {
  type: string;
  price: IPrice;
}

interface ITotalPriceChargePeriod {
  recurringChargePeriod: string;
  type: string;
  originalprice: IOriginalPrice;
  price: IPrice;
}

interface ICurrentPriceChargePeriod {
  recurringChargePeriod: string;
  type: string;
  price: IPrice;
}

export interface IOriginalPrice {
  currencyCode: string;
  taxIncludedAmount: number;
  amount: number;
}
interface IPrice {
  currencyCode: string;
  taxRate: number;
  taxIncludedAmount: number;
  amount: number;
  taxAmount: number;
}

