import React, { useEffect } from 'react';
import { checkIsGoBackSIA, IProduct } from '@vfit/consumer/data-access';
import { getHome } from '@vfit/shared/data-access';
import { CheckoutProvider, useCheckout } from './iBuyMobile.context';
import ShoppingCart from './ShoppingCart/shoppingCart';
import { ENABLE_DEBUG, productNotFound, retrieveProduct } from './iBuyMobile.utils';

const ShoppingCartOffer = () => {
  const { product, setProduct } = useCheckout();

  useEffect(() => {
    const productInPage = retrieveProduct(!checkIsGoBackSIA()) as IProduct;
    if (!productInPage) {
      productNotFound();
      setTimeout(() => {
        if (!ENABLE_DEBUG) window.location.href = `${getHome()}`;
      }, 1000);
    } else {
      setProduct({ ...productInPage });
    }
  }, []);

  if (!product || Object.keys(product)?.length === 0) return null;

  return <ShoppingCart />;
};

const IBuyMobile = () => (
  <CheckoutProvider>
    <ShoppingCartOffer />
  </CheckoutProvider>
);

export default IBuyMobile;
