import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { ISoftEvents } from '@vfit/consumer/providers';
import { checkWindow } from '@vfit/shared/data-access';
import { IN_TEST, isInABTest } from '@vfit/consumer/data-access';

export function useABTest() {
  const { asPath } = useRouter();

  const checkClearStorage = () => {
    if (isInABTest()) {
      localStorage.setItem(IN_TEST, '1');
    }
  };

  useEffect(() => {
    const handleEventAbTest = (event: Event) => {
      const { detail } = event as CustomEvent as { detail: boolean };
      if (!checkWindow()) {
        return;
      }
      if (detail) localStorage.setItem(IN_TEST, '1');
      else localStorage.removeItem(IN_TEST);
    };
    document.addEventListener(ISoftEvents.SET_AB_TEST, handleEventAbTest);
    return () => {
      document.removeEventListener(ISoftEvents.SET_AB_TEST, handleEventAbTest);
    };
  }, []);

  useEffect(() => {
    checkClearStorage();
  }, [asPath]);
}
