import { useEffect, useState } from 'react';
import { useReleaseResource, useReservePhoneNumber } from '@vfit/consumer/data-access';
import { IUseReserveMobileFlow, IUseReserveMobileFlowParams } from './useReserveFlow.models';

export const useReserveFlow = ({
  flowEnabler,
}: IUseReserveMobileFlowParams): IUseReserveMobileFlow => {
  const [config, setConfig] = useState<{
    isSuccess: boolean;
    isLoading: boolean;
    isError: boolean;
  }>({
    isSuccess: false,
    isLoading: false,
    isError: false,
  });

  const {
    mutate: reserveMutate,
    isSuccess: isSuccessReserve,
    isLoading: isLoadingReserve,
    isError: isErrorReserve,
    data: reserveData,
  } = useReservePhoneNumber();

  const {
    mutate: releaseResourceMutate,
    isSuccess: isSuccessReleaseResource,
    isLoading: isLoadingReleaseResource,
    isError: isErrorReleaseResource,
  } = useReleaseResource();

  useEffect(() => {
    if (
      flowEnabler &&
      !isLoadingReserve &&
      !isSuccessReserve &&
      !isErrorReserve &&
      !isLoadingReleaseResource &&
      !isErrorReleaseResource &&
      !isSuccessReleaseResource
    ) {
      setConfig((prevState) => ({
        ...prevState,
        isError: false,
        isSuccess: false,
        isLoading: true,
      }));
      reserveMutate();
    }
  }, [flowEnabler]);

  useEffect(() => {
    if (isSuccessReleaseResource && !isErrorReleaseResource && !isLoadingReleaseResource) {
      setConfig({ isSuccess: true, isLoading: false, isError: true });
    } else if (isErrorReleaseResource) {
      setConfig({ isSuccess: false, isLoading: false, isError: true });
    }
  }, [isSuccessReleaseResource, isLoadingReleaseResource]);

  useEffect(() => {
    if (isSuccessReserve && !isErrorReserve && !isLoadingReserve) {
      releaseResourceMutate({ valueList: reserveData?.map((el) => el.value || '') });
    } else if (isErrorReserve) {
      setConfig({ isSuccess: false, isLoading: false, isError: true });
    }
  }, [isSuccessReserve, isLoadingReserve]);

  return { ...config };
};
