import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';
import { Wrapper } from '../../convergenceModal.style';

export const DetailsWrapper = styled(Wrapper)`
  margin-top: -32px;
  max-height: 100vh;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -28px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: -48px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0;
  gap: 16px;

  div {
    width:100%
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;

    div {
      width: 290px;
    }
  }

`;
