import { CMS_CONFIG, useCmsConfig } from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { FloatingBanner, Portal } from '@vfit/shared/components';
import { useDeviceType } from '@vfit/shared/hooks';
import { IFloatingBannerCMS } from '@vfit/shared/models';
import { stickyFloatingBannerOrganize } from './stickyFloatingBanner.organize';

const Floatingbanner = () => {
  const cmsData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_FLOATING_BANNER
  ) as IFloatingBannerCMS;
  const { isMobile, isTablet } = useDeviceType();

  const bannerConfig = stickyFloatingBannerOrganize(cmsData);

  const portalBanner = (
    customAppend: (element: HTMLDivElement) => void,
    customCreateWrapper?: () => HTMLDivElement
  ) => (
    <Portal
      wrapperId="banner"
      customCreateWrapper={customCreateWrapper}
      customAppendElement={(wrapperElement) => {
        customAppend(wrapperElement);
      }}
    >
      <FloatingBanner {...bannerConfig} />
    </Portal>
  );

  const renderBannerDesktop = () =>
    portalBanner((wrapperElement: HTMLDivElement) => {
      const headerFirstChild = document.getElementById('mainHeader')?.firstChild;
      if (headerFirstChild)
        headerFirstChild?.parentNode?.insertBefore?.(wrapperElement, headerFirstChild?.nextSibling);
    });

  const renderBannerMobileTablet = () =>
    portalBanner(
      (wrapperElement: HTMLDivElement) => {
        const headerFirstChild = document.getElementById('mainHeader');
        if (headerFirstChild)
          headerFirstChild?.parentNode?.insertBefore?.(
            wrapperElement,
            headerFirstChild?.nextSibling
          );
      },
      () => {
        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute('id', 'banner');
        wrapperElement.style.position = 'fixed';
        wrapperElement.style.top = '0px';
        wrapperElement.style.zIndex = '5';
        return wrapperElement;
      }
    );

  return (
    <>
      {(isMobile || isTablet) && renderBannerMobileTablet()}
      {!isMobile && !isTablet && renderBannerDesktop()}
    </>
  );
};

export default Floatingbanner;
