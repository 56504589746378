import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IFindCallerResponse,
  IShoppingCartResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ISubmitPayload, ISubmitResponse } from './submit.models';

/**
 * API service to submit user's order
 * @param cartId
 * @param payload
 * @param customOptions
 * @param searchParams
 */
export const submitService = async (
  cartId: string,
  payload: ISubmitPayload,
  customOptions?: CustomOptions,
  searchParams?: { [key: string]: string }
): Promise<ISubmitResponse> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.SUBMIT}`, payload, {
    searchParams: {
      ...nextClient.searchParams,
      ...searchParams,
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Mutation for saving into the key 'submitQuery'
 * the response coming from the API service to submit user's order
 * @returns
 */
export const useSubmitMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'submitMutation',
    () => {
      const shoppingCartSaved = localStorage.getItem('shoppingCart');
      const shoppingCart: IShoppingCartResponse =
        !!shoppingCartSaved && JSON.parse(shoppingCartSaved as string);
      LoggerInstance.debug('shoppingCart: ', shoppingCart);
      const cartId = shoppingCart && shoppingCart?.id;
      const cartItems = shoppingCart && shoppingCart?.cartItem[0];

      // Get customerData from local storage
      const customerSaved = localStorage.getItem('customerData');
      const customerData: GetCustomerResponse[] =
        !!customerSaved && JSON.parse(customerSaved as string);
      LoggerInstance.debug('customerData: ', customerData);
      const customerId = customerData?.[0]?.id;

      // Get deliveryDetails from local storage
      const deliveryDetailsSaved = localStorage.getItem('deliveryDetailsIsEvolved');
      const deliveryDetails = !!deliveryDetailsSaved && JSON.parse(deliveryDetailsSaved as string);
      const deliveryMethod =
        !!deliveryDetails && deliveryDetails[0]?.deliveryItems?.[0].details?.[0]?.code;

      const payload: ISubmitPayload = {
        customerId,
        isPortIn: true,
        deliveryMethod,
        productsInOrders: [
          { orderItemId: cartItems.dataSim[0].id },
          { orderItemId: cartItems.fixedVoice[0].id },
          { orderItemId: cartItems.internet[0].id },
        ],
      };

      return submitService(cartId, payload, undefined, {
        bypassChargeDistribution: 'true',
      });
    },
    {
      onSuccess: (data: ISubmitResponse) => {
        // save authorize object into storage
        localStorage.setItem('submitOrder', JSON.stringify(data));
        queryClient.setQueryData('submitQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - submitQuery: `, error);
        queryClient.setQueryData('submitQuery', errorMock(`submitQuery`, error));
        localStorage.setItem(`submitQuery`, JSON.stringify(errorMock(`submitQuery`, error)));
      },
    }
  );
};

export const useSubmitFixedMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'submitMutation',
    () => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const cartId = shoppingCart?.id;
      const cartItems = shoppingCart?.cartItem[0];
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const deliveryDetails = getFromLocalStorageByKey('deliveryDetailsIsAdvance');

      const getDeliveryCode = (dI: any): string => {
        if (dI?.length > 0) {
          const details = [];
          dI?.forEach((el) => details.push(el?.details?.[0] || {}));
          const filteredItem = details?.find((itemToFilter) => itemToFilter?.isSelected);
          return filteredItem?.code || 'S';
        }
        return 'T';
      };

      const deliveryMethod = getDeliveryCode(deliveryDetails[0]?.deliveryItems);
      const payload: ISubmitPayload = {
        customerId,
        isPortIn: true,
        deliveryMethod,
        productsInOrders: [
          { orderItemId: cartItems?.dataSim?.[0]?.id },
          { orderItemId: cartItems?.fixedVoice?.[0]?.id },
          { orderItemId: cartItems?.internet?.[0]?.id },
        ],
      };

      return submitService(cartId, payload, undefined, {
        bypassChargeDistribution: 'true',
      });
    },
    {
      onSuccess: (data: ISubmitResponse) => {
        // save authorize object into storage
        localStorage.setItem('submitOrder', JSON.stringify(data));
        queryClient.setQueryData('submitQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - submitQuery: `, error);
        queryClient.setQueryData('submitQuery', errorMock(`submitQuery`, error));
        localStorage.setItem(`submitQuery`, JSON.stringify(errorMock(`submitQuery`, error)));
      },
    }
  );
};

export const useSubmitMobileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'submitMutation',
    () => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const deliveryDetails = getFromLocalStorageByKey('postDeliveryServiceDetails');
      const portability: string | null = localStorage.getItem('portability');

      const deliveryMethod = deliveryDetails?.deliveryDetails?.[0]?.code || '';
      const cartId = shoppingCart?.id;
      const cartItems = shoppingCart?.cartItem?.[0];
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;

      const payload: ISubmitPayload = {
        customerId,
        isPortIn: portability === '1',
        deliveryMethod,
        productsInOrders: [{ orderItemId: cartItems?.id }],
        checkSimSwapCounter: false,
      };

      return submitService(cartId, payload, undefined, {
        bypassChargeDistribution: 'true',
      });
    },
    {
      onSuccess: (data: ISubmitResponse) => {
        // save authorize object into storage
        localStorage.setItem('submitOrder', JSON.stringify(data));
        queryClient.setQueryData('submitQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - submitQuery: `, error);
        queryClient.setQueryData('submitQuery', errorMock(`submitQuery`, error));
        localStorage.setItem(`submitQuery`, JSON.stringify(errorMock(`submitQuery`, error)));
      },
    }
  );
};
