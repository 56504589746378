export enum ProductType {
  ADDON = 'ADDON',
  NBA = 'NBA',
  MASSMARKET = 'MASSMARKET',
}
export interface IActivationPayload {
  id?: ICatalogProductId;
  name?: string;
  type?: string;
  activationDate?: string;
  components?: any;
}

export interface IActiveResponse {
  id?: ICatalogProductId;
  name?: string;
  type?: string;
  productType?: ProductType;
  description?: string;
  longDescription?: string;
  campaignDetail?: ICampaignDetail;
  cmsHighlighted?: boolean;
  cmsCategory: string;
  cmsCustomization: any;
}

export interface ICampaignDetail {
  bolloDescription: string;
  featureDescription: IFeatureDescription[];
}

interface IFeatureDescription {
  name: string;
  value: string;
}
interface ICatalogProductId {
  productOfferingId?: string;
  specificationId?: string;
  pricePlanId?: string;
  customerId?: string;
  treatmentCode?: string;
  campaignId?: string;
}

export interface IQueryString {
  msisdn?: string;
  productType?: ProductType;
}

export interface IActivationRequest {
  payload: IActivationPayload;
  queryString: IQueryString;
  deviceId?: string;
}

export interface IActivableResponse {
  mop?: string;
  sections?: IActivableSection[];
}

interface IActivableSection {
  item?: string;
  itemType?: string;
  products?: IActivableProduct[];
}

export interface IActivableProduct {
  id?: ICatalogProductId;
  name?: string;
  description?: string;
  type?: string;
  productType?: ProductType;
  bundlePO?: string;
  serviceId?: string;
  note?: string;
  category?: string;
  finalPrice?: any;
  activationDate?: string;
  isCampaign?: boolean;
  campaignDetail?: {
    bolloDescription: string;
    components: any;
    price?: {
      priceRef: string;
      value: string;
      priceType: string;
    }[];
  };
}
