import { API } from '@vfit/shared/data-access';
import {
  CustomOptions,
  INextError,
  LoggerInstance,
  UseQueryConfig,
  handleUseQuery,
  dxlConsumerCloudClient
} from '@vfit/shared/data-access';
import { UseQueryResult } from 'react-query';
import { IIdentifyResponse } from './identify.models';
import { errorMock } from '../../utils/utils';

/**
 * This method is used to retrieve data for enrichment services: it retrieves the msisdn if the customer has one associated.
 * This in used in the customHookFlow hook
 * Method: GET
 * @returns
 */

const identifyService = (customOptions?: CustomOptions) =>
  dxlConsumerCloudClient.get(API.DXL_IDENTIFY, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IIdentifyResponse>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the identifyService
 * @param customOptions
 * @returns
 */

export const useIdentify = (
  customOptions?: CustomOptions
): UseQueryResult<IIdentifyResponse, INextError> => {

  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'identify';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const result = identifyService(customOptions);
      return result;
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: customOptions?.enabled !== undefined ? customOptions.enabled : false,
      onSuccess: (data: IIdentifyResponse) => {
        localStorage.setItem('identify', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - identify', error);
        localStorage.setItem('identify', JSON.stringify(errorMock('identify')));
      },
    },
  };

  return handleUseQuery(options) as UseQueryResult<IIdentifyResponse, INextError>;
};

