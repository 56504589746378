import { Button } from '@vfit/shared/atoms';
import { ProductDetail } from '@vfit/shared/components';
import { getUserInfo } from 'libs/consumer/providers/src/lib/softManager/utils';
import { IDetailsStepProps } from './detailsStep.models';
import { ButtonDiv, DetailsWrapper } from './detailsStep.style';
import { ButtonContainer } from '../../fallbackModal.style';

const DetailsStep = ({
  details,
  modalInfo,
  onButtonClick,
  onSecondButtonClick,
}: IDetailsStepProps) => (
  <DetailsWrapper>
    <ProductDetail
      trackingOpt={{
        event: ['ui_interaction'],
        event_label: 'offer details',
        opts: { event_category: 'users', event_action: 'click' },
        visitorTrackingOpts: getUserInfo(),
        disableInitialTrack: true,
      }}
      details={details}
      modalInfo={modalInfo}
    />
    <ButtonContainer>
      <ButtonDiv>
        <Button onClick={onButtonClick} variant={details.firstButtonVarian || 'primary'}>
          {details.firstButtonLabel}
        </Button>
        {onSecondButtonClick && details.secondButtonLabel && !details.disableSecondAction && (
          <Button onClick={onSecondButtonClick} variant={details.seconButtonVarian || 'secondary'}>
            {details.secondButtonLabel}
          </Button>
        )}
      </ButtonDiv>
    </ButtonContainer>
  </DetailsWrapper>
);

export default DetailsStep;

