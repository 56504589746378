import { nextClient } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { useQuery, useQueryClient } from 'react-query';

const getCampaignDetailsService = (campaignId: string, audienceId: string): Promise<unknown> =>
  nextClient.get(`${API.CAMPAIGN}/${campaignId}`, {
    searchParams: {
      salesChannel: 'selfService',
      audienceType: 'SUBSCRIBER_ID',
      audienceId,
    },
  });

export const useGetCampaignDetails = (campaignId: string, queryEnabler?: boolean) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['getCampaignDetails'],
    () => {
      const getSubscriberByResource = queryClient.getQueryData('getSubscriberByResource');
      const audienceId = getSubscriberByResource?.[0]?.subscriberId || '';
      return getCampaignDetailsService(campaignId, audienceId);
    },
    {
      enabled: queryEnabler || false,
    }
  );
};
