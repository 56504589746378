import { useEffect, useState } from 'react';
import {
  BillingAddress,
  checkIsGoBackSIA,
  IBillingAccountResponse,
  useBillDelivery,
  useCreateAddress,
  useReplaceBillingAddressV1,
  useValidateAddress,
} from '@vfit/consumer/data-access';
import { IAddressResponse, IValidateAddressRequest } from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IUseDeliveryBillingResponse } from './useDeliveryBillingFlow.models';
import { useCheckout } from '../../../iBuyFixed.context';
import { InvoiceType } from '../../components/InvoiceCard/components/ChooseInvoice/chooseInvoice.models';

const ENABLE_REPLACE_LOGIC = false;

const DEFAULT_DELIVERY_BILLING: IUseDeliveryBillingResponse = {
  validateAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  replaceAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  billDelivery: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const useDeliveryBillingFlow = (): IUseDeliveryBillingResponse => {
  const { invoice, billingAddress, customerFlow, billingEmail } = useCheckout();
  const enableBillDelivery = customerFlow?.billingAccount?.isSuccess;
  const [billingReturn, setBillingReturn] =
    useState<IUseDeliveryBillingResponse>(DEFAULT_DELIVERY_BILLING);
  const {
    mutateAsync: billDeliveryMutation,
    isLoading: isLoadingBillDelivery,
    isSuccess: isSuccessBillDelivery,
    isError: isErrorBillDelivery,
  } = useBillDelivery();
  const {
    mutate: validateAddressMutation,
    data: validatedShippingAddress,
    isLoading: validateAddressIsLoading,
    isSuccess: validateAddressIsSuccess,
    isError: validateAddressIsError,
  } = useValidateAddress();
  const {
    mutate: createAddressMutation,
    data: createAddressData,
    isLoading: isLoadingCreateAddressMutation,
    isSuccess: isSuccessCreateAddressMutation,
    isError: isErrorCreateAddressMutation,
  } = useCreateAddress();
  const {
    mutate: replaceBillingAddressMutation,
    isLoading: isLoadingReplace,
    isSuccess: isSuccessReplace,
    isError: isErrorReplace,
  } = useReplaceBillingAddressV1();

  const createBillingAddress = (
    billingAddressCreatedOrValidated: IAddressResponse | BillingAddress
  ) => {
    const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
    const billingAccountId = billingAccount?.id;

    if (ENABLE_REPLACE_LOGIC) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
        billingEbill: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      replaceBillingAddressMutation({
        billingAccountId,
        billingAddressId: billingAddressCreatedOrValidated.id,
        billingAddressType: 'postalAddress',
      });
    } else {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
        replaceAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
    }
  };

  useEffect(() => {
    if (isLoadingReplace) {
      setBillingReturn((prevState) => ({
        ...prevState,
        replaceAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorReplace) {
      setBillingReturn((prevState) => ({
        ...prevState,
        replaceAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessReplace) {
      setBillingReturn((prevState) => ({
        ...prevState,
        replaceAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
    }
  }, [isSuccessReplace, isLoadingReplace, isErrorReplace]);

  useEffect(() => {
    if (isLoadingCreateAddressMutation) {
      setBillingReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorCreateAddressMutation) {
      setBillingReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessCreateAddressMutation) {
      setBillingReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      createBillingAddress(createAddressData);
    }
  }, [
    isSuccessCreateAddressMutation,
    isLoadingCreateAddressMutation,
    isErrorCreateAddressMutation,
  ]);

  useEffect(() => {
    if (isLoadingBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billDelivery: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billDelivery: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billDelivery: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
    }
  }, [isSuccessBillDelivery, isLoadingBillDelivery, isErrorBillDelivery]);

  useEffect(() => {
    if (validateAddressIsLoading) {
      setBillingReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (validateAddressIsError) {
      setBillingReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (validateAddressIsSuccess) {
      setBillingReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      createAddressMutation({
        address: validatedShippingAddress?.validAddressList?.[0],
        isDelivery: true,
      });
    }
  }, [validateAddressIsSuccess, validateAddressIsLoading, validateAddressIsError]);

  useEffect(() => {
    if (!checkIsGoBackSIA()) {
      if (invoice?.option) {
        if (invoice.option === InvoiceType.EBILL) {
          billDeliveryMutation({
            billFormat: InvoiceType.EBILL,
            emailAddress: billingEmail
          });
        } else {
          billDeliveryMutation({
            billFormat: 'paperBill',
            physicalAddress: createAddressData,
          });
        }
      }
    }
  }, [invoice, billingEmail]);

  useEffect(() => {
    if (
      enableBillDelivery &&
      billingAddress?.street &&
      billingAddress?.city &&
      !validateAddressIsLoading &&
      !checkIsGoBackSIA()
    ) {
      validateAddressMutation({
        placeObject: billingAddress as unknown as IValidateAddressRequest,
      });
    }
  }, [billingAddress, enableBillDelivery]);

  return billingReturn;
};
