import { ReactNode, useEffect, useRef, useState } from 'react';
import { ISupportModule, StepperModal } from '@vfit/shared/components';
import { useCmsConfig, CMS_CONFIG, usePage } from '@vfit/consumer/data-access';
import { API, openPopup, PAGES, useTracking, volaModalManager } from '@vfit/shared/data-access';
import { IActionSunrise, IActionType, IClickToCallMeData } from '@vfit/shared/models';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { ETrackConvergenceModal, IConvergenceModalProps } from './fallbackModal.models';
import OfferStep from './components/OfferStep/offerStep';
import DetailsStep from './components/DetailsStep/detailsStep';

const FallbackModal = ({
  product,
  isOpen,
  handleClose,
  handleAccept,
  buildTrackingObject,
  offerData,
  taggingPageName,
}: IConvergenceModalProps) => {
  const { page } = usePage();
  const queryClient = useQueryClient();
  const { trackView, trackWithNewDatalayer } = useTracking({
    event: [`${taggingPageName}`],
    disableInitialTrack: true,
  });
  const stepperRef = useRef<any>(null);
  const { OFFER_STEP, DETAILS_STEP } = ETrackConvergenceModal;
  const [currentStep, setCurrentStep] = useState(OFFER_STEP);
  const volaClickToCallData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_CLICK_TO_CALL_CONF
  ) as IClickToCallMeData;
  const supportModulesCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_SUPPORT_MODULE
  ) as ISupportModule;

  const handleAction = (action: IActionSunrise) => {
    const type = action.type ? +action.type : 0;
    switch (type) {
      case IActionType.CUSTOM_URL:
        handleAccept();
        break;
      case IActionType.CALL_ME_NOW:
        if (action?.callMeNow) openPopup(action.callMeNow);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (action?.title && action?.url) {
          volaModalManager.handleVolaModal({
            url: action?.url || '',
            cmsClickToCallData: { ...volaClickToCallData },
            taggingOption: {
              product_target_segment: 'consumer',
              event_category: 'sales',
              page_subsection: 'checkout',
              versatile_prop: 'new ctc',
            },
            pageProduct: SoftManagerService(queryClient).getPageProduct(product),
          });
        }
        break;
      default:
        break;
    }
  };

  const trackLink = (label?: string) => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: `${label}`,
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  useEffect(() => {
    if (isOpen) {
      const trackingOfferStep = buildTrackingObject(currentStep);
      trackWithNewDatalayer({
        ...trackingOfferStep,
      });
    }
  }, [currentStep, isOpen]);

  const components: ReactNode[] = [
    <OfferStep
      data={{
        title: offerData?.offerProposition?.title || '',
        offerDescription: offerData?.offerProposition?.description || '',
        productTitle: offerData?.offerDetails?.title || '',
        price: offerData?.price || '',
        recurrence: offerData?.recurrence || '',
        priceDescription: offerData?.priceDescription || '',
        productSpecs: offerData?.specs || [],
        firstButtonLabel: offerData?.firstButtonAction?.title || '',
        fistButtonVariant: offerData?.firstButtonAction?.style,
        secondButtonLabel: offerData?.secondButtonAction?.title,
        secondButtonVariant: offerData?.secondButtonAction?.style,
        detailsLabel: offerData?.offerProposition?.showDetailLabel || '',
      }}
      onFirstButtonClick={() => {
        const firstButtonLabel = offerData?.firstButtonAction?.title || '';
        handleAction(offerData?.firstButtonAction);
        trackLink(firstButtonLabel);
      }}
      onSecondButtonClick={() => {
        handleAction(offerData?.secondButtonAction);
        const secondButtonLabel = offerData?.secondButtonAction?.title || '';
        trackLink(secondButtonLabel);
      }}
      onSeeDetailClick={() => {
        stepperRef?.current?._stepperModalGoNext();
        const detailsLabel = offerData?.offerProposition?.showDetailLabel || '';
        const modalContent = document.getElementById('modalContent');
        modalContent?.scrollTo(0, 0);
        trackLink(detailsLabel);
        setCurrentStep(DETAILS_STEP);
      }}
    />,
    product ? (
      <DetailsStep
        details={{
          title: offerData?.offerDetails?.title || '',
          conditions: offerData?.offerDetails.conditions,
          description: offerData?.offerDetails.description,
          offerDetails: offerData?.offerDetails.offerDetails,
          price: offerData?.price || '',
          recurrence: offerData?.recurrence,
          disableSecondAction: offerData?.offerDetails?.disableSecondAction,
          firstButtonLabel: offerData?.firstButtonAction?.title || '',
          firstButtonVarian: offerData?.firstButtonAction?.style,
          secondButtonLabel: offerData?.secondButtonAction.title,
          seconButtonVarian: offerData?.secondButtonAction?.style,
        }}
        modalInfo={{
          title: supportModulesCms?.supportmodules?.needHelp?.title || '',
          ctaName: supportModulesCms?.supportmodules?.needHelp?.ctaSupportCall || '',
        }}
        onButtonClick={() => {
          handleAction(offerData?.firstButtonAction);
          const firstButtonLabel = offerData?.firstButtonAction?.title || '';
          trackLink(firstButtonLabel);
        }}
        onSecondButtonClick={() => {
          handleAction(offerData?.secondButtonAction);
          const secondButtonLabel = offerData?.secondButtonAction?.title || '';
          trackLink(secondButtonLabel);
        }}
      />
    ) : (
      <div />
    ),
  ];

  return (
    <StepperModal
      id="fallback-modal"
      isOpen={isOpen}
      handleClose={handleClose}
      ref={stepperRef}
      components={components}
    />
  );
};

export default FallbackModal;
