import React, { useEffect } from 'react';
import { Form } from '@vfit/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormExtended } from '@vfit/consumer/hooks';
import { Container, Title, InputWrapper } from './chooseOperator.style';
import { IChooseOperator } from './chooseOperator.model';
import { chooseOperatorSchema } from './chooseOperatorSchema.validation';
import { IFormData } from '../../../portabilityMigrationCard.models';

const ChooseOperator: React.FC<IChooseOperator> = ({
  onChangeData,
  operators,
  title,
  isMultipleOperators,
  dropdownPlaceholder,
  textInputPlaceholder,
  hiddenMigrationCode,
  passedData,
}) => {
  const {
    hookReturn: {
      register,
      setValue,
      getValues,
      formState: { errors },
    },
    registerSearchSelect,
  } = useFormExtended<IFormData>({
    resolver: yupResolver(chooseOperatorSchema()),
    mode: 'all',
  });

  const handlerData = () => {
    const allValues = getValues();
    const checkIsValid =
      allValues.operator !== '' &&
      allValues.migrationCode.length >= 8 &&
      allValues.migrationCode.length <= 20;
    onChangeData?.(getValues(), checkIsValid);
  };

  const isPassed = (data?: IFormData) => data?.operator !== '' && data?.migrationCode !== '';

  useEffect(() => {
    const shouldValidate = isPassed(passedData);
    if (passedData) {
      setValue('operator', passedData.operator, {
        shouldValidate,
        shouldTouch: shouldValidate,
      });
      setValue('migrationCode', passedData.migrationCode, {
        shouldValidate,
        shouldTouch: shouldValidate,
      });
    }
  }, []);

  return (
    <Container>
      <Title>{title}</Title>
      <InputWrapper isMultipleOperators={isMultipleOperators}>
        <div className="text-input">
          <Form.SearchSelect
            placeholder=" "
            label={dropdownPlaceholder}
            items={operators}
            searchable
            autoComplete="off"
            {...registerSearchSelect('operator')}
            setValue={(v) => {
              setValue('operator', v);
              handlerData();
            }}
          />
        </div>
        {!hiddenMigrationCode && (
          <div className="textInput">
            <Form.TextInput
              label={textInputPlaceholder}
              placeholder=" "
              {...register('migrationCode', {
                onChange: (e) => {
                  setValue('migrationCode', e.target.value.replace(/\s+/g, ''));
                  handlerData();
                },
              })}
              error={errors?.migrationCode?.message || ''}
            />
          </div>
        )}
      </InputWrapper>
    </Container>
  );
};

export default ChooseOperator;
