import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';
import { IProductCardTopImage } from '../../productCard.style';

export const ProductCardTopImageLoader = styled.div<IProductCardTopImage>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
  max-height: 300px;
  border-radius: 20px 20px 0 0;
  padding-left: 22px;
  padding-right: 22px;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  @media (min-width: ${breakpoints.tablet}) {
    height: ${({ large }) => (large ? '300px' : '150px')};
    padding-left: 36px;
    padding-right: 36px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: ${({ large }) => (large ? '300px' : '200px')};
    padding-left: 36px;
    padding-right: 36px;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const ProductCardTextLoader = styled.div`
  width: 100%;
  height: 70px;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const ProductCardListLoaderEmpty = styled.div`
  height: 70px;
  background-color: white;
`;

export const ProductCardListLoader = styled.div`
  width: 100%;
  height: 30px;
  margin-bottom: 8px;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

