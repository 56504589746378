import { useMutation, useQueryClient } from 'react-query';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { ISilentLoginOptions, ISilentLoginResponse } from './silentLogin.models';

/**
 * Silent login api service.
 * API	POST authentication/external-user/v1/silentLogin
 * In the first time -  This request return to us the main information  about the user
 * (The next times it's just checks if the user is login or not)
 * Method:	POST
 * QueryString Params: salesChannel=selfService
 * @param options
 * @returns
 */
export const silentLoginService = (customOptions?: CustomOptions): Promise<ISilentLoginResponse> =>
  nextClient.post(
    API.SILENT_LOGIN,
    {},
    {
      ...(customOptions?.headers && { headers: customOptions.headers }),
      ...(customOptions?.silentLoginHeaders && {
        silentLoginHeaders: customOptions.silentLoginHeaders,
      }),
    }
  );

/**
 * Silent Login mutation
 * @returns
 */
export const useSilentLogin = (customOptions?: CustomOptions) => {
  const queryClient = useQueryClient();
  const keyString = 'silentLogin';

  return useMutation(
    'loginCheckMutation',
    (r7FlowIndicator?: boolean) => {
      let options: { headers: any; };
      if (!r7FlowIndicator) {
        options = { headers: { ...nextClient.baseHeaders, r7flowindicator: 'false' } };
      }
      LoggerInstance.debug('TEST R2 - options', options);

      return silentLoginService({
        headers: {
          ...options?.headers,
          ...customOptions?.headers,
        },
        ...(customOptions?.silentLoginHeaders && {
          silentLoginHeaders: customOptions.silentLoginHeaders,
        }),
      });
    },
    {
      onSuccess: (data: ISilentLoginResponse) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        sessionStorage.removeItem('uxfauthorization');
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
        customOptions?.onError?.(error);
      },
    }
  );
};

