import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFontSize } from '@vfit/shared/themes';
import { IBackStyle, SECONDS_TIMING_ANIMATION } from './stepperStateCards.models';

interface IStepperCardListItem {
  transformThis?: boolean;
  transformPrev?: boolean;
  top: number;
  width: number;
  height: number;
  opacity: number;
  zIndex: number;
}

export const DefaultBackStyle: IBackStyle = {
  color: 'white',
  visibility: 'visible',
  cursor: 'pointer',
};

export const IntersectedBackStyle: IBackStyle = {
  color: 'black',
  visibility: 'hidden',
  cursor: 'none',
};

export const StepperCardsBlurred = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #000;
`;

export const DivImgContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  position: absolute;
  filter: blur(60px);
  top: 0;
  left: 0;
`;

export const StepperCardsContainer = styled.div<{ footerHeight?: number }>`
  position: relative;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  animation: ${() => `${SECONDS_TIMING_ANIMATION}s slide-up`};
  height: calc(
    100vh - ${({ footerHeight }) => (footerHeight !== undefined ? `${footerHeight}px` : '20px')}
  );
  align-items: center;
  justify-content: center;
  display: flex;

  @keyframes slide-up {
    from {
      padding-top: 800px;
    }

    to {
      padding-top: 0;
    }
  }
`;

export const StepperCardListContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;

  @media (min-width: ${breakpoints.tablet}) {
    width: 70%;
    zoom: 0.85;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 70%;
    zoom: 0.75;
  }
`;

export const StepperCardList = styled.ul``;

export const StepperCardListItem = styled.li<IStepperCardListItem>`
  transition: all 400ms ease-in;
  position: absolute;
  list-style: none;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: ${colors.$ffffff};
  background-size: cover;
  border-radius: 20px;
  animation: ${({ transformThis, transformPrev }) => {
    if (!transformPrev && !transformThis) return 'inherit';
    if (transformThis) return 'scale-down 500ms';
    return 'scale-up 500ms';
  }};
  top: ${({ top }) => `${top}px`};
  width: ${({ width }) => `${width}px`};
  min-height: ${({ height }) => `${height}px`};
  opacity: ${({ opacity }) => `${opacity}`};
  z-index: ${({ zIndex }) => `${zIndex}`};

  &:last-child::after {
    content: ' ';
    display: block;
    background: transparent;
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: -85px;
  }

  @keyframes scale-up {
    from {
      opacity: 0;
      transform: translate3d(-57px, 15px, 0) rotate(-4deg);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0) rotate(0deg);
    }
  }

  @keyframes scale-down {
    from {
      opacity: 1;
      transform: translate3d(0, 0, 0) rotate(0deg);
    }

    to {
      opacity: 0;
      transform: translate3d(-57px, 15px, 0) rotate(-4deg);
    }
  }
`;

export const Skeleton = styled.div<{ height: number }>`
  padding: 65px 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;
  min-height: ${({ height }) => `${height}px`};
`;

export const StepperCardNavigation = styled.div`
  z-index: 2;
  position: fixed;
  margin-top: 27px;
  margin-left: 13px;
  display: flex;
  align-items: center;

  div {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 9px;
      height: 16px;
    }
  }

  span {
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(14)}
    color: white;
    margin-left: 15px;
    z-index: -1;
  }

  @media (min-width: ${breakpoints.tablet}) {
    cursor: pointer;
    margin-top: 37px;
    margin-left: 50px;
    padding-left: 0;

    span {
      ${pxToCssFontSize(16)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    z-index: 2;
    margin-left: 98px;
    padding-left: 0;
  }
`;

