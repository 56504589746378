import React, { useState } from 'react';
import { checkWindow } from '@vfit/shared/data-access';
import { VfModal } from '@vfit/shared/components';
import { Container, Fab, ContainerButtons } from './editAppSession.style';

const EditAppSession = () => {
  const [localCF, setLocalCF] = useState<string>('');
  const [cmsID, setCmsID] = useState<string>('');
  const [dxlID, setDxlID] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const setModalHeight = () => {
    const defaultValue = 563;
    if (!checkWindow()) return defaultValue;
    const height = window?.innerHeight;
    if (!height) return defaultValue;
    return height - (height * 30) / 100;
  };

  const saveSession = () => {
    if (cmsID) localStorage.setItem('cmsIdAppMock', cmsID);
    if (dxlID) localStorage.setItem('dxlIdAppMock', dxlID);
    if (localCF) localStorage.setItem('cfAppMock', localCF);
    window.location.reload();
  };

  const resetMockSession = () => {
    localStorage.removeItem('cmsIdAppMock');
    localStorage.removeItem('cfAppMock');
  };

  return (
    <Container>
      <VfModal
        wrapperId="editAppSession"
        height={setModalHeight()}
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
      >
        <>
          <div>
            <p>CMS ID</p>
            <input type="text" value={cmsID} onChange={(e) => setCmsID(e.target.value)} />
          </div>
          <div>
            <p>DXL ID</p>
            <input type="text" value={dxlID} onChange={(e) => setDxlID(e.target.value)} />
          </div>
          <div>
            <p>CF</p>
            <input type="text" value={localCF} onChange={(e) => setLocalCF(e.target.value)} />
          </div>
          <ContainerButtons>
            <button type="button" onClick={saveSession}>
              SAVE
            </button>
            <button type="button" onClick={resetMockSession}>
              RESET
            </button>
          </ContainerButtons>
        </>
      </VfModal>
      <Fab onClick={() => setShowModal(true)}>
        <span>set mock</span>
      </Fab>
    </Container>
  );
};

export default EditAppSession;
