import { getTechnology, TechnologyCategories, TechTypes } from '@vfit/shared/data-access';
import {
  IOfferingServiceabilityResponse,
  IServiceabilityCharacteristic,
} from '@vfit/shared/models';

const recoverServiceabilityField = (data: IServiceabilityCharacteristic[], field: string) => {
  const fieldRetrived = data?.filter((item: any) => item.displayName === field);
  if (!fieldRetrived || fieldRetrived.length === 0) return '';
  if (field === 'DisplayTechnologyName') {
    return fieldRetrived?.[0]?.displayValue;
  }
  return fieldRetrived[0].displayValue as TechTypes;
};

const organizeTechnology = (offeringServiceability: IOfferingServiceabilityResponse) => {
  const originalTechType: TechTypes = recoverServiceabilityField(
    offeringServiceability?.serviceabilityCharacteristic,
    'Technology'
  ) as TechTypes;
  const mappedCategory = getTechnology(originalTechType);
  const displayedTecnology =
    recoverServiceabilityField(
      offeringServiceability?.serviceabilityCharacteristic,
      'DisplayTechnologyName'
    ) || '';
  const cmsConfigUrl = `${mappedCategory.toLowerCase()}.json`;
  return {
    originalTechType,
    mappedCategory,
    displayedTecnology: mappedCategory,
    shoppingCartTech: displayedTecnology,
    cmsConfigUrl,
  };
};

export {
  TechTypes,
  TechnologyCategories,
  getTechnology,
  organizeTechnology,
  recoverServiceabilityField,
};
