import styled from 'styled-components';
import {fonts, pxToCssFont, colors} from '@vfit/shared/themes';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 16px;
`;

export const Heading = styled.div``;

export const Title = styled.div`
  font-weight: 700;
  ${pxToCssFont(24, 32)}
`;

export const Subtitle = styled.div`
  font-weight: 400;
  ${pxToCssFont(24, 32)}
`;

export const PromoDisclaimer = styled.div`
  ${pxToCssFont(16, 22)}
  font-family: ${fonts.regular};
  color: ${colors.$7e7e7e};
  a{
    font-family: ${fonts.regular};
    font-weight: 700;
    color: black;
    text-decoration: underline;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ListElement = styled.div`
  display: flex;
  gap: 12px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  justify-content: center;
  p {
    margin: 0;
  }
`;
export const Text = styled.p`
  font-weight: 400;
  ${pxToCssFont(20, 28)};
  margin: 0;
`;

export const IconContainer = styled.div`
  width: 42px;
  height: 42px;
`;
