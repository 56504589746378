import { CMS_CONFIG, IPersonalInfoCmsMobile, useCmsConfig } from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ListCard } from '@vfit/consumer/components';
import { organizeRecognitionSimCourier } from './recognitionSimCourier.utils';
import { IRecognitionSimCourier } from './recognitionSimCourier.models';
import { getUserInfo, getTagging } from '../../../../checkout.utils';
import { useCheckout } from '../../../../../iBuyMobile.context';

const RecognitionSimCourier = ({ isMNP, price }: IRecognitionSimCourier) => {
  const { simOptions } = useCheckout();

  const selectedSimOption = simOptions.simOptions.find((simOption) => simOption.selection);
  const isEsim = selectedSimOption?.value?.toLowerCase() === 'esim' || false;

  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;

  const taggingMobile = getTagging(isMNP ? 'mnp' : 'new sim');

  useTracking({
    event: ['checkout_step4.8'],
    event_label: 'VR: Corriere',
    opts: { ...taggingMobile?.opts, page_section: isEsim ? 'e-sim' : 'sim fisica' },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const simCourierCardData = organizeRecognitionSimCourier(dataFromCms, isEsim, price || '', isMNP);
  const list = {
    title: simCourierCardData?.list?.title,
    items: isMNP ? simCourierCardData?.list?.portabilityItems : simCourierCardData.list.items,
  };

  return <ListCard data={simCourierCardData?.data} list={list} />;
};

export default RecognitionSimCourier;
