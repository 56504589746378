import { IProduct, getSelectedOfferIds } from '@vfit/consumer/data-access';
import { checkWindow } from '@vfit/shared/data-access';

const checkFwaRedirect = (product: IProduct, allProducts: IProduct[]) => {
  const { offerId: filteredOfferID, cmsId } = getSelectedOfferIds();
  let productRedirect: IProduct | undefined;
  let fwaRedirect = false;
  if (filteredOfferID !== undefined) {
    const isAlternativeOffer = filteredOfferID != product?.offerId;
    const checkTechFWA =
      checkWindow() &&
      localStorage.getItem('isTechFWA') !== 'undefined' &&
      JSON.parse(localStorage.getItem('isTechFWA') as string) === true;

    fwaRedirect = isAlternativeOffer && checkTechFWA;

    if (fwaRedirect) {
      productRedirect = allProducts?.find((productForSlug) => productForSlug?.offerId == cmsId);
    }
  }

  return { fwaRedirect, productRedirect };
};

export { checkFwaRedirect };
