import React from 'react';
import { Button, Input, LoadingSpinner } from '@vfit/shared/atoms';
import { ICommonData } from '@vfit/shared/models';
import { checkIsApp } from '@vfit/consumer/data-access';
import { useVoucherInsertionCard } from './voucherInsertionCard.hook';
import * as S from './voucherInsertionCard.style';
import { voucherCodeFieldName, voucherSchema } from './voucherInsertion.shema';

export const VoucherInsertionCard: React.FC<ICommonData> = () => {
  const { voucherCms, handleClick, errors, register, isLoading } = useVoucherInsertionCard();
  return (
    <S.CardFrame>
      {voucherCms?.voucherstwilight?.insertion?.title && (
        <S.Title>{voucherCms?.voucherstwilight?.insertion?.title}</S.Title>
      )}
      {voucherCms?.voucherstwilight?.insertion?.message && (
        <S.Message>{voucherCms?.voucherstwilight?.insertion?.message}</S.Message>
      )}
      <Input
        error={errors?.voucherCode?.message}
        label={voucherCms?.voucherstwilight?.insertion?.inputName}
        disabled={isLoading}
        {...register(voucherCodeFieldName, voucherSchema.voucherCode)}
      />
      <Button
        disabled={isLoading}
        variant={checkIsApp() ? 'mva:primary' : 'primary'}
        onClick={handleClick}
      >
        {voucherCms?.voucherstwilight?.insertion?.buttonLabel}
        {isLoading && (
          <S.Loading>
            <LoadingSpinner />
          </S.Loading>
        )}
      </Button>
    </S.CardFrame>
  );
};

export default VoucherInsertionCard;
