import {ErrorCodes, IGenericErrorCMS, IPersonalInfoCmsMobile} from '@vfit/consumer/data-access';
import {ICMSError} from "@vfit/shared/models";
import {ErrorDetail} from "@vfit/shared/data-access";

export const organizeContactMobile = (ContactCMS: IPersonalInfoCmsMobile) => ({
  roSubtitle:
    ContactCMS?.personalinfomobile?.contactInfoRecap?.description ||
    'Ecco un riepilogo dei tuoi contatti personali per essere ricontattato al telefono e per ricevere via mail le comunicazioni sull’attivazione. Verifica che siano corretti o modificali prima di proseguire.',
  roTelephoneLable:
    ContactCMS?.personalinfomobile?.contactInfoRecap?.checkTelephone || 'Il tuo numero di telefono',
  roEmailLable:
    ContactCMS?.personalinfomobile?.contactInfoRecap?.checkEmail || 'Il tuo indirizzo email',
  roCtaTelephone: ContactCMS?.personalinfomobile?.contactInfoRecap?.editNumber || 'Modifica numero',
  roCtaEmail: ContactCMS?.personalinfomobile?.contactInfoRecap?.editEmail || 'Modifica email',
});

export const organizeContact = (ContactCMS: IPersonalInfoCmsMobile) => ({
  title: ContactCMS?.personalinfomobile?.contactAddress?.title || '',
  subtitle: ContactCMS?.personalinfomobile?.contactAddress?.subtitle || '',
  telephoneLable: ContactCMS?.personalinfomobile?.selectInput?.telephone || '',
  emailLable: ContactCMS?.personalinfomobile?.selectInput?.email || '',
  footerInfo: ContactCMS?.personalinfomobile?.contactAddress?.checkboxLabel || '',
});

export const handleCRMCodes = (crmCode : string) => {
  switch(crmCode) {
    case "CRM-0020":
    case "CRM-0024":
      return ErrorCodes.CREATE_CUSTOMER_PHONE_NUMBER_ALREADY_PRESENT;
    case "CRM-0021":
    case "CRM-0023":
      return ErrorCodes.CREATE_CUSTOMER_EMAIL_ADDRESS_ALREADY_PRESENT;
    case "CRM-0022":
    case "CRM-0025":
      return ErrorCodes.CREATE_CUSTOMER_CONTACT_ALREADY_PRESENT;
    default:
      return ''
  }
}

export const handleErrorCustomer = (errorCode : string, errorList: ICMSError[], genericError? : IGenericErrorCMS) : ErrorDetail => {
  const errorDetails = errorList.find((item : ICMSError) => item.error === errorCode);
  const errorGeneric = genericError?.genericerror || null;
  return {
    title: errorDetails?.title || errorGeneric?.title || '',
    message: errorDetails?.message || errorGeneric?.description || '',
    actionText: errorDetails?.button?.title || errorGeneric?.buttonLabel || '',
    url: errorDetails?.button?.urlForRedirect || ''
  }
}
