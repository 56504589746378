import { checkIsGoBackSIA, errorMock, useAuthorizeMutation } from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import { useCheckout } from '../../../iBuyFixed.context';

export const useAuthorizeFlow = () => {
  const { authorizeFlow, setAuthorizeFlow } = useCheckout();
  const {
    data: authorizeData,
    error: errorAuthorize,
    isSuccess: isSuccessAuthorize,
    isLoading: isLoadingAuthorize,
    isError: isErrorAuthorize,
    mutate: mutateAuthorize,
  } = useAuthorizeMutation();

  const resetReserveAuthorization = () => {
    setAuthorizeFlow({
      ...authorizeFlow,
      isStartAuthorizeFlow: false,
      resetReserveAuthorization,
      authorize: {
        data: undefined,
        error: undefined,
        isSuccess: false,
        isLoading: false,
        isError: false,
      },
    });
  };

  useEffect(() => {
    if (isLoadingAuthorize) {
      setAuthorizeFlow({
        ...authorizeFlow,
        authorize: {
          ...authorizeFlow.authorize,
          error: undefined,
          isError: false,
          isSuccess: false,
          isLoading: true,
        },
      });
    } else if (isErrorAuthorize) {
      setAuthorizeFlow({
        ...authorizeFlow,
        authorize: {
          ...authorizeFlow.authorize,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('authorize', errorAuthorize),
        },
      });
    } else if (isSuccessAuthorize) {
      setAuthorizeFlow({
        ...authorizeFlow,
        authorize: {
          isLoading: false,
          isError: false,
          isSuccess: true,
          data: authorizeData,
        },
      });
    }
  }, [isSuccessAuthorize, isLoadingAuthorize, isErrorAuthorize]);

  useEffect(() => {
    if (authorizeFlow?.isStartAuthorizeFlow && !checkIsGoBackSIA()) {
      setAuthorizeFlow({
        ...authorizeFlow,
        resetReserveAuthorization,
        isStartAuthorizeFlow: false,
      });
      if (authorizeFlow?.selectedMethod) {
        mutateAuthorize({
          siaFlowType: authorizeFlow?.siaFlowType || undefined,
          payMeanType: authorizeFlow?.selectedMethod,
          authorizationOperation: authorizeFlow?.authorizationOperationMethod,
        });
      }
    } else {
      setAuthorizeFlow({
        ...authorizeFlow,
        resetReserveAuthorization,
      });
    }
  }, [authorizeFlow?.isStartAuthorizeFlow]);

  useEffect(() => {
    setAuthorizeFlow({
      ...authorizeFlow,
      resetReserveAuthorization,
    });
  }, []);

  return null;
};
