import { IPortabilityCMS } from '@vfit/consumer/data-access';

const organizePortabilityCard = (portabilityCardCms: IPortabilityCMS) => ({
  title: portabilityCardCms?.portability?.general?.title || '',
  description: portabilityCardCms?.portability?.general?.description || '',
  items: [
    {
      title: portabilityCardCms?.portability?.general?.choicePortability?.option1?.title || 'Sì',
      description:
        portabilityCardCms?.portability?.general?.choicePortability?.option1?.description ||
        'Ci occuperemo noi di disattivare la linea del tuo vecchio operatore e attivare la nuova.',
      value: 'yes',
    },
    {
      title: portabilityCardCms?.portability?.general?.choicePortability?.option2?.title || 'No',
      description:
        portabilityCardCms?.portability?.general?.choicePortability?.option1?.description ||
        'Ricordati che dovrai provvedere tu alla disattivazione della linea del tuo vecchio operatore.',
      value: 'no',
    },
  ],
});

export { organizePortabilityCard };
