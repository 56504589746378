import {
  checkIsGoBackSIA,
  errorMock,
  IShoppingCartResponse,
  // useGetShoppingCartSilentUpdate,
  useModifyMobileSimDeviceService,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import {
  ECodeIdentification,
  IIdentificationDetails,
  // IUpdateProductPriceType,
} from '@vfit/shared/models';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { useDeliveryBaseFlow } from '../UseDeliveryBaseFlow/useDeliveryBaseFlow';
// import { updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';

export const useCustomerDeliveryBase = () => {
  const {
    product,
    isLoadingCart,
    customerId,
    isStartRecognition,
    isStartDeliveryBase,
    simTypeSelectedOption,
    customerDeliveryBaseInfo,
    setIsStartDeliveryBase,
    // setProduct,
    setCustomerDeliveryBaseInfo,
  } = useCheckout();
  const queryClient = useQueryClient();
  const [isStartDelivery, setIsStartDelivery] = useState(false);
  const [simTypeSelectedOptionPosted, setSimTypeSelectedOptionPosted] = useState<string>('');
  const {
    isSuccess: isDeliverySuccess,
    isLoading: isDeliveryLoading,
    isError: isDeliveryError,
    errorMessage: deliveryErrorMessage,
    error: deliveryError,
    deliveryOptions,
    identificationDetails,
  } = useDeliveryBaseFlow({ enabled: isStartDelivery || isStartDeliveryBase });
  const {
    error: modifySimError,
    mutate: mutateModifySim,
    isLoading: isLoadingModifySim,
    isSuccess: isSuccessModifySim,
    isError: isErrorModifySim,
  } = useModifyMobileSimDeviceService();
  // const { isSuccess: isSuccessShoppingCart, refetch: refetchShoppingCart } =
  //   useGetShoppingCartSilentUpdate(undefined, { enabled: false });

  /**
   * Save delivery, first get. Enable VR only in ABtest
   */
  const saveDeliveryState = () => {
    let identificationDetailsFiltered: IIdentificationDetails | IIdentificationDetails[] = [];
    const IS_ENABLED_VR = product?.enableVR;
    if (IS_ENABLED_VR) {
      identificationDetailsFiltered = identificationDetails;
    } else if (Array.isArray(identificationDetails)) {
      identificationDetailsFiltered = identificationDetails?.filter(
        (el) => el?.code !== ECodeIdentification.VR
      );
    } else if ((identificationDetails as IIdentificationDetails)?.code === ECodeIdentification.VR) {
      identificationDetailsFiltered = [];
    }
    // TODO TMP REMOVE FOR CHECK RESPONSE FROM AMDOCS
    // if (isDeliverySuccess) {
    //   refetchShoppingCart();
    // }
    setCustomerDeliveryBaseInfo({
      ...customerDeliveryBaseInfo,
      deliveryBase: {
        ...customerDeliveryBaseInfo.deliveryBase,
        isLoading: isDeliveryLoading,
        isSuccess: isDeliverySuccess,
        isError: isDeliveryError,
        errorMessage: deliveryErrorMessage,
        error: deliveryError,
        deliveryOptions,
        identificationDetails: identificationDetailsFiltered,
      },
    });
  };

  const successModifySim = (isWithoutModifySim = false) => {
    setSimTypeSelectedOptionPosted(simTypeSelectedOption?.id || '');
    setCustomerDeliveryBaseInfo({
      ...customerDeliveryBaseInfo,
      deliveryBase: {
        ...customerDeliveryBaseInfo.deliveryBase,
        isSuccess: isWithoutModifySim && customerDeliveryBaseInfo?.deliveryBase?.isSuccess,
        isError: false,
        error: undefined,
        // this loading is true because after modify in called delivery base
        // and this loading is for remove lag for cards
        isLoading: true,
      },
      modifySimDevice: {
        error: undefined,
        isLoading: false,
        isError: false,
        isSuccess: true,
      },
    });
  };

  const updateSimTypeSelectionDefault = () => {
    const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
    const simProductCharacteristics =
      shoppingCart?.cartItem?.[0]?.sim?.mobileSIMProduct?.[0].productCharacteristic || [];
    const simSKU = simProductCharacteristics.find(
      (el: any) => el?.displayName?.toLowerCase() === 'sku'
    );
    if (simSKU?.value) setSimTypeSelectedOptionPosted(simSKU.value);
  };

  // useEffect(() => {
  //   if (isSuccessShoppingCart) {
  //     setProduct({
  //       ...product,
  //       ...updateProductPrice(product, IUpdateProductPriceType.EDIT),
  //     });
  //   }
  // }, [isSuccessShoppingCart]);

  useEffect(() => {
    if (isStartDeliveryBase) setIsStartDeliveryBase(false);
  }, [isStartDeliveryBase]);

  useEffect(() => {
    if (isStartDelivery) setIsStartDelivery(false);
  }, [isStartDelivery]);

  useEffect(() => {
    saveDeliveryState();
  }, [
    isDeliveryError,
    isDeliverySuccess,
    isDeliveryLoading,
    deliveryOptions,
    identificationDetails,
  ]);

  useEffect(() => {
    if (customerId && customerDeliveryBaseInfo?.modifySimDevice?.isSuccess)
      setIsStartDelivery(true);
  }, [customerId, customerDeliveryBaseInfo]);

  useEffect(() => {
    if (isLoadingModifySim) {
      setCustomerDeliveryBaseInfo({
        ...customerDeliveryBaseInfo,
        modifySimDevice: {
          error: undefined,
          isError: false,
          isSuccess: false,
          isLoading: true,
        },
      });
    } else if (isErrorModifySim) {
      setCustomerDeliveryBaseInfo({
        ...customerDeliveryBaseInfo,
        modifySimDevice: {
          error: errorMock('modifySim', modifySimError),
          isLoading: false,
          isError: true,
          isSuccess: false,
        },
      });
    } else if (isSuccessModifySim) {
      resetData(queryClient, ['deliveryDetailsIsBaseMobile']);
      successModifySim();
    }
  }, [isLoadingModifySim, isSuccessModifySim, isErrorModifySim]);

  useEffect(() => {
    if (isStartRecognition && simTypeSelectedOption) {
      if (simTypeSelectedOptionPosted !== simTypeSelectedOption.id)
        mutateModifySim({ simOption: simTypeSelectedOption });
      else {
        successModifySim(true);
      }
    }
  }, [isStartRecognition]);

  useEffect(() => {
    if (!isLoadingCart && !checkIsGoBackSIA()) {
      updateSimTypeSelectionDefault();
    }
  }, [isLoadingCart]);

  return null;
};
