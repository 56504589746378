import { ICommonData } from '@vfit/shared/models';
import { useEffect } from 'react';
import { useCheckout } from '../../../iBuyFixed.context';
import ChooseInvoice from './components/ChooseInvoice/chooseInvoice';
import LoadingCard from '../LoadingCard/loadingCard';
import * as S from './invoiceCard.style'

const InvoiceCard = (props: ICommonData) => {
  const { customerFlow, setIsDisabledBackButton } = useCheckout();

  useEffect(() => {
    setIsDisabledBackButton(!customerFlow?.billingAccount?.isSuccess);
  }, [customerFlow.billingAccount]);

  if (!customerFlow?.billingAccount?.isSuccess) {
    return <LoadingCard />;
  }

  return (
    <S.Invoice>
      <ChooseInvoice {...props} />
    </S.Invoice>
  );
};

export default InvoiceCard;
