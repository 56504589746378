import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 0 auto;
  gap: 16px;
  width: 287px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 366px;
    gap: 12px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const Title = styled.div`
  padding: 2rem 0 1rem;
  font-family: ${fonts.exbold};
  font-weight: 400;
  font-style: normal;
  text-align: center;
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    padding: 3.75rem 0 1rem;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled.div`
  div {
    margin: 0 auto;
    font-family: ${fonts.regular};
    font-weight: 400;
    font-style: normal;
    text-align: center;
    ${pxToCssFont(20, 26)}
    margin-bottom: 32px;

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(20, 30)}
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: 40px;
    }

    p {
      margin-block: 0;
    }
  }
`;

export const TextLoader = styled.div`
  width: inherit;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 58px;
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const BottomLable = styled.div`
  margin-top: 20px;
  display: flex;
  text-align: left;
`;

export const BottomText = styled.div`
  p{
    margin: 0 auto ;
    font-family: ${fonts.regular};
    font-weight: 400;
    font-style: normal;
    text-align: left;
    ${pxToCssFont(14, 18)}
  }
`;

