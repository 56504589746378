import { EditAddress, EditAddressOptions, StoreList } from '@vfit/consumer/components';
import { VfModalStepper } from '@vfit/shared/components';
import { trackLink } from '@vfit/shared/data-access';
import { IAddressType, IOptionalText, IValidateAddressRequest } from '@vfit/shared/models';
import { useEffect, useMemo, useState } from 'react';
import { useSearchPickUpPoints } from '@vfit/consumer/hooks';
import { useQueryClient } from 'react-query';
import { resetData } from '@vfit/shared/data-access';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { IMode } from '../EditAddress/editAddress.models';
import { IInputs } from '../CoverageTool/coverageTool.models';
import { Wrapper, ContentSpinner } from './stepperAddressModal.style';
import {
  ILocateStoreStepper,
  IStepperModalModel,
  PickupLocationsStepper,
} from './stepperAddressModal.model';

const StepperAddressModal = ({
  address,
  shippingAddress,
  setShippingAddress,
  postalOffices,
  pickupPoints,
  vodafoneStores,
  onChangePickupPoint,
  isOpen,
  handleClose,
  deliveryOptions,
  userData,
  setInvoice,
  setPickupPoint,
  setBillingAddress,
  billingAddress,
  multipleOptions,
  editAddressStepperModalConfig,
  mapConfig,
  mapConfigEdit,
  editAddressOptionsConfig,
  isLoading: isLoadingStepperModal,
  manualAddressConfig,
}: IStepperModalModel) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState(0);
  const [inputPositionData, setInputPositionData] = useState<IValidateAddressRequest>();
  const [selectedElem, setSelectedElem] = useState<IOptionalText>();
  const [options, setOptions] = useState<IOptionalText[]>([]);
  const [height, setHeight] = useState<{ tablet: string; desktop: string }>({
    tablet: '',
    desktop: '',
  });

  const {
    isLoading,
    pickupPoints: newPickupPoints,
    isSuccess: newPickupPointsSearchIsSuccess,
    startingSearchAddress,
    setStartingSearchAddress,
  } = useSearchPickUpPoints(address, inputPositionData, step, selectedElem?.value as IAddressType);

  const deliveryCenterData = useMemo(() => {
    let pointsToReturn: PickupLocationsStepper[] | ILocateStoreStepper[] = postalOffices;
    if (selectedElem?.value === IAddressType.PP) pointsToReturn = pickupPoints;
    else if (selectedElem?.value === IAddressType.P) pointsToReturn = vodafoneStores;
    return (
      (newPickupPointsSearchIsSuccess &&
        newPickupPoints &&
        newPickupPoints.length > 0 &&
        newPickupPoints) ||
      pointsToReturn
    );
  }, [
    selectedElem?.value,
    newPickupPointsSearchIsSuccess,
    postalOffices,
    vodafoneStores,
    pickupPoints,
    newPickupPoints,
  ]);

  const onCloseModal = () => {
    setStep(0);
    if (address) setStartingSearchAddress(address);
    handleClose();
  };

  useEffect(() => {
    if (deliveryOptions) {
      setOptions(
        multipleOptions
          ?.filter((option) => deliveryOptions?.map((opt) => opt.code).includes(option.type))
          ?.map((listElem) => ({
            title: listElem.title,
            description: listElem.text,
            value: listElem.type,
          })) || []
      );
    }
  }, [deliveryOptions]);

  useEffect(() => {
    if (address) {
      setStartingSearchAddress(address);
    }
  }, [address]);

  const onNext = () => {
    if (step === 2 && options.length > 1) {
      setStep(1);
      return;
    }
    if (
      (step === 1 && selectedElem?.value === IAddressType.S) ||
      (step === 0 && options.length === 1) ||
      (step === 0 && deliveryOptions?.length <= 1)
    ) {
      onCloseModal();
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  const onBack = () => {
    if (step === 1) {
      if (address) setStartingSearchAddress(address);
    }
    setStep((prevStep) => prevStep - 1);
  };

  const handlerSelectAddress = (data: IOptionalText) => {
    setSelectedElem(data);
    setTimeout(() => onNext(), 400);
  };

  const handlerShippingAddress = (deliveryCenter: PickupLocationsStepper) => {
    resetData(queryClient, [
      'deliveryAddress',
      'deliveryCreatedAddress',
      'deliveryDetailsIsEditAddress',
      'individual',
      'linkContactAndCustomer',
    ]);
    const deconstructedAddress: string[] =
      deliveryCenter.locationAddress.formattedAddress1.split(' ');
    let street = '';
    deconstructedAddress.forEach((e: string, i: number) => {
      street += i < deconstructedAddress.length - 1 ? `${e} ` : '';
    });
    setShippingAddress({
      ...shippingAddress,
      city: deliveryCenter.locationAddress.city || '',
      postalCode: deliveryCenter.locationAddress.postalCode || '',
      stateOrProvince: deliveryCenter.locationAddress.stateOrProvince || '',
      street: street || '',
      streetNumber: deconstructedAddress.pop() || '',
      postalOfficeDescription: deliveryCenter?.locationAddress?.postalOfficeDescription || '',
      typePickupPoint: selectedElem?.value || '',
    });
    onChangePickupPoint({ ...deliveryCenter, typePickupPoint: selectedElem?.value });
    onCloseModal();
  };

  const handleEditAddress = (inputs: IInputs) => {
    resetData(queryClient, ['pickupAddress', 'pickupCreatedAddress']);
    setInputPositionData({
      city: inputs?.city || '',
      displayStateOrProvince: inputs?.stateOrProvince,
      postalCode: inputs?.postalCode || '',
      stateOrProvince: inputs?.stateOrProvince || '',
      street: inputs?.street || '',
      streetNumber: inputs?.streetNumber || '',
      latitude: inputs?.latitude?.toString() || '',
      longitude: inputs?.longitude?.toString() || '',
    });
  };

  const isEditAddress = () =>
    (step === 0 &&
      !options?.find((e) =>
        [IAddressType.PO, IAddressType.PP].includes(e.value as IAddressType)
      )) ||
    (step === 1 && selectedElem?.value === IAddressType.S) ||
    step === 2;

  const isEditAddressOptions = () =>
    step === 0 &&
    options?.find((e) =>
      [IAddressType.PO, IAddressType.PP, IAddressType.P].includes(e.value as IAddressType)
    );

  const handlerFlow = () => {
    if (isEditAddress()) {
      return (
        <Wrapper>
          {isLoading && (
            <ContentSpinner>
              <LoadingSpinner />
            </ContentSpinner>
          )}
          {!isLoading && (
            <EditAddress
              firstName={userData?.firstName}
              lastName={userData?.lastName}
              handleClose={() => onNext()}
              origin
              mode={step === 2 ? IMode.Modify : IMode.Change}
              modeAction={handleEditAddress}
              lables={step === 2 ? mapConfigEdit : undefined}
              shippingAddress={shippingAddress}
              billingAddress={billingAddress}
              setBillingAddress={setBillingAddress}
              setShippingAddress={setShippingAddress}
              setInvoice={setInvoice}
              setPickupPoint={setPickupPoint}
              cmsConfig={editAddressStepperModalConfig}
              manualAddressConfig={manualAddressConfig}
            />
          )}
        </Wrapper>
      );
    }
    if (isEditAddressOptions()) {
      return (
        <Wrapper>
          <EditAddressOptions
            deliveryCenterData={deliveryCenterData}
            onSaveAddress={() => []}
            handlerSelectAddress={handlerSelectAddress}
            options={options}
            hiddenCta
            editAddressOptionsConfig={editAddressOptionsConfig}
          />
        </Wrapper>
      );
    }
    return (
      <StoreList
        storeListData={{
          deliveryCenterData,
          position: startingSearchAddress,
          addressType: selectedElem?.value as IAddressType,
          cardItem: {
            title:
              selectedElem?.value === IAddressType.PP
                ? mapConfig.titlePP
                : mapConfig.titlePO || 'Uffici Postali',
            subTitle:
              selectedElem?.value === IAddressType.PP
                ? mapConfig.subTitlePP
                : mapConfig.subTitlePO ||
                  'Ecco i negozi Vodafone vicini al tuo indirizzo di installazione',
            ctaAddress: {
              action: () => {
                trackLink(mapConfig.ctaModifyLable || 'Modifica indirizzo');
                onNext();
              },
              label: mapConfig.ctaModifyLable || 'Modifica indirizzo',
            },
            switchLabelDx: mapConfig.switchLabelDx || 'Lista',
            switchLabelSx: mapConfig.switchLabelSx || 'Mappa',
            ctaDeliveryPoint: {
              action: (deliveryCenter) => {
                trackLink(mapConfig.ctaSaveLable || '');
                handlerShippingAddress(deliveryCenter);
              },
              label: mapConfig.ctaSaveLable || 'Seleziona questo punto di ritiro',
            },
          },
          isLoading,
        }}
      />
    );
  };

  useEffect(() => {
    if (options?.length > 0) {
      if (isEditAddress()) {
        setHeight({ tablet: '608.5px', desktop: '633.5px' });
      }
      if (isEditAddressOptions()) {
        setHeight({ tablet: '', desktop: '' });
      }
    }
  }, [options]);

  return (
    <VfModalStepper
      id="edit-address-modal"
      showBackCta={step !== 0}
      isOpen={isOpen}
      onBack={onBack}
      handleClose={onCloseModal}
      height={height}
    >
      <>
        {isLoadingStepperModal && (
          <ContentSpinner>
            <LoadingSpinner />
          </ContentSpinner>
        )}
        {!isLoadingStepperModal && handlerFlow()}
      </>
    </VfModalStepper>
  );
};

export default StepperAddressModal;
