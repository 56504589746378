import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const TabsDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  overflow: auto;
  flex-wrap: nowrap;
  margin-top: 80px;
  width: fit-content;
  cursor: pointer;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const TabBox = styled.div<{ selected: boolean }>`
  font-family: ${(props) => (props.selected ? fonts.exbold : fonts.light)};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(28, 36)}
  border-bottom: ${(props) => (props.selected ? `2px solid ${colors.$e60000}` : '')};
  padding: 12px 24px !important;
  align-items: center;
  flex-shrink: 0;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const TabLine = styled.div`
  background: ${colors.$bebebe};
  height: 1px;
  margin-bottom: 25px;
`;

