import { WarningInfo } from '@vfit/shared/components';
import { IWarningInfo } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { getUserInfo, getTagging } from '../../../../checkout.utils';

const UpdateCartCard = (props: IWarningInfo) => {
  const taggingData = getTagging('loading offer');

  useTracking({
    event: ['view'],
    event_label: 'loading offer',
    opts: taggingData?.opts,
    cartProduct: taggingData?.trackingProduct,
    pageProduct: taggingData?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  return <WarningInfo {...props} />;
};

export default UpdateCartCard;
