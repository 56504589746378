import { useEffect, useState } from 'react';
import { ButtonSlide } from '@vfit/shared/atoms';
import { Form } from '@vfit/shared/components';
import { colors } from '@vfit/shared/themes';
import { useForm } from 'react-hook-form';
import { API, PAGES, trackLink } from '@vfit/shared/data-access';
import { CMS_CONFIG, useCmsConfig, IPersonalInfoCMS, checkIsApp } from '@vfit/consumer/data-access';
import { yupResolver } from '@hookform/resolvers/yup';
import { IEditObject, IEditProps } from './editField.models';
import { IEditSchema } from './editField.validation';
import { ButtonDiv, Container, Div, Subtitle, Title } from './editField.style';
import { organizeEditField } from './editField.utils';

const EditField = ({ onSubmit, isPhone, isLoading }: IEditProps) => {
  const {
    register,
    getValues,
    formState: { errors },
  } = useForm<IEditObject>({
    resolver: yupResolver(IEditSchema),
    mode: 'onChange',
  });
  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const {
    buttonLableEmail,
    lableEmail,
    buttonLableNumber,
    subtitleEmail,
    titleEmail,
    subtitleNumber,
    titleNumber,
    lableNumber,
  } = organizeEditField(dataFromCms);
  const [disabled, setDisabled] = useState<boolean>(true);

  const onFormSubmit = () => {
    trackLink(isPhone ? buttonLableNumber : buttonLableEmail);
    const data = getValues();
    onSubmit(data);
  };

  const changeDisabled = () => {
    if (
      errors.email !== undefined ||
      errors.phoneNumber !== undefined ||
      getValues().email === '' ||
      getValues().phoneNumber === ''
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    changeDisabled();
  }, [errors.email, errors.phoneNumber, getValues]);

  return (
    <Container>
      <Title>{isPhone ? titleNumber : titleEmail}</Title>
      <Subtitle>{isPhone ? subtitleNumber : subtitleEmail}</Subtitle>
      <Div>
        <div className="text-input">
          <Form.TextInput
            label={isPhone ? lableNumber : lableEmail}
            error={isPhone ? errors.phoneNumber?.message : errors.email?.message}
            placeholder=" "
            {...register(isPhone ? 'phoneNumber' : 'email', { onChange: changeDisabled })}
          />
        </div>

        <ButtonDiv>
          <ButtonSlide
            label={isPhone ? buttonLableNumber : buttonLableEmail}
            onClick={onFormSubmit}
            disabled={disabled || isLoading}
            borderColor={colors.$0d0d0d}
            isApp={checkIsApp()}
            hoverColor={colors.$262626}
            hoverTextColor={colors.$ffffff}
            clickColor={colors.$7e7e7e}
            clickTextColor={colors.$ffffff}
            name="action_editField"
          />
        </ButtonDiv>
      </Div>
    </Container>
  );
};

export default EditField;
