import { IPayments } from '@vfit/consumer/data-access';

const organizeCheckBoxPayment = (paymentCMS: IPayments, availableNewPayMeans: string[]) => {
  const checkIfPayPal = (el: string): string => {
    if (el === 'payPal') return el.toLowerCase();
    return el;
  };
  return {
    title: paymentCMS?.paymentsmobile?.title || '',
    description: paymentCMS?.paymentsmobile?.description || '',
    items:
      availableNewPayMeans
        ?.filter((el) => !!paymentCMS?.paymentsmobile?.choice?.[checkIfPayPal(el)])
        ?.map((el) => ({
          title: paymentCMS?.paymentsmobile?.choice?.[checkIfPayPal(el)]?.title || '',
          description: paymentCMS?.paymentsmobile?.choice?.[checkIfPayPal(el)]?.description || '',
          value: checkIfPayPal(el),
        })) || [],
    modal: {
      textLink: paymentCMS?.paymentsmobile?.modal?.linkLabel || '',
      titleModal: paymentCMS?.paymentsmobile?.modal?.title || '',
      descriptionModal: paymentCMS?.paymentsmobile?.modal?.description || '',
    },
  };
};

const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message ||
    'Siamo spiacenti ma si è verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || 'Ops!',
});

export { organizePaymentMethodError, organizeCheckBoxPayment };
