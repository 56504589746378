import styled from 'styled-components';

export const WidgetContainer = styled.div<{ isLastWidget: boolean }>`
  margin-bottom: ${({ isLastWidget }) => (isLastWidget ? '109px' : 0)};
`;

export const FormContainer = styled.div<{ isLastWidget: boolean }>`
  padding-bottom: ${({ isLastWidget }) => (isLastWidget ? '50px' : 0)};
`;

export const WidgetDivider = styled.div`
  margin-top: 64px;
`;
