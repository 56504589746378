import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

/* stylelint-disable value-no-vendor-prefix */
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  height: 100%;
  justify-content: space-between;
  font-weight: 400;
  font-family: ${fonts.exbold};
  color: ${colors.$262626};
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 12px 3px 0 0;
  }
`;

export const Title = styled.div`
  ${pxToCssFont(30, 38)}
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    text-align: center;
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    text-align: left;
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Description = styled.div`
  margin: 0 auto;
  font-family: ${fonts.regular};
  ${pxToCssFont(18, 24)}
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    text-align: center;
  }

  @media (min-width: ${breakpoints.desktop}) {
    text-align: left;
  }
`;

export const ButtonSlideContainer = styled.div<{ isSticky?: boolean }>`
  // position: ${({ isSticky }) => (isSticky ? 'sticky' : 'static')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  background: ${colors.$ffffff};

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: ${({ isSticky }) => (isSticky ? '20px' : '40px')};
    flex-direction: row;
    gap: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: ${({ isSticky }) => (isSticky ? '24px' : '48px')};
    gap: 32px;
  }

  div {
    width: 100%;
    justify-content: center;
    display: flex;

    button {
      max-width: 296px;
    }
  }
`;

export const TextContainer = styled.div<{ isSticky?: boolean }>`
  overflow: ${({ isSticky }) => (isSticky ? 'auto' : 'unset')};
`;

