import type { InferGetStaticPropsType, GetStaticProps, GetStaticPaths } from 'next';

import { useEffect, useState } from 'react';
import { LoggerInstance, checkWindow } from '@vfit/shared/data-access';
import { dehydrate } from 'react-query';
import { Layout } from '@vfit/consumer/components';
import {
  BUSINESS_CMS_DEFAULT,
  getConfiguration,
  getStaticPathPagesDxl,
  IStaticPathPagesPaths,
} from '@vfit/business-data-access';
import { IPage } from '@vfit/consumer/data-access';
import { LayoutBusiness } from '@vfit/business-components';

import {
  prefetchAllQueries,
  prefetchStaticPathsConsumer,
  prefetchDataConsumer,
  prefetchPagesConsumer,
} from '@vfit/cms';

export default function PageBuilder(props: InferGetStaticPropsType<typeof getStaticProps>) {
  const { page } = props;
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = new URLSearchParams(querystringParams);
  const isApp = urlParams.get('app');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  if ((page as IStaticPathPagesPaths)?.params?.isVBIPages)
    return <LayoutBusiness page={page as IStaticPathPagesPaths} isApp={!!isApp} />;

  return !loading ? <Layout isApp={!!isApp} /> : <div />;
}

export const getStaticPaths = (async () => {
  const allPages = await prefetchStaticPathsConsumer();
  const allPagesDxl = await getStaticPathPagesDxl();
  const paths = [...allPages, ...allPagesDxl] as {
    params: {
      path: string[];
    };
  }[];

  return {
    paths,
    fallback: false,
  };
}) satisfies GetStaticPaths;

export const getStaticProps = (async (context) => {
  const contextPath = (context.params?.path as [])?.join('/') || '';
  const allPages = await prefetchPagesConsumer();
  // Find current page
  let page: IPage | IStaticPathPagesPaths = null;
  Object.keys(allPages).forEach((pK) => {
    const lPage = allPages[pK];
    if (lPage.params?.path?.join('/') === contextPath) page = lPage;
  });
  let queryClient;

  if (page) {
    // prefetch data for page
    queryClient = await prefetchDataConsumer(page as IPage, BUSINESS_CMS_DEFAULT);
  } else {
    const allPagesDxl = await getStaticPathPagesDxl();
    page = allPagesDxl.find((p) => p.params?.path?.join('/') == contextPath) ?? null;
    if (!page) {
      return {
        props: {
          page: null,
        },
      };
    }
    const config = getConfiguration(page?.params?.layout, page?.params?.pageType);
    queryClient = await prefetchAllQueries(config);
  }

  return {
    props: {
      page: page || null,
      dehydratedState: dehydrate(queryClient),
    },
  };
}) satisfies GetStaticProps;

