import { useEffect, useState } from 'react';
import {
  CUSTOMER_TYPES,
  errorMock,
  useCoexistenceUser,
  useGetCustomer,
  useLoggedUser,
  useSilentLogin,
} from '@vfit/consumer/data-access';
import { ITrackError, LoggerInstance } from '@vfit/shared/data-access';

interface ILoggedFlow {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  errorSilentLogin?: ITrackError;
}

export const useLoggedCustomerFlow = (): ILoggedFlow => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorSilentLogin, setErrorSilentLogin] = useState<ITrackError>(undefined);
  const [customerId, setCustomerId] = useState('');

  /**
   * Instantiation of .mutate() objects
   */
  const {
    data: silentLogin,
    mutate: silentLoginMutate,
    isSuccess: isSuccessSilentLogin,
    isLoading: isLoadingSilentLogin,
    isError: isErrorSilentLogin,
    error: silentLoginError,
  } = useSilentLogin();
  const silentLoginIsSuccess = isSuccessSilentLogin && silentLogin;
  const {
    data: silentLoginR2,
    mutate: silentLoginR2Mutate,
    isSuccess: isSuccessSilentLoginR2,
    isLoading: isLoadingSilentLoginR2,
  } = useSilentLogin();
  const silentLoginR2IsSuccess = isSuccessSilentLoginR2 && silentLoginR2;

  /**
   * Enabling API calls only if the addressObject retrieved from google autocomplete
   * is fully filled
   */
  const {
    data: coexistenceData,
    isSuccess: coexistenceIsSuccess,
    isError: coexistenceIsError,
  } = useCoexistenceUser();
  const coexistenceSuccess = coexistenceIsSuccess && coexistenceData;

  const {
    data: userData,
    isError: isErrorUser,
    isSuccess: isSuccessUser,
    refetch: refetchUser,
  } = useLoggedUser({ enabled: false });
  const {
    data: customerData,
    isSuccess: customerIsSuccess,
    isError: customerIsError,
  } = useGetCustomer(customerId, { enabled: !!customerId });

  /**
   * useEffect to trigger silentLogin mutation the first time
   */
  useEffect(() => {
    if (!isLoading && !silentLogin && !isLoadingSilentLogin && !isSuccessSilentLogin) {
      setIsLoading(true);
      silentLoginMutate(true);
    }
  }, []);

  /**
   * useEffect to trigger coexistence
   * or set on localstorage R2_logged
   */
  useEffect(() => {
    if (isSuccessSilentLogin && silentLogin) {
      LoggerInstance.debug('TEST LOGGED - silentLogin : ', silentLogin);
      if (silentLogin?.flowId === 'newAnonymous') {
        LoggerInstance.debug('TEST LOGGED - silentLogin : newAnonymous');
        setIsSuccess(true);
      }
      if (silentLogin?.flowId === 'existingAnonymous') {
        LoggerInstance.debug('TEST LOGGED - silentLogin : existingAnonymous');
        setIsSuccess(true);
      }
      if (silentLogin?.flowId === 'existingEnriched') {
        LoggerInstance.debug('TEST LOGGED - silentLogin : existingEnriched');
        setIsSuccess(true);
      }
      if (silentLogin?.flowId === 'existingLogin' || silentLogin?.flowId === 'newLogin') {
        LoggerInstance.debug('TEST LOGGED - silentLogin : existingLogin');
        refetchUser();
      }
    }
    if (
      coexistenceIsSuccess &&
      !!coexistenceData &&
      coexistenceData?.userType === CUSTOMER_TYPES.LEGACY
    ) {
      if (!silentLoginR2 && !isLoadingSilentLoginR2 && !isSuccessSilentLoginR2) {
        silentLoginR2Mutate(false);
      }
    }
  }, [silentLoginIsSuccess, coexistenceSuccess]);

  useEffect(() => {
    if (isSuccessSilentLoginR2 && silentLoginR2) {
      LoggerInstance.debug('TEST LOGGED - silentLogin R2: ', silentLoginR2);
      if (silentLoginR2?.flowId === 'newAnonymous') {
        LoggerInstance.debug('TEST LOGGED - silentLogin R2 : newAnonymous');
        setIsSuccess(true);
      }
      if (silentLoginR2?.flowId === 'existingAnonymous') {
        LoggerInstance.debug('TEST LOGGED - silentLogin R2: existingAnonymous');
        setIsSuccess(true);
      }
      if (silentLoginR2?.flowId === 'existingLogin') {
        LoggerInstance.debug('TEST LOGGED - silentLogin R2: existingLogin');
      }
      if (silentLoginR2?.flowId === 'newLogin') {
        // FIRING SILENT LOGIN R2 TWICE
        LoggerInstance.debug('TEST LOGGED - silentLogin R2: newLogin');
        silentLoginR2Mutate(false);
      }
    }
  }, [silentLoginR2IsSuccess]);

  useEffect(() => {
    if (isSuccessUser && userData?.customer?.[0]?.id) {
      setCustomerId(userData?.customer?.[0]?.id);
    }
  }, [isSuccessUser]);

  /**
   * useEffect to manage the loader on retrieved costumer data
   */
  useEffect(() => {
    if (customerIsSuccess) {
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [customerIsSuccess]);

  useEffect(() => {
    if (isErrorSilentLogin) {
      setIsLoading(false);
      setIsError(true);
      setErrorSilentLogin(errorMock(`silentLogin`, silentLoginError));
    }
    if (isErrorUser) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('ERROR - user');
    }
    if (customerIsError) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage('ERROR - customerData');
    }
    if (coexistenceIsError) {
      LoggerInstance.error('coexistance ERROR to be refactored');
    }
  }, [
    customerIsError,
    isErrorUser,
    isErrorSilentLogin,
    !!customerData,
    !!userData,
    !!silentLogin,
    !!coexistenceIsError,
  ]);

  return { isSuccess, isLoading, isError, errorMessage, errorSilentLogin };
};
