import { IVoucherSpecs } from '@vfit/consumer/data-access';
import { purify } from '@vfit/shared/themes';

export const organizeVoucherInsertion = (cmsData: IVoucherSpecs) => {
  const purifiedLabel = {
    purifiedPriceDetail: purify([cmsData?.priceDetails || ''])?.[0],
    purifiedDescription: purify([cmsData?.productDescription || ''])?.[0],
    purifiedProductName: purify([cmsData?.productName?.toUpperCase() || ''])?.[0],
  };
  return purifiedLabel;
};

