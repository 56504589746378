import { useMutation, useQueryClient } from 'react-query';
import {
  CustomOptions,
  handleUseQuery,
  INextError,
  LoggerInstance,
  nextClient,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import {
  errorMock,
  getQueryStringParams,
  GetCustomerResponse,
  IShoppingCartResponse,
  IPostDeliveryPayload,
  IReplaceBillingAddressOrderPayload,
  IMutationPayload,
  isMobileProduct,
  IFindCallerResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IAddressDataObject,
  IIdentificationDetails,
  ILocateStoreResponse,
  IPickUpLocation,
} from '@vfit/shared/models';
import { IParameters, KEY } from './deliveryDetails.models';

/**
 * A getter method to retrieve the delivery code based on the deliveryItems
 * @param deliveryItems
 * @returns
 */
const deliveryCode = (deliveryItems: any): string => {
  if (deliveryItems?.length > 0) {
    const filteredItem = deliveryItems?.find((itemToFilter) => itemToFilter?.isSelected);
    return filteredItem?.code || 'S';
  }
  return isMobileProduct() ? 'S' : 'T';
};

const getDeliveryDetailsServiceV1 = (
  cartId: string,
  parameters: IParameters,
  customOptions?: CustomOptions
) =>
  nextClient.get(`${API.ORDER}/${cartId}${API.DELIVERY_DETAILS}`, {
    searchParams: parameters,
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

export const getDeliveryDetailsServiceV2 = (
  cartId: string,
  parameters: IParameters,
  customOptions?: CustomOptions
) =>
  nextClient.get(`${API.ORDER_V2}/${cartId}${API.DELIVERY_DETAILS}`, {
    searchParams: parameters,
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * POST method to call the deliveryDetails api
 * @param cartId
 * @param payload
 * @returns
 */
const postDeliveryDetailsServiceV1 = (cartId: string, payload: any) =>
  nextClient.post(`${API.ORDER}/${cartId}${API.DELIVERY_DETAILS}`, payload);

/**
 * POST method to call the deliveryDetails api
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
const postDeliveryDetailsServiceV2 = (
  cartId: string,
  payload: any,
  customOptions?: CustomOptions
) =>
  nextClient.post(`${API.ORDER_V2}/${cartId}${API.DELIVERY_DETAILS}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * POST method to call edit bilingAddress
 * @param billingAccountId
 * @param payload
 * @returns
 */
const editBillingAddressV1 = (billingAccountId: string, payload: any) =>
  nextClient.post(
    `${API.REPLACE_BILLING_ADDRESS}/${billingAccountId}/replaceBillingAddress`,
    payload
  );

/**
 * This is a custome useQuery hook to handle all the delivery Get types.
 * @param key
 * @param enabler
 * @param pickupPoint
 * @param customOptions
 * @returns
 */
export const useDeliveryServiceDetails = (
  key: KEY,
  enabler: boolean,
  pickupPoint?: any,
  customOptions?: CustomOptions
) => {
  const queryClient = useQueryClient();
  const { cartId, parameters, queryEnabler } = getQueryStringParams(queryClient, key, pickupPoint);

  LoggerInstance.debug('KEY', key, 'cartid', cartId, 'param', parameters, 'enabler', queryEnabler);

  const keysDependency =
    customOptions && customOptions.keyDependency
      ? customOptions.keyDependency
      : [`deliveryDetails${key}`];

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      if (!isMobileProduct())
        return getDeliveryDetailsServiceV2(cartId as string, parameters, customOptions);
      return getDeliveryDetailsServiceV1(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: queryEnabler && enabler,
      onSuccess: (data) => {
        if (key === KEY.isEditAddress) {
          localStorage.setItem(
            KEY.ShippingMethodCode,
            data?.deliveryDetails?.[0]?.shippingOptions?.[0].name || 'ST'
          );
        }
        // Save deliveryDetails response object into local storage.
        LoggerInstance.debug('delivery success', data);
        localStorage.setItem(`deliveryDetails${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - deliveryDetails${key}: `, error);
        localStorage.setItem(
          `deliveryDetails${key}`,
          JSON.stringify(errorMock(`deliveryDetails${key}`, error))
        );
      },
    },
  };

  return handleUseQuery(options);
};

/**
 * This is a custom Mutation hook to handle the delivery POST types.
 * @param pickupPoint
 * @returns
 */
export const usePostDeliveryServiceDetails = (
  pickupPoint?: IPickUpLocation | ILocateStoreResponse
) => {
  const queryClient = useQueryClient();
  return useMutation(
    ['postDeliveryServiceDetailsMutation'],
    (mutationPayload: IMutationPayload) => {
      const { shippingAddress, identificationMethod: identificationPostMethod } =
        mutationPayload || {};
      const { cartId, parameters } = getQueryStringParams(
        queryClient,
        KEY.isPost,
        pickupPoint as IPickUpLocation
      );
      LoggerInstance.debug('KEY', KEY.isPost, 'cartid', cartId, 'param', parameters, 'enabler');
      const payload = [];

      if (parameters) {
        const getFixedDeliveryItems = (dItems) => {
          const items = [];
          dItems?.forEach((el) => {
            if (el?.items?.length > 0) {
              el?.items?.forEach((item) => {
                if (item) items.push(item);
              });
            }
          });
          return items;
        };

        const shippingMethodEditAddress = localStorage.getItem(KEY.ShippingMethodCode);
        localStorage.removeItem(KEY.ShippingMethodCode);

        const getItemPayload = (param) => {
          const {
            deliveryMethod,
            identificationMethod,
            recipientAddress,
            deliveryItems,
            recipientDetails,
          } = param;

          const shippingMethodCode =
            shippingMethodEditAddress ||
            deliveryItems?.[0]?.details?.[0]?.shippingOptions?.[0]?.name ||
            'ST';

          const pickUpDetails = pickupPoint?.storeId
            ? {
                id: pickupPoint.storeId,
                addressId:
                  (pickupPoint as IPickUpLocation)?.locationAddress?.id ||
                  (pickupPoint as ILocateStoreResponse)?.storeAddress?.id,
              }
            : {};

          let indetificationMethodCode = identificationMethod;
          if (identificationPostMethod) {
            indetificationMethodCode =
              (identificationPostMethod as IIdentificationDetails[])?.find((iD) => iD.isSelected)
                ?.code || 'CO';
          }
          return {
            deliveryMethodCode: deliveryMethod,
            shippingMethodCode,
            indetificationMethodCode,
            recipientDetails: {
              recipientAddress: {
                id: recipientAddress?.id,
                ...(pickupPoint?.storeId && recipientAddress),
              },
              contactPhoneNumber: recipientDetails?.contactPhoneNumber,
              contactEmailAddress: recipientDetails?.contactEmailAddress,
              recipientName: {
                formattedName: recipientDetails?.recipientName?.formattedName,
              },
              notes: shippingAddress?.note,
            },
            pickUpDetails,
            deliveryItems: getFixedDeliveryItems(deliveryItems),
          };
        };

        if (Array.isArray(parameters)) {
          parameters.forEach((param) => {
            payload.push({ ...getItemPayload(param) });
          });
        } else {
          payload.push({ ...getItemPayload(parameters) });
        }
      }

      return postDeliveryDetailsServiceV2(cartId as string, payload);
    },
    {
      onSuccess: (data: any) => {
        const parsedData = data ? JSON.stringify(data) : '';
        if (data?.recipientDetails?.notes) {
          localStorage.setItem('deliveryNote', data?.recipientDetails?.notes || '');
        }
        localStorage.setItem('postDeliveryServiceDetails', parsedData);
        queryClient.setQueryData('postDeliveryServiceDetails', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - postDeliveryServiceDetails: ', error);
        queryClient.setQueryData(
          'postDeliveryServiceDetails',
          errorMock('postDeliveryServiceDetails', error)
        );
        localStorage.setItem(
          'postDeliveryServiceDetails',
          JSON.stringify(errorMock('postDeliveryServiceDetails', error))
        );
      },
    }
  );
};

/**
 * Delivery Base V1 hook
 * @param customOptions
 * @returns
 */
export const useDeliveryBaseV1 = (customOptions?: CustomOptions) => {
  const key = customOptions?.keyDependency
    ? customOptions.keyDependency?.[0]
    : 'deliveryDetailsIsBase';
  const keysDependency = [key];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id || findCaller?.customerRef?.id;
  const queryEnabler = !!cartId && !!customerId;
  const enabled: boolean =
    customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration',
        customerId,
        filter: 'All',
        lob: 'WS',
      };
      return getDeliveryDetailsServiceV1(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data) => {
        LoggerInstance.debug('delivery success', data);
        localStorage.setItem(`${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${key}: `, error);
        localStorage.setItem(`${key}`, JSON.stringify(errorMock(`${key}`, error)));
      },
    },
  };

  return handleUseQuery(options);
};

/**
 * Delivery Base V2 hook
 * @param customOptions
 * @returns
 */
export const useDeliveryBaseV2 = (customOptions?: CustomOptions) => {
  const key = customOptions?.keyDependency
    ? customOptions.keyDependency?.[0]
    : 'deliveryDetailsIsBase';
  const keysDependency = [key];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id || findCaller?.customerRef?.id;
  const queryEnabler = !!cartId && !!customerId;
  const enabled: boolean =
    customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration',
        customerId,
        filter: 'All',
        lob: 'WL',
        identificationMethod: 'NA',
      };
      return getDeliveryDetailsServiceV2(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data) => {
        LoggerInstance.debug('delivery success', data);
        localStorage.setItem(`${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${key}: `, error);
        localStorage.setItem(`${key}`, JSON.stringify(errorMock(`${key}`, error)));
      },
    },
  };

  return handleUseQuery(options);
};

/**
 * Delivery Advance V1 hook
 * @param identificationMethod
 * @param customOptions
 * @returns
 */
export const useDeliveryAdvanceV1 = (
  identificationMethod: string,
  customOptions?: CustomOptions
) => {
  const key = customOptions?.keyDependency
    ? customOptions.keyDependency?.[0]
    : 'deliveryDetailsIsAdvance';
  const keysDependency = [key];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id || findCaller?.customerRef?.id;
  const queryEnabler = !!cartId && !!customerId;
  const enabled: boolean =
    customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration',
        identificationMethod,
        customerId,
        filter: 'All',
        lob: 'WS',
      };
      return getDeliveryDetailsServiceV1(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data) => {
        LoggerInstance.debug('delivery success', data);
        localStorage.setItem(`${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${key}: `, error);
        localStorage.setItem(`${key}`, JSON.stringify(errorMock(`${key}`, error)));
      },
    },
  };
  return handleUseQuery(options);
};

/**
 * Delivery Advance V2 hook
 * @param identificationMethod
 * @param addressId
 * @param deliveryMethod
 * @param customOptions
 * @returns
 */
export const useDeliveryAdvanceV2 = (
  identificationMethod: string,
  addressId: string,
  deliveryMethod: string,
  customOptions?: CustomOptions
) => {
  const key = customOptions?.keyDependency
    ? customOptions.keyDependency?.[0]
    : 'deliveryDetailsIsAdvance';
  const keysDependency = [key];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id || findCaller?.customerRef?.id;
  const queryEnabler = !!cartId && !!customerId;
  const enabled: boolean =
    customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const parameters = {
        salesChannel: 'selfService',
        identificationMethod,
        addressId,
        deliveryMethod,
        filter: 'All',
        lob: 'WL',
      };
      return getDeliveryDetailsServiceV2(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data) => {
        LoggerInstance.info('delivery success', data);
        localStorage.setItem(`${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${key}: `, error);
        localStorage.setItem(`${key}`, JSON.stringify(errorMock(`${key}`, error)));
      },
    },
  };
  return handleUseQuery(options);
};

/**
 * Delivery Evolved V2 hook
 * @param shippingAddress
 * @param pickupPoint
 * @param customOptions
 * @returns
 */
export const useDeliveryEvolvedV2 = (
  shippingAddress?: IAddressDataObject,
  pickupPoint?: IPickUpLocation | ILocateStoreResponse,
  customOptions?: CustomOptions
) => {
  const key = customOptions?.keyDependency
    ? customOptions.keyDependency?.[0]
    : 'deliveryDetailsIsEvolvedMobile';
  const keysDependency = [key];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id || findCaller?.customerRef?.id;
  const queryEnabler = !!cartId && !!customerId;
  const enabled: boolean =
    customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      let deliveryDetailsAdvanced = getFromLocalStorageByKey('deliveryDetailsIsAdvance');
      if (deliveryDetailsAdvanced && !Array.isArray(deliveryDetailsAdvanced)) {
        deliveryDetailsAdvanced = [deliveryDetailsAdvanced];
      }
      const deliveryData = deliveryDetailsAdvanced?.[0];
      const { deliveryDetails, identificationDetails } = deliveryData;
      const selectedMethod = identificationDetails?.filter((item) => item.isSelected === true);
      const identificationMethod = selectedMethod?.[0]?.code || 'NA';
      const deliveryMethod = pickupPoint?.storeId
        ? (pickupPoint as IPickUpLocation)?.typePickupPoint || 'PO'
        : deliveryCode(deliveryDetails);
      const parameters = {
        salesChannel: 'selfService',
        mode: 'reviewMode',
        identificationMethod,
        deliveryMethod,
        customerId,
        filter: 'All',
        lob: 'WL',
      };
      return getDeliveryDetailsServiceV2(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data) => {
        LoggerInstance.debug('delivery success', data);
        localStorage.setItem(`${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${key}: `, error);
        localStorage.setItem(`${key}`, JSON.stringify(errorMock(`${key}`, error)));
      },
    },
  };
  return handleUseQuery(options);
};

/**
 * Delivery Evolved V1 hook
 * @param shippingAddress
 * @param pickupPoint
 * @param customOptions
 * @returns
 */
export const useDeliveryEvolvedV1 = (
  shippingAddress?: IAddressDataObject,
  pickupPoint?: IPickUpLocation | ILocateStoreResponse,
  customOptions?: CustomOptions
) => {
  const key = customOptions?.keyDependency
    ? customOptions.keyDependency?.[0]
    : 'deliveryDetailsIsEvolvedMobile';
  const keysDependency = [key];
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id || findCaller?.customerRef?.id;
  const queryEnabler = !!cartId && !!customerId;
  const enabled: boolean =
    customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler;
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      let deliveryDetailsAdvanced = getFromLocalStorageByKey('deliveryDetailsIsAdvance');
      if (deliveryDetailsAdvanced && !Array.isArray(deliveryDetailsAdvanced)) {
        deliveryDetailsAdvanced = [deliveryDetailsAdvanced];
      }
      const deliveryData = deliveryDetailsAdvanced?.[0];
      const { deliveryDetails, identificationDetails } = deliveryData;
      const selectedMethod = identificationDetails?.filter((item) => item.isSelected === true);
      const identificationMethod = selectedMethod?.[0]?.code || 'NA';
      const recipientAddress = pickupPoint?.storeId
        ? (pickupPoint as IPickUpLocation)?.locationAddress ||
          (pickupPoint as ILocateStoreResponse)?.storeAddress
        : shippingAddress;
      const deliveryMethod = pickupPoint?.storeId
        ? (pickupPoint as IPickUpLocation)?.typePickupPoint || 'PO'
        : deliveryCode(deliveryDetails);
      const parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration',
        identificationMethod,
        addressId: recipientAddress?.id || pickupPoint?.storeId,
        deliveryMethod,
        filter: 'All',
        lob: 'WS',
      };
      return getDeliveryDetailsServiceV1(cartId as string, parameters, customOptions);
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data) => {
        LoggerInstance.debug('delivery success', data);
        localStorage.setItem(`${key}`, JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${key}: `, error);
        localStorage.setItem(`${key}`, JSON.stringify(errorMock(`${key}`, error)));
      },
    },
  };
  return handleUseQuery(options);
};

/**
 * Delivery Post Method
 * @returns
 */
export const usePostDeliveryV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['postDeliveryServiceDetailsMutation'],
    ({ shippingAddress, pickupPoint }: IPostDeliveryPayload) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      let deliveryDetailsEvolved = getFromLocalStorageByKey('deliveryDetailsIsEvolvedMobile');
      if (deliveryDetailsEvolved && !Array.isArray(deliveryDetailsEvolved)) {
        deliveryDetailsEvolved = [deliveryDetailsEvolved];
      }
      const deliveryData = deliveryDetailsEvolved?.[0];
      const { deliveryItems, identificationDetails, recipientDetails, deliveryDetails } =
        deliveryData;
      const selectedMethod = identificationDetails?.filter((item) => item.isSelected === true);
      const identificationMethodCode = selectedMethod?.[0]?.code;
      const recipientAddress = pickupPoint?.storeId
        ? (pickupPoint as IPickUpLocation)?.locationAddress ||
          (pickupPoint as ILocateStoreResponse)?.storeAddress
        : shippingAddress;
      const deliveryMethodCode = pickupPoint?.storeId
        ? (pickupPoint as IPickUpLocation)?.typePickupPoint || 'PO'
        : deliveryCode(deliveryDetails);
      const shippingMethodCode = deliveryDetails?.[0]?.shippingOptions?.[0]?.name;
      const pickUpDetails = pickupPoint?.storeId
        ? {
            id: pickupPoint.storeId,
            addressId:
              (pickupPoint as IPickUpLocation)?.locationAddress?.id ||
              (pickupPoint as ILocateStoreResponse)?.storeAddress?.id,
          }
        : {};

      const payload = [
        {
          deliveryMethodCode,
          shippingMethodCode,
          indetificationMethodCode: identificationMethodCode,
          recipientDetails: {
            recipientAddress: {
              id: recipientAddress?.id,
              ...(pickupPoint?.storeId && recipientAddress),
            },
            contactPhoneNumber: recipientDetails?.contactPhoneNumber,
            contactEmailAddress: recipientDetails?.contactEmailAddress,
            recipientName: {
              formattedName: recipientDetails?.recipientName?.formattedName,
            },
            notes: shippingAddress?.note,
          },
          pickUpDetails,
          deliveryItems,
        },
      ];

      return postDeliveryDetailsServiceV1(cartId as string, payload[0]);
    },
    {
      onSuccess: (data) => {
        const parsedData = data ? JSON.stringify(data) : '';
        localStorage.setItem('postDeliveryServiceDetails', parsedData);
        queryClient.setQueryData('postDeliveryServiceDetails', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - postDeliveryServiceDetails: ', error);
        queryClient.setQueryData(
          'postDeliveryServiceDetails',
          errorMock('postDeliveryServiceDetails', error)
        );
        localStorage.setItem(
          'postDeliveryServiceDetails',
          JSON.stringify(errorMock('postDeliveryServiceDetails', error))
        );
      },
    }
  );
};

/**
 * Delivery Post Method V2
 * @returns
 */
export const usePostDeliveryV2 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['postDeliveryServiceDetailsMutation'],
    ({ shippingAddress, pickupPoint }: IPostDeliveryPayload) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      let deliveryDetailsEvolved = getFromLocalStorageByKey('deliveryDetailsIsEvolved');
      if (deliveryDetailsEvolved && !Array.isArray(deliveryDetailsEvolved)) {
        deliveryDetailsEvolved = [deliveryDetailsEvolved];
      }

      const payload = [];
      const deliveryData = deliveryDetailsEvolved;
      let recipientAddress;

      if (!pickupPoint?.storeId) {
        recipientAddress = shippingAddress;
      } else {
        recipientAddress =
          (pickupPoint as IPickUpLocation)?.locationAddress ||
          (pickupPoint as ILocateStoreResponse)?.storeAddress;
      }

      if (deliveryData) {
        const getDeliveryItem = (deliveryItem) => {
          const { deliveryItems, identificationDetails, recipientDetails } = deliveryItem;
          const selectedMethod = identificationDetails?.filter(
            (item: any) => item.isSelected === true
          );

          const getDeliveryCode = (dI: any): string => {
            if (dI?.length > 0) {
              const details = [];
              dI?.forEach((el) => details.push(el?.details?.[0] || {}));
              const filteredItem = details?.find((itemToFilter) => itemToFilter?.isSelected);
              return filteredItem?.code || 'S';
            }
            return 'T';
          };

          const identificationMethodCode = selectedMethod?.[0]?.code;

          const shippingMethodCode =
            deliveryItems?.[0]?.details?.[0]?.shippingOptions?.[0]?.name || 'ST';

          const pickUpDetails = pickupPoint?.storeId
            ? {
                id: pickupPoint.storeId,
                addressId:
                  (pickupPoint as IPickUpLocation)?.locationAddress?.id ||
                  (pickupPoint as ILocateStoreResponse)?.storeAddress?.id,
              }
            : {};

          const getFixedDeliveryItems = (dItems) => {
            const items = [];
            dItems?.forEach((el) => {
              if (el?.items?.length > 0) {
                el?.items?.forEach((item) => {
                  if (item) items.push(item);
                });
              }
            });
            return items;
          };

          const deliveryItemsFixed = getFixedDeliveryItems(deliveryItems);
          const haveAntType = deliveryItemsFixed?.some(
            (el) => el.itemType?.toLowerCase() === 'ant'
          );

          let deliveryMethodCode = pickupPoint?.storeId
            ? (pickupPoint as IPickUpLocation)?.typePickupPoint || 'PO'
            : getDeliveryCode(deliveryItems);
          if (haveAntType) deliveryMethodCode = 'T';

          return {
            deliveryMethodCode,
            shippingMethodCode,
            indetificationMethodCode: identificationMethodCode,
            recipientDetails: {
              recipientAddress: {
                id: recipientAddress?.id,
                ...(pickupPoint?.storeId && recipientAddress),
              },
              contactPhoneNumber: recipientDetails?.contactPhoneNumber,
              contactEmailAddress: recipientDetails?.contactEmailAddress,
              recipientName: {
                formattedName: recipientDetails?.recipientName?.formattedName,
              },
              notes: shippingAddress?.note,
            },
            pickUpDetails,
            deliveryItems: deliveryItemsFixed,
          };
        };

        if (Array.isArray(deliveryData)) {
          deliveryData?.forEach((delivery) => {
            payload.push({ ...getDeliveryItem(delivery) });
          });
        } else {
          payload.push({ ...getDeliveryItem(deliveryData) });
        }
      }

      return postDeliveryDetailsServiceV2(cartId as string, payload);
    },
    {
      onSuccess: (data) => {
        const parsedData = data ? JSON.stringify(data) : '';
        localStorage.setItem('postDeliveryServiceDetails', parsedData);
        queryClient.setQueryData('postDeliveryServiceDetails', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - postDeliveryServiceDetails: ', error);
        queryClient.setQueryData(
          'postDeliveryServiceDetails',
          errorMock('postDeliveryServiceDetails', error)
        );
        localStorage.setItem(
          'postDeliveryServiceDetails',
          JSON.stringify(errorMock('postDeliveryServiceDetails', error))
        );
      },
    }
  );
};

/**
 * Delivery Replace billing address
 * @returns
 */
export const useReplaceBillingAddressV1 = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['replaceBillingAddress'],
    ({
      billingAccountId,
      billingAddressId,
      billingAddressType,
    }: IReplaceBillingAddressOrderPayload) =>
      editBillingAddressV1(billingAccountId, {
        billingAddressId,
        billingAddressType,
      }),
    {
      onSuccess: (data) => {
        const parsedData = data ? JSON.stringify(data) : '';
        localStorage.setItem('postDeliveryServiceDetails', parsedData);
        queryClient.setQueryData('postDeliveryServiceDetails', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - postDeliveryServiceDetails: ', error);
        queryClient.setQueryData(
          'postDeliveryServiceDetails',
          errorMock('postDeliveryServiceDetails', error)
        );
        localStorage.setItem(
          'postDeliveryServiceDetails',
          JSON.stringify(errorMock('postDeliveryServiceDetails', error))
        );
      },
    }
  );
};
