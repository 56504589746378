import { formatQueryParam, ICharacteristicDevice, ISku } from '@vfit/consumer/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { checkWindow } from '@vfit/shared/data-access';
import { useEffect, useState } from 'react';
import { getMappingDeviceCharacteristics, getSku } from './useDevice.utils';

/**
 * Test window location: Next router slow
 */
const useQueryStateWindow = () => {
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = checkWindow() && new URLSearchParams(querystringParams);
  const [color, setColor] = useState(checkWindow() && urlParams.get('color'));
  const [size, setSize] = useState(checkWindow() && urlParams.get('size'));

  const setQueryParams = (colorQueryParam: string, sizeQueryParam: string) => {
    const url = new URL(window.location as unknown as URL);
    url.searchParams.set('color', formatQueryParam(colorQueryParam));
    url.searchParams.set('size', formatQueryParam(sizeQueryParam));
    setColor(colorQueryParam);
    setSize(sizeQueryParam);
    window.history.pushState(null, '', url.toString());
  };

  return {
    setQueryParams,
    color,
    size,
  };
};

/**
 * Returns the selectedSku from a list of Skus, Characteristics JSON Object, an the QUERY STRING PARAMS.
 * @param skus
 * @param characteristics
 * @returns
 */
export const useSkuWindowLocation = (skus: ISku[], characteristics: ICharacteristicDevice) => {
  const { color, size, setQueryParams } = useQueryStateWindow();
  const [returnedSku, setReturnedSku] = useState<ISku | undefined>(undefined);

  const setQueryStringParams = (sku: ISku) => {
    const foundedSize = characteristics?.sizes?.elements?.find((el) => el?.id == sku?.idSize);
    if (
      formatQueryParam(foundedSize?.value) !== size ||
      formatQueryParam(sku?.displayNameColor) !== color
    ) {
      setQueryParams(sku?.displayNameColor, foundedSize?.value);
    }
  };

  // region FILTER

  const changeSku = (sku: ISku) => {
    const foundedSize = characteristics?.sizes?.elements?.find((el) => el?.id == sku?.idSize);
    filter(formatQueryParam(sku.displayNameColor), formatQueryParam(foundedSize.value));
  };

  const filter = (colorFilter, sizeFilter) => {
    const mappedDeviceCharacteristics = getMappingDeviceCharacteristics(
      { color: colorFilter, size: sizeFilter },
      skus,
      characteristics
    );
    const returnedSkuLocal = getSku(skus, mappedDeviceCharacteristics);
    if (returnedSkuLocal) {
      LoggerInstance.debug('UPDATING RETURN AND QUERY');
      setQueryStringParams(returnedSkuLocal);
      setReturnedSku(returnedSkuLocal);
    }
  };

  // endregion

  useEffect(() => {
    filter(color, size);
  }, []);

  return { selectedSku: returnedSku, changeSku };
};
