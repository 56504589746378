import React, { useEffect } from 'react';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import { Player } from '@lottiefiles/react-lottie-player';
import { datadogRum } from '@datadog/browser-rum';
import { useCheckout } from '../../../../../iBuyMobile.context';
import { Container, Title, Subtitle, TextLoaderEmpty, TextContainer } from './loadingCard.style';
import { ILoadingCardProps } from './loadingCard.models';

export const LoadingCard = ({ product, hasAcceptedFallback }: ILoadingCardProps) => {
  const { currentStepKey } = useCheckout();
  const { digitalBundleConfig } = product;

  const renderText = () => {
    if (hasAcceptedFallback) {
      return (
        <TextContainer>
          <Title>{digitalBundleConfig?.loadingCard?.title}</Title>
          <Subtitle>{digitalBundleConfig?.loadingCard?.subtitle}</Subtitle>
        </TextContainer>
      );
    }

    return <TextLoaderEmpty />;
  };

  const getLoader = (): string => digitalBundleConfig?.loadingCard?.loader || '';

  const checkStuckTimeout = () => {
    datadogRum.addError({
      step: currentStepKey,
      message:
        'La pagina sembra non rispondere in questo momento. Attendi o premi il tasto in basso per essere ricontattato.',
      error: 'stuck',
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkStuckTimeout();
    }, 120000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container>
      {renderText()}
      {getLoader() ? (
        <Player
          src={requireStaticImageAtomComponent(getLoader()) || ''}
          className="player"
          loop
          autoplay
        />
      ) : (
        <ShadowsGroup quantity={2} heights={90} />
      )}
    </Container>
  );
};

