import { VfModal } from '@vfit/shared/components';
import { Button } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import { INoOfferModalProps } from './noOfferModal.models';
import { ButtonContainer, Container, Description, Title, TopContainer } from './noOfferModal.style';

export const NoOfferModal = ({
  title,
  description,
  buttonLabel,
  onButtonClick,
  onClose,
  isOpen,
}: INoOfferModalProps) => {
  const [pTitle, pDescription] = purify([title, description]);

  return (
    <VfModal isOpen={isOpen} handleClose={onClose}>
      <Container>
        <TopContainer>
          <Title dangerouslySetInnerHTML={{ __html: pTitle || '' }} />
          <Description dangerouslySetInnerHTML={{ __html: pDescription || '' }} />
        </TopContainer>
        <ButtonContainer>
          <Button variant="primary" onClick={onButtonClick}>
            {buttonLabel}
          </Button>
        </ButtonContainer>
      </Container>
    </VfModal>
  );
};
