import OptionalText from '../OptionalText/optionalText';
import { ICheckboxItem } from './checkboxItem.models';
import { Container, CheckboxContainer } from './checkboxItem.style';

const CheckboxItem = ({ item, index, selIndex, onSelectItem }: ICheckboxItem) => {
  const handleClick = () => {
    onSelectItem(index);
  };
  return (
    <Container index={index} colorIndex={selIndex} onClick={handleClick}>
      <CheckboxContainer>
        <input
          type="radio"
          name="singleSelection"
          checked={selIndex === index}
          onChange={() => {}}
        />
      </CheckboxContainer>
      <OptionalText
        detail={item.detail}
        title={item.title}
        description={item.description}
        extra={item.extra}
        value={item.value}
      />
    </Container>
  );
};

export default CheckboxItem;

