import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { IMultipleOperators } from '../portability.model';

export const Container = styled.div`
  text-align: center;
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  font-weight: 700;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }
`;

export const InputWrapper = styled.div<IMultipleOperators>`
  margin-top: ${(props) => (props.isMultipleOperators ? 16 : 32)}px;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .text-input {
    width: 100%;
  }
`;

