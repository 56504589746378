export interface ISubscriberResponse {
  customerId: string;
  subscriberId: string;
}

export enum QueryParams {
  SELF = 'selfService',
  SUBSCRIBER= 'subscriber',
  DESC = 'DESC',
  FILTER = "status%3D%3DCANCELLED"
}

export interface IActiveCampaignResponse {
  campaignId: string;
  expirationDate: number;
  assetUrl: string;
}

export interface IAcceptCampaignPayload {
  audienceType: string;
  audienceId: string;
  msisdn: string;
  isWinbackCampaign: string;
  customerId: string;
}

export interface IAcceptCampaignResponse {
  orderId: string;
  orderType: string;
}
