import { IProduct } from '@vfit/consumer/data-access';
import { ICustomSize, ICustomSizeValues } from '@vfit/shared/models';
import { ITab } from './components/Tabs/tabs.model';

const DEFAULT_SIZE: ICustomSize = {
  default: {
    width: 405,
  },
  tablet: {
    width: 329,
  },
  mobile: {
    width: 329,
  },
};

/**
 * Return the size of slide
 * @param slideIndex
 * @param isMobile
 * @param isTablet
 */
const getSlideSize = (
  slideIndex: number,
  isMobile: boolean,
  isTablet: boolean
): ICustomSizeValues => {
  if (isTablet) return DEFAULT_SIZE.tablet || DEFAULT_SIZE.mobile;
  return isMobile ? DEFAULT_SIZE.mobile : DEFAULT_SIZE.default;
};

const updateAllMobileHeight = (
  ref: HTMLDivElement,
  allMobileHeight: number[],
  activeIndex: number
) => {
  const allSlides = ref?.getElementsByClassName('swiper-slide');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allSlides.length; i++) {
    let pxAssign = `${allMobileHeight[activeIndex]}px`;
    if (i !== activeIndex) {
      pxAssign = `${allMobileHeight[activeIndex] - 20}px`;
    }
    (allSlides[i] as HTMLElement).style.height = pxAssign;
  }
};

const getAllHeight = (ref: HTMLDivElement) => {
  const pagination = ref?.getElementsByClassName('swiper-pagination');
  (pagination[0] as HTMLElement).style.marginBottom = '42px';
  const allSlides = ref?.getElementsByClassName('swiper-slide');
  const allHeight = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < allSlides.length; i++) {
    if (allSlides[i].firstChild) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      allHeight.push((allSlides[i] as HTMLElement).firstChild?.clientHeight);
    }
  }
  return allHeight;
};

const getTabFilters = (products: IProduct[], defaultSelectedTabName: string) => {
  const tabFilterCopy: ITab[] = [];
  products.forEach((product) => {
    if (!tabFilterCopy.find((tab) => tab.value === product?.tab?.value)) {
      if (product.tab?.value) {
        tabFilterCopy.push({
          isActive: false,
          value: product?.tab?.value,
        });
      }
    }
  });
  tabFilterCopy.push({
    isActive: true,
    value: defaultSelectedTabName,
  });
  return tabFilterCopy;
};

export { getSlideSize, getAllHeight, updateAllMobileHeight, DEFAULT_SIZE, getTabFilters };
