import { IPersonalDataObject } from '@vfit/shared/models';

export enum REGION {
  ITALIA = 'Italia',
  FOREIGN_COUNTRY = 'Estero',
}

export enum GENDER {
  MALE = 'Maschio',
  FEMALE = 'Femmina',
}

export type IFiscalCodeInfo = {
  errorMessage: string;
  title: string;
  description: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  gender: string;
  nationality: string;
  birthPlace: string;
  buttonLabel: string;
  birthNation: string;
};

export type IFiscalCodeData = {
  defaultData?: IPersonalDataObject;
  onSubmit: (data: IPersonalDataObject) => void;
  data?: IFiscalCodeInfo;
};
