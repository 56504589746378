import { UseQueryResult } from 'react-query';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { ICustomerReferenceDataResponse } from './customerReferenceData.models';

/**
 * This method is used retrieve the customer References from ApicWeb.
 * Method: GET
 * SearchParams: salesChannel: selfService
 * @returns
 */
const customerReferenceService = (customOptions?: CustomOptions) =>
  nextClient.get(API.CUSTOMER_REFERENCE_DATA, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<ICustomerReferenceDataResponse>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the customerReferenceService
 * @param customOptions
 * @returns
 */
export const useGetCustomerReference = (
  customOptions?: CustomOptions
): UseQueryResult<ICustomerReferenceDataResponse, INextError> => {
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency
      ? customOptions.keyDependency
      : 'customerReference';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => customerReferenceService(customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      onSuccess: (data: ICustomerReferenceDataResponse) => {
        localStorage.setItem('customerReference', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - customerReference: `, error);
        localStorage.setItem(
          `customerReference`,
          JSON.stringify(errorMock(`customerReference`, error))
        );
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<ICustomerReferenceDataResponse, INextError>;
};
