import { useQueryClient, UseQueryResult } from 'react-query';
import {
  errorMock,
  LOGGED_USER_KEYS,
  IFindCallerResponse,
  IUserService,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  nextClient,
  LoggerInstance,
  INextError,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { PostCustomerResponse } from '@vfit/shared/models';
import { GetCustomerResponse } from './getCustomer.models';
import { ISilentLoginResponse } from '../authentication';

/**
 * This method calls che customer service looking for a specific ID
 * Method: GET
 * @param customerId
 * @param customOptions
 */
const customerService = (customerId: string, customOptions?: CustomOptions) =>
  nextClient.get(`${API.CUSTOMER}/${customerId}`, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<GetCustomerResponse[]>;

/**
 * This method recalls the customer ID and use it to returns all the customer information saved
 * @param checkLogged
 * @param customOptions
 * @param customerActiveSubs
 * @returns
 */
export const useCustomerInfo = (
  checkLogged?: boolean,
  customOptions?: CustomOptions,
  customerActiveSubs?: boolean
): UseQueryResult<GetCustomerResponse[], INextError> => {
  // GET CUSTOMER BEFORE ASSOCIATE CUSTOMER
  const queryClient = useQueryClient();
  const patchCustomer: GetCustomerResponse | undefined = getFromLocalStorageByKey('patchCustomer');

  let customerId: string;
  let queryEnabler: boolean;

  if (!checkLogged && !patchCustomer) {
    LoggerInstance.debug('HAS AXCTIVE SUBS - 1', customerActiveSubs);
    const postCustomerData: PostCustomerResponse | undefined =
      queryClient.getQueryData('postCustomer');

    const findCallerSaved = localStorage.getItem('findCaller');
    const findCaller: IFindCallerResponse | boolean =
      !!findCallerSaved && JSON.parse(findCallerSaved as string);
    const recognizedUser =
      customerActiveSubs === true &&
      (findCaller as IFindCallerResponse)?.resultInfo === 'existingNextCustomer' &&
      (findCaller as IFindCallerResponse)?.customerRef?.id;
    LoggerInstance.debug('findCaller', findCaller);
    // TODO take from findCaller(2)
    customerId = recognizedUser
      ? (findCaller as IFindCallerResponse)?.customerRef?.id
      : postCustomerData?.id;

    queryEnabler = Boolean(customerId);
    LoggerInstance.debug('queryEnabler, customerID', queryEnabler, customerId);
  } else if (customerActiveSubs === false) {
    LoggerInstance.debug('HAS AXCTIVE SUBS - 2', customerActiveSubs, patchCustomer?.id);

    customerId = patchCustomer?.id;
    queryEnabler = Boolean(patchCustomer);
  } else {
    LoggerInstance.debug('HAS AXCTIVE SUBS - 3', customerActiveSubs);

    const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
    const findCaller = getFromLocalStorageByKey('findCaller');

    const user: IUserService | undefined = queryClient.getQueryData('user');

    const silentLogin: ISilentLoginResponse | undefined = queryClient.getQueryData('silentLogin');

    const loggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;

    customerId = user?.customer?.[0]?.id || findCaller?.customerRef?.id || customerData?.[0]?.id;

    queryEnabler = Boolean(customerId) && loggedUser;
  }

  if (!customerId) {
    queryEnabler = false;
  }

  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'customerData';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => customerService(customerId as string, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: queryEnabler,
      onSuccess: (data: GetCustomerResponse[]) => {
        // save customerData object into storage
        const oldCF: string = data?.[0]?.owningIndividual?.fiscalCode || '';
        localStorage.setItem('customerData', JSON.stringify(data));
        localStorage.setItem('customerDataCf', oldCF);
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - customerData: `, error);
        localStorage.setItem(`customerData`, JSON.stringify(errorMock(`customerData`, error)));
        customOptions?.onError?.(error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<GetCustomerResponse[], INextError>;
};

/**
 * This method retrieve customer
 * @param customerId
 * @param customOptions
 * @returns
 */
export const useGetCustomer = (
  customerId: string,
  customOptions?: CustomOptions
): UseQueryResult<GetCustomerResponse[], INextError> => {
  const enabled = customOptions?.enabled !== undefined ? customOptions?.enabled : true;
  const keysDependency: string | string[] = customOptions?.keyDependency
    ? customOptions.keyDependency
    : 'customerData';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => customerService(customerId as string, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled,
      onSuccess: (data: GetCustomerResponse[]) => {
        // save customerData object into storage
        const oldCF: string = data?.[0]?.owningIndividual?.fiscalCode || '';
        localStorage.setItem('customerData', JSON.stringify(data));
        localStorage.setItem('customerDataCf', oldCF);
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - customerData: `, error);
        localStorage.setItem(`customerData`, JSON.stringify(errorMock(`customerData`, error)));
        customOptions?.onError?.(error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<GetCustomerResponse[], INextError>;
};
