import {
  formatQueryParam,
  ICharacteristicDevice,
  ICharacteristicsValues,
  ISku,
} from '@vfit/consumer/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { IGetMappingDeviceCharacteristics } from './useDevices.models';

/**
 * Given a list of SKUs it returns back the DEFAUL if is SELLABLE
 *
 * If the DEFAULT is NOT SELLABLE it retrieve the defaultIdColor
 * and it returns back the first SELLABLE with DEFAULT_ID_COLOR
 *
 * @param skus
 * @returns
 */
const getDefaultSku = (skus: ISku[]): ISku => {
  // Getting the DEFAULT if AVAILABLE
  const defaultSku = skus.find((sku) => sku?.isDefault && sku?.isSellable);
  if (defaultSku) {
    return defaultSku;
  }

  // Get the default color
  const defaultIdColor = skus.find((sku) => sku?.isDefault)?.idColor;
  // Return the firs available of the color
  return skus.find((sku) => sku?.idColor === defaultIdColor && sku?.isSellable);
};

/**
 * Return the fallback Sku if there is no SIZE and COLOR parameter.
 * It handles the cases:
 * -  COLOR |   SIZE  -
 * -    0   |    0    -  --> FIRST SKU when DEFAULT:TRUE + SELLABLE:TRUE
 * -    0   |    1    -  --> FIRST COLOR when SIZE:1 + SELLABLE:TRUE
 * -    1   |    0    -  --> FIRST SIZE when COLOR:1 + SELLABLE:TRUE
 * -    1   |    1    -  --> NOT HANDLED
 * --------------------
 * @param skus
 * @param colorMapping
 * @param sizeMapping
 * @returns
 */
const getFallBackSku = (
  skus: ISku[],
  colorMapping?: ICharacteristicsValues,
  sizeMapping?: ICharacteristicsValues
): ISku | undefined => {
  // FALLBACKS
  // 1 - NO PARAMS
  if (!colorMapping && !sizeMapping) {
    return getDefaultSku(skus);
  }
  // 2 - ONLY COLOR
  if (colorMapping && !sizeMapping) {
    LoggerInstance.debug(skus.find((sku) => sku?.idColor == colorMapping.id));
    return skus.find((sku) => sku?.idColor == colorMapping.id && sku?.isSellable);
  }
  // 3 - ONLY SIZE
  if (!colorMapping && sizeMapping) {
    return skus.find((sku) => sku?.idSize == sizeMapping.id && sku?.isSellable);
  }
  return undefined;
};

/**
 * Return mapped device/characteristic
 * @param queryParams
 * @param skus
 * @param characteristics
 */
const getMappingDeviceCharacteristics = (
  queryParams,
  skus: ISku[],
  characteristics: ICharacteristicDevice
): IGetMappingDeviceCharacteristics => {
  // Destructuring COLOR and SIZE from queryParams Object
  const { color: colorQueryParam, size: sizeQueryParam } = queryParams;

  // Return the Ids for COLOR and SIZE from Characteristics JSON Ogject
  const colorQueryParamId = skus.find(
    (sku) => formatQueryParam(sku.displayNameColor) === colorQueryParam
  )?.idColor;

  // colorQueryParams characteristics skus
  const colorMapping = characteristics?.colors?.elements?.find(
    (color) => color.id == colorQueryParamId
  );
  const sizeMapping = characteristics?.sizes?.elements?.find(
    (size) => formatQueryParam(size.value) == sizeQueryParam
  );
  return {
    colorQueryParam,
    colorMapping,
    sizeQueryParam,
    sizeMapping,
  };
};

/**
 * Return Sku initial or fallback if no initial founded
 * @param skus
 * @param mappedDeviceCharacteristic
 * @returns
 */
const getSku = (
  skus: ISku[],
  mappedDeviceCharacteristic: IGetMappingDeviceCharacteristics
): ISku => {
  const { colorMapping, sizeMapping } = mappedDeviceCharacteristic;

  // Filtering the DEFAULT fallback sku
  const fallbackSku = getFallBackSku(skus, colorMapping, sizeMapping);

  LoggerInstance.debug({ fallbackSku });

  // FILTERING (COLOR-SIZE)
  const initialSku = skus.find(
    (sku) => sku?.idColor == colorMapping?.id && sku?.idSize == sizeMapping?.id
  );
  LoggerInstance.debug({ initialSku });

  return initialSku || fallbackSku;
};

export { getSku, getDefaultSku, getFallBackSku, getMappingDeviceCharacteristics };
