import { useEffect, useState } from 'react';
import {
  useGetDataFromStorage,
  useCreateAddress,
  useServiceabilityAddressMutation,
  useValidateAddress,
  ISilentLoginResponse,
  CUSTOMER_TYPES,
  isAppMock,
  retrieveProduct,
  getParamsInUrl,
  usePage,
  IProductOfferListByTech,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { LoggerInstance } from '@vfit/shared/data-access';
import {
  CoverageErrorType,
  IAddressResponse,
  IOfferingServiceabilityResponse,
  IValidateAddressRequest,
  IValidateAddressResponse,
} from '@vfit/shared/models';
import { checkWindow, getFromLocalStorageByKey, getHome } from '@vfit/shared/data-access';

interface IAPIError {
  error: string;
  errorCode?: number;
  errorMessage: string;
}

type ICoverageToolFlow = [
  isAddressObjectFul: boolean,
  isSuccess: boolean,
  isLoading: boolean,
  isError: boolean,
  errorMessage: string,
  errorType: number
];

interface IUseCoverageToolFlowPayload {
  offerId: string;
  onConfirmAddress: boolean;
  alternativeProductList?: string[];
  alternativeProductListByTech?: IProductOfferListByTech[];
  step?: string;
}

export const useCoverageToolFlow = ({
  offerId,
  onConfirmAddress,
  alternativeProductList,
  alternativeProductListByTech,
  step,
}: IUseCoverageToolFlowPayload): ICoverageToolFlow => {
  const { products: allProducts } = usePage();
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<number>(CoverageErrorType.KO);
  const [mutationIsNotFull, setMutationIsNotFull] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const coexistenceData: {
    userType: string;
    trustCookie: string;
  } = getFromLocalStorageByKey('coexistenceUser');
  const silentLoginR2: ISilentLoginResponse = queryClient.getQueryData('silentLoginR2');
  const isLoggedR2 =
    silentLoginR2?.flowId &&
    silentLoginR2?.flowId === 'existingLogin' &&
    coexistenceData?.userType === CUSTOMER_TYPES.LEGACY;

  /**
   * Hook for useValidateAddress() & useCreateAddress();
   */
  const validateAddressMutation = useValidateAddress();
  const createAddressMutation = useCreateAddress();
  const addressObject: IValidateAddressRequest | undefined = queryClient.getQueryData(
    'coverageToolInputAddress'
  );

  const serviceabilityAddressMutation = useServiceabilityAddressMutation(
    offerId,
    allProducts || [],
    alternativeProductList,
    alternativeProductListByTech,
    addressObject?.placeId
  );

  const isLocalStorageDisable = localStorage.getItem('disableReset');
  LoggerInstance.debug('this is DISABLE LOCAL STORAGE', isLocalStorageDisable);

  const validatedAddress = useGetDataFromStorage<IValidateAddressResponse>(
    'validatedAddress',
    !!isLocalStorageDisable
  );

  const offeringServiceability = useGetDataFromStorage<IOfferingServiceabilityResponse>(
    'offeringServiceability',
    !!isLocalStorageDisable
  );

  const createdAddress = useGetDataFromStorage<IAddressResponse>(
    'createdAddress',
    !!isLocalStorageDisable
  );

  LoggerInstance.debug(
    'THIS IS DATA',
    validatedAddress,
    offeringServiceability,
    createdAddress,
    addressObject
  );

  /**
   *
   * Enabling API calls only if the addressObject retrieved from google autocomplete
   * is fully filled
   */
  const flowEnabler =
    !!(addressObject as IValidateAddressRequest)?.city &&
    !!(addressObject as IValidateAddressRequest)?.postalCode &&
    !!(addressObject as IValidateAddressRequest)?.stateOrProvince &&
    !!(addressObject as IValidateAddressRequest)?.street &&
    !!(addressObject as IValidateAddressRequest)?.streetNumber &&
    onConfirmAddress; // this to trigger API calls on "Conferma Indirizzo"
  // disable the coverage tool if the R2 user is logged

  const redirectShoppingCartAppMock = () => {
    const product = retrieveProduct();
    const redirectUrl = `${getHome()}/${
      product?.redirectCoveragePath || ''
    }?app=true&flowType=1&placeId=${addressObject?.placeId}&cmsId=${product.cmsId}`;
    window.location.href = redirectUrl;
  };

  /**
   * Check address already validated
   * when user click on edit address this check prevent the api call if address is already validated
   * @return boolean
   */
  const checkAddressAlreadyValidated = (): boolean => {
    LoggerInstance.debug({ validatedAddress });
    const validAddress = (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0];
    if (!validAddress) return false;
    return (
      validAddress.city?.toLowerCase() === addressObject?.city?.toLowerCase() &&
      validAddress.postalCode?.toLowerCase() === addressObject?.postalCode?.toLowerCase() &&
      validAddress.stateOrProvince?.toLowerCase() ===
        addressObject?.stateOrProvince.toLowerCase() &&
      validAddress.street?.toLowerCase() === addressObject?.street?.toLowerCase()
    );
  };

  const checkValidateAddress = () => {
    const showError = () => {
      LoggerInstance.debug('ERROR POPUP');
      const isTechFWA =
        checkWindow() &&
        localStorage.getItem('isTechFWA') !== 'undefined' &&
        JSON.parse(localStorage.getItem('isTechFWA') as string) === true;
      setIsLoading(false);
      setIsError(true);
      setErrorType(isTechFWA ? CoverageErrorType.FWA : CoverageErrorType.KO);
      setErrorMessage((validatedAddress as IAPIError)?.errorMessage || '');
    };
    LoggerInstance.debug(
      'serviceabilityAddressMutation validatedAddress',
      validatedAddress as IValidateAddressResponse
    );
    LoggerInstance.debug(
      'serviceabilityAddressMutation.mutate PAYLOAD',
      (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0]
    );

    if (
      validateAddressMutation.isSuccess &&
      !!validatedAddress &&
      (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0]
    ) {
      if (
        !(validatedAddress as IAPIError)?.error &&
        (!offeringServiceability || !!isLocalStorageDisable)
      ) {
        if (isAppMock() && checkWindow() && getParamsInUrl('app')) {
          redirectShoppingCartAppMock();
        } else {
          serviceabilityAddressMutation.mutate(
            (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0]
          );
        }
      } else {
        showError();
      }
    } else if (validateAddressMutation.isError || (validatedAddress as IAPIError)?.error) {
      showError();
    }
  };

  /*
    Triggering ValidateAddress Mutation if flowEnabler and addressObject
  */
  useEffect(() => {
    if (!flowEnabler && !!addressObject) {
      setMutationIsNotFull(true);
    }
    if (addressObject && flowEnabler) {
      const addressAlreadyValidated = checkAddressAlreadyValidated();
      if (isLocalStorageDisable || (!addressAlreadyValidated && !validatedAddress)) {
        setIsLoading(true);
        validateAddressMutation.mutate({
          placeObject: addressObject,
          ...(step ? { step: step } : {}),
        });
      }
    }
  }, [addressObject, flowEnabler]);

  /*
    Checking ValidateAddress error and success. Triggering OfferingServiceability Mutation
  */
  useEffect(() => {
    if (!!validatedAddress && validateAddressMutation.isSuccess) {
      checkValidateAddress();
    }

    LoggerInstance.debug('THIS IS validateAddress', validatedAddress);
    LoggerInstance.debug('THIS IS validateAddressMutation', validateAddressMutation);
    LoggerInstance.debug(
      'THIS IS USEEFFECT ABILITATION',
      validatedAddress &&
        validateAddressMutation.isSuccess &&
        !(validatedAddress as IAPIError)?.error
    );
  }, [!!validatedAddress, validateAddressMutation.isSuccess]);

  useEffect(() => {
    if (validateAddressMutation.isError && (validatedAddress as IAPIError)?.error) {
      setIsError(true);
      setErrorMessage((validatedAddress as IAPIError)?.errorMessage);
    }
  }, [validateAddressMutation.isError, !!validatedAddress]);

  /*
    Checking OfferingServiceability error and success. Triggering CreateAddress Mutation
  */
  useEffect(() => {
    if ((offeringServiceability as IAPIError)?.error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage((offeringServiceability as IAPIError)?.errorMessage);
      setErrorType((offeringServiceability as IAPIError)?.errorCode || CoverageErrorType.KO);
    }

    if (
      !(offeringServiceability as IAPIError)?.error &&
      serviceabilityAddressMutation.isSuccess &&
      !isError &&
      (!createdAddress || !!isLocalStorageDisable) &&
      !createAddressMutation.isLoading &&
      (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0]
    ) {
      // TODO: check optimistic update in offering serviceability
      createAddressMutation.mutate({
        address: (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0],
      });
    }
  }, [isError, offeringServiceability, serviceabilityAddressMutation.isSuccess]);

  /*
    Checking createdAddress error and success. Triggering isSuccess to true
  */
  useEffect(() => {
    if (!isError && createdAddress) {
      setIsSuccess(true);
      setIsLoading(false);
    }
  }, [createdAddress]);

  useEffect(() => {
    if (isLoggedR2) {
      LoggerInstance.debug('TEST R2 - USER NOT ABLE TO MAKE COVERAGE');
      setErrorType(CoverageErrorType.R2_LOGGED);
      setIsLoading(false);
      setIsError(true);
    }
  }, [isLoggedR2]);

  useEffect(() => {
    if (isSuccess || isError) {
      setIsLoading(false);
      setIsSuccess(false);
      setIsError(false);
    }
  }, [isSuccess, isError]);

  return [mutationIsNotFull, isSuccess, isLoading, isError, errorMessage, errorType];
};

