import { Children } from 'react';
import { Tab, TabsContainer } from './tabs.style';
import { ITabs } from './tabs.model';

export const Tabs: React.FC<ITabs> = ({ tabs, action }) => (
  <TabsContainer>
    {Children.toArray(
      tabs?.map(({ value, isActive }, index) => (
        <Tab onClick={() => action(index)} isActive={isActive}>
          {value}
        </Tab>
      ))
    )}
  </TabsContainer>
);

