import { FC, useEffect, useState } from 'react';
import { ICheckoutData } from '../../checkout.models';
import VoucherInsertionCard from './components/VoucherInsertionCard/voucherInsertionCard';
import VoucherSuccessCard from './components/VoucherSuccessCard/voucherSuccessCard';
import { useCheckout } from '../../../iBuyMobile.context';

export const VoucherCard: FC<ICheckoutData> = (props) => {
  const { voucher } = useCheckout();
  const [isValidVaucher, setIsValidVaucher] = useState(
    (voucher?.voucher?.isSuccess &&
      voucher?.shoppingCart?.isSuccess &&
      !voucher?.isInvalidVoucher &&
      !voucher?.isExpiredVoucher) ||
      false
  );

  const checkVoucherValidation = () => {
    if (!voucher?.validateVoucher?.isSuccess) {
      setIsValidVaucher(false);
    } else if (!voucher?.isExpiredVoucher && !voucher?.isInvalidVoucher) {
      setIsValidVaucher(true);
    }
  };

  useEffect(() => {
    checkVoucherValidation();
  }, [voucher]);

  if (isValidVaucher) return <VoucherSuccessCard {...props} />;
  return <VoucherInsertionCard {...props} />;
};

export default VoucherCard;
