import { useEffect, useState } from 'react';
import {
  useAcceptCampaign,
  useGetCampaignDetails,
  useGetShoppingCartSilentUpdate,
  useGetSubscriberByResource,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { resetData } from '@vfit/shared/data-access';
import { isMockWinback } from './useWinback.utils';

export const useWinback = (phoneNumber: string) => {
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const campaignId = localStorage.getItem('targetCampaignId') || '';

  const {
    data: subscriberData,
    isSuccess: isSuccessGetSubscriber,
    isLoading: isLoadingGetSubscriber,
    isError: isErrorGetSubscriber,
    refetch: refetchGetSubscriberByResource,
  } = useGetSubscriberByResource(phoneNumber, false);

  const {
    isSuccess: isSuccessCampaignDetails,
    isLoading: isLoadingCampaignDetails,
    isError: isErrorCampaignDetails,
    refetch: refetchCampaignDetails,
  } = useGetCampaignDetails(campaignId, false);

  const {
    mutate: mutateAcceptCampaign,
    data: acceptCampaignData,
    isSuccess: isSuccessAcceptCampaign,
    isLoading: isLoadingAcceptCampaign,
    isError: isErrorAcceptCampaign,
  } = useAcceptCampaign();

  const {
    isSuccess: isSuccessShoppingCart,
    isLoading: isLoadingShoppingCart,
    isError: isErrorShoppingCart,
  } = useGetShoppingCartSilentUpdate(acceptCampaignData?.orderId, {
    enabled: !!acceptCampaignData?.orderId,
  });

  const handleAcceptCampaign = (enable: boolean) => {
    if (!enable) return;
    if (subscriberData) {
      mutateAcceptCampaign({
        phoneNumber,
        campaignId,
      });
    }
  };

  const resetState = () => {
    setIsLoading(false);
    setIsSuccess(false);
    setIsError(false);
    resetData(queryClient, [
      'getShoppingCart',
      'getSubscriberByResource',
      'getCampaignDetails',
      'acceptCampaign',
    ]);
  };

  useEffect(() => {
    if (isSuccessGetSubscriber) {
      setIsError(!subscriberData);
      if (!isError) refetchCampaignDetails();
    }
  }, [isSuccessGetSubscriber]);

  useEffect(() => {
    handleAcceptCampaign(isSuccessCampaignDetails);
  }, [isSuccessCampaignDetails]);

  useEffect(() => {
    const loading = [
      isLoadingGetSubscriber,
      isLoadingCampaignDetails,
      isLoadingAcceptCampaign,
      isLoadingShoppingCart,
    ].some(Boolean);
    setIsLoading(loading);
  }, [
    isLoadingGetSubscriber,
    isLoadingCampaignDetails,
    isLoadingAcceptCampaign,
    isLoadingShoppingCart,
  ]);

  useEffect(() => {
    const success = [
      isSuccessGetSubscriber,
      isSuccessCampaignDetails,
      isSuccessAcceptCampaign,
      isSuccessShoppingCart,
    ].every(Boolean);
    setIsSuccess(success);
  }, [
    isSuccessGetSubscriber,
    isSuccessCampaignDetails,
    isSuccessAcceptCampaign,
    isSuccessShoppingCart,
  ]);

  useEffect(() => {
    const error = [
      isErrorGetSubscriber,
      isErrorCampaignDetails,
      isErrorAcceptCampaign,
      isErrorShoppingCart,
    ].some(Boolean);
    setIsError(error);
  }, [isErrorGetSubscriber, isErrorCampaignDetails, isErrorAcceptCampaign, isErrorShoppingCart]);

  const refetchFlowStart = () => {
    if (isMockWinback()) {
      setIsSuccess(true);
    } else {
      refetchGetSubscriberByResource();
    }
  };

  return {
    isSuccess,
    isLoading,
    isError,
    refetchFlowStart,
    resetState,
  };
};
