import {IBrightSkyCms} from "@vfit/consumer/data-access";

export const evaluateCmsData = (cmsData : IBrightSkyCms) => {
  const brightSkyObj = cmsData?.brightsky;
  return ({
    isVisible: brightSkyObj?.isVisible || "false",
    text: brightSkyObj?.text || "",
    redirectUrl: brightSkyObj?.redirectUrl || "",
    redirectLabel: brightSkyObj?.redirectLabel || "",
    imagePath: brightSkyObj?.imagePath || "",
    marketId: brightSkyObj?.marketId || "",
    srcScript: brightSkyObj?.srcScript || "",
    mode: brightSkyObj?.mode || "popup"
  })
}
