import { useQuery } from 'react-query';
import { IPageNavigation } from '@vfit/shared/models';
import { handleUseQueryes, LoggerInstance } from '@vfit/shared/data-access';
import { IPage, IUsePage } from './page.models';
import { getHubKey } from './page.utils';
import { IProduct } from '../../api/products/products.models';
import { IDevice } from '../../utils/devices/devices.models';

export const usePage = (): IUsePage => {
  const configQuery = {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Infinity,
    cacheTime: Infinity,
  };
  const isInCartView = window?.location?.href?.includes('shopping-cart');
  const { data: page } = useQuery<IPage>(['page'], configQuery);
  const { data: pageNavigation } = useQuery<IPageNavigation>(['getPageNavigation'], configQuery);
  const devicesApi = page?.params?.elements?.apiDeviceList?.toLowerCase().split('/').join('-');
  const productsApi = page?.params?.elements?.apiProductsList;
  const productsKey = isInCartView
    ? 'shopping-cart-products'
    : getHubKey(page?.params?.key, productsApi);
  const { data: product } = useQuery<IProduct>(['product'], configQuery);
  const { data: products } = useQuery<{ [key: string]: IProduct }>([productsKey], configQuery);
  const { data: device } = useQuery<IDevice>(['device'], configQuery);
  const { data: devices } = useQuery<{ [key: string]: IDevice }>([devicesApi], configQuery);
  const { data: apiCustomHubs } = useQuery<string[] | undefined>(['apiCustomHubs'], configQuery);
  const allApiInCustomHubs = [];
  Object.keys?.(apiCustomHubs || {})?.forEach((el) => allApiInCustomHubs.push(apiCustomHubs[el]));
  const allCustomHubsQueries = handleUseQueryes(
    allApiInCustomHubs?.map((el) => ({
      queryKey: [el],
      queryFn: () => {
        LoggerInstance.log('queryFn', el);
      },
      options: configQuery,
    }))
  );

  /**
   * Retrieve products/devices for custom hubs and custom widgets
   */
  const getCustomHubs = (): { [key: string]: IProduct[] | IDevice[] } => {
    const customHubs = {};
    Object.keys?.(apiCustomHubs || {})?.forEach((el) => {
      customHubs[el] = allCustomHubsQueries[apiCustomHubs[el]]?.data || [];
    });
    return customHubs;
  };

  return {
    page,
    pageNavigation,
    product,
    products: Object.values(products || {}),
    device,
    devices: Object.values(devices || {}),
    customHubs: getCustomHubs(),
  };
};
