import { IWinBackCMS } from '@vfit/consumer/data-access';

export const organizeWinBackErrorPopup = (cmsData: IWinBackCMS) => ({
  popupTitle: cmsData?.winbacknumber?.expiredPopup?.title || '',
  popupMessage: cmsData?.winbacknumber?.expiredPopup?.message || '',
  popupCtaText: cmsData?.winbacknumber?.expiredPopup?.ctaText || '',
  popupButtonText: cmsData?.winbacknumber?.expiredPopup?.buttonText || '',
  popupButtonRedirect: cmsData?.winbacknumber?.expiredPopup?.buttonRedirect || '',
  urlClickToCall: cmsData?.winbacknumber?.expiredPopup?.urlClickToCall || '',
});

