import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';

export const ProductSolutionTextLoader = styled.div`
  width: inherit;
  margin-left: 24px;
  margin-right: 24px;
  height: 90px;
  margin-bottom: 16px;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100vh;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const ProductSolutionContainerText = styled.div`
  margin: 48px 23px 31px;
  width: 300px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 420px;
    margin: 0 23px 31px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    width: 624px;
  }
`;

