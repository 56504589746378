import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CMS_CONFIG,
  errorMock,
  getLastPaymentMethod,
  IGenericErrorCMS,
  IPayments,
  // PAYMENT_PRESENT_CC_BANK_ACCONT,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import {
  API,
  getFromLocalStorageByKey,
  openPopup,
  PAGES,
  setTrackLink,
} from '@vfit/shared/data-access';
import { EMethodCode, IPayMeanServiceResponse, ISIAFlowType } from '@vfit/shared/models';
import { resetData, useTracking } from '@vfit/shared/data-access';
import { PaymentMethod } from '@vfit/consumer/components';
import { organizePaymentMethod, organizePaymentMethodError } from './paymentMethodCard.utils';
import { useCheckout } from '../../../iBuyMobile.context';
import { ID_FLOWS } from '../../checkout.constants';
import { ICheckoutData } from '../../checkout.models';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { useUpdatePaymentMethodFlow } from '../../hooks/UseUpdatePaymentMethodFlow/useUpdatePaymentMethodFlow';

const PaymentMethodCard = ({
  handleOnChangeEnableGoNext,
  handleGoNextSlide,
  handleGoPrevSlide,
}: ICheckoutData) => {
  const queryClient = useQueryClient();
  const taggingMobile = getTagging('payment method pre-filled');
  const {
    customerFlow,
    checkoutErrors,
    owningData,
    isModalButtonSticky,
    product,
    setIsLastCard,
    setCurrentStepKey,
    setIsPreselectedCard,
  } = useCheckout();
  const [isSuccessChangePayment, setIsSuccessChangePayment] = useState(false);
  const paymentData = customerFlow?.payment?.data;
  const payMean: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');
  // const paymentMethodAlreadyHave = localStorage.getItem(PAYMENT_PRESENT_CC_BANK_ACCONT);

  // NEW MOP - remove logic
  // let typePaymentMethodAlreadyHave = '';
  // if (paymentMethodAlreadyHave) {
  //   if (paymentMethodAlreadyHave === '1') typePaymentMethodAlreadyHave = EMethodCode.CREDIT_CARD;
  //   if (paymentMethodAlreadyHave === '2') typePaymentMethodAlreadyHave = EMethodCode.BANK_ACCOUNT;
  // }
  const selectedPaymean = getLastPaymentMethod(payMean as IPayMeanServiceResponse);

  // const isCreditCard =
  //   selectedPaymean?.type?.toLowerCase() === EMethodCode?.CREDIT_CARD.toLowerCase();

  const { trackView } = useTracking({
    event: ['checkout_step9.0'],
    event_label: 'payment method pre-filled',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'payment method pre-filled');

  const {
    isSuccess: isSuccessUpdatePaymentMethod,
    isError: isErrorUpdatePaymentMethod,
    error: errorUpdatePaymentMethod,
    handleResetState: resetPutPaymentState,
  } = useUpdatePaymentMethodFlow(
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderItemOnBillPayment
      ?.billingOfferIdForChangePaymentMethod || [''],
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderActionId || '',
    !isSuccessChangePayment,
    customerFlow?.payment?.data?.orderPayment?.orderOnBillPayment?.availablePaymentMethods?.find(
      (availablePayment) =>
        availablePayment.type?.toLowerCase() === selectedPaymean?.type?.toLowerCase()
    ),
    ISIAFlowType.RECURRENT,
    'verify'
  );

  const isFamilyPlus = product?.isFamilyPlusProduct;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const paymentKeyCMS = isFamilyPlus
    ? API.CMS_GET_FAMILY_PLUS_PAYMENT
    : API.CMS_GET_PAYMENTS_MOBILE;
  const dataFromCms = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], paymentKeyCMS) as IPayments;
  const { title, subtitle, buttonLabel, yourIbanTitle, yourCreditCardTitle, expirationLabelTitle } =
    organizePaymentMethod(dataFromCms);
  const { titlePaymentError, messagePaymentError } = organizePaymentMethodError(dataFromCms);

  const handleEnableNextStep = () => {
    if (handleOnChangeEnableGoNext) handleOnChangeEnableGoNext(true);
  };

  const onChangePayment = () => {
    setIsLastCard(false);
    setTimeout(() => {
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'change paym method',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
      if (handleGoNextSlide) handleGoNextSlide();
    }, 300);
  };

  useEffect(() => {
    const resetPaymentData = () => {
      resetData(queryClient, ['payMean', 'authorize']);
    };
    if (errorUpdatePaymentMethod) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorMock('authorize', { url: API.AUTHORIZE, status: '500', statusText: '500' }),
        titlePaymentError,
        messagePaymentError,
        action,
        'Chiudi',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
        product?.removeCTC ? undefined : product?.paymentErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
    if (isErrorUpdatePaymentMethod) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorUpdatePaymentMethod || undefined,
        titlePaymentError,
        messagePaymentError,
        action,
        'Chiudi',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', action),
        product?.removeCTC ? undefined : product?.paymentErrorCallMeNow?.label,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorUpdatePaymentMethod]);

  useEffect(() => {
    if (isSuccessUpdatePaymentMethod && handleEnableNextStep) {
      setIsSuccessChangePayment(true);
      handleEnableNextStep();
      resetPutPaymentState?.();
    }
  }, [isSuccessUpdatePaymentMethod]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PAYMENT_METHOD);
    // localStorage.setItem(PAYMENT_PRESENT_CC_BANK_ACCONT, isCreditCard ? '1' : '2');
    setIsPreselectedCard(true);
    setIsLastCard(true);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  return (
    <PaymentMethod
      owningIndividual={owningData.owningIndividual}
      handleChangePayment={onChangePayment}
      handleGoPrevSlide={handleGoPrevSlide}
      payMean={selectedPaymean}
      showLoader={!isSuccessChangePayment}
      config={{
        title,
        subtitle,
        buttonLabel,
        titlePaymentError,
        messagePaymentError,
        yourIbanTitle,
        yourCreditCardTitle,
        expirationLabelTitle,
      }}
    />
  );
};

export default PaymentMethodCard;

