import { INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { useQuery } from 'react-query';
import { API } from '@vfit/shared/data-access';
import { ICMSOperatorsList, IPortInfoResponse } from './getPortabilityInfoMobile.models';
import {
  overrideOperatorsListMobile,
  reorderOperators,
  filterOperatorsMobile,
} from './portabilityNumber.utils';
import { IShoppingCartResponse } from '../shopping-cart';
import { errorMock } from '../../utils/utils';

export const portabilityInfoService = (cartId: string) =>
  nextClient.get(`${API.GET_PORTABILITY_INFO}/${cartId}`, {
    searchParams: {
      salesChannel: 'selfService',
      portInInfoType: 'ALL',
    },
  }) as Promise<IPortInfoResponse>;

export const useGetPortabilityInfoMobile = (
  CMSOperatorsList?: ICMSOperatorsList,
  CMSOrderList?: ICMSOperatorsList,
  CMSFilterList?: ICMSOperatorsList
) => {
  const shoppingCartSaved = localStorage.getItem('shoppingCart');
  const shoppingCart: IShoppingCartResponse =
    !!shoppingCartSaved &&
    shoppingCartSaved !== 'undefined' &&
    JSON.parse(shoppingCartSaved as string);

  const cartId = shoppingCart?.id;
  const queryEnabler = !!cartId;

  return useQuery(['getPortabilityInfo'], () => portabilityInfoService(cartId), {
    enabled: queryEnabler,
    onSuccess: (data: IPortInfoResponse) => {
      const filteredList = filterOperatorsMobile(data, CMSFilterList);
      const reorderedList = reorderOperators(filteredList, CMSOrderList);
      const overriddenData = overrideOperatorsListMobile(reorderedList, CMSOperatorsList);
      localStorage.setItem('portabilityInfo', JSON.stringify(overriddenData));
    },
    onError: (error: INextError) => {
      LoggerInstance.error('ERROR - getPortabilityInfo', error);
      localStorage.setItem('portabilityInfo', JSON.stringify(errorMock('getPortabilityInfo')));
    },
  });
};
