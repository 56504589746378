import { useEffect } from 'react';
import {
  CMS_CONFIG,
  useCmsConfig,
  IPersonalInfoCMS,
  IUserIdentityField,
} from '@vfit/consumer/data-access';
import { API, LoggerInstance, PAGES, setTrackLink, useTracking } from '@vfit/shared/data-access';
import { ConfirmIdentity } from '@vfit/shared/components';
import { ICommonData } from '@vfit/shared/models';
import { getUserInfo, getTagging } from '../../../../checkout.utils';
import { organizeConfirmIdentityCard } from './confirmIdentityApp.utils';
import { useCheckout } from '../../../../../iBuyMobile.context';

const ConfirmIdentityApp = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const { findCaller, setFindCaller } = useCheckout();
  const tagging = getTagging('confirm id', 'mobile');

  const { trackView } = useTracking({
    event: ['checkout_step1.1'],
    event_label: 'verify id',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'verify id');

  const handleSelect = (selectedItem: IUserIdentityField) => {
    setFindCaller({
      ...findCaller,
      selectedContactMedium: selectedItem.field?.trim() ? selectedItem : undefined,
    });
  };

  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const { descriptionCms, placeholderCms, titleCms, ctaLabelCms } =
    organizeConfirmIdentityCard(dataFromCms);

  const cardConfig = {
    getSelectedData: handleSelect,
    identityTypeItems: [
      findCaller.individualRef.email || '',
      findCaller.individualRef.msisdn || '',
    ],
    title: titleCms,
    description: descriptionCms,
    placeholder: placeholderCms,
    cta: {
      label: ctaLabelCms || '',
      action: () => LoggerInstance.debug('click from'),
    },
  };

  useEffect(() => {
    setFindCaller({
      ...findCaller,
      selectedContactMedium: undefined,
    });
  }, []);

  useEffect(() => {
    if (handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(
        !!(findCaller.selectedContactMedium?.field && findCaller.selectedContactMedium.fieldType)
      );
    }
  }, [findCaller.selectedContactMedium]);

  return <ConfirmIdentity data={cardConfig} />;
};

export default ConfirmIdentityApp;
