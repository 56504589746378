import type { SVGProps } from 'react';
import { memo } from 'react';

const VodafoneStoreIcon = (): JSX.Element => {
  return (
    <svg
      className="vodafoneStoreIcon"
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#e60000" />
      <path
        d="M17.4987 28.2488C23.942 28.2488 29.1654 23.2057 29.1654 16.9847C29.1654 10.7638 23.942 5.7207 17.4987 5.7207C11.0554 5.7207 5.83203 10.7638 5.83203 16.9847C5.83203 23.2057 11.0554 28.2488 17.4987 28.2488Z"
        fill="white"
      />
      <path
        d="M17.592 23.2645C14.3866 23.2748 11.0511 20.633 11.0371 16.3915C11.0297 13.5868 12.597 10.887 14.6006 9.2816C16.5548 7.71911 19.2317 6.71693 21.66 6.70898C21.9728 6.70898 22.2996 6.73283 22.4997 6.79879C20.3767 7.22398 18.6868 9.13139 18.6942 11.2955C18.6939 11.357 18.6986 11.4183 18.7082 11.4791C22.261 12.3144 23.8735 14.3839 23.8834 17.2474C23.8933 20.1109 21.5522 23.2518 17.592 23.2645Z"
        fill="#e60000"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(VodafoneStoreIcon);
