import { colors, pxToRem, fonts, breakpoints } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Container = styled.section`
  padding: ${pxToRem(23, 40)};
  border: 1px solid ${colors.$bebebe};
  border-radius: ${pxToRem(20)};
  flex-grow: 1;

  @media (min-width: ${breakpoints.tablet}) {
    min-width: 50%;
  }
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${pxToRem(16)};

  &:not(:first-of-type) {
    padding-top: ${pxToRem(16)};
    border-top: 1px solid ${colors.$262626};
  }
`;

export const Title = styled.span`
  font-family: ${fonts.light};
  font-size: ${pxToRem(20)};
  font-weight: 400;
  text-align: right;
`;

export const Value = styled.span`
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: right;

  p {
    margin: 0;
    font-size: 18px;
    line-height: 24px;

    &:not(:first-of-type) {
      margin: ${pxToRem(5, 0, 0)};
    }
  }
`;

export const BtnBox = styled.div`
  margin-top: ${pxToRem(16)};

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    max-width: 40%;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 50%;
  }

  a {
    line-height: ${pxToRem(21.7)};
    font-family: ${fonts.regular};
    font-size: ${pxToRem(14)};
  }
`;
