import { Table } from './Table';
import { TitleDescription } from './TitleDescription';
import { useProductCharacteristicsTable } from './productCharacteristicsTable.hook';
import * as S from './productCharacteristicsTable.style';
import { IProdCharacteristicsTable } from './productCharacteristics.models';

export const ProductCharacteristicsTable: React.FC<IProdCharacteristicsTable> = ({
  widgetsCharacteristics,
}) => {
  const { data } = useProductCharacteristicsTable(widgetsCharacteristics);

  return (
    <S.Wrapper>
      <TitleDescription {...data?.texts} />
      <Table {...data?.table} />
    </S.Wrapper>
  );
};
