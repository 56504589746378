import { useMutation, useQueryClient } from 'react-query';
import { INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IShoppingCartResponse } from '@vfit/consumer/data-access';
import {
  IFormat,
  IModifyMobileSimDevicePayload,
  IModifyMobileSimDeviceResponse,
  ISimTypeResponse,
} from '@vfit/shared/models';

export interface IUseModifyMobileSimDeviceService {
  simOption: IFormat;
}

/**
 * @param cartId
 * @param payload
 * @returns
 */
const modifyMobileSimDeviceService = (cartId, payload: IModifyMobileSimDevicePayload) =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.MODIFY_MOBILE_SIM_DEVICE}`, payload, {
    notJsonParsing: true,
  } as any);

/**
 * @returns
 */
export const useModifyMobileSimDeviceService = () => {
  const queryClient = useQueryClient();
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');

  return useMutation(
    'modifyMobileSimDevice',
    ({ simOption }: IUseModifyMobileSimDeviceService) => {
      const simInformation: ISimTypeResponse = getFromLocalStorageByKey('simSelection');
      const productCharacteristicId =
        simInformation?.orderItems?.[0]?.product?.childProduct?.[0]?.id;
      const cartId = shoppingCart?.id;
      const cartItemId = shoppingCart?.cartItem?.[0].id;
      const payload: IModifyMobileSimDevicePayload = {
        productsInOrders: [
          {
            orderItemId: cartItemId,
            mobileSimProduct: {
              type: 'mobileSimProduct',
              serialNumber: null,
              productCharacteristic: [
                {
                  id: productCharacteristicId,
                  name: 'formato SIM',
                  value: simOption.id,
                },
              ],
            },
          },
        ],
        actualTechnology: '',
      };
      localStorage.setItem(
        'modifyMobileSimDeviceId',
        JSON.stringify({ simOptionId: simOption?.id || '' })
      );
      return modifyMobileSimDeviceService(cartId, payload);
    },
    {
      onSuccess: (data: IModifyMobileSimDeviceResponse) => {
        localStorage.setItem('modifyMobileSimDevice', JSON.stringify(data));
        queryClient.setQueryData('modifyMobileSimDevice', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR on modifyMobileSimDevice: `, error);
        localStorage.setItem(`modifyMobileSimDevice`, JSON.stringify(error));
      },
    }
  );
};
