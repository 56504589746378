import React, { useCallback, useEffect, useState } from 'react';
import { VodafoneStore } from '@vfit/consumer/components';
import { SwitchToggle, CheckboxList, ButtonSlide, LinkWithIcon } from '@vfit/shared/atoms';
import { IOptionalText } from '@vfit/shared/models';
import { IStoreList } from './storeList.models';
import { trackLink } from '@vfit/shared/data-access';
import {
  StoreListContainer,
  Title,
  SubTitle,
  SelectedAddress,
  CtaBox,
  LabelSwitch,
  SwitchToggleContainer,
  AddressListContainer,
  ButtonContainer,
  WrapButton,
  MapContainer,
} from './storeList.style';
import {
  ILocateStoreStepper,
  PickupLocationsStepper,
} from '../StepperAddressModal/stepperAddressModal.model';
import { TextLoader } from '../StepperAddressModal/stepperAddressModal.style';
import { checkIsApp } from '@vfit/consumer/data-access';

const StoreList: React.FC<IStoreList> = ({ storeListData }) => {
  const [showMap, setShowMap] = useState(true);
  const [deliveryItemList, setDeliveryItemList] = useState<PickupLocationsStepper[] | []>([]);
  const [deliveryItemIndex, setDeliveryItemIndex] = useState<undefined | number>();
  const { deliveryCenterData, cardItem, addressType, position, isLoading } = storeListData;
  const fullAddress = `${position?.street} ${position?.streetNumber}, ${position?.city}`
    .toLowerCase()
    .concat(` ${position?.stateOrProvince.toUpperCase()}`);

  const resetDeliveryItemSelected = useCallback(() => {
    const deliveryItemListCopy = deliveryItemList;
    for (let i = 0; i < deliveryItemListCopy.length; i += 1) {
      deliveryItemListCopy[i].isSelected = false;
      deliveryItemListCopy[i].value = '';
    }
    setDeliveryItemList(deliveryItemListCopy);
  }, [deliveryItemList]);

  const selectDeliveryPoint = (index?: number) => {
    if (typeof index === 'number') {
      resetDeliveryItemSelected();
      const deliveryItemListCopy = deliveryItemList;
      deliveryItemListCopy[index].isSelected = true;
      // @ts-ignore
      deliveryItemListCopy[index].value += index;
      setDeliveryItemList(deliveryItemListCopy);
      setDeliveryItemIndex(index);
    }
  };

  const handlerSelectDeliveryPoint = (v: IOptionalText, index?: number) => {
    selectDeliveryPoint(index);
  };
  const handlerDeliveryPointFromMap = (index: number) => {
    selectDeliveryPoint(index);
  };

  useEffect(() => {
    const deliveryItem: PickupLocationsStepper[] | ILocateStoreStepper[] =
      deliveryCenterData?.map(
        (item): PickupLocationsStepper => ({
          ...(item as PickupLocationsStepper),
          title: (
            item as PickupLocationsStepper
          ).locationAddress.postalOfficeDescription.toLowerCase(),
          description: `${(
            item as PickupLocationsStepper
          ).locationAddress.formattedAddress1.toLowerCase()}</br> ${
            (item as PickupLocationsStepper).locationAddress.postalCode
          } - ${(item as PickupLocationsStepper).locationAddress.displayStateOrProvince}`,
          detail: `${(item as PickupLocationsStepper).aerialDistance.toPrecision(2)}km`,
        })
      ) || [];
    setDeliveryItemList(deliveryItem);
  }, [deliveryCenterData]);

  return (
    <StoreListContainer>
      <Title>{cardItem.title}</Title>
      <SubTitle>{cardItem.subTitle}</SubTitle>
      {isLoading && <TextLoader />}
      {!isLoading && <SelectedAddress>{fullAddress}</SelectedAddress>}
      <CtaBox>
        <LinkWithIcon onClick={cardItem.ctaAddress.action} text={cardItem.ctaAddress.label} />
        <SwitchToggleContainer>
          <LabelSwitch>{cardItem.switchLabelSx}</LabelSwitch>
          <SwitchToggle isActive={showMap} onSwitch={() => setShowMap((prevState) => !prevState)} />
          <LabelSwitch>{cardItem.switchLabelDx}</LabelSwitch>
        </SwitchToggleContainer>
      </CtaBox>
      {!isLoading &&
        (showMap ? (
          <AddressListContainer>
            <CheckboxList
              passedValueIndex={deliveryItemIndex}
              align="flex-start"
              items={deliveryItemList}
              onChangeItem={(v: IOptionalText, index) => handlerSelectDeliveryPoint(v, index)}
            />
          </AddressListContainer>
        ) : (
          <MapContainer>
            <VodafoneStore
              position={{
                lat: position?.latitude?.toString() || '',
                lng: position?.longitude?.toString() || '',
              }}
              items={deliveryItemList}
              type={addressType}
              onSelectItem={handlerDeliveryPointFromMap}
            />
          </MapContainer>
        ))}
      {isLoading && (
        <>
          <TextLoader />
          <TextLoader />
          <TextLoader />
        </>
      )}
      <WrapButton>
        <ButtonContainer>
          <ButtonSlide
            disabled={!(typeof deliveryItemIndex !== 'undefined')}
            isApp={checkIsApp()}
            borderColor="#262626"
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            label={cardItem.ctaDeliveryPoint.label}
            onClick={() => {
              trackLink(cardItem.ctaDeliveryPoint.label || '');
              if (typeof deliveryItemIndex !== 'undefined')
                cardItem.ctaDeliveryPoint.action(deliveryItemList[deliveryItemIndex]);
            }}
            name="action_store_list"
          />
        </ButtonContainer>
      </WrapButton>
    </StoreListContainer>
  );
};

export default StoreList;
