import { useEffect } from 'react';
import {
  errorMock,
  getCurrentUserType,
  getHubPath,
  ICampaignResponse,
  IFamilyPlusErrorType,
  IUserType,
  useCampaignAccept,
  useCampaignsInfoMobile,
  useGetShoppingCart,
  usePage,
} from '@vfit/consumer/data-access';
import { updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';
import { useCheckout } from '../../../iBuyMobile.context';

export const useFamilyPlus = () => {
  const {
    product,
    familyPlus,
    isStartFamilyPlus,
    isStartFamilyPlusAccept,
    setIsLoadingCart,
    setIsStartFamilyPlus,
    setIsStartAcceptFamilyPlus,
    setFamilyPlus,
    setProduct,
  } = useCheckout();
  const { products: allProducts, product: pageProduct } = usePage();
  // if product is familyplus from hub the familyplus id is the offerId else the familyPlusId in poduct configuration
  const familyPlusId = product?.isFamilyPlusProduct ? product?.offerId : product?.familyPlusId;
  const familyPlusProduct = allProducts?.find((el) => el.offerId.toString() == familyPlusId);
  const currentUserType = getCurrentUserType();
  const isProspectOrInactive =
    currentUserType === IUserType.PROSPECT_USER || currentUserType === IUserType.NEXT_USER_INACTIVE;
  const {
    data: campaignsData,
    isSuccess: isSuccessCampaigns,
    isError: isErrorCampaigns,
    isLoading: isLoadingCampaigns,
    refetch: refetchCampaignsInfoMobile,
  } = useCampaignsInfoMobile({ enabled: false });
  const {
    mutate: mutateAcceptCampaign,
    data: acceptCampaignData,
    isSuccess: isSuccessAcceptCampaign,
    isLoading: isLoadingAcceptCampaign,
    isError: isErrorAcceptCampaign,
  } = useCampaignAccept();
  const {
    isSuccess: isSuccessGetShoppingCart,
    isLoading: isLoadingGetShoppingCart,
    isError: isErrorGetShoppingCart,
    error: errorGetShoppingCart,
    refetch: getShoppingCartRefetch,
  } = useGetShoppingCart(familyPlus?.accept?.acceptId || '', { enabled: false });

  // region UPDATE CART

  useEffect(() => {
    if (isLoadingGetShoppingCart && isSuccessAcceptCampaign) {
      if (product?.isFamilyPlusProduct) setIsLoadingCart(true);
      setFamilyPlus({
        ...familyPlus,
        updateCart: {
          ...familyPlus.accept,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorGetShoppingCart) {
      if (product?.isFamilyPlusProduct) setIsLoadingCart(false);
      setFamilyPlus({
        ...familyPlus,
        updateCart: {
          ...familyPlus.accept,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock(
            'shoppingCart',
            errorGetShoppingCart,
            undefined,
            undefined,
            'Error get shopping cart for family plus',
            false,
            {
              familyPlusAcceptId: familyPlus?.accept?.acceptId,
            }
          ),
          errorType: IFamilyPlusErrorType.GET_SHOPPING_CART,
        },
      });
    } else if (isSuccessGetShoppingCart) {
      // if not is familyplus from hub
      // switch product
      if (!product?.isFamilyPlusProduct) {
        const hub = pageProduct ? getHubPath(pageProduct) : '';
        if (familyPlusProduct) {
          localStorage.setItem('tProd_backUp', JSON.stringify(product));
          setProduct({
            ...product,
            ...familyPlusProduct,
            ...(hub && { hubPath: hub }),
            ...updateProductPrice(familyPlusProduct),
            shoppingCartUrl: window.location.href,
          });
        }
      } else {
        // else update family plus price
        setProduct({
          ...product,
          ...updateProductPrice(product),
        });
        setIsLoadingCart(false);
      }
      setFamilyPlus({
        ...familyPlus,
        updateCart: {
          ...familyPlus.accept,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errorType: undefined,
        },
      });
    }
  }, [isLoadingGetShoppingCart, isErrorGetShoppingCart, isSuccessGetShoppingCart]);

  // endregion

  // region ACCEPT

  useEffect(() => {
    if (familyPlus?.accept?.acceptId && !isSuccessGetShoppingCart && !isLoadingGetShoppingCart)
      getShoppingCartRefetch();
  }, [familyPlus]);

  useEffect(() => {
    if (isLoadingAcceptCampaign) {
      setFamilyPlus({
        ...familyPlus,
        accept: {
          ...familyPlus.accept,
          isLoading: true,
          isSuccess: false,
          isAccepted: false,
          isError: false,
        },
      });
    } else if (isErrorAcceptCampaign) {
      setFamilyPlus({
        ...familyPlus,
        accept: {
          ...familyPlus.accept,
          isLoading: false,
          isSuccess: false,
          isAccepted: false,
          isError: true,
          errorType: IFamilyPlusErrorType.ACCEPT,
        },
      });
    } else if (isSuccessAcceptCampaign) {
      setFamilyPlus({
        ...familyPlus,
        accept: {
          ...familyPlus.accept,
          isLoading: false,
          isSuccess: true,
          isAccepted: true,
          isError: false,
          acceptId: acceptCampaignData?.orderId,
        },
        updateCart: {
          ...familyPlus.updateCart,
          isLoading: true,
        },
      });
    }
  }, [isLoadingAcceptCampaign, isErrorAcceptCampaign, isSuccessAcceptCampaign]);

  useEffect(() => {
    if (isStartFamilyPlusAccept && !familyPlus?.accept?.isAccepted) {
      setIsStartAcceptFamilyPlus(false);
      mutateAcceptCampaign({
        campaignId: familyPlus.campaign?.campaignId || '',
        sessionId: familyPlus.campaign?.sessionId || '',
      });
    }
  }, [isStartFamilyPlusAccept]);

  // endregion

  // region CAMPAIGNS

  const checkHasCampaign = () => {
    const foundedCampaign = campaignsData?.find(
      (item: ICampaignResponse) => item?.productOfferId?.toString() === familyPlusId?.toString()
    );
    setFamilyPlus({
      ...familyPlus,
      campaign: {
        ...familyPlus.campaign,
        isLoading: false,
        isError: false,
        isSuccess: true,
        isEligible: !!foundedCampaign,
        campaignId: foundedCampaign?.id?.toString() || '',
        sessionId: foundedCampaign?.sessionId?.toString() || '',
        ...(!!foundedCampaign && { familyPlusProduct }),
      },
    });
  };

  useEffect(() => {
    if (isLoadingCampaigns) {
      setFamilyPlus({
        ...familyPlus,
        campaign: {
          ...familyPlus.campaign,
          isSuccess: false,
          isError: false,
          isEligible: false,
          isLoading: true,
        },
      });
    } else if (isErrorCampaigns) {
      setFamilyPlus({
        ...familyPlus,
        campaign: {
          ...familyPlus.campaign,
          isLoading: false,
          isSuccess: false,
          isEligible: false,
          isError: true,
          errorType: IFamilyPlusErrorType.CAMPAIGNS,
        },
      });
    } else if (isSuccessCampaigns) {
      checkHasCampaign();
    }
  }, [isLoadingCampaigns, isErrorCampaigns, isSuccessCampaigns]);

  useEffect(() => {
    if (!isProspectOrInactive && isStartFamilyPlus) {
      setIsStartFamilyPlus(false);
      refetchCampaignsInfoMobile();
    }
  }, [isStartFamilyPlus]);

  // endregion

  return null;
};
