import { useMutation, useQueryClient } from 'react-query';
import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { nextClient, LoggerInstance, INextError } from '@vfit/shared/data-access';

export const deleteShoppingCartItemService = (cartId: string, cartItemId: string) =>
  nextClient.delete(
    `${API.SHOPPING_CART}/${cartId}/${API.CART_ITEM}/${cartItemId}`
  ) as Promise<IShoppingCartResponse>;

export const useDeleteItemShoppingCart = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'deleteShoppingCart',
    (cartItemId: string) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      return deleteShoppingCartItemService(cartId, cartItemId);
    },
    {
      onSuccess: (data: IShoppingCartResponse) => {
        localStorage.setItem('deleteShoppingCart', JSON.stringify(data));
        queryClient.setQueryData('deleteShoppingCart', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - deleteShoppingCart: `, error);
        queryClient.setQueryData('deleteShoppingCart', errorMock(`deleteShoppingCart`, error));
        localStorage.setItem(
          `deleteShoppingCart`,
          JSON.stringify(errorMock(`deleteShoppingCart`, error))
        );
      },
    }
  );
};
