import { useState, useCallback } from 'react';
import { debounce } from 'lodash-es';
import { checkWindow, isAcceptedCookieLawGroup } from '@vfit/shared/data-access';
import { IOnTrack } from '@vfit/shared/models';
import { handleEnv, LoggerInstance } from '@vfit/shared/data-access';
import { IUseTrackingHeroSliderConsumer } from './heroSliderConsumer.models';

const useTrackingHeroSliderConsumer: IUseTrackingHeroSliderConsumer = () => {
  const [prevSlide, setPrevSlide] = useState<number | null>(null);

  const debouncedOnTrack = useCallback(
    debounce((s) => {
      const acceptedCookie =
        handleEnv('NODE_ENV') === 'development' ? true : isAcceptedCookieLawGroup('2');
      if (!acceptedCookie || !checkWindow()) return;
      if (!window?.['_uxa']) window['_uxa'] = window?.['_uxa'] || [];
      setTimeout(() => {
        try {
          const uxa = window?.['_uxa'];
          LoggerInstance.log('Push uxa', {
            category: 'homepage',
            name: `viewSlide${s.id}`,
            type: 'scroll',
          });
          if (prevSlide !== s.id) {
            uxa?.push?.(['trackPageEvent', `viewSlide${s.id}`]);
            setPrevSlide(s.id);
          }
        } catch (e) {
          LoggerInstance.log('Error uxa', e);
        }
      }, 1000);
    }, 100),
    [prevSlide]
  );

  const onTrack: IOnTrack = (s) => {
    debouncedOnTrack(s);
  };

  return {
    onTrack,
  };
};

export { useTrackingHeroSliderConsumer };
