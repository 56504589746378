import { ButtonSlide } from '@vfit/shared/atoms';
import { Form } from '@vfit/shared/components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { API, PAGES } from '@vfit/shared/data-access';
import { CMS_CONFIG, useCmsConfig, IPersonalInfoCMS, checkIsApp } from '@vfit/consumer/data-access';
import { IEditNumberProps, IPhoneNumber } from './editNumber.models';
import { IEditNumberSchema } from './editNumber.validation';
import { ButtonDiv, Container, Div, Subtitle, Title } from './editNumber.style';
import { organizeEditNumber } from './editNumber.utils';

const EditNumber = ({ onSubmit, newNumber }: IEditNumberProps) => {
  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const { title, subtitle, titleNew, buttonText, lable } = organizeEditNumber(dataFromCms);

  const {
    register,
    getValues,
    formState: { isValid, errors },
  } = useForm<IPhoneNumber>({
    resolver: yupResolver(IEditNumberSchema),
    mode: 'onChange',
  });

  const onFormSubmit = () => {
    const data = getValues();
    onSubmit(data);
    // handleClose();
  };

  return (
    <Container>
      <Title>{newNumber ? titleNew : title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Div>
        <div className="text-input">
          <Form.TextInput
            label={lable}
            error={errors.phoneNumber?.message}
            placeholder=" "
            {...register('phoneNumber')}
          />
        </div>

        <ButtonDiv>
          <ButtonSlide
            label={buttonText}
            onClick={onFormSubmit}
            disabled={!isValid}
            borderColor="#0d0d0d"
            isApp={checkIsApp()}
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            name="action_editNumber"
          />
        </ButtonDiv>
      </Div>
    </Container>
  );
};

export default EditNumber;
