import { useGetFindCaller, ErrorCodes } from '@vfit/consumer/data-access';
import { IFiscalCodeReverse, computeInverse } from '@vfit/shared/data-access';
import { useEffect, useState } from 'react';

interface IUseFiscalCodeFlow {
  ageLimit?: number;
  fiscalCode: string;
  owningDataIsValid: boolean;
  isSiaError?: boolean;
  isFlowEnabled?: boolean;
  isWinbackFlow?: boolean;
}

export const useFiscalCodeFlow = ({
  isFlowEnabled = true,
  fiscalCode,
  owningDataIsValid,
  ageLimit = 18,
  isWinbackFlow = false,
}: IUseFiscalCodeFlow): {
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  getFindCallerResponse: any;
  errorMessage: string;
  errorCode: string;
  resetShoppingCart: () => void;
  isInResetCustomer: boolean;
} => {
  // const queryClient = useQueryClient();
  const [isSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [errorCode, setErrorCode] = useState<string>('');
  const [fiscalCodeIsValid, setFiscalCodeIsValid] = useState(false);
  const [isInResetCustomer, setIsInResetCustomer] = useState(false);

  /*
 Getting all the query related o check the responses data
*/
  // const offeringServiceability = getDataFromStorage<IOfferingServiceabilityResponse>('offeringServiceability');
  // const createdAddress = getDataFromStorage<IAddressResponse>('createdAddress');
  // const fiscalCodeResponse =
  //   getDataFromStorage<IFiscalCodeBirthPlaceResponse>('fiscalCodeBirthPlace');
  // const shoppingCart = getDataFromStorage<IShoppingCartResponse>('shoppingCart');
  // const checkout = getDataFromStorage<ICheckoutResponse>('checkout')
  const cfOld = localStorage.getItem('customerDataCf');

  /*
 Enabling API calls only if the fiscal code is a valid one
*/
  const isFiscalCodeValid = (): boolean => !!(fiscalCode && fiscalCode.length === 16);

  /*
 Firing useGetFiscalCodeInfo API calls only if the fiscal code is a valid and owningDataIsValid
*/
  // const {
  //   data: fiscalCodeData,
  //   isSuccess: fiscalCodeIsSuccess,
  //   isError: fiscalCodeIsError,
  //   isLoading: fiscalCodeIsLoading,
  // } = useGetFiscalCodeInfo(fiscalCode, {
  //   enabled: owningDataIsValid && fiscalCodeIsValid && isFiscalCodeValid(),
  // });

  /*
 Firing useGetFindCaller API calls only if the fiscal code is a valid and owningDataIsValid and fiscalCode length equal 16
*/
  const {
    data: getFindCallerResponse,
    isLoading: isLoadingGetFindCaller,
    isError: isErrorFindCaller,
  } = useGetFindCaller(
    {
      ...(isWinbackFlow && { isWinbackFlow }),
      fiscalCode: fiscalCode?.toUpperCase(),
    },
    {
      enabled: owningDataIsValid && fiscalCodeIsValid && fiscalCode.length === 16 && isFlowEnabled,
    }
  );

  /*
 Check fiscal code error
*/
  const checkFiscalCode = () => {
    const cfData: IFiscalCodeReverse | null = computeInverse(fiscalCode) as IFiscalCodeReverse;

    const getAge = (birthDateString) => {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        // eslint-disable-next-line no-plusplus
        age--;
      }
      if (age === ageLimit) {
        if (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate()) {
          return age - 1;
        }
        if (today.getMonth() < birthDate.getMonth()) {
          return age - 1;
        }
        return age;
      }
      return age;
    };
    const day = cfData?.day;
    // eslint-disable-next-line no-unsafe-optional-chaining
    const year = +cfData?.year;
    const month = cfData?.month;
    const age = getAge(`${month}/${day}/${year}`);

    if (!cfData) {
      setErrorCode(ErrorCodes.FISCAL_CODE_ERROR);
      setIsError(false);
      setFiscalCodeIsValid(false);
    } else if (!cfData.year || age < ageLimit) {
      setErrorCode(ErrorCodes.FISCAL_CODE_UNDERAGE);
      setIsError(true);
      setFiscalCodeIsValid(false);
    } else {
      setErrorCode('');
      setIsError(false);
      setFiscalCodeIsValid(true);
    }
  };

  /*
 Custom method to reset silentLogin authentication token due to current customer updating
*/
  const resetShoppingCart = () => {
    // sessionStorage.removeItem('uxfauthorization');
    // const uxfauthorization = sessionStorage.getItem('uxfauthorization');
    // if (!uxfauthorization) {
    //   shoppingCartMutation.mutate({createdAddress, offeringServiceability} as {
    //     createdAddress: IAddressResponse;
    //     offeringServiceability: IOfferingServiceabilityResponse;
    //   });
    // }
  };

  /*
 Handle the error reset on fiscalCode/owningDataIsValid's changing
*/
  useEffect(() => {
    setErrorCode('');
    setIsError(false);
    setIsLoading(false);
    setFiscalCodeIsValid(false);
    if (owningDataIsValid && isFiscalCodeValid()) {
      checkFiscalCode();
    }
  }, [fiscalCode, owningDataIsValid]);

  // useEffect(() => {
  //   if (shoppingCartMutation.isSuccess && !!shoppingCartMutation?.data)
  //     deleteShoppingCartMutation.mutate(shoppingCartMutation.data);
  // }, [!!shoppingCartMutation?.data, shoppingCartMutation.isSuccess]);
  //
  // useEffect(() => {
  //   if (deleteShoppingCartMutation.isSuccess && !!deleteShoppingCartMutation?.data && !checkoutMutation.isLoading && !checkoutMutation.isSuccess) checkoutMutation.mutate(shoppingCartMutation?.data?.id);
  // }, [!!deleteShoppingCartMutation?.data, deleteShoppingCartMutation.isSuccess]);
  //
  // useEffect(() => {
  //   if (checkoutMutation.isSuccess && !!checkoutMutation?.data) {
  //     setIsLoading(false);
  //     setIsSuccess(true);
  //     setIsError(false);
  //   }
  // }, [!!checkoutMutation?.data]);

  /*
Firing the checkout mutation API call on page rerender
*/
  // useEffect(() => {
  //   //setIsLoading(true); TODO RA:discuss about
  //   if (!isSiaError && !!shoppingCart && !checkoutMutation.isSuccess && !checkoutMutation.isLoading && !checkout) checkoutMutation.mutate(undefined);
  // }, [!!shoppingCart]);

  /*
Reset query and authentication token on current customer updating
 */
  useEffect(() => {
    if (getFindCallerResponse && cfOld && fiscalCode && fiscalCode !== cfOld) {
      setIsInResetCustomer(true);
      // resetShoppingCart();
    }
  }, [getFindCallerResponse]);

  /*
Error and Success handling on useFiscalCodeFlow response
*/
  useEffect(() => {
    if (isLoadingGetFindCaller) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoadingGetFindCaller]);

  useEffect(() => {
    if (isErrorFindCaller) {
      setErrorCode(ErrorCodes.FISCAL_CODE_ERROR);
      setIsError(true);
      setFiscalCodeIsValid(false);
    }
  }, [isErrorFindCaller]);

  return {
    isSuccess,
    isLoading,
    isError,
    getFindCallerResponse,
    errorMessage,
    errorCode,
    resetShoppingCart,
    isInResetCustomer,
  };
};
