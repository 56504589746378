import { setTrackLink } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { IDevice } from '@vfit/consumer/data-access';

export const useTaggingDeviceList = (devices: IDevice[]) => {
  const queryClient = useQueryClient();
  const pageProduct = SoftManagerService(queryClient).getSoftDevicesByDevices(
    devices?.filter((device) => device?.devicedetail?.isSellable) || []
  );
  const { trackView } = useTracking({
    event: ['product_view'],
    opts: {
      event_category: 'sales',
      page_section: 'device',
      page_subsection: 'catalogo',
      page_type: 'device',
      journey_type: 'task',
    },
    pageProduct,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
    disableInitialTrack: true,
  });
  setTrackLink(trackView);
};
