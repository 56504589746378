import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { IGenerateOtpPayload, IGenerateOtpResponse } from './generateOTP.models';

/**
 * Methods to invoke the POST /generate service
 * @param payload
 * @param customOptions
 * @returns
 */
const generateOTPService = (
  payload: IGenerateOtpPayload,
  customOptions?: CustomOptions
): Promise<IGenerateOtpResponse> =>
  nextClient.post(API.GENERATE, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Mutation to generate the OTP code
 * @returns
 */
export const useGenerateOTP = (
  moduleName = 'customer-SelfService',
  customOptions?: CustomOptions
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'generateOTP',
    (data: IGenerateOtpPayload) => {
      LoggerInstance.debug('useGenerateOTP data ', data);

      const payload = {
        moduleName,
        ...data,
      };
      LoggerInstance.debug('useGenerateOTP payload ', payload);

      return generateOTPService(payload, customOptions);
    },
    {
      onSuccess: (data: IGenerateOtpResponse) => {
        localStorage.setItem('generateOTP', JSON.stringify(data));
        queryClient.setQueryData('generateOTP', data);
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - generateOTP: `, error);
        queryClient.setQueryData('generateOTP', errorMock(`generateOTP`, error));
        localStorage.setItem(`generateOTP`, JSON.stringify(errorMock(`generateOTP`, error)));
        customOptions?.onError?.(error);
      },
    }
  );
};

