import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const Container = styled.div`
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 492px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 40px;
    height: 737px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 74px;
  }
`;

export const TextLoaderEmpty = styled.div`
  height: 30px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;
