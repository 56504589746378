import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  margin: 0 32px;

  @media (max-width: ${breakpoints.tablet}) {
    height: auto;

    // overflow-x: hidden;
    max-height: calc(100vh - 230px);
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 64px;
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    width: 616px;
  }
`
