import { Accordion, ImageAtoms, Divider, CustomText } from '@vfit/shared/atoms';
import {
  AccordionWrap,
  Content,
  Description,
  DetailsContainer,
  Img,
  P,
  Title,
} from '../detailsModals.style';
import { ITechinologyDetailsProps } from './technologyDetails.models';

const TechnologyDetails = (OrganizedModals: ITechinologyDetailsProps) => {
  const { title, imageSrc, firstDescription, secondDescription, subtitle, accordions } =
    OrganizedModals;
  return (
    <DetailsContainer>
      <Content>
        <Title>
          <CustomText text={title} />
        </Title>
        <P>
          <CustomText text={subtitle} />
        </P>
        <Divider marginBottom={0.5} />
        <Description>
          <CustomText text={firstDescription} />
        </Description>
        <Img>
          <ImageAtoms nameCard="techDetail" image={imageSrc} />
        </Img>
        <Description>
          <CustomText text={secondDescription} />
        </Description>
        <AccordionWrap>
          {accordions.map((accordion) => (
            <Accordion
              key={Math.random().toString()}
              title={accordion.title}
              content={[
                <CustomText
                  size={16}
                  lineHeight={22}
                  modal
                  textAlign="start"
                  text={accordion.description}
                />,
              ]}
              fontSize="18px"
              textColor="#0d0d0d"
              iconColor="#e60000"
            />
          ))}
        </AccordionWrap>
      </Content>
    </DetailsContainer>
  );
};

export default TechnologyDetails;

