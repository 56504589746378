import { useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { setTrackLink, setTrackView } from '@vfit/shared/data-access';
import { removeCurrency } from '@vfit/consumer/hooks';
import { retrieveProductTechnology } from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';

export const useCoverageToolOtpTagging = () => {
  const queryClient = useQueryClient();
  const { product } = useCoverageToolModalProvider();
  const pageProduct = {
    product_category: 'fixed',
    product_id: `${product?.offerId}`,
    product_name: product?.slug,
    product_price: product?.price && removeCurrency(product.price),
    product_quantity: '1',
  };

  const opts = {
    event_category: 'journey',
    page_subsection: 'lock-in user otp',
    page_section: 'fixed offers',
    page_type: 'OTP',
    journey_name: 'verify lock-in',
    product_technology: retrieveProductTechnology(),
  };
  const { trackView } = useTracking({
    event: ['journey'],
    event_label: 'lock-in OTP',
    opts,
    pageProduct,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
  });
  setTrackLink(trackView, 'lock-in OTP');
  setTrackView(trackView);
};
