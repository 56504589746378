import { ErrorCodes, IPersonalInfoCmsMobile, IPortabilityMobileCMS, IProduct } from '@vfit/consumer/data-access';
import { ErrorDetail } from '@vfit/shared/data-access';
import { IGenericError } from '@vfit/shared/models';

export const checkError = (
  errorCode: string,
  handleOnClose: (() => void) | undefined,
  errorMessge: IGenericError
): ErrorDetail => {
  const title = errorMessge?.title || '';
  let message = errorMessge?.description || '';
  const actionText = errorMessge?.buttonLabel || '';
  let onClose: (() => void) | undefined = handleOnClose;
  let actionEvent: (() => void) | undefined = handleOnClose;
  if (errorCode === ErrorCodes.FISCAL_CODE_UNDERAGE) {
    message = 'Devi essere maggiorenne per proseguire';
    onClose = undefined;
    actionEvent = undefined;
  }
  if (errorCode === ErrorCodes.FISCAL_CODE_ERROR) {
    message = 'Il codice fiscale non è valido. Inserisci un codice fiscale valido per proseguire';
    onClose = undefined;
    actionEvent = undefined;
  }
  return {
    title,
    message,
    actionText,
    onClose,
    actionEvent,
  };
};

export const organizeNameFiscalCodeMobile = (
  nameFiscalCodeCms: IPersonalInfoCmsMobile,
  product?: IProduct | null
) => ({
  title: nameFiscalCodeCms?.personalinfomobile?.firstCard?.title || '',
  description: nameFiscalCodeCms?.personalinfomobile?.firstCard?.description || '',
  firstNameLable: nameFiscalCodeCms?.personalinfomobile?.selectInput?.firstName || '',
  lastNameLable: nameFiscalCodeCms?.personalinfomobile?.selectInput?.lastName || '',
  fiscalCodeLable: nameFiscalCodeCms?.personalinfomobile?.selectInput?.fiscalCode || '',
  buttonLabel: nameFiscalCodeCms?.personalinfomobile?.firstCard?.fcMissingLabel || '',
  legacyPopup: {
    legacyPopupIsOpen: true,
    legacyPopupTitle:
      product?.commonOverride?.legacyCtc?.title ||
      nameFiscalCodeCms?.personalinfomobile?.clientRecognition?.redirect?.title ||
      '',
    legacyPopupMessage:
      product?.commonOverride?.legacyCtc?.description ||
      nameFiscalCodeCms?.personalinfomobile?.clientRecognition?.redirect?.description ||
      '',
    legacyPopupActionText:
      product?.commonOverride?.legacyCtc?.buttonLabel ||
      nameFiscalCodeCms?.personalinfomobile?.clientRecognition?.redirect?.buttonLabel ||
      '',
    legacyPopupUrl:
      product?.commonOverride?.legacyCtc?.url ||
      nameFiscalCodeCms?.personalinfomobile?.clientRecognition?.redirect?.url ||
      '',
  },
});

export const organizePrsonalInfoMobile = (dataFromCms: IPersonalInfoCmsMobile) => ({
  title: dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.title || 'Dati personali',
  description:
    dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.description ||
    'Queste sono le tue informazioni personali.',
  bottomText:
    dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.bottomText ||
    'Vuoi effettuare l’ordine per un’altra persona?',
  lableName:
    dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.labelName ||
    'Il tuo nome e cognome',
  lableFiscalCode:
    dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.lableFiscalCode ||
    'Il tuo codice fiscale',
  action: dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.textAction || 'Logout',
  url:
    dataFromCms?.personalinfomobile?.clientLogged?.personalInfo?.url ||
    'https://www.vodafone.it/area-utente/fai-da-te/Common/PaginaUnicadiLogin.html',
});

export const organizeRetriveFiscalCodeMobile = (nameFiscalCodeCms: IPersonalInfoCmsMobile, portabilityCardCms: IPortabilityMobileCMS) => ({
  retriveFiscalCode: {
    title: nameFiscalCodeCms?.personalinfomobile?.fiscalCode?.title || '',
    description: nameFiscalCodeCms?.personalinfomobile?.fiscalCode?.description || '',
    firstName: nameFiscalCodeCms?.personalinfomobile?.selectInput?.firstName || '',
    lastName: nameFiscalCodeCms?.personalinfomobile?.selectInput?.lastName || '',
    birthDate: nameFiscalCodeCms?.personalinfomobile?.selectInput?.birthdate || '',
    gender: nameFiscalCodeCms?.personalinfomobile?.selectInput?.gender || '',
    nationality: nameFiscalCodeCms?.personalinfomobile?.selectInput?.country || '',
    birthPlace: nameFiscalCodeCms?.personalinfomobile?.selectInput?.city || '',
    birthNation:
      nameFiscalCodeCms?.personalinfomobile?.selectInput?.birthNation || 'Nazione di nascita',
    errorMessage: nameFiscalCodeCms?.personalinfomobile?.firstCard?.allFieldsAreRequired || '',
    buttonLabel: nameFiscalCodeCms?.personalinfomobile?.fiscalCode?.buttonLabel || '',
  },
  nameFiscalCodeCard: {
    title: nameFiscalCodeCms?.personalinfomobile?.firstCard?.title || '',
    titleReadOnly:
      nameFiscalCodeCms?.personalinfomobile?.firstCard?.titleReadOnly ||
      nameFiscalCodeCms?.personalinfomobile?.firstCard?.title ||
      '',
    description: nameFiscalCodeCms?.personalinfomobile?.firstCard?.description || '',
    descriptionReadOnly:
      nameFiscalCodeCms?.personalinfomobile?.firstCard?.descriptionReadOnly ||
      nameFiscalCodeCms?.personalinfomobile?.firstCard?.description ||
      '',
    firstNameLable: nameFiscalCodeCms?.personalinfomobile?.selectInput?.firstName || '',
    lastNameLable: nameFiscalCodeCms?.personalinfomobile?.selectInput?.lastName || '',
    fiscalCodeLable: nameFiscalCodeCms?.personalinfomobile?.selectInput?.fiscalCode || '',
    buttonLabel: nameFiscalCodeCms?.personalinfomobile?.firstCard?.fcMissingLabel || '',
  },
  howToModalCard: {
    title: portabilityCardCms?.portabilitymobile?.howToModal?.label || '',
    description: portabilityCardCms?.portabilitymobile?.howToModal?.description || '',
  },
});
