import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const BackgroundDiv = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: -10;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 24px 16px;
  animation: 1s slidein;

  @keyframes slidein {
    0% {
        margin-top: 100%;
        opacity: 0;
      }
    
    100% {
        margin-top: 0%;
        opacity: 1;
      }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
  width: 100%;
  gap: 16px;
  padding: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 20%);
  border-radius: 6px;
  background: ${colors.$ffffff};

  @media (min-width: ${breakpoints.tablet}) {
    width: 600px;
    padding: 16px 24px 32px;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
`;

export const ModalTitle = styled.p`
  display: flex;
  margin: 0 0 24px;
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 30)};
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 0 48px;
    ${pxToCssFont(40, 48)};
  }
`;

export const ModalButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  div {
    width: 100%;
  }

  button {
    border-radius: 4px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 280px;
  }
`;
