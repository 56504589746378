import { useEffect } from 'react';
import {
  checkIsGoBackSIA,
  CMS_CONFIG,
  errorMock,
  IPersonalInfoCmsMobile,
  IShoppingCartResponse,
  useCmsConfig,
  useSimTypeSelectionService,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey, PAGES } from '@vfit/shared/data-access';
import { IFormat } from '@vfit/shared/models';
import { useCheckout } from '../../../iBuyMobile.context';

export const useCartAsyncInformation = () => {
  const {
    isLoadingCart,
    setSimOptions,
    setCartAsyncInfo,
    cartAsyncInfo,
    setSimTypeSelectedOption,
  } = useCheckout();
  const simDataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const {
    mutate: mutateSim,
    isError: isErrorSim,
    isLoading: isLoadingSim,
    isSuccess: isSuccessSim,
    data: dataSim,
    error: dataSimError,
  } = useSimTypeSelectionService();

  // region SIM TYPE SELECTION

  const updateSimOptions = () => {
    const simProduct = dataSim?.orderItems?.find(
      (orderItem) => orderItem?.product?.type?.toLowerCase() === 'mobile'
    );
    const allSimOptions = simProduct?.product?.childProduct?.[0]?.format || [];
    const simOptionsData = {
      simOptions:
        allSimOptions?.map((simOption) => {
          const isEsim = simOption?.name?.toLowerCase()?.includes('esim');
          if (isEsim) {
            return {
              ...simOption,
              title:
                simDataFromCms?.personalinfomobile?.simType?.choice?.[0]?.title ||
                'Si, voglio una eSIM',
              value: 'eSim',
            };
          }
          return {
            ...simOption,
            title:
              simDataFromCms?.personalinfomobile?.simType?.choice?.[1]?.title ||
              'No, mantengo la SIM fisica',
            value: 'not eSim',
          };
        }) || [],
    };
    setSimOptions(simOptionsData);
    const selectedOption: IFormat | undefined = allSimOptions?.find((el) => el.selection);
    if (selectedOption) {
      setSimTypeSelectedOption(selectedOption);
    }
  };

  useEffect(() => {
    if (isSuccessSim && dataSim) {
      updateSimOptions();
    }
  }, [dataSim, isSuccessSim]);

  useEffect(() => {
    if (isLoadingSim) {
      setCartAsyncInfo({
        ...cartAsyncInfo,
        simTypeSelection: {
          error: undefined,
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      });
    } else if (isErrorSim) {
      setCartAsyncInfo({
        ...cartAsyncInfo,
        simTypeSelection: {
          error: errorMock('simSelection', dataSimError),
          isLoading: false,
          isError: true,
          isSuccess: false,
        },
      });
    } else if (isSuccessSim) {
      setCartAsyncInfo({
        ...cartAsyncInfo,
        simTypeSelection: {
          error: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      });
    }
  }, [isErrorSim, isLoadingSim, isSuccessSim]);

  useEffect(() => {
    if (!isLoadingCart && !checkIsGoBackSIA()) {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const simSelection = getFromLocalStorageByKey('simSelection');
      if (!simSelection && shoppingCart?.id) {
        mutateSim({ cartId: shoppingCart.id });
      }
    }
  }, [isLoadingCart]);

  // endregion

  return null;
};
