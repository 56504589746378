import {
  IAddressDataObject,
  IIdentificationDetails,
  ILocateStoreResponse,
  IPickUpLocation,
} from '@vfit/shared/models';

export interface IMutationPayload {
  shippingAddress?: IAddressDataObject;
  pickupPoint?: IPickUpLocation | ILocateStoreResponse;
  identificationMethod?: IIdentificationDetails[] | IIdentificationDetails;
}

export interface IPostDeliveryPayload {
  shippingAddress?: IAddressDataObject;
  pickupPoint?: IPickUpLocation | ILocateStoreResponse;
}

export interface IReplaceBillingAddressOrderPayload {
  billingAccountId?: string;
  billingAddressId?: string;
  billingAddressType?: string;
}

export interface IDeliveryItem {
  itemId: string;
  itemCid: string;
  itemType: string;
  itemName: string;
  lineOfBusiness: string;
  details?: any;
}

export interface IParameters {
  salesChannel: string;
  filter: string;
  lob: string;
  identificationMethod?: string;
  mode?: string;
  customerId?: string;
  addressId?: string;
  deliveryMethod?: string;
  orderActionsInput?: string;
}

export enum KEY {
  isBase = 'IsBase',
  isBaseMobile = 'IsBaseMobile',
  isAdvanced = 'IsAdvanced',
  IsAdvancedMobile = 'IsAdvancedMobile',
  isPost = 'IsPost',
  isEvolved = 'IsEvolved',
  IsEvolvedMobile = 'IsEvolvedMobile',
  isEditAddress = 'IsEditAddress',
  IsUltraMobile = 'IsUltraMobile',
  ShippingMethodCode = 'deliveryShippingMethodCode',
}
