import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { Player } from '@lottiefiles/react-lottie-player';
import {
  ProductSolutionTextLoader,
  ProductSolutionContainerText,
} from './productSolutionLoader.style';

interface IProductSolutionLoader {
  lottieFile?: string;
}

const ProductSolutionLoader = ({ lottieFile }: IProductSolutionLoader) => (
  <>
    {!lottieFile && (
      <>
        <ProductSolutionTextLoader />
        <ProductSolutionTextLoader />
      </>
    )}
    {lottieFile && (
      <ProductSolutionContainerText>
        <Player
          src={requireStaticImageAtomComponent(lottieFile).src}
          className="player"
          loop
          autoplay
        />
      </ProductSolutionContainerText>
    )}
  </>
);

export default ProductSolutionLoader;

