import React from 'react';
import { Button, Divider, Input, Price } from '@vfit/shared/atoms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CMS_CONFIG, IVoucherInsertionData, useCmsConfig } from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import {
  IVoucherInsertionSchema,
  IVoucherInsertionManagmentProps,
} from './voucherInsertionManagment.models';
import {
  FieldsContainer,
  StyledDivider,
  StyledProductName,
  StyledVoucherApplyDescription,
  VoucherInsertionManagmentCard,
  VoucherLeftCard,
  VoucherRightTitle,
} from './voucherInsertionManagment.style';
import { voucherInsertionSchema } from './voucherInsertionManagment.validation';
import { organizeVoucherInsertion } from './voucherInsertionManagment.organize';

const VoucherInsertionManagment: React.FC<IVoucherInsertionManagmentProps> = () => {
  const {
    register,
    formState: { isValid, errors },
  } = useForm<IVoucherInsertionSchema>({
    resolver: yupResolver(voucherInsertionSchema()),
    mode: 'all',
  });
  const voucherInsertionData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_VOUCHER_INSERTION_MANAGMENT_CONF
  ) as IVoucherInsertionData;

  const purifiedLabel = organizeVoucherInsertion(
    voucherInsertionData?.vouchersinsertion?.voucherSpecs
  );

  const { isDesktop } = useDeviceType();
  const renderFields = () => (
    <FieldsContainer>
      <Input
        label={voucherInsertionData?.vouchersinsertion?.voucherApply?.inputPlaceholder}
        error={errors?.discountCode?.message}
        {...register('discountCode')}
      />
      <Button variant="primary" disabled={!isValid} onClick={() => {}}>
        {voucherInsertionData?.vouchersinsertion?.voucherApply?.buttonConfirm}
      </Button>
      <Divider marginBottom={1} />
      <Button variant="secondary" disabled={!isValid} onClick={() => {}}>
        {voucherInsertionData?.vouchersinsertion?.voucherApply?.ctc?.label}
      </Button>
      <Divider marginBottom={1} />
      <Button variant="secondary" disabled={!isValid} onClick={() => {}}>
        {voucherInsertionData?.vouchersinsertion?.voucherApply?.showDetailButton}
      </Button>
    </FieldsContainer>
  );

  return (
    <VoucherInsertionManagmentCard>
      <VoucherLeftCard>
        <h5>{voucherInsertionData?.vouchersinsertion?.voucherSpecs?.voucherRemaning}</h5>
        <StyledProductName>{purifiedLabel?.purifiedProductName}</StyledProductName>
        {purifiedLabel?.purifiedPriceDetail && (
          // eslint-disable-next-line react/no-danger
          <h3 dangerouslySetInnerHTML={{ __html: purifiedLabel?.purifiedPriceDetail || '' }} />
        )}
        <Price
          variant="details"
          label={isDesktop ? 'Price top label' : ''}
          oldAmount={voucherInsertionData?.vouchersinsertion?.voucherSpecs?.oldPrice}
          amount={`${voucherInsertionData?.vouchersinsertion?.voucherSpecs?.newPrice}/mese`}
          specs={voucherInsertionData?.vouchersinsertion?.voucherSpecs?.specifications}
          detail={voucherInsertionData?.vouchersinsertion?.voucherSpecs?.priceDetails}
        />
        {!isDesktop && (
          <>
            <Divider marginBottom={2} />
            {renderFields()}
          </>
        )}
      </VoucherLeftCard>
      <StyledDivider />
      <div>
        <VoucherRightTitle>
          {voucherInsertionData?.vouchersinsertion?.voucherApply?.title}
        </VoucherRightTitle>
        {purifiedLabel?.purifiedDescription && (
          <StyledVoucherApplyDescription
            dangerouslySetInnerHTML={{ __html: purifiedLabel?.purifiedDescription }}
          />
        )}
        {isDesktop && renderFields()}
      </div>
    </VoucherInsertionManagmentCard>
  );
};

export default VoucherInsertionManagment;

