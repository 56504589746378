import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

const basicGap = '16px';
const doubleGap = '32px';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 287px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 366px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const Title = styled.div`
  padding: 2rem 0 1rem;
  font-family: ${fonts.exbold};
  text-align: center;
  font-weight: 400;
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    padding: 3.75rem 0 1rem;
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Text = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  margin-top: ${basicGap};
  margin-bottom: ${doubleGap};
  text-align: center;
  font-weight: 400;
  ${pxToCssFont(20, 26)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const TextALignLeft = styled(Text)`
  text-align: left;
  margin: 0 0 ${basicGap};
  font-size: 14px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-top: 0;
  }

  p {
    position: relative;
  }

  gap: ${basicGap};

  @media (min-width: ${breakpoints.tablet}) {
    gap: 20px;
  }
`;

export const Footer = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(14, 18)}
  margin-top: ${doubleGap};
  margin-bottom: ${doubleGap};
  display: flex;
  flex-direction: row;
`;

export const MobileFlowInputs = styled.div`
  margin-top: ${basicGap};
`;

export const FooterDiv = styled.div`
  width: fit-content;
  text-align: start;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 25px;
  margin-right: 10px;
`;

export const Checkbox = styled.div`
  input {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 3;
  }

  .checkmark::after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark {
    position: absolute;
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
    border-radius: ${pxToRem(3)};
    border: ${pxToRem(1)} solid ${colors.$bebebe};
  }

  &:hover input ~ .checkmark {
    border: ${pxToRem(1)} solid ${colors.$bebebe};
  }

  input:checked ~ .checkmark {
    background-color: ${colors.$00697c};
    border: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
`;

export const ReadOnlyDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    gap: 28px;
    margin-bottom: 4px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 12px;
  }
`;

export const Field = styled.div`
  text-align: left;
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(18, 24)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  p {
    margin: 0 0 8px;
    color: ${colors.$00697c};
  }

  div {
    justify-content: flex-start;
  }
`;
