import { IConfigOtp, IOtpPortabilityMobile, IPersonalInfoCMS } from '@vfit/consumer/data-access';
import { fonts, pxToLineHeight } from '@vfit/shared/themes';

export const organizeOtp = (
  dataFromCms: IPersonalInfoCMS,
  msisdnOrEmail: any,
  contactMedium: any
): IConfigOtp => ({
  title: dataFromCms?.personalinfo?.clientRecognition?.title || '',
  description: dataFromCms?.personalinfo?.clientRecognition?.description || '',
  codeResponseDescription:
    dataFromCms?.personalinfo?.clientRecognition?.codeResponse?.description || '',
  instructionText: {
    text: msisdnOrEmail
      ? `${dataFromCms?.personalinfo?.clientRecognition?.telephoneVerify?.replace(
          '$number',
          `<b style="color:red">${contactMedium.field}</b>`
        )}`
      : `${dataFromCms?.personalinfo?.clientRecognition?.emailVerify?.replace(
          '$email',
          `
<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
    <b style="color:red">${contactMedium?.field || ''}</b>
</div>
`
        )}`,
    fontFamily: fonts.exbold,
    size: 20,
    lineHeight: pxToLineHeight(20, 26),
  },
  alerts: {
    error: dataFromCms?.personalinfo?.clientRecognition?.codeResponse?.alertWarn || '',
    sentNew: dataFromCms?.personalinfo?.clientRecognition?.codeResponse?.alertSuccess || '',
  },
  otpError: dataFromCms?.personalinfo?.clientRecognition?.codeResponse?.otpError || '',
  detail: dataFromCms?.personalinfo?.clientRecognition?.codeMissingLabel || '',
  links: {
    sendNew: dataFromCms?.personalinfo?.clientRecognition?.codeNewLabel || '',
    noAccess: dataFromCms?.personalinfo?.clientRecognition?.codeNotAccessLabel || '',
    urlNoAccess: dataFromCms?.personalinfo?.clientRecognition?.contactNotAccessUrl || '',
  },
});

export const organizeOtpPortability = (
  dataFromCms: IOtpPortabilityMobile,
  msisdnOrEmail: any,
  contactMedium: any
): IConfigOtp => ({
  title: dataFromCms?.title || '',
  description: dataFromCms?.description || '',
  codeResponseDescription:
    dataFromCms?.codeResponse?.description || '',
  instructionText: {
    text: msisdnOrEmail
      ? `${dataFromCms?.telephoneVerify?.replace(
          '$number',
          `<b style="color:red">${contactMedium.field}</b>`
        )}`
      : `${dataFromCms?.emailVerify?.replace(
          '$email',
          `
<div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
    <b style="color:red">${contactMedium?.field || ''}</b>
</div>
`
        )}`,
    fontFamily: fonts.exbold,
    size: 20,
    lineHeight: pxToLineHeight(20, 26),
  },
  alerts: {
    error: dataFromCms?.codeResponse?.alertWarn || '',
    sentNew: dataFromCms?.codeResponse?.alertSuccess || '',
  },
  otpError: dataFromCms?.codeResponse?.otpError || '',
  detail: dataFromCms?.codeMissingLabel || '',
  links: {
    sendNew: dataFromCms?.codeNewLabel || '',
    noAccess: dataFromCms?.codeNotAccessLabel || '',
    urlNoAccess: dataFromCms?.contactNotAccessUrl || '',
  },
});

