import {
  ErrorCodes,
  IPersonalInfoCMS,
  IProduct,
  retrieveCartCookie,
  setCartCookie,
} from '@vfit/consumer/data-access';
import { ErrorDetail } from '@vfit/shared/data-access';
import { ICartCookie, IGenericError } from '@vfit/shared/models';
import { isAcceptedCookieLawGroup } from '@vfit/shared/data-access';

export const checkError = (
  errorCode: string,
  handleOnClose: (() => void) | undefined,
  errorMessge: IGenericError
): ErrorDetail => {
  const title = errorMessge?.title || '';
  let message = errorMessge?.description || '';
  const actionText = errorMessge?.buttonLabel || '';
  let onClose: (() => void) | undefined = handleOnClose;
  let actionEvent: (() => void) | undefined = handleOnClose;
  if (errorCode === ErrorCodes.FISCAL_CODE_UNDERAGE) {
    message = 'Devi essere maggiorenne per proseguire';
    onClose = undefined;
    actionEvent = undefined;
  }
  if (errorCode === ErrorCodes.FISCAL_CODE_ERROR) {
    message = 'Il codice fiscale non è valido. Inserisci un codice fiscale valido per proseguire';
    onClose = undefined;
    actionEvent = undefined;
  }
  return {
    title,
    message,
    actionText,
    onClose,
    actionEvent,
  };
};

export const organizeNameFiscalCode = (
  nameFiscalCodeCms: IPersonalInfoCMS,
  product?: IProduct | null
) => ({
  title: nameFiscalCodeCms?.personalinfo?.fiscalCode?.title || '',
  description: nameFiscalCodeCms?.personalinfo?.fiscalCode?.description || '',
  firstNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.firstName || '',
  lastNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.lastName || '',
  fiscalCodeLable: nameFiscalCodeCms?.personalinfo?.selectInput?.fiscalCode || '',
  buttonLabel: nameFiscalCodeCms?.personalinfo?.generalInfo?.fcMissingLabel || '',
  legacyPopup: {
    legacyPopupIsOpen: true,
    legacyPopupTitle:
      product?.commonOverride?.legacyCtc?.title ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.title ||
      '',
    legacyPopupMessage:
      product?.commonOverride?.legacyCtc?.description ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.description ||
      '',
    legacyPopupActionText:
      product?.commonOverride?.legacyCtc?.buttonLabel ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.buttonLabel ||
      '',
    legacyPopupUrl:
      product?.commonOverride?.legacyCtc?.url ||
      nameFiscalCodeCms?.personalinfo?.clientRecognition?.redirect?.url ||
      '',
  },
});

export const organizePersonalInfo = (dataFromCms: IPersonalInfoCMS) => ({
  title: dataFromCms?.personalinfo?.clientLogged?.personalInfo?.title || 'Dati personali',
  description:
    dataFromCms?.personalinfo?.clientLogged?.personalInfo?.description ||
    'Queste sono le tue informazioni personali.',
  bottomText:
    dataFromCms?.personalinfo?.clientLogged?.personalInfo?.bottomText ||
    'Vuoi effettuare l’ordine per un’altra persona?',
  lableName:
    dataFromCms?.personalinfo?.clientLogged?.personalInfo?.labelName || 'Il tuo nome e cognome',
  lableFiscalCode:
    dataFromCms?.personalinfo?.clientLogged?.personalInfo?.lableFiscalCode ||
    'Il tuo codice fiscale',
  action: dataFromCms?.personalinfo?.clientLogged?.personalInfo?.textAction || 'Logout',
  url:
    dataFromCms?.personalinfo?.clientLogged?.personalInfo?.url ||
    'https://www.vodafone.it/area-utente/fai-da-te/Common/PaginaUnicadiLogin.html',
});

export const organizeRetriveFiscalCode = (nameFiscalCodeCms: IPersonalInfoCMS) => ({
  retriveFiscalCode: {
    title: nameFiscalCodeCms?.personalinfo?.fiscalCode?.title || '',
    description: nameFiscalCodeCms?.personalinfo?.fiscalCode?.description || '',
    firstName: nameFiscalCodeCms?.personalinfo?.selectInput?.firstName || '',
    lastName: nameFiscalCodeCms?.personalinfo?.selectInput?.lastName || '',
    birthDate: nameFiscalCodeCms?.personalinfo?.selectInput?.birthdate || '',
    gender: nameFiscalCodeCms?.personalinfo?.selectInput?.gender || '',
    nationality: nameFiscalCodeCms?.personalinfo?.selectInput?.country || '',
    birthPlace: nameFiscalCodeCms?.personalinfo?.selectInput?.city || '',
    errorMessage: nameFiscalCodeCms?.personalinfo?.generalInfo?.errorMessage?.error2 || '',
    birthNation: nameFiscalCodeCms?.personalinfo?.selectInput?.birthNation || 'Nazione di nascita',
    buttonLabel: nameFiscalCodeCms?.personalinfo?.fiscalCode?.buttonLabel || '',
  },
  nameFiscalCodeCard: {
    title: nameFiscalCodeCms?.personalinfo?.generalInfo?.title || '',
    titleReadOnly:
      nameFiscalCodeCms?.personalinfo?.generalInfo?.titleReadOnly ||
      nameFiscalCodeCms?.personalinfo?.generalInfo?.title ||
      '',
    description: nameFiscalCodeCms?.personalinfo?.generalInfo?.description || '',
    descriptionReadOnly:
      nameFiscalCodeCms?.personalinfo?.generalInfo?.descriptionReadOnly ||
      nameFiscalCodeCms?.personalinfo?.generalInfo?.description ||
      '',
    firstNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.firstName || '',
    lastNameLable: nameFiscalCodeCms?.personalinfo?.selectInput?.lastName || '',
    fiscalCodeLable: nameFiscalCodeCms?.personalinfo?.selectInput?.fiscalCode || '',
    buttonLabel: nameFiscalCodeCms?.personalinfo?.generalInfo?.fcMissingLabel || '',
  },
});

export const setNewCartCookie = (
  findCallerResult: string,
  product: IProduct,
  cartCookieDaysDuration: number
) => {
  const isAcceptedPrivacyPolicy = isAcceptedCookieLawGroup('4');
  if (!product || !isAcceptedPrivacyPolicy || product.isNoCookie || product.isLockInMMProduct)
    return;
  const cartCookie = retrieveCartCookie() as ICartCookie;
  let userType: 'anonymous' | 'legacy' | 'next';
  switch (findCallerResult) {
    case 'anonymous': {
      userType = 'anonymous';
      break;
    }
    case 'existingLegacyCustomer': {
      userType = 'legacy';
      break;
    }
    case 'existingNextCustomer': {
      userType = 'next';
      break;
    }
    default: {
      userType = 'anonymous';
      break;
    }
  }
  localStorage.setItem('userType', userType);
  setCartCookie(
    product,
    cartCookie?.address,
    cartCookie?.technology,
    cartCookieDaysDuration,
    userType
  );
};
