import { useEffect, useState } from 'react';
import {
  BillingAddress,
  checkIsGoBackSIA,
  IBillingAccountResponse,
  useBillDelivery,
  useCreateAddress,
  useReplaceBillingAddressV1,
  useValidateAddress,
} from '@vfit/consumer/data-access';
import { IAddressResponse, IValidateAddressRequest } from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IUseDeliveryBilling, IUseDeliveryBillingResponse } from './useDeliveryBillingFlow.models';

const ENABLE_REPLACE_LOGIC = false;

const DEFAULT_DELIVERY_BILLING: IUseDeliveryBillingResponse = {
  validateAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  createAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  replaceAddress: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  billingPaper: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
  billingEbill: {
    isLoading: false,
    isSuccess: false,
    isError: false,
  },
};

export const useDeliveryBillingFlow = ({
  billingAddress,
  billingEmail,
  enableBillDelivery,
}: IUseDeliveryBilling): IUseDeliveryBillingResponse => {
  const [billingReturn, setBillingReturn] =
    useState<IUseDeliveryBillingResponse>(DEFAULT_DELIVERY_BILLING);
  const {
    mutateAsync: billDeliveryEbillMutation,
    isLoading: isLoadingEBillDelivery,
    isSuccess: isSuccessEBillDelivery,
    isError: isErrorEBillDelivery,
  } = useBillDelivery();
  const {
    mutateAsync: billDeliveryMutation,
    isLoading: isLoadingBillDelivery,
    isSuccess: isSuccessBillDelivery,
    isError: isErrorBillDelivery,
  } = useBillDelivery();
  const {
    mutate: validateAddressMutation,
    data: validatedShippingAddress,
    isLoading: validateAddressIsLoading,
    isSuccess: validateAddressIsSuccess,
    isError: validateAddressIsError,
  } = useValidateAddress();
  const {
    mutate: createAddressMutation,
    data: createAddressData,
    isLoading: isLoadingCreateAddressMutation,
    isSuccess: isSuccessCreateAddressMutation,
    isError: isErrorCreateAddressMutation,
  } = useCreateAddress();
  const {
    mutate: replaceBillingAddressMutation,
    isLoading: isLoadingReplace,
    isSuccess: isSuccessReplace,
    isError: isErrorReplace,
  } = useReplaceBillingAddressV1();

  const createBillingAddress = (
    billingAddressCreatedOrValidated: IAddressResponse | BillingAddress
  ) => {
    const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
    const billingAccountId = billingAccount?.id;

    if (ENABLE_REPLACE_LOGIC) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
        billingEbill: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      replaceBillingAddressMutation({
        billingAccountId,
        billingAddressId: billingAddressCreatedOrValidated.id,
        billingAddressType: 'postalAddress',
      });
    } else {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
        replaceAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      billDeliveryMutation({
        billFormat: 'paperBill',
        physicalAddress: billingAddressCreatedOrValidated,
      });
    }
  };

  useEffect(() => {
    if (isLoadingEBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingEbill: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorEBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingEbill: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessEBillDelivery && !checkIsGoBackSIA()) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingEbill: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
    }
  }, [isSuccessEBillDelivery, isErrorEBillDelivery, isLoadingEBillDelivery]);

  useEffect(() => {
    if (isLoadingBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorBillDelivery) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessBillDelivery && !checkIsGoBackSIA()) {
      setBillingReturn((prevState) => ({
        ...prevState,
        billingPaper: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      billDeliveryEbillMutation({ billFormat: 'eBill', emailAddress: billingEmail });
    }
  }, [isSuccessBillDelivery, isErrorBillDelivery, isLoadingBillDelivery]);

  useEffect(() => {
    if (isLoadingReplace) {
      setBillingReturn((prevState) => ({
        ...prevState,
        replaceAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorReplace) {
      setBillingReturn((prevState) => ({
        ...prevState,
        replaceAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessReplace) {
      setBillingReturn((prevState) => ({
        ...prevState,
        replaceAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
    }
  }, [isSuccessReplace, isLoadingReplace, isErrorReplace]);

  useEffect(() => {
    if (isLoadingCreateAddressMutation) {
      setBillingReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (isErrorCreateAddressMutation) {
      setBillingReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (isSuccessCreateAddressMutation) {
      setBillingReturn((prevState) => ({
        ...prevState,
        createAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      createBillingAddress(createAddressData);
    }
  }, [
    isSuccessCreateAddressMutation,
    isLoadingCreateAddressMutation,
    isErrorCreateAddressMutation,
  ]);

  useEffect(() => {
    if (validateAddressIsLoading) {
      setBillingReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      }));
    } else if (validateAddressIsError) {
      setBillingReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: false,
          isSuccess: false,
          isError: true,
        },
      }));
    } else if (validateAddressIsSuccess && !checkIsGoBackSIA()) {
      setBillingReturn((prevState) => ({
        ...prevState,
        validateAddress: {
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      }));
      createAddressMutation({
        address: validatedShippingAddress?.validAddressList?.[0],
        isDelivery: true,
      });
    }
  }, [validateAddressIsSuccess, validateAddressIsLoading, validateAddressIsError]);

  useEffect(() => {
    if (
      !checkIsGoBackSIA() &&
      enableBillDelivery &&
      billingAddress?.street &&
      billingAddress?.city &&
      !validateAddressIsLoading
    ) {
      validateAddressMutation({
        placeObject: billingAddress as IValidateAddressRequest,
      });
    }
  }, [billingAddress, enableBillDelivery]);

  return billingReturn;
};
