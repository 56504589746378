import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import { ImageAtoms, FullScreenModal, ButtonSlide, CustomText } from '@vfit/shared/atoms';
import { Portal } from '@vfit/shared/components';
import { allQueryKeyArray } from '@vfit/consumer/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { handleEnv, LoggerInstance, resetData, useTracking } from '@vfit/shared/data-access';
import { useDeviceType } from '@vfit/shared/hooks';
import { useVideoRecognitionMutation } from '@vfit/shared/data-access';
import { BackgroundDiv, Container, ModalTitle, ModalButton } from './vRLanding.style';
import VRCard from './components/VRCard/vRCard';
import { IVRProps } from './vRLanding.models';
import { encryptSimSerialNumber, getRedirectUrl } from './vRLanding.utils';
import QROverlay from './components/QROverlay/qROverlay';

const VRLanding = ({
  barCodeAcquisition,
  errorModal,
  simInfoModal,
  flowImages,
  qROverlay,
}: IVRProps) => {
  const [encryptedSimSerial, setEncryptedSimSerial] = useState('');
  const [modalConfig, setModalConfig] = useState<{
    isOpen: boolean;
    title: string;
    description: string;
    button: string;
  }>({
    isOpen: false,
    title: '',
    description: '',
    button: '',
  });
  const videoRecognitionMutation = useVideoRecognitionMutation();
  const { push } = useRouter();
  const queryClient = useQueryClient();
  const { isDesktop } = useDeviceType();
  const [isQROverlayDisplayed, setIsQROverlayDisplayed] = useState(
    (qROverlay && Object.keys(qROverlay).length > 0 && isDesktop) || false
  );

  const opts = {
    event_category: 'sales',
    page_subsection: 'sim activation',
    page_section: 'vr',
    page_type: 'manual input sim',
    journey_name: 'sim activation',
  };

  const { trackView, trackWithNewDatalayer } = useTracking({
    event: ['journey_start', 'view'],
    event_label: 'sim manual',
    opts,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
    disableInitialTrack: isDesktop,
  });

  const handleLinkTagging = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: 'info link',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  const handleSubmit = async (serialNumber: string) => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: 'prosegui',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    videoRecognitionMutation.mutate(serialNumber);
    const encryptedSim = await Promise.resolve(await encryptSimSerialNumber(serialNumber));
    setEncryptedSimSerial(encryptedSim);
  };

  const handleCloseModal = () => {
    if (videoRecognitionMutation.isSuccess)
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'nuovo seriale',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    if (videoRecognitionMutation.isError)
      trackView({
        event_name: 'ui_interaction',
        event_label_track: 'riprova',
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    setModalConfig({
      isOpen: false,
      title: '',
      description: '',
      button: '',
    });
    videoRecognitionMutation.reset();
    trackWithNewDatalayer({
      event: ['journey_start', 'view'],
      event_label: 'sim manual',
      opts,
      pageProduct: undefined,
      cartProduct: undefined,
      visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
    });
  };

  const handleCloseQROverlay = () => {
    setIsQROverlayDisplayed(false);
    trackWithNewDatalayer({
      event: ['journey_start', 'view'],
      event_label: 'sim manual',
      opts,
      visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
    });
  };

  useEffect(() => {
    LoggerInstance.debug('VR URL', handleEnv('NEXT_PUBLIC_VR_URL') || '');
    resetData(queryClient, allQueryKeyArray);
  }, []);

  useEffect(() => {
    if (videoRecognitionMutation.isSuccess) {
      if (Object.keys(videoRecognitionMutation.data).length === 0) {
        trackView({
          event_name: 'page_error',
          event_label_track: 'page_error',
          event_category: 'error',
          page_error: `|_|${errorModal?.serialNotCorrect?.title}|_|${errorModal?.serialNotCorrect?.description}`,
          page_type: 'error page',
        });
        setModalConfig({
          isOpen: true,
          title: errorModal?.serialNotCorrect?.title || '',
          description: errorModal?.serialNotCorrect?.description || '',
          button: errorModal?.serialNotCorrect?.button || '',
        });
      } else if (encryptedSimSerial !== '') {
        push(getRedirectUrl(videoRecognitionMutation.data.relatedEntity.id, encryptedSimSerial));
      }
    }
  }, [videoRecognitionMutation.isSuccess, encryptedSimSerial]);

  useEffect(() => {
    if (videoRecognitionMutation.isError) {
      setModalConfig({
        isOpen: true,
        title: 'Ops',
        description: 'Si è verificato un errore, al momento il servizio non è disponibile.',
        button: 'Riprova',
      });
      trackView({
        event_name: 'page_error',
        event_label_track: 'page_error',
        event_category: 'error',
        page_error: `${videoRecognitionMutation.error?.status}|_|Ops|_|Si è verificato un errore, al momento il servizio non è disponibile.`,
        page_error_code: `${videoRecognitionMutation.error?.status}`,
        page_type: 'error page',
      });
    }
  }, [videoRecognitionMutation.isError]);

  return (
    <>
      <BackgroundDiv>
        <ImageAtoms nameCard="vRBackground" image={flowImages?.background} />
      </BackgroundDiv>
      <div>
        <Container>
          <ImageAtoms
            nameCard="vodafoneLogo"
            image={flowImages?.logo}
            style={{ width: 'auto', height: '32px' }}
          />
          <VRCard
            data={barCodeAcquisition}
            infoModal={simInfoModal}
            onSubmit={handleSubmit}
            handleTagging={handleLinkTagging}
          />
        </Container>
      </div>
      <Portal wrapperId="video-recognition">
        <FullScreenModal show={modalConfig.isOpen} onCloseModal={handleCloseModal} showIcon={false}>
          <ModalTitle>{modalConfig.title}</ModalTitle>
          <CustomText
            text={modalConfig.description}
            modal
            size={18}
            lineHeight={24}
            textAlign="center"
          />
          <ModalButton>
            <ButtonSlide
              label={modalConfig.button}
              onClick={handleCloseModal}
              buttonColor="#e60000"
              labelColor="#fff"
              hoverColor="#bd0000"
              clickColor="#a10000"
            />
          </ModalButton>
        </FullScreenModal>
      </Portal>
      <Portal wrapperId="video-recognition">
        <FullScreenModal show={modalConfig.isOpen} onCloseModal={handleCloseModal} showIcon={false}>
          <ModalTitle>{modalConfig.title}</ModalTitle>
          <CustomText
            text={modalConfig.description}
            modal
            size={18}
            lineHeight={24}
            textAlign="center"
          />
          <ModalButton>
            <ButtonSlide
              label={modalConfig.button}
              onClick={handleCloseModal}
              buttonColor="#e60000"
              labelColor="#fff"
              hoverColor="#bd0000"
              clickColor="#a10000"
            />
          </ModalButton>
        </FullScreenModal>
      </Portal>
      <Portal wrapperId="video-recognition">
        <FullScreenModal show={isQROverlayDisplayed} onCloseModal={handleCloseQROverlay}>
          <QROverlay {...qROverlay} onAnchorClick={handleCloseQROverlay} />
        </FullScreenModal>
      </Portal>
    </>
  );
};

export default VRLanding;
