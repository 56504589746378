import { FC, PropsWithChildren } from 'react'
import {FreeHtml} from "@vfit/shared/components";
import {IProductDetails} from "../topDevice.models";

const WithProductDetails:
  FC<PropsWithChildren<IProductDetails>> = ({description,children}) => (
  <>
    {children}
    <FreeHtml html={description} />
  </>
)

export default WithProductDetails
