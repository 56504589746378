import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ListDiv = styled.div`
  margin-bottom: 40px;
  margin-top: 16px;
  font-family: ${fonts.exbold};
  font-weight: bold;
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;margin-bottom: 40px;
`

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media (max-width: ${breakpoints.tablet}) {
    position: absolute;
    bottom: 10px;
    max-width: 80%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    justify-content: center;

    div {
      width: 300px;
    }
  }
`

export const ParagraphContainer = styled.p`
  margin: 0;
`;

export const Title = styled(ParagraphContainer)`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const OfferPrice = styled(Title)`
  .rec {
    ${pxToCssFont(24, 28)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 8px;

    .rec {
      ${pxToCssFont(24, 30)}
    }
  }
`;

export const OfferDescription = styled.div`
  div {
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;

    p,
    span {
      ${pxToCssFont(16, 22)}
      color: ${colors.$262626};
      margin:0;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin: 0;
      margin-top: 4px;
    }
  }
`;
