import { purify } from '@vfit/shared/themes';
import React, { Children } from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import * as S from './productCharacteristics.style';

export const ProductCharacteristics = ({ elements, title, subTitle, promoDisclaimer }: IList) => {
  const getTextPurified = (text: string) => purify([text || ''])?.[0] || '';

  return (
    <S.MainContainer>
      <S.Heading>
        {title && <S.Title>{title}</S.Title>}
        {subTitle && <S.Subtitle>{subTitle}</S.Subtitle>}
      </S.Heading>
      <S.ListContainer>
        {elements &&
          elements.length > 0 &&
          Children.toArray(
            elements.map((element) => (
              <S.ListElement>
                <S.IconContainer>
                  <ImageAtoms image={element.icon} nameCard={element.text} />
                </S.IconContainer>
                <S.TextContainer>
                  {element?.subtext && (
                    <S.Text
                      dangerouslySetInnerHTML={{ __html: getTextPurified(element?.subtext) }}
                    />
                  )}
                </S.TextContainer>
              </S.ListElement>
            ))
          )}
      </S.ListContainer>
      {!!promoDisclaimer &&
        <S.PromoDisclaimer
          dangerouslySetInnerHTML={{ __html: getTextPurified(promoDisclaimer) }}
        />
      }
    </S.MainContainer>
  );
};

export interface IList {
  elements: IListElement[];
  title?: string;
  subTitle?: string;
  promoDisclaimer?: string;
}

export interface IListElement {
  text: string;
  subtext?: string;
  icon?: string;
}
