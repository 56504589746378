import { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { VodafoneStoreIcon, PosteIcon, Location } from '@vfit/shared-icons';
import { useDeviceType } from '@vfit/shared/hooks';
import { MAP_OPTIONS } from './vodafoneStore.utils';
import { MapContainer, Current, Detail, LocationIcon, Banner } from './vodafoneStore.style';
import { IAddressMapProps } from './vodafoneStore.module';
import {IAddressType} from "@vfit/shared/models";

const DUMMY = {
  lable: 'Consegna in store:',
};

// TO-DO find a way to use local images
const myLocation = 'https://vf-test-components.s3.eu-south-1.amazonaws.com/images/myLocation.svg';
const vodafoneStoreDot =
  'https://vf-test-components.s3.eu-south-1.amazonaws.com/images/vodafoneStoreDot.svg';
const vodafoneStoreSelected =
  'https://vf-test-components.s3.eu-south-1.amazonaws.com/images/vodafoneStoreSelected.svg';
const posteDot = 'https://vf-test-components.s3.eu-south-1.amazonaws.com/images/posteDot.svg';
const posteSelected =
  'https://vf-test-components.s3.eu-south-1.amazonaws.com/images/posteSelected.svg';

const VodafoneStore = ({ type, items, onSelectItem, position }: IAddressMapProps) => {
  const [itemIndex, setItemIndex] = useState(-1);
  const [currentPosition, setCurrentPosition] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 0, lng: 0 });
  const urlKeys = {
    key: process.env['NX_GOOGLE_MAPS_API_KEY'] || '',
    libraries: ['places'],
    language: 'it',
    region: 'it',
    version: 'weekly',
  };
  const markers: any = [];
  const { isDesktop, isTablet, isMobile } = useDeviceType();

  let iconSize = { min: 0, max: 0 };
  if (isDesktop) {
    iconSize = { min: 23, max: 46 };
  } else if (isTablet) {
    iconSize = { min: 15, max: 41 };
  } else {
    iconSize = { min: 12, max: 30 };
  }

  // TO CHECK @Martina before Husky
  // const iconSize = isDesktop
  //   ? { min: 23, max: 46 }
  //   : isTablet
  //   ? { min: 15, max: 41 }
  //   : { min: 12, max: 30 };

  const myLocationSize = isDesktop
    ? { height: 77.14, width: 60.68 }
    : { height: 56.02, width: 46.56 };

  const [mapKey, setMapKey] = useState<boolean>(true);
  const [localMap, setLocalMap] = useState<any>();

  useEffect(() => {
    setItemIndex(items.findIndex((e) => e.isSelected));
  }, []);

  useEffect(() => {
    setMapKey(!mapKey);
  }, [isMobile, isTablet, isDesktop]);

  useEffect(() => {
    if (position && position.lat !== '' && position.lat !== '')
      setCurrentPosition({
        lat: parseFloat(position.lat),
        lng: parseFloat(position.lng),
      });
  }, []);

  const centerMap = () =>
    localMap.panTo(new google.maps.LatLng(currentPosition?.lat, currentPosition?.lng));

  const chooseIcon = (idMarker: number, i: number, isSelected: boolean) => {
    let icon = '';
    if (idMarker === i && isSelected) {
      switch (type) {
        case IAddressType.P:
          icon = vodafoneStoreSelected;
          break;
        case IAddressType.PO:
          icon = posteSelected;
          break;
        case IAddressType.PP:
          icon = posteSelected;
          break;
        case IAddressType.S:
          break;
        default:
          break;
      }
    } else {
      switch (type) {
        case IAddressType.P:
          icon = vodafoneStoreDot;
          break;
        case IAddressType.PO:
          icon = posteDot;
          break;
        case IAddressType.PP:
          icon = posteDot;
          break;
        case IAddressType.S:
          break;
        default:
          break;
      }
    }
    // TO CHECK @Martina before Husky
    // const icon =
    //   type === IAddressType.PP
    //     ? idMarker === i && isSelected
    //       ? vodafoneStoreSelected
    //       : vodafoneStoreDot
    //     : idMarker === i && isSelected
    //       ? posteSelected
    //       : posteDot;
    const size = idMarker === i && isSelected ? iconSize.max : iconSize.min;

    return {
      url: icon,
      scaledSize: new google.maps.Size(size, size),
    };
  };

  const updateMarkers = (idMarker: number) => {
    onSelectItem(idMarker);
    setItemIndex(idMarker);
    markers.forEach((marker: any, i: number) => {
      if (i > 0) marker.setIcon(chooseIcon(1, 1, i === idMarker + 1));
    });
  };

  const renderMarkers = (
    map: any,
    maps: any,
    lat: number,
    lng: number,
    idMarker: number,
    icon: any
  ) => {
    const marker = new maps.Marker({
      position: { lat, lng },
      icon,
      map,
      zIndex: 60,
    });
    markers.push(marker);
    if (idMarker > -1) {
      maps.event.addDomListener(marker, 'click', () => updateMarkers(idMarker));
    }
  };

  return (
    <MapContainer>
      <>
        <GoogleMapReact
          bootstrapURLKeys={urlKeys}
          zoom={14}
          options={MAP_OPTIONS}
          draggable
          key={mapKey.toString()}
          center={currentPosition}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            setLocalMap(map);
            renderMarkers(map, maps, currentPosition?.lat, currentPosition?.lng, -1, {
              url: myLocation, // icon
              scaledSize: new google.maps.Size(myLocationSize.height, myLocationSize.width),
            });
            items.forEach((store, index) => {
              const { locationAddress, isSelected } = store;
              renderMarkers(
                map,
                maps,
                parseFloat(locationAddress?.latitude),
                parseFloat(locationAddress?.longitude),
                index,
                chooseIcon(1, 1, isSelected || false)
              );
            });
          }}
        />
        <LocationIcon onClick={() => centerMap()}>
          <Location />
        </LocationIcon>

        <Banner>
          <Current>
            {type === IAddressType.PP ? <VodafoneStoreIcon /> : <PosteIcon />}
            <Detail>
              <p>{DUMMY.lable}</p>
              {itemIndex !== -1 && (
                <p>{`${items[
                  itemIndex
                ]?.locationAddress.postalOfficeDescription.toLowerCase()} - ${items[
                  itemIndex
                ]?.locationAddress.formattedAddress1.toLowerCase()}, ${
                  items[itemIndex]?.locationAddress.stateOrProvince
                }`}</p>
              )}
            </Detail>
          </Current>
        </Banner>
      </>
    </MapContainer>
  );
};

export default VodafoneStore;
