import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

const Container = styled.div`
  font-family: ${fonts.regular};
`;

const FormContainer = styled.div`
  margin: 0 16px;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 157px;
  }
`;

const ImageDiv = styled.div`
  height: 132vw;
  overflow: hidden;
  position: relative;
  background: #f4f4f4;

  @media (min-width: ${breakpoints.desktop}) {
    height: 650px;
  }
`;

const ButtonContainer = styled.div`
  padding: 0 46px;

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;

    button {
      width: 150px;
    }
  }
`;

const ImageSection = styled.article`
  transform: rotate(30deg);
  position: absolute;
  width: 100%;
  height: 188vw;
  bottom: 28vw;
  right: -39vw;
  overflow: hidden;
  border-radius: 50vw;

  img {
    position: absolute;
    width: 132vw;
    max-width: none !important;
    left: -17vw;
    bottom: 0;
    transform: rotate(-30deg);
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: 1300px;
    width: 550px;
    border-radius: 381px;
    bottom: 40px;
    right: -80px;

    img {
      width: 141%;
      max-width: none !important;
      left: -30px;
      top: 680px;
      object-fit: cover;
    }
  }
`;

const H1 = styled.h1`
  text-align: center;
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(30, 30)}
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 30px;
  gap: 50px;
  padding: 0 20px;

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: center;
    padding: 0 62px;
    gap: 30px;

    .container {
      width: 50%;
    }
  }
`;

const RadioListContainer = styled.div`
  display: flex;
  width: 50%;
  gap: 16px;
  font-size: 18px;
  flex-direction: column;
  margin-bottom: 32px;
  padding-right: 13px;
  @media (min-width: ${breakpoints.tablet}) {
    justify-content: center;
  }

  div {
    font-size: 18px;
    margin: 0 0 0 4px;
    border: none;
    font-weight: normal;
    font-family: ${fonts.regular};
    padding: 0;
  }
`;

export {
  Container,
  FormContainer,
  InputContainer,
  ButtonContainer,
  H1,
  ImageDiv,
  ImageSection,
  RadioListContainer,
};
