import { UseQueryResult } from 'react-query';
import { API } from '@vfit/shared/data-access';
import {
  INextError,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';

export interface IPartnerOfferValidateResponse {
  firstName: string;
  lastName: string;
  fiscalCode: string;
}

/**
 * Decoding token from Amdocs
 * I didn't use search params because they were automatically converted
 * preventing the sending of special characters
 * @param token
 * @param customOptions
 */
const partnerOfferValidateService = (token: string, customOptions?: CustomOptions) =>
  nextClient.get(
    `${API.PARTNER_OFFER_VALIDATE}?token=${encodeURIComponent(token)}&salesChannel=selfService`,
    {
      searchParams: undefined,
      ...(customOptions?.headers && { headers: customOptions.headers }),
    }
  ) as Promise<IPartnerOfferValidateResponse>;

export const usePartnerOfferValidate = (
  token: string,
  customOptions?: CustomOptions
): UseQueryResult<IPartnerOfferValidateResponse, INextError> => {
  const keysDependency = 'partnerOfferValidate';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => partnerOfferValidateService(token, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      onSuccess: (data: IPartnerOfferValidateResponse) => {
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        customOptions?.onError?.(error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IPartnerOfferValidateResponse, INextError>;
};
