import { useEffect, useState } from 'react';
import { Alert, Divider, LinkWithIcon, CustomText } from '@vfit/shared/atoms';
import { Form } from '@vfit/shared/components';
import { openPopup, trackLink } from '@vfit/shared/data-access';
import { AlertSuccess, AlertWarning } from '@vfit/shared-icons';
import { Container, Description, Detail, LinkContainer, Title } from './otp.style';
import { IOtpProps } from './otp.models';
import GenericLoading from '../GenericLoading/genericLoading';

const Otp = ({
  configOtp,
  contactMedium,
  isLoading,
  isError,
  reSendOtp,
  onVerify,
  setIsError,
  noAccessEnabled = true,
  product,
  showLoaderCard,
}: IOtpProps) => {
  const [hasSentNewCode, setHasSentNewCode] = useState(false);
  const { otpError, title, links, detail, alerts, instructionText, codeResponseDescription } =
    configOtp;

  const handleReSend = () => {
    setHasSentNewCode(true);
    setIsError();
    if (contactMedium) reSendOtp(contactMedium.field, contactMedium.fieldType);
    setTimeout(() => setHasSentNewCode(false), 30000);
  };

  const handleChange = (otp: string) => {
    onVerify(otp);
  };

  useEffect(() => {
    if (isError) setHasSentNewCode(false);
  }, [isError]);

  const handleRedirect = () => {
    if (product?.urlClickToCallOtp) {
      openPopup(product.urlClickToCallOtp);
    } else if (links?.urlNoAccess) {
      window.location.href = links.urlNoAccess;
    }
  };

  if (showLoaderCard && product) return <GenericLoading product={product} />;

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{codeResponseDescription}</Description>
      <CustomText
        text={instructionText.text || ''}
        fontFamily={instructionText.fontFamily}
        size={instructionText.size}
        lineHeight={instructionText.lineHeight}
      />
      {!isError && <Divider marginBottom={-1.5} />}
      {isError && (
        <div style={{ marginTop: '-8px', marginBottom: '24px' }}>
          <Alert type="error" icon={<AlertWarning />} text={alerts?.error || ''} />
        </div>
      )}
      <div style={{ paddingTop: '24px' }}>
        <Form.OtpInput
          number={6}
          showError={isError}
          errorMessage={otpError}
          onChange={handleChange}
          forcedDisabled={isLoading}
          setIsError={setIsError}
        />
      </div>
      <Detail>{detail}</Detail>
      <LinkContainer>
        <LinkWithIcon
          text={links.sendNew}
          onClick={() => {
            trackLink(links.sendNew || '');
            handleReSend();
          }}
        />
        {noAccessEnabled && (
          <LinkWithIcon
            text={links.noAccess}
            onClick={() => {
              trackLink(links.noAccess || '');
              handleRedirect();
            }}
          />
        )}
      </LinkContainer>
      {hasSentNewCode && (
        <div style={{ marginTop: '28px' }}>
          <Alert type="success" icon={<AlertSuccess />} text={alerts.sentNew || ''} />
        </div>
      )}
    </Container>
  );
};

export default Otp;
