import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  color: ${colors.$262626};
  font-weight: 400;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Subtitle = styled.div`
  font-family: ${fonts.regular};
  ${pxToCssFont(18, 24)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const CheckDiv = styled.div`
  align-self: flex-start;
  overflow: scroll;
  overflow-x: hidden;
  margin-bottom: 32px;
  min-height: 68px;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
    min-height: 72px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 32px;
  }
`;

export const LinkDiv = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 36px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 44px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 52px;
  }
`;

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .button-slide-container {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: 258px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 296px;
    }
  }
`;

