import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  padding: 32px 20px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 60px 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 60px 55px;
  }

  .linkWithIcon {
    margin-top: 16px;
    display: flex;
    justify-content: left;

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(18, 26)}
    }
  }
`;

export const Title = styled.div<{ margin?: (number | string)[] }>`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)};
  margin: ${({ margin }) => (margin != undefined ? pxToRem(...margin) : '0 0 16px')};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(20, 26)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }
`;

export const OldNumber = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  text-align: center;
  color: ${colors.$e60000};
  margin-bottom: 32px;
`;

export const MigrationForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;
`;

export const Footer = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(14, 18)}
  display: flex;
  flex-direction: row;
`;

export const FooterDiv = styled.div`
  width: fit-content;
  text-align: start;
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 25px;
  margin-right: 10px;
`;

export const Checkbox = styled.div`
  input {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 3;
  }

  .checkmark::after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark {
    position: absolute;
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
    border-radius: ${pxToRem(3)};
    border: ${pxToRem(1)} solid ${colors.$bebebe};
  }

  &:hover input ~ .checkmark {
    border: ${pxToRem(1)} solid ${colors.$bebebe};
  }

  input:checked ~ .checkmark {
    background-color: ${colors.$00697c};
    border: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
`;

export const ParagraphTitle = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  margin-bottom: 8px;
`;

export const Paragraph = styled.div`
  div {
    margin: 0;
    text-align: left;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(20, 26)}
  }
`;

export const ModalContent = styled.div`
  p {
    margin: 0 0 24px;

    &:has(b) {
      margin: 0 0 8px;
    }
  }
`;

export const Question = styled.p`
  text-align: left;
  margin-top: 12px;
  margin-bottom: 0;
  span {
    font-weight: 700;
    ${pxToCssFont(16, 22)};
    transition: all 0.2s;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: ${colors.$e60000};
      text-decoration-color: red;
    }
  }
`;
