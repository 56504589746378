import {
  ProductSpecificationProductCharacteristic,
  AvailableValue,
} from '@vfit/consumer/data-access';
import { IPortInfoResponse, ICMSOperatorsList } from './getPortabilityInfoMobile.models';
import { IMultiPlayOfferingConfigurationResponse } from './multiPlayOfferingConfiguration.models';

/**
 * This method is used to retrieve the provider.
 * @param array
 * @param filteringProvider
 * @returns
 */
export const retrieveProvider = (array: AvailableValue[], filteringProvider: string) => {
  if (!array) return undefined;
  const value = array
    ?.map((item: AvailableValue) => {
      if (item?.displayValue === filteringProvider) {
        return item?.value;
      }
      return undefined;
    })
    .filter((item: any) => item !== undefined);
  return value[0];
};

/**
 * This Method is used to retrieve the MSDIN.
 * @param array
 * @param filteringString
 * @returns
 */
export const retrieveMSDIN = (
  array: ProductSpecificationProductCharacteristic[],
  filteringString: string = 'RLC_TelephoneNumber'
) => {
  if (!array) return undefined;
  const value = array
    ?.map((item: ProductSpecificationProductCharacteristic) => {
      if (item?.name === filteringString) {
        return item?.displayValue;
      }
      return undefined;
    })
    .filter((item: any) => item !== undefined);
  return value[0];
};

export const adaptSelectedValue = (selectedValue: string) => {
  const selectedValueBoolean: boolean = JSON.parse(selectedValue);
  return selectedValueBoolean ? 'Yes' : 'No';
};

export const filterOperatorsMobile = (
  data: IPortInfoResponse,
  valuesToBeDisplayed?: ICMSOperatorsList
) => {
  const operatorData =
    data.portInDetails.mobilePortInDetails[0]?.portInProviderCust?.availableValues;

  if (operatorData && valuesToBeDisplayed && valuesToBeDisplayed.elements?.length > 0) {
    const cmsValueList = valuesToBeDisplayed.elements
      .filter((element) => element.value && element.value !== '')
      .map((element) => element.value.toLowerCase());

    const filteredOperators = operatorData.filter((operator) =>
      cmsValueList.includes(operator.value.toLowerCase())
    );

    return {
      ...data,
      portInDetails: {
        ...data.portInDetails,
        mobilePortInDetails: [
          {
            ...data.portInDetails.mobilePortInDetails[0],
            portInProviderCust: {
              ...data.portInDetails.mobilePortInDetails[0]?.portInProviderCust,
              availableValues: filteredOperators,
            },
          },
        ],
      },
    } as IPortInfoResponse;
  }

  return data;
};

export const overrideOperatorsListMobile = (
  data: IPortInfoResponse,
  retrievedList?: ICMSOperatorsList
) => {
  const operatorData =
    data.portInDetails.mobilePortInDetails[0]?.portInProviderCust?.availableValues;

  if (operatorData && retrievedList && retrievedList.elements?.length > 0) {
    retrievedList.elements.forEach((entry) => {
      const foundIndex = operatorData.findIndex((operator) => {
        return entry.value?.toLowerCase() === operator.value.toLowerCase();
      });
      if (foundIndex !== -1 && entry.displayValue?.trim().length > 0) {
        operatorData[foundIndex].displayValue = entry.displayValue;
      }
    });

    return {
      ...data,
      portInDetails: {
        ...data.portInDetails,
        mobilePortInDetails: [
          {
            ...data.portInDetails.mobilePortInDetails[0],
            portInProviderCust: {
              ...data.portInDetails.mobilePortInDetails[0]?.portInProviderCust,
              availableValues: operatorData,
            },
          },
        ],
      },
    } as IPortInfoResponse;
  }

  return data;
};

export const reorderOperators = (data: IPortInfoResponse, newOrderList?: ICMSOperatorsList) => {
  const operatorData =
    data.portInDetails.mobilePortInDetails[0]?.portInProviderCust?.availableValues;

  if (operatorData && newOrderList && newOrderList.elements?.length > 0) {
    const amdocsValueList = operatorData.map((element) => element.value.toLowerCase());
    const validRetrievedOperators = newOrderList.elements.filter(
      (element) =>
        element.value &&
        element.value !== '' &&
        element.displayValue &&
        element.displayValue !== '' &&
        amdocsValueList.includes(element.value?.toLowerCase())
    );
    const cmsValueList = validRetrievedOperators.map((element) => element.value?.toLowerCase());

    const notRetrievedElements = operatorData.filter(
      (operator) => !cmsValueList.includes(operator.value?.toLowerCase())
    );

    const newOperatorList = validRetrievedOperators
      .map((operator) => {
        return {
          type: 'STRING',
          ...operator,
        };
      })
      .concat(notRetrievedElements);

    return {
      ...data,
      portInDetails: {
        ...data.portInDetails,
        mobilePortInDetails: [
          {
            ...data.portInDetails.mobilePortInDetails[0],
            portInProviderCust: {
              ...data.portInDetails.mobilePortInDetails[0]?.portInProviderCust,
              availableValues: newOperatorList,
            },
          },
        ],
      },
    } as IPortInfoResponse;
  }

  return data;
};

export const overrideOperatorsListFixed = (
  data: IMultiPlayOfferingConfigurationResponse,
  retrievedList?: ICMSOperatorsList
) => {
  const operatorData =
    data.fixedVoicePortInDetails?.[0]?.r1MissingMigrationFixedPortInSpecification
      ?.r1FirstDonatingNetworkOperator?.availableValues;

  if (operatorData && retrievedList && retrievedList.elements?.length > 0) {
    retrievedList.elements.forEach((entry) => {
      const foundIndex = operatorData.findIndex((operator) => {
        return entry.value?.toLowerCase() === operator.value.toLowerCase();
      });
      if (foundIndex !== -1 && entry.displayValue?.trim().length > 0) {
        operatorData[foundIndex].displayValue = entry.displayValue;
      }
    });

    return {
      ...data,
      fixedVoicePortInDetails: [
        {
          ...data.fixedVoicePortInDetails[0],
          r1MissingMigrationFixedPortInSpecification: {
            ...data.fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification,
            r1FirstDonatingNetworkOperator: {
              ...data.fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification
                .r1FirstDonatingNetworkOperator,
              availableValues: operatorData,
            },
          },
        },
      ],
    } as IMultiPlayOfferingConfigurationResponse;
  }

  return data;
};

