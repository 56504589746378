import { getCurrentUserType, IBillingCard, IUserType, useBillingAccount } from '@vfit/consumer/data-access';
import { InvoiceType } from './chooseInvoice.models';

const checkUserType = () => getCurrentUserType() === IUserType.NEXT_USER_ACTIVE || getCurrentUserType() === IUserType.LOGGED_USER;

export const organizeBillingInvoiceCard = (
  billingCheckboxProps: IBillingCard,
  address: string,
  billingEmail?: string,
  secondItemAction?: () => void
) => {
  const prefix = billingCheckboxProps?.billinginformation?.card;
  return {
    title: prefix?.title || '',
    description: prefix?.description || '',
    items: [
      {
        title: prefix?.choice?.option1?.title || '',
        description:
          prefix?.choice?.option1?.description.replace('FIRSTADDRESS', `${address}`) || '',
        extra: {
          text: '',
        },
        value: InvoiceType?.PAPER,
      },
      {
        title: prefix?.choice?.option2?.title || '',
        titleIcon: prefix?.choice?.option2?.icon,
        description:
          prefix?.choice?.option2?.description.replace('EMAIL', `${billingEmail || ''}`) || '',
        extra: {
          text: checkUserType() ? prefix?.choice?.option2?.linkAddressLabel || '' : '',
          action: checkUserType() ? secondItemAction : undefined,
          variant: "action"
        },
        value: InvoiceType?.EBILL,
      },
    ],
  };
};

export const useGetUpdatedBilling = () => {
  const {
    isSuccess,
    isLoading,
    isError,
    refetch: refecthBillingAccount,
  } = useBillingAccount({ enabled: false });

  const handleRefetch = (enabler: boolean) => enabler && refecthBillingAccount();

  return {
    handleRefetch,
    isError,
    isLoading,
    isSuccess
  }
};
