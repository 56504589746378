import { useEffect } from 'react';
import { API, PAGES, setTrackLink } from '@vfit/shared/data-access';
import { CMS_CONFIG, IWinBackCMS, useCmsConfig } from '@vfit/consumer/data-access';
import { useTracking, defineYupSchema, regex } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';
import { ID_FLOWS } from '../../checkout.constants';
import { ICheckoutData } from '../../checkout.models';
import { getUserInfo, getTagging } from '../../checkout.utils';

const WinbackNumberCard = ({ handleOnChangeEnableGoNext }: ICheckoutData) => {
  const { winbacknumber: cmsData } = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_WINBACK_DATA
  ) as IWinBackCMS;
  const { title, description, placeholder, disclaimer } = cmsData;

  const { setCurrentStepKey, winBackNumber, setWinBackNumber, isLoadingCart } = useCheckout();

  const taggingMobile = getTagging('winback');

  const { trackView } = useTracking({
    event: ['view'],
    event_label: 'Verify ID',
    opts: {
      event_category: ['sales', 'journey'],
      journey_name: 'winback',
      journey_type: 'journey',
      page_section: 'campaign',
      page_subsection: 'check number',
      page_type: 'Verify ID',
    },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'verify ID');

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.WINBACK_NUMBER);
  }, []);

  useEffect(() => {
    const isValid = !!winBackNumber?.match(regex['mobilePhoneNumber']);
    handleOnChangeEnableGoNext?.(isValid);
  }, [winBackNumber]);

  const onChangeData = (telephoneNumber: string) => {
    setWinBackNumber(telephoneNumber);
  };

  return (
    <PortabilityNumber
      title={title}
      description={description}
      label={placeholder}
      value={winBackNumber}
      disclaimer={disclaimer}
      onChangeData={onChangeData}
      isLoading={isLoadingCart}
      disabled={false}
      schema={defineYupSchema('phoneNumber')}
    />
  );
};

export default WinbackNumberCard;

