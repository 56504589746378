import { IProductCharacteristicsTable } from '@vfit/consumer/data-access';
import { purify } from '@vfit/shared/themes';

export const organizeProductCharacteristicsTable = (cmsData?: IProductCharacteristicsTable) => ({
  title: cmsData?.title || '',
  description: purify([cmsData?.description || ''])?.[0] || '',
  table: {
    btn: {
      buttonLabel: cmsData?.buttonLabel || '',
      buttonLink: cmsData?.buttonLink || '',
    },
    table: cmsData?.table.map((obj) => ({
      title: obj?.title || '',
      value: purify([obj?.value || ''])?.[0] || '',
    })),
  },
});
