import { CMS_CONFIG, ICoverageToolCongifg, useCmsConfig } from '@vfit/consumer/data-access';
import { ITrackError, ITrackErrorRes, LoggerInstance, TEventName } from '@vfit/shared/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { IAddressResponse } from '@vfit/shared/models';
import { COVERAGE_TOOL_FLOW } from './coverageToolModal.flow';
import { IStepCard } from '../StepperStateCards/stepperStateCards.models';

const getCoverageToolFlow = (isLockIn = false, isApp = false, isLogged = false) => {
  if (isLockIn && !isApp && !isLogged) {
    return COVERAGE_TOOL_FLOW.LOCKIN;
  }
  return COVERAGE_TOOL_FLOW.DEFAULT;
};

const coverageToolLockInMessage = () => {
  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;
  const errorNoOffer = {
    title: dataFromCms?.coveragetool?.lockIn?.modalErrorNoOffer?.title || '',
    description: dataFromCms?.coveragetool?.lockIn?.modalErrorNoOffer?.description || '',
    editLabel: dataFromCms?.coveragetool?.lockIn?.modalErrorNoOffer?.editLabel || '',
    submitLabel: dataFromCms?.coveragetool?.lockIn?.modalErrorNoOffer?.customAction?.title || '',
  };

  const errorNoCoverage = {
    title: dataFromCms?.coveragetool?.lockIn?.modalErrorNoCoverage?.title || '',
    description: dataFromCms?.coveragetool?.lockIn?.modalErrorNoCoverage?.description || '',
    editLabel: dataFromCms?.coveragetool?.lockIn?.modalErrorNoCoverage?.editLabel || '',
    submitLabel: dataFromCms?.coveragetool?.lockIn?.modalErrorNoCoverage?.clickToCall?.title || '',
  };

  return { errorNoOffer, errorNoCoverage };
};

const organizeFlowsAppend = (
  flowCards: IStepCard[],
  enableAppend: boolean,
  idFromStep: string,
  appendSteps: IStepCard[]
) => {
  const indexAppend = flowCards.findIndex((el) => el.title === idFromStep);
  let organizedFlows = flowCards.slice(0, indexAppend + 1);
  const lastFlows = flowCards.slice(indexAppend + 1);
  if (enableAppend) {
    const alreadyPresent = appendSteps[0].title === lastFlows[0].title;
    if (alreadyPresent) return flowCards;
    organizedFlows = organizedFlows.concat(appendSteps);
    organizedFlows = organizedFlows.concat(lastFlows);
  } else if (lastFlows[0].title === appendSteps[0].title) {
    const removedAppendInfo = lastFlows.slice(appendSteps.length);
    organizedFlows = organizedFlows.concat(removedAppendInfo);
  } else if (appendSteps?.findIndex((aS) => aS.title === lastFlows[0].title) > -1) {
    const removedAppendInfo = lastFlows.slice(1);
    organizedFlows = organizedFlows.concat(removedAppendInfo);
  } else {
    organizedFlows = organizedFlows.concat(lastFlows);
  }
  return organizedFlows;
};

const getAddress = () => {
  const createdAddressSaved = localStorage.getItem('createdAddress');
  const createdAddress: IAddressResponse =
    !!createdAddressSaved && JSON.parse(createdAddressSaved as string);
  LoggerInstance.debug('createdAddress : ', createdAddress);
  if (!createdAddress) return undefined;
  const dataToDisplay = [
    `${(createdAddress as IAddressResponse)?.street || ''} ${
      (createdAddress as IAddressResponse)?.streetNumber || ''
    }`,
    `${(createdAddress as IAddressResponse)?.postalCode || ''} ${
      (createdAddress as IAddressResponse)?.city
    }`,
    `${(createdAddress as IAddressResponse)?.stateOrProvince || ''}`,
  ];
  return {
    ...createdAddress,
    displayedAddress: dataToDisplay.join(', '),
  };
};

const getTrackError = (error: ITrackError, step?: string): ITrackErrorRes => {
  const pageError = `
  ${error?.error?.url || ''}_
  ${error?.error?.status || ''}_
  ${error?.errorType || ''}_
  ${error?.isBlocking ? 'blk' : 'nblk'}_
  ${step || ''}`;
  return {
    event_name: 'page_error' as TEventName,
    event_label_track: 'page_error',
    event_category: 'error',
    page_error: pageError,
    page_error_code: error?.errorCode || '',
    page_type: 'error page',
  };
};

export {
  getCoverageToolFlow,
  organizeFlowsAppend,
  getAddress,
  coverageToolLockInMessage,
  getTrackError,
};
