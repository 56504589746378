import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CMS_CONFIG,
  useCmsConfig,
  useValidateAddressMutation,
  ICoverageToolCongifg,
} from '@vfit/consumer/data-access';
import { API, PAGES, trackLink } from '@vfit/shared/data-access';
import { getPlaceId, useDeviceType } from '@vfit/shared/hooks';
import { LoggerInstance } from '@vfit/shared/data-access';
import { Form } from '@vfit/shared/components';
import { ContainerDeliveryAddress, Div, TextALignLeft, Wrapper } from './deliveryAddress.style';
import { IDeliveryAddressData, IDeliveryContact } from './deliveryAddress.models';
import { IGeoAddressElab } from '../CoverageTool/coverageTool.models';
import {
  checkAddressFromValue,
  composeAddress,
  getAddressFromLatLng,
} from '../CoverageTool/coverageTool.utils';
import { retrieveAddress } from './deliveryAddress.utils';

const DeliveryAddress = ({
  labels,
  state,
  onChangeData,
  onChangeCompleteAddress,
  onChangeHiddenStreetNumber,
  handleOpenModal,
}: IDeliveryAddressData) => {
  const { isDesktop } = useDeviceType();
  const mutation = useValidateAddressMutation();
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);
  const [isLoadingGeolocation, setIsLoadingGeolocation] = useState<boolean>(false);
  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;
  const errorMessageGeolocalizzation =
    coverageToolConfig?.coveragetool?.geolocalization?.alertError || '';

  let initialState = {
    placeId: false,
    streetNumber: true,
  };
  if (state.isHiddenStreetNumber !== undefined) {
    initialState.streetNumber = state.isHiddenStreetNumber;
  } else if (state?.street !== '' && state?.streetNumber !== '') {
    initialState = {
      placeId: false,
      streetNumber: false,
    };
  }

  const [hiddenFields, setHiddenFields] = useState(initialState);
  const methods = useFormContext<IDeliveryContact>();
  const {
    control,
    register,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = methods;

  // eslint-disable-next-line arrow-body-style
  const GetAddressObjectFromPlaceId = async (placeId: string) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await getPlaceId(placeId);
  };

  const setAddressObject = async (placeId: string | null, text?: string) => {
    //  Only if there is placeId
    if (placeId) {
      const addressObj = await GetAddressObjectFromPlaceId(placeId as string);
      //  Only if getAddressObject retireve an address object
      if (addressObj) {
        //  Shows street number text input at the first step if no retrievable from Google API placeId
        if (!addressObj?.streetNumber) {
          setHiddenFields((prevState) => ({
            ...prevState,
            streetNumber: false,
          }));
          onChangeHiddenStreetNumber?.(false);
        } else {
          setValue('streetNumber', addressObj.streetNumber);
          onChangeData?.();
        }
        //  sets all the other fields
        setValue('placeId', placeId);
        if (addressObj.city) setValue('city', addressObj.city);
        if (addressObj.postalCode) setValue('postalCode', addressObj.postalCode);
        if (addressObj.street) setValue('street', addressObj.street);
        if (addressObj.stateOrProvince) setValue('stateOrProvince', addressObj.stateOrProvince);
        setLatitude(addressObj.latitude);
        setLongitude(addressObj.longitude);
        await trigger(['city', 'postalCode', 'street', 'stateOrProvince']);
      }
      const inputs = getValues();

      const mutateLocation = {
        ...inputs,
        latitude: parseFloat(addressObj?.latitude || latitude),
        longitude: parseFloat(addressObj?.longitude || longitude),
      };

      mutation.mutate(mutateLocation);
      onChangeData?.();
    } else if (!placeId && !!text) {
      //  If placeId === null, it uses the input string 'text' to get the address object
      //  instead of the placeId. It uses useAddressGeocoder()
      const addressGeocoder = await checkAddressFromValue(text);

      if (addressGeocoder) {
        //  Check if with address from addrString + streetNumber can get all the fields.
        //  if not, it sets isManual to true and show all the form fields
        setLatitude(addressGeocoder.latitude);
        setLongitude(addressGeocoder.longitude);

        //  if all fields retrieved, it sets all of them
        if (addressGeocoder.placeId) setValue('placeId', addressGeocoder.placeId);
        if (addressGeocoder.streetNumber) setValue('streetNumber', addressGeocoder.streetNumber);
        if (addressGeocoder.city) setValue('city', addressGeocoder.city);
        if (addressGeocoder.postalCode) setValue('postalCode', addressGeocoder.postalCode);
        if (addressGeocoder.street) setValue('street', addressGeocoder.street);
        if (addressGeocoder.stateOrProvince)
          setValue('stateOrProvince', addressGeocoder.stateOrProvince);

        await trigger();

        const inputs = getValues();

        const mutateLocation = {
          ...inputs,
          latitude: addressGeocoder.latitude ? parseFloat(addressGeocoder.latitude) : 0,
          longitude: addressGeocoder.longitude ? parseFloat(addressGeocoder.longitude) : 0,
        };
        mutation.mutate(mutateLocation);
        onChangeData?.();
        //  if (props.onSubmit) props.onSubmit(mutateLocation, checkCoverageToolType(mutateLocation))
      }
    }
  };

  useEffect(() => {
    mutation.reset();
    setIsFormDisabled(false);
  }, []);

  const placeId = watch('placeId');
  const streetNumber = watch('streetNumber');

  useEffect(() => {
    //  first call of setAddressObject using placeId if no street number setted
    if (placeId && !streetNumber && hiddenFields.streetNumber) {
      setAddressObject(placeId).catch((e) => {
        LoggerInstance.debug(e);
      });
    } else if (placeId && streetNumber) {
      const inputs = getValues();
      const formattedAddress = retrieveAddress({
        city: inputs.city,
        postalCode: inputs.postalCode,
        stateOrProvince: inputs.stateOrProvince,
        street: inputs.street,
        streetNumber,
      });
      setAddressObject(null, formattedAddress).catch((e) => {
        LoggerInstance.debug(e);
      });
    }
  }, [placeId, streetNumber, hiddenFields.streetNumber, setValue]);

  useEffect(() => {
    if (!placeId) {
      setValue('streetNumber', '');
    }
  }, [placeId]);

  const onIconClick = () => {
    setValue('streetNumber', '');
    setValue('postalCode', '');
    setValue('stateOrProvince', '');
    setValue('city', '');
    setValue('street', '');
    setValue('placeId', undefined);
    setHiddenFields({
      placeId: false,
      streetNumber: true,
    });
    onChangeHiddenStreetNumber?.(true);
    onChangeData?.();
  };

  const findPosition = async (p: GeolocationPosition) => {
    trackLink('click geolocalization');
    return new Promise((resolve) => {
      getAddressFromLatLng(p?.coords?.latitude, p?.coords?.longitude)
        .then((value: any) => {
          let address: IGeoAddressElab = {
            street: '',
            city: '',
            province: '',
            postalCode: '',
          };
          if (value !== undefined) {
            setHiddenFields((prevState: any) => ({
              ...prevState,
              streetNumber: false,
            }));
            onChangeHiddenStreetNumber?.(false);
            address = composeAddress(value);
          }
          resolve(address);
          if (address.latitude) setLatitude(address.latitude);
          if (address.longitude) setLongitude(address.longitude);
          if (address.streetNumber) setValue('streetNumber', address.streetNumber);
          if (address.postalCode) setValue('postalCode', address.postalCode);
          if (address.province) setValue('stateOrProvince', address.province.toUpperCase());
          if (address.city) setValue('city', address.city.toUpperCase());
          if (address.street) setValue('street', address.street);
          if (address.placeId) setValue('placeId', address.placeId);
        })
        .catch((err) => {
          LoggerInstance.debug(err);
          // TODO: Error geolocation
        });
    });
  };

  const handleLoadingGeolocation = (isLoadingGeo: boolean) => {
    setIsLoadingGeolocation(isLoadingGeo);
  };

  return (
    <ContainerDeliveryAddress>
      <TextALignLeft>{labels?.description}</TextALignLeft>
      <Wrapper>
        <Div>
          <Controller
            control={control}
            name="placeId"
            render={({ field: { onChange, onBlur, ref } }) => (
              <Form.AddressInput
                label={labels?.deliveryAddress || ''}
                onChange={(event) => {
                  setHiddenFields((prevState) => ({
                    ...prevState,
                    streetNumber: true,
                  }));
                  onChangeHiddenStreetNumber?.(true);
                  onChange(event);
                }}
                changeValue={(completeAddress) => {
                  onChangeCompleteAddress?.(completeAddress);
                }}
                defaultValue={state.completeAddress || ''}
                hidden={hiddenFields.placeId}
                autoFocus={isDesktop}
                autoComplete="off"
                onIconClick={onIconClick}
                onBlur={onBlur}
                ref={ref}
                disabled={isFormDisabled || isLoadingGeolocation}
                findPosition={findPosition}
                onChangeGeolocation={handleLoadingGeolocation}
                errorMessage={errorMessageGeolocalizzation}
                placeholderAutocomplete={{
                  enabled: true,
                  label: 'Ricerca manuale',
                  action: handleOpenModal,
                }}
              />
            )}
          />
          <Form.TextInput
            placeholder=" "
            label={labels?.civicNumber || ''}
            hidden={hiddenFields.streetNumber}
            error={!hiddenFields.streetNumber ? errors.streetNumber?.message : ' '}
            autoFocus
            autoComplete="off"
            disabled={isFormDisabled}
            {...register('streetNumber', {
              onChange: () => {
                const inputs = getValues();
                const mutateLocation = {
                  ...inputs,
                };
                mutation.mutate(mutateLocation);
                onChangeData?.();
              },
            })}
          />
        </Div>
      </Wrapper>
    </ContainerDeliveryAddress>
  );
};

export default DeliveryAddress;
