import {
  ALL_EVENTS,
  checkBypassApiCallFromSIA,
  checkIsGoBackSIA,
  createPayloadFixed,
  getIsStartFromLockin,
  IProduct,
  isLockInIdServiceable,
  useDeleteItemShoppingCart,
  useGetCountry,
  useShoppingCartMutation,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import {
  IAddressResponse,
  ICountryObject,
  IOfferingServiceabilityResponse,
} from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { getLocalAddress, isAppLockin } from '../shoppingCart.utils';

export function useShoppingCartInfo(
  product: IProduct,
  isSuccessDynamicCart = true
): {
  isCompletedSilentLogin: boolean;
  isLoading: boolean;
  isError: boolean;
  isErrorServiceability?: boolean;
} {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [isErrorServiceability, setIsErrorServiceability] = useState<boolean>(false);
  const [isCompletedSilentLogin, setIsCompletedSilentLogin] = useState(false);
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const {
    isLoading: isCountriesLoading,
    refetch: refetchCountry,
    isSuccess: isSuccessCountry,
    isError: isErrorGetCountry,
  } = useGetCountry({ enabled: false });
  const {
    mutate: mutateShoppingCart,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
    data: shoppingCartData,
  } = useShoppingCartMutation();
  const { mutate: mutateDeleteShoppingCart } = useDeleteItemShoppingCart();
  const isSuccessGetCountry = isSuccessCountry || !!countries;

  const checkDeleteCart = () => {
    const offeringServiceability: IOfferingServiceabilityResponse =
      getFromLocalStorageByKey('offeringServiceability');
    if (offeringServiceability) {
      if (isSuccessShoppingCart && shoppingCartData) {
        const tvItemIdFromMutation: string = shoppingCartData?.cartItem?.[0]?.tv?.[0]?.id;
        if (tvItemIdFromMutation) mutateDeleteShoppingCart(tvItemIdFromMutation);
      }
    }
  };

  const checkShoppingCart = () => {
    const paymentErrorTitle = localStorage.getItem('pymT');
    const paymentErrorMessage = localStorage.getItem('pymM');
    if (isAppLockin() || isLockInIdServiceable()) {
      setIsLoading(!isSuccessGetCountry);
      setIsError(false);
      return;
    }
    const isCallShoppingCart =
      !checkIsGoBackSIA() &&
      !paymentErrorMessage &&
      !paymentErrorTitle &&
      isSuccessDynamicCart &&
      isCompletedSilentLogin &&
      !isLoadingShoppingCart &&
      !isSuccessShoppingCart &&
      isSuccessGetCountry;
    if (isCallShoppingCart) {
      const offeringServiceability: IOfferingServiceabilityResponse =
        getFromLocalStorageByKey('offeringServiceability');
      if (!offeringServiceability || !offeringServiceability?.id) {
        setIsErrorServiceability(true);
      } else {
        const createdAddress = getLocalAddress();
        const payload = createPayloadFixed(
          product?.offerId?.toString(),
          (createdAddress as IAddressResponse)?.id?.toString() || '',
          (offeringServiceability as IOfferingServiceabilityResponse)?.id?.toString() || ''
        );
        if (payload && product?.offerId) mutateShoppingCart(payload);
        else {
          setIsError(true);
        }
      }
    }
  };

  useEffect(() => {
    if (isErrorShoppingCart || isErrorGetCountry) {
      setIsError(true);
      setIsLoading(false);
    }
  }, [isErrorShoppingCart, isErrorGetCountry]);

  useEffect(() => {
    if (isSuccessShoppingCart && !isCountriesLoading) {
      checkDeleteCart();
      setIsLoading(false);
    }
  }, [isSuccessShoppingCart, isCountriesLoading]);

  useEffect(() => {
    checkShoppingCart();
  }, [isCompletedSilentLogin, isSuccessGetCountry, isSuccessDynamicCart]);

  useEffect(() => {
    const listener = () => {
      setIsCompletedSilentLogin(true);
    };
    if (!checkBypassApiCallFromSIA()) {
      if (!countries) refetchCountry();
    }
    if (getIsStartFromLockin() && !isLockInIdServiceable()) listener();
    if (typeof document !== 'undefined') {
      document.addEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
    }
    return () => {
      document.removeEventListener(ALL_EVENTS.SILENT_LOGIN_COMPLETE, listener);
    };
  }, []);

  return {
    isCompletedSilentLogin,
    isLoading,
    isError,
    isErrorServiceability,
  };
}
