import {
  GetCustomerResponse,
  IContactMediumCustomer,
  IShoppingCartResponse,
  isMobileProduct,
  KEY,
  IParameters,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { QueryClient } from 'react-query';
import {
  IDeliveryOptions,
  ILocateStoreResponse,
  IPickUpLocation,
  IValidAddressList,
} from '@vfit/shared/models';

// region NEW POST FLOW

const getAddressValidatedInDeliveryByCoords = (
  deliveryBaseData,
  validatedAddress: IValidAddressList
) => {
  const compareAddress = (address1, address2) =>
    address1.city?.toLowerCase() === address2?.city?.toLowerCase() &&
    address1.postalCode?.toLowerCase() === address2?.postalCode?.toLowerCase() &&
    address1.stateOrProvince?.toLowerCase() === address2?.stateOrProvince?.toLowerCase() &&
    address1.street?.toLowerCase() === address2?.street?.toLowerCase() &&
    address1.streetNumber?.toLowerCase() === address2?.streetNumber?.toLowerCase();

  const availableAddresses = deliveryBaseData?.recipientDetails?.availableAddresses || [];
  const recipientAddress = deliveryBaseData?.recipientDetails?.recipientAddress || [];
  if (availableAddresses?.length > 0) {
    const haveAvailableAddress = availableAddresses?.find((el) =>
      compareAddress(el, validatedAddress)
    );
    const haveRecipientAddress = compareAddress(recipientAddress, validatedAddress);
    if (haveAvailableAddress) return haveAvailableAddress;
    if (haveRecipientAddress) return recipientAddress;
    return null;
  }
  return null;
};

// endregion

const getCustomerAddress = (): IContactMediumCustomer => {
  const customer: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const contactMedium = customer?.[0]?.owningIndividual?.contactMedium || [];
  return contactMedium?.find((contact) => contact.type == 'postalAddress');
};

const isCustomerAddressSameRecipient = (deliveryBaseData) => {
  const customerPostalAddress = getCustomerAddress();
  const deliveryBaseAddressId = deliveryBaseData?.recipientDetails?.recipientAddress?.id;
  return customerPostalAddress?.id === deliveryBaseAddressId;
};

const isPresentCustomerAddressInDelivery = (deliveryBaseData) => {
  const availableAddresses = deliveryBaseData?.recipientDetails?.availableAddresses || [];
  const deliveryBaseAddressId = deliveryBaseData?.recipientDetails?.recipientAddress?.id;
  const customerPostalAddress = getCustomerAddress();
  if (!availableAddresses || availableAddresses?.length === 0) return true;
  if (availableAddresses?.length > 0) {
    return (
      availableAddresses?.some((el) => el.id === customerPostalAddress?.id) ||
      customerPostalAddress?.id === deliveryBaseAddressId
    );
  }
  return customerPostalAddress?.id === deliveryBaseAddressId;
};

const getCustomerAddressInDeliveryByCoords = (deliveryBaseData) => {
  const availableAddresses = deliveryBaseData?.recipientDetails?.availableAddresses || [];
  const customerPostalAddress = getCustomerAddress();
  if (availableAddresses?.length > 0) {
    return availableAddresses?.find(
      (el) =>
        el.city === customerPostalAddress?.city &&
        el.postalCode === customerPostalAddress?.postalCode &&
        el.stateOrProvince === customerPostalAddress?.stateOrProvince &&
        el.street === customerPostalAddress?.street &&
        el.streetNumber === customerPostalAddress?.streetNumber
    );
  }
  return null;
};

const updateCustomerAddressId = (availableShippingAddress) => {
  if (!availableShippingAddress) return;
  const customer: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const contactMedium = customer?.[0]?.owningIndividual?.contactMedium || [];
  const postalAddress = contactMedium?.find((contact) => contact.type == 'postalAddress');
  postalAddress.id = availableShippingAddress?.id;
  localStorage.setItem('customerData', JSON.stringify(customer));
};

// region Delivery API utils

/**
 * A getter method to format different API parameters based on the @KEY
 * @param key
 * @param customerId
 * @param advancedParams
 * @param evolvedParams
 * @returns
 */
const getParams = (
  key: KEY,
  customerId?: string,
  advancedParams?: any,
  evolvedParams?: any
): IParameters => {
  let parameters: IParameters;

  LoggerInstance.debug(advancedParams, evolvedParams, 'advanced and envolved params');

  switch (key) {
    case 'IsBase': {
      parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration', // First load of the delivery details
        customerId,
        filter: 'All',
        lob: 'WL', // const – Only for fibra delivery details
        identificationMethod: 'NA',
      };
      break;
    }
    case 'IsBaseMobile': {
      parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration', // First load of the delivery details
        customerId,
        filter: 'All',
        lob: 'WS', // const – Only for fibra delivery details
      };
      break;
    }
    case 'IsAdvanced': {
      parameters = {
        salesChannel: 'selfService',
        identificationMethod: advancedParams?.identificationMethod,
        addressId: advancedParams?.addressId,
        deliveryMethod: advancedParams?.deliveryMethod,
        filter: 'All',
        lob: 'WL',
      };
      break;
    }
    case 'IsAdvancedMobile': {
      parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration', // First load of the delivery details
        identificationMethod: advancedParams?.identificationMethod,
        customerId,
        filter: 'All',
        lob: 'WS', // const – Only for fibra delivery details
      };
      break;
    }
    case 'IsEvolved': {
      parameters = {
        salesChannel: 'selfService',
        mode: 'reviewMode',
        customerId,
        identificationMethod: evolvedParams?.identificationMethod,
        deliveryMethod: evolvedParams?.deliveryMethod,
        filter: 'All',
        lob: 'WL',
      };
      break;
    }
    case 'IsEvolvedMobile': {
      parameters = {
        salesChannel: 'selfService',
        identificationMethod: advancedParams?.identificationMethod,
        addressId: advancedParams?.addressId,
        deliveryMethod: advancedParams?.deliveryMethod,
        filter: 'All',
        lob: 'WS',
      };
      break;
    }
    case 'IsUltraMobile': {
      parameters = {
        salesChannel: 'selfService',
        mode: 'reviewMode',
        customerId,
        identificationMethod: evolvedParams?.identificationMethod,
        filter: 'All',
        lob: 'WS',
      };
      break;
    }
    case 'IsEditAddress': {
      parameters = {
        salesChannel: 'selfService',
        mode: 'loadConfiguration',
        customerId,
        identificationMethod: evolvedParams?.identificationMethod,
        deliveryMethod: evolvedParams?.deliveryMethod,
        filter: 'All',
        lob: 'WL',
      };
      break;
    }
    default:
      break;
  }

  return parameters;
};

/**
 * A getter method to retrieve the delivery code based on the deliveryItems
 * @param deliveryItems
 * @returns
 */
const deliveryCode = (deliveryItems: any): string => {
  if (deliveryItems && deliveryItems?.[0]?.details) {
    const objToFilter = deliveryItems?.[0]?.details;
    const filteredItem = objToFilter.filter(
      (itemToFilter: any) => itemToFilter?.code == 'T' || itemToFilter?.code == 'S'
    );
    return filteredItem?.[0]?.code;
  }
  return isMobileProduct() ? 'S' : 'T'; // or 'S'
};

/**
 * Method to inspect if the get delivery information has the Postal Office delivery option.
 * @param items
 * @returns
 */
export const hasPostalOfficeOption = (items: IDeliveryOptions[]): boolean =>
  items?.filter((element: any) => element?.code === 'PO').length > 0;

/**
 * Method to inspect if the get delivery information has the Pickup Store delivery option.
 * @param items
 * @returns
 */
export const hasPickupStoreOption = (items: IDeliveryOptions[]): boolean =>
  items?.filter((element: any) => element?.code === 'PP').length > 0;

/**
 * This is a custome useQuery hook to handle all the delivery Get types.
 * @param queryClient
 * @param key
 * @param pickupPoint
 * @returns
 */
const getQueryStringParams = (
  queryClient: QueryClient,
  key: KEY,
  pickupPoint?: IPickUpLocation | ILocateStoreResponse
): {
  cartId: string | undefined;
  parameters: any | undefined;
  queryEnabler: boolean;
} => {
  let parameters;
  let identificationMethod;
  let addressId;
  let deliveryMethod;
  let isEvolved;
  let queryEnabler = false;

  const customerData: GetCustomerResponse | undefined = getFromLocalStorageByKey('customerData');
  const patchCustomer: GetCustomerResponse = getFromLocalStorageByKey('patchCustomer');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');

  const customerId: string | undefined =
    customerData?.[0]?.id || patchCustomer?.owningIndividual?.id;

  const cartId = shoppingCart?.id;

  // Retrive new address ID.
  const deliveryCreatedAddressDataSaved = getFromLocalStorageByKey('deliveryCreatedAddress');
  const deliveryCreatedAddress: any | undefined =
    queryClient.getQueryData(['deliveryCreatedAddress']) || deliveryCreatedAddressDataSaved;

  // Retrieve installation address
  const createdAddressDataSaved = getFromLocalStorageByKey('createdAddress');
  const createdAddress: any | undefined =
    queryClient.getQueryData(['createdAddress']) || createdAddressDataSaved;

  LoggerInstance.debug('GET QUERY STRING PARAMS', key);

  if (key === 'IsBase') {
    LoggerInstance.debug('GET PARAM KEY IS BASE');
    parameters = getParams(key, customerId);
    queryEnabler = !!cartId && !!customerId && !!parameters;
  }

  if (key === 'IsAdvanced') {
    LoggerInstance.debug('GET PARAM KEY IS ADVANCED');
    const deliveryDetailsBaseQuery: any | undefined = queryClient.getQueryData([
      'deliveryDetailsIsBase',
    ]);
    const deliveryDetailsBaseSaved: string | null = localStorage.getItem('deliveryDetailsIsBase');

    const deliveryDetailsBase: any | undefined =
      deliveryDetailsBaseQuery ||
      (deliveryDetailsBaseSaved ? JSON.parse(deliveryDetailsBaseSaved as string) : undefined);

    if (deliveryDetailsBase && !!deliveryDetailsBase?.[0]) {
      // eslint-disable-next-line
      const { deliveryItems, identificationDetails } = deliveryDetailsBase?.[0];

      const selectedMethod = identificationDetails?.filter((item: any) => item.isSelected === true);

      identificationMethod = selectedMethod?.[0]?.code; // 'NA'

      const addressIdMobile = getCustomerAddress()?.id;

      addressId = isMobileProduct() ? addressIdMobile : createdAddress?.id; // recipientDetails?.recipientAddress?.id;

      deliveryMethod = deliveryCode(deliveryItems);

      const advancedParams = {
        identificationMethod,
        addressId,
        deliveryMethod,
      };
      parameters = getParams(key, undefined, advancedParams);
      queryEnabler = !!identificationMethod && !!addressId && !!deliveryMethod && !!parameters;
    }
  }

  if (key === 'IsEvolved') {
    const deliveryDetailsPostQuery: any = queryClient.getQueryData(['postDeliveryServiceDetails']);
    const deliveryDetailsPostSaved: string | null = localStorage.getItem(
      'postDeliveryServiceDetails'
    );
    const deliveryDetailsPost: any =
      deliveryDetailsPostQuery ||
      (deliveryDetailsPostSaved && JSON.parse(deliveryDetailsPostSaved)
        ? JSON.parse(deliveryDetailsPostSaved as string)
        : undefined);

    if (deliveryDetailsPost && deliveryDetailsPost?.[0]) {
      // eslint-disable-next-line
      const { deliveryItems, identificationDetails } = deliveryDetailsPost?.[0];

      const selectedMethod = identificationDetails?.filter((item: any) => item.isSelected === true);

      identificationMethod = selectedMethod?.[0]?.code;
      deliveryMethod = deliveryCode(deliveryItems);

      isEvolved = Boolean(!!deliveryDetailsPost && !!deliveryDetailsPost?.[0]);

      const evolvedParams = { identificationMethod, deliveryMethod };

      parameters = getParams(key, customerId, null, evolvedParams);
      queryEnabler =
        !!cartId && !!customerId && !!deliveryMethod && !!identificationMethod && !!isEvolved;
    }
  }

  if (key === 'IsPost') {
    parameters = [];
    LoggerInstance.debug('KEY IS POST ');

    const deliveryDetailsAdvancedKey = 'deliveryDetailsIsAdvanced';
    const deliveryDetailsAdvancedQuery: any | undefined = queryClient.getQueryData([
      deliveryDetailsAdvancedKey,
    ]);
    const deliveryDetailsAdvancedSaved: string | null = localStorage.getItem(
      deliveryDetailsAdvancedKey
    );
    let deliveryDetailsAdvanced: any | undefined =
      deliveryDetailsAdvancedQuery ||
      (deliveryDetailsAdvancedSaved
        ? JSON.parse(deliveryDetailsAdvancedSaved as string)
        : undefined);

    if (deliveryDetailsAdvanced && !Array.isArray(deliveryDetailsAdvanced)) {
      deliveryDetailsAdvanced = [deliveryDetailsAdvanced];
    }

    const isEditDeliveryAddressQuery: any | undefined = queryClient.getQueryData([
      'deliveryDetailsIsEditAddress',
    ]);
    const isEditDeliveryAddressSaved: string | null = localStorage.getItem(
      'deliveryDetailsIsEditAddress'
    );
    const isEditDeliveryAddress: any | undefined =
      isEditDeliveryAddressQuery ||
      (isEditDeliveryAddressSaved ? JSON.parse(isEditDeliveryAddressSaved as string) : undefined);

    LoggerInstance.debug('DELIVERY ADVANCED', deliveryDetailsAdvanced);

    let deliveryData = deliveryDetailsAdvanced;
    let recipientAddress;
    if (!!deliveryDetailsAdvanced && !pickupPoint?.storeId && !isEditDeliveryAddress) {
      const customerAddress = getCustomerAddress();
      const deliveryBase = isMobileProduct()
        ? getFromLocalStorageByKey('deliveryDetailsIsBaseMobile')
        : getFromLocalStorageByKey('deliveryDetailsIsBase');
      const foundedAddressInDelivery = getCustomerAddressInDeliveryByCoords(deliveryBase);
      if (foundedAddressInDelivery) {
        recipientAddress = foundedAddressInDelivery;
        updateCustomerAddressId(foundedAddressInDelivery);
      } else {
        recipientAddress = customerAddress;
      }
    } else if (!!deliveryDetailsAdvanced && !!isEditDeliveryAddress && !pickupPoint?.storeId) {
      recipientAddress = deliveryCreatedAddress || createdAddress;
    } else if (!!isEditDeliveryAddress && pickupPoint?.storeId) {
      LoggerInstance.debug('Third POST');
      LoggerInstance.debug('Pickuppoint delivery', pickupPoint);
      if (isEditDeliveryAddress)
        deliveryData = Array.isArray(isEditDeliveryAddress)
          ? isEditDeliveryAddress
          : [isEditDeliveryAddress];
      recipientAddress =
        (pickupPoint as IPickUpLocation)?.locationAddress ||
        (pickupPoint as ILocateStoreResponse)?.storeAddress;
    }

    if (deliveryData) {
      const getDeliveryItem = (deliveryItem) => {
        const { deliveryItems, identificationDetails, recipientDetails, deliveryDetails } =
          deliveryItem;
        LoggerInstance.debug('RECIPIENT DETAILS', recipientDetails);
        LoggerInstance.debug('RECIPIENT DELIVERY', deliveryItems);
        LoggerInstance.debug('RECIPIENT ADDR', recipientAddress);

        const selectedMethod = identificationDetails?.filter(
          (item: any) => item.isSelected === true
        );

        const identificationMethodCode = selectedMethod?.[0]?.code; // 'NA'

        const deliveryMethodCode = pickupPoint?.storeId
          ? (pickupPoint as IPickUpLocation)?.typePickupPoint || 'PO'
          : deliveryCode(deliveryItems);

        return {
          recipientAddress,
          recipientDetails,
          identificationMethod: identificationMethodCode,
          deliveryMethod: deliveryMethodCode,
          deliveryItems,
          deliveryDetails,
        };
      };

      if (Array.isArray(deliveryData)) {
        deliveryData?.forEach((delivery) => {
          parameters.push({ ...getDeliveryItem(delivery) });
        });
      } else {
        parameters.push({ ...getDeliveryItem(deliveryData) });
      }
    }
  }

  if (key === 'IsEditAddress') {
    LoggerInstance.debug('ISEDITADDRESS', pickupPoint);
    let params;

    const deliveryDetailsPost: any | undefined = getFromLocalStorageByKey(
      'postDeliveryServiceDetails'
    );

    if (deliveryDetailsPost) {
      LoggerInstance.debug('ISEDITADDRESS start flow', deliveryDetailsPost);
      let identificationDetails = [];
      if (Array.isArray(deliveryDetailsPost)) {
        identificationDetails = deliveryDetailsPost?.[0]?.identificationDetails;
      } else {
        identificationDetails = deliveryDetailsPost?.identificationDetails;
      }

      // Retrive identification method.
      const selectedMethod = identificationDetails?.filter((item: any) => item.isSelected === true);
      identificationMethod = selectedMethod?.[0]?.code;

      // TRUE ==> 'S' shipping to home
      if (!pickupPoint?.storeId) {
        // Retrive new address ID.
        const deliveryCreatedAddressFromQuery: any | undefined = queryClient.getQueryData([
          'deliveryCreatedAddress',
        ]);

        addressId = deliveryCreatedAddressFromQuery?.id;

        // Retrive delivery method.
        deliveryMethod = 'S';

        // Build envolved params for to get all params.
        const evolvedParams = { identificationMethod, deliveryMethod };
        params = getParams(key, customerId, null, evolvedParams);
        // Check if we have all params for to start request.
        queryEnabler =
          !!cartId && !!customerId && !!deliveryMethod && !!identificationMethod && !!addressId;
      } else if (pickupPoint?.storeId) {
        addressId =
          (pickupPoint as IPickUpLocation)?.locationAddress?.id ||
          (pickupPoint as ILocateStoreResponse)?.storeAddress?.id;
        // Retrive delivery method.
        deliveryMethod = (pickupPoint as IPickUpLocation)?.typePickupPoint || 'PO';
        // Build envolved params for to get all params.
        const evolvedParams = { identificationMethod, deliveryMethod };
        params = getParams(key, customerId, null, evolvedParams);
        // Check if we have all params for to start request.
        queryEnabler = !!cartId && !!customerId && !!deliveryMethod && !!identificationMethod;
      }

      // Add to parameters new addressID retrived.
      parameters = { ...params, addressId };
    }
  }

  if (key === 'IsBaseMobile') {
    LoggerInstance.debug('GET PARAM KEY IS BASE');
    parameters = getParams(key, customerId);
    queryEnabler = !!cartId && !!customerId && !!parameters;
  }

  if (key === 'IsAdvancedMobile') {
    LoggerInstance.debug('GET PARAM KEY IS ADVANCED');
    const deliveryDetailsBaseQuery: any | undefined = queryClient.getQueryData([
      'deliveryDetailsIsBaseMobile',
    ]);
    const deliveryDetailsBaseSaved: string | null = localStorage.getItem(
      'deliveryDetailsIsBaseMobile'
    );

    const deliveryDetailsBase: any | undefined =
      deliveryDetailsBaseQuery ||
      (deliveryDetailsBaseSaved ? JSON.parse(deliveryDetailsBaseSaved as string) : undefined);

    if (deliveryDetailsBase) {
      let deliveryItems;
      let identificationDetails;
      if (Array.isArray(deliveryDetailsBase)) {
        deliveryItems = deliveryDetailsBase?.[0]?.deliveryItems;
        identificationDetails = deliveryDetailsBase?.[0]?.identificationDetails;
      } else {
        deliveryItems = deliveryDetailsBase?.deliveryItems;
        identificationDetails = deliveryDetailsBase?.identificationDetails;
      }

      const selectedMethod = identificationDetails?.filter((item: any) => item.isSelected === true);

      identificationMethod = selectedMethod?.[0]?.code; // 'NA'
      deliveryMethod = deliveryCode(deliveryItems);

      const advancedParams = {
        identificationMethod,
        deliveryMethod,
      };
      parameters = getParams(key, customerId, advancedParams);
      LoggerInstance.debug('KEY ISADVANCEDMOBILE - identificationMethod: ', identificationMethod);
      LoggerInstance.debug('KEY ISADVANCEDMOBILE - addressId: ', addressId);
      LoggerInstance.debug('KEY ISADVANCEDMOBILE - deliveryMethod: ', deliveryMethod);
      LoggerInstance.debug('KEY ISADVANCEDMOBILE - parameters: ', parameters);

      queryEnabler = !!identificationMethod && !!customerId && !!deliveryMethod && !!parameters;
    }
  }

  if (key === 'IsEvolvedMobile') {
    LoggerInstance.debug('GET PARAM KEY IS EVOLVED');
    const deliveryDetailsBaseQuery: any | undefined = queryClient.getQueryData([
      'deliveryDetailsIsBaseMobile',
    ]);
    const deliveryDetailsBaseSaved: string | null = localStorage.getItem(
      'deliveryDetailsIsBaseMobile'
    );

    const deliveryDetailsBase: any | undefined =
      deliveryDetailsBaseQuery ||
      (deliveryDetailsBaseSaved ? JSON.parse(deliveryDetailsBaseSaved as string) : undefined);

    if (deliveryDetailsBase) {
      let deliveryItems;
      let identificationDetails;
      if (Array.isArray(deliveryDetailsBase)) {
        deliveryItems = deliveryDetailsBase?.[0]?.deliveryItems;
        identificationDetails = deliveryDetailsBase?.[0]?.identificationDetails;
      } else {
        deliveryItems = deliveryDetailsBase?.deliveryItems;
        identificationDetails = deliveryDetailsBase?.identificationDetails;
      }

      const selectedMethod = identificationDetails?.filter((item: any) => item.isSelected === true);

      identificationMethod = selectedMethod?.[0]?.code; // 'NA'

      const addressIdMobile =
        getCustomerAddress()?.id ||
        patchCustomer?.owningIndividual?.contactMedium?.find(
          (item) => item.type === 'postalAddress'
        )?.id;

      addressId = isMobileProduct() ? addressIdMobile : createdAddress?.id; // recipientDetails?.recipientAddress?.id;

      deliveryMethod = deliveryCode(deliveryItems);

      const advancedParams = {
        identificationMethod,
        addressId,
        deliveryMethod,
      };
      parameters = getParams(key, undefined, advancedParams);
      LoggerInstance.debug('identificationMethod', identificationMethod);
      LoggerInstance.debug('addressId', addressId);
      LoggerInstance.debug('deliveryMethod', deliveryMethod);
      LoggerInstance.debug('parameters', parameters);
      queryEnabler = !!identificationMethod && !!addressId && !!deliveryMethod && !!parameters;
    }
  }

  if (key === 'IsUltraMobile') {
    const deliveryDetailsPostQuery: any = queryClient.getQueryData(['postDeliveryServiceDetails']);
    const deliveryDetailsPostSaved: string | null = localStorage.getItem(
      'postDeliveryServiceDetails'
    );
    const deliveryDetailsPost: any =
      deliveryDetailsPostQuery ||
      (deliveryDetailsPostSaved && JSON.parse(deliveryDetailsPostSaved)
        ? JSON.parse(deliveryDetailsPostSaved as string)
        : undefined);

    if (deliveryDetailsPost) {
      // let deliveryItems;
      let identificationDetails;
      if (Array.isArray(deliveryDetailsPost)) {
        // deliveryItems = deliveryDetailsPost?.[0]?.deliveryItems;
        identificationDetails = deliveryDetailsPost?.[0]?.identificationDetails;
      } else {
        // deliveryItems = deliveryDetailsPost?.deliveryItems;
        identificationDetails = deliveryDetailsPost?.identificationDetails;
      }

      const selectedMethod = identificationDetails?.filter((item: any) => item.isSelected === true);

      identificationMethod = selectedMethod?.[0]?.code;
      // deliveryMethod = deliveryCode(deliveryItems);

      if (isMobileProduct()) {
        isEvolved = Boolean(deliveryDetailsPost);
      } else {
        isEvolved = Boolean(!!deliveryDetailsPost && !!deliveryDetailsPost?.[0]);
      }

      const evolvedParams = { identificationMethod, deliveryMethod };

      parameters = getParams(key, customerId, null, evolvedParams);
      queryEnabler = !!cartId && !!customerId && !!identificationMethod && !!isEvolved;
    }
  }

  return { cartId, parameters, queryEnabler };
};

// endregion

export {
  getCustomerAddress,
  isCustomerAddressSameRecipient,
  isPresentCustomerAddressInDelivery,
  getCustomerAddressInDeliveryByCoords,
  getAddressValidatedInDeliveryByCoords,
  updateCustomerAddressId,
  getParams,
  getQueryStringParams,
};
