import { useQueryClient, UseQueryResult } from 'react-query';
import { errorMock, IAuthorizeResponse, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { IAuthorizationResponse } from '@vfit/shared/models';

/**
 * This method is used retrieve the authorizationOrderService from ApicWeb.
 * With this response we can verify the payment submitted in SIA.
 * Method: GET
 * @param customOptions
 * @param orderId
 * @param siaOrderId
 * @returns
 */
const authorizationOrderService = (
  customOptions: CustomOptions,
  orderId: string,
  siaOrderId: string
) => {
  LoggerInstance.debug('authorizationOrderService  orderId: ', orderId);
  LoggerInstance.debug('authorizationOrderService  siaOrderId: ', siaOrderId);
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'authorization';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () =>
      nextClient.get(`${API.PAYMENT_MANAGEMENT_ORDER}/${orderId}/${API.AUTHORIZATION}`, {
        searchParams: {
          ...nextClient.searchParams,
          siaOrderId,
        },
      }) as Promise<IAuthorizationResponse>,
    options: {
      ...(customOptions && { ...customOptions }),
    },
  };
  return handleUseQuery(options) as UseQueryResult<IAuthorizationResponse, INextError>;
};

/**
 * This method is used to instantiate a custom useQuery hook to handle the authorizationOrderService.
 * @returns
 */
export const useAuthorizationOrder = (customOptions?: CustomOptions) => {
  const queryClient = useQueryClient();
  const customOptionsEnabled = typeof customOptions === undefined ? true : customOptions?.enabled;
  LoggerInstance.debug('useAuthorizationOrder : ', useAuthorizationOrder);

  const authorizeSaved = localStorage.getItem('authorize');
  const authorize: IAuthorizeResponse = !!authorizeSaved && JSON.parse(authorizeSaved as string);
  const siaOrderId = authorize?.authorizationParameters?.siaOrderId;
  LoggerInstance.debug('useAuthorizationOrder siaOrderId : ', siaOrderId);

  const shoppingCartSaved = localStorage.getItem('shoppingCart');
  const shoppingCart: IShoppingCartResponse =
    !!shoppingCartSaved && JSON.parse(shoppingCartSaved as string);
  LoggerInstance.debug('useAuthorizationOrder shoppingCart : ', shoppingCart);
  const cartId = shoppingCart?.id;

  LoggerInstance.debug('useAuthorizationOrder cartId : ', cartId);

  return authorizationOrderService(
    {
      enabled: Boolean(cartId) && Boolean(siaOrderId) && customOptionsEnabled,
      onSuccess: (data: IAuthorizationResponse) => {
        const status = data?.authorization?.status?.toLowerCase();
        if (data && data.authorization && status && status !== 'ok' && status !== 'timeoutok') {
          const error = errorMock(
            'authorization',
            {
              status: '200',
              url: API.AUTHORIZATION,
              statusText: '200',
            },
            status,
            data?.authorization?.errorCode
          );
          // save authorization ERROR into storage and queryClient
          queryClient.setQueryData('authorizationError', error);
          localStorage.setItem('authorizationError', JSON.stringify(error));
        } else {
          // save authorization object into storage
          localStorage.setItem('authorization', JSON.stringify(data));
          LoggerInstance.debug('useAuthorizationOrder data : ', data);
        }
      },
      onError: (error: INextError) => {
        const errorObject = errorMock('authorization', error);
        LoggerInstance.error(`ERROR - authorization: `, error);
        LoggerInstance.debug('useAuthorizationOrder error : ', errorObject);
        queryClient.setQueryData('authorizationError', errorObject);
        localStorage.setItem('authorizationError', JSON.stringify(errorObject));
      },
    },
    cartId,
    siaOrderId
  );
};

/**
 * This method is used to instantiate a custom useQuery hook to handle the authorizationOrderService.
 * @returns
 */
export const useGetAuthorization = (customOptions?: CustomOptions) => {
  const queryClient = useQueryClient();
  const customOptionsEnabled = typeof customOptions === undefined ? true : customOptions?.enabled;
  LoggerInstance.debug('useAuthorizationOrder : ', useAuthorizationOrder);
  const authorize: IAuthorizeResponse = getFromLocalStorageByKey('authorize');
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const siaOrderId = authorize?.authorizationParameters?.siaOrderId;
  const cartId = shoppingCart?.id;
  return authorizationOrderService(
    {
      keyDependency: customOptions?.keyDependency,
      enabled: Boolean(cartId) && Boolean(siaOrderId) && customOptionsEnabled,
      onSuccess: (data: IAuthorizationResponse) => {
        const status = data?.authorization?.status?.toLowerCase();
        if (data && data.authorization && status && status !== 'ok' && status !== 'timeoutok') {
          const error = errorMock(
            'authorization',
            {
              status: '200',
              url: API.AUTHORIZATION,
              statusText: '200',
            },
            status
          );
          queryClient.setQueryData('authorizationError', error);
          localStorage.setItem('authorizationError', JSON.stringify(error));
        } else {
          localStorage.setItem('authorizationRecurring', JSON.stringify(data));
          LoggerInstance.debug('useAuthorizationOrder data : ', data);
        }
      },
      onError: (error: INextError) => {
        const errorObject = errorMock('authorization', error);
        LoggerInstance.error(`ERROR - authorization: `, error);
        LoggerInstance.debug('useAuthorizationOrder error : ', errorObject);
        queryClient.setQueryData('authorizationError', errorObject);
        localStorage.setItem('authorizationError', JSON.stringify(errorObject));
      },
    },
    cartId,
    siaOrderId
  );
};
