import { useCallback, useEffect, useState } from 'react';
import { getFromLocalStorageByKey, LoggerInstance, useTracking } from '@vfit/shared/data-access';
import { API, openPopup, PAGES, setTrackLink, trackLink } from '@vfit/shared/data-access';
import {
  EMethodCode,
  IAvailablePaymentMethods,
  IOptionalText,
  ISIAFlowType,
  IUpdateProductPriceType,
  IWarningInfo,
} from '@vfit/shared/models';
import {
  CMS_CONFIG,
  getCurrentUserType,
  IGenericErrorCMS,
  IPayments,
  IS_AUTOMATIC_RECHARGE_SELECTED,
  IS_SELECTED_WALLET,
  IUserType,
  productSpecificError,
  SELECTED_PAYMENT,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { Skeleton, WarningInfo } from '@vfit/shared/components';
import { CheckboxCards } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyMobile.context';
import { getTagging, getUserInfo } from '../../checkout.utils';
import {
  organizeCheckBoxPayment,
  organizeCheckBoxPaymentItems,
  organizePaymentMethodError,
  SKELETON_SHAPE,
} from './recurrentPaymentCard.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { CONTEXT_KEY } from '../../../iBuyMobile.context.data';
import { ICheckoutData } from '../../checkout.models';
import { updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';
import { useUpdatePaymentMethodFlow } from '../../hooks/UseUpdatePaymentMethodFlow/useUpdatePaymentMethodFlow';

const RecurrentPaymentCard = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICheckoutData) => {
  const {
    checkoutErrors,
    availablePaymentMethods,
    hideStickyBar,
    customerFlow,
    isModalButtonSticky,
    product,
    setProduct,
    setCurrentStepKey,
    setAvailablePaymentMethods,
    setIsLastCard,
    setUpdateProductPriceCtrl,
    setHideStickyBar,
    setUpfrontPayment,
    setIsDisabledBackButton,
    setIsPreselectedCard,
  } = useCheckout();
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR_MOBILE
  ) as IGenericErrorCMS;
  const {
    paymentsmobile: { warningPriceChange, warningWaiting },
  } = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PAYMENTS_MOBILE) as IPayments;
  const cmsWarningUpdateOffer: IWarningInfo = {
    title: warningPriceChange?.title || '',
    subtitle: warningPriceChange?.subtitle || '',
    description: warningPriceChange?.description || '',
  };
  const cmsWarningWaitingOffer: IWarningInfo = {
    title: warningWaiting?.title || warningPriceChange?.title || 'Attendere',
    subtitle:
      warningWaiting?.subtitle ||
      warningPriceChange?.subtitle ||
      'Stiamo caricando i dati della tua offerta',
    description: warningWaiting?.description || warningPriceChange?.description || '',
    lottie: product?.loaders?.choosePayment || '',
  };
  const { buttonPaymentError, urlPaymentErrorCallMeNow } = productSpecificError(product);
  const isFamilyPlus = product?.isFamilyPlusProduct;
  const paymentKeyCMS = isFamilyPlus
    ? API.CMS_GET_FAMILY_PLUS_PAYMENT
    : API.CMS_GET_PAYMENTS_MOBILE;
  const paymentCMS = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], paymentKeyCMS) as IPayments;
  const {
    messageSelectionPaymentError,
    titleSelectionPaymentError,
    buttonLabelSelectionPayment,
    iconSelectionPayment,
    titleSelectedPaymentError,
    messageSelectedPaymentError,
    buttonLabelSelectedPayment,
    iconSelectedPayment,
  } = organizePaymentMethodError(paymentCMS);
  const taggingMobile = getTagging('add payment method');
  const { title, description, modal } = organizeCheckBoxPayment(paymentCMS);
  const [items, setItems] = useState<Array<IOptionalText>>([]);
  const [enablePut, setEnablePut] = useState(false);
  const [isShowUpdatePrice, setIsShowUpdatePrice] = useState(false);
  const paymentData = customerFlow?.payment?.data;
  const paymentIsLoading = customerFlow?.payment?.isLoading;
  const paymentIsError = customerFlow?.payment?.isError;
  const paymentIsSuccess = customerFlow?.payment?.isSuccess;

  const isAutoWalletEnabled = product?.isAutoWallet || false;
  const selectedPaymentMethod = isAutoWalletEnabled
    ? availablePaymentMethods?.find(
        (availablePayment) => availablePayment.methodCode === EMethodCode?.WALLET
      )
    : availablePaymentMethods?.find((availablePayment) => availablePayment.configured);

  const {
    isLoading: isLoadingUpdatePaymentMethod,
    isSuccess: isSuccessUpdatePaymentMethod,
    isError: isErrorUpdatePaymentMethod,
    error: errorUpdatePaymentMethod,
    handleResetState: resetPutPaymentState,
  } = useUpdatePaymentMethodFlow(
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderItemOnBillPayment
      ?.billingOfferIdForChangePaymentMethod || [''],
    paymentData?.orderPayment?.orderItemPayments?.[0]?.orderActionId || '',
    enablePut,
    selectedPaymentMethod,
    ISIAFlowType.RECURRENT
  );

  const { trackView } = useTracking({
    event:
      getCurrentUserType() === IUserType.PROSPECT_USER
        ? ['checkout_step9.0']
        : ['checkout_step9.1'],
    event_label: 'add payment method',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'add payment method');

  useEffect(() => {
    // reset for back SIA
    window.history.pushState(null, '', window.location.pathname);
    setCurrentStepKey(ID_FLOWS.RECURRENT_PAYMENT_CARD);
    setIsPreselectedCard(false);
    setUpfrontPayment(undefined);
    // mutateUPC('No');
    localStorage.removeItem(IS_AUTOMATIC_RECHARGE_SELECTED);
    setIsLastCard(true);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  const handleSelect = useCallback(
    (item: IOptionalText | undefined) => {
      LoggerInstance.debug('HANDLE SELECT IN AUTHORIZE', item);
      if (!item) return;
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        availablePaymentMethods.map((method) => ({
          ...method,
          configured: method.methodCode === item.value,
          selection: method.methodCode === item.value,
        }));
      setAvailablePaymentMethods(availablePaymentMethodsPrev);
      localStorage.setItem(SELECTED_PAYMENT, item.value || '');
      if (item.value !== EMethodCode.WALLET) {
        setEnablePut(true);
        setIsLastCard(true);
        setUpdateProductPriceCtrl({
          isUpdatePrice: false,
          isPriceUpdated: true,
        });
        handleOnChangeEnableGoNext?.(false);
      } else {
        setUpdateProductPriceCtrl({
          isUpdatePrice: true,
          isPriceUpdated: false,
        });
        setIsLastCard(false);
        setEnablePut(false);
        handleOnChangeEnableGoNext?.(true);
      }
    },
    [availablePaymentMethods]
  );

  useEffect(() => {
    const resetPaymentData = () => {
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.map((method) => ({
          ...method,
          selection: false,
        })) || [];

      setAvailablePaymentMethods(availablePaymentMethodsPrev || []);
      setItems(
        organizeCheckBoxPaymentItems(
          paymentCMS,
          paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods || [],
          -1
        )
      );
    };
    if (!paymentIsLoading && !paymentIsError && paymentData) {
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.map(
          (method, index) => ({
            ...method,
            selection: availablePaymentMethods[index]?.selection,
          })
        ) || [];
      const billingAccount = getFromLocalStorageByKey('billingAccount');
      setAvailablePaymentMethods(availablePaymentMethodsPrev || []);
      const walletEl =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.filter(
          (method) => method?.methodCode === EMethodCode.WALLET
        ) || [];
      setItems(
        organizeCheckBoxPaymentItems(
          paymentCMS,
          isAutoWalletEnabled
            ? walletEl
            : paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods || [],
          // Fix 28/10 => Loader Go back SIA, force user selection
          // availablePaymentMethods?.findIndex?.((aPm) => aPm.selection === true)
          -1,
          billingAccount?.availableNewPayMeans || []
        )
      );
    }
    if (!paymentIsLoading && paymentIsError) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      setHideStickyBar(false);

      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        customerFlow?.paymean?.error || undefined,
        titleSelectedPaymentError,
        messageSelectedPaymentError,
        action,
        buttonLabelSelectedPayment || buttonPaymentError,
        () => openPopup(urlPaymentErrorCallMeNow || '', action),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true,
        false,
        false,
        iconSelectedPayment || ''
      );
    }
  }, [paymentData, paymentIsError, paymentIsLoading]);

  const getSelectedItem = () => {
    const selectionOption = items?.find((i) => i.selection);
    return {
      title: selectionOption?.title || '',
      value: selectionOption?.value || '',
    };
  };

  useEffect(() => {
    const resetPaymentData = () => {
      const availablePaymentMethodsPrev: Array<IAvailablePaymentMethods> =
        paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods?.map((method) => ({
          ...method,
          selection: false,
        })) || [];

      setAvailablePaymentMethods(availablePaymentMethodsPrev || []);
      setItems(
        organizeCheckBoxPaymentItems(
          paymentCMS,
          paymentData?.orderPayment.orderOnBillPayment?.availablePaymentMethods || [],
          -1
        )
      );
    };
    if (isLoadingUpdatePaymentMethod && !localStorage.getItem(CONTEXT_KEY)) {
      setIsShowUpdatePrice(true);
      setHideStickyBar(true);
    }
    if (isLoadingUpdatePaymentMethod && localStorage.getItem(CONTEXT_KEY)) {
      localStorage.removeItem(CONTEXT_KEY);
    }
    if (
      isSuccessUpdatePaymentMethod &&
      !isLoadingUpdatePaymentMethod &&
      !isErrorUpdatePaymentMethod
    ) {
      if (isShowUpdatePrice) {
        setProduct({
          ...product,
          ...updateProductPrice(product, IUpdateProductPriceType.RESET),
        });
        setHideStickyBar(false);
        setIsShowUpdatePrice(false);
        localStorage.removeItem(IS_SELECTED_WALLET);
      }
      handleOnChangeEnableGoNext?.(true);
      setEnablePut(false);
      resetPutPaymentState();
    }
    if (isErrorUpdatePaymentMethod && !isLoadingUpdatePaymentMethod) {
      setEnablePut(false);
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      resetPaymentData();
      setHideStickyBar(false);

      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {},
        errorUpdatePaymentMethod || undefined,
        titleSelectionPaymentError,
        messageSelectionPaymentError,
        action,
        buttonLabelSelectionPayment || buttonPaymentError,
        () => openPopup(urlPaymentErrorCallMeNow || '', action),
        product?.removeCTC ? undefined : product?.genericErrorCallMeNow?.label || '',
        isModalButtonSticky,
        true,
        false,
        false,
        iconSelectionPayment || ''
      );
    }
  }, [isSuccessUpdatePaymentMethod, isLoadingUpdatePaymentMethod, isErrorUpdatePaymentMethod]);

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const trackGoNext = () => {
      const selectedPaymentMethod = availablePaymentMethods?.find((el) => el.configured);
      let linkName = '';
      switch (selectedPaymentMethod?.methodCode) {
        case EMethodCode.BANK_ACCOUNT:
          linkName = 'iban';
          break;
        case EMethodCode.CREDIT_CARD:
          linkName = 'carta di credito';
          break;
        case EMethodCode.WALLET:
          linkName = 'credito residuo';
          break;
        case EMethodCode.DEBIT_CARD:
          linkName = 'carta di debito';
          break;
        case EMethodCode.PREPAID_CARD:
          linkName = 'carta prepagata';
          break;
        case EMethodCode.PAYPAL:
          linkName = 'paypal';
          break;
        default:
          break;
      }
      trackLink(linkName);
    };
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [availablePaymentMethods, hideStickyBar]);

  useEffect(() => {
    setIsDisabledBackButton(isShowUpdatePrice);
  }, [isShowUpdatePrice]);

  const getWarningConfig = () => {
    const retrieveAlreadySelectedWallet = localStorage.getItem(IS_SELECTED_WALLET);
    if (product?.isUpdatedPriceWallet || retrieveAlreadySelectedWallet) {
      return cmsWarningUpdateOffer;
    }
    return cmsWarningWaitingOffer;
  };

  if (paymentIsLoading && !isLoadingUpdatePaymentMethod && items?.length > 0) {
    return <Skeleton {...SKELETON_SHAPE} />;
  }

  return (
    <>
      {isShowUpdatePrice && <WarningInfo {...getWarningConfig()} />}
      <div style={{ display: isShowUpdatePrice ? 'none' : 'block' }}>
        <CheckboxCards
          items={items}
          title={title || ''}
          description={description}
          bottomModal={modal}
          selectedItem={getSelectedItem()}
          value={getSelectedItem().value}
          setSelectedItem={() => {}}
          onSelectItem={handleSelect}
          isSuccess={paymentIsSuccess}
          disableItems={isLoadingUpdatePaymentMethod}
        />
      </div>
    </>
  );
};

export default RecurrentPaymentCard;

