import { CMS_CONFIG, IPersonalInfoCmsMobile, useCmsConfig } from '@vfit/consumer/data-access';
import { API, PAGES } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ListCard } from '@vfit/consumer/components';
import { organizeRecognitionSimVideoRecognition } from './recognitionSimVideoRecognition.utils';
import { getUserInfo, getTagging } from '../../../../checkout.utils';
import { IRecognitionSimVideoRecognition } from './recognitionSimVideoRecognition.models';
import { useCheckout } from '../../../../../iBuyMobile.context';

const RecognitionSimVideoRecognition = ({ isMNP }: IRecognitionSimVideoRecognition) => {
  const { simOptions } = useCheckout();

  const selectedSimOption = simOptions.simOptions.find((simOption) => simOption.selection);
  const isEsim = selectedSimOption?.value?.toLowerCase() === 'esim' || false;

  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const dataRecognitionSimVideoRecognition = organizeRecognitionSimVideoRecognition(
    dataFromCms,
    isEsim
  );

  const list = {
    title: dataRecognitionSimVideoRecognition.list.title,
    items: isMNP
      ? dataRecognitionSimVideoRecognition.list.portabilityItems
      : dataRecognitionSimVideoRecognition.list.items,
  };

  const taggingMobile = getTagging(isMNP ? 'mnp' : 'new sim');

  useTracking({
    event: ['checkout_step4.8'],
    event_label: isMNP ? 'VR new num mnp' : 'VR new num',
    opts: { ...taggingMobile?.opts, page_subsection: isEsim ? 'e-sim' : 'sim fisica' },
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  return <ListCard data={dataRecognitionSimVideoRecognition.data} list={list} />;
};

export default RecognitionSimVideoRecognition;
