import { UseQueryResult } from 'react-query';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { IFindCallerResponse } from './findCaller.models';

export interface IGetFindCaller {
  fiscalCode?: string;
  firstName?: string;
  secondName?: string;
  phoneNumber?: string;
  msisdn?: string;
  activeSubsCheckRequired?: 'true' | 'false';
  createCookie?: string;
  filters?: string;
}

/**
 * This method is used retrieve the findCallerService from ApicWeb.
 * With this respose we can gat the user type and handle it accordingly.
 * Method: GET
 * SearchParams:
 * salesChannel: selfService,
 * fiscalCode,
 * createCookie: 'true',
 * filters: 'partialDataFlag==false;noFiscalCodeFlag==Italian',
 * @param payload
 * @param customOptions
 * @returns
 */
const findCallerService = (payload: IGetFindCaller, customOptions?: CustomOptions) =>
  nextClient.get(API.FIND_CALLER, {
    searchParams: {
      ...payload,
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IFindCallerResponse>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the findCallerService
 * @param payload
 * @param customOptions
 */
export const useGetFindCaller = (
  payload: IGetFindCaller,
  customOptions?: CustomOptions
): UseQueryResult<IFindCallerResponse, INextError> => {
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'findCaller';

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () =>
      findCallerService(
        {
          ...payload,
          createCookie: 'true',
          filters: 'partialDataFlag==false;noFiscalCodeFlag==Italian',
          activeSubsCheckRequired: payload?.activeSubsCheckRequired || 'true',
        },
        customOptions
      ),
    options: {
      ...(customOptions && { ...customOptions }),
      onSuccess: (data: IFindCallerResponse) => {
        if (data) {
          localStorage.setItem('findCaller', JSON.stringify(data));
          const recognizedUser =
            !!data &&
            data?.resultInfo === 'existingNextCustomer' &&
            data?.customerRef &&
            data?.customerRef?.id;
          if (recognizedUser)
            localStorage.setItem('recognizedUser', JSON.stringify(recognizedUser));
          customOptions?.onSuccess?.(data);
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - findCaller: `, error);
        localStorage.setItem(`findCaller`, JSON.stringify(errorMock(`findCaller`, error)));
        customOptions?.onError?.(error);
      },
    },
  };

  return handleUseQuery(options) as UseQueryResult<IFindCallerResponse, INextError>;
};

