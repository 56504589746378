import { IOrganizeRedirect, IRedirect } from './coverageToolError.models';

export const organizeRedirect = ({ product, commonConfig, isAlternativeFWA }: IOrganizeRedirect): IRedirect => {
  const buttonListFwa = isAlternativeFWA ? [
    {
      text: product?.redirectFWA?.title || '',
    },
    {
      text: product?.redirectFWA?.alternativeOfferRedirect?.labelRedirect || '',
    },
  ] : [
    {},
    {
      text: product?.redirectFWA?.title || '',
    },
  ]

  return {
    titleFwa: product?.redirectFWA?.card?.title || '',
    titleR2: commonConfig?.personalinfo?.clientRecognition?.redirect?.title || '',
    descriptionFwa: product?.redirectFWA?.card?.description || '',
    descriptionR2: commonConfig?.personalinfo?.clientRecognition?.redirect?.description || '',
    linkRedirectFwa: product?.redirectFWA?.url || '',
    buttonListFwa,
    buttonListR2: [
      {},
      {
        text: commonConfig?.personalinfo?.clientRecognition?.redirect?.buttonLabel || '',
      },
    ],
  };
};
