import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { ISimSelectionPayload, ISimTypeResponse } from '@vfit/shared/models';

/**
 * @param payload
 * @param customOptions
 * @returns
 */
const simTypeSelectionService = (payload: ISimSelectionPayload, customOptions?: CustomOptions) =>
  nextClient.post(API.SIM_TYPE, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.ignoreUpdateToken && { ignoreUpdateToken: true }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<ISimTypeResponse>;

/**
 * @returns
 */
export const useSimTypeSelectionService = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'simSelection',
    ({ cartId }: { cartId: string }) => {
      const id = cartId && cartId.substring(0, cartId.length - 1);
      const payload: ISimSelectionPayload = {
        externalRefernce: cartId,
        id,
      };
      return simTypeSelectionService(payload, { ignoreUpdateToken: true });
    },
    {
      onSuccess: (data: ISimTypeResponse) => {
        localStorage.setItem('simSelection', JSON.stringify(data));
        queryClient.setQueryData('simSelection', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR on simSelection: `, error);
        localStorage.setItem(`simSelection`, JSON.stringify(error));
      },
    }
  );
};

