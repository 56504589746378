import styled from 'styled-components';
import { colors, fonts } from '@vfit/shared/themes';

export const TopProductContainer = styled.div`
  margin-bottom: 120px;
`;

export const ButtonDesc = styled.div`
  display: flex;
  margin: 0 23px 50px;
`;

export const ModalButtonContainer = styled.div`
  width: 300px;
  max-width: 400px;
  padding-bottom: 25px;
  margin: 0 auto;
  margin-top: 30px;

  div {
    width: 300px;
    max-width: 400px;
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }

  p {
    margin-top: 0;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 48px 23px 31px;
`;

export const ModalTitle = styled.div`
  div {
    margin: 0;
    margin-bottom: 24px;
  }
`;

export const ModalContent = styled.div`
  div {
    margin: 0;
  }
`;

export const StickyOfferContainerTopProduct = styled.div``;

