import { useMutation, useQueryClient } from 'react-query';
import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { IAddressResponse } from '@vfit/shared/models';
import { IAssociateCustomerPayload } from './associateCustomer.models';

/**
 * associateCustomerService is a method to associate the customer to the created ShoppingCart
 * Method: POST
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
const associateCustomerService = (
  cartId: string,
  payload: IAssociateCustomerPayload,
  customOptions?: CustomOptions
): Promise<{}> =>
  nextClient.post(`${API.SHOPPING_CART}/${cartId}${API.ASSOCIATE_CUSTOMER}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

export const useAssociateCustomer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ['associateCustomer'],
    // eslint-disable-next-line consistent-return
    () => {
      const address: IAddressResponse = getFromLocalStorageByKey('createdAddress');
      const findCaller = getFromLocalStorageByKey('findCaller');
      const customerData = getFromLocalStorageByKey('customerData');
      const fiscalCode = customerData?.[0]?.owningIndividual?.fiscalCode;
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id || '';
      return associateCustomerService(cartId, {
        customerId: customerData?.[0]?.id || findCaller?.customerRef?.id,
        fiscalCode: fiscalCode || findCaller?.individualRef?.fiscalCode,
        isLegacyCustomer: !findCaller?.customerSetup,
        contactId: customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId,
        productId: shoppingCart?.cartItem?.[0]?.product?.id,
        isCompatibilityRulesRequired: true,
        ruleActivityName: 'SetProductUser',
        orderActions: [{ orderActionId: shoppingCart?.cartItem?.[0]?.internet?.[0]?.id }],
        installationAddress: { id: address?.id },
      });
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('associateCustomer', JSON.stringify(data));
        queryClient.setQueryData('associateCustomer', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - associateCustomer: `, error);
        queryClient.setQueryData('associateCustomer', errorMock(`associateCustomer`, error));
        localStorage.setItem(
          `associateCustomer`,
          JSON.stringify(errorMock(`associateCustomer`, error))
        );
      },
    }
  );
};
