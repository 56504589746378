import {
  ICmsConfigAddressManual,
  IEditAddressCmsConfig,
  IEditAddressConfig,
} from '@vfit/shared/models';
import { IDeliveryInformation, IDeliveryInformationMobile, IMapCms } from '../cms.models';

export const organizeEditAddressFixed = (cmsConfig: IEditAddressConfig) => ({
  title: cmsConfig?.title || 'Cambia Indirizzo',
  billingSubtitle: cmsConfig?.billingSubtitle || 'Inserisci il nuovo indirizzo di fatturazione',
  shippingSubtitle: cmsConfig?.shippingSubtitle || '<p>Dove vorresti ricevere la tua sim</p>\r\n',
  lable: cmsConfig?.lable || 'I tuoi dati personali',
  paragraph_manual:
    cmsConfig?.paragraph_manual ||
    "<p>Siamo spiacenti ma l'indirizzo inserito non è valido. Per favore, inserire manualmente l'indirizzo corretto.</p>\r\n",
  labels: {
    addressInput: cmsConfig?.labels?.addressInput || 'Indirizzo',
    city: cmsConfig?.labels?.city || 'Comune',
    postalCode: cmsConfig?.labels?.postalCode || 'CAP',
    stateOrProvince: cmsConfig?.labels?.stateOrProvince || 'Provincia',
    streetNumber: cmsConfig?.labels?.streetNumber || 'Numero Civico',
    street: cmsConfig?.labels?.street || 'Indirizzo',
  },
  placeHolders: {
    addressInput: ' ',
    city: ' ',
    postalCode: ' ',
    stateOrProvince: ' ',
    streetNumber: ' ',
    street: ' ',
  },
  submitButton: cmsConfig?.submitButton || 'SALVA INDIRIZZO',
  submitButton_manual: cmsConfig?.submitButton_manual || 'AGGIORNA MANUALMENTE',
  genericError: cmsConfig?.genericError || 'Tutti i campi sono obbligatori.',
  apiError:
    cmsConfig?.apiError ||
    "Non è stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti.",
});

export const organizeEditAddressMobile = (cmsConfig: IEditAddressConfig) => ({
  title: cmsConfig?.title || 'Cambia Indirizzo',
  billingSubtitle: cmsConfig?.billingSubtitle || 'Inserisci il nuovo indirizzo di fatturazione',
  shippingSubtitle: cmsConfig?.shippingSubtitle || 'Cambia indirizzo di spedizione',
  lable: cmsConfig?.lable || 'I tuoi dati personali',
  paragraph_manual:
    cmsConfig?.paragraph_manual ||
    "<p>Siamo spiacenti ma l'indirizzo inserito non è valido. Per favore, inserire manualmente l'indirizzo corretto.</p>\r\n",
  labels: {
    addressInput: cmsConfig?.labels?.addressInput || 'Indirizzo',
    city: cmsConfig?.labels?.city || 'Comune',
    postalCode: cmsConfig?.labels?.postalCode || 'CAP',
    stateOrProvince: cmsConfig?.labels?.stateOrProvince || 'Provincia',
    streetNumber: cmsConfig?.labels?.streetNumber || 'Numero Civico',
    street: cmsConfig?.labels?.street || 'Indirizzo',
  },
  placeHolders: {
    addressInput: ' ',
    city: ' ',
    postalCode: ' ',
    stateOrProvince: ' ',
    streetNumber: ' ',
    street: ' ',
  },
  submitButton: cmsConfig?.submitButton || 'SALVA INDIRIZZO',
  submitButton_manual: cmsConfig?.submitButton_manual || 'AGGIORNA MANUALMENTE',
  genericError: cmsConfig?.genericError || 'Tutti i campi sono obbligatori.',
  apiError:
    cmsConfig?.apiError ||
    "Non è stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti.",
});

export const organizeMap = (mapData: IMapCms) => {
  const mapConfig = {
    titlePO: mapData?.storemap?.postalOffice?.title || '',
    titlePP: mapData?.storemap?.pickupPoint?.title || 'Pickup points',
    titleP: mapData?.storemap?.vfStore?.title || '',
    subTitlePO: mapData?.storemap?.postalOffice?.subtitle || '',
    subTitlePP:
      mapData?.storemap?.pickupPoint?.subtitle ||
      'Modifica il tuo indirizzo per trovare il pickup point più vicino a te.',
    subTitleP: mapData?.storemap?.vfStore?.subtitle || '',
    ctaModifyLable: mapData?.storemap?.common?.ctaModifyLable || '',
    switchLabelSx: mapData?.storemap?.common?.switchLabelSX || '',
    switchLabelDx: mapData?.storemap?.common?.switchLabelDX || '',
    ctaSaveLable: mapData?.storemap?.common?.ctaSaveLable || '',
  };

  const mapEditConfig = {
    title: mapData?.storemap?.common?.ctaSaveLable || '',
    subtitle: mapData?.storemap?.common?.subtitle || '',
  };

  return {
    mapEditConfig,
    mapConfig,
  };
};

export const organizeEditAddressOptionFixed = (data: IDeliveryInformation, isFwa?: boolean) => ({
  title: data?.deliveryinformation?.addressChange?.title || 'Cambia indirizzo',
  subtitle: data?.deliveryinformation?.addressChange?.subtitle || 'Indirizzo di spedizione',
  description: isFwa
    ? data?.deliveryinformation?.addressChange?.simShippingLabel || 'Dove vuoi ricevere la SIM?'
    : data?.deliveryinformation?.addressChange?.description || 'Dove vuoi ricevere il modem?',
  ctaLable: data?.deliveryinformation?.addressChange?.buttonLabel || 'Salva indirizzo',
});

export const organizeEditAddressOptionMobile = (data: IDeliveryInformationMobile) => ({
  title: data?.deliveryinformationm?.addressChange?.title || 'Cambia indirizzo',
  subtitle: data?.deliveryinformationm?.addressChange?.subtitle || 'Indirizzo di spedizione',
  description:
    data?.deliveryinformationm?.addressChange?.description || 'Dove vuoi ricevere la tua SIM?',
  ctaLable: data?.deliveryinformationm?.addressChange?.buttonLabel || 'Salva indirizzo',
});

export const organizeEditAddressManual = (
  cmsData: IEditAddressCmsConfig
): ICmsConfigAddressManual => ({
  addressInputCard: {
    title: cmsData?.editaddressconfig?.title || 'Indirizzo',
    description: 'Inserisci manualmente il tuo indirizzo',
    labels: {
      title: cmsData?.editaddressconfig?.title || 'Cambia indirizzo',
      street: cmsData?.editaddressconfig?.labels?.street || 'Indirizzo',
      streetNumber: cmsData?.editaddressconfig?.labels?.streetNumber || 'Numero civico',
      displayStateOrProvince: cmsData?.editaddressconfig?.labels?.stateOrProvince || 'Provincia',
      city: cmsData?.editaddressconfig?.labels?.city || 'Comune',
      postalCode: cmsData?.editaddressconfig?.labels?.postalCode || 'CAP',
    },
    buttonLabel: cmsData?.editaddressconfig?.submitButton_manual || 'AGGIORNA MANUALMENTE',
  },
  addressErrors: {
    city: {
      min: 'Campo non valido',
      matchRegex: 'Campo non valido',
      required: 'Campo obbligatorio',
    },
    postalCode: {
      min: 'Campo non valido',
      matchRegex: 'Campo non valido',
      required: 'Campo obbligatorio',
    },
    stateOrProvince: {
      min: 'Campo non valido',
      matchRegex: 'Campo non valido',
      required: 'Campo obbligatorio',
    },
    street: {
      min: 'Campo non valido',
      matchRegex: 'Campo non valido',
      required: 'Campo obbligatorio',
    },
    streetNumber: {
      min: 'Campo non valido',
      matchRegex: 'Campo non valido',
      required: 'Campo obbligatorio',
    },
  },
  genericErrorAddress:
    cmsData?.editaddressconfig?.apiError ||
    "Non è stato possibile trovare l'indirizzo. La preghiamo di ricontrollare i dati inseriti.",
});
