import { ISlides } from '@vfit/consumer/data-access';
import { IHeroSlider } from './heroSlider.models';

export const useHeroSlider = (heroSlides: IHeroSlider | undefined, isReverse?: boolean) => {
  const slides = heroSlides?.slides as ISlides[];
  if (!slides) {
    return {
      slides: [],
    };
  }
  return { slides: isReverse ? [...slides].reverse() : slides };
};
