import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  margin: 0 32px 170px;



  @media (max-width: ${breakpoints.tablet}) {
    // overflow-x: hidden;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 64px 110px;
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    width: 616px;
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px 0;
  width: 100%;
  background: ${colors.$ffffff};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 20px 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 32px 128px;
  }
`;
