import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

const basicGap = '16px'
const doubleGap = '32px'

export const ContainerDeliveryAddress = styled.div`
  margin-top: ${basicGap};
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 287px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 366px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-top: 0;
  }

  p {
    position: relative;
  }

  gap: ${basicGap};

  @media (min-width: ${breakpoints.tablet}) {
    gap: 20px;
  }
`;

export const Text = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  margin-top: ${basicGap};
  margin-bottom: ${doubleGap};
  text-align: center;
  font-weight: 400;
  ${pxToCssFont(20, 26)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const TextALignLeft = styled(Text)`
  text-align: left;
  margin: 0 0 ${basicGap};
  font-size: 14px;
`