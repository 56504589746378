import styled from 'styled-components';
import { breakpoints, colors, fonts } from '@vfit/shared/themes';

export const ProductSolutionsContainer = styled.div`
  display: block;

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    // margin-bottom: 120px;
  }
`;

export const ProductSolutionsText = styled.div`
  div {
    text-align: center;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 33%;

    div {
      text-align: left;
      margin-left: 81px;
    }
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    div {
      margin-left: 81px;
    }
  }
`;

