import { IPersonalInfoCmsMobile, replaceShippingCost } from '@vfit/consumer/data-access';

export const organizeRecognitionSimCourier = (
  data: IPersonalInfoCmsMobile,
  isEsim: boolean,
  price: string,
  isPortability: boolean
) => {
  const cmsConfig = isEsim
    ? data?.personalinfomobile?.recognitionCourierEsim
    : data?.personalinfomobile?.recognitionCourier;

  return {
    data: {
      title: replaceShippingCost(cmsConfig?.title || '', price),
      description: replaceShippingCost(cmsConfig?.description || '', price),
      textBold: replaceShippingCost(
        isPortability ? cmsConfig?.textMnp : cmsConfig?.textBold || '',
        price
      ),
      text: replaceShippingCost(cmsConfig?.text || '', price),
    },
    list: {
      title: replaceShippingCost(cmsConfig?.list?.[0].title || '', price),
      items: cmsConfig?.list?.[0]?.elements || [
        {
          text: '',
          icon: '',
        },
      ],
      portabilityItems: cmsConfig?.list?.[0].portability || [
        {
          text: '',
          icon: '',
        },
      ],
    },
  };
};
