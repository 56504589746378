import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ImageAtoms, LinkWithIcon, ButtonSlide, FullScreenModal } from '@vfit/shared/atoms';
import { Portal, Form } from '@vfit/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Title,
  Subtitle,
  Description,
  ImageDiv,
  InputDiv,
  ButtonDiv,
  ModalTitle,
  ModalDescription,
} from './vRCard.style';
import { IVRCardProps } from './vRCard.models';
import { validationSchema } from './vRCard.validation';
import { CardContainer, Divider } from '../../vRLanding.style';

const VRCard = ({ data, onSubmit, infoModal, handleTagging }: IVRCardProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    register,
    setValue,
    getValues,
    formState: { isValid, errors },
  } = useForm<{ simSerialNumber: string }>({
    resolver: yupResolver(validationSchema()),
    mode: 'all',
  });

  const handleSubmit = () => {
    const inputs = getValues();
    onSubmit(inputs.simSerialNumber);
  };

  const handleOpenModal = () => {
    if (handleTagging) handleTagging();
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CardContainer>
        <Title>{data?.title}</Title>
        <Divider />
        <Subtitle>{data?.subtitle}</Subtitle>
        <ImageDiv>
          <ImageAtoms
            nameCard="simImage"
            image={data?.image}
            style={{
              filter: 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 20%))',
              height: '83px',
              width: '150px',
            }}
          />
          <Description>{data?.description}</Description>
        </ImageDiv>
        <InputDiv>
          <div className="textInput">
            <Form.TextInput
              label={data?.labels?.input || ''}
              placeholder=" "
              error={errors.simSerialNumber?.message}
              {...register('simSerialNumber', {
                onChange: (event) => {
                  setValue('simSerialNumber', event.target.value);
                },
              })}
            />
          </div>
          <LinkWithIcon text={data?.labels?.anchor} size="1" onClick={handleOpenModal} />
          <ButtonDiv>
            <ButtonSlide
              label={data?.labels?.button}
              onClick={handleSubmit}
              disabled={!isValid}
              buttonColor="#e60000"
              labelColor="#fff"
              hoverColor={isValid ? '#bd0000' : 'bebebe'}
              clickColor={isValid ? '#a10000' : 'bebebe'}
              disabledButtonColor="#bebebe"
              disabledBorderColor="#bebebe"
              disabledTextColor="#fff"
            />
          </ButtonDiv>
        </InputDiv>
      </CardContainer>
      <Portal wrapperId="video-recognition">
        <FullScreenModal show={isModalOpen} onCloseModal={handleCloseModal}>
          <ModalTitle>{infoModal?.title || ''}</ModalTitle>
          <ModalDescription>{infoModal?.modalContent || ''}</ModalDescription>
        </FullScreenModal>
      </Portal>
    </>
  );
};

export default VRCard;
