import {
  BlankPage,
  HubPage,
  HubDevicePage,
  ProductDetailPage,
  DeviceDetailPage,
  ShoppingCartPage,
  HomepagePage,
  NotFoundPage,
} from '@vfit/consumer/components';
import { IPageProps, usePage } from '@vfit/consumer/data-access';

export default function Layout({ isApp }: IPageProps) {
  const { page } = usePage() || {};
  const params = page?.params;
  switch (params?.layout) {
    case 'HUB':
      if (params?.elements?.apiDeviceList) {
        return <HubDevicePage isApp={isApp} />;
      }
      return <HubPage isApp={isApp} />;
    case 'BLANK':
      if (params?.elements?.heroslider) {
        return <HomepagePage isApp={isApp} />;
      }
      return <BlankPage isApp={isApp} />;
    case 'PRODUCT_DETAIL':
      if (params?.elements?.apiDeviceList) {
        return <DeviceDetailPage isApp={isApp} />;
      }
      return <ProductDetailPage isApp={isApp} />;
    case 'SHOPPING_CART':
      return <ShoppingCartPage />;
    default:
      break;
  }
  return <NotFoundPage isApp />;
}
