import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { useMutation, useQueryClient } from 'react-query';
import { IAddressResponse } from '@vfit/shared/models';
import { IAddressRequest, IModifyCreatedAddress } from './address.models';

/**
 * Create Address Service for api createdAddress.
 * This method is used to create the address and generate an ID from ApicWeb services.
 * API "care/v1/address"
 * Method: POST
 * @param payload
 * @param customOptions
 * @returns
 */
export const createAddressService = (
  payload: IAddressRequest,
  customOptions?: CustomOptions
): Promise<IAddressResponse> =>
  nextClient.post(API.ADDRESS, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run createAddressService using the validateAddress service response as input.
 * Call this after validateAddress/offeringServiceability have been called.
 * @returns
 */
export const useCreateAddress = (customOptions?: CustomOptions) => {
  const queryClient = useQueryClient();
  let keyString = 'createdAddress';

  return useMutation(
    'createAddress',
    (req: IModifyCreatedAddress) => createAddressService(req.address, customOptions),
    {
      onMutate: async (data: IModifyCreatedAddress) => {
        if (data?.isBilling === true) {
          keyString = 'billingCreatedAddress';
        } else if (data?.isDelivery === true) {
          keyString = 'deliveryCreatedAddress';
        } else if (data?.isPickup === true) {
          keyString = 'pickupCreatedAddress';
        }
      },
      onSuccess: (data: IAddressResponse) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
        customOptions?.onError?.(error);
      },
    }
  );
};

