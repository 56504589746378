import { ICMSWidgetType, IPageProps, ITaggingCMS } from '@vfit/business-data-access';
import { TrackingPageContextProvider } from '@vfit/business-data-access';
import { Footer, Header } from '@vfit/shared/components';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { withProtected } from './hoc/withProtected';
import { PageLayout } from './layout/pageLayout.style';
import Widgets from '../Widgets/widgets';

const Blank = ({ page, isApp }: IPageProps) => {
  const { elements, haveHeader, isHeaderSmall, haveFooter } = page?.params || {};
  const { header, tagging } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);

  // se c'è l'headerSmall e non c'è il topHero (oppure non è il primo widget) viene aggiunto il litePadding
  const widgets = Object.values(elements ?? {}).filter(
    (e) => ICMSWidgetType[e?.type] !== undefined
  );
  const topHeroWidgetIndex = Object.values(widgets ?? {}).findIndex(
    (widget) => widget.type === ICMSWidgetType.TOP_HERO
  );

  return (
    // questo div serve per aggiungere padding a fondo pagina quando presente lo sticky
    <div>
      {haveHeader && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          litePadding={isHeaderSmall && topHeroWidgetIndex !== 0}
        />
      )}
      <PageLayout className="noPadding">
        <TrackingPageContextProvider
          tagging={elements?.tagging as ITaggingCMS}
          layer="blankDataLayer"
        >
          <Widgets aemElements={elements ?? {}} />
        </TrackingPageContextProvider>
      </PageLayout>
      {haveFooter && !isApp && <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />}
    </div>
  );
};

const BlankProtected = withProtected()(Blank);

export default BlankProtected;
