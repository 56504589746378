import { CustomText } from '@vfit/shared/atoms';
import { IconListItem } from '@vfit/shared/components';
import { Children } from 'react';
import { Wrapper, Title, TextContainer, Text, TextBold, List } from './listCard.style';
import { IListCardProps } from './listCard.models';

const ListCard = ({ data, list }: IListCardProps) => {
  const { title, description, text, textBold } = data;
  return (
    <Wrapper>
      <Title>{title}</Title>
      <CustomText modal text={description} size={20} lineHeight={26} />
      <TextContainer>
        <TextBold>{textBold}</TextBold>
        <Text>{text}</Text>
      </TextContainer>
      <div>
        <TextBold>{list.title}</TextBold>
        <List>
          {Children.toArray(
            list.items?.map((item) => <IconListItem text={item.text} icon={item.icon} />)
          )}
        </List>
      </div>
    </Wrapper>
  );
};

export default ListCard;
