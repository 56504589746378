import { breakpoints, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const CardFrame = styled.section`
  margin: ${pxToRem(32, 20, 16)};

  @media (min-width: ${breakpoints.tablet}) {
    margin: ${pxToRem(32, 40)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: ${pxToRem(32, 54)};
  }

  button {
    margin-top: ${pxToRem(32)};
  }
`;

export const Title = styled.h2`
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(30, 38)};
  margin-top: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    margin-top: ${pxToRem(60)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Message = styled.p`
  font-family: ${fonts.regular};
  font-weight: 400;
  text-align: center;
  ${pxToCssFont(20, 26)}
  margin: ${pxToRem(0, 0, 32)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }
`;

export const Loading = styled.div`
  position: absolute;

  div {
    position: relative;
    left: 120px;
    top: 11px;

    svg {
      width: 25px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    div {
      left: 155px;
    }
  }
`;
