import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { errorMock, GetCustomerResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IAddressResponse,
  IAddressType,
  IPickUpLocation,
  IPickUpLocationPayload,
} from '@vfit/shared/models';

/**
 * This method calls the searchPickUpLocationService.
 * This methods is able to find the pickup points related to the address.
 * Method: POST
 * @param payload
 * @param customOptions
 * @returns
 */
const searchPickUpLocationsService = (
  payload: IPickUpLocationPayload,
  customOptions?: CustomOptions
): Promise<IPickUpLocation[]> =>
  nextClient.post(`${API.CARE_V1}/${API.SEARCH_PICKUP_LOCATIONS}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run searchPickUpLocationsService using the addressID.
 * @returns
 */
export const useSearchPickUpLocations = () => {
  const queryClient = useQueryClient();
  let keyString = 'searchPickUpLocations';
  return useMutation(
    ['searchPickUpLocationsMutation'],
    ({ id, options }: { id?: string; options?: IAddressType }) => {
      const installationAddress = getFromLocalStorageByKey('createdAddress');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const contactMedium = customerData?.[0]?.owningIndividual?.contactMedium || [];
      const postalAddress = contactMedium?.find((contact) => contact.type == 'postalAddress');
      let addressId = (installationAddress as IAddressResponse)?.id || postalAddress?.id;
      if (id) {
        addressId = id;
      }
      let payload: IPickUpLocationPayload;
      switch (options) {
        case IAddressType.PP:
          payload = {
            addressId,
            geoLocations: {},
            radius: 30,
            additionalIdType: 'PICKUP_POINTX9',
          };
          keyString = 'searchPickUpStoreLocations';
          break;
        case IAddressType.PO:
          payload = {
            addressId,
            geoLocations: {},
            radius: 30, // const (comin from AEM)
            additionalIdType: 'POSTAL_OFFICEX9', // Selected store type
          };
          break;
        default:
          payload = {
            addressId,
            geoLocations: {},
            radius: 30, // const (comin from AEM)
            additionalIdType: 'POSTAL_OFFICEX9', // Selected store type
          };
      }
      return searchPickUpLocationsService(payload);
    },
    {
      onMutate: async (data) => {
        if (data?.id) {
          keyString += data.id;
        }
      },
      onSuccess: (data: IPickUpLocation[]) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
