import {ICharacteristicDevice, ISku} from "@vfit/consumer/data-access";

export const evaluateSizes = (models: ISku[], characteristics: ICharacteristicDevice) =>{
  const allSizes = characteristics?.sizes?.elements?.map((el: { id: string; value: string }) => el) || [];
  return allSizes?.filter((el)=> models?.find((model) => model.idSize === el.id.toString()));
}

export const evaluateColors= (models: ISku[]) => {
  const displayNameSet: Set<string> = new Set();
  const colors: {id: string; displayName: string}[] = [];

  models.forEach((model) => {
    if (!displayNameSet.has(model.displayNameColor)) {
      displayNameSet.add(model.displayNameColor);
      colors.push({id: model.idColor, displayName: model.displayNameColor});
    }
  });

  return colors;
}

export const isAvailableSize = (selectedSku: ISku, models: ISku[], sizeId:string) : boolean => {
  const skuFiltered = models.filter((sku)=> sku.displayNameColor ===selectedSku.displayNameColor);
  return skuFiltered.some((sku) => sku.idSize === sizeId);
}

export const isAvailableColor = (selectedSku: ISku, models: ISku[], colorName: string) : boolean => {
  const skuFiltered = models.filter((sku) => sku.idSize === selectedSku.idSize);
  return skuFiltered.some((sku) => sku.displayNameColor === colorName);
}

export const selectSkuBySize = (models: ISku[], sizeId: string, selectedSku: ISku) => {
  const modelsByColor = models.filter((model) => model.displayNameColor === selectedSku.displayNameColor);
  const foundSku = modelsByColor.find((model) => model.idSize === sizeId);
  if (foundSku){
    return foundSku;
  }
    return models.find((model) => model.idSize === sizeId);

}
export const showBorderColor = (inputColor: string) => {
  let showBorder = false;
  const rgbColor = inputColor.startsWith("#") ? hexToRgbConvert(inputColor) : inputColor;

  const match = rgbColor.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  const r = parseInt(match?.[1] || '', 10);
  const g = parseInt(match?.[2] || '', 10);
  const b = parseInt(match?.[3] || '', 10);

  if (r > 245 && g > 245 && b > 245) {
    showBorder =  true;
  }
  return showBorder;
}

const hexToRgbConvert =(hexColor: string)=> {
  const r = parseInt(hexColor.slice(1, 3), 16);
  const g = parseInt(hexColor.slice(3, 5), 16);
  const b = parseInt(hexColor.slice(5, 7), 16);

  return `rgb(${r}, ${g}, ${b})`;
}
