import { useEffect } from 'react';
import {
  errorMock,
  getLastPaymentMethod,
  useGetAuthorization,
  useGetPaymean,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';

export const useAuthorizationAsyncInformation = () => {
  const {
    isStartAuthorizationAsyncInfo,
    authorizationAsyncInfo,
    setUpfrontPayment,
    setIsStartAuthorizationAsyncInfo,
    setAuthorizationAsyncInfo,
  } = useCheckout();

  const {
    data: authorizationData,
    error: authorizationError,
    isSuccess: isSuccessAuthorization,
    isError: isErrorAuthorization,
    isLoading: isLoadingAuthorization,
    refetch: refetchAuthorization,
  } = useGetAuthorization({
    enabled: false,
  });

  const {
    data: payMeanData,
    error: payMeanError,
    isError: isErrorPayMean,
    isLoading: isLoadingPayMean,
    isSuccess: isSuccessPayMean,
    refetch: refetchPayMean,
  } = useGetPaymean({ enabled: false });

  useEffect(() => {
    if (isLoadingPayMean) {
      setAuthorizationAsyncInfo({
        ...authorizationAsyncInfo,
        payMean: {
          ...authorizationAsyncInfo.payMean,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorPayMean) {
      setAuthorizationAsyncInfo({
        ...authorizationAsyncInfo,
        payMean: {
          ...authorizationAsyncInfo.payMean,
          isSuccess: false,
          isError: true,
          isLoading: false,
          error: errorMock('payMean', payMeanError),
        },
      });
    } else if (isSuccessPayMean) {
      const payMeanElement = payMeanData && getLastPaymentMethod(payMeanData);
      if (payMeanElement) {
        setUpfrontPayment(payMeanElement);
      }
      setAuthorizationAsyncInfo({
        ...authorizationAsyncInfo,
        payMean: {
          ...authorizationAsyncInfo.payMean,
          data: payMeanData,
          error: undefined,
          isError: false,
          isSuccess: true,
          isLoading: false,
        },
      });
    }
  }, [isErrorPayMean, isLoadingPayMean, isSuccessPayMean]);

  useEffect(() => {
    if (isLoadingAuthorization) {
      setAuthorizationAsyncInfo({
        ...authorizationAsyncInfo,
        authorization: {
          ...authorizationAsyncInfo.authorization,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorAuthorization) {
      setAuthorizationAsyncInfo({
        ...authorizationAsyncInfo,
        authorization: {
          ...authorizationAsyncInfo.authorization,
          data: authorizationData,
          isSuccess: false,
          isError: true,
          isLoading: false,
          error: errorMock('authorization', authorizationError),
        },
      });
    } else if (isSuccessAuthorization && authorizationData) {
      const statusAuthorization = authorizationData?.authorization?.status?.toLowerCase();
      const isErrorStatus = statusAuthorization !== 'ok' && statusAuthorization !== 'timeoutok';
      if (isErrorStatus && authorizationData?.authorization?.status) {
        setAuthorizationAsyncInfo({
          ...authorizationAsyncInfo,
          authorization: {
            ...authorizationAsyncInfo.authorization,
            data: authorizationData,
            isSuccess: false,
            isError: true,
            isLoading: false,
            error: errorMock(
              'authorization',
              {
                status: '200',
                url: API.AUTHORIZATION,
                statusText: '200',
              },
              statusAuthorization,
              authorizationData?.authorization?.errorCode
            ),
          },
        });
      } else {
        setAuthorizationAsyncInfo({
          ...authorizationAsyncInfo,
          payMean: {
            ...authorizationAsyncInfo.payMean,
            isSuccess: false,
            isLoading: true,
            isError: false,
          },
          authorization: {
            ...authorizationAsyncInfo.authorization,
            data: authorizationData,
            error: undefined,
            isError: false,
            isSuccess: true,
            isLoading: false,
          },
        });
        refetchPayMean();
      }
    }
  }, [isErrorAuthorization, isLoadingAuthorization, isSuccessAuthorization]);

  useEffect(() => {
    if (isStartAuthorizationAsyncInfo) {
      // reset authorization
      setAuthorizationAsyncInfo({
        ...authorizationAsyncInfo,
        authorization: {
          isLoading: false,
          isError: false,
          isSuccess: false,
          data: undefined,
        },
      });
      setIsStartAuthorizationAsyncInfo(false);
      refetchAuthorization();
    }
  }, [isStartAuthorizationAsyncInfo]);

  return null;
};

