import {
  handleFirstCharacterOfSentence,
  IDeliveryInformation,
  organizeEditAddressFixed,
} from '@vfit/consumer/data-access';
import { LoggerInstance } from '@vfit/shared/data-access';
import { IAddressDataObject, IEditAddressCmsConfig } from '@vfit/shared/models';
import { ISkeleton } from '@vfit/shared/components';
import { IOwningIndividual } from '../../checkout.models';
import { isOutdoor, isTechFWA } from '../../checkout.utils';

const getDataOrganized = (
  deliveryInformation: IDeliveryInformation,
  owningIndividual: IOwningIndividual,
  installationAddress: IAddressDataObject,
  shippingAddress: IAddressDataObject,
  billingAddress: IAddressDataObject,
  editAddressData: IEditAddressCmsConfig,
  showTechAddress: boolean
) => {
  const prefix = deliveryInformation?.deliveryinformation;
  // this is label for fwa indoor or outdoor before editing shipping
  const shippingAddressFWA = isOutdoor()
    ? prefix?.outdoor?.shippingLabel || ''
    : prefix?.indoor?.shippingLabel || '';
  // this is label for fwa indoor or outdoor after editing shipping
  const editShippingAddressFWA = isOutdoor()
    ? prefix?.outdoor?.shippingLabelChange || ''
    : prefix?.indoor?.shippingLabelChange || '';

  const deliveryData = {
    topTitle: prefix?.resume?.title || '',
    ...(showTechAddress && {
      description: isTechFWA()
        ? prefix?.indoor?.description || ''
        : prefix?.resume?.description || '',
    }),
    ...(!showTechAddress && {
      description: isTechFWA()
        ? prefix?.outdoor?.description || ''
        : prefix?.resume?.description || '',
    }),
    userInfoTitle: prefix?.commonLabel?.installationAddress || 'Indirizzo di installazione',
    billingData: {
      title: prefix?.addressBilling?.bottomTitle || 'Indirizzo di fatturazione',
      matchAddress:
        prefix?.addressBilling?.title ||
        'L’indirizzo di fatturazione corrisponde all’indirizzo di installazione.',
      noMatch: prefix?.addressBilling?.description || 'Non corrisponde?',
      labelEdit: prefix?.addressBilling?.linkLabelEdit || 'Modifica Indirizzo',
      changeAddress: prefix?.addressBilling?.linkLabel || "Cambia l'indirizzo di fatturazione",
    },
    shippingData: {
      title: prefix?.addressDelivery?.bottomTitle || "Cambia l'indirizzo di spedizione",
      matchAddress:
        prefix?.addressDelivery?.title ||
        "L'indirizzo di spedizione corrisponde all'indirizzo di installazione.",
      noMatch: prefix?.addressDelivery?.description || 'Non corrisponde?',
      labelEdit: prefix?.addressDelivery?.linkLabel || 'Modifica Indirizzo',
      changeAddress: prefix?.addressDelivery?.linkLabelEdit || "Cambia l'indirizzo di spedizione",
    },
    noteLabels: {
      noteTitle: prefix?.commonLabel?.descriptionNote || 'Inserisci le note per la consegna',
      noteTitleAfterEdit: prefix?.commonLabel?.linkLabelEdit || 'Modifica note per la consegna',
    },
    noteModalConfig: {
      title: prefix?.commonLabel?.linkLabel || 'Note di consegna',
      description: prefix?.commonLabel?.modalTextLabel || 'Inserisci le note per la consegna',
      textLabel: prefix?.commonLabel?.placeholderNotes || 'Note per la consegna',
      ctaLabel: prefix?.commonLabel?.modalCtaLabel || 'Salva note',
      titleMod: prefix?.commonLabel?.linkLabelEdit || 'Modifica note per la consegna',
      descriptionMod: prefix?.commonLabel?.linkLabel || 'Note di consegna',
    },
    labelShippingFWA: shippingAddressFWA || '',
    labelChangeAddressFWA: editShippingAddressFWA || '',
    afterShippingEdit: isTechFWA()
      ? editShippingAddressFWA || ''
      : prefix?.addressChange?.subtitle || '',
  };

  return {
    topTitle: deliveryData?.topTitle || '',
    description: deliveryData?.description || '',
    userInfoTitle: deliveryData?.userInfoTitle,
    userData: {
      lastName: owningIndividual?.lastName,
      firstName: owningIndividual?.firstName,
      address: handleFirstCharacterOfSentence(shippingAddress?.street || '').concat(
        ' ',
        shippingAddress?.streetNumber || ''
      ),
      city: handleFirstCharacterOfSentence(shippingAddress?.city || ''),
      zipCode: shippingAddress?.postalCode || '',
      province: shippingAddress?.stateOrProvince || '',
    },
    billingData: {
      matchAddress: deliveryData?.billingData?.matchAddress || '',
      noMatch: deliveryData?.billingData?.noMatch || '',
      title: deliveryData?.billingData?.title || '',
      labelEdit: deliveryData?.billingData?.labelEdit || '',
      changeAddress: deliveryData?.billingData?.changeAddress || '',
      ctaAction: () => LoggerInstance.debug('billing'),
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(billingAddress?.street).concat(
          ' ',
          billingAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(billingAddress?.city),
        zipCode: billingAddress?.postalCode || '',
        province: billingAddress?.stateOrProvince || '',
      },
    },
    shippingData: {
      matchAddress: isTechFWA()
        ? deliveryData?.labelShippingFWA
        : deliveryData?.shippingData?.matchAddress,
      noMatch: deliveryData?.shippingData?.noMatch || '',
      title: deliveryData?.shippingData?.title || '',
      labelEdit: deliveryData?.shippingData?.labelEdit || '',
      changeAddress: deliveryData?.shippingData?.changeAddress || '',
      ctaAction: () => LoggerInstance.debug('shipping'),
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(shippingAddress?.street || '').concat(
          ' ',
          shippingAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(shippingAddress?.city),
        zipCode: shippingAddress?.postalCode || '',
        province: shippingAddress?.stateOrProvince || '',
        note: shippingAddress?.note || '',
        postalOfficeDescription: shippingAddress?.postalOfficeDescription || '',
      },
    },
    installationData: {
      matchAddress: isTechFWA()
        ? deliveryData?.labelShippingFWA
        : deliveryData?.shippingData?.matchAddress,
      noMatch: deliveryData?.shippingData?.noMatch || '',
      title: deliveryData?.shippingData?.title || '',
      labelEdit: deliveryData?.shippingData?.labelEdit || '',
      changeAddress: deliveryData?.shippingData?.changeAddress || '',
      addressData: {
        lastName: owningIndividual?.lastName || '',
        firstName: owningIndividual?.firstName || '',
        address: handleFirstCharacterOfSentence(installationAddress?.street || '').concat(
          ' ',
          installationAddress?.streetNumber || ''
        ),
        city: handleFirstCharacterOfSentence(installationAddress?.city),
        zipCode: installationAddress?.postalCode || '',
        province: installationAddress?.stateOrProvince || '',
        note: installationAddress?.note || '',
        postalOfficeDescription: installationAddress?.postalOfficeDescription || '',
      },
    },
    changeAddressFwa: deliveryData?.labelChangeAddressFWA || '',
    afterEditAddress: deliveryData?.afterShippingEdit || '',
    noteModalConfig: deliveryData?.noteModalConfig,
    noteLabels: deliveryData?.noteLabels,
    editAddressConfig: organizeEditAddressFixed(editAddressData.editaddressconfig),
    disableAddNotes: deliveryInformation?.deliveryinformation?.disableAddNotes === 'true',
  };
};

const skeletonShape: ISkeleton = {
  margins: [60, 30],
  tabletMargins: [32, 15],
  groups: [
    { heights: 45, marginBottom: 15 },
    { heights: 60, marginBottom: 32 },
    { heights: 60, marginBottom: 15 },
    { heights: 75, repeat: 4, gap: 15 },
    { heights: 45, marginBottom: 15 },
  ],
};

export { getDataOrganized, skeletonShape };
