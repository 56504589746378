import { useEffect, useState } from 'react';
import {
  useGetDataFromStorage,
  useCreateAddress,
  useValidateAddress,
  CUSTOMER_TYPES,
  ISilentLoginResponse,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { LoggerInstance } from '@vfit/shared/data-access';
import {
  CoverageErrorType,
  IAddressResponse,
  IValidateAddressRequest,
  IValidateAddressResponse,
} from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IAPIError,
  ICoverageToolFlow,
  IUseAddressMobileFlowPayload,
} from './useAddressMobileFlow.models';

export const useAddressMobileFlow = ({
  onConfirmAddress,
}: IUseAddressMobileFlowPayload): ICoverageToolFlow => {
  const queryClient = useQueryClient();
  const [address, setAddress] = useState<IAddressResponse>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<number>(CoverageErrorType.KO);
  const [mutationIsNotFull, setMutationIsNotFull] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const coexistenceData: {
    userType: string;
    trustCookie: string;
  } = getFromLocalStorageByKey('coexistenceUser');
  const silentLoginR2: ISilentLoginResponse | undefined = queryClient.getQueryData('silentLoginR2');
  const isLoggedR2 =
    silentLoginR2?.flowId &&
    silentLoginR2?.flowId === 'existingLogin' &&
    coexistenceData?.userType === CUSTOMER_TYPES.LEGACY;

  /**
   * Hook for useValidateAddress() & useCreateAddress();
   */
  const validateAddressMutation = useValidateAddress();
  const createAddressMutation = useCreateAddress(); ///

  const isLocalStorageDisable = localStorage.getItem('disableReset');

  const addressObject: IValidateAddressRequest | undefined = queryClient.getQueryData(
    'coverageToolInputAddress'
  );

  const validatedAddress = useGetDataFromStorage<IValidateAddressResponse>(
    'validatedAddress',
    !!isLocalStorageDisable
  );

  const createdAddress = useGetDataFromStorage<IAddressResponse>(
    'createdAddress',
    !!isLocalStorageDisable
  );

  /**
   *
   * Enabling API calls only if the addressObject retrieved from google autocomplete
   * is fully filled
   */
  const flowEnabler =
    !!(addressObject as IValidateAddressRequest)?.city &&
    !!(addressObject as IValidateAddressRequest)?.postalCode &&
    !!(addressObject as IValidateAddressRequest)?.stateOrProvince &&
    !!(addressObject as IValidateAddressRequest)?.street &&
    !!(addressObject as IValidateAddressRequest)?.streetNumber &&
    onConfirmAddress;

  /**
   * Check address already validated
   * when user click on edit address this check prevent the api call if address is already validated
   * @return boolean
   */
  const checkAddressAlreadyValidated = (): boolean => {
    LoggerInstance.debug({ validatedAddress });
    const validAddress = (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0];
    if (!validAddress) return false;
    return (
      validAddress.city?.toLowerCase() === addressObject?.city?.toLowerCase() &&
      validAddress.postalCode?.toLowerCase() === addressObject?.postalCode?.toLowerCase() &&
      validAddress.stateOrProvince?.toLowerCase() ===
        addressObject?.stateOrProvince.toLowerCase() &&
      validAddress.street?.toLowerCase() === addressObject?.street?.toLowerCase()
    );
  };

  const checkValidateAddress = () => {
    const showError = () => {
      LoggerInstance.debug('ERROR POPUP');
      setIsLoading(false);
      setIsError(true);
      setErrorType(CoverageErrorType.KO);
      setErrorMessage((validatedAddress as IAPIError)?.errorMessage || '');
    };
    LoggerInstance.debug(
      'serviceabilityAddressMutation validatedAddress',
      validatedAddress as IValidateAddressResponse
    );
    LoggerInstance.debug(
      'serviceabilityAddressMutation.mutate PAYLOAD',
      (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0]
    );

    if (validateAddressMutation.isError || (validatedAddress as IAPIError)?.error) {
      showError();
    }
  };

  useEffect(() => {
    if (!flowEnabler && !!addressObject) {
      setMutationIsNotFull(true);
    }
    if (addressObject && flowEnabler) {
      const addressAlreadyValidated = checkAddressAlreadyValidated();
      if (!addressAlreadyValidated && !validatedAddress) {
        setIsLoading(true);
        validateAddressMutation.mutate({ placeObject: addressObject });
      }
    }
  }, [addressObject, flowEnabler]);

  useEffect(() => {
    if (!!validatedAddress && validateAddressMutation.isSuccess) {
      checkValidateAddress();
    }
  }, [!!validatedAddress, validateAddressMutation.isSuccess]);

  useEffect(() => {
    if (validateAddressMutation.isError && (validatedAddress as IAPIError)?.error) {
      setIsError(true);
      setErrorMessage((validatedAddress as IAPIError)?.errorMessage);
    }
  }, [validateAddressMutation.isError, !!validatedAddress]);

  useEffect(() => {
    if (
      !isError &&
      (!createdAddress || !!isLocalStorageDisable) &&
      !createAddressMutation.isLoading &&
      (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0] &&
      validateAddressMutation.isSuccess
    ) {
      createAddressMutation.mutate({
        address: (validatedAddress as IValidateAddressResponse)?.validAddressList?.[0],
      });
    }
  }, [isError, validateAddressMutation.isSuccess]);

  useEffect(() => {
    if (!isError && createAddressMutation.isSuccess && createAddressMutation?.data) {
      setIsSuccess(true);
      setAddress(createAddressMutation?.data);
    }
  }, [createAddressMutation.isSuccess, createAddressMutation?.data]);

  useEffect(() => {
    if (isSuccess) {
      setIsLoading(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isLoggedR2) {
      setErrorType(CoverageErrorType.R2_LOGGED);
      setIsLoading(false);
      setIsError(true);
    }
  }, [isLoggedR2]);

  return {
    data: address,
    validatedAddress: validateAddressMutation?.data,
    mutationIsNotFull,
    isSuccess,
    isLoading,
    isError,
    errorMessage,
    errorType,
  };
};
