import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 287px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 366px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 406px;
  }
`;

export const Title = styled.div`
  padding: 32px 0 16px;
  font-family: ${fonts.exbold};
  text-align: center;
  font-weight: 400;
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    padding: 60px 0 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Text = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  margin-top: ${pxToRem(16)};
  margin-bottom: ${pxToRem(32)};
  text-align: center;
  font-weight: 400;
  ${pxToCssFont(20, 26)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${pxToRem(40)};
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin-top: 0;
  }

  p {
    position: relative;
    margin-bottom: 0;
  }

  gap: ${pxToRem(16)};

  @media (min-width: ${breakpoints.tablet}) {
    gap: ${pxToRem(20)};
  }
`;

export const Footer = styled.div`
  display: flex;
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(14, 18)}
  margin-top: ${pxToRem(16)};
  margin-bottom: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: ${pxToRem(20)};
  }
`;

export const Skeleton = styled.div`
  margin-top: 3.75rem;
`;
