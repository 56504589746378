import { CoverageToolAuto } from '@vfit/consumer/components';
import { useEffect, useState } from 'react';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ICommonData } from '../../../StepperStateCards/stepperStateCards.models';
import { DEFAULT_ADDRESS, useCoverageToolModalProvider } from '../../coverageToolModal.context';
import { ICoverageToolType, IInputs } from '../../../CoverageTool/coverageTool.models';

const CoverageToolAutoCard = ({ handleGoNextSlide }: ICommonData) => {
  const isDisableReset = localStorage.getItem('disableReset');
  const [enableNext, setEnableNext] = useState(false);
  const { setAddress, offerId, setIsManual, setIsAddressConfirmed } =
    useCoverageToolModalProvider();
  const queryClient = useQueryClient();

  const resetCoverageToolData = async (forceReset?: boolean) => {
    await resetData(queryClient, ['coverageToolInputAddress']);
    if (!isDisableReset || forceReset)
      await resetData(queryClient, [
        'offeringServiceability',
        'validatedAddress',
        'createdAddress',
      ]);
  };

  useEffect(() => {
    resetCoverageToolData();
  }, []);

  const onSubmit = (data: IInputs, typeCoverage: ICoverageToolType) => {
    setAddress({
      ...data,
      typeCoverage,
    });
    if (typeCoverage === ICoverageToolType.MANUAL) {
      setIsManual(true);
    } else {
      setIsManual(false);
    }
    if (handleGoNextSlide) {
      setTimeout(() => {
        setEnableNext(true);
      }, 500);
    }
  };

  useEffect(() => {
    if (enableNext && handleGoNextSlide) {
      if (!isDisableReset) setIsAddressConfirmed(true);
      handleGoNextSlide();
    }
  }, [enableNext]);

  const onReset = () => {
    resetCoverageToolData(true);
    setAddress(DEFAULT_ADDRESS);
  };

  return (
    <CoverageToolAuto
      offerId={offerId}
      onSubmit={onSubmit}
      state={DEFAULT_ADDRESS}
      onReset={onReset}
    />
  );
};

export default CoverageToolAutoCard;
