import { Children, useLayoutEffect, useRef } from 'react';
import { CardInfoDevice, ListCardDevice } from '@vfit/shared/components';
import { CustomText } from '@vfit/shared/atoms';
import { API, PAGES } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import {
  CMS_CONFIG,
  ICharacteristicsDevices,
  IGenericErrorCMS,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { IDevicesList } from './devicesList.models';
import { MainContainer, CardInfoContainer } from './devicesList.style';
import { clickDeviceButtonAction, getDeviceCards, updateAllCardsHeight } from './devicesList.utils';
import { useTaggingDeviceList } from './devicesList.tagging';

const DevicesList = ({ topText, listCardTitle, devices }: IDevicesList) => {
  const router = useRouter();
  const deviceListsElements = useRef<HTMLDivElement>(null);

  useTaggingDeviceList(devices);

  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const deviceChar = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_DEVICE_CHARACTERISTICS
  ) as ICharacteristicsDevices;
  const cardDevices = getDeviceCards(devices);
  const haveMarketingLabel = cardDevices?.some((el) => el.device?.devicedetail?.haveMarketingLabel);

  useLayoutEffect(() => {
    if (deviceListsElements?.current) updateAllCardsHeight(deviceListsElements.current);
  }, []);

  // region FILTERS AND COLORS
  // const [filteredCardDevices, setFilteredCardDevices] = useState(cardDevices);

  // const handleResetFilter = () => {
  //   setFilteredCardDevices(cardDevices);
  // };

  // const handleFilter = (filters: {key: string, id: string}[]) => {
  //   let filteredDevices = cardDevices;
  //   filters.forEach((filter) => {
  //     if (filteredDevices.length > 0) {
  //       filteredDevices = filteredDevices.filter((device) => {
  //         if (Object.keys(device.device.devicedetail).includes(filter.key)) {
  //           return Object.values(device.device.devicedetail).includes(filter.id);
  //         }
  //         if (Object.keys(device.device.devicedetail.models[0]).includes(filter.key)) {
  //           device.device.devicedetail.models.forEach((model) => Object.values(model).includes(filter.id));
  //         }

  //         return false;
  //       });
  //     }
  //   })
  //   setFilteredCardDevices(filteredDevices);
  // };

  // const availableColors = (device: IDevice) => {
  //   const isAvailable = device?.devicedetail?.hub?.availableColor?.enabled || false;
  //   const colors = getAllColors(device, deviceChar?.devicecharacteristic);
  //   if (!isAvailable) return null;
  //   return <div />;
  //   return (
  //     <ColorContainer>
  //       {Children.toArray(colors.map((c) => <Color bColor={c.exColor || ''} />))}
  //     </ColorContainer>
  //   );
  // };
  // endregion

  return (
    <MainContainer>
      {topText && <CustomText size={52} text={topText} />}
      <ListCardDevice title={listCardTitle} refContainer={deviceListsElements}>
        {Children.toArray(
          cardDevices?.map((item) => (
            <CardInfoContainer>
              <CardInfoDevice
                {...item?.config}
                enableAutoHeight
                disableMarginTop={!haveMarketingLabel}
                actionClick={(action) =>
                  clickDeviceButtonAction(
                    action,
                    item?.device,
                    deviceChar?.devicecharacteristic,
                    genericError,
                    router
                  )
                }
              />
            </CardInfoContainer>
          ))
        )}
      </ListCardDevice>
    </MainContainer>
  );
};

export default DevicesList;
