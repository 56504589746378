import styled from 'styled-components';
import { breakpoints, fonts } from '@vfit/shared/themes';

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: auto;
  background: rgba(221, 230, 235, 100%);
  padding: 24px;
  gap: 8px;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    padding: 0;
    height: 80px !important;
    gap: 16px;
  }
`;

export const ImageContainer = styled.div`
  width: 48px;
  height: 48px;
`;

export const Text = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;

  @media (min-width: ${breakpoints.desktop}) {
    font-size: 22px;
    line-height: 32px;
  }
`;

export const Link = styled.a`
  color: rgba(0, 0, 0, 100%);
`;

