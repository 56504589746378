import { ISliderCarousel } from '@vfit/shared/models';
import { ICarouselCMS } from '../../cms.models';

export const getDefaultSlider = (widget: ICarouselCMS): ISliderCarousel => ({
  slides:
    widget.elements?.map((s) => ({
      ...s,
      action: {
        ...s.action,
        callMeNow: s.action?.callMeNow || s.action?.url || '',
        // @ts-ignore
        small: s.action?.small ? s.action.small === 'true' : false,
      },
    })) || [],
  topText: widget?.title || '',
});
