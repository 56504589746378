import { useState, useEffect } from 'react';
import {
  CMS_CONFIG,
  errorMock,
  IGenericErrorCMS,
  useCmsConfig,
  useShoppingCartActivationCostMutation,
} from '@vfit/consumer/data-access';
import { ICommonData, IOptionalText } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { API, PAGES, openPopup } from '@vfit/shared/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { useCheckout } from '../../../iBuyFixed.context';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { getCheckoutFWA } from './upfrontSelectionCard.utils';

const UpfrontSelectionCard = ({ handleOnChangeEnableGoNext, handleOnClose }: ICommonData) => {
  const {
    checkoutErrors,
    product,
    upfrontSelection,
    isModalButtonSticky,
    setUpfrontSelection,
    setCurrentStepKey,
  } = useCheckout();
  const [selectedItem, setSelectedItem] = useState<IOptionalText>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { checkoutFWA } = getCheckoutFWA(product);
  const updateShoppingCartAtivationCostMutation = useShoppingCartActivationCostMutation();
  const tagging = getTagging('upfront payment', 'fixed');

  const { trackView } = useTracking({
    event: ['checkout_step8.1'],
    event_label: 'upfront payment choice',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const handleSelect = (item: IOptionalText | undefined) => {
    if (item?.value !== undefined && item?.value !== upfrontSelection?.option) {
      setUpfrontSelection({
        option: item.value,
      });
      updateShoppingCartAtivationCostMutation.mutate(item.value === '1');
    }
  };

  useEffect(() => {
    const goNextButton = document.getElementById('sticky-offer-next');
    const trackGoNext = () => {
      const event_label = selectedItem?.value === '1' ? 'one payment' : 'monthly payment';
      trackView({
        event_name: 'ui_interaction',
        event_label_track: event_label,
        event_category: 'users',
        event_action: 'click',
        tracking_type: 'link',
      });
    };
    goNextButton?.addEventListener('click', trackGoNext);
    return () => {
      goNextButton?.removeEventListener('click', trackGoNext);
    };
  }, [selectedItem]);

  useEffect(() => {
    if (updateShoppingCartAtivationCostMutation.isSuccess && handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(true);
      setIsLoading(false);
    }
    if (updateShoppingCartAtivationCostMutation.isLoading && handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(false);
      setIsLoading(true);
    }
    if (updateShoppingCartAtivationCostMutation.isError) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        handleOnClose,
        {
          ...errorMock('updateShoppingCartPayment', updateShoppingCartAtivationCostMutation?.error),
          isBlocking: true,
        },
        '',
        '',
        handleOnClose,
        genericError?.genericerror?.buttonLabel || '',
        () => openPopup(product?.genericErrorCallMeNow?.url || '', handleOnClose),
        product?.genericErrorCallMeNow?.label,
        isModalButtonSticky
      );
    }
  }, [
    updateShoppingCartAtivationCostMutation.isSuccess,
    updateShoppingCartAtivationCostMutation.isLoading,
    updateShoppingCartAtivationCostMutation.isError,
  ]);

  useEffect(() => {
    setSelectedItem(undefined);
    setUpfrontSelection({
      option: '',
    });
    setCurrentStepKey(ID_FLOWS.INVOICE);
    if (handleOnChangeEnableGoNext) {
      handleOnChangeEnableGoNext(false);
    }
  }, []);

  return (
    <div>
      {checkoutFWA?.items && (
        <CheckboxCards
          items={checkoutFWA?.items}
          title={checkoutFWA?.title}
          description={checkoutFWA?.description}
          setSelectedItem={setSelectedItem}
          selectedItem={selectedItem}
          onSelectItem={handleSelect}
          value={upfrontSelection.option}
          disableItems={isLoading}
        />
      )}
    </div>
  );
};

export default UpfrontSelectionCard;
