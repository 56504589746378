import { ICarouselCMS } from '../../cms.models';
import { IHomeLinksProps } from '../homepage.models';

export const getAnchors = (widget: ICarouselCMS): IHomeLinksProps => ({
  anchors: widget.elements.map((element) => ({
    ...element,
    id: element.id || 0,
    image: element.image || '',
    title: element.title || '',
    text: element.description || '',
    url: element.action?.url || '',
  })),
  text: widget.title || '',
  description: widget?.description || '',
});
