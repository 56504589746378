import { IPageProps, ITaggingCMS } from '@vfit/business-data-access';
import { PageLoadingContextProvider, TrackingPageContextProvider } from '@vfit/business-data-access';
import { Footer, Header } from '@vfit/shared/components';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { withProtected } from './hoc/withProtected';
import { PageLayout } from './layout/pageLayout.style';
import Widgets from '../Widgets/widgets';

const Appointment = ({ page, isApp }: IPageProps) => {
  const { elements, haveHeader, isHeaderSmall, haveFooter } = page?.params || {};
  const { header, tagging } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);

  return (
    // questo div serve per aggiungere padding a fondo pagina quando presente lo sticky
    <div>
      {haveHeader && !isApp && <Header onTrack={tagging} header={header} lite={isHeaderSmall} />}
      <PageLayout className="noPadding">
        <PageLoadingContextProvider>
          <TrackingPageContextProvider
            tagging={elements?.tagging as ITaggingCMS}
            layer="appointmentDataLayer"
          >
            <Widgets aemElements={elements ?? {}} />
          </TrackingPageContextProvider>
        </PageLoadingContextProvider>
      </PageLayout>
      {haveFooter && !isApp && <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />}
    </div>
  );
};

const BlankProtected = withProtected()(Appointment);

export default BlankProtected;
