import { Alert, Button, Input, LoadingSpinner, Svg } from '@vfit/shared/atoms';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { purify } from '@vfit/shared/themes';
import { IEditEmailProps } from './editEmailModal.models';
import { validationSchema } from './editEmailModal.utils';
import {
  ModalTitle,
  ModalDescription,
  ModalBodyContainer,
  ModalTextContainer,
  ButtonDiv,
  ModalWrapper,
  LoadingContainer,
} from './editEmailModal.style';

const EditEmailModal = ({ cmsData, onSubmit, isLoading }: IEditEmailProps) => {
  const {
    register,
    getValues,
    formState: { errors, isValid },
  } = useForm<{ email: string }>({ resolver: yupResolver(validationSchema()), mode: 'onChange' });

  const [ pDescription ] = purify([ cmsData.description ])

  const handleSubmit = () => {
    const input = getValues();
    if (onSubmit) onSubmit(input.email);
  };

  return (
    <ModalWrapper>
      <ModalTextContainer>
        <ModalTitle>{cmsData.title}</ModalTitle>
        <ModalDescription dangerouslySetInnerHTML={{ __html: pDescription || '' }} />
      </ModalTextContainer>
      <ModalBodyContainer className="body">
        <Input
          label={cmsData.inputLabel || ''}
          error={errors?.['email']?.message as string}
          disabled={isLoading}
          {...register('email')}
        />
        <Alert text={cmsData.alertText || ''} type="warning" icon={<Svg color='#fff' width={24} name="warning" />} />
      </ModalBodyContainer>
      <ButtonDiv>
        <Button
          variant="secondary"
          onClick={handleSubmit}
          disabled={isLoading || !isValid}
          className="button-slide-container"
        >
          {cmsData.buttonLabel}
          {isLoading && (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
        </Button>
      </ButtonDiv>
    </ModalWrapper>
  );
};

export default EditEmailModal;

