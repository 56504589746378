import { ButtonSlide, CheckboxList } from '@vfit/shared/atoms';
import { useMemo } from 'react';
import { trackLink } from '@vfit/shared/data-access';
import { IAddressType } from '@vfit/shared/models';
import { checkIsApp } from '@vfit/consumer/data-access';
import { IEditAddressOptions } from './editAddressOptions.models';
import {
  Container,
  Title,
  Subtitle,
  Description,
  CheckboxStyle,
  ButtonStyle,
} from './editAddressOptions.style';

const EditAddressOptions = ({
  options,
  onSaveAddress,
  handlerSelectAddress,
  hiddenCta,
  deliveryCenterData,
  editAddressOptionsConfig,
}: IEditAddressOptions) => {
  const disabledDeliveryCenter = useMemo(() => {
    if (!deliveryCenterData || deliveryCenterData?.length === 0) {
      const index = options.findIndex((item) => item.value === IAddressType.PO);
      if (index !== -1) {
        return {
          index,
          state: true,
        };
      }
      return undefined;
    }
    return undefined;
  }, [deliveryCenterData, options]);

  return (
    <Container>
      <div>
        <Title>{editAddressOptionsConfig?.title}</Title>
        <Subtitle>{editAddressOptionsConfig?.subtitle}</Subtitle>
        <Description>{editAddressOptionsConfig?.description}</Description>
        <CheckboxStyle>
          <CheckboxList
            disabledByIndex={disabledDeliveryCenter}
            onChangeItem={(data) => {
              trackLink(data.title || '');
              handlerSelectAddress(data);
            }}
            items={options}
          />
        </CheckboxStyle>
      </div>
      {!hiddenCta && (
        <ButtonStyle>
          <ButtonSlide
            label={editAddressOptionsConfig?.ctaLable}
            onClick={() => {
              trackLink(editAddressOptionsConfig?.ctaLable || '');
              onSaveAddress();
            }}
            borderColor="#0d0d0d"
            isApp={checkIsApp()}
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            name="action_saveAddress"
          />
        </ButtonStyle>
      )}
    </Container>
  );
};
export default EditAddressOptions;
