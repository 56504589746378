import * as yup from 'yup';

export const portabilityNumberSchema = () =>
  yup.object({
    phoneNumber: yup
      .string()
      .required('Campo obbligatorio')
      .min(1, 'Campo obbligatorio')
      .max(10, 'Inserire un numero valido')
      .matches(/^0[1-9]([0-9]{6,8})/, 'Inserire un numero valido'),
  });
