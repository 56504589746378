import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const Container = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 80px;
  }
`;

