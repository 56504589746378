import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { useMutation, useQueryClient } from 'react-query';
import { IUseVoucherPayload, IUseVoucherResponse } from './voucher.models';

/**
 * Recall service POST /updateProductCharacteristic/voucher
 * Update voucher product characteristic
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
export const voucherService = (
  cartId: string,
  payload: IUseVoucherPayload,
  customOptions?: CustomOptions
): Promise<IUseVoucherResponse> =>
  nextClient.post(
    `${API.ORDER}/${cartId}/${API.UPDATE_PRODUCT_CHARACTERISTIC}/${API.VOUCHER}`,
    payload,
    {
      ...(customOptions?.headers && { headers: customOptions.headers }),
      ...(customOptions?.silentLoginHeaders && {
        silentLoginHeaders: customOptions.silentLoginHeaders,
      }),
    }
  );

/**
 * Custom mutation hook for run updateVoucherService.
 * @returns
 */
export const useVoucher = () => {
  const queryClient = useQueryClient();
  const keyString = 'voucher';
  return useMutation(
    ['doVoucher'],
    (payload: IUseVoucherPayload) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      LoggerInstance.debug('doVoucher before calling service : ', cartId);
      return voucherService(cartId as string, payload);
    },
    {
      onSuccess: (data: IUseVoucherResponse) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
