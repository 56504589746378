import { useEffect, useState } from 'react';
import { useTracking } from '@vfit/shared/data-access';
import { API, PAGES, openPopup } from '@vfit/shared/data-access';
import { EMethodCode, ICommonData, IOptionalText, ISIAFlowType } from '@vfit/shared/models';
import {
  CMS_CONFIG,
  useCmsConfig,
  IPayments,
  productSpecificError,
  SELECTED_PAYMENT,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { CheckboxCards } from '@vfit/consumer/components';
import { WarningInfo } from '@vfit/shared/components';
import { organizeCheckBoxPayment, organizePaymentMethodError } from './recurrentPaymentCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { getTagging, getUserInfo } from '../../checkout.utils';
import { ID_FLOWS } from '../../checkout.constants';

const RecurrentPaymentCard = ({ handleOnChangeEnableGoNext, handleGoPrevSlide }: ICommonData) => {
  const {
    checkoutErrors,
    product,
    isModalButtonSticky,
    authorizeFlow,
    customerFlow,
    setIsLastCard,
    setHideStickyBar,
    setIsDisabledBackButton,
    setAuthorizeFlow,
    setCurrentStepKey,
  } = useCheckout();
  const { authorize } = authorizeFlow;
  const { billingAccount } = customerFlow;
  const { availableNewPayMeans } = billingAccount?.data || {};
  const [selectedItem, setSelectedItem] = useState<IOptionalText>();
  const { buttonPaymentError, urlPaymentErrorCallMeNow } = productSpecificError(product);
  const paymentCMS = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_PAYMENTS) as IPayments;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const cmsWarningPayment = {
    title: paymentCMS?.paymentsmobile?.title || 'Grazie!',
    subtitle: 'Stiamo elaborando il tuo ordine',
    description:
      paymentCMS?.paymentsmobile?.description ||
      "Per completare l'operazione potrebbe volerci qualche istante. Per favore attendi qui, senza lasciare la pagina.",
  };
  const { title, items, description, modal } = organizeCheckBoxPayment(
    paymentCMS,
    availableNewPayMeans?.filter((el) => el !== 'wallet') || []
  );
  const { titlePaymentError, messagePaymentError } = organizePaymentMethodError(paymentCMS);

  const tagging = getTagging('payment method', 'fixed');

  const { trackView } = useTracking({
    event: ['checkout_step9'],
    event_label: 'payment method',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });

  const checkErrors = () => {
    const action = () => {
      if (handleGoPrevSlide) handleGoPrevSlide();
    };
    checkoutErrors?.showAndTrackError?.(
      genericError,
      action,
      authorize?.error || undefined,
      titlePaymentError,
      messagePaymentError,
      action,
      'Chiudi',
      () => openPopup(urlPaymentErrorCallMeNow || '', action),
      buttonPaymentError,
      isModalButtonSticky,
      true
    );
  };

  const handleStartPaymentFlow = (event: Event) => {
    event?.stopPropagation();
    trackView({
      event_name: 'ui_interaction',
      event_label_track: selectedItem?.title || selectedItem?.value || '',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
    let methodCode = selectedItem?.value;
    if (selectedItem?.value === 'paypal') methodCode = EMethodCode?.PAYPAL;
    setAuthorizeFlow({
      ...authorizeFlow,
      selectedMethod: (methodCode || '') as EMethodCode,
      ...(product?.enableRecurringPayment && { siaFlowType: ISIAFlowType.RECURRENT }),
      authorizationOperationMethod: 'capture',
      isStartAuthorizeFlow: true,
    });
  };

  const submitOrder = () => {
    setTimeout(() => {
      const goNextButton = document.getElementById('sticky-offer-next');
      goNextButton?.removeEventListener('click', handleStartPaymentFlow);
      if (goNextButton?.firstChild) {
        (goNextButton?.firstChild as HTMLButtonElement)?.click();
      }
    }, 800);
  };

  const checkLoading = () => {
    setIsDisabledBackButton(authorize?.isLoading);
    setHideStickyBar(authorize?.isLoading);
  };

  const onOpenModal = () => {
    trackView({
      event_name: 'ui_interaction',
      event_label_track: modal?.titleModal || '',
      event_category: 'users',
      event_action: 'click',
      tracking_type: 'link',
    });
  };

  useEffect(() => {
    if (selectedItem) {
      if (authorize?.isError) {
        checkErrors();
      }
      checkLoading();
      if (authorize?.isSuccess) {
        submitOrder();
      }
    }
  }, [authorize]);

  useEffect(() => {
    authorizeFlow?.resetReserveAuthorization?.();
    const goNextButton = document.getElementById('sticky-offer-next');
    handleOnChangeEnableGoNext?.(!!selectedItem?.value);
    if (!selectedItem?.value) {
      localStorage.removeItem(SELECTED_PAYMENT);
    } else {
      localStorage.setItem(SELECTED_PAYMENT, selectedItem?.value);
    }
    goNextButton?.addEventListener('click', handleStartPaymentFlow);
    return () => {
      goNextButton?.removeEventListener('click', handleStartPaymentFlow);
    };
  }, [selectedItem]);

  useEffect(() => {
    setIsLastCard(true);
    setCurrentStepKey(ID_FLOWS.RECURRENT_PAYMENT_CARD);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  if (authorizeFlow?.authorize?.isLoading) {
    return <WarningInfo {...cmsWarningPayment} />;
  }

  return (
    <CheckboxCards
      items={items}
      title={title}
      description={description}
      bottomModal={modal}
      onOpeneModal={onOpenModal}
      setSelectedItem={setSelectedItem}
      selectedItem={selectedItem}
    />
  );
};

export default RecurrentPaymentCard;
