import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';

export const StoreListContainer = styled.div`
  height: 100%;
  margin: 12px 44px 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 21.5px 40px 34px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 29.5px 109px 64px;
  }
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin-bottom: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const SubTitle = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(20, 30)}
    margin-bottom: 0;
  }
`;

export const SelectedAddress = styled(Paragraph)`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  color: ${colors.$e60000};
  margin-bottom: 8px;
  text-align: center;
  text-transform: capitalize;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    text-align: left;
    margin-bottom: 16px;
  }
`;

export const CtaBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;

  @media (min-width: ${breakpoints.tablet}) {
    gap: 32px;
  }

  .linkWithIcon {
    ${pxToCssFontSize(16)}
  }
`;

export const CtaAddress = styled.button`
  display: inline-flex;
  font-family: ${fonts.regular};
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid ${colors.$262626};
  color: ${colors.$262626};
  font-weight: 700;
  ${pxToCssFont(16, 22)}

  &:hover {
    cursor: pointer;
  }
`;

export const SwitchToggleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 26px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 36px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 42px;
  }
`;

export const LabelSwitch = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(12, 16)}
  color: #000;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(14, 18)}
  }
`;

export const AddressListContainer = styled.div`
  overflow-y: scroll;
  height: 30vh;
  margin-bottom: 34.5px;
  padding-right: 10px;
  width: calc(100% + 10px);
  text-transform: capitalize;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 46px;
  }
`;

export const MapContainer = styled.div`
  // height: 100%;
  margin: 0 -44px 32px;

  @media (min-width: ${breakpoints.tablet}) {
    height: auto;
    margin: 0 -40px 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 -109px 40px;
  }
`;

export const WrapButton = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
`;

export const ButtonContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    position: relative;
    width: 350px;
  }
`;

