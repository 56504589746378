import { ICampaignResponse, IProduct } from '@vfit/consumer/data-access';

const checkCampaignEligibility = (
  product: IProduct,
  campaignsData: ICampaignResponse[]
): ICampaignResponse | undefined => {
  // FixedLine type filter campaignsOffer
  const fixedCampaignsList =
    campaignsData?.filter((item: ICampaignResponse) => item?.type?.toLowerCase() === 'fixed') || [];
  if (fixedCampaignsList?.length === 0) {
    return undefined;
  }
  const lockInOffersFound: ICampaignResponse | undefined = fixedCampaignsList?.find(
    (item: ICampaignResponse) =>
      item?.fixedBundle?.productOfferId?.toString() === product?.offerId?.toString()
  );
  if (!lockInOffersFound) {
    return undefined;
  }
  return lockInOffersFound;
};

const getEligibilityCampaign = (
  product: IProduct,
  campaignsData: ICampaignResponse[]
): ICampaignResponse | undefined => checkCampaignEligibility(product, campaignsData);

export { getEligibilityCampaign };
