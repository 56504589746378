import { CoverageToolManual } from '@vfit/consumer/components';
import { ICommonData } from '../../../StepperStateCards/stepperStateCards.models';
import { ICoverageToolType, IInputs } from '../../../CoverageTool/coverageTool.models';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';

const CoverageToolManualCard = ({ handleGoNextSlide, handleGoPrevSlide }: ICommonData) => {
  const { address, setAddress, offerId } = useCoverageToolModalProvider();

  const goNextSlide = (data: IInputs, typeCoverage: ICoverageToolType) => {
    setAddress({
      ...data,
      typeCoverage,
    });
    if (handleGoNextSlide) {
      handleGoNextSlide();
    }
  };

  const goPrevSlide = () => {
    if (handleGoPrevSlide) {
      handleGoPrevSlide();
    }
  };

  return (
    <CoverageToolManual
      offerId={offerId}
      onSubmit={goNextSlide}
      onBack={goPrevSlide}
      state={address}
    />
  );
};

export default CoverageToolManualCard;
