import {
  IProduct,
  IWinBackCampaignConfig,
  IWinBackCampaignConfigDetail,
  IWinBackCMS,
} from '@vfit/consumer/data-access';
import { errorManager, ErrorService } from '@vfit/shared/data-access';

const checkProductOfferFlow = (
  product: IProduct,
  winbackCampaignsList: IWinBackCampaignConfig,
  cmsWinbackData: IWinBackCMS
): IProduct => {
  const urlParams = new URLSearchParams(window.location.search);
  const clientCode = urlParams?.get('cC') || urlParams?.get('cc') || '';
  const targetCampaignId = urlParams?.get('tC') || urlParams?.get('tc') || '';
  const { campaigndetails: campaignDetails }: IWinBackCampaignConfigDetail =
    winbackCampaignsList?.[clientCode] || {};
  const { campaignId } = product;

  const redirectHomePage = () => {
    window.location.href = '/';
  };

  const manageErrorWinback = () => {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: cmsWinbackData?.winbacknumber?.errorPopup?.title || 'Ops',
      message:
        cmsWinbackData?.winbacknumber?.errorPopup?.message ||
        'Si è verificato un problema. Riprovare in seguito',
      actionText: 'Chiudi',
      fullscreen: true,
      disableCloseModalOnClickAction: true,
      onClose: redirectHomePage,
      actionEvent: () => {
        redirectHomePage();
      },
    });
  };

  const showExpiredCampaign = () => {
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
      title: cmsWinbackData?.winbacknumber?.expiredPopup?.title || '',
      message: cmsWinbackData?.winbacknumber?.expiredPopup?.message || '',
      actionText: cmsWinbackData?.winbacknumber?.expiredPopup?.ctaText || '',
      actionEvent: () => {
        redirectHomePage();
      },
      secondButtonText: cmsWinbackData?.winbacknumber?.expiredPopup?.buttonText || '',
      secondActionEvent: () => {
        window.location.href = cmsWinbackData?.winbacknumber?.expiredPopup?.buttonRedirect || '';
      },
      onClose: () => {
        window.location.href = cmsWinbackData?.winbacknumber?.expiredPopup?.buttonRedirect || '';
      },
      disableTrack: true,
    });
  };

  if (campaignId && (!clientCode || !targetCampaignId)) {
    manageErrorWinback();
    return product;
  }

  if (clientCode && campaignId === clientCode) {
    const { expirationDate } = campaignDetails || {};
    const isExpired = !expirationDate ? true : new Date() > new Date(expirationDate as string);
    if (isExpired) {
      showExpiredCampaign();
      return product;
    }
    return {
      ...product,
      isWinback: true,
      cc: clientCode,
      tc: targetCampaignId,
    };
  }

  return product;
};

export { checkProductOfferFlow };
