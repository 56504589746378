import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { IVerifyOtpPayload, IVerifyOtpResponse } from '@vfit/shared/models';

/**
 * Methods to invoke the POST /verify service
 * @param payload
 * @param customOptions
 * @returns
 */
const verifyOTPService = (
  payload: IVerifyOtpPayload,
  customOptions?: CustomOptions
): Promise<IVerifyOtpResponse> =>
  nextClient.post(API.VERIFY, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Mutation to verify if the OTP code is valid
 * @returns
 */
export const useVerifyOTP = (customOptions?: CustomOptions) => {
  const queryClient = useQueryClient();

  LoggerInstance.debug('useVerifyOTP');

  return useMutation(
    'verifyOTP',
    (otp: string) => {
      LoggerInstance.debug('useVerifyOTP otp : ', otp);
      const generateOTPSaved =
        localStorage.getItem('generateOTP') &&
        JSON.parse(localStorage.getItem('generateOTP') as string);

      const payload: IVerifyOtpPayload = {
        id: generateOTPSaved.id,
        otp,
        pkatId: generateOTPSaved.pkatId,
      };
      LoggerInstance.debug('useVerifyOTP payload : ', payload);

      return verifyOTPService(payload, customOptions);
    },
    {
      onSuccess: (data: IVerifyOtpResponse) => {
        localStorage.setItem('verifyOTP', JSON.stringify(data));
        queryClient.setQueryData('verifyOTP', data);
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - verifyOTP: `, error);
        queryClient.setQueryData('verifyOTP', errorMock(`verifyOTP`, error));
        localStorage.setItem(`verifyOTP`, JSON.stringify(errorMock(`verifyOTP`, error)));
        customOptions?.onError?.(error);
      },
    }
  );
};

