import { Children } from 'react';
import CheckboxItem from '../CheckboxItem/checkboxItem';
import { ICheckboxMultiline } from './checkboxMultiline.models';
import { Parent, CheckboxDiv } from './checkboxMultiline.style';

const CheckboxMultiline = ({ items, selIndex, onSelectItem }: ICheckboxMultiline) => (
  <Parent>
    {Children.toArray(
      items.map((item, index) => (
        <CheckboxDiv lastLine={index <= items.length - 2}>
          <CheckboxItem item={item} index={index} selIndex={selIndex} onSelectItem={onSelectItem} />
        </CheckboxDiv>
      ))
    )}
  </Parent>
);

export default CheckboxMultiline;
