import { IAddressDataObject, IAddressType } from '@vfit/shared/models';
import { IOwningIndividual } from '@vfit/consumer/data-access';
import { IOtherInformation } from './yourAddress.model';

const getShippingAddressDescription = (
  shippingAddress: IAddressDataObject,
  shippingData?: IOtherInformation,
  owningIndividual?: IOwningIndividual
) => {
  const { typePickupPoint } = shippingAddress || {};
  const officialOfficePlaceholder = 'Filiale';
  if (!typePickupPoint || !shippingData?.addressData?.postalOfficeDescription)
    return `${owningIndividual?.firstName || ''} ${owningIndividual?.lastName || ''}`;
  if (typePickupPoint === IAddressType.PO && shippingData?.addressData?.postalOfficeDescription)
    return `${officialOfficePlaceholder} ${shippingData?.addressData?.postalOfficeDescription.toLowerCase()}`;
  if (shippingData?.addressData?.postalOfficeDescription)
    return `${shippingData?.addressData?.postalOfficeDescription.toLowerCase()}`;
  return `${owningIndividual?.firstName || ''} ${owningIndividual?.lastName || ''}`;
};

export { getShippingAddressDescription };
