import {ICountry} from "@vfit/shared/data-access";

const getMunicipalityByString = (
  allMunicipalities: ICountry[],
  municipality: string
): ICountry | undefined => {
  const lastIndexProvince = municipality.lastIndexOf("(");
  let countryString = municipality;
  if(lastIndexProvince > -1)
    countryString = municipality.substr(0 , municipality.lastIndexOf("(")).trim();
  return allMunicipalities.find((m) => m.municipality.toLowerCase() === countryString.toLowerCase());
}

export {
  getMunicipalityByString
}
