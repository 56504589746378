import { useEffect, useState } from 'react';
import { getItemCookie, setItemCookie } from '@vfit/shared/data-access';

// @ts-ignore
const withProtected = () => (Component) => (props) => {
  const { page } = props || {};
  const { isProtected, protectedPass } = page?.params || {};
  const [isShowPage, setIsShowPage] = useState(true);

  const checkPass = () => {
    if (isProtected && page?.params?.key) {
      const pageCookie = getItemCookie(page.params.key);
      if (!pageCookie) {
        const pwd = prompt('Please enter password');
        if (pwd === protectedPass) {
          setItemCookie(page.params.key, '1', 2);
        } else {
          setIsShowPage(false);
        }
      }
    }
  };

  useEffect(() => {
    checkPass();
  }, []);

  if (!isShowPage) return null;

  return <Component {...props} />;
};

export { withProtected };
