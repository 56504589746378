import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDeviceType, useIntersectionObserver } from '@vfit/shared/hooks';
import { volaModalManager } from '@vfit/shared/data-access';
import { CoverageToolModal, SlideProduct } from '@vfit/consumer/components';
import {
  ButtonSlide,
  ButtonSlideCTA,
  ContainerTopProduct,
  ImageDivider,
  CustomText,
} from '@vfit/shared/atoms';
import {
  ICmsConfigAutoDesc,
  useAutoDesc,
  removeCurrency,
  usePartnerOfferFlow,
  useRedirectCoverageToolFlow,
} from '@vfit/consumer/hooks';
import { Fade } from 'react-awesome-reveal';
import {
  checkIsApp,
  checkPanicMode,
  CMS_CONFIG,
  getFormattedPriceString,
  getProductCategory,
  getRedirectCoverage,
  IGlobalConfigCMS,
  IGlobalPromoCMS,
  IN_ACTIVATION,
  IOfferDetailProduct,
  IProduct,
  IWinBackCampaignConfig,
  IWinBackCMS,
  useCmsConfig,
  usePage,
} from '@vfit/consumer/data-access';
import { ISupportModule, ProductDetail, StickyOffer, VfModal } from '@vfit/shared/components';
import { fonts } from '@vfit/shared/themes';
import {
  ActionTextType,
  IActionSlide,
  IActionType,
  IClickToCallMeData,
  IErrorFormApi,
  ISubmitOutput,
  IType,
} from '@vfit/shared/models';
import {
  API,
  getButtonActionByActionType,
  IGetAllCmsForm,
  manageErrorLeadPlatform,
  manageSuccessLeadPlatform,
  openLeadModal,
  openPopup,
  PAGES,
  trackLink,
} from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useRouter } from 'next/router';
import {
  ButtonDesc,
  DisclaimerContainer,
  ModalButtonContainer,
  ModalTitle,
  ModalContent,
  StickyOfferContainerTopProduct,
  DescriptionContainer,
} from './topProduct.style';
import { IProductModal, ITopProductProps } from './topProduct.models';
import { checkProductOfferFlow } from './topProduct.utils';
import { ProductCharacteristics } from './components/ProductCharacteristics/productCharacteristics';

/**
 * TODO: Refactor top product for lockin/isLandingClub
 * @param topProduct
 * @param disableNavigation
 * @param disclaimer
 * @param divider
 * @param isR02
 * @param onOverrideCoverageToolCta
 * @constructor
 */
const TopProduct = ({
  product: topProduct,
  disableNavigation,
  disclaimer,
  divider,
  isR02,
  onOverrideCoverageToolCta,
}: ITopProductProps) => {
  const { push } = useRouter();
  const { cmsId } = topProduct;
  const queryClient = useQueryClient();
  const { page } = usePage();
  const winbackCampaignsList = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_WINBACK_CONFIG
  ) as IWinBackCampaignConfig;
  const cmsWinbackData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_WINBACK_DATA
  ) as IWinBackCMS;
  const { globalconfig } =
    (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_CONFIG) as IGlobalConfigCMS) || {};
  const { globalpromo } = checkIsApp()
    ? (useCmsConfig(
      CMS_CONFIG[PAGES.CONSUMER],
      API.CMS_GET_GLOBAL_PROMO_IADD
    ) as IGlobalPromoCMS) || {}
    : (useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_GLOBAL_PROMO) as IGlobalPromoCMS) || {};
  const { panicMode } = globalconfig || {};
  const volaClickToCallData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_CLICK_TO_CALL_CONF
  ) as IClickToCallMeData;
  const ctcAutoDesc = useCmsConfig(CMS_CONFIG[PAGES.CONSUMER], API.CMS_GET_CTC_AUTO_DESC);
  const { enableAutoDesc, customString } =
    (ctcAutoDesc?.['ctcautodesc'] as ICmsConfigAutoDesc) || {};

  usePartnerOfferFlow({ product: topProduct });
  const product: IProduct = checkProductOfferFlow(topProduct, winbackCampaignsList, cmsWinbackData);
  const baseCtcUrl =
    product?.ctcPdpUrl || product?.pdpSecondAction?.callMeNow || product?.pdpSecondAction?.url;
  const iadd_auto_ctc = useAutoDesc(product as IProduct, {
    enableAutoDesc,
    url: baseCtcUrl,
    customString,
  });

  const [isShowCoverageTool, setIsShowCoverageTool] = useState(false);
  const [directionSlideStickyOffer, setDirectionSlideStickyOffer] = useState<
    'upward' | 'downward' | undefined
  >();
  const { isMobile } = useDeviceType();
  const [isInActivation] = useRedirectCoverageToolFlow(cmsId || '');
  const productContentRef = useRef<HTMLDivElement | null>(null);
  const [modal, setModal] = useState<IProductModal>({
    show: false,
    type: IType.TEXT,
  });
  const supportModulesCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_SUPPORT_MODULE
  ) as ISupportModule;
  const slideProductMainBtn = useRef<HTMLDivElement>(null);
  const { isIntersecting: slideProductMainBtnIsIntersected } =
    useIntersectionObserver(slideProductMainBtn, { rootMargin: '-10%' }) || {};
  const { isDesktop } = useDeviceType();

  const productOfferTitle = getFormattedPriceString(product.price, product.recurrence);
  const detailLabel = product?.buttons?.[0]?.label || '';

  useEffect(() => {
    if (!isShowCoverageTool && isInActivation) {
      setIsShowCoverageTool(true);
    }
  }, [isInActivation]);

  const resetModal = () => {
    setModal({
      show: false,
      type: IType.TEXT,
      text: '',
      title: '',
      disableOrganizeText: false,
    });
  };

  const productDetailButton = (): JSX.Element | null => {
    if (!product.offerDetailLabel || (isMobile && !checkIsApp())) return null;
    const showDetail = () => {
      trackLink('product details', 'users');
      setModal({
        show: true,
        disableOrganizeText: false,
        type: IType.PRODUCT,
      });
    };
    return (
      <ButtonDesc>
        <ButtonSlideCTA isApp={checkIsApp()}>
          <ButtonSlide
            onClick={showDetail}
            isApp={checkIsApp()}
            borderColor="#0d0d0d"
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            label={product.offerDetailLabel}
            name="action_showDetail"
          />
        </ButtonSlideCTA>
      </ButtonDesc>
    );
  };

  const productLandingClubButton = (): JSX.Element | null => {
    if (!product.isLandingClub || (isMobile && !checkIsApp())) return null;
    if (product?.hubSecondAction?.title)
      return (
        <ButtonDesc>
          <ButtonSlideCTA isApp={checkIsApp()}>
            <ButtonSlide
              onClick={() => getButtonActionByActionType(product?.hubSecondAction, push, queryClient)}
              isApp={checkIsApp()}
              borderColor="#0d0d0d"
              hoverColor="#262626"
              hoverTextColor="#fff"
              clickColor="#7e7e7e"
              clickTextColor="#fff"
              label={product.hubSecondAction?.title}
              name="action_showDetail"
            />
          </ButtonSlideCTA>
        </ButtonDesc>
      );
    else return null
  };

  const onClickTypeInText = (
    type: ActionTextType,
    description: string | undefined,
    title?: string
  ) => {
    if (description) {
      setModal({
        show: true,
        type: IType.TEXT,
        text: description || '',
        title: title || '',
        disableOrganizeText: true,
      });
    }
  };

  const scrollToContent = () => {
    if (productContentRef?.current) {
      productContentRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const onActionProduct = (action?: IActionSlide) => {
    let allLeads;
    let refLead;
    let lead;
    let autoActionUrlOverride;
    let finalAction;
    switch (action?.type) {
      case IActionType.CUSTOM_URL:
        if (isR02?.() && product?.redirectR02) {
          window.open(product.redirectR02 || '', action?.isBlank === 'true' ? '_blank' : '_self');
        } else if (action?.url) {
          window.open(action.url, action?.isBlank === 'true' ? '_blank' : '_self');
        }
        break;
      case IActionType.CALL_ME_NOW:
        autoActionUrlOverride = (iadd_auto_ctc?.ctcAutoUrl ||
          product?.ctcPdpUrl ||
          action?.callMeNow) as string;
        finalAction = checkIsApp()
          ? autoActionUrlOverride
          : product?.ctcPdpUrl || action?.callMeNow;
        if (finalAction) openPopup(finalAction);
        break;
      case IActionType.CALL_ME_NOW_SUNRISE:
        if (action?.title && action?.url) {
          volaModalManager.handleVolaModal({
            url: action?.url || '',
            cmsClickToCallData: { ...volaClickToCallData },
            taggingOption: page?.params?.elements?.taggingOptions,
            pageProduct: SoftManagerService(queryClient).getPageProduct(product),
          });
        }
        break;
      case IActionType.COVERAGE_TOOL:
        if (onOverrideCoverageToolCta) onOverrideCoverageToolCta();
        else setIsShowCoverageTool(true);
        break;
      case IActionType.CHECKOUT_MODAL_MOBILE:
        localStorage.setItem('tProd', JSON.stringify(product));
        window.location.href = getRedirectCoverage();
        break;
      case IActionType.COVERAGE_TOOL_WITH_REDIRECT:
        if (action?.url && cmsId) {
          const alreadyHaveParams = action.url.includes('?');
          const redirectUrl = alreadyHaveParams
            ? `${action.url}&${IN_ACTIVATION}=${cmsId}`
            : `${action.url}?${IN_ACTIVATION}=${cmsId}`;
          window.location.href = redirectUrl;
        }
        break;
      case IActionType.LEAD_MODAL:
        allLeads = queryClient?.getQueryData('getAllLeads') as IGetAllCmsForm;
        // form name
        refLead = action?.localUrl || '';
        lead = allLeads[refLead];
        if (lead?.forms) {
          openLeadModal({
            form: lead.forms,
            onSuccess: (submitOutput: ISubmitOutput) =>
              manageSuccessLeadPlatform(submitOutput, push, queryClient),
            onError: (submitOutput: ISubmitOutput[], errorCmsApi?: IErrorFormApi) =>
              manageErrorLeadPlatform(submitOutput, errorCmsApi, push, queryClient),
          });
        }
        break;
      default:
        break;
    }
  };

  const hideCoverageTool = () => {
    setIsShowCoverageTool(false);
  };

  const modalCustomFooter = () => (
    <ModalButtonContainer>
      <ButtonSlide
        label={
          (checkPanicMode(panicMode, product)
            ? product.panicModeAction?.title
            : product.action?.title) || ''
        }
        onClick={() => {
          resetModal();
          onActionProduct(
            checkPanicMode(panicMode, product) ? product.panicModeAction : product.action
          );
        }}
        isApp={checkIsApp()}
        buttonColor="#e60000"
        labelColor="#fff"
        borderColor="#e60000"
        hoverColor="#bd0000"
        clickColor="#a10000"
        name={`action_top_product_${product?.offerId?.toString() || ''}`}
      />
    </ModalButtonContainer>
  );

  const modalSimpleText = () => (
    <>
      {modal.title && (
        <ModalTitle>
          <CustomText
            fontFamily={fonts.exbold}
            size={36}
            lineHeight={45}
            textAlign="left"
            text={modal.title}
          />
        </ModalTitle>
      )}
      {modal.text && (
        <ModalContent>
          <CustomText
            size={18}
            lineHeight={24}
            textAlign="left"
            text={modal.text}
            disableOrganizeText={modal.disableOrganizeText}
          />
        </ModalContent>
      )}
    </>
  );

  useLayoutEffect(() => {
    if (isDesktop) {
      setDirectionSlideStickyOffer(slideProductMainBtnIsIntersected ? 'downward' : 'upward');
    }
  }, [isDesktop, slideProductMainBtnIsIntersected]);

  const getProductElements = () => {
    const productCharacteristics = product?.offerLockInApp?.shortCharacteristicsLockIn?.items || [];
    const elements = productCharacteristics.map((p) => ({
      text: p?.title || '',
      subtext: p?.description || '',
      icon: p?.icon || '',
    }));
    if (product?.isGlobalPromoActive) {
      if (globalpromo?.label) {
        elements.unshift({
          text: '',
          subtext: globalpromo.label || '',
          icon: 'promo.png',
        });
      }
    }
    return elements;
  };

  const getOfferDetails = () => {
    const offerDetails: IOfferDetailProduct[] = [...(product.offerDetails || [])];
    const promoDetails = globalpromo?.characteristcs;
    if (product.isGlobalPromoActive) {
      promoDetails?.forEach((promoDetail) => {
        offerDetails.push({
          title: promoDetail.title,
          description: promoDetail.description,
        });
      });
    }
    return offerDetails;
  };

  return (
    <>
      <SlideProduct
        ref={slideProductMainBtn}
        disableNavigation={disableNavigation}
        onActiveProduct={onActionProduct}
        product={product}
        globalPromo={globalpromo}
        onButtonScroll={scrollToContent}
        handleClickInflation={(inflationHtml) => {
          if (inflationHtml) onClickTypeInText(ActionTextType.club, inflationHtml);
        }}
        isPanicMode={checkPanicMode(panicMode, product)}
      />
      {product.productDescription && (
        <>
          <ContainerTopProduct ref={productContentRef}>
            <Fade direction="up" triggerOnce>
              <DescriptionContainer>
                {!checkIsApp() &&
                  product.isGlobalPromoActive &&
                  globalpromo?.detailPageDescription && (
                    <CustomText
                      text={globalpromo.detailPageDescription}
                      textAlign="left"
                      onClickType={onClickTypeInText}
                      size={52}
                      lineHeight={70}
                      sizeMobile={35}
                      lineHeightMobile={45}
                      margin={[0]}
                    />
                  )}
                {checkIsApp() && (
                  <CustomText
                    text={product.productDescription}
                    textAlign="left"
                    onClickType={onClickTypeInText}
                    sizeMobile={24}
                    lineHeightMobile={32}
                    margin={[0]}
                  />
                )}
                {!checkIsApp() && (
                  <CustomText
                    text={product.productDescription}
                    textAlign="left"
                    onClickType={onClickTypeInText}
                    size={52}
                    lineHeight={70}
                    sizeMobile={35}
                    lineHeightMobile={45}
                    margin={[0]}
                  />
                )}
              </DescriptionContainer>
              {!product?.isLandingClub && productDetailButton()}
              {product?.isLandingClub && productLandingClubButton()}
              {checkIsApp() &&
                !product?.isLandingClub &&
                getProductElements() &&
                getProductElements()?.length > 0 && (
                  <ProductCharacteristics
                    elements={getProductElements()}
                    title={product?.offerLockInApp?.shortCharacteristicsLockIn?.title || ''}
                    promoDisclaimer={
                      product.isGlobalPromoActive ? globalpromo?.detailPageDisclaimer : undefined
                    }
                  />
                )}
              {disclaimer && (
                <DisclaimerContainer>
                  <CustomText
                    textAlign="left"
                    text={disclaimer}
                    lineHeight={22}
                    size={16}
                    fontFamily={fonts.regular}
                  />
                </DisclaimerContainer>
              )}
            </Fade>
          </ContainerTopProduct>
          {divider?.image && (
            <Fade direction="up" triggerOnce>
              <ImageDivider image={divider?.image} alt={divider.text || ''} />
            </Fade>
          )}
        </>
      )}
      <VfModal
        height={563}
        isOpen={modal.show}
        handleClose={resetModal}
        customFooter={modal.type === IType.PRODUCT ? modalCustomFooter : undefined}
      >
        {modal.text ? (
          modalSimpleText()
        ) : (
          <ProductDetail
            trackingOpt={{
              event: ['ui_interaction'],
              event_label: 'offer details',
              opts: { event_category: 'users', event_action: 'click' },
              visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
              disableInitialTrack: false,
            }}
            details={{
              title: product.title,
              conditions: product.conditions,
              callMeNowHelp: product.callMeNowHelp,
              description: product.description,
              offerDetails: getOfferDetails(),
              customDescriptionCVM: product.customDescriptionCVM,
              price: product.price,
              recurrence: product.recurrence,
            }}
            modalInfo={{
              title: supportModulesCms?.supportmodules?.needHelp?.title || '',
              ctaName: supportModulesCms?.supportmodules?.needHelp?.ctaSupportCall || '',
            }}
            showHelps={false}
          />
        )}
      </VfModal>
      {product?.price && product?.action?.title && (
        <StickyOfferContainerTopProduct>
          <StickyOffer
            slideBanner={directionSlideStickyOffer}
            id="sticky-offer-product"
            title={productOfferTitle}
            description={detailLabel || ''}
            topLabel={product?.topLabelStickyPdp || ''}
            ctaActivation={{
              action: () =>
                onActionProduct(
                  checkPanicMode(panicMode, product) ? product.panicModeAction : product.action
                ),
              label:
                (checkPanicMode(panicMode, product)
                  ? product.panicModeAction?.title
                  : product.action?.title) || '',
            }}
            content={
              <ProductDetail
                trackingOpt={{
                  event: ['view'],
                  event_label: 'offer details',
                  opts: { product_target_segment: 'consumer' },
                  pageProduct: {
                    product_id: `${product?.offerId}`,
                    product_name: product?.slug,
                    product_category: getProductCategory(product?.offerType || ''),
                    product_price: product?.price && removeCurrency(product.price),
                    product_quantity: '1',
                  },
                }}
                details={{
                  title: product.title,
                  conditions: product.conditions,
                  callMeNowHelp: product.callMeNowHelp,
                  description: product.description,
                  offerDetails: getOfferDetails(),
                  originalPrice: product.originalPrice,
                  price: product.price,
                  customDescriptionCVM: product.customDescriptionCVM,
                  recurrence: product.recurrence,
                }}
                modalInfo={{
                  title: supportModulesCms?.supportmodules?.needHelp?.title || '',
                  ctaName: supportModulesCms?.supportmodules?.needHelp?.ctaSupportCall || '',
                }}
              />
            }
            hideDetailLabel={product?.buttonHide}
            showDetailLabel={product?.buttonShow}
          />
        </StickyOfferContainerTopProduct>
      )}
      {product && (
        <CoverageToolModal
          backgroundImage={product.image}
          isOpen={isShowCoverageTool}
          product={product}
          handleClose={hideCoverageTool}
        />
      )}
    </>
  );
};
export default TopProduct;
