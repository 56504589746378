import { useQueryClient, UseQueryResult } from 'react-query';
import { errorMock, IShoppingCartResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  LoggerInstance,
  nextClient,
  INextError,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { IPaymentServiceResponse } from '@vfit/shared/models';

/**
 * This method calls the paymentService looking for a specific cartId
 * Method: GET
 * searchParams: {
 * salesChannel: 'selfService',
 * levelOfData: 'amount,paymentMethod,paymentDistribution'}
 * @param cartId
 * @param customOptions
 * @returns
 */
const paymentService = (cartId: string, customOptions?: CustomOptions) =>
  nextClient.get(`${API.ORDER}/${cartId}/${API.PAYMENT}`, {
    searchParams: {
      salesChannel: 'selfService',
      levelOfData: 'amount,paymentMethod,paymentDistribution',
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IPaymentServiceResponse>;

/**
 * This method calls the paymentService
 * @param customOptions
 * @returns
 */
export const usePayment = (
  customOptions?: CustomOptions
): UseQueryResult<IPaymentServiceResponse, INextError> => {
  const queryClient = useQueryClient();
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  const cartId = shoppingCart?.id;
  const queryEnabler = customOptions ? customOptions.enabled && !!cartId : !!cartId;
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'paymentData';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => paymentService(cartId as string, customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: queryEnabler,
      onSuccess: (data: IPaymentServiceResponse) => {
        queryClient.setQueryData('paymentData', data);
        localStorage.setItem('paymentData', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - paymentData: `, error);
        queryClient.setQueryData('paymentData', errorMock('paymentData', error));
        localStorage.setItem(`paymentData`, JSON.stringify(errorMock(`paymentData`, error)));
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IPaymentServiceResponse, INextError>;
};
