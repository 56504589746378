import React, { useState, useEffect } from 'react';
import { ButtonSlide } from '@vfit/shared/atoms';
import { Form } from '@vfit/shared/components';
import { trackLink } from '@vfit/shared/data-access';
import { checkIsApp } from '@vfit/consumer/data-access';
import {
  Container,
  CtaContainer,
  Description,
  Title,
  InputContainer,
} from './noteModalDelivery.style';
import { INoteModalDelivery } from './noteModalDelivery.model';

const NoteModalDelivery: React.FC<INoteModalDelivery> = ({
  handleClose,
  shippingAddress,
  setShippingAddress,
  noteModalConfig,
}) => {
  const [text, setText] = useState<string>(shippingAddress.note || '');
  const [mod, setMod] = useState<boolean>(false);

  const onChangeText = () => {
    setShippingAddress({
      ...shippingAddress,
      note: text,
    });
    handleClose();
  };

  useEffect(() => {
    if (shippingAddress.note === undefined || shippingAddress.note === '') setMod(true);
  }, [shippingAddress.note]);

  return (
    <Container>
      <InputContainer>
        <Title>{!mod ? noteModalConfig?.titleMod : noteModalConfig?.title}</Title>
        <Description>
          {!mod ? noteModalConfig?.descriptionMod : noteModalConfig?.description}
        </Description>
        <Form.TextAreaInput
          id="noteValue"
          maxLength={255}
          placeholder=" "
          label={noteModalConfig?.textLabel}
          style={{ height: 'auto', zIndex: 1 }}
          value={text}
          onChange={(v) => setText(v.target.value)}
        />
      </InputContainer>
      <CtaContainer>
        <ButtonSlide
          disabled={text.trim().length === 0 && mod}
          borderColor="#0d0d0d"
          isApp={checkIsApp()}
          hoverColor="#262626"
          hoverTextColor="#fff"
          clickColor="#7e7e7e"
          clickTextColor="#fff"
          label={noteModalConfig?.ctaLabel}
          onClick={() => {
            onChangeText();
            trackLink(noteModalConfig?.ctaLabel);
          }}
          name="action_note"
        />
      </CtaContainer>
    </Container>
  );
};
export default NoteModalDelivery;
