import { useEffect } from 'react';
import {
  ErrorCodes,
  IAPIError,
  ICheckPortInFeasibilityIIResponse,
  IPortInDetailsMobile,
  useCheckPortInFeasibilityIIMobile,
  useModifyPhoneNumberMobileProduct,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { alreadyDonePortability } from './usePortabilityFlow.utils';

export const usePortabilityFlow = () => {
  const { portability, portabilityStatus, setPortabilityStatus, ICCID } = useCheckout();

  const portabilityPayload: IPortInDetailsMobile = {
    currentPlanType: portability?.portabilityMigration?.currentPlan,
    currentServiceProviderId: portability?.portabilityMigration?.currentProvider,
    currentServiceProviderIdName: portability?.portabilityMigration?.currentProvider,
    msisdn: portability.portabilityNumber,
    isSimAvailable: ICCID && ICCID?.trim() !== '' ? 'true' : 'false',
    transferRemainingBalance: String(portability?.portabilityMigration?.transferBalance),
    simCardNumber: ICCID?.trim() || '',
  };

  const flowEnabler =
    (portability.portabilityWant &&
      !alreadyDonePortability() &&
      portability?.portabilityOtp?.isVerified &&
      portabilityStatus?.isStartPortIn) ||
    false;
  const isStartFinalPortIn = portabilityStatus?.isStartFinalPortIn;

  const {
    mutate: mutatePortIn,
    data: checkPortInData,
    isError: isErrorPortIn,
    isLoading: isLoadingPortIn,
    isSuccess: isSuccessPortIn,
    reset: resetPortIn,
  } = useCheckPortInFeasibilityIIMobile();

  const {
    mutate: mutateModify,
    isError: isErrorModify,
    isSuccess: isSuccessModify,
  } = useModifyPhoneNumberMobileProduct();

  useEffect(() => {
    if (flowEnabler) {
      mutatePortIn(portabilityPayload);
    }
  }, [flowEnabler]);

  useEffect(() => {
    if (isLoadingPortIn) {
      setPortabilityStatus({
        ...portabilityStatus,
        portInData: undefined,
        isLoadingCheck: true,
        isSuccessCheck: false,
        isErrorCheck: false,
      });
    } else if (isSuccessPortIn) {
      setPortabilityStatus({
        ...portabilityStatus,
        portInData: checkPortInData,
        isLoadingCheck: false,
        isSuccessCheck: true,
        isErrorCheck: false,
      });
    }
  }, [isLoadingPortIn, isSuccessPortIn]);

  useEffect(() => {
    const dataPortIn = getFromLocalStorageByKey('checkPortInFeasibilityIIQuery');
    if (
      ((isSuccessPortIn && checkPortInData) ||
        (dataPortIn as ICheckPortInFeasibilityIIResponse).resultDescription?.toLowerCase() ===
          'success') &&
      dataPortIn &&
      !isErrorPortIn &&
      (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultDescription?.toLowerCase() !==
        'failure' &&
      !(dataPortIn as unknown as IAPIError)?.error &&
      isStartFinalPortIn
    ) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoading: true,
        isSuccess: false,
        isError: false,
      });
      mutateModify(portabilityPayload);
    }
    if ((dataPortIn as unknown as IAPIError)?.error) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoadingCheck: false,
        isSuccessCheck: false,
        isErrorCheck: true,
        errorMessage: (dataPortIn as unknown as IAPIError)?.errorMessage,
      });
    }
    if (
      isSuccessPortIn &&
      (!dataPortIn ||
        (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultDescription?.toLowerCase() ===
          'failure')
    ) {
      resetPortIn();
      let errorText = '';
      if (
        (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]?.messageCode ===
        '9000054'
      ) {
        errorText = ErrorCodes.MIGRATION_CODE_ERROR;
      } else if (
        (dataPortIn as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]?.messageDescription
      ) {
        errorText = ErrorCodes.NUMBER_ERROR;
      } else {
        errorText = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
      }
      setPortabilityStatus({
        ...portabilityStatus,
        portInData: checkPortInData,
        isLoadingCheck: false,
        isSuccessCheck: false,
        isErrorCheck: true,
        errorMessage: errorText,
      });
    }
  }, [isLoadingPortIn, isSuccessPortIn, isErrorPortIn, isStartFinalPortIn]);

  useEffect(() => {
    if (!portabilityStatus?.isErrorCheck && isSuccessModify) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoading: false,
        isSuccess: true,
        isError: false,
      });
    }
    if (isErrorModify) {
      setPortabilityStatus({
        ...portabilityStatus,
        isLoading: false,
        isSuccess: false,
        isError: true,
      });
    }
  }, [portabilityStatus?.isErrorCheck, isSuccessModify, isErrorModify]);

  return null;
};
