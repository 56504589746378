import CheckboxAndTextProps from './checkboxAndText.models';
import { Checkbox, CheckboxDiv, CheckboxText, Wraper } from './checkboxAndText.style';

const CheckboxAndText = (p: CheckboxAndTextProps) => {
  return (
    <Wraper>
      <CheckboxDiv>
        <Checkbox>
          <input
            type="checkbox"
            checked={p.isChecked}
            onChange={() => p.toggleIsChecked(!p.isChecked)}
          />
          <span className="checkmark" />
        </Checkbox>
      </CheckboxDiv>
      <CheckboxText>{p.text}</CheckboxText>
    </Wraper>
  );
};

export default CheckboxAndText;
