import { IDevice, IDeviceCMS } from './devices.models';

/**
 * Return back the formatted string to be pushed in query string parameters
 * @param val
 * @returns
 */
export const formatQueryParam = (val: string) => val?.toLowerCase()?.replace(/\s/g, '-');

/**
 * Organize Device, mapping from CMS device in FE device
 * @param device
 * @param slug
 */
export const organizeDevice = (device: IDeviceCMS, slug: string): IDevice => ({
  ...device,
  devicedetail: {
    ...device.devicedetail,
    slug,
    isAvailable: device?.devicedetail?.isAvailable === 'true',
    isSellable: device?.devicedetail?.isSellable === 'true',
    haveMarketingLabel: device?.devicedetail?.haveMarketingLabel === 'true',
    hub: {
      ...device?.devicedetail?.hub,
      availableColor: {
        ...device?.devicedetail?.hub?.availableColor,
        enabled: device?.devicedetail?.hub?.availableColor?.enabled === 'true',
      },
    },
    pdp: {
      ...device?.devicedetail?.pdp,
      keywords: device?.devicedetail?.pdp.keywords?.map((keyword) => keyword.key) || [],
    },
    models:
      device?.devicedetail?.models?.map((model) => ({
        ...model,
        isDefault: model.isDefault === 'true',
        isAvailable: model.isAvailable === 'true',
        isSellable: model.isSellable === 'true',
        images: model.images?.map((image) => image.key) || [],
      })) || [],
  },
});

