import { useQuery } from 'react-query';
import { nextClient } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { ISubscriberResponse, QueryParams } from './winback.models';

const searchSubscriberByResourceService = (phoneNumber: string) =>
  nextClient.get(API.SEARCH_SUBSCRIBER_BY_RESOURCE, {
    searchParams: {
      salesChannel: QueryParams.SELF,
      levelOfData: QueryParams.SUBSCRIBER,
      limit: 1,
      sort: QueryParams.DESC,
      filters: decodeURIComponent(QueryParams.FILTER),
      subscriptionMsisdn: phoneNumber,
    },
  }) as Promise<ISubscriberResponse[]>;

export const useGetSubscriberByResource = (number: string, queryEnabler?: boolean) =>
  useQuery(['getSubscriberByResource'], () => searchSubscriberByResourceService(number), {
    enabled: queryEnabler || false,
  });
