import { IProduct, retrieveInTest } from '@vfit/consumer/data-access';

const checkIsInAbTest = (product: IProduct): IProduct => {
  const isInTest = retrieveInTest() && product?.enableAbTest;
  const abTestConfiguration = product.offerDetailABTest || {};
  return !isInTest
    ? product
    : {
        ...product,
        ...abTestConfiguration,
      };
};

const checkIsCVM = (product: IProduct): IProduct => {
  if (!product.isCvm) return product;
  if (!product?.cvmConfig || product?.cvmConfig?.length === 0) return product;
  const urlParams = new URLSearchParams(window.location.search);
  const cvmConfiguration = urlParams?.get('cvm');
  if (!cvmConfiguration) return product;
  const foundedCvmConfig = product.cvmConfig.find((el) => el.idCvm === cvmConfiguration);
  if (!foundedCvmConfig) return product;
  return {
    ...product,
    price: foundedCvmConfig?.price || '',
    subprice: foundedCvmConfig?.subprice || '',
    originalPrice: foundedCvmConfig?.originalPrice || '',
    recurrence: foundedCvmConfig?.recurrence || '',
    title: foundedCvmConfig?.titleHubCard || '',
    description: foundedCvmConfig?.priceDescription || '',
    customDescriptionCVM: foundedCvmConfig?.stickyOfferDescription || '',
    action: {
      ...foundedCvmConfig?.mainAction,
      // eslint-disable-next-line no-unsafe-optional-chaining
      type: foundedCvmConfig?.mainAction?.type ? +foundedCvmConfig?.mainAction?.type : -1,
      callMeNow: foundedCvmConfig?.mainAction?.url || '',
    },
    pdpSecondAction: {
      ...foundedCvmConfig?.secondAction,
      // eslint-disable-next-line no-unsafe-optional-chaining
      type: foundedCvmConfig?.secondAction?.type ? +foundedCvmConfig?.secondAction?.type : -1,
      callMeNow: foundedCvmConfig?.secondAction?.url || '',
    },
  };
};

const checkProductConfigurations = (product: IProduct): IProduct => {
  let productToReturn = checkIsInAbTest(product);
  productToReturn = checkIsCVM(productToReturn);
  return productToReturn;
};

export { checkProductConfigurations };
