import { useMutation, useQueryClient } from 'react-query';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';

interface IReleaseResourcePayload {
  valueList: string[];
}

/**
 * Methods to invoke the POST /release resource service
 * @param payload
 * @param customOptions
 */
const releaseResourceService = (payload: any, customOptions?: CustomOptions) =>
  nextClient.post(API.RELEASE_RESOURCE, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
    searchParams: {
      salesChannel: 'selfService',
    },
  });

/**
 * Mutation reserve phone number
 */
export const useReleaseResource = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'releaseResource',
    ({ valueList }: IReleaseResourcePayload) => {
      const payload = {
        value: valueList,
        resourceType: 'msisdn',
      };
      LoggerInstance.debug('reservePhoneNumber payload ', payload);

      return releaseResourceService(payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem('releaseResource', JSON.stringify(data));
        queryClient.setQueryData('releaseResource', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - releaseResource: `, error);
        queryClient.setQueryData('releaseResource', errorMock(`releaseResource`, error));
        localStorage.setItem(
          `releaseResource`,
          JSON.stringify(errorMock(`releaseResource`, error))
        );
      },
    }
  );
};
