import { ICMSWidgetType, IPageProps, ITaggingCMS } from '@vfit/business-data-access';
import {
  HubPostCoverageContextProvider,
  PageLoadingContextProvider,
  RetargetContextProvider,
  TrackingPageContextProvider,
  NationContextProvider,
  PopUpFilterProvider,
} from '@vfit/business-data-access';
import { Footer, Header } from '@vfit/shared/components';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { TabsContextProvider } from '@vfit/shared/data-access';
import { withProtected } from './hoc/withProtected';
import { PageLayout } from './layout/pageLayout.style';
import Widgets from '../Widgets/widgets';
import { IBusinessSliderProductsHub } from '../WidgetSliderProductsHub/widgetSliderProductsHub.models';

const Hub = ({ page, isApp }: IPageProps) => {
  const { elements, haveHeader, isHeaderSmall, haveFooter } = page?.params || {};
  const { header, tagging } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);

  const hubProduct = Object.values(elements ?? {}).find(
    (widget) => widget.type === ICMSWidgetType.SLIDER_PRODUCTS_HUB_BUSINESS
  ) as IBusinessSliderProductsHub;

  return (
    <>
      {haveHeader && !isApp && <Header onTrack={tagging} header={header} lite={isHeaderSmall} />}
      <PageLayout className="noPadding">
        <PageLoadingContextProvider>
          <TrackingPageContextProvider
            hubProduct={hubProduct}
            tagging={elements?.tagging as ITaggingCMS}
            layer="hubDataLayer"
            type={page?.params?.pageType}
          >
            <RetargetContextProvider>
              <TabsContextProvider>
                <PopUpFilterProvider>
                <NationContextProvider pageType={elements?.pagetype || {}}>
                  <HubPostCoverageContextProvider
                    pageType={elements?.pagetype || {}}
                    hubProduct={hubProduct}
                  >
                    <Widgets aemElements={elements ?? {}} />
                  </HubPostCoverageContextProvider>

                </NationContextProvider>
                </PopUpFilterProvider>
              </TabsContextProvider>
            </RetargetContextProvider>
          </TrackingPageContextProvider>
        </PageLoadingContextProvider>
      </PageLayout>
      {haveFooter && !isApp && <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />}
    </>
  );
};

const HubProtected = withProtected()(Hub);

export default HubProtected;
