import { breakpoints, fonts, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";

export const Title = styled.div`
  padding: 32px 0 16px;
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  font-family: ${fonts.exbold};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    padding: 60px 0 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Text = styled.div`
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  font-family: ${fonts.regular};
`;

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    max-height: inherit;
    margin: 0 auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: 0;

    ::-webkit-scrollbar {
      width: 0; /* for Chrome, Safari, and Opera */
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-height: inherit;
  }
`;