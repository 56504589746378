import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Skeleton } from '@vfit/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCustomerIdentification } from '@vfit/consumer/data-access';
import { purify } from '@vfit/shared/themes';
import { documentIdSchema } from './documentId.validation';
import {
  IDocumentId,
  IDocumentIdData,
  IIdentification,
  IPersonalDataObject,
} from './documentId.models';
import { Div, Footer, Text, Title, Wrapper, skeletonShape } from './documentId.style';

const DocumentId = ({
  data,
  nationality,
  identificationType,
  onChangeData,
  isReadonly,
  isLoading,
  setIsValidForm,
  configCms,
}: IDocumentIdData) => {
  const { owningIndividual } = data;
  const {
    title,
    docExpirationDateLable,
    docLable,
    docNumberLable,
    errorMessage,
    countryLable,
    subtitle,
  } = configCms;
  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { isValid, touchedFields, errors },
  } = useForm<IDocumentId>({
    resolver: yupResolver(documentIdSchema()),
    mode: 'all',
  });
  const [dataChanged, setDataChanged] = useState<number>(0);
  const [setFieldValue] = useState<string>('');
  const isPassed = (passeData?: IIdentification) =>
    passeData?.identificationType !== '' &&
    passeData?.identificationNumber !== '' &&
    passeData?.expirationDate !== '' &&
    passeData?.nationality !== '';

  const [purifySubtitle] = purify([subtitle || '']);

  useEffect(() => {
    if (!isLoading) {
      const identification = getCustomerIdentification(owningIndividual);
      const shouldValidate = isPassed(identification || undefined);
      if (owningIndividual) {
        setValue('identificationType', identification?.identificationType || '', {
          shouldValidate,
        });
        setValue('identificationNumber', identification?.identificationNumber || '', {
          shouldValidate,
        });
        setValue('expirationDate', identification?.expirationDate || '', {
          shouldTouch: shouldValidate,
          shouldValidate,
        });
        setValue('nationality', identification?.nationality || '', {
          shouldValidate,
        });
      }
    }
  }, [isLoading, data]);

  const changeData = () => {
    const inputs = getValues();
    const changedData: IPersonalDataObject = {
      ...data,
      owningIndividual: {
        ...owningIndividual,
        identification: [
          {
            identificationType: inputs.identificationType || '',
            nationality: inputs.nationality || '',
            identificationNumber: inputs.identificationNumber || '',
            expirationDate: inputs.expirationDate || '',
          },
        ],
      },
    };
    if (onChangeData) onChangeData(changedData, isValid);
    setDataChanged((prevState) => prevState + 1);
  };

  useEffect(() => {
    setIsValidForm?.(isValid);
  }, [dataChanged, isValid]);

  useEffect(() => {
    if (!isLoading) changeData();
  }, [isValid]);

  return (
    <Wrapper>
      {isLoading ? (
        <Skeleton {...skeletonShape} />
      ) : (
        <>
          <Title>{title}</Title>
          {purifySubtitle && (
            <Text
              dangerouslySetInnerHTML={{
                __html: purifySubtitle,
              }}
            />
          )}
          <Div>
            <div className="textInput">
              <Form.SelectInput
                label={docLable}
                setValue={() => setFieldValue}
                onSelectValue={(selectedValue) => {
                  setValue('identificationType', selectedValue);
                  trigger('identificationType');
                  setValue('identificationNumber', '');
                  trigger('identificationNumber');
                  changeData();
                }}
                isDisabled={isLoading || isReadonly}
                errorMessage={errors.identificationType?.message}
                placeholder=" "
                {...register('identificationType', {
                  onChange: changeData,
                })}
                items={identificationType || ['Patente', 'Passaporto', 'Carta']}
              />
            </div>
            <div className="textInput">
              <Form.TextInput
                label={docNumberLable}
                placeholder=" "
                autoComplete="off"
                disabled={isLoading || isReadonly}
                error={
                  touchedFields.identificationNumber ? errors.identificationNumber?.message : ''
                }
                {...register('identificationNumber', {
                  onChange: changeData,
                })}
              />
            </div>
            <div className="textInput">
              <Form.MaskInput
                mask="99/99/9999"
                label={docExpirationDateLable}
                placeholder=" "
                autoComplete="off"
                disabled={isLoading || isReadonly}
                error={errors.expirationDate?.message}
                {...register('expirationDate', {
                  onChange: changeData,
                })}
              />
            </div>
            <div className="textInput">
              <Form.SelectInput
                label={countryLable}
                isDisabled={isLoading || isReadonly}
                setValue={() => setFieldValue}
                onSelectValue={(selectedValue) => {
                  setValue('nationality', selectedValue);
                  trigger('nationality');
                  changeData();
                }}
                errorMessage={touchedFields.nationality ? errors.nationality?.message : ''}
                suggestionsMaxHeight="120px"
                placeholder=" "
                {...register('nationality', {
                  onChange: changeData,
                })}
                items={nationality || ['Italia']}
              />
            </div>
          </Div>
          <Footer>{errorMessage}</Footer>
        </>
      )}
    </Wrapper>
  );
};

export default DocumentId;
