import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';
import { Wrapper } from '../../fallbackModal.style';

export const DetailsWrapper = styled(Wrapper)`
  margin-top: -32px;
  margin-bottom: 150px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: -28px;
    margin-bottom: 150px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: -48px;
    margin-bottom: 110px;
  }
`;



export const ButtonDiv = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;

  div {
    width: 100%;
  }

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 80%;
  }

  @media (min-width: ${breakpoints.tablet}) {
    div {
      width: 290px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-flow: row;
  }
`;

