import { retrieveInTestByOffer, useGetShoppingCartSilentUpdate } from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import {
  ECodeIdentification,
  IIdentificationDetails,
  IUpdateProductPriceType,
} from '@vfit/shared/models';
import { resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { useCheckout } from '../../../iBuyFixed.context';
import { updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';
import { useDeliveryBaseAdvanceFlow } from '../UseDeliveryBaseAdvance/useDeliveryBaseAdvance';

export const useCustomerDeliveryBase = () => {
  const {
    product,
    isStartRecognition,
    isStartDeliveryBase,
    customerDeliveryBaseInfo,
    setIsStartDeliveryBase,
    setProduct,
    setCustomerDeliveryBaseInfo,
  } = useCheckout();
  const queryClient = useQueryClient();
  const {
    isSuccess: isDeliverySuccess,
    isLoading: isDeliveryLoading,
    isError: isDeliveryError,
    errorMessage: deliveryErrorMessage,
    error: deliveryError,
    deliveryOptions,
    identificationDetails,
  } = useDeliveryBaseAdvanceFlow({ enabled: isStartDeliveryBase });
  const { isSuccess: isSuccessShoppingCart, refetch: refetchShoppingCart } =
    useGetShoppingCartSilentUpdate(undefined, { enabled: false });

  /**
   * Save delivery, first get. Enable VR only in ABtest
   */
  const saveDeliveryState = () => {
    let identificationDetailsFiltered: IIdentificationDetails | IIdentificationDetails[] = [];
    const isEnabledVR = product?.enableVR ? true : retrieveInTestByOffer();
    if (isEnabledVR) {
      identificationDetailsFiltered = identificationDetails;
    } else if (Array.isArray(identificationDetails)) {
      identificationDetailsFiltered = identificationDetails?.filter(
        (el) => el?.code !== ECodeIdentification.VR
      );
    } else if ((identificationDetails as IIdentificationDetails)?.code === ECodeIdentification.VR) {
      identificationDetailsFiltered = {} as IIdentificationDetails;
    }
    if (isDeliverySuccess) {
      refetchShoppingCart();
    }
    setCustomerDeliveryBaseInfo({
      ...customerDeliveryBaseInfo,
      deliveryBase: {
        ...customerDeliveryBaseInfo.deliveryBase,
        isLoading: isDeliveryLoading,
        isSuccess: isDeliverySuccess,
        isError: isDeliveryError,
        errorMessage: deliveryErrorMessage,
        error: deliveryError,
        deliveryOptions,
        identificationDetails: identificationDetailsFiltered,
      },
    });
  };

  useEffect(() => {
    if (isSuccessShoppingCart) {
      setProduct({
        ...product,
        ...updateProductPrice(product, IUpdateProductPriceType.EDIT),
      });
    }
  }, [isSuccessShoppingCart]);

  useEffect(() => {
    if (isStartDeliveryBase) setIsStartDeliveryBase(false);
  }, [isStartDeliveryBase]);

  useEffect(() => {
    saveDeliveryState();
  }, [
    isDeliveryError,
    isDeliverySuccess,
    isDeliveryLoading,
    deliveryOptions,
    identificationDetails,
  ]);

  useEffect(() => {
    if (isStartRecognition) {
      resetData(queryClient, ['deliveryDetailsIsBase']);
      setCustomerDeliveryBaseInfo({
        ...customerDeliveryBaseInfo,
        deliveryBase: {
          ...customerDeliveryBaseInfo.deliveryBase,
          isSuccess: customerDeliveryBaseInfo?.deliveryBase?.isSuccess,
          isError: false,
          error: undefined,
          // this loading is true because after modify in called delivery base
          // and this loading is for remove lag for cards
          isLoading: true,
        },
      });
    }
  }, [isStartRecognition]);

  return null;
};
