import { IOptionalText } from '@vfit/shared/models';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { useCheckout } from '../../../iBuyMobile.context';
import { getEsimItem, isAlreadySelectedEsim } from './useOnlyEsimFlow.utils';

export const useOnlyEsimFlow = () => {
  const {
    product,
    simOptions,
    portabilityStatus,
    currentStepKey,
    setSimTypeSelectedOption,
    setSimOptions,
  } = useCheckout();

  const handleSelectEsim = (item: IOptionalText | undefined) => {
    if (!item) return;
    const modifyMobileSimDeviceId = getFromLocalStorageByKey('modifyMobileSimDeviceId');
    const selectedOption = simOptions?.simOptions?.find((el) => el.value === item.value);
    if (
      selectedOption &&
      selectedOption?.id !== modifyMobileSimDeviceId?.simOptionId &&
      !portabilityStatus?.isLoadingCheck
    ) {
      setSimTypeSelectedOption(selectedOption);
    }
    setSimOptions({
      ...simOptions,
      simOptions: simOptions?.simOptions?.map((sO) => {
        if (sO.value === item?.value) {
          return {
            ...sO,
            selection: true,
          };
        }
        return {
          ...sO,
          selection: false,
        };
      }),
    });
  };

  useEffect(() => {
    if (product?.isEsimOnly && !isAlreadySelectedEsim(simOptions)) {
      const esimItem = getEsimItem(simOptions);
      if (esimItem)
        handleSelectEsim({
          title: esimItem?.title || '',
          value: esimItem?.value || '',
        });
    }
  }, [currentStepKey]);

  return null;
};
