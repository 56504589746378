import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 35px 20px 37px;
  text-align: left;
  font-weight: 400;
  color: ${colors.$262626};
  font-family: ${fonts.regular};

  div {
    justify-content: left;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 59px 40px 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 60px 55px 0;
  }
`;

export const Title = styled.div`
  text-align: center;
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin-bottom: ${pxToRem(16)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled.div`
  text-align: center;
  ${pxToCssFont(20, 26)}
  margin-bottom: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${pxToRem(40)};
  }
`;

export const Lable = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 700;
  ${pxToCssFont(18, 24)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  div {
    height: ${pxToRem(24)};
  }

  span {
    color: ${colors.$00697c};
  }
`;

export const BottomText = styled.div`
  font-family: ${fonts.exbold};
  ${pxToCssFont(20, 26)}
  margin-bottom: ${pxToRem(16)};
  margin-top: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
    margin-bottom: ${pxToRem(12)};
    margin-top: ${pxToRem(40)};
  }
`;

export const TextLoader = styled.div`
  width: inherit;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 150px;
  margin-top: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;
