import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  padding: 0 20px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 40px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 54px;
  }
`;

export const Div = styled.div`
  padding-top: 32px;
  gap: 16px;
  display: flex;
  flex-direction: column;

  p {
    position: relative;
  }

  @media (min-width: ${breakpoints.tablet}) {
    gap: 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-top: 40px;
  }
`;

export const LinkDiv = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  padding-top: 16px;
  margin-bottom: 46px;

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 12px;
  }

  button {
    background: transparent;
    text-align: left;
    border: none;
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(16, 22)}
    color: #262626;
    text-decoration: underline;
    cursor: ${({ isDisabled }) => (isDisabled ? 'blocked' : 'pointer')};
  }
`;
export const HowToLink = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  padding-top: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 12px;
  }

  button {
    background: transparent;
    text-align: left;
    border: none;
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(16, 22)}
    color: #262626;
    text-decoration: underline;
    cursor: ${({ isDisabled }) => (isDisabled ? 'blocked' : 'pointer')};
  }
`;
export const Title = styled.div`
  padding: 32px 0 16px;
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  font-family: ${fonts.exbold};
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    padding: 60px 0 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Text = styled.div`
  font-weight: 400;
  ${pxToCssFont(20, 26)}
  font-family: ${fonts.regular};
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }
`;

export const ContentSpinner = styled.div`
  svg {
    width: 25px;
    height: 25px;
    margin: -10px 0 0 -25px;
  }
`;
