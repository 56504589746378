import { IPersonalInfoCmsMobile } from '@vfit/consumer/data-access';

export const organizeRecognitionSimVideoRecognition = (
  data: IPersonalInfoCmsMobile,
  isEsim: boolean
) => {
  const cmsConfig = isEsim
    ? data?.personalinfomobile?.recognitionVideoEsim
    : data?.personalinfomobile?.recognitionVideo;
  return {
    data: {
      title: cmsConfig?.title || '',
      description: cmsConfig?.description || '',
      textBold: cmsConfig?.textBold || '',
      text: cmsConfig?.text || '',
    },
    list: {
      title: cmsConfig?.list?.[0]?.title || '',
      items: cmsConfig?.list?.[0]?.elements || [
        {
          text: '',
          icon: '',
        },
      ],
      portabilityItems: cmsConfig?.list?.[0]?.portability || [
        {
          text: '',
          icon: '',
        },
      ],
    },
  };
};
