import { breakpoints, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(32)};
  padding: 0;
  margin: ${pxToRem(20, 40)};

  @media (min-width: ${breakpoints.tablet}) {
    margin: ${pxToRem(20, 60)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0;
    width: 100%;
    padding: ${pxToRem(80)};
  }
`;
