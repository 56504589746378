import React, { useContext, useState } from 'react';
import { IProduct } from '@vfit/consumer/data-access';
import { IFindCallerObject, IPersonalDataObject } from '@vfit/shared/models';
import {
  ICoverageToolType,
  IInputsCoverageTool,
  IInputsCoverageToolError,
} from '../CoverageTool/coverageTool.models';

interface ICoverageToolModalProvider {
  children: React.ReactNode;
}

export type ICoverageToolModalContext = {
  address: IInputsCoverageTool;
  offerId: string;
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  isAddressConfirmed: boolean;
  errorCoverage: IInputsCoverageToolError;
  setAddress: (address: IInputsCoverageTool) => void;
  setOfferId: (offerId: string) => void;
  coverageLockInUser: IPersonalDataObject;
  product: IProduct | null;
  slidesGoBack: number;
  isManual: boolean;
  isForcedManual: boolean;
  isDisabledBackButton: boolean;
  findCaller: IFindCallerObject | null;
  setIsSuccess: (isSuccess: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsError: (isError: boolean) => void;
  setIsAddressConfirmed: (isAddressConfirmed: boolean) => void;
  setErrorCoverage: (address: IInputsCoverageToolError) => void;
  setCoverageLockInUser: (coverageLockInUser: IPersonalDataObject) => void;
  setProduct: (product: IProduct) => void;
  setSlidesGoBack: (slides: number) => void;
  setIsManual: (isManual: boolean) => void;
  setIsForcedManual: (isForcedManual: boolean) => void;
  setFindCaller: (findCaller: IFindCallerObject | null) => void;
  setIsDisabledBackButton: (isDisabled: boolean) => void;
};

export const DEFAULT_IS_LOADING = false;

export const DEFAULT_IS_SUCCESS = false;

export const DEFAULT_IS_ERROR = false;

export const DEFAULT_IS_CONFIRMED = false;

export const DEFAULT_IS_DISABLED_BACK_BUTTON = false;

export const DEFAULT_LOCKIN_USER: IPersonalDataObject = {
  owningIndividual: {
    nation: '',
    fiscalCode: '',
    firstName: '',
    lastName: '',
    contactMedium: [
      {
        type: 'email',
        preferred: true,
        emailAddress: '',
      },
      { type: 'phone', preferred: false, phoneNumber: '' },
    ],
    identification: [
      {
        identificationType: '',
        nationality: '',
        identificationNumber: '',
        expirationDate: '',
      },
    ],
  },
};

export const DEFAULT_ADDRESS: IInputsCoverageTool = {
  placeId: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  streetNumber: '',
  street: '',
  completeAddress: '',
  typeCoverage: ICoverageToolType.AUTO,
};

export const DEFAULT_OFFER_ID = '';

export const DEFAULT_ERROR_COVERAGE: IInputsCoverageToolError = {
  placeId: '',
  city: '',
  postalCode: '',
  stateOrProvince: '',
  streetNumber: '',
  street: '',
  typeCoverage: ICoverageToolType.AUTO,
  showInModal: false,
};

export const DEFAULT_SLIDES_GO_BACK = -1;

export const DEFAULT_IS_MANUAL = false;

export const DEFAULT_FORCED_MANUAL = false;

export const DEFAULT_FIND_CALLER: IFindCallerObject | null = null;

export const CoverageToolModalContext = React.createContext<ICoverageToolModalContext>({
  address: DEFAULT_ADDRESS,
  offerId: DEFAULT_OFFER_ID,
  errorCoverage: DEFAULT_ERROR_COVERAGE,
  coverageLockInUser: DEFAULT_LOCKIN_USER,
  slidesGoBack: DEFAULT_SLIDES_GO_BACK,
  isManual: DEFAULT_IS_MANUAL,
  isForcedManual: DEFAULT_FORCED_MANUAL,
  product: null,
  findCaller: null,
  isLoading: DEFAULT_IS_LOADING,
  isSuccess: DEFAULT_IS_SUCCESS,
  isError: DEFAULT_IS_ERROR,
  isAddressConfirmed: DEFAULT_IS_CONFIRMED,
  isDisabledBackButton: DEFAULT_IS_DISABLED_BACK_BUTTON,
  setIsSuccess: () => {},
  setIsLoading: () => {},
  setIsError: () => {},
  setIsAddressConfirmed: () => {},
  setOfferId: () => {},
  setAddress: () => {},
  setErrorCoverage: () => {},
  setCoverageLockInUser: () => {},
  setProduct: () => {},
  setSlidesGoBack: () => {},
  setIsManual: () => {},
  setIsForcedManual: () => {},
  setFindCaller: () => {},
  setIsDisabledBackButton: () => {},
});

export const CoverageToolModalProvider = ({ children }: ICoverageToolModalProvider) => {
  const [offerId, setOfferIdData] = useState<string>(DEFAULT_OFFER_ID);
  const [address, setInfoAddress] = useState<IInputsCoverageTool>({
    ...DEFAULT_ADDRESS,
  });
  const [coverageLockInUser, setCoverageLockInUserData] = useState<IPersonalDataObject>({
    ...DEFAULT_LOCKIN_USER,
  });
  const [errorCoverage, setErrorCoverageAddress] = useState<IInputsCoverageTool>({
    ...DEFAULT_ERROR_COVERAGE,
  });
  const [product, setProductData] = useState<IProduct | null>(null);
  const [slidesGoBack, setSlidesGoBackData] = useState<number>(DEFAULT_SLIDES_GO_BACK);
  const [isManual, setIsManualData] = useState<boolean>(DEFAULT_IS_MANUAL);
  const [isForcedManual, setIsForcedManualData] = useState<boolean>(DEFAULT_FORCED_MANUAL);
  const [findCaller, setFindCallerData] = useState<IFindCallerObject | null>(DEFAULT_FIND_CALLER);
  const [isSuccess, setIsSuccessData] = useState<boolean>(DEFAULT_IS_SUCCESS);
  const [isLoading, setIsLoadingData] = useState<boolean>(DEFAULT_IS_LOADING);
  const [isError, setIsErrorData] = useState<boolean>(DEFAULT_IS_ERROR);
  const [isAddressConfirmed, setIsAddressConfirmedData] = useState<boolean>(DEFAULT_IS_CONFIRMED);
  const [isDisabledBackButton, setIsDisabledBackButtonData] = useState<boolean>(
    DEFAULT_IS_DISABLED_BACK_BUTTON
  );

  const setOfferId = (offerIdData: string) => {
    setOfferIdData(offerIdData);
  };

  const setAddress = (addressInfo: IInputsCoverageTool) => {
    setInfoAddress({ ...addressInfo });
  };

  const setErrorCoverage = (addressInfo: IInputsCoverageToolError) => {
    setErrorCoverageAddress({ ...addressInfo });
  };

  const setCoverageLockInUser = (coverageLockInUserInfo: IPersonalDataObject) => {
    setCoverageLockInUserData({ ...coverageLockInUserInfo });
  };

  const setProduct = (productInfo: IProduct) => {
    setProductData({ ...productInfo });
  };

  const setSlidesGoBack = (slidesBack: number) => {
    setSlidesGoBackData(slidesBack);
  };

  const setIsManual = (isManualData: boolean) => {
    setIsManualData(isManualData);
  };

  const setIsForcedManual = (isForcedManualData: boolean) => {
    setIsForcedManualData(isForcedManualData);
  };

  const setIsSuccess = (isSuccessData: boolean) => {
    setIsSuccessData(isSuccessData);
  };

  const setIsLoading = (isLoadingData: boolean) => {
    setIsLoadingData(isLoadingData);
  };

  const setIsError = (isErrorData: boolean) => {
    setIsErrorData(isErrorData);
  };

  const setIsAddressConfirmed = (isAddressConfirmedData: boolean) => {
    setIsAddressConfirmedData(isAddressConfirmedData);
  };

  const setFindCaller = (findCallerData: IFindCallerObject | null) => {
    setFindCallerData(findCallerData);
  };

  const setIsDisabledBackButton = (isDBB: boolean) => {
    setIsDisabledBackButtonData(isDBB);
  };

  return (
    <CoverageToolModalContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        address,
        setAddress,
        offerId,
        setOfferId,
        errorCoverage,
        setErrorCoverage,
        coverageLockInUser,
        setCoverageLockInUser,
        product,
        setProduct,
        slidesGoBack,
        setSlidesGoBack,
        isManual,
        setIsManual,
        isForcedManual,
        setIsForcedManual,
        findCaller,
        setFindCaller,
        isSuccess,
        setIsSuccess,
        isLoading,
        setIsLoading,
        isError,
        setIsError,
        isAddressConfirmed,
        setIsAddressConfirmed,
        isDisabledBackButton,
        setIsDisabledBackButton,
      }}
    >
      {children}
    </CoverageToolModalContext.Provider>
  );
};

export const useCoverageToolModalProvider = () => useContext(CoverageToolModalContext);
