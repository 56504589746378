import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import * as style from './appliedVoucher.style';
import { AppliedVoucherModel } from './appliedVoucher.models';
import { organizeVoucherDiscount } from './voucherDiscount.organize';

const AppliedVoucher: React.FC<AppliedVoucherModel> = ({ voucherInput, voucherDiscountData }) => {
  const { src, srcSet } = requireStaticImageAtomComponent(
    voucherDiscountData?.vouchersdiscount?.appliedVoucher?.image || ''
  );

  const appliedVoucherData = organizeVoucherDiscount(voucherDiscountData, voucherInput);

  return (
    <style.Container>
      <style.ImageStyle alt="celebrate.svg" srcSet={srcSet} src={src} />
      <h1>{appliedVoucherData?.vouchersdiscount?.appliedVoucher?.title}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: appliedVoucherData?.vouchersdiscount?.appliedVoucher?.subtitle || '',
        }}
      />
      <style.DiscountAppliedBox>
        <h1>{appliedVoucherData?.vouchersdiscount?.appliedVoucher?.recurrenceDisclaimer}</h1>
        <style.PriceDiscount>
          <p>27,90€</p>
          <p>24,90€</p>
          <p>/mese</p>
        </style.PriceDiscount>
      </style.DiscountAppliedBox>
    </style.Container>
  );
};

export default AppliedVoucher;

