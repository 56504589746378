import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ProductSolutionsContainer = styled.div`
  display: block;
  margin-bottom: 48px;

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 45px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    // margin-bottom: 120px;
    justify-content: space-between;
  }
`;

export const ProductSolutionsText = styled.div`
  div {
    text-align: left;
  }

  h3 {
    font-family: ${fonts.exbold};
    ${pxToCssFont(20, 26)}
  }

  h4 {
    font-family: ${fonts.light};
    ${pxToCssFont(20, 26)}
    margin-top: 12px;
  }

  p {
    margin-top: 12px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    div {
      margin-top: 0;
      text-align: left;
      max-width: 420px;
    }

    h3 {
      ${pxToCssFont(30, 38)}
    }

    h4 {
      ${pxToCssFont(24, 30)}
      margin-top: 14px;
    }

    p {
      margin-top: 14px;
    }
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    div {
      text-align: left;
      max-width: 624px;
    }
  }
`;

export const ProductSolutionsProduct = styled.div`
  width: 100%;
  margin-right: 33px;
`;

export const Container = styled.div`
  div {
    margin: 0 24px;
  }

  .Image {
    height: 64px;
    width: 64px;
    top: 0;
    border-radius: 0;
    margin: 0 auto;
  }

  .Player {
    height: 64px;
    width: 64px;
    top: 0;
    border-radius: 0;
  }

  .Title {
    font-family: ${fonts.exbold};
    ${pxToCssFont(36, 45)}
    font-weight: 400;
    letter-spacing: 0;
    margin: 12px 24px;
    text-align: center;

    @media (min-width: ${breakpoints.tablet}) {
      text-align: left;
    }
  }

  .Description {
    div {
      margin: 0;
      padding-bottom: 32px;

      p,
      span {
        font-family: ${fonts.regular};
        ${pxToCssFont(24, 30)}
        font-weight: 400 !important;
        letter-spacing: 0;
        text-align: center;
      }
    }

    @media (min-width: ${breakpoints.tablet}) {
      div {
        padding-bottom: 0;

        p {
          text-align: left;
          ${pxToCssFont(24, 30)};
          margin-bottom: 15px;
        }
      }
    }

    @media (min-width: ${breakpoints.desktop}) {
      div {
        padding-bottom: 0;

        p {
          text-align: left;
          ${pxToCssFont(24, 30)};
          margin-bottom: 15px;
        }
      }
    }
  }
`;
