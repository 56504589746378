import { useMutation, useQueryClient } from 'react-query';
import ky from 'ky';
import { LoggerInstance, INextError, CustomOptions, handleEnv } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { errorMock } from '@vfit/consumer/data-access';
import { IDeleteShoppingCartResponse } from './deleteCart.models';

/**
 * Shopping cart DELETE service
 * The client is not used because I need to pass a custom header.
 * The delete is not always done with the header of the new cart.
 * We didn't have the time to customize nextClient
 * @param shoppingCartId
 * @param customOptions
 * @returns
 */
export const deleteShoppingCartService = (
  shoppingCartId: string,
  customOptions?: CustomOptions
): Promise<IDeleteShoppingCartResponse> => {
  const prefixUrl = handleEnv('NEXT_PUBLIC_NEXT_ENVIRO') || '';
  return ky
    .delete(`${prefixUrl}/${API.SHOPPING_CART}/${shoppingCartId}`, {
      ...(customOptions?.headers && { headers: customOptions.headers }),
    })
    .json();
};

interface IDeleteShoppingCartPayload {
  shoppingCartId: string;
  customOptions?: CustomOptions;
}

/**
 * This method handle the delete cart service.
 * @returns
 */
export const useDeleteShoppingCartMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'deleteShoppingCartService',
    (payload: IDeleteShoppingCartPayload) =>
      deleteShoppingCartService(payload.shoppingCartId, payload.customOptions),
    {
      onSuccess: (data: IDeleteShoppingCartResponse) => {
        LoggerInstance.debug(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - deleteShoppingCart: `, error);
        queryClient.setQueryData('deleteShoppingCart', errorMock('deleteShoppingCart', error));
        localStorage.setItem(
          `deleteShoppingCart`,
          JSON.stringify(errorMock(`deleteShoppingCart`, error))
        );
      },
    }
  );
};
