import { IAddressObject } from '@vfit/shared/models';

export const retrieveAddress = (add: IAddressObject) => {
  let addressString = '';
  Object.values(add).map((el: string) => {
    if (el) addressString = addressString.concat(el, ', ');
    return null;
  });
  return addressString;
};
