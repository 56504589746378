import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  CART_TYPE,
  errorMock,
  getCostFromShoppingCartByType,
  getLastPaymentMethod,
  getOneTimeAmountFromShoppingCart,
  isMobileProduct,
  retrieveProduct,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  IPayMeanServiceResponse,
  IPaymentServiceResponse,
  ISIAFlowType,
} from '@vfit/shared/models';
import {
  IAuthorizeMutationPayload,
  IAuthorizePayload,
  IAuthorizeResponse,
} from './authorize.models';

/**
 * This method calls the Authorize Service
 * Method: POST
 * SearchParams: salesChannel: selfService, isIBANValidationRequired: 'false'
 * @param payload
 * @param cartId
 * @param customOptions
 * @returns
 */
export const authorizeService = (
  payload: IAuthorizePayload,
  cartId: string,
  customOptions?: CustomOptions
): Promise<IAuthorizeResponse> =>
  nextClient.post(`${API.PAYMENT_MANAGEMENT_ORDER}/${cartId}/${API.AUTHORIZE}`, payload, {
    searchParams: {
      ...nextClient.searchParams,
      isIBANValidationRequired: 'false',
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * This is a custom hook to manage the authorizeService.
 * @returns
 */
export const useAuthorizeMutation = () => {
  LoggerInstance.debug('HANDLE SELECT IN AUTHORIZE');
  const queryClient = useQueryClient();
  return useMutation(
    'paymentAuth',
    ({
      payMeanType: payloadPaymeanType,
      siaFlowType,
      authorizationOperation: authorizationOperationMethod,
    }: IAuthorizeMutationPayload) => {
      const product = retrieveProduct();
      const shoppingCart = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      LoggerInstance.debug('HANDLE SELECT IN AUTHORIZE ENTER MUTATION CALL');
      const payMean: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');
      const paymentData: IPaymentServiceResponse = getFromLocalStorageByKey('paymentData');
      const recurrence: string = retrieveProduct()?.recurrence;
      const offerId: string = product?.offerId?.toString();
      const lastPayment = getLastPaymentMethod(payMean);
      // use'verify' only for already existing payment
      let authorizationOperation = authorizationOperationMethod;
      if (!authorizationOperation)
        authorizationOperation = payloadPaymeanType ? 'capture' : 'verify';

      // region Different Payload Fixed/mobile
      let price;
      let flowType;
      let payMeanPurpose;
      let ordDescr;
      let formattedValue;
      let payMeanType;
      let payMeanId;
      const IS_SUNRISE_COP_FLOW = isMobileProduct() || product?.enableRecurringPayment;
      if (!IS_SUNRISE_COP_FLOW) {
        const recurringAmount =
          getCostFromShoppingCartByType(CART_TYPE.RECURRING)?.formattedPrice?.toString() || 0;
        price =
          product.isLockInProduct || product.isLockInMMProduct
            ? `${recurringAmount}€`
            : retrieveProduct()?.price;
        formattedValue = price
          .toString()
          .replace(',', '.')
          .replace('/', ',')
          .replace('€', '&euro;'); // "27.90&euro;mese,3893198"
        flowType = 'cop';
        payMeanPurpose =
          paymentData?.orderPayment?.orderItemPayments?.[0]?.orderItemOnBillPayment
            ?.payMeanPurpose || 'onBill';
        ordDescr = formattedValue.concat(`,${recurrence},${offerId},`);
        payMeanType = payloadPaymeanType || lastPayment?.type || undefined;
        if (authorizationOperation === 'verify' && payMean) payMeanId = lastPayment?.id;
      } else {
        price = retrieveProduct()?.price;
        formattedValue = price
          .trim()
          .toString()
          .replace(',', '.')
          .replace('/', ',')
          .replace('€', '&euro;'); // "27.90&euro;mese,3893198"
        flowType = 'Sunrise_Mobile_Cop';
        payMeanPurpose = siaFlowType === ISIAFlowType.RECURRENT ? 'onBill' : 'immediate';
        payMeanType = payloadPaymeanType || lastPayment?.type || undefined;
        LoggerInstance.debug(
          'Authorize',
          payMean,
          payloadPaymeanType,
          lastPayment,
          authorizationOperation
        );
        if (payMean && authorizationOperation === 'verify') payMeanId = lastPayment?.id;
        if (siaFlowType === ISIAFlowType.RECURRENT) {
          ordDescr = formattedValue.concat(`,${recurrence},${offerId},`);
        } else {
          const activationCost = getOneTimeAmountFromShoppingCart();
          const shippingCost = getCostFromShoppingCartByType(CART_TYPE.SHIPPING);
          let totalPrice = activationCost.price || 0;
          if (shippingCost.price) totalPrice += shippingCost.price;
          // totalPrice Es 6.99
          if (totalPrice) {
            ordDescr = formattedValue.concat(`,${recurrence},${offerId},${totalPrice}&euro;`);
          } else {
            ordDescr = formattedValue.concat(`,${recurrence},${offerId}`);
          }
        }
      }
      // endregion

      LoggerInstance.debug('NEW PAYMENT CARD ordDescr : ', ordDescr);

      const payload: IAuthorizePayload = {
        payMeanType,
        payMeanPurpose,
        authorizationOperation,
        flowType,
        orddescr: ordDescr,
        payMeanId,
      };

      LoggerInstance.debug('NEW PAYMENT CARD PAYLOAD : ', payload);
      return authorizeService(payload, cartId);
    },
    {
      onSuccess: (data: IAuthorizeResponse) => {
        localStorage.setItem('authorize', JSON.stringify(data));
        LoggerInstance.debug('authorize SUCCESS', data);
        queryClient.setQueryData('authorize', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - authorize: `, error);
        queryClient.setQueryData('authorize', errorMock(`authorize`, error));
        localStorage.setItem(`authorize`, JSON.stringify(errorMock(`authorize`, error)));
      },
    }
  );
};

/**
 * Authorize Query saves the complete authorize response
 */
export const useGetAuthorize = () => useQuery<IAuthorizeResponse>('authorize');
