import { useEffect } from 'react';
import {
  CMS_CONFIG,
  useCmsConfig,
  IPortabilityMobileCMS,
  productSpecificError,
  useGetPortabilityInfoMobile,
  errorMock,
  IGenericErrorCMS,
} from '@vfit/consumer/data-access';
import { API, openPopup, PAGES, setTrackLink, regex, defineYupSchema } from '@vfit/shared/data-access';
import { useTracking } from '@vfit/shared/data-access';
import { ShadowsGroup } from '@vfit/shared/components';
import {
  organizePortabilityError,
  organizePortabilityNumberCard,
} from './portabilityNumberCard.utils';
import PortabilityNumber from './components/PortabilityNumber/portabilityNumber';
import { ICheckoutData } from '../../checkout.models';
import { ID_FLOWS } from '../../checkout.constants';
import { getUserInfo, getTagging } from '../../checkout.utils';
import { Skeleton } from './portabilityNumberCard.style';
import { DEFAULT_PORTABILITY } from '../../../iBuyMobile.context.data';
import { useCheckout } from '../../../iBuyMobile.context';

const PortabilityNumberCard = ({
  handleOnChangeEnableGoNext,
  handleGoPrevSlide,
}: ICheckoutData) => {
  const {
    checkoutErrors,
    portability,
    isModalButtonSticky,
    product,
    setPortability,
    setCurrentStepKey,
    setIsValidNumberPortability,
  } = useCheckout();
  const isRequiredPortability = product?.isRequiredPortability;
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const cmsApiKey = API.CMS_GET_PORTABILITY_MOBILE;
  const portabilityNumberCardCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    cmsApiKey
  ) as IPortabilityMobileCMS;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { title, description, label, disclaimer } = organizePortabilityNumberCard(
    portabilityNumberCardCMS,
    'portabilitymobile'
  );
  const { titleError, messageError } = organizePortabilityError(portabilityNumberCardCMS);

  const {
    isLoading: isLoadingPortInfo,
    isError: isErrorPortInfo,
    error: errorPortIn,
  } = useGetPortabilityInfoMobile(
    portabilityNumberCardCMS.portabilitymobile.operatorList,
    portabilityNumberCardCMS.portabilitymobile.operatorsOrder,
    product?.operatorListFilter
  );
  const taggingMobile = getTagging('portability');

  const { trackView } = useTracking({
    event: ['checkout_step4.1'],
    event_label: 'number portability',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'number portability');

  const checkPortabilityValidation = () => {
    if (portability?.portabilityNumber) {
      const isMatches = portability.portabilityNumber.match(regex['mobilePhoneNumber']);
      handleOnChangeEnableGoNext?.(!!isMatches);
    }
  };

  useEffect(() => {
    if (isErrorPortInfo) {
      const action = () => {
        if (handleGoPrevSlide) handleGoPrevSlide();
      };
      checkoutErrors?.showAndTrackError?.(
        genericError,
        action,
        errorMock(
          'portininfo',
          errorPortIn,
          undefined,
          undefined,
          'portin info in portabilityCard',
          false,
          {
            portabilityNumber: portability?.portabilityNumber || '',
            portabilityOperators: portability?.portabilityOperators || '',
          }
        ),
        titleError,
        messageError,
        action,
        'Torna indietro',
        () => openPopup(urlGenericErrorCallMeNow, action),
        product?.removeCTC ? undefined : buttonGenericError,
        isModalButtonSticky,
        true
      );
    }
  }, [isErrorPortInfo]);

  useEffect(() => {
    checkPortabilityValidation();
  }, [portability]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.PORTABILITY_NUMBER);
    checkPortabilityValidation();
    if (isRequiredPortability) {
      localStorage.setItem('portability', '1');
    }
  }, []);

  const onChangeData = (telephoneNumber: string) => {
    setIsValidNumberPortability(false);
    setPortability({
      ...DEFAULT_PORTABILITY,
      portabilityWant: isRequiredPortability ? true : portability.portabilityWant,
      portabilityNumber: telephoneNumber || '',
    });
  };

  if (isLoadingPortInfo) {
    return (
      <Skeleton>
        <ShadowsGroup heights={70} quantity={3} />
      </Skeleton>
    );
  }

  return (
    <PortabilityNumber
      title={title}
      description={description}
      label={label}
      disclaimer={disclaimer}
      value={portability.portabilityNumber}
      onChangeData={onChangeData}
      disabled={false}
      schema={defineYupSchema('phoneNumber')}
    />
  );
};

export default PortabilityNumberCard;

