import { useEffect, useState } from 'react';
import { recognizedUser, updateContactProblemDataLocal } from '@vfit/consumer/data-access';
import { resetData } from '@vfit/shared/data-access';
import { trackLink } from '@vfit/shared/data-access';
import { ICommonData, IPersonalDataObject } from '@vfit/shared/models';
import { useQueryClient } from 'react-query';
import { IContactInfo } from '../../checkout.models';
import { updateContactDataLocal } from './contactLockInCard.utils';
import { useCheckout } from '../../../iBuyFixed.context';
import { ID_FLOWS } from '../../checkout.constants';
import Contact from './components/Contact/contact';
import { useContactLockInCard } from './contactLockInCard.hook';
import ContactLockInTagging from './contactLockInCard.tagging';
import LoadingCard from '../LoadingCard/loadingCard';

const ContactLockInCard = (props: ICommonData) => {
  const { handleOnChangeEnableGoNext } = props;
  const queryClient = useQueryClient();
  const {
    owningData,
    contactInfo,
    setOwningData,
    setContactInfo,
    setCurrentStepKey,
    setIsDisabledBackButton,
  } = useCheckout();
  const { isLoading } = useContactLockInCard(props);
  const [isValidForm, setIsValidForm] = useState(false);

  useEffect(() => {
    const backButton = document.getElementById('back-button');
    if (handleOnChangeEnableGoNext) {
      // setIsDisabledBackButton(false);
      if (recognizedUser()) {
        setTimeout(() => {
          handleOnChangeEnableGoNext(!isLoading && (isValidForm || (recognizedUser() as boolean)));
        }, 800);
      } else {
        setTimeout(() => {
          handleOnChangeEnableGoNext(!isLoading && isValidForm);
        }, 800);
      }
    }
    const reset = () => {
      resetData(queryClient, ['associateCustomer']);
    };
    backButton?.addEventListener('click', reset);
    return () => {
      backButton?.removeEventListener('click', reset);
    };
  }, [isValidForm, isLoading]);

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.CONTACT_CARD_LOCKIN);
    updateContactProblemDataLocal(contactInfo);
    setIsDisabledBackButton(true);
    return () => {
      setIsDisabledBackButton(false);
    };
  }, []);

  const onChangeData = (data: IPersonalDataObject) => {
    updateContactDataLocal(data);
    setOwningData(data);
  };

  const onChangeCheck = (data: IContactInfo) => {
    let trackData = 'off';

    if (data.checkContactProblem) {
      trackData = 'on';
    }

    trackLink(`re-contact checkbox ${trackData}`);
    updateContactProblemDataLocal(data);
    setContactInfo(data);
  };

  if (isLoading) {
    return <LoadingCard />;
  }

  return (
    <>
      {!isLoading && <ContactLockInTagging />}
      <Contact
        data={owningData}
        checkInfo={contactInfo}
        onChangeData={onChangeData}
        onChangeCheck={onChangeCheck}
        isReadOnly
        setIsValidForm={(valid: boolean) => setIsValidForm(valid)}
      />
    </>
  );
};

export default ContactLockInCard;
