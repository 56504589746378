import { useMutation, useQueryClient } from 'react-query';
import { errorMock } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { LoggerInstance, nextClient, INextError } from '@vfit/shared/data-access';
import { IAvailablePaymentMethods } from '@vfit/shared/models';
import { IPayLoadPaymentPut } from './paymnetMethod.model';

export interface IPaymentMethodMutation {
  selectedPaymentMethod: IAvailablePaymentMethods;
  billingOfferIdForChangePaymentMethod: Array<string>;
  orderActionId: string;
}

/**
 * This method calls the paymentService to update payment method for a specific cartId
 * Method: PUT
 * searchParams: {
 * salesChannel: 'selfService'}
 * @param cartId
 * @returns
 */
const payMentMethodService = (cartId: string, payLoad: IPayLoadPaymentPut) =>
  nextClient.put(`${API.ORDER}/${cartId}/${API.PAYMENT_METHOD}`, payLoad, {
    searchParams: {
      salesChannel: 'selfService',
    },
  }) as Promise<{}>;

/**
 * Custom mutation hook for run billDeliveryDetailsService using the createdAddress/billingCreatedAddress, the customerData and the billingAccount.
 * Call this after the customer, shoppingCart and delivery have just been created.
 * @returns
 */
export const usePaymentMethod = () => {
  const queryClient = useQueryClient();
  const keyString = 'paymentMethod';

  return useMutation(
    ['paymentMethodPut'],
    (paramsPaymentMethodPutMutation: IPaymentMethodMutation) => {
      const shoppingCart = getFromLocalStorageByKey('shoppingCart');

      LoggerInstance.log('payment method put shoppingCart : ', shoppingCart);

      const cartId = shoppingCart?.id;

      const payLoad = {
        orderPayment: {
          orderItemPayments: [
            {
              orderItemOnBillPayment: {
                selectedPaymentMethod: paramsPaymentMethodPutMutation.selectedPaymentMethod,
                billingOfferIdForChangePaymentMethod:
                  paramsPaymentMethodPutMutation.billingOfferIdForChangePaymentMethod,
              },
              orderActionId: paramsPaymentMethodPutMutation.orderActionId,
            },
          ],
        },
        skipValidation: false,
        performSave: true,
      };

      return payMentMethodService(cartId, payLoad);
    },
    {
      onSuccess: (data: {}) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR payment method put - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
