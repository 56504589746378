import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  padding-bottom: 36px;
`;

export const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  p {
    margin: 0;
  }
  img {
    object-fit: contain !important;
  }
  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    width: inherit;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    width: inherit;
  }
`;

export const ColorContainer = styled.div`
  padding-top: 14px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Color = styled.div<{ bColor: string }>`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${({ bColor }) => bColor};
`;
