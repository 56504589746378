import { PageProduct, TrackingOptions, useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { setTrackLink } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { IDevice, ISku } from '@vfit/consumer/data-access';

interface ITaggingBlank {
  device?: IDevice;
  taggingOptions?: TrackingOptions;
  taggingProduct?: PageProduct;
  selectedSku?: ISku;
}

const TaggingDeviceDetails = ({
  device,
  taggingOptions,
  taggingProduct,
  selectedSku,
}: ITaggingBlank) => {
  const client = useQueryClient();
  const { trackWithNewDatalayer } = useTracking({
    event: ['product_view'],
    disableInitialTrack: true,
  });
  setTrackLink(trackWithNewDatalayer);

  useEffect(() => {
    selectedSku &&
      trackWithNewDatalayer({
        event: ['product_view'],
        event_label: SoftManagerService(client).getPromoLabel(device?.devicedetail?.slug || ''),
        opts: taggingOptions,
        pageProduct: taggingProduct,
        cartProduct: undefined,
        visitorTrackingOpts: SoftManagerService(client).getUser(),
      });
  }, [selectedSku]);

  return null;
};

export default TaggingDeviceDetails;
