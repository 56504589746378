import { LinkWithIcon, PaymentCard } from '@vfit/shared/atoms';
import { SELECTED_PAYMENT } from '@vfit/consumer/data-access';
import { AmericanExpressLight, MastercardLight, VisaLight } from '@vfit/shared-icons';
import { useEffect, useState } from 'react';
import { purify } from '@vfit/shared/themes';
import { EMethodCode } from '@vfit/shared/models';
import { IPaymentMethods, IPaymentMethodsProps } from './paymentMethod.models';
import { LinkWrapper, Subtitle, TextLoader, Title, Wrapper } from './paymentMethod.style';

const PaymentMethod = ({
  owningIndividual,
  handleChangePayment,
  config,
  payMean: paymentData,
  showLoader,
}: IPaymentMethodsProps) => {
  const { title, subtitle, buttonLabel, yourIbanTitle, yourCreditCardTitle, expirationLabelTitle } =
    config;
  const [pSubtitle] = purify([subtitle || '']);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentMethods | null>(null);

  useEffect(() => {
    if (paymentData) {
      const isCreditCard =
        paymentData?.type?.toLowerCase() === EMethodCode?.CREDIT_CARD.toLowerCase();
      localStorage.setItem(
        SELECTED_PAYMENT,
        isCreditCard ? EMethodCode.CREDIT_CARD : EMethodCode.BANK_ACCOUNT
      );
      const expirationMonth =
        paymentData?.expiryMonth?.length === 1
          ? `0${paymentData?.expiryMonth}`
          : paymentData.expiryMonth;
      setPaymentInfo({
        cardNumber: `****${
          isCreditCard ? paymentData?.lastFourDigits || '' : (paymentData as any)?.ibanNumber || ''
        }`,
        circuit: isCreditCard ? paymentData?.displayBrand || '' : '',
        cardOwner: `${owningIndividual?.firstName || ''} ${owningIndividual?.lastName || ''}`,
        title: isCreditCard
          ? yourCreditCardTitle || 'La tua carta'
          : yourIbanTitle || 'Il tuo iban',
        expirationLabel: isCreditCard
          ? expirationLabelTitle?.replace(
              '$expirationDate',
              `${expirationMonth}/${paymentData?.expiryYear}`
            ) || `Valida fino al ${expirationMonth}/${paymentData?.expiryYear}`
          : '',
      });
    }
  }, [paymentData]);

  const getIconCard = (): JSX.Element => {
    if (!paymentData) return <div />;
    const { brand } = paymentData;
    if (!brand) return <div />;
    switch (brand.trim().toLowerCase()) {
      case 'mastercard':
        return <MastercardLight />;
      case 'visa':
        return <VisaLight />;
      case 'americanexpress': {
        return <AmericanExpressLight />;
      }
      default:
        return <div />;
    }
  };

  return (
    <Wrapper>
      <>
        <Title>{title}</Title>
        <Subtitle>
          <div
            dangerouslySetInnerHTML={{
              __html: pSubtitle || '',
            }}
          />
        </Subtitle>
        {showLoader && <TextLoader />}
        {!showLoader &&
          (paymentInfo?.cardNumber as string) &&
          (paymentInfo?.cardOwner as string) && (
            <>
              <PaymentCard {...paymentInfo} icon={getIconCard()} />
              {buttonLabel && handleChangePayment && (
                <LinkWrapper>
                  <LinkWithIcon onClick={handleChangePayment} text={buttonLabel} />
                </LinkWrapper>
              )}
            </>
          )}
      </>
    </Wrapper>
  );
};

export default PaymentMethod;
