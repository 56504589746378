import {
  IDXLCampaigns,
  IAppSession,
  IDXLNboProduct,
  START_FROM_APP_LOCKIN,
  DXL_NBO_CAMPAIGNS,
} from '@vfit/consumer/data-access';
import {
  dxlClientJourneyApp,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
  LoggerInstance,
  INextError,
  awsConsumerClient,
} from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { UseQueryResult } from 'react-query';

/**
 * This method calls che campaigns service
 * 'Recommendation/v2/queryProductRecommendation' from DXL Client
 * @param searchParams
 * @param customHeaders
 * @param isAwsClient
 */
const getActivableProduct = (
  searchParams?: { [key: string]: string },
  customHeaders?: { [key: string]: string },
  isAwsClient?: boolean
) => {
  if (isAwsClient) {
    return awsConsumerClient.get(`api/${API.ACTIVABLE_PRODUCTS}`, {
      ...(customHeaders && { headers: customHeaders }),
      ...(searchParams && { searchParams }),
    }) as Promise<IDXLCampaigns>;
  }
  return dxlClientJourneyApp.get(API.ACTIVABLE_PRODUCTS, {
    ...(customHeaders && { headers: customHeaders }),
    ...(searchParams && { searchParams }),
  }) as Promise<IDXLCampaigns>;
};

export const setDxlCampaigns = (data: IDXLCampaigns): IDXLNboProduct[] => {
  const dxlCampaignsProducts = data?.products || [];
  const dxlNboCampaignsFixed = dxlCampaignsProducts?.filter((campaign: IDXLNboProduct) =>
    campaign?.type?.includes('fixed')
  );
  if (dxlNboCampaignsFixed && dxlNboCampaignsFixed?.length > 0) {
    localStorage.setItem(START_FROM_APP_LOCKIN, 'true');
    localStorage.setItem(DXL_NBO_CAMPAIGNS, JSON.stringify(dxlNboCampaignsFixed));
    return dxlNboCampaignsFixed;
  }
  return [];
};

/**
 * This method recalls the customer ID and use it to returns all the customer information saved
 *
 */
export const useDXLCampaigns = (customOptions?: CustomOptions) => {
  LoggerInstance.debug('********* useDXLCampaigns');
  const queryEnabler = false;
  const keysDependency =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : ['dxlCampaigns'];

  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => {
      const appSession: IAppSession = getFromLocalStorageByKey('appSession');
      const appUserPhoneNumber = appSession?.value || '';
      const appVersion = appSession?.appVersion?.split('.') || '0.0.0';
      const appVersionMajor = appVersion?.[0] || '0';
      const appVersionMinor = appVersion?.[1] || '0';
      const isNewAppVersion =
        appVersionMajor && appVersionMinor && +appVersionMajor > 12 && +appVersionMinor > 2;
      const customerUserAgent = appSession?.customerUserAgent || '';
      const installationId = appSession?.installationId || '';
      const token = appSession?.token || '';
      const customHeaders = isNewAppVersion
        ? {
            'X-Device-UserAgent': customerUserAgent,
            'X-Device-Id': installationId,
            'vf-a-appconsumerauth': token,
          }
        : {
            'X-Device-UserAgent': customerUserAgent,
            'X-Device-Id': installationId,
            clientId: '4002',
          };

      return getActivableProduct(
        {
          msisdn: appUserPhoneNumber,
          productType: 'NBA',
        },
        customHeaders,
        isNewAppVersion
      );
    },
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: queryEnabler,
      onSuccess: (data: IDXLCampaigns) => {
        setDxlCampaigns(data);
        LoggerInstance.debug('useDXLCampaigns onSuccess : ', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.debug('UseDxlCampaigns', error);
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IDXLCampaigns, INextError>;
};
