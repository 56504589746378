import {
  IAddressDataObject,
  ICmsConfigAddressManual,
  IEditAddressConfig,
  IInvoiceObject,
  IPickUpLocation,
} from '@vfit/shared/models';
import { IInputs } from '../CoverageTool/coverageTool.models';

export enum IMode {
  Change = 'change',
  Modify = 'modify',
}
export interface IEditAddressProps {
  firstName: string;
  lastName: string;
  handleClose: () => void;
  origin: boolean;
  mode?: IMode;
  modeAction?: (inputs: IInputs) => void;
  state?: IInputs;
  lables?: { title: string; subtitle: string };
  shippingAddress: IAddressDataObject;
  billingAddress?: IAddressDataObject;
  setBillingAddress?: (shippingAddress: IAddressDataObject) => void;
  setShippingAddress: (billingAddress: IAddressDataObject) => void;
  setInvoice: (invoice: IInvoiceObject) => void;
  setPickupPoint: (ppoint: IPickUpLocation) => void;
  cmsConfig: IEditAddressConfig;
  manualAddressConfig?: ICmsConfigAddressManual;
}

export interface IDataEditAddress {
  title: string;
  billingSubtitle: string;
  shippingSubtitle: string;
  lable: string;
  paragraph_manual: string;
  labels: {
    addressInput: string;
    city: string;
    postalCode: string;
    stateOrProvince: string;
    streetNumber: string;
    street: string;
  };
  placeHolders: {
    addressInput: string;
    city: string;
    postalCode: string;
    stateOrProvince: string;
    streetNumber: string;
    street: string;
  };
  submitButton: string;
  submitButton_manual: string;
  genericError: string;
  apiError: string;
}
