import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const DetailsContainer = styled.div`
  margin: 0 auto;
  margin-top: 0;
`;

export const Title = styled.div`
  div {
    margin: 0;
    font-family: ${fonts.exbold};
    font-weight: 400;
    text-align: left;

    @media (min-width: ${breakpoints.mobile}) {
      ${pxToCssFont(30, 38)}
      margin-bottom: 32px;
    }

    @media (min-width: ${breakpoints.tablet}) {
      margin-bottom: 24px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      ${pxToCssFont(36, 45)}
      margin-bottom: 32px;
    }
  }
`;

export const P = styled.div`
  div {
    margin: 0;
    font-weight: 700;
    font-family: ${fonts.regular};
    text-align: left;

    @media (min-width: ${breakpoints.mobile}) {
      ${pxToCssFont(20, 26)}
    }

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(20, 30)}
    }
  }
`;

export const Img = styled.div`
  img {
    width: 100% !important;
    height: 150px !important;
    margin: 16px 0 !important;
    border-radius: 15px !important;
    object-fit: contain !important;
  }

  @media (min-width: ${breakpoints.mobile}) {
    img {
      margin: 16px 0 !important;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    img {
      margin: 32px 0 !important;
    }
  }
`;

export const Description = styled.div`
  div {
    margin: 0;

    img {
      display: none;
    }

    ul {
      margin: 0;
      padding-left: 24px;
    }

    li {
      margin: 0;
    }

    p {
      margin: 0;
    }

    font-weight: 400;
    font-family: ${fonts.regular};
    text-align: left;
    ${pxToCssFont(16, 22)}
  }

  @media (min-width: ${breakpoints.mobile}) {
    div {
      ${pxToCssFont(18, 22)}
    }
  }
`;

export const Content = styled.div`
  padding: 0;
  max-height: 85vh;

  @media (min-width: ${breakpoints.tablet}) {
    max-height: 50vh;
    margin-left: 0;
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const AccordionWrap = styled.div`
  margin: 24px 0;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 47px 0;
  }
`;

