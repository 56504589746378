import {
  getProductsSlider,
  ICarouselCMS,
  IDevice,
  IPanicMode,
  IProduct,
  IWidgetType,
  IGlobalPromo,
} from '@vfit/consumer/data-access';
import { ISliderCarousel, ISplittedImageTextCard } from '@vfit/shared/models';
import { IFaqWidget } from '../Faq/faq.models';

const getProductSlidesConsumer = (
  widget: ICarouselCMS,
  customHubs?: { [key: string]: IProduct[] | IDevice[] },
  panicModeConfiguration?: IPanicMode,
  globalPromo?: IGlobalPromo
): ISliderCarousel => {
  const { slides, text } = getProductsSlider(
    widget,
    widget?.title || '',
    customHubs,
    panicModeConfiguration,
    globalPromo
  );
  return {
    slides: slides?.filter((s) => s.product !== null),
    topText: text,
  };
};

/**
 * Method to return the faq widget
 * @param widget
 */
const getFaqWidget = (widget: ICarouselCMS): IFaqWidget => ({
  topText: widget?.title || '',
  faq: {
    divider: widget?.divider || undefined,
    elements: widget?.elements || [],
    showMore: widget?.customAction || undefined,
  },
});

/**
 * Add in documentation only for splitted images text widget
 * @param widget
 */
const getSplittedImageTextWidget = (widget: ICarouselCMS): ISplittedImageTextCard => ({
  topText: widget?.title || '',
  title: widget?.elements?.[0]?.title || '',
  description: widget?.elements?.[0]?.description || '',
  urlImg: widget?.elements?.[0]?.image || '',
  urlImgMobile: widget?.elements?.[0]?.imageMobile || '',
  info: widget?.description || '',
  action: widget?.customAction,
  disableCoverImg: widget?.isShort === 'true',
  divider: widget?.divider,
});

/**
 * Method to organize all widgets
 * Include filters for all widgets tabs
 * @param widgets
 */
const organizeWidgets = (widgets: ICarouselCMS[]): ICarouselCMS[] => {
  const haveTabsWidgets = widgets.some((widget) => widget.type === IWidgetType.TABS_WIDGETS);
  if (!haveTabsWidgets) {
    return widgets;
  }
  // TODO: Remove button?localUrl after CMS release
  const widgetsWithoutRef = widgets?.filter((widget) => !widget.ref && !widget?.button?.localUrl);
  const widgetsWithRef = widgets?.filter((widget) => !!widget.ref || !!widget?.button?.localUrl);
  return widgetsWithoutRef.map((widget) => {
    if (widget.type !== IWidgetType.TABS_WIDGETS) return widget;
    return {
      ...widget,
      elements: widget.elements.map((element) => ({
        ...element,
        widgets: widgetsWithRef.filter(
          (wWR) =>
            wWR.ref == element?.id?.toString() || wWR.button?.localUrl == element?.id?.toString()
        ),
      })),
    };
  });
};

export { getProductSlidesConsumer, getFaqWidget, getSplittedImageTextWidget, organizeWidgets };
