import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Wrapper = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    max-height: inherit;
    margin: 0 auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: 0;

    ::-webkit-scrollbar {
      width: 0; /* for Chrome, Safari, and Opera */
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-height: inherit;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Text = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  margin-bottom: 24px;
  font-weight: 400;
  color: ${colors.$262626};
  ${pxToCssFont(18, 24)}

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .maskInputFieldset {
    margin-top: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    gap: 20px;
  }

  .textInput {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    column-gap: 20.36px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    column-gap: 32px;
  }

  .nation {
    label {
      top: ${pxToRem(-13)};
      ${pxToCssFont(14, 18)}
      z-index: 2;
    }
  }
`;

export const BirthPlaceContainer = styled.div`
  @media (min-width: ${breakpoints.tablet}) {
    width: 230.64px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 296px;
  }
`;

export const Footer = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(14, 18)}
  margin-top: 16px;
  margin-bottom: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 20px;
    margin-bottom: 45px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;

  @media (max-width: ${breakpoints.tablet}) {
    position: relative;
    background: ${colors.$ffffff};
  }

  .buttonSlide {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: 255px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 295px;
    }
  }

  button:disabled {
    background: white;
    border-color: ${colors.$bebebe};

    span {
      color: ${colors.$bebebe};
    }
  }
`;

