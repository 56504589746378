import styled from 'styled-components';
import { breakpoints, colors, fonts } from '@vfit/shared/themes';

export const MainContainer = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.div<{ enablePaddingTop: boolean }>`
  margin-top: ${({ enablePaddingTop }) => (enablePaddingTop ? 0 : '48px')};
`;

export const ProductListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  .swiper {
    margin-left: inherit;
  }

  .swiper-wrapper {
    height: inherit;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 45px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-bottom: 120px;
  }
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
  }
`;

export const  Title = styled.div<{ haveHeaderLight: boolean }>`
div {
    margin: 0;
    padding: 30px !important;
    padding-left: ${props => props.haveHeaderLight ? '60px !important' : ''};

    p {
      margin-block: 0;
    }

    @media (min-width: ${breakpoints.tablet}) {
      padding: ${props => props.haveHeaderLight ? '45px 30px 48px 60px !importantt' : '45px 30px 48px !important'};
    }

    @media (min-width: ${breakpoints.desktop}) {
      padding: 48px 48px 32px 48px !important;
    }

  }
`;

