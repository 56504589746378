import { ButtonSlide } from '@vfit/shared/atoms';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useTracking } from '@vfit/shared/data-access';
import { API, PAGES, purifyTextWithLogo, setTrackLink, trackLink } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  useCmsConfig,
  ICoverageToolCongifg,
  IPersonalInfoCMS,
  retrieveProductTechnology,
  goToHubProduct,
  IGenericErrorCMS,
  checkIsApp,
  handleFWA,
  handleKo,
  handleR2Logged,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { removeCurrency } from '@vfit/consumer/hooks';
import { purify } from '@vfit/shared/themes';
import { CoverageErrorType } from '@vfit/shared/models';
import { ICoverageErrorProps } from './coverageToolError.models';
import {
  ButtonSlideContainer,
  Container,
  Title,
  Description,
  TextContainer,
} from './coverageToolError.style';
import { organizeRedirect } from './coverageToolError.utils';

const CoverageToolError = ({
  onEdit,
  onClose,
  onRedirectClick,
  formattedAddress,
  coverageErrorType,
  product,
  isButtonSticky,
  isAlternativeFWA,
}: ICoverageErrorProps) => {
  // Retrive logged user if available.
  const client = useQueryClient();

  // Product for tracking.
  const pageProduct = {
    product_name: product?.slug,
    product_price: product?.price && removeCurrency(product.price),
    product_id: `${product?.offerId}`,
    product_quantity: '1',
    product_category: ['fixed']
  };

  const opts = {
    event_category: 'tools',
    page_subsection: 'coverage tool',
    page_section: 'fixed offers',
    page_type: 'coverage ko',
    journey_name: 'coverage tool',
    product_technology: retrieveProductTechnology(),
  };

  const { trackView } = useTracking({
    event: ['tool_end'],
    event_label: coverageErrorType !== CoverageErrorType.FWA ? 'no coverage' : 'fwa coverage',
    opts,
    pageProduct,
    cartProduct: { cart_product_name: product?.slug || '' },
    visitorTrackingOpts: SoftManagerService(client).getUser(),
  });
  setTrackLink(trackView);

  const coverageToolConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_COVERAGE_TOOL_CONFIG
  ) as ICoverageToolCongifg;
  const commonConfig = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const {
    titleFwa,
    descriptionFwa,
    buttonListFwa,
    linkRedirectFwa,
    titleR2,
    descriptionR2,
    buttonListR2,
  } = organizeRedirect({ product, commonConfig, isAlternativeFWA });

  const getCoverageToolMessage = () => {
    switch (coverageErrorType) {
      case CoverageErrorType.KO:
        return {
          title: coverageToolConfig?.coveragetool?.koCoverageTool?.title || '',
          description:
            coverageToolConfig?.coveragetool?.koCoverageTool?.description.replace(
              'PLACEHOLDER',
              `${formattedAddress}` || ''
            ) || '',
          buttonList: [
            {
              text: coverageToolConfig?.coveragetool?.koCoverageTool?.buttonEditAddress || '',
            },
            {
              text: coverageToolConfig?.coveragetool?.koCoverageTool?.buttonCallsYou || '',
            },
          ],
        };
      case CoverageErrorType.FWA:
        return {
          title: titleFwa,
          description: descriptionFwa,
          buttonList: buttonListFwa,
        };
      case CoverageErrorType.ERROR:
        return {
          title: genericError?.genericerror?.title || 'Ops!',
          description:
            genericError?.genericerror?.description ||
            'Si è verificato un errore. Riprovare in seguito',
          buttonList: [
            {
              text: '',
            },
            {
              text: genericError.genericerror.buttonLabel || 'Chiudi',
            },
          ],
        };
      case CoverageErrorType.R2_LOGGED:
        return {
          title: titleR2,
          description: descriptionR2,
          buttonList: buttonListR2,
        };
      default:
        return {
          title: coverageToolConfig?.coveragetool?.koCoverageTool?.title || '',
          description:
            coverageToolConfig?.coveragetool?.koCoverageTool?.description.replace(
              'PLACEHOLDER',
              `${formattedAddress}` || ''
            ) || '',
          buttonList: [
            {
              text: coverageToolConfig?.coveragetool?.koCoverageTool?.buttonEditAddress || '',
            },
            {
              text: coverageToolConfig?.coveragetool?.koCoverageTool?.buttonCallsYou || '',
            },
          ],
        };
    }
  };

  const handleClickEditCoverageToolError = () => {
    if (onEdit) onEdit();
  };

  const handleClickActionCoverageToolError = () => {
    switch (coverageErrorType) {
      case CoverageErrorType.KO:
        handleKo(product?.urlClickToCallNoCoverage || product?.callMeNowHelp?.url || '');
        break;
      case CoverageErrorType.FWA:
        trackLink(product?.redirectFWA?.title || '');
        handleFWA(linkRedirectFwa);
        break;
      case CoverageErrorType.R2_LOGGED:
        handleR2Logged(product?.redirectR02 || product?.callMeNowHelp?.url || '');
        break;
      case CoverageErrorType.ERROR:
        goToHubProduct();
        break;
      default:
        if (onClose) onClose();
        break;
    }
  };

  const handleRedirectCoverageToolError = () => {
    trackLink(product?.redirectFWA?.alternativeOfferRedirect?.labelRedirect || '');
    if (onRedirectClick) onRedirectClick();
  };

  const { title, description, buttonList } = getCoverageToolMessage();
  const [pTitle, pDescription] = purify([title, description], purifyTextWithLogo);

  return (
    <Container>
      <TextContainer isSticky={isButtonSticky}>
        {pTitle && <Title dangerouslySetInnerHTML={{ __html: pTitle }} />}
        {pDescription && <Description dangerouslySetInnerHTML={{ __html: pDescription }} />}
      </TextContainer>
      <ButtonSlideContainer isSticky={isButtonSticky}>
        {buttonList?.[0]?.text && (
          <ButtonSlide
            label={buttonList[0].text}
            onClick={
              isAlternativeFWA
                ? handleClickActionCoverageToolError
                : handleClickEditCoverageToolError
            }
            borderColor="#0d0d0d"
            isApp={checkIsApp()}
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            name="action_ko"
          />
        )}
        {buttonList?.[1]?.text && (
          <ButtonSlide
            label={buttonList[1].text}
            onClick={
              isAlternativeFWA
                ? handleRedirectCoverageToolError
                : handleClickActionCoverageToolError
            }
            isApp={checkIsApp()}
            hoverColor="#bd0000"
            clickColor="#a10000"
            buttonColor="#e60000"
            labelColor="#fff"
            name="action_ko_second"
          />
        )}
      </ButtonSlideContainer>
    </Container>
  );
};
export default CoverageToolError;

