import { CART_TYPE, getOneTimeAmountForUpfront, IPayments } from '@vfit/consumer/data-access';
import { IPayMean } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import { IEscapeRegExp, IReplacePlaceHolderWithData } from './upfrontPaymentCard.models';

export const DEFAULT_CREDIT_CARD: IPayMean = {
  displayBrand: '*********',
  object_type: '*****',
  id: '***********',
  type: '*****',
  accountNumber: '*******',
  brand: '********',
  lastFourDigits: '****',
  holderName: '*********',
  expiryMonth: '**',
  expiryYear: '****',
};

export const organizePaymentMethod = (dataFromCms: IPayments) => ({
  title: dataFromCms?.paymentsmobile?.title || '',
  subtitle: dataFromCms?.paymentsmobile?.userRegistered?.description || '',
  buttonLabel: dataFromCms?.paymentsmobile?.userRegistered?.changeLabel || '',
});

export const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message ||
    'Siamo spiacenti ma si è verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || 'Ops!',
  titleErrorErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.title || '',
  messageErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.description || '',
  titleErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.title || '',
  messageErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.description || '',
});

export const organizeUpfrontSelectionCard = (dataFromCms: IPayments) => {
  const oneTimeAmount = getOneTimeAmountForUpfront(true);
  const escapeRegExp: IEscapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  const replacePlaceHolderWithData: IReplacePlaceHolderWithData = (str, placeHolder, data) =>
    purify([str.replace(new RegExp(escapeRegExp(placeHolder), 'g'), `${data || ''}`) || ''])?.[0] ||
    ''; // Escaped RegEx from $ needed for multiple placeholder occurrences in the template

  return {
    titleUpFront: dataFromCms?.paymentsmobile?.upFrontPayment?.title || 'Contributo iniziale',
    descriptionCC: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCC,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    descriptionCreditRemaning: replacePlaceHolderWithData(
      dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCreditRemaining,
      CART_TYPE.PLACEHOLDER_ONETIME,
      oneTimeAmount
    ),
    paymentMethod: [
      {
        title:
          dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[0]?.title ||
          'Carta di credito',
        description:
          dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[0]?.description || '',
        value: 'creditCard',
      },
      {
        title: dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[1]?.title || 'Paypal',
        description:
          dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[1]?.description || '',
        value: 'creditCardAndPaypal',
      },
    ],
    bottomModalConfig: {
      textLink: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.textLink || '',
      titleModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.title || '',
      descriptionModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.description || '',
    },
  };
};
