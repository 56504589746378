import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  padding: 32px 16px 60px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 60px 40px 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 60px 55px 64px;
  }
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled(Paragraph)`
  margin: 0 auto;
  text-align: center;
  max-width: 287px;
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  margin-top: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
    max-width: 366px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 406px;
  }
`;

export const InstallationAddress = styled(Paragraph)`
  margin-top: 32px;
  margin-bottom: 8px;
  text-align: start;
  font-family: ${fonts.exbold};
  ${pxToCssFont(20, 26)}

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 16px;
    margin-top: 33px;
    ${pxToCssFont(24, 30)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 40px;
  }
`;

export const UserInfo = styled.div`
  .username {
    text-transform: capitalize;
  }

  p {
    margin: 0;
    font-weight: 400;
    color: ${colors.$262626};
    text-align: start;
    font-family: ${fonts.regular};
    ${pxToCssFont(18, 24)}

    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(20, 30)}
    }
  }

  p:nth-child(2) {
    font-weight: 700;
    color: ${colors.$00697c};
  }

  p:nth-child(5) {
    margin-top: ${pxToRem(8)};

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: ${pxToRem(16)};
    }
  }
`;

export const DeliveryCta = styled.button`
  display: flex;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: none;
  border-bottom: 1px solid ${colors.$262626};
  margin-top: 8px;
  color: ${colors.$262626};
  font-weight: 700;
  font-family: ${fonts.regular};
  ${pxToCssFont(16, 22)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 22)}
    margin-top: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(18, 26)}
  }

  &:hover {
    cursor: pointer;
    color: ${colors.$e60000};
    border-bottom: 1px solid ${colors.$e60000};
  }
`;

export const Divider = styled.div`
  margin-top: 24px;
  border-bottom: 1px solid ${colors.$bebebe};
`;

export const BottomTitle = styled(InstallationAddress)`
  max-width: 287px;
  margin-top: 20px;
  margin-bottom: 16px;
  color: #333;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 366px;
    margin-top: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: 406px;
    margin-top: 25px;
  }
`;

export const LabelCta = styled(Paragraph)`
  font-weight: 700;
  color: #333;
  text-align: start;
  font-family: ${fonts.regular};
  ${pxToCssFont(16, 22)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const BillingCta = styled(DeliveryCta)`
  margin-top: 8px;
`;

export const TextLoader = styled.div`
  width: inherit;
  background: linear-gradient(0.25turn, transparent, ${colors.$ffffff}, transparent),
    linear-gradient(#eee, #eee), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
  animation: loading 1.5s infinite;

  :first-child {
    margin-top: 60px;
    margin-bottom: 16px;
    height: 45px;
  }

  height: 150px;
  margin-top: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  @keyframes loading {
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
`;

export const TextLoaderEmpty = styled.div`
  height: 30px;
  margin-top: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;
