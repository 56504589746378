import { purify } from '@vfit/shared/themes';
import { IFloatingBannerCMS } from '@vfit/shared/models';

export const stickyFloatingBannerOrganize = (cmsConfig: IFloatingBannerCMS) => ({
  animationDelay: cmsConfig?.floatingbanner?.floatingBanner?.animationDelay || '',
  animationType: cmsConfig?.floatingbanner?.floatingBanner?.animationType || 'slide-up',
  buttonCloseXColor: cmsConfig?.floatingbanner?.floatingBanner?.buttonCloseXColor || '#fff',
  htmlDesktop: purify([cmsConfig?.floatingbanner?.floatingBanner?.htmlDesktop || ''])?.[0] || '',
  htmlTablet: purify([cmsConfig?.floatingbanner?.floatingBanner?.htmlTablet || ''])?.[0] || '',
  htmlMobile: purify([cmsConfig?.floatingbanner?.floatingBanner?.htmlMobile || ''])?.[0] || '',
});

export type IStickyBannerHeader = ReturnType<typeof stickyFloatingBannerOrganize>;
