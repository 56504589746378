import React from 'react';
import styled from 'styled-components';
import { IPageProps, checkIsApp, usePage, useTranslate } from '@vfit/consumer/data-access';
import { Footer, Header } from '@vfit/shared/components';
import { ButtonSlide } from '@vfit/shared/atoms';
import { checkWindow, getHome, useFooter, useHeader } from '@vfit/shared/data-access';

export const NotFoundContainer = styled.div`
  font: 110%/1.5 system-ui, sans-serif;
  background: #ffffff;
  color: #131417;
  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;
  padding: 2rem;
`;

/**
 * This is TEMP 404 page. Is only in "Emergency" case
 * @param page
 * @param isApp
 * @constructor
 */
export default function NotFoundPage({ isApp }: IPageProps) {
  const { page, pageNavigation } = usePage();
  const { haveHeader, isHeaderSmall, haveFooter, isNewHeader } = page?.params || {};
  const { header, tagging } = useHeader(pageNavigation);
  const { footer } = useFooter(pageNavigation);
  const { translate } = useTranslate();
  return (
    <div>
      {haveHeader && header && !isApp && (
        <Header
          onTrack={tagging}
          header={header}
          lite={isHeaderSmall}
          onTranslate={translate}
          isExtendedHeader={isNewHeader}
        />
      )}
      <NotFoundContainer>
        <div>
          <h1>La pagina non è al momento disponibile</h1>
          <p>Siamo spiacenti ma la pagina non è al momento disponibile, riprova più tardi</p>
          <div>
            <div>
              <ButtonSlide
                label="Vai in homepage"
                onClick={() => {
                  if (checkWindow()) {
                    window.open(getHome(), '_self');
                  }
                }}
                isApp={checkIsApp()}
                borderColor="#262626"
                labelColor="#262626"
                hoverColor="#262626"
                hoverTextColor="#fff"
                clickColor="#7e7e7e"
                clickTextColor="#fff"
                name="action_notFoundPage"
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <ButtonSlide
                label={"Effettua l'accesso"}
                onClick={() => {
                  window.open(
                    'https://www.vodafone.it/area-utente/fai-da-te/Common/PaginaUnicadiLogin.html',
                    '_self'
                  );
                }}
                borderColor=""
                isApp={checkIsApp()}
                buttonColor="#e60000"
                labelColor="#fff"
                hoverColor="#bd0000"
                hoverTextColor="#fff"
                clickColor="#a10000"
                clickTextColor="#fff"
                name="secondAction_notFoundPage"
              />
            </div>
          </div>
        </div>
      </NotFoundContainer>
      {haveFooter && !isApp && <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />}
    </div>
  );
}
