/* eslint-disable no-unsafe-optional-chaining */
import { IActionType } from '@vfit/shared/models';
import { IProduct } from '../../../api/products/products.models';
import { ISlides } from '../Action/action.models';
import { ICarouselCMS, IGlobalPromo, IPanicMode } from '../../cms.models';
import { checkPanicMode, overrideAction, overridePromo } from '../../../utils/utils';
import { IDevice } from '../../../utils/devices/devices.models';

const checkActionType = (actionType?: IActionType) => {
  const type = actionType ? +actionType : -1;
  switch (type) {
    case IActionType.CALL_ME_NOW:
    case IActionType.SHOW_DETAIL:
      return false;
    default:
      return true;
  }
};

const organizeProductSlider = (
  product: IProduct,
  panicModeConfiguration?: IPanicMode,
  globalPromoConfig?: IGlobalPromo
): ISlides => {
  const overrideFlag =
    checkPanicMode(panicModeConfiguration, product) &&
    checkActionType(product?.homepageDetail?.action?.type);
  const actionProduct = overrideAction(product, overrideFlag);
  return {
    id: +product.offerId,
    category: product?.category || '',
    title: product?.title || '',
    image: product?.image || '',
    imageMobile: product?.imageMobile || '',
    video: product?.homepageDetail?.video || '',
    videoMobile: product?.homepageDetail?.videoMobile || '',
    overlayImage: product?.homepageDetail?.overlayImage || '',
    overlayImageMobile: product?.homepageDetail?.overlayImageMobile || '',
    redirectCoveragePath: product.redirectCoveragePath,
    action: { ...actionProduct },
    ...(product?.slug && { slug: product.slug }),
    ...(product?.offerType && {
      // eslint-disable-next-line no-unsafe-optional-chaining
      offerType: +product?.offerType,
    }),
    product: {
      ...overridePromo(product, globalPromoConfig),
      ...(checkPanicMode(panicModeConfiguration, product) && { hubSecondAction: undefined }),
    },
  };
};

export const getProductsSlider = (
  widget: ICarouselCMS,
  widgetTitle: string,
  customHubs?: { [key: string]: IProduct[] | IDevice[] },
  panicModeCOnfiguration?: IPanicMode,
  globalPromo?: IGlobalPromo
) => {
  const { elements } = widget;
  const getHubProduct = (el: ISlides): IProduct | undefined => {
    const hubKey = el?.key?.toLowerCase() || '';
    const cmsId = el.id;
    return customHubs?.[hubKey]?.[`_${cmsId}`];
  };
  const slides: ISlides[] = elements
    ?.filter?.((el) => !!getHubProduct(el))
    .map((el) => organizeProductSlider(getHubProduct(el), panicModeCOnfiguration, globalPromo));
  return { slides, text: widgetTitle };
};
