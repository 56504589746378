import React from 'react';
import { useRouter } from 'next/router';
import { ISplittedImageTextCard } from '@vfit/shared/models';
import { SplittedImageTextCard as SplittedImageTextCardCommon } from '@vfit/shared/components';
import { getButtonActionByActionType } from '@vfit/shared/data-access';

const SplittedImageTextCard: React.FC<ISplittedImageTextCard> = ({
  topText,
  title,
  description,
  urlImg,
  urlImgMobile,
  action,
  info,
  divider,
  disableCoverImg,
}) => {
  const { push } = useRouter();
  return (
    <SplittedImageTextCardCommon
      topText={topText}
      title={title}
      description={description}
      urlImg={urlImg}
      urlImgMobile={urlImgMobile}
      info={info}
      divider={divider}
      action={action}
      handleButtonClick={() => getButtonActionByActionType(action, push)}
      disableCoverImg={disableCoverImg}
    />
  );
};

export default SplittedImageTextCard;

