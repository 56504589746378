import { Children } from 'react';
import { CustomText, LinkWithIcon, ButtonSlide } from '@vfit/shared/atoms';
import { IconListItem } from '@vfit/shared/components';
import { purify } from '@vfit/shared/themes';
import { checkIsApp } from '@vfit/consumer/data-access';
import { IOfferStepProps } from './offerStep.models';
import {
  Title,
  OfferDescription,
  OfferPrice,
  BottomContainer,
  ButtonDiv,
  ListDiv,
} from './offerStep.style';
import { Wrapper } from '../../convergenceModal.style';

const OfferStep = ({
  data,
  onSeeDetailClick,
  onFirstButtonClick,
  onSecondButtonClick,
}: IOfferStepProps) => {
  const {
    title,
    offerDescription,
    productTitle,
    price,
    recurrence,
    priceDescription,
    productSpecs,
    detailsLabel,
    firstButtonLabel,
    secondButtonLabel,
  } = data;

  const [purifyDescription] = purify([priceDescription || '']);

  return (
    <Wrapper>
      <Title>{title}</Title>
      <CustomText text={offerDescription} modal size={20} lineHeight={28} textAlign="left" />
      <Title>{productTitle}</Title>
      <OfferPrice>
        {price}
        <span className="rec">{recurrence && `/${recurrence}`}</span>
      </OfferPrice>
      <OfferDescription>
        {purifyDescription && <div dangerouslySetInnerHTML={{ __html: purifyDescription }} />}
      </OfferDescription>
      <ListDiv>
        {Children.toArray(productSpecs.map((spec) => <IconListItem text={spec} />))}
      </ListDiv>
      <BottomContainer>
        <LinkWithIcon text={detailsLabel} onClick={onSeeDetailClick} size="2" />
        <ButtonDiv>
          <ButtonSlide
            onClick={onFirstButtonClick}
            label={firstButtonLabel}
            isApp={checkIsApp()}
            buttonColor="#e60000"
            hoverColor="#bd0000"
            clickColor="#a10000"
            labelColor="#fff"
          />
          {onSecondButtonClick && secondButtonLabel && (
            <ButtonSlide
              onClick={onSecondButtonClick}
              label={secondButtonLabel}
              isApp={checkIsApp()}
              buttonColor="#fff"
              hoverColor="#262626"
              hoverTextColor="#fff"
              clickColor="#7e7e7e"
              clickTextColor="#fff"
              borderColor="#0d0d0d"
            />
          )}
        </ButtonDiv>
      </BottomContainer>
    </Wrapper>
  );
};

export default OfferStep;
