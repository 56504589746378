import { IPortabilityCMS } from '@vfit/consumer/data-access';
import { IChooseOperator } from './portability.model';

export const organizePortabilityCard = (portabilityCms: IPortabilityCMS) => {
  const dataToReturn: IChooseOperator = {
    chooseOperator: [
      {
        title: portabilityCms?.portability?.provider?.providerTitles?.providerOne || '',
        textInput: portabilityCms?.portability?.selectInput?.migrationCode || '',
        dropDown: portabilityCms?.portability?.provider?.title || '',
      },
      {
        title: portabilityCms?.portability?.provider?.providerTitles?.providerTwo || '',
        textInput: portabilityCms?.portability?.selectInput?.migrationCode || '',
        dropDown: portabilityCms?.portability?.provider?.title || '',
      },
    ],
  };

  return dataToReturn;
};
