import { useMutation, useQueryClient } from 'react-query';
import { INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { errorMock, GetCustomerResponse } from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IAddressResponse, ILocateStorePayload, ILocateStoreResponse } from '@vfit/shared/models';

/**
 * This method calls the locateStoreService.
 * This methods is able to find the store related to the address.
 * Method: POST
 * @param payload
 * @returns
 */
const locateStoreService = (payload: ILocateStorePayload): Promise<ILocateStoreResponse[]> =>
  nextClient.post(`${API.CARE_V1}/${API.LOCATE_STORE}`, payload);

/**
 * Custom mutation hook for run locateStoreService using the addressID.
 * @returns
 */
export const useLocateStore = () => {
  const queryClient = useQueryClient();
  let keyString = 'locateStore';
  return useMutation(
    ['locateStoreMutation'],
    (id?: string) => {
      const installationAddress = getFromLocalStorageByKey('createdAddress');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const contactMedium = customerData?.[0]?.owningIndividual?.contactMedium || [];
      const postalAddress = contactMedium?.find((contact) => contact.type == 'postalAddress');
      let addressId = (installationAddress as IAddressResponse)?.id || postalAddress?.id;
      if (id) {
        addressId = id;
      }
      const payload = {
        addressId,
        geoLocations: {},
        radius: 30,
        isPickUpStore: 'Y',
        checkInventoryAvailability: false,
      };

      return locateStoreService(payload);
    },
    {
      onMutate: async (data) => {
        if (data) {
          keyString += data;
        }
      },
      onSuccess: (data: ILocateStoreResponse[]) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
