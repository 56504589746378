import {
  recoverServiceabilityField,
  errorMock,
  BACKUP_OFFER_KEY,
  PLACE_ID,
  IProduct,
  IProductOfferListByTech,
  checkIsApp,
  START_PRODUCT_HUB,
} from '@vfit/consumer/data-access';
import { API, TechTypes } from '@vfit/shared/data-access';
import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  CoverageErrorType,
  IBackupOffer,
  ICharacteristic,
  IOfferingServiceabilityRequest,
  IOfferingServiceabilityResponse,
  IServiceabilityAddress,
} from '@vfit/shared/models';

/**
 * Offering Serviceability Service for api offeringServiceability.
 * This method is used to retrieve the serviceability related to a specific address input.
 * @param payload
 * @param customOptions
 */
const offeringServiceabilityService = (
  payload: IOfferingServiceabilityRequest,
  customOptions?: CustomOptions
): Promise<IOfferingServiceabilityResponse> =>
  nextClient.post(API.OFFERING_SERVICEABILITY, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook for run offeringServiceabilityService using the validateAddress service response as input.
 * Call this after validateAddress has been called.
 * @param offerId
 * @param allProducts
 * @param alternativeCmsIdList
 * @param place_id
 * @returns
 */
export const useServiceabilityAddressMutation = (
  offerId: string,
  allProducts: IProduct[],
  alternativeCmsIdList?: string[],
  alternativeCmsIdListByTech?: IProductOfferListByTech[],
  place_id?: string
) => {
  const queryClient = useQueryClient();
  return useMutation(
    'offeringServiceability',
    (validatedAddress: IServiceabilityAddress) =>
      offeringServiceabilityService({
        serviceabilityAddress: validatedAddress,
      }),
    {
      onSuccess: (data: IOfferingServiceabilityResponse) => {
        if (data?.serviceabilityResult === 'serviceable') {
          if (place_id) localStorage.setItem(PLACE_ID, place_id);

          const originalTechType: TechTypes = recoverServiceabilityField(
            data?.serviceabilityCharacteristic,
            'Technology'
          ) as TechTypes;

          const idByTech = alternativeCmsIdListByTech?.filter(
            (el) => el?.technology.toLowerCase() === originalTechType.toLowerCase()
          );
          const allLockinProducts = allProducts?.filter((el) => el?.isLockInProduct);
          const lockinIds = allLockinProducts?.map((el) => el?.cmsId);

          const alternativeProductList: { cmsId: string; offerId: string }[] = [];
          const alternativeOffersList: string[] = [];

          alternativeCmsIdList?.forEach((alternativeCmsId) => {
            const foundedProd = allProducts?.find(
              (el) => el?.cmsId == alternativeCmsId && (checkIsApp() || !el?.isLockInProduct)
            );
            if (foundedProd?.offerId) {
              alternativeProductList.push({
                cmsId: foundedProd?.cmsId?.toString(),
                offerId: foundedProd?.offerId?.toString(),
              });
              alternativeOffersList.push(foundedProd?.offerId?.toString());
            }
          });

          const isTechFWA: boolean = originalTechType.toLowerCase().includes('fwa');
          const isOutdoor: boolean = isTechFWA
            ? originalTechType.toLowerCase().includes('outdoor')
            : false;
          localStorage.setItem('isTechFWA', JSON.stringify(isTechFWA));
          localStorage.setItem('isOutdoor', JSON.stringify(isOutdoor));
          localStorage.setItem('techOffer', JSON.stringify(originalTechType));

          if (idByTech?.length > 0 && (checkIsApp() || !lockinIds?.includes(idByTech?.[0]?.id))) {
            const findByTechCms = allProducts?.find((el) => el?.cmsId === idByTech?.[0]?.id);
            const foundedOffer = data?.offeringServiceabilityItem.find(
              (offer: IBackupOffer) => offer?.offering?.id === findByTechCms?.offerId?.toString()
            );
            localStorage.setItem('offeringServiceability', JSON.stringify(data));
            localStorage.setItem(
              BACKUP_OFFER_KEY,
              JSON.stringify({
                offeringId: foundedOffer?.offering?.id,
                cmsId:
                  alternativeProductList?.find?.((el) => el?.offerId == foundedOffer?.offering?.id)
                    ?.cmsId || '',
              })
            );

            const startProductTechOverrideHub = window?.location?.href;
            localStorage.setItem(START_PRODUCT_HUB, startProductTechOverrideHub);
            queryClient.setQueryData('offeringServiceability', data);
          } else {
            let foundedOffer = data?.offeringServiceabilityItem.find(
              (offer: IBackupOffer) => offer?.offering?.id === offerId
            );

            // alternative product
            if (!foundedOffer && alternativeOffersList?.length > 0) {
              alternativeOffersList?.forEach((aOL) => {
                const foundedAmdOffer = (
                  data as IOfferingServiceabilityResponse
                )?.offeringServiceabilityItem?.find(
                  (backupOffer: IBackupOffer) =>
                    aOL?.toString() === backupOffer?.offering?.id?.toString()
                );
                if (foundedAmdOffer && !foundedOffer) foundedOffer = foundedAmdOffer;
              });
            }
            const unserviceableCharacteristic = data?.serviceabilityCharacteristic.filter(
              (characteristic: ICharacteristic) =>
                characteristic.displayName === 'DisplayTechnologyName' &&
                characteristic.displayValue === '3G 4G'
            );

            if (foundedOffer && !unserviceableCharacteristic[0]) {
              localStorage.setItem('offeringServiceability', JSON.stringify(data));
              localStorage.setItem(
                BACKUP_OFFER_KEY,
                JSON.stringify({
                  offeringId: foundedOffer?.offering?.id,
                  cmsId:
                    alternativeProductList?.find?.(
                      (el) => el?.offerId === foundedOffer?.offering?.id
                    )?.cmsId || '',
                })
              );
              queryClient.setQueryData('offeringServiceability', data);
            } else {
              queryClient.setQueryData('offeringServiceability', {
                error: 'ERROR on serviceabilityAddress',
                errorCode: isTechFWA ? CoverageErrorType.FWA : CoverageErrorType.KO,
                errorMessage: `The offer ${offerId} is not serviceable on this address`,
              });
            }
          }
        } else {
          localStorage.removeItem(PLACE_ID);
          queryClient.setQueryData(
            'offeringServiceability',
            errorMock(
              'offeringServiceability',
              {
                status: '200',
                statusText: '200',
                url: API.OFFERING_SERVICEABILITY,
              },
              `The address is not serviceable.`
            )
          );
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - offeringServiceability: `, error);
        queryClient.setQueryData('offeringServiceability', {
          error: 'ERROR on serviceabilityAddress',
          errorCode: CoverageErrorType.ERROR,
          errorMessage: `The offer ${offerId} is not serviceable on this address`,
        });
        localStorage.setItem(
          `offeringServiceability`,
          JSON.stringify(errorMock(`offeringServiceability`))
        );
      },
    }
  );
};
