import { useEffect, useState } from 'react';
import { CheckboxList, LinkWithIcon, CustomText } from '@vfit/shared/atoms';
import { IOptionalText } from '@vfit/shared/models';
import { GenericDetail, ShadowsGroup, VfModal } from '@vfit/shared/components';
import { Container, Title, Description, BottomLable, BottomText } from './checkboxCards.style';
import { ICheckboxCards } from './checkboxCards.models';

const CheckboxCards = (props: ICheckboxCards) => {
  const {
    title,
    description,
    bottomModal,
    bottomText,
    disableItems = false,
    items,
    value,
    setSelectedItem,
    onSelectItem,
    isSuccess = true,
    selectedItem,
    onOpeneModal,
  } = props;
  const [item, setItem] = useState<IOptionalText>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const selectedItemFound = items?.findIndex((obj) => obj.value === selectedItem?.value);
  const [passedItemIndex, setPassedItemIndex] = useState<number>(selectedItemFound);

  useEffect(() => {
    if (item !== undefined && item?.value !== selectedItem?.value) {
      setSelectedItem(item);

      if (onSelectItem) onSelectItem(item);
      if (item) setPassedItemIndex(items.findIndex((obj) => obj.value === value));
    }
  }, [item]);

  useEffect(() => {
    if (items && items.length > 0) {
      const foundItemIndex = items?.findIndex((obj) => obj.value === selectedItem?.value);
      setPassedItemIndex(foundItemIndex);
    }
  }, [items]);

  useEffect(() => {
    if (openModal) onOpeneModal?.();
  }, [openModal]);

  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Description>
          <CustomText text={description || ''} />
        </Description>
        {isSuccess ? (
          <CheckboxList
            marginBottom={{ mobile: 12, tablet: 12, desktop: 12 }}
            items={items}
            selectedItem={setItem}
            passedValueIndex={passedItemIndex}
            disabled={disableItems}
          />
        ) : (
          <ShadowsGroup quantity={2} heights={70} />
        )}
        {bottomModal && (
          <BottomLable>
            <LinkWithIcon
              size="1"
              onClick={() => setOpenModal(!openModal)}
              text={bottomModal?.textLink}
            />
          </BottomLable>
        )}
        <BottomText>
          <p>{bottomText}</p>
        </BottomText>
      </Container>

      <VfModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
        <GenericDetail
          title={bottomModal?.titleModal || ''}
          description={bottomModal?.descriptionModal || ''}
          options={{
            textAlign: 'left',
          }}
        />
      </VfModal>
    </>
  );
};

export default CheckboxCards;
