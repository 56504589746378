import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 0 auto;
  color: ${colors.$262626};
  font-family: ${fonts.regular};
  font-weight: 400;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: 32px;
  }
`;

export const Subtitle = styled.div`
  div {
    ${pxToCssFont(18, 24)}
    text-align: left;
    margin: 0 0 32px;

    @media (min-width: ${breakpoints.tablet}) {
      margin: 0 0 44px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      margin: 0 0 30px;
    }
  }
`;

export const Data = styled.div`
  font-weight: 700;
  ${pxToCssFont(18, 24)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 28)}
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }

  div {
    color: ${colors.$00697c};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  .button-slide-container {
    position: relative;
    bottom: 0;
    width: 100%;
    background: ${colors.$ffffff};

    @media (min-width: ${breakpoints.tablet}) {
      width: 258px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: 296px;
    }
  }
`;
