import {
  HubPage,
  ProductPage,
  BlankPage,
  NotFoundPage,
  SecondaryHubPage,
  AppointmentPage,
  SimActivationPage,
} from '@vfit/business-components';
import { IPageProps } from '@vfit/business-data-access';

export default function LayoutBusiness({ page, isApp }: IPageProps) {
  const { params } = page || {};
  switch (params?.layout) {
    case 'HUB':
      return <HubPage page={page} isApp={isApp} />;
    case 'SECONDARY_HUB':
      return <SecondaryHubPage page={page} isApp={isApp} />;
    case 'PDP':
      return <ProductPage page={page} isApp={isApp} />;
    case 'BLANK':
      return <BlankPage page={page} isApp={isApp} />;
    case 'APPOINTMENT':
      return <AppointmentPage page={page} isApp={isApp} />;
    case 'SIM_ACTIVATION':
      return <SimActivationPage page={page} isApp={isApp} />;
    default:
      break;
  }
  return <NotFoundPage />;
}

