import {
  BYPASS_CALL_API_SIA,
  IShoppingCartResponse,
  productSpecificError,
  retrieveProduct,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey, getHome, openPopup } from '@vfit/shared/data-access';
import { errorManager, ErrorService } from '@vfit/shared/data-access';

const checkCartValidation = () => {
  const product = retrieveProduct();
  const home = getHome();
  const shoppingCartUrl = product?.shoppingCartUrl;
  const { buttonGenericError, urlGenericErrorCallMeNow } = productSpecificError(product);
  const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
  if (shoppingCart?.cartValidation?.status === 'blocked') {
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: 'Attenzione',
      event_label: 'cart_blocked',
      errorCode: '401',
      message: "Non è possibile concludere l'acquisto per questa tipologia di utente",
      onClose: () => {
        localStorage.removeItem(BYPASS_CALL_API_SIA);
        window.open(shoppingCartUrl || home, '_self');
      },
      actionEvent: () => openPopup(urlGenericErrorCallMeNow),
      actionText: buttonGenericError,
      disableTrack: false,
    });
  }
};

export { checkCartValidation };
