import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  BillingAddress,
  errorMock,
  GetCustomerResponse,
  IBillingAccountResponse,
  IFindCallerResponse,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IAddressResponse } from '@vfit/shared/models';
import { IBillDeliveryDetailsPayload } from './billDeliveryDetails.models';

/**
 * This method is used to edit the billing type from the customer choice.
 * Method: PUT
 * @param customerId
 * @param billingId
 * @param payload
 * @param customOptions
 * @returns
 */
const billDeliveryDetailsService = (
  customerId: string,
  billingId: string,
  payload: IBillDeliveryDetailsPayload,
  customOptions?: CustomOptions
): Promise<{}> =>
  nextClient.put(
    `${API.CUSTOMER}/${customerId}/${API.BILLING_ACCOUNT}/${billingId}/${API.BILLING_DELIVERY_DETAILS}`,
    payload,
    {
      ...(customOptions?.headers && { headers: customOptions.headers }),
      ...(customOptions?.silentLoginHeaders && {
        silentLoginHeaders: customOptions.silentLoginHeaders,
      }),
    }
  );

/**
 * Custom mutation hook for run billDeliveryDetailsService using the createdAddress/billingCreatedAddress, the customerData and the billingAccount.
 * Call this after the customer, shoppingCart and delivery have just been created.
 * @returns
 * @deprecated
 */
export const useBillDeliveryDetails = () => {
  const queryClient = useQueryClient();
  const keyString = 'billDeliveryDetails';
  const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
  const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
  const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
  const billingId = billingAccount?.id;
  const customerEmail = customerData?.[0]?.owningIndividual?.contactMedium.filter(
    (item) => item.type === 'email'
  );
  const createdBillingAddress: IAddressResponse | undefined = queryClient.getQueryData([
    'billingCreatedAddress',
  ]);

  const createdAddress: IAddressResponse = getFromLocalStorageByKey('createdAddress');

  return useMutation(
    ['billDelivery'],
    (data: string) => {
      let payload: IBillDeliveryDetailsPayload;
      const physicalAddress =
        createdBillingAddress || createdAddress || customerData?.[0]?.billingAddress;
      switch (data) {
        case 'eBill':
          payload = {
            billFormat: data,
            emailAddress: customerEmail?.[0]?.emailAddress,
          };
          break;
        case 'paperBill':
          payload = {
            billFormat: data,
            physicalAddress,
          };
          break;
        default:
          break;
      }
      LoggerInstance.log('billDeliveryDetails payload : ', payload);
      return billDeliveryDetailsService(customerId, billingId, payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};

export const useBillDelivery = () => {
  const queryClient = useQueryClient();
  const keyString = 'billDeliveryDetails';
  return useMutation(
    ['billDelivery'],
    (data: {
      billFormat: string;
      physicalAddress?: IAddressResponse | BillingAddress;
      emailAddress?: string;
    }) => {
      let payload: IBillDeliveryDetailsPayload;
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const customerId = findCaller?.customerRef?.id || customerData?.[0]?.id;
      const billingId = billingAccount?.id;
      const customerEmail = customerData?.[0]?.owningIndividual?.contactMedium.filter(
        (item) => item.type === 'email'
      );
      const { billFormat, physicalAddress, emailAddress } = data;
      switch (billFormat) {
        case 'eBill':
          payload = {
            billFormat,
            emailAddress: emailAddress || customerEmail?.[0]?.emailAddress,
          };
          break;
        case 'paperBill':
          payload = {
            billFormat,
            ...(physicalAddress && { physicalAddress }),
          };
          break;
        default:
          break;
      }
      return billDeliveryDetailsService(customerId, billingId, payload);
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(keyString, JSON.stringify(data));
        queryClient.setQueryData(keyString, data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
      },
    }
  );
};
