export enum ISoftEvents {
  ADD_CUSTOM_OFFER = 'addCustomOfferNotificationEvent',
  SET_AB_TEST = 'setAbTest',
}

export interface IOrganizedSoftProducts {
  product_id?: string[];
  product_name?: string[];
  product_price?: string[];
  product_quantity?: string;
  product_category?: string[];
}

export type TVisitorLoginStatus = 'logged in' | 'logged out' | 'logged in multi factor';

export interface VisitorTrackingOpts {
  visitor_addon_id_primary?: string;
  visitor_addon_type_active?: string;
  visitor_asset_plan_id_active?: string;
  visitor_asset_plan_id_primary?: string;
  visitor_asset_plan_name_active?: string;
  visitor_asset_plan_type_active?: string;
  visitor_asset_status_active?: string;
  visitor_customer_dob?: string;
  visitor_customer_gender?: string;
  visitor_customer_location_city?: string[];
  visitor_customer_location_country?: string[];
  visitor_customer_location_id?: string[];
  visitor_customer_name_first?: string;
  visitor_customer_name_last?: string;
  visitor_customer_region?: string;
  visitor_id_asset_active?: string;
  visitor_id_asset_primary?: string;
  visitor_id_asset_active_md5?: string;
  visitor_id_email?: string;
  visitor_login_type?: string;
  visitor_login_status?: TVisitorLoginStatus;
  visitor_id_asset_list?: string[];
  visitor_type?: string;
}

export interface TrackingOptions {
  event_category?: string | string[];
  event_action?: string;
  page_subsection?: string;
  page_type?: string;
  page_section?: string;
  journey_name?: string;
  enabled?: boolean;

  transaction_id_external?: string;
  transaction_type?: string;
  transaction_product_category?: string;
  transaction_product_id?: string;
  transaction_product_name?: string;
  transaction_product_price?: string;
  transaction_product_quantity?: string;
  transaction_total?: string;
  product_technology?: string;
}

// Tracking product into page.
export interface PageProduct {
  product_id?: string | string[];
  product_name?: string | string[];
  product_category?: string | string[];
  product_price?: string | string[];
  product_sku?: string;
  product_brand?: string;
  product_quantity?: string;
  product_price_recurrence_amount?: string;
  product_price_recurrence_amount_default?: string;
  product_price_recurrence_number?: string;
  product_price_recurrence_type?: string;
}
