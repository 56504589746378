import { useEffect } from 'react';
import { Portal, HeaderBanner } from '@vfit/shared/components';
import { IBanner } from '@vfit/shared/models';
import { useDeviceType } from '@vfit/shared/hooks';
import { useQueryClient } from 'react-query';
import { IStickyBannerHeader } from './stickyBannerHeader.models';
import { getBannerConfig } from './stickyBannerHeader.utils';

const StickyBannerHeader = ({ bannerCustomConfig }: IStickyBannerHeader) => {
  const queryClient = useQueryClient();
  const { isMobile, isTablet, isDesktop } = useDeviceType();
  const bannerConfig: IBanner = getBannerConfig(queryClient, bannerCustomConfig);
  const { animationDelay, htmlDesktop, htmlMobile, htmlTablet } = bannerConfig || {};

  const portalBanner = (
    customAppend: (element: HTMLDivElement) => void,
    customCreateWrapper?: () => HTMLDivElement
  ) => (
    <Portal
      wrapperId="banner"
      customCreateWrapper={customCreateWrapper}
      customAppendElement={(wrapperElement) => {
        customAppend(wrapperElement);
      }}
    >
      <HeaderBanner {...bannerConfig} />
    </Portal>
  );

  const appendStickyDefault = (wrapperElement: HTMLDivElement) => {
    const wrapperElementDefault = wrapperElement;
    if (isDesktop) {
      wrapperElementDefault.style.position = 'fixed';
      wrapperElementDefault.style.top = '0px';
      wrapperElementDefault.style.zIndex = '2';
      wrapperElementDefault.style.width = '100%';
    }
    const stickyBannerHeaderChild = document.getElementById('stickyBannerHeader');
    if (stickyBannerHeaderChild)
      stickyBannerHeaderChild?.parentNode?.insertBefore?.(
        wrapperElementDefault,
        stickyBannerHeaderChild?.nextSibling
      );
  };
  const appendStickyNewHeader = (elementToAppend: ChildNode, isMobileTablet?: boolean) => {
    const banner = document.getElementById('banner');
    if (banner) {
      banner.style.position = 'fixed';
      banner.style.zIndex = '3';
      const topPadding = isMobileTablet ? 0 : 5;
      const node = elementToAppend as HTMLElement;
      const firstElementBottom = node.getBoundingClientRect().bottom;
      banner.style.top = `${firstElementBottom + topPadding}px`;

      window.addEventListener('scroll', () => {
        const currentfirstElementBottom = node.getBoundingClientRect().bottom;
        if (currentfirstElementBottom <= 0) {
          banner.style.top = '0';
        } else {
          banner.style.top = `${currentfirstElementBottom + topPadding}px`;
        }
      });
    }
  };
  const renderBannerDesktop = () => {
    if (!htmlDesktop) return undefined;
    return portalBanner((wrapperElement: HTMLDivElement) => {
      const headerFirstChild = document.getElementById('mainHeader')?.firstChild;
      const headerLiteFirstChild = document.getElementById('header-lite')?.firstChild;
      const extendedHeaderFirstChild = document.getElementById('extendedHeader')?.firstChild;
      const elementToAppend = headerFirstChild || headerLiteFirstChild || extendedHeaderFirstChild;
      if (elementToAppend) {
        elementToAppend?.parentNode?.insertBefore?.(wrapperElement, elementToAppend?.nextSibling);
        if (headerLiteFirstChild) {
          const banner = document.getElementById('banner');
          if (banner) {
            banner.style.position = 'fixed';
            banner.style.top = '0px';
            banner.style.zIndex = '3';
          }
        }
        if (extendedHeaderFirstChild) {
          appendStickyNewHeader(elementToAppend);
        }
      } else {
        appendStickyDefault(wrapperElement);
      }
    });
  };

  const renderBannerMobileTablet = () => {
    if (!htmlMobile && !htmlTablet) return undefined;
    return portalBanner(
      (wrapperElement: HTMLDivElement) => {
        const headerFirstChild = document.getElementById('mainHeader');
        const headerLiteFirstChild = document.getElementById('header-lite');
        const extendedHeaderFirstChild = document.getElementById('extendedHeader');
        const elementToAppend =
          headerFirstChild || headerLiteFirstChild || extendedHeaderFirstChild;
        if (elementToAppend) {
          elementToAppend?.parentNode?.insertBefore?.(wrapperElement, elementToAppend?.nextSibling);
          const banner = document.getElementById('banner');
          if (headerLiteFirstChild) {
            if (banner) {
              banner.style.position = 'fixed';
              banner.style.top = '0px';
              banner.style.zIndex = '3';
              banner.style.paddingTop = '0';
            }
          }
          if (extendedHeaderFirstChild) {
            appendStickyNewHeader(elementToAppend, true);
          }
        } else appendStickyDefault(wrapperElement);
      },
      () => {
        const wrapperElement = document.createElement('div');
        wrapperElement.setAttribute('id', 'banner');
        // FIx: 09/04 remove position fixed for mobile and tablet
        // wrapperElement.style.position = 'fixed';
        // wrapperElement.style.top = '0px';
        wrapperElement.style.position = 'fixed';
        wrapperElement.style.top = '0px';
        wrapperElement.style.zIndex = '3';
        return wrapperElement;
      }
    );
  };

  const animationDelayNumber = parseInt(animationDelay || '0', 10);

  /**
   * Method to updatezIndex for header.
   * it is a temporary fix that allows the correct display
   * of the sticky banner that appears under the header
   */
  const updateZIndex = (zIndex: string) => {
    const header = document.getElementById?.('mainHeader');
    const headerLite = document.getElementById?.('header-lite');
    const extendedHeaderFirstChild = document.getElementById('extendedHeader');
    const headerElement = header || headerLite || extendedHeaderFirstChild;
    if (headerElement) headerElement.style.zIndex = zIndex;
  };

  useEffect(() => {
    updateZIndex('7');
    setTimeout(() => {
      updateZIndex('4');
    }, animationDelayNumber);
  }, []);

  return (
    <>
      {(isMobile || isTablet) && renderBannerMobileTablet()}
      {!isMobile && !isTablet && renderBannerDesktop()}
    </>
  );
};

export default StickyBannerHeader;
