import { useEffect } from 'react';
import { SELECTED_PAYMENT } from '@vfit/consumer/data-access';
import { setTrackLink } from '@vfit/shared/data-access';
import { EMethodCode, ICommonData } from '@vfit/shared/models';
import { useTracking } from '@vfit/shared/data-access';
import { useCheckout } from '../../../../iBuyFixed.context';
import { ID_FLOWS } from '../../../checkout.constants';
import { getUserInfo, getTagging } from '../../../checkout.utils';
import PreselectedUpfrontPayment from '../PreselectedUpfrontPayment/preselectedUpfrontPayment';
import ChooseUpfrontPayment from '../ChooseUpfrontPayment/chooseUpfrontPayment';

const UpfrontPayment = (props: ICommonData) => {
  const { setIsLastCard, setCurrentStepKey, setUpdateProductPriceCtrl } = useCheckout();
  const selectedPayment = localStorage.getItem(SELECTED_PAYMENT);
  const selectedPaymentMethodIsCCard =
    selectedPayment?.toLowerCase() === EMethodCode.CREDIT_CARD.toLowerCase();

  const tagging = getTagging('checkout', 'fixed');
  const { trackView } = useTracking({
    event: ['checkout_step10.1'],
    event_label: 'upfront payment',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'upfront payment');

  useEffect(() => {
    setCurrentStepKey(ID_FLOWS.UPFRONT_PAYMENT);
    window.history.pushState(null, '', window.location.pathname);
    setUpdateProductPriceCtrl({
      isPriceUpdated: true,
      isUpdatePrice: false,
    });
    setIsLastCard(true);
    return () => {
      setIsLastCard(false);
    };
  }, []);

  if (selectedPaymentMethodIsCCard) return <PreselectedUpfrontPayment {...props} />;

  return <ChooseUpfrontPayment {...props} />;
};

export default UpfrontPayment;
