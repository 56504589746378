import * as yup from 'yup';

export const IEditSchema = yup.object({
  phoneNumber: yup
    .string()
    .required('Campo obbligatorio')
    .min(10, 'Campo errato')
    .max(11, 'Campo errato')
    .matches(/^[0-9]*$/, 'Inserire un numero valido'),
  email: yup.string().required('Campo obbligatorio').email('Inserire email nel formato corretto'),
});
