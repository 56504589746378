import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';

export const ShoppingCartOfferContainer = styled.div`
  margin-top: 48px;

  h3 {
    font-family: ${fonts.exbold};
    font-weight: 400;
    ${pxToCssFontSize(20)}
    margin-top: 0;
    margin-bottom: 0;
  }

  h4 {
    font-family: ${fonts.light};
    font-weight: 400;
    ${pxToCssFontSize(20)}
    margin-top: 14px;
    margin-bottom: 0;
  }

  #edit-address {
    text-decoration-line: underline;
    text-underline-position: from-font;
    width: max-content;

    &:hover {
      color: ${colors.$e60000};
    }

    &:active {
      color: ${colors.$e60000};
    }
  }

  p {
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(18, 26)}
    color: ${colors.$262626};
    margin: 0;
    margin-top: 16px;
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 32px;
    margin-right: 32px;

    h3 {
      ${pxToCssFontSize(30)}
    }

    h4 {
      ${pxToCssFontSize(24)}
    }

    p {
      ${pxToCssFontSize(18)}
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 80px;
    margin-right: 80px;

    h3 {
      ${pxToCssFont(36, 38)}
    }

    h4 {
      ${pxToCssFontSize(24)}
    }

    p {
      ${pxToCssFontSize(18)}
    }
  }
`;
