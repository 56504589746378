import { useEffect, useState } from 'react';
import {
  checkIsGoBackSIA,
  getDxlAppMock,
  IAPIError,
  isAppMock,
  setDxlCampaigns,
  useCreateAddress,
  useDXLCampaigns,
  usePage,
  useServiceabilityAddressMutation,
  useValidateAddress,
} from '@vfit/consumer/data-access';
import {
  ERROR_CODES_DYNAMIC_SHOPPING_CART,
  FLOW_TYPE_DYNAMIC_SHOPPING_CART,
} from '@vfit/shared/models';
import { AppMobile, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE,
  DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS,
  retrieveCartInfo,
  getIAddConfiguration,
  iAddProductNotFound,
} from './useDynamicShoppingCartFlow.utils';
import {
  IUseDynamicShoppingCart,
  IUseDynamicShoppingCartResponse,
} from './useDynamicShoppingCartFlow.models';

export function useDynamicShoppingCartFlow() {
  const { products } = usePage();

  const [coverageToolFlowPayload, setCoverageToolFlowPayload] = useState<IUseDynamicShoppingCart>({
    product: undefined,
    onConfirmAddress: false,
    alternativeProductList: [],
  });
  const [dynamicShoppingCartResponse, setDynamicShoppingCartResponse] =
    useState<IUseDynamicShoppingCartResponse>(DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE);

  const {
    mutate: validateAddressMutate,
    isLoading: isLoadingValidateAddress,
    isError: isErrorValidateAddress,
    isSuccess: isSuccessValidateAddress,
    data: validateAddressData,
  } = useValidateAddress();
  const {
    mutate: createdAddressMutate,
    isLoading: isLoadingCreatedAddress,
    isError: isErrorCreatedAddress,
    isSuccess: isSuccessCreatedAddress,
  } = useCreateAddress();
  const {
    mutate: serviceAbilityMutate,
    isLoading: isLoadingServiceAbility,
    isError: isErrorServiceAbility,
    isSuccess: isSuccessServiceAbility,
  } = useServiceabilityAddressMutation(
    coverageToolFlowPayload?.product?.offerId?.toString() || '',
    products || [],
    coverageToolFlowPayload.alternativeProductList,
    coverageToolFlowPayload?.product?.alternativeProductOfferListByTech
  );
  const {
    isError: isErrorDXLCampaigns,
    isSuccess: isSuccessDXLCampaigns,
    isLoading: isLoadingDXLCampaigns,
    refetch: startDXLCampaigns,
  } = useDXLCampaigns();

  // region FLOWS

  const successFlow = () => {
    setDynamicShoppingCartResponse({
      ...DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS,
      flowType: coverageToolFlowPayload?.flowType || '',
      product: coverageToolFlowPayload?.product,
    });
  };

  const errorFlow = (errorCode: number) => {
    setDynamicShoppingCartResponse({
      ...dynamicShoppingCartResponse,
      isLoading: false,
      isSuccess: false,
      isError: true,
      flowType: coverageToolFlowPayload?.flowType || '',
      errorCode,
    });
  };

  const initDynamicShoppingCart = async () => {
    const cartInfo = await retrieveCartInfo(products || []);
    if (!cartInfo) {
      setDynamicShoppingCartResponse({
        ...DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS,
        forceInit: true,
      });
    }
    setCoverageToolFlowPayload(cartInfo);
  };

  const preconditionFlow = () => {
    switch (coverageToolFlowPayload.flowType) {
      case FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_FIXED:
        if (coverageToolFlowPayload?.product && coverageToolFlowPayload?.onConfirmAddress) {
          validateAddressMutate({
            placeObject: {
              ...coverageToolFlowPayload?.placeDetails,
              latitude: coverageToolFlowPayload?.placeDetails?.latitude?.toString(),
              longitude: coverageToolFlowPayload?.placeDetails?.latitude?.toString(),
            },
          });
        }
        break;
      case FLOW_TYPE_DYNAMIC_SHOPPING_CART.SHOPPING_CART_MOBILE:
        successFlow();
        break;
    }
  };

  const startFlow = () => {
    createdAddressMutate({
      address: validateAddressData?.validAddressList?.[0],
    });
  };

  // endregion

  useEffect(() => {
    if (isErrorDXLCampaigns) {
      if (AppMobile.checkIsApp()) {
        errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.ERROR_DXL_CAMPAIGNS);
      }
    }
  }, [isErrorDXLCampaigns]);

  useEffect(() => {
    if (isSuccessDXLCampaigns && !isLoadingDXLCampaigns) startFlow();
  }, [isSuccessDXLCampaigns, isLoadingDXLCampaigns]);

  useEffect(() => {
    if (isSuccessServiceAbility) {
      const offeringServiceAbility = getFromLocalStorageByKey('offeringServiceability');
      if (!(offeringServiceAbility as IAPIError)?.error) {
        if (AppMobile.checkIsApp()) {
          const updatedIAddConfigAfterService = getIAddConfiguration(products);
          if (!updatedIAddConfigAfterService) {
            // NO PRODUCT FOUND
            errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.SERVICE_ABILITY_ERROR);
            iAddProductNotFound();
          } else {
            setDynamicShoppingCartResponse({
              ...DEFAULT_DYNAMIC_SHOPPING_CART_RESPONSE_SUCCESS,
              flowType: coverageToolFlowPayload?.flowType || '',
              product: updatedIAddConfigAfterService,
            });
          }
        } else {
          successFlow();
        }
      } else {
        // NO PRODUCT FOUND
        errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.SERVICE_ABILITY_ERROR);
      }
    } else if (isErrorServiceAbility) {
      errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.SERVICE_ABILITY_ERROR);
    }
  }, [isSuccessServiceAbility]);

  useEffect(() => {
    if (isSuccessCreatedAddress) {
      serviceAbilityMutate(validateAddressData?.validAddressList?.[0]);
    } else if (isErrorCreatedAddress) {
      errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.CREATED_ADDRESS_ERROR);
    }
  }, [isSuccessCreatedAddress]);

  useEffect(() => {
    if (isSuccessValidateAddress) {
      if (AppMobile.checkIsApp()) {
        if (isAppMock()) {
          setDxlCampaigns(getDxlAppMock());
          startFlow();
        } else {
          startDXLCampaigns();
        }
      } else {
        startFlow();
      }
    } else if (isErrorValidateAddress) {
      errorFlow(ERROR_CODES_DYNAMIC_SHOPPING_CART.VALIDATE_ADDRESS_ERROR);
    }
  }, [isSuccessValidateAddress]);

  useEffect(() => {
    if (
      isLoadingCreatedAddress ||
      isLoadingServiceAbility ||
      isLoadingValidateAddress ||
      isLoadingValidateAddress
    ) {
      setDynamicShoppingCartResponse({
        ...dynamicShoppingCartResponse,
        isLoading: true,
      });
    }
  }, [
    isLoadingCreatedAddress,
    isLoadingServiceAbility,
    isLoadingValidateAddress,
    isLoadingValidateAddress,
  ]);

  useEffect(() => {
    if (coverageToolFlowPayload.flowType) {
      setDynamicShoppingCartResponse({
        ...dynamicShoppingCartResponse,
        flowType: coverageToolFlowPayload?.flowType || '',
        product: coverageToolFlowPayload?.product,
      });
      preconditionFlow();
    }
  }, [coverageToolFlowPayload.flowType]);

  useEffect(() => {
    if (dynamicShoppingCartResponse?.isSuccess) {
      setTimeout(() => {
        if (!checkIsGoBackSIA())
          window.history.pushState({}, document.title, window.location.pathname);
      }, 1000);
    }
  }, [dynamicShoppingCartResponse]);

  useEffect(() => {
    initDynamicShoppingCart();
  }, []);

  return dynamicShoppingCartResponse;
}
