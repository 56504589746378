import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const CardFrame = styled.div`
  margin: ${pxToRem(82, 20)};

  @media (min-width: ${breakpoints.tablet}) {
    margin: ${pxToRem(196, 40)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: ${pxToRem(202, 54)};
  }
`;

export const Title = styled.h2`
  font-family: ${fonts.exbold};
  font-weight: 400;
  font-size: ${pxToRem(35)};
  ${pxToCssFont(35, 38)};
  color: ${colors.$e60000};
  margin: ${pxToRem(0, 0, 16)};

  @media (min-width: ${breakpoints.tablet}) {
    font-size: ${pxToRem(42)};
    ${pxToCssFont(42, 52)};
    margin: ${pxToRem(0, 0, 24)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: ${pxToRem(0, 0, 20)};
  }
`;

export const Subtitle = styled.h3`
  ${pxToCssFont(24, 28)};
  font-family: ${fonts.exbold};
  font-weight: 400;
  margin: ${pxToRem(0, 0, 16)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)};
  }
`;

export const DisplayPriceBox = styled.section`
  border: 1px solid ${colors.$bebebe};
  border-radius: ${pxToRem(5)};
  min-height: ${pxToRem(100)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${pxToRem(20)};

  @media (min-width: ${breakpoints.tablet}) {
    text-align: left;
  }
`;

export const TitlePriceDisplay = styled.h6`
  ${pxToCssFont(18, 24)};
  margin: 0;
`;

export const PricesBox = styled.div`
  align-items: flex-end;
  justify-content: center;
  display: flex;

  @media (min-width: ${breakpoints.tablet}) {
    justify-content: flex-start;
  }
`;

export const Message = styled.div`
  margin: ${pxToRem(16, 0, 0)};

  @media (min-width: ${breakpoints.tablet}) {
    font-size: ${pxToRem(14)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    font-size: ${pxToRem(16)};
  }

  p {
    margin: 0;
  }
`;

