import { useTracking } from '@vfit/shared/data-access';
import { useEffect } from 'react';
import { getUserInfo, getTagging } from '../../../../checkout.utils';
import { useCheckout } from '../../../../../iBuyFixed.context';

export const useVoucherSuccessCardTagging = () => {
  const { voucher } = useCheckout();
  const tagging = getTagging('voucher typ', 'fixed');
  const dataLayer = {
    event: ['checkout_step8.3'],
    event_label: 'voucher applied',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    product_discount_value: voucher?.validateVoucher?.data?.finalPrice,
    transaction_coupon_code: voucher?.voucherId || '',
    visitorTrackingOpts: getUserInfo(),
  };
  const { trackWithNewDatalayer } = useTracking({
    ...dataLayer,
    disableInitialTrack: true,
  });

  useEffect(() => {
    if (voucher?.shoppingCart?.isSuccess) {
      trackWithNewDatalayer({
        ...dataLayer,
      });
    }
  }, [voucher]);

  return null;
};
