import * as yup from 'yup';

// TODO: Re-evaluate validation when error designs are available

export const documentIdSchema = () =>
  yup.object({
    identificationType: yup.string().required(' '),
    identificationNumber: yup
      .string()
      .ensure()
      .when('identificationType', {
        is: "Carta d'identità",
        then: yup
          .string()
          .required(' ')
          .matches(
            /^[a-zA-Z]{2}[0-9]{5}([0-9]{2}||[a-zA-Z]{2})$/,
            'Inserire un numero di documento valido'
          )
          .min(9, 'Inserire un numero di documento valido'),
        otherwise: yup
          .string()
          .required(' ')
          .matches(/^[a-zA-Z0-9]*$/, 'Inserire un numero di documento valido')
          .min(6, 'Inserire un numero di documento valido')
          .max(15, 'Inserire un numero di documento valido'),
      }),

    expirationDate: yup
      .string()
      .required(' ')
      .test('rangeTest', 'Inserire una data di scadenza valida', (value) => {
        if (value?.includes('_') === false) {
          if (value.match(/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/)) {
            const today: Date = new Date();
            const dmy = value.split('/');
            const selectedDate = new Date(
              parseInt(dmy[2], 10),
              parseInt(dmy[1], 10) - 1,
              parseInt(dmy[0], 10)
            );
            const yesterday: Date = new Date();
            yesterday.setDate(today.getDate() - 1);
            const tenYears: Date = new Date();
            tenYears.setFullYear(today.getFullYear() + 10);
            if (selectedDate > yesterday && selectedDate <= tenYears) return true;
            return false;
          }
          return false;
        }
        return false;
      }),
    nationality: yup.string().required(' '),
  });
