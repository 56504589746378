import { useEffect, useState } from 'react';
import { errorManager, ErrorService, resetData } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import {
  checkIsApp,
  CMS_CONFIG,
  errorMock,
  handleFirstCharacterOfSentence,
  IPersonalInfoCMS,
  IProduct,
  LOCK_IN_IS_ACCEPTED_FROM_HUB,
  LOCKIN_OFFER_COOKIE,
  setPersistData,
  useCampaignAccept,
  useCmsConfig,
  useGetShoppingCartSilentUpdate,
} from '@vfit/consumer/data-access';
import { VfModal } from '@vfit/shared/components';
import { ButtonSlide } from '@vfit/shared/atoms';
import { API, openPopup, PAGES, setItemCookie, trackView } from '@vfit/shared/data-access';
import {
  coverageToolLockInMessage,
  getAddress,
  getTrackError,
} from '../../coverageToolModal.utils';
import { ICommonData } from '../../../StepperStateCards/stepperStateCards.models';
import Otp from '../../../Otp/otp';
import { useCoverageToolModalProvider } from '../../coverageToolModal.context';
import {
  ButtonDiv,
  EditAddress,
  ModalDescription,
  ModalTitle,
  ModalWrap,
} from './coverageToolOtpCard.style';
import { IModalConfig } from '../../coverageToolModal.models';
import { checkLockInOffer, ICheckLockin } from './coverageToolOtpCard.utils';
import { organizeOtp } from '../../../Otp/otp.utils';
import { useCoverageToolOtpTagging } from './coverageToolOtpCard.tagging';
import { useCoverageToolOtp } from './coverageToolOtpCard.hook';

const CoverageToolOtpCard = ({
  handleGoNextSlide,
  handleGoPrevSlide,
  handleOnClose,
}: ICommonData) => {
  const queryClient = useQueryClient();
  const { coverageLockInUser, findCaller, product, setIsDisabledBackButton, setSlidesGoBack } =
    useCoverageToolModalProvider();
  const [acceptOrderId, setAcceptOrderId] = useState<string>('');
  const [isLoadingLockIn, setIsLoadingLockIn] = useState<boolean>(false);
  const {
    resetCoverageToolOtpCardFlow,
    onVerifyOtp,
    onGenerateOtp,
    generateOtp,
    verifyOtp,
    findCaller: findCallerPostOtp,
    customer,
    campaigns,
    checkEligibility,
  } = useCoverageToolOtp();
  const {
    data: acceptData,
    error: acceptError,
    isLoading: isLoadingAccept,
    isSuccess: isSuccessAccept,
    isError: isErrorAccept,
    mutate: mutateAccept,
  } = useCampaignAccept();
  const {
    error: getShoppingCartError,
    isLoading: isLoadingShoppingCart,
    isSuccess: isSuccessShoppingCart,
    isError: isErrorShoppingCart,
    refetch: refetchShoppingCart,
  } = useGetShoppingCartSilentUpdate(acceptOrderId, {
    enabled: false,
    keyDependency: ['getShoppingCartLockIn'],
  });

  useCoverageToolOtpTagging();

  const isLoading =
    generateOtp?.isLoading ||
    verifyOtp?.isLoading ||
    findCallerPostOtp?.isLoading ||
    customer?.isLoading ||
    campaigns?.isLoading;
  const [isError, setIsError] = useState(false);
  const [isCheckLockin, setIsCheckLockin] = useState(false);
  const INIT_MODAL_CONFIG: IModalConfig = {
    isOpen: false,
    title: '',
    description: '',
    subDesc: undefined,
    firstActionLabel: '',
    firstAction: () => {},
    secondActionLabel: '',
    secondAction: () => {},
  };
  const [modalConfig, setModalConfig] = useState<IModalConfig>(INIT_MODAL_CONFIG);

  const { errorNoCoverage, errorNoOffer } = coverageToolLockInMessage();

  const dataFromCms = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA
  ) as IPersonalInfoCMS;
  const configOTP = organizeOtp(
    dataFromCms,
    findCaller?.selectedContactMedium?.fieldType === 'msisdn',
    findCaller?.selectedContactMedium
  );

  const onClickEditNumber = () => {
    setModalConfig({ ...INIT_MODAL_CONFIG });
    sessionStorage.removeItem('uxfauthorization');
    if (handleGoPrevSlide) handleGoPrevSlide();
  };

  const onClickEditAddress = () => {
    setModalConfig({ ...INIT_MODAL_CONFIG });
    sessionStorage.removeItem('uxfauthorization');
    setTimeout(() => {
      setSlidesGoBack(3);
    }, 200);
  };

  const onClickDiscover = () => {
    if (handleGoNextSlide) {
      setPersistData();
      handleGoNextSlide({}, true);
    }
  };

  const onClickCallMeNow = () => {
    if (product?.callMeNowHelp?.url) openPopup(product?.callMeNowHelp?.url);
  };

  const handleSetError = () => {
    setIsError(false);
  };

  const handleRedirect = () => {
    const checkOffer = checkLockInOffer(product as IProduct, checkEligibility?.lockInProduct);
    const address = getAddress();
    const isApp = localStorage.getItem('isApp');
    switch (checkOffer) {
      case ICheckLockin.NO_OFFER_FOUND:
        trackView({
          event_name: 'journey_end',
          event_label_track: 'lock-in not available for address',
          event_category: 'journey',
          page_type: 'ko',
          tracking_type: 'view',
          journey_name: 'verify lock-in',
          page_subsection: 'lock-in match result',
        });
        if (isApp) {
          setModalConfig({
            isOpen: true,
            title: errorNoOffer.title,
            description: errorNoOffer.description,
            firstActionLabel: errorNoOffer.editLabel,
            firstAction: onClickEditNumber,
            secondActionLabel: errorNoOffer.submitLabel,
            secondAction: onClickDiscover,
          });
        } else {
          setItemCookie(
            LOCKIN_OFFER_COOKIE,
            {
              firstName: coverageLockInUser?.owningIndividual?.firstName || '',
              lastName: coverageLockInUser?.owningIndividual?.lastName || '',
              fiscalCode: coverageLockInUser?.owningIndividual?.fiscalCode || '',
            },
            0.021,
            false,
            'privati.vodafone.it'
          );
          setPersistData();
          handleGoNextSlide?.({}, true);
        }
        break;
      case ICheckLockin.GO_TO_OFFER:
        if (handleGoNextSlide) {
          setPersistData();
          handleGoNextSlide({}, true);
        }
        break;
      case ICheckLockin.NO_COVERAGE:
        trackView({
          event_name: 'journey_end',
          event_label_track: 'lock-in not available for address',
          event_category: 'journey',
          page_type: 'ko',
          tracking_type: 'view',
          journey_name: 'verify lock-in',
          page_subsection: 'lock-in match result',
        });
        setModalConfig({
          isOpen: true,
          onCloseAction: handleOnClose,
          title: errorNoCoverage.title,
          description: errorNoCoverage.description,
          ...(address?.displayedAddress && {
            subDesc: (
              <div>
                <h4>
                  <b>Indirizzo</b>
                </h4>
                <p>{handleFirstCharacterOfSentence(address?.displayedAddress || '')}</p>
              </div>
            ),
          }),
          firstActionLabel: errorNoCoverage.editLabel,
          firstAction: onClickEditAddress,
          secondActionLabel: errorNoCoverage.submitLabel,
          secondAction: onClickCallMeNow,
        });
        break;
      default:
        break;
    }
  };

  const checkLockin = () => {
    if (checkEligibility?.eligible && checkEligibility?.lockInProduct) {
      setIsLoadingLockIn(true);
      mutateAccept({
        campaignId: checkEligibility.lockInOffer?.id || '',
        sessionId: checkEligibility.lockInOffer?.sessionId || '',
      });
    } else {
      handleRedirect();
    }
  };

  useEffect(() => {
    if (isErrorShoppingCart) {
      const goBackAndReset = () => {
        setTimeout(() => {
          setSlidesGoBack(3);
        }, 200);
      };
      setIsLoadingLockIn(false);
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: 'Ops!',
        message: 'Si è verificato un errore. Riprova in seguito',
        onClose: goBackAndReset,
        actionEvent: goBackAndReset,
        actionText: 'Chiudi',
        trackError: getTrackError(errorMock(`shoppingCart`, getShoppingCartError)),
      });
    } else if (isSuccessShoppingCart) {
      setIsLoadingLockIn(false);
      localStorage.setItem(LOCK_IN_IS_ACCEPTED_FROM_HUB, '1');
      handleRedirect();
    }
  }, [isSuccessShoppingCart, isErrorShoppingCart, isLoadingShoppingCart]);

  useEffect(() => {
    if (acceptOrderId) refetchShoppingCart();
  }, [acceptOrderId]);

  useEffect(() => {
    if (isErrorAccept) {
      const goBackAndReset = () => {
        setTimeout(() => {
          setSlidesGoBack(3);
        }, 200);
      };
      setIsLoadingLockIn(false);
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: 'Ops!',
        message: 'Si è verificato un errore. Riprova in seguito',
        onClose: goBackAndReset,
        actionEvent: goBackAndReset,
        actionText: 'Chiudi',
        trackError: getTrackError(errorMock(`campaignAccept`, acceptError)),
      });
    } else if (isSuccessAccept) {
      setAcceptOrderId(acceptData?.orderId || '');
    }
  }, [isSuccessAccept, isErrorAccept, isLoadingAccept]);

  useEffect(() => {
    if (isCheckLockin) checkLockin();
  }, [isCheckLockin]);

  useEffect(() => {
    if (campaigns?.isSuccess && checkEligibility) {
      setIsCheckLockin(true);
    }
  }, [campaigns]);

  useEffect(() => {
    setIsDisabledBackButton(isLoadingLockIn);
  }, [isLoadingLockIn]);

  useEffect(
    () => () => {
      resetCoverageToolOtpCardFlow();
      resetData(queryClient, [
        'findCaller',
        'customerReference',
        'generateOTP',
        'verifyOTP',
        'campaignsData',
        'customerData',
        'recognizedUser',
        'lockinOfferFound',
      ]);
    },
    []
  );

  return (
    <>
      <Otp
        product={product as IProduct}
        contactMedium={findCaller?.selectedContactMedium}
        isError={isError}
        isLoading={isLoading}
        reSendOtp={onGenerateOtp}
        onVerify={onVerifyOtp}
        setIsError={handleSetError}
        configOtp={configOTP}
        showLoaderCard={isLoading || isLoadingLockIn || isSuccessShoppingCart}
      />
      <VfModal
        isOpen={modalConfig.isOpen}
        handleClose={() => {
          setModalConfig({ ...INIT_MODAL_CONFIG });
          if (modalConfig.onCloseAction) {
            modalConfig.onCloseAction();
          }
        }}
      >
        <ModalWrap>
          <div>
            <ModalTitle>{modalConfig.title}</ModalTitle>
            <ModalDescription>{modalConfig.description}</ModalDescription>
            {modalConfig.subDesc && (
              <div>
                {modalConfig.subDesc}
                <EditAddress onClick={modalConfig.firstAction}>Modifica indirizzo</EditAddress>
              </div>
            )}
          </div>
          <ButtonDiv>
            {!modalConfig.subDesc && modalConfig.firstActionLabel && modalConfig.firstAction && (
              <ButtonSlide
                label={modalConfig.firstActionLabel}
                onClick={modalConfig.firstAction}
                isApp={checkIsApp()}
                borderColor="#262626"
                labelColor="#262626"
                hoverColor="#262626"
                hoverTextColor="#fff"
                clickColor="#7e7e7e"
                clickTextColor="#fff"
                name="action_first_coverageToolOtp"
              />
            )}
            {modalConfig.secondActionLabel && modalConfig.secondAction && (
              <ButtonSlide
                label={modalConfig.secondActionLabel}
                onClick={modalConfig.secondAction}
                isApp={checkIsApp()}
                borderColor={modalConfig.subDesc ? '#262626' : ''}
                buttonColor={modalConfig.subDesc ? '#fff' : '#e60000'}
                labelColor={modalConfig.subDesc ? '#262626' : '#fff'}
                hoverColor={modalConfig.subDesc ? '#262626' : '#bd0000'}
                hoverTextColor={modalConfig.subDesc ? '#fff' : '#fff'}
                clickColor={modalConfig.subDesc ? '#7e7e7e' : '#a10000'}
                clickTextColor={modalConfig.subDesc ? '#fff' : '#fff'}
                name="action_second_coverageToolOtp"
              />
            )}
          </ButtonDiv>
        </ModalWrap>
      </VfModal>
    </>
  );
};

export default CoverageToolOtpCard;
