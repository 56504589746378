import { useEffect, useState } from 'react';
import { checkWindow, getButtonActionByActionType, setItemCookie } from '@vfit/shared/data-access';
import {
  IProduct,
  PARTNER_OFFER_COOKIE,
  TOKEN_PARTNER_OFFER,
  usePartnerOfferValidate,
} from '@vfit/consumer/data-access';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';

interface IUsePartnerOfferFlow {
  product: IProduct;
}

export function usePartnerOfferFlow({ product }: IUsePartnerOfferFlow) {
  const { push } = useRouter();
  const [tokenPartnerOffer, setTokenPartnerOffer] = useState<string>('');
  const isPartnerOffer = product?.partnerOffer?.isPartnerOffer;
  const errorPartnerOffer = product?.partnerOffer?.errorPartnerOffer;
  const {
    data: partnerOfferData,
    isError: isErrorPartnerOffer,
    isSuccess: isSuccessPartnerOffer,
    isLoading: isLoadingPartnerOffer,
  } = usePartnerOfferValidate(tokenPartnerOffer, { enabled: !!tokenPartnerOffer });

  const manageErrorPartnerOffer = () => {
    const redirectHomePage = () => {
      window.location.href = '/';
    };
    errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
      title: errorPartnerOffer?.title || 'Ops',
      message:
        errorPartnerOffer?.description || 'Si è verificato un problema. Riprovare in seguito',
      actionText: errorPartnerOffer?.action?.title || 'Chiudi',
      fullscreen: true,
      disableCloseModalOnClickAction: true,
      onClose: redirectHomePage,
      actionEvent: () => {
        if (errorPartnerOffer?.action) getButtonActionByActionType(errorPartnerOffer.action, push);
        else redirectHomePage();
      },
    });
  };

  const checkIsPartnerOffer = () => {
    if (!checkWindow()) {
      return;
    }
    if (!isPartnerOffer) return;
    const queryString = new URLSearchParams(window?.location?.search);
    const token = queryString?.get(TOKEN_PARTNER_OFFER)?.replace(/ /g, '+');
    if (!token) {
      manageErrorPartnerOffer();
    } else {
      setTokenPartnerOffer(token);
    }
  };

  useEffect(() => {
    if (isSuccessPartnerOffer) {
      setItemCookie(PARTNER_OFFER_COOKIE, partnerOfferData, 0.021, false, 'privati.vodafone.it');
    }
  }, [isSuccessPartnerOffer]);

  useEffect(() => {
    if (isErrorPartnerOffer) {
      manageErrorPartnerOffer();
    }
  }, [isErrorPartnerOffer]);

  useEffect(() => {
    checkIsPartnerOffer();
  }, []);

  return {
    partnerOfferData,
    isErrorPartnerOffer,
    isSuccessPartnerOffer,
    isLoadingPartnerOffer,
  };
}
