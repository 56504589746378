export const getRecognitionPrice = (price: number) => {
  let shippingPriceParsed = price?.toString()?.replace('.', ',');
  if (shippingPriceParsed && shippingPriceParsed != '0') {
    const splitted = shippingPriceParsed.split(',');
    if (splitted?.length > 0 && splitted?.[1]?.length === 1) {
      shippingPriceParsed = `${splitted[0]},${splitted[1]}0`;
    }
  }
  return shippingPriceParsed == '0' ? 0 : shippingPriceParsed;
};
