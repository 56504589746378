import * as yup from 'yup';

export const chooseOperatorSchema = () =>
  yup.object({
    operator: yup.string().required('Campo Obbligatorio'),
    migrationCode: yup
      .string()
      .required('Campo Obbligatorio')
      .min(8, 'codice di migrazione non valido')
      .max(20, 'codice di migrazione non valido'),
  });
