import { useEffect } from 'react';
import { Portal } from '@vfit/shared/components';
import { usePage, CMS_TOOL_START } from '@vfit/consumer/data-access';
import { OverlayGlobalStyle } from './coverageToolModal.style';
import CoverageToolModalContent from './coverageToolModalContent';
import { ICoverageToolModalProps } from './coverageToolModal.models';
import { CoverageToolModalProvider } from './coverageToolModal.context';

const CoverageToolModalContainer = ({
  isOpen,
  handleClose,
  backgroundImage,
  product: coverageProduct,
  customRedirect,
}: ICoverageToolModalProps) => {
  const { products: allProducts } = usePage();
  const foundedProduct = allProducts?.find((el) => el?.cmsId === coverageProduct?.cmsId);
  const product = foundedProduct || coverageProduct;

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' && handleClose ? handleClose() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if (isOpen && product) {
      localStorage.setItem('tProd', JSON.stringify(product));
      localStorage.setItem(CMS_TOOL_START, product?.cmsId || '');
    }
  }, [isOpen]);

  return isOpen ? (
    <Portal wrapperId="coverage-tool">
      <>
        <OverlayGlobalStyle />
        <CoverageToolModalProvider>
          <CoverageToolModalContent
            backgroundImage={backgroundImage}
            product={product}
            customRedirect={customRedirect}
            handleClose={handleClose}
          />
        </CoverageToolModalProvider>
      </>
    </Portal>
  ) : null;
};

const CoverageToolModal = (props: ICoverageToolModalProps) => {
  const { isOpen } = props;
  if (!isOpen) return null;
  return <CoverageToolModalContainer {...props} />;
};

export default CoverageToolModal;
