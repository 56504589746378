import {
  IDeviceItem,
  IDevices,
  ISlides,
  ITechCard,
  ITechnology,
  TechnologyCategories,
} from '@vfit/consumer/data-access';
import { ITechnologyDetailsProps } from './technologyCards.models';

export const organizeTechnologyCardModal = (cmsTechConfig: ITechCard) => {
  const dataToReturn: ITechnologyDetailsProps = {
    title: cmsTechConfig?.technology?.card?.title || '',
    subtitle: cmsTechConfig?.technology?.detail?.subtitle || '',
    firstDescription: cmsTechConfig?.technology?.detail?.description?.textSimple || '',
    secondDescription: cmsTechConfig?.technology?.detail?.description?.textHtml || '',
    imageSrc: cmsTechConfig?.technology?.detail?.description?.image || '',
    accordions: cmsTechConfig?.technology?.detail?.accordion?.map((accordion) => ({
      description: accordion?.description || '',
      title: accordion?.title || '',
    })),
  };

  return dataToReturn;
};

export const organizeTechCardsProductDetail = (
  aemTechnologyData: ITechCard | undefined,
  tecnologyMappedCategory: string,
  devices: IDevices
): ISlides[] => {
  const filteredDevices: ISlides[] = [];

  const organizeItem = (device: ITechnology) => ({
    title: device?.card?.title || '',
    description: device?.card?.description || '',
    // eslint-disable-next-line no-unsafe-optional-chaining
    id: +device?.id,
    image: device?.card?.image || '',
    showInfoLine: false,
    action: {
      title: device?.card?.buttonLabel || '',
      descriptionModal: device?.detail?.description?.textHtml || '',
    },
    detail: device.detail,
  });

  if (aemTechnologyData) {
    filteredDevices.push({
      title: aemTechnologyData?.technology?.card?.title || '',
      description: aemTechnologyData?.technology?.card?.description || '',
      // eslint-disable-next-line no-unsafe-optional-chaining
      id: +aemTechnologyData?.technology?.id,
      image: aemTechnologyData?.technology?.card?.image || '',
      showInfoLine: true,
      action: {
        title: aemTechnologyData?.technology?.card?.buttonLabel || '',
        descriptionModal: aemTechnologyData?.technology?.detail?.description?.textHtml || '',
      },
    });
  }

  Object.keys(devices).forEach((keyDevice) => {
    const deviceItem: IDeviceItem = devices[keyDevice];
    switch (tecnologyMappedCategory) {
      case TechnologyCategories.FTTH:
        if (deviceItem.device?.visibility?.showInFtth === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      case TechnologyCategories.FTTC:
        if (deviceItem.device?.visibility?.showInFttc === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      case TechnologyCategories.ADSL:
        if (deviceItem.device?.visibility?.showInAdsl === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      case TechnologyCategories.FWAINDOOR:
        if (deviceItem.device?.visibility?.showInFwaIndoor === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      case TechnologyCategories.FWAOUTDOOR:
        if (deviceItem.device?.visibility?.showInFwaOutdoor === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      case TechnologyCategories.FWAOUTDOOR5G:
        if (deviceItem.device?.visibility?.showInFwaOutdoor5G === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      case TechnologyCategories.FTTHTIM:
        if (deviceItem.device?.visibility?.showInFtthTim === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
        case TechnologyCategories.FWAINDOOR5G:
        if (deviceItem.device?.visibility?.showInFwaIndoor5G === 'true') {
          filteredDevices.push(organizeItem(deviceItem.device));
        }
        break;
      default:
        break;
    }
  });

  return filteredDevices;
};
