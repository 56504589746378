import { purify } from '@vfit/shared/themes';
import { purifyTextWithLogo, setTrackLink, trackLink } from '@vfit/shared/data-access';
import { Button, ImageAtoms, LinkWithIcon } from '@vfit/shared/atoms';
import { useTracking } from '@vfit/shared/data-access';
import { SoftManagerService } from '@vfit/consumer/providers';
import { useQueryClient } from 'react-query';
import { ChevronRight } from '@vfit/shared-icons';
import { IQROverlay } from './qROverlay.models';
import {
  Description,
  QRCode,
  Title,
  Image,
  OverlayContainer,
  ButtonContainer,
} from './qrOverlay.style';

const QROverlay = ({
  title,
  description,
  qrImage,
  overlayImage,
  buttonLabel,
  anchorLabel,
  onAnchorClick,
  linkToCopy,
}: IQROverlay) => {
  const [pTitle, pDescription] = purify([title, description], purifyTextWithLogo);
  const queryClient = useQueryClient();

  const opts = {
    event_category: 'sales',
    page_subsection: 'sim activation',
    page_section: 'vr',
    page_type: 'snodo desktop o mobile',
    journey_name: 'sim activation',
  };

  const { trackView } = useTracking({
    event: ['journey_start', 'view'],
    event_label: 'snodo',
    opts,
    visitorTrackingOpts: SoftManagerService(queryClient).getUser(),
  });
  setTrackLink(trackView);

  const handleClose = () => {
    onAnchorClick && onAnchorClick();
    anchorLabel && trackLink(anchorLabel);
  };

  const handleButtonClick = () => {
    linkToCopy && navigator.clipboard.writeText(linkToCopy);
    buttonLabel && trackLink(buttonLabel);
  };

  return (
    <OverlayContainer>
      <Image>
        <ImageAtoms nameCard="overlayImage" image={overlayImage} />
      </Image>
      {pTitle && <Title dangerouslySetInnerHTML={{ __html: pTitle }} />}
      {pDescription && <Description dangerouslySetInnerHTML={{ __html: pDescription }} />}
      {qrImage && (
        <QRCode>
          <ImageAtoms nameCard="appDownload" image={qrImage} />
        </QRCode>
      )}
      <ButtonContainer>
        <Button variant="mva:alt1" onClick={handleButtonClick}>
          {buttonLabel}
        </Button>
      </ButtonContainer>
      {anchorLabel && <LinkWithIcon text={anchorLabel} onClick={handleClose} color="#0096AE" variant='vr' icon={<ChevronRight />} />}
    </OverlayContainer>
  );
};

export default QROverlay;

