import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  errorMock,
  IShoppingCartResponse,
  ProductSpecificationProductCharacteristic,
  IMultiPlayOfferingConfigurationResponse,
  ICheckPortInFeasibilityIIMutationPayload,
  IPortInDetailsMobile,
} from '@vfit/consumer/data-access';
import { IOfferingServiceabilityResponse, IPortabilityObject } from '@vfit/shared/models';
import { adaptSelectedValue, retrieveMSDIN, retrieveProvider } from './portabilityNumber.utils';
import {
  IModifyPhoneNumberMobileProductPayload,
  IModifyPhoneNumberProductPayload,
} from './modifyPhoneNumberProduct.models';

/**
 * API service to validate the portability number process
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
export const modifyPhoneNumberProductService = async (
  cartId: string,
  payload: IModifyPhoneNumberProductPayload,
  customOptions?: CustomOptions
): Promise<IShoppingCartResponse> =>
  nextClient.post(`${API.SHOPPING_CART}/${cartId}/${API.MODIFY_PHONE_NUMBER_PRODUCT}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * API service to validate the portability number mobile process
 * @param cartId
 * @param payload
 * @returns
 */
export const modifyPhoneNumberMobileProductService = async (
  cartId: string,
  payload: IModifyPhoneNumberMobileProductPayload
): Promise<void> =>
  nextClient.post(`${API.SHOPPING_CART}/${cartId}/${API.MODIFY_PHONE_NUMBER_PRODUCT}`, payload);

/**
 * Mutation for saving into the key 'modifyPhoneNumberProductQuery'
 * the response coming from the API service modifyPhoneNumberProduct
 * to validate the portability number process
 * @returns
 * @deprecated
 */
export const useModifyPhoneNumberProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'modifyPhoneNumberProductMutation',
    (data: ICheckPortInFeasibilityIIMutationPayload) => {
      const { values, providers, isMultipleOperator } = data;

      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const offeringServiceability: IOfferingServiceabilityResponse =
        getFromLocalStorageByKey('offeringServiceability');
      const multiPlayOfferingConfiguration: IMultiPlayOfferingConfigurationResponse =
        getFromLocalStorageByKey('multiPlayOfferingConfiguration');

      const cartId = shoppingCart?.id;

      const firstServiceProviders =
        !!multiPlayOfferingConfiguration &&
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails[0]
          .r1MissingMigrationFixedPortInSpecification.r1FirstDonatingNetworkOperator
          .availableValues;
      const secondServiceProviders =
        !!multiPlayOfferingConfiguration &&
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails[0]
          .r1MissingMigrationFixedPortInSpecification.r1SecondDonatingNetworkOperator
          .availableValues; // .filter((item:any)=> {item})//.value === 'TLC'})

      const firstProvider =
        firstServiceProviders &&
        retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
      const secondProvider =
        secondServiceProviders &&
        retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);

      // const isMultipleOperator = values?.portabilityOperators || false;
      const productCharacteristic: ProductSpecificationProductCharacteristic[] | undefined =
        shoppingCart && shoppingCart?.cartItem[0]?.fixedVoice[0]?.product?.productCharacteristic;
      const defMSDIN = productCharacteristic && retrieveMSDIN(productCharacteristic);

      const payload: IModifyPhoneNumberProductPayload = {
        fixedPortInSpecification: {
          portIndicator: true,
        },
        internetPortInSpecification: {
          portIndicator: false,
        },
        r1FixedPortInSpecification: {
          r1PhoneNumber: values?.cli,
          r1IsInternetIndicator: values?.hasInternetService,
          r1FirstDonatingNetworkOperator: {
            selectedValue: firstProvider,
          },
          ...(values?.firstMigrationCode && {
            r1FirstDonatingNetworkOperatorCode: {
              selectedValue: values?.firstMigrationCode,
            },
          }),
          ...(providers?.secondSelectedProvider && {
            r1SecondDonatingNetworkOperator: {
              selectedValue: secondProvider, /// commerce/v1/multiPlayOfferingConfiguration/{cart_item_id} |fixedVoicePortInDetails[0].r1MissingMigrationFixedPortInSpecification.r1SecondDonatingNetworkOperator.availableValues.find(value)
            },
            ...(values?.firstMigrationCode && {
              r1SecondDonatingNetworkOperatorCode: {
                selectedValue: isMultipleOperator
                  ? values?.secondMigrationCode
                  : values?.firstMigrationCode,
              },
            }),
          }),
        },
        serviceabilityId: offeringServiceability?.id, // "8893111", //commerce/v1/offeringServiceability | id ( offeringServiceability of check out)
        fixedCartItemId: shoppingCart?.cartItem[0]?.fixedVoice[0]?.id, // commerce/v1/shoppingCart | cartItem[0].fixedVoice[0].id
        internetCartItemId: shoppingCart?.cartItem[0]?.internet[0]?.id, // commerce/v1/shoppingCart | cartItem[0].internet[0].id
        defaultMsisdn: defMSDIN,
      };

      return modifyPhoneNumberProductService(cartId, payload);
    },
    {
      onSuccess: (data: IShoppingCartResponse) => {
        // save modifyPhoneNumberProductQuery object into storage
        localStorage.setItem('modifyPhoneNumberProductQuery', JSON.stringify(data));
        queryClient.setQueryData('modifyPhoneNumberProductQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - modifyPhoneNumberProductQuery: `, error);
        queryClient.setQueryData(
          'modifyPhoneNumberProductQuery',
          errorMock(`modifyPhoneNumberProductQuery`, error, 'KO')
        );
        localStorage.setItem(
          `modifyPhoneNumberProductQuery`,
          JSON.stringify(errorMock(`modifyPhoneNumberProductQuery`, error, 'KO'))
        );
      },
    }
  );
};

export const useModifyPhoneNumberFixedProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'modifyPhoneNumberProductMutation',
    ({
      hasInternetService,
      isMultipleOperator,
      portabilityNumber,
      portabilityMigration,
    }: IPortabilityObject) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const offeringServiceability: IOfferingServiceabilityResponse =
        getFromLocalStorageByKey('offeringServiceability');
      const multiPlayOfferingConfiguration: IMultiPlayOfferingConfigurationResponse =
        getFromLocalStorageByKey('multiPlayOfferingConfiguration');
      const cartId = shoppingCart?.id;

      const firstServiceProviders =
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
          ?.r1MissingMigrationFixedPortInSpecification?.r1FirstDonatingNetworkOperator
          ?.availableValues;
      const secondServiceProviders =
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
          ?.r1MissingMigrationFixedPortInSpecification?.r1SecondDonatingNetworkOperator
          .availableValues;

      const productCharacteristic: ProductSpecificationProductCharacteristic[] | undefined =
        shoppingCart?.cartItem[0]?.fixedVoice[0]?.product?.productCharacteristic;
      const defMSDIN = productCharacteristic && retrieveMSDIN(productCharacteristic);
      // const isMultipleOperator =
      //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]?.r1MissingMigrationFixedPortInSpecification?.r1VoiceAndInternetDifferentProvider?.toLowerCase() ===
      //     'yes' || false;
      // const hasInternetService =
      //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
      //     ?.r1MissingMigrationFixedPortInSpecification?.r1IsInternetIndicator === 'Yes';
      const providers = {
        firstSelectedProvider: portabilityMigration?.firstOperator,
        // eslint-disable-next-line no-nested-ternary
        ...(hasInternetService && isMultipleOperator
          ? { secondSelectedProvider: portabilityMigration?.secondOperator }
          : hasInternetService && !isMultipleOperator
          ? { secondSelectedProvider: portabilityMigration?.firstOperator }
          : {}),
      };
      const firstProvider =
        firstServiceProviders &&
        retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
      const secondProvider =
        secondServiceProviders &&
        retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);

      const payload: IModifyPhoneNumberProductPayload = {
        fixedPortInSpecification: {
          portIndicator: true,
        },
        internetPortInSpecification: {
          portIndicator: false,
        },
        r1FixedPortInSpecification: {
          r1PhoneNumber: portabilityNumber,
          r1IsInternetIndicator: hasInternetService ? 'Yes' : 'No',
          r1FirstDonatingNetworkOperator: {
            selectedValue: firstProvider,
          },
          ...(portabilityMigration?.firstCode && {
            r1FirstDonatingNetworkOperatorCode: {
              selectedValue: portabilityMigration?.firstCode,
            },
          }),
          ...(providers?.secondSelectedProvider && {
            r1SecondDonatingNetworkOperator: {
              selectedValue: secondProvider,
            },
            ...(portabilityMigration?.firstCode && {
              r1SecondDonatingNetworkOperatorCode: {
                selectedValue: isMultipleOperator
                  ? portabilityMigration?.secondCode
                  : portabilityMigration?.firstCode,
              },
            }),
          }),
        },
        serviceabilityId: offeringServiceability?.id,
        fixedCartItemId: shoppingCart?.cartItem[0]?.fixedVoice[0]?.id,
        internetCartItemId: shoppingCart?.cartItem[0]?.internet[0]?.id,
        defaultMsisdn: defMSDIN,
      };

      return modifyPhoneNumberProductService(cartId, payload);
    },
    {
      onSuccess: (data: IShoppingCartResponse) => {
        // save modifyPhoneNumberProductQuery object into storage
        localStorage.setItem('modifyPhoneNumberProductQuery', JSON.stringify(data));
        queryClient.setQueryData('modifyPhoneNumberProductQuery', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - modifyPhoneNumberProductQuery: `, error);
        queryClient.setQueryData(
          'modifyPhoneNumberProductQuery',
          errorMock(`modifyPhoneNumberProductQuery`)
        );
        localStorage.setItem(
          `modifyPhoneNumberProductQuery`,
          JSON.stringify(errorMock(`modifyPhoneNumberProductQuery`))
        );
      },
    }
  );
};

export const useModifyPhoneNumberMobileProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'modifyPhoneNumberProductMutation',
    ({
      msisdn,
      isSimAvailable,
      simCardNumber,
      currentPlanType,
      currentServiceProviderId,
      transferRemainingBalance,
    }: IPortInDetailsMobile) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;

      let payload: IModifyPhoneNumberMobileProductPayload = {
        phoneNumberProduct: {
          isPortIn: true,
          msisdn,
          // "simAvailable" should not be passed for 'modifyPhoneNumberProduct'
          // simAvailable: {
          //   selectedValue: adaptSelectedValue(isSimAvailable),
          // },
          transferRemainingBalance: {
            selectedValue: adaptSelectedValue(transferRemainingBalance),
          },
          portInProviderCust: {
            selectedValue: currentServiceProviderId,
          },
          currentPlan: {
            selectedValue: currentPlanType,
          },
        },
        mobileCartitemId: shoppingCart?.cartItem?.[0]?.id,
        levelOfData: 'minimalData',
      };

      const isSimAvailableBoolean: boolean = JSON.parse(isSimAvailable);
      if (isSimAvailableBoolean) {
        payload = {
          ...payload,
          phoneNumberProduct: {
            ...payload.phoneNumberProduct,
            simNumber: simCardNumber,
          },
        };
      }

      return modifyPhoneNumberMobileProductService(cartId, payload);
    },
    {
      onSuccess: () => {
        LoggerInstance.error(`SUCCESS - modifyPhoneNumberMobileProductQuery: `);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - modifyPhoneNumberMobileProductQuery: `, error);
        queryClient.setQueryData(
          'modifyPhoneNumberProductQuery',
          errorMock(`modifyPhoneNumberProductQuery`)
        );
        localStorage.setItem(
          `modifyPhoneNumberProductQuery`,
          JSON.stringify(errorMock(`modifyPhoneNumberProductQuery`))
        );
      },
    }
  );
};

