import {
  checkBypassApiCallFromSIA,
  checkIsGoBackSIA,
  errorMock,
  getCurrentUserType,
  IUserType,
  useGetCustomer,
  useRetrieveMultiLines,
} from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import { useCheckout } from '../../../iBuyMobile.context';
import { useTermsFlow } from '../UseTermsFlow/useTermsFlow';

export const useCustomerAsyncInformation = () => {
  const {
    termsAndConditions,
    customerId,
    simTypeSelectedOption,
    isStartRecognition,
    customerAsyncInfo,
    setIsStartRecognition,
    setTermsAndConditions,
    setCustomerAsyncInfo,
  } = useCheckout();
  const {
    isSuccess: isSuccessTermsAndConditions,
    isError: isErrorTermsAndConditions,
    isLoading: isLoadingTermsAndConditions,
  } = useTermsFlow(termsAndConditions, checkBypassApiCallFromSIA(), true, !!customerId);
  const {
    error: retrieveMultilinesError,
    isSuccess: isSuccessRetrieveMultilines,
    isError: isErrorRetrieveMultilines,
    isLoading: isLoadingRetrieveMultilines,
    mutate: retrieveMultilinesMutation,
  } = useRetrieveMultiLines();
  const {
    data: customerInfoData,
    isSuccess: isSuccessCustomerInfo,
    isLoading: isLoadingCustomerInfo,
    isError: isErrorCustomerInfo,
    error: customerInfoError,
    refetch: refetchCustomerInfo,
  } = useGetCustomer(customerId, { enabled: false });

  useEffect(() => {
    if (isStartRecognition) setIsStartRecognition(false);
  }, [isStartRecognition]);

  useEffect(() => {
    if (isLoadingCustomerInfo) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        customerInfo: {
          ...customerAsyncInfo.customerInfo,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorCustomerInfo) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        customerInfo: {
          ...customerAsyncInfo.customerInfo,
          isSuccess: false,
          isLoading: false,
          isError: true,
          error: errorMock('customerInfo', customerInfoError),
        },
      });
    } else if (isSuccessCustomerInfo) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        customerInfo: {
          ...customerAsyncInfo.customerInfo,
          error: undefined,
          data: customerInfoData,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      });
    }
  }, [isErrorCustomerInfo, isLoadingCustomerInfo, isSuccessCustomerInfo]);

  useEffect(() => {
    if (isLoadingRetrieveMultilines) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        retrieveMultilines: {
          ...customerAsyncInfo.retrieveMultilines,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorRetrieveMultilines) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        retrieveMultilines: {
          ...customerAsyncInfo.retrieveMultilines,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('retrieveMultilines', retrieveMultilinesError),
        },
      });
    } else if (isSuccessRetrieveMultilines) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        retrieveMultilines: {
          ...customerAsyncInfo.retrieveMultilines,
          error: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      });
    }
  }, [isErrorRetrieveMultilines, isLoadingRetrieveMultilines, isSuccessRetrieveMultilines]);

  useEffect(() => {
    if (isLoadingTermsAndConditions) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        termsAndConditions: {
          ...customerAsyncInfo.termsAndConditions,
          error: undefined,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorTermsAndConditions) {
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        termsAndConditions: {
          ...customerAsyncInfo.termsAndConditions,
          isSuccess: false,
          isError: true,
          isLoading: false,
        },
      });
    } else if (isSuccessTermsAndConditions) {
      setTermsAndConditions({
        ...termsAndConditions,
        isSuccessTermsAndConditions,
        isLoadingTermsAndConditions,
        isErrorTermsAndConditions,
      });
      setCustomerAsyncInfo({
        ...customerAsyncInfo,
        termsAndConditions: {
          ...customerAsyncInfo.termsAndConditions,
          error: undefined,
          isError: false,
          isSuccess: true,
          isLoading: false,
        },
      });
    }
  }, [isErrorTermsAndConditions, isLoadingTermsAndConditions, isSuccessTermsAndConditions]);

  useEffect(() => {
    const currentUserType = getCurrentUserType();
    if (customerId && !checkIsGoBackSIA()) {
      if (currentUserType !== IUserType.NEXT_USER_INACTIVE) refetchCustomerInfo();
      retrieveMultilinesMutation();
      if (simTypeSelectedOption) setIsStartRecognition(true);
    }
  }, [customerId]);

  return null;
};
