import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IAPIError } from '@vfit/consumer/data-access';
import { IPayMeanServiceResponse } from '@vfit/shared/models';

export function usePayMeanData(): {
  payMean: IPayMeanServiceResponse | undefined | IAPIError;
} {
  const queryClient = useQueryClient();
  const [payMean, setPayMean] = useState<IPayMeanServiceResponse | undefined | IAPIError>(
    undefined
  );

  const payMeanData: IPayMeanServiceResponse | undefined | IAPIError =
    queryClient.getQueryData('payMean');

  useEffect(() => {
    if (payMeanData) {
      setPayMean(payMeanData);
    }
  }, [!!payMeanData]);

  return { payMean };
}
