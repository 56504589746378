import { errorMock, getFromLocalStorageByKey, ITrackError, resetData } from '@vfit/shared/data-access';
import {
  createPayloadMobile,
  GetCustomerResponse,
  ISilentLoginResponse,
  LOGGED_USER_KEYS,
  useShoppingCartMutation,
  useSimTypeSelectionService,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { useCheckout } from '../../../iBuyMobile.context';
import { IUseDigitalBundleProps } from './useDigitalBundle.models';

export const useDigitalBundle = ({ enabler, product }: IUseDigitalBundleProps) => {
  const { customerFlow, portabilityStatus, setCustomerFlow, setPortabilityStatus } = useCheckout();
  const { paymean } = customerFlow;
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<ITrackError>();
  const queryClient = useQueryClient();
  const silentLogin: ISilentLoginResponse = queryClient.getQueryData(
    'silentLogin'
  ) as ISilentLoginResponse;
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const customerDataList: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
  const customerData = customerDataList?.[0];
  const shoppingCartMutation = useShoppingCartMutation();
  const {
    mutate: mutateSim,
    isError: isErrorSim,
    isSuccess: isSuccessSim,
    error: dataSimError,
  } = useSimTypeSelectionService();

  useEffect(() => {
    if (isSuccessSim) {
      setPortabilityStatus({
        ...portabilityStatus,
        portInData: undefined,
        isLoadingCheck: false,
        isSuccessCheck: false,
        isErrorCheck: false,
        isStartPortIn: false,
      });
      setCustomerFlow({
        ...customerFlow,
        acceptedFallbackOffer: true,
      });
    }
  }, [isSuccessSim]);

  useEffect(() => {
    if (shoppingCartMutation.isSuccess && shoppingCartMutation.data) {
      mutateSim({ cartId: shoppingCartMutation.data.id });
    }
  }, [shoppingCartMutation.isSuccess]);

  useEffect(() => {
    if (enabler && product?.offerId && product?.planId.toString()) {
      const payload = createPayloadMobile(
        product?.offerId,
        product?.planId.toString(),
        isLoggedUser,
        customerData?.id || '',
        product?.isRequiredPortability,
        product?.isRequiredPortability
          ? {
              dummyNumber: product?.portabilityDummyNumber,
              dummyOperator: product?.portabilityDummyOperator,
            }
          : undefined
      );
      if (payload) {
        setCustomerFlow({
          ...customerFlow,
          associateCustomer: {
            isLoading: false,
            isSuccess: false,
            isError: false,
          },
          paymean: {
            isLoading: false,
            isSuccess: false,
            isError: false,
          },
          billingAccount: {
            isLoading: false,
            isSuccess: false,
            isError: false,
          },
        });
        resetData(queryClient, [
          'associateCustomer',
          'paymentData',
          'billingAccount',
          'payMean',
          'updateProductChatacteristics',
          'doUpdateProductCharacteristic',
          'checkPortInFeasibilityII',
        ]);
        shoppingCartMutation.mutate(payload);
        setIsLoading(true);
      } else setIsError(true);
    }
  }, [enabler]);

  useEffect(() => {
    if (shoppingCartMutation.isError) {
      setIsLoading(false);
      setIsError(true);
      setError(errorMock('shoppingCart', shoppingCartMutation.error));
    }
    if (isErrorSim) {
      setIsLoading(false);
      setIsError(true);
      setError(errorMock('simInformation', dataSimError));
    }
  }, [shoppingCartMutation.isError, isErrorSim]);

  useEffect(() => {
    if (customerFlow.associateCustomer.isError) {
      setIsLoading(false);
      setIsError(true);
      setError(customerFlow.associateCustomer.error);
    }
    if (customerFlow.payment.isError) {
      setIsLoading(false);
      setIsError(true);
      setError(customerFlow.payment.error);
    }
    if (customerFlow.billingAccount.isError) setIsLoading(false);
    if (customerFlow.paymean.isError) {
      setIsLoading(false);
      setIsError(true);
      setError(customerFlow.paymean.error);
    }
    if (customerFlow.updateProductChatacteristics.isError) {
      setIsLoading(false);
      setIsError(true);
      setError(customerFlow.updateProductChatacteristics.error);
    }
  }, [
    customerFlow.associateCustomer.isError,
    customerFlow.payment.isError,
    customerFlow.billingAccount.isError,
    customerFlow.paymean.isError,
    customerFlow.updateProductChatacteristics.isError,
  ]);

  useEffect(() => {
    if (shoppingCartMutation.isSuccess && isSuccessSim && paymean.isSuccess) {
      setIsLoading(false);
      setIsSuccess(true);
      setCustomerFlow({
        ...customerFlow,
        acceptedFallbackOffer: false,
      });
    }
  }, [shoppingCartMutation, isSuccessSim, paymean.isSuccess]);

  return {
    isError,
    isLoading,
    isSuccess,
    error,
  };
};
