import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonSlide } from '@vfit/shared/atoms';
import { Form } from '@vfit/shared/components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  calculateFiscalCode,
  Gender,
  getForeignCountries,
  getItalianMunicipalities,
  ICountry,
} from '@vfit/shared/data-access';
import { useFormExtended } from '@vfit/consumer/hooks';
import { errorManager, ErrorService } from '@vfit/shared/data-access';
import { checkIsApp } from '@vfit/consumer/data-access';
import { fiscalCodeInfoSchema } from './retrieveFiscalCode.validation';
import { IFiscalCodeData, REGION, IFiscalCodeInfo, GENDER } from './retrieveFiscalCode.models';
import {
  ButtonContainer,
  Div,
  Footer,
  InputContainer,
  Text,
  Title,
  Wrapper,
} from './retrieveFiscalCode.style';
import { getMunicipalityByString } from './retrieveFiscalCode.utils';

const RetrieveFiscalCode = ({ defaultData, onSubmit, data }: IFiscalCodeData) => {
  const [birthLabel, setBirthLabel] = useState('Comune di nascita');
  const italianMunicipalities: ICountry[] = getItalianMunicipalities();
  const foreignCountries: ICountry[] = getForeignCountries();

  const calculatedItalianMunicipalities = useCallback(
    () =>
      italianMunicipalities.map(
        (municipality) => `${municipality.municipality} (${municipality.province})`
      ),
    [italianMunicipalities]
  );

  const calculatedForeignCountries = useCallback(
    () => foreignCountries.map((fCountry) => `${fCountry.municipality}`),
    [foreignCountries]
  );

  const {
    hookReturn: {
      register,
      setValue,
      clearErrors,
      trigger,
      formState: { errors },
    },
    registerSearchSelect,
    allValues,
    isAllRequiredValid,
  } = useFormExtended<IFiscalCodeInfo>({
    resolver: yupResolver(fiscalCodeInfoSchema()),
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      birthDate: '',
      gender: '',
      nationality: '',
      birthPlace: '',
    },
  });

  const itemsForBirthPlace = useMemo(
    () =>
      allValues.nationality === REGION.ITALIA
        ? calculatedItalianMunicipalities()
        : calculatedForeignCountries(),
    [allValues.nationality, calculatedForeignCountries, calculatedItalianMunicipalities]
  );

  useEffect(() => {
    setValue('firstName', defaultData?.owningIndividual?.firstName || '');
    setValue('lastName', defaultData?.owningIndividual?.lastName || '');
  }, [defaultData, setValue]);

  const calculate = () => {
    const dateOfBirth = allValues.birthDate?.split('/');
    let municipality: ICountry | undefined;
    if (allValues.nationality === REGION.ITALIA) {
      municipality = getMunicipalityByString(italianMunicipalities, allValues.birthPlace || '');
    }
    if (allValues.nationality === REGION.FOREIGN_COUNTRY) {
      municipality = getMunicipalityByString(foreignCountries, allValues.birthPlace || '');
    }
    if (!municipality || !dateOfBirth) {
      // TODO: Error
      return;
    }
    const fiscalCode = calculateFiscalCode(
      allValues.firstName || '',
      allValues.lastName || '',
      allValues.gender === 'Femmina' ? Gender.Female : Gender.Male,
      +dateOfBirth[0],
      +dateOfBirth[1],
      +dateOfBirth[2],
      municipality
    );

    if (!fiscalCode) {
      // TODO: cms
      errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
        title: 'Attenzione',
        message: 'La data inserita non è corretta',
        actionText: 'Chiudi',
      });
      return;
    }

    if (defaultData?.owningIndividual) {
      onSubmit({
        owningIndividual: {
          ...defaultData?.owningIndividual,
          firstName: allValues.firstName.trim() || '',
          lastName: allValues.lastName.trim() || '',
          nation:
            allValues.nationality === REGION.ITALIA ? REGION.ITALIA : municipality.municipality,
          fiscalCode,
        },
        isValid: true,
      });
    }
  };

  return (
    <Wrapper>
      <Title>{data?.title || ''}</Title>
      <Text>{data?.description || ''}</Text>
      <Div>
        <InputContainer>
          <div className="textInput">
            <Form.TextInput
              label={data?.firstName || ''}
              placeholder=" "
              error={errors.firstName?.message}
              {...register('firstName')}
            />
          </div>

          <div className="textInput">
            <Form.TextInput
              label={data?.lastName || ''}
              placeholder=" "
              error={errors.lastName?.message}
              {...register('lastName')}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <div className="textInput">
            <Form.MaskInput
              mask="99/99/9999"
              label={data?.birthDate || ''}
              placeholder=" "
              error={errors.birthDate?.message}
              {...register('birthDate', {
                onBlur: () => {
                  trigger('birthDate');
                },
              })}
            />
          </div>

          <div className="textInput">
            <Form.SearchSelect
              label={data?.gender || 'genere'}
              placeholder=" "
              items={[GENDER.MALE, GENDER.FEMALE]}
              {...registerSearchSelect('gender')}
            />
          </div>
        </InputContainer>

        <InputContainer>
          <div className="textInput">
            <Form.SearchSelect
              label={data?.nationality || ''}
              placeholder=" "
              items={[REGION.ITALIA, REGION.FOREIGN_COUNTRY]}
              {...registerSearchSelect('nationality')}
              autoComplete="off"
              setValue={(v) => {
                v === 'Italia'
                  ? setBirthLabel(data?.birthPlace || '')
                  : setBirthLabel(data?.birthNation || '');
                setValue('nationality', v);
                clearErrors('birthPlace');
                setValue('birthPlace', '');
              }}
            />
          </div>

          <div className="textInput">
            <Form.SearchSelect
              isDisabled={!allValues.nationality || !!errors?.nationality?.message}
              label={birthLabel}
              placeholder=" "
              items={itemsForBirthPlace}
              searchable
              autoComplete="off"
              {...registerSearchSelect('birthPlace')}
            />
          </div>
        </InputContainer>
      </Div>

      <Footer>{data?.errorMessage}</Footer>

      <ButtonContainer>
        <div className="buttonSlide">
          <ButtonSlide
            onClick={calculate}
            label={data?.buttonLabel || 'CALCOLA'}
            disabled={!isAllRequiredValid}
            borderColor="#262626"
            isApp={checkIsApp()}
            labelColor="#262626"
            hoverColor="#262626"
            hoverTextColor="#fff"
            clickColor="#7e7e7e"
            clickTextColor="#fff"
            name="action_retrieveFiscalCode"
          />
        </div>
      </ButtonContainer>
    </Wrapper>
  );
};

export default RetrieveFiscalCode;
