import { IPersonalInfoCMS } from '@vfit/consumer/data-access';

export const organizeAddNumber = (dataFromCms: IPersonalInfoCMS) => ({
  title: dataFromCms?.personalinfo?.clientLogged?.editNumber?.title || '',
  subtitle: dataFromCms?.personalinfo?.clientLogged?.editNumber?.description || '',
  buttonText: dataFromCms?.personalinfo?.clientLogged?.editNumber?.buttonLabel || '',
  numbers: [
    {
      title: '******1111',
      value: '1111111111',
    },
    {
      title: '******2222',
      value: '2222222222',
    },
    {
      title: '******3333',
      value: '3333333333',
    },
  ],
  selIndexMok: 2,
  ctaLable: dataFromCms?.personalinfo?.clientLogged?.editNumber?.addNewNumberLabel || '',
});
