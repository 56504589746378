import { IPayments } from '@vfit/consumer/data-access';
import { IPayMean } from '@vfit/shared/models';

export const DEFAULT_CREDIT_CARD: IPayMean = {
  displayBrand: '*********',
  object_type: '*****',
  id: '***********',
  type: '*****',
  accountNumber: '*******',
  brand: '********',
  lastFourDigits: '****',
  holderName: '*********',
  expiryMonth: '**',
  expiryYear: '****',
};

export const organizePaymentMethod = (dataFromCms: IPayments) => ({
  title: dataFromCms?.paymentsmobile?.title || '',
  subtitle: dataFromCms?.paymentsmobile?.userRegistered?.description || '',
  buttonLabel: dataFromCms?.paymentsmobile?.userRegistered?.changeLabel || '',
});

export const organizePaymentMethodError = (paymentCMS: IPayments) => ({
  titlePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.title || '',
  messagePaymentError: paymentCMS?.paymentsmobile?.paymentSelectionError?.description || '',
  messageSelectedPaymentError:
    paymentCMS?.paymentsmobile?.paymentError?.notFound?.message ||
    'Siamo spiacenti ma si è verificato un errore sulla scelta del nuovo metodo di pagamento. Riprovare in seguito.',
  titleSelectedPaymentError: paymentCMS?.paymentsmobile?.paymentError?.notFound?.title || 'Ops!',
  titleErrorErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.title || '',
  messageErrorRetriveCart: paymentCMS?.paymentsmobile?.errorRetriveCart?.description || '',
  titleErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.title || '',
  messageErrorUpdatePaymentRecharge:
    paymentCMS?.paymentsmobile?.errorUpdatePaymentRecharge?.description || '',
});

export const organizeUpfrontSelectionCard = (dataFromCms: IPayments) => ({
  titleUpFront: dataFromCms?.paymentsmobile?.upFrontPayment?.title || 'Contributo iniziale',
  descriptionCC: dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCC || '',
  descriptionCreditRemaning:
    dataFromCms?.paymentsmobile?.upFrontPayment?.descriptionCreditRemaining || '',
  paymentMethod: [
    {
      title:
        dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[0]?.title ||
        'Carta di credito',
      description:
        dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[0]?.description || '',
      value: 'creditCard',
    },
    {
      title: dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[1]?.title || 'Paypal',
      description:
        dataFromCms?.paymentsmobile?.upFrontPayment?.paymentMethod?.[1]?.description || '',
      value: 'creditCardAndPaypal',
    },
  ],
  bottomModalConfig: {
    textLink: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.textLink || '',
    titleModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.title || '',
    descriptionModal: dataFromCms?.paymentsmobile?.upFrontPayment?.modal?.description || '',
  },
});
