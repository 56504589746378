import style from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = style.div`
    margin: 0 auto;
    padding: 32px 20px;
    font-style: normal;
    color: ${colors.$262626};
    width: 287px

    @media (min-width: ${breakpoints.tablet}) {
        width: 366px;
    }

    @media (min-width: ${breakpoints.desktop}) {
        width: 446px;
    }
`;

export const Title = style.div`
    font-family: ${fonts.exbold};
    font-weight: 400;
    text-align: center;
    ${pxToCssFont(30, 38)}
    margin-bottom: 16px;

    @media (min-width: ${breakpoints.tablet}) {
        ${pxToCssFont(42, 52)}
    }

    @media (min-width: ${breakpoints.desktop}) {
        ${pxToCssFont(36, 45)}
    }
`;

export const Description = style.div`
    font-family: ${fonts.regular};
    font-weight: 400;
    text-align: center;
    ${pxToCssFont(20, 26)}
    margin-bottom: -16px;

    @media (min-width: ${breakpoints.tablet}) {
        ${pxToCssFont(20, 30)}
        margin-bottom: 26px
    }

    @media (min-width: ${breakpoints.desktop}) {
        margin-bottom: 40px;
    }
`;

export const Detail = style.div`
    font-family: ${fonts.regular};
    font-weight: 400;
    text-align: center;
    ${pxToCssFont(12, 16)}
    margin-bottom: 20px;
    margin-top: 32px;

    @media (min-width: ${breakpoints.tablet}) {
        ${pxToCssFont(14, 18)}
    }
`;

export const LinkContainer = style.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const InputContainer = style.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
`;

export const OtpInput = style.input`
    display: inline-block;
    text-align: center;
    height: 30px;
    width: 24px;
    border-top: none;
    border-left: none;
    border-right: none;
`;

