import { colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;

  span {
    position: absolute;
    cursor: pointer;
    border-radius: 100px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.$e60000};
    transition: 0.4s;

    &::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      top: 2px;
      background-color: ${colors.$ffffff};
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + span::before {
      transform: translateX(21px);
    }

    &:disabled + span {
      background-color: ${colors.$bebebe};
    }
  }
`;

