import { INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { useMutation, useQueryClient } from 'react-query';
import { IAcceptCampaignPayload, IAcceptCampaignResponse } from './winback.models';
import { errorMock } from '../../utils/utils';

const acceptCampaignService = (
  campaignId: string,
  payload: IAcceptCampaignPayload
): Promise<IAcceptCampaignResponse> =>
  nextClient.post(`${API.CAMPAIGN}/${campaignId}/accept`, payload);

export const useAcceptCampaign = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'acceptCampaign',
    ({ phoneNumber, campaignId }: IUseAcceptCampaignMutation) => {
      const getSubscriberByResource = queryClient.getQueryData('getSubscriberByResource');
      const audienceId = getSubscriberByResource?.[0]?.subscriberId || '';
      const customerId = getSubscriberByResource?.[0]?.customerId || '';
      const _payload: IAcceptCampaignPayload = {
        audienceType: 'SUBSCRIBER_ID',
        msisdn: phoneNumber,
        isWinbackCampaign: 'true',
        audienceId,
        customerId,
      };
      return acceptCampaignService(campaignId, _payload);
    },
    {
      onSuccess: (data: IAcceptCampaignResponse) => {
        queryClient.setQueryData('acceptCampaign', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - acceptCampaign', error);
        queryClient.setQueryData('acceptCampaign', errorMock('acceptCampaign', error));
      },
    }
  );
};

interface IUseAcceptCampaignMutation {
  phoneNumber: string;
  campaignId: string;
}
