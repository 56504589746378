import React, { useEffect } from 'react';
import { Portal } from '@vfit/shared/components';
import { ICheckoutModalProps } from './checkout.models';
import Checkout from './checkout';

const CheckoutModal = ({
  isOpen,
  handleClose,
  backgroundImage,
  isLoadingCart,
  isErrorOnPaymentShowErrorModal,
  isOnResetUser,
  isErrorOnPayment,
  hideCards,
}: ICheckoutModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!isOpen) return null;

  return (
    <Portal wrapperId="checkout">
      <Checkout
        backgroundImage={backgroundImage}
        handleClose={handleClose}
        isLoadingCart={isLoadingCart}
        isErrorOnPayment={isErrorOnPayment}
        isErrorOnPaymentShowErrorModal={isErrorOnPaymentShowErrorModal}
        isOnResetUser={isOnResetUser}
        hideCards={hideCards}
      />
    </Portal>
  );
};

export default CheckoutModal;
