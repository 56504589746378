import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import {
  errorMock,
  IBillingAccountResponse,
  IShoppingCartResponse,
} from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import {
  ILightCreditVettingPayload,
  ILightCreditVettingResponse,
  IShippingAddress,
} from './lightCredtVetting.models';

/**
 * This method calls the service that verify the credit card submitted.
 * Method: POST
 * @param cartId
 * @param payload
 * @param customOptions
 * @returns
 */
export const lightCreditVettingService = (
  cartId: string,
  payload: ILightCreditVettingPayload,
  customOptions?: CustomOptions
): Promise<ILightCreditVettingResponse> =>
  nextClient.post(`${API.ORDER}/${cartId}/${API.LIGHT_CREDIT_VETTING}`, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Custom mutation hook to manage lightCreditVettingService.
 * @returns
 */
export const useLightCreditVettingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'lightCreditCardVetting',
    () => {
      // Get the shoppingCart obj from storage
      const shoppingCartSaved = localStorage.getItem('shoppingCart');
      const shoppingCart: IShoppingCartResponse =
        !!shoppingCartSaved && JSON.parse(shoppingCartSaved as string);
      LoggerInstance.debug('shoppingCart : ', shoppingCart);
      const cartId = shoppingCart?.id;

      // Get billingAccount from local storage
      const billingAccountSaved = localStorage.getItem('billingAccount');
      const billingAccount: IBillingAccountResponse =
        !!billingAccountSaved && JSON.parse(billingAccountSaved as string);
      const physicalAddress: IShippingAddress =
        billingAccount?.billDeliveryDetails?.physicalAddress;

      LoggerInstance.debug('physicalAddress : ', physicalAddress);

      const payload: ILightCreditVettingPayload = {
        shippingAddress: {
          id: physicalAddress?.id,
          street: physicalAddress?.street,
          streetNumber: physicalAddress?.streetNumber,
          city: physicalAddress?.city,
          country: physicalAddress?.country,
          stateOrProvince: physicalAddress?.stateOrProvince,
          postalCode: physicalAddress?.postalCode,
          formattedAddress1:
            physicalAddress?.formattedAddress || physicalAddress?.formattedAddress1,
        },
      };

      return lightCreditVettingService(cartId, payload);
    },
    {
      onSuccess: (data: ILightCreditVettingResponse) => {
        LoggerInstance.debug('SUCCESS', data);
        queryClient.setQueryData('lightCreditVetting', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - lightCreditVetting: `, error);
        queryClient.setQueryData('lightCreditVetting', errorMock(`lightCreditVetting`, error));
        localStorage.setItem(
          `lightCreditVetting`,
          JSON.stringify(errorMock(`lightCreditVetting`, error))
        );
      },
    }
  );
};
