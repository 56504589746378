import { Button } from '@vfit/shared/atoms';
import * as S from './table.style';
import { ITable } from './table.models';

export const Table: React.FC<ITable> = ({ table, btn }) => {
  if (!Array.isArray(table) || !table[0].title) return null;
  return (
    <S.Container>
      {table?.map((e) => (
        <S.Line>
          {e.title && <S.Title>{e.title}</S.Title>}
          {e.value && <S.Value dangerouslySetInnerHTML={{ __html: e.value }} />}
        </S.Line>
      ))}
      {btn?.buttonLabel && btn?.buttonLink && (
        <S.BtnBox>
          <Button variant="secondary" href={btn?.buttonLink} target="_blank">
            {btn?.buttonLabel}
          </Button>
        </S.BtnBox>
      )}
    </S.Container>
  );
};
