import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTracking } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { SoftManagerService } from '@vfit/consumer/providers';
import { yupResolver } from '@hookform/resolvers/yup';
import { VfModal, Form } from '@vfit/shared/components';
import { retrieveProduct } from '@vfit/consumer/data-access';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { IPersonalDataObject } from '@vfit/shared/models';
import RetrieveFiscalCode from '../RetrieveFiscalCode/retrieveFiscalCode';
import { IData, IOwningIndividual, IPersonalInfo } from './nameSurnameFiscalCode.models';
import { ContentSpinner, Div, HowToLink, LinkDiv, Text, Title, Wrapper } from './nameSurnameFiscalCode.styles';
import { nameSurnameFiscalCode } from './validationSchema';
import { isValidCF, isValidCFLength } from './nameSurnameFiscalCode.utils';
import HowToCard from './components/HowToCard/howToCard';

const NameFiscalCode = (props: IPersonalInfo) => {
  const { data, isLoading, onChangeData, errorFiscalCode, cmsConfig, taggingData, isFormDisabled, isLoggedUser } =
    props;
  const client = useQueryClient();
  const product = retrieveProduct();
  const [showRetrieveFiscalCode, setShowRetrieveFiscalCode] = useState(false);
  const [showHowToModal, setShowHowToModal] = useState(false);
  const [fullNameEmpty, setFullNameEmpty] = useState(true);
  const { owningIndividual } = data;
  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isValid, errors },
  } = useForm<IData>({
    resolver: yupResolver(nameSurnameFiscalCode),
    mode: 'all',
  });
  const { retriveFiscalCode, nameFiscalCodeCard, howToModalCard } = cmsConfig;

  const { trackWithNewDatalayer } = useTracking({
    event: ['view'],
    disableInitialTrack: true,
  });

  const isPassed = (passeData: IOwningIndividual) =>
    passeData.firstName !== '' && passeData.lastName !== '' && passeData.fiscalCode !== '';

  useEffect(() => {
    const shouldValidate = isPassed(owningIndividual);
    if (owningIndividual) {
      setValue('firstName', owningIndividual.firstName, { shouldValidate });
      setValue('lastName', owningIndividual.lastName, { shouldValidate });
      setValue('fiscalCode', owningIndividual.fiscalCode, { shouldValidate });
    }
  }, []);

  useEffect(() => {
    if (owningIndividual) {
      setValue('fiscalCode', owningIndividual.fiscalCode, { shouldValidate: false });
    }
  }, [owningIndividual.fiscalCode]);

  const handleShowCalculateFiscalCode = useCallback(() => {
    if (!isLoading) {
      setShowRetrieveFiscalCode(true);
      trackWithNewDatalayer({
        event: ['checkout_step1.4'],
        event_label: 'popup CF',
        opts: taggingData?.opts,
        cartProduct: taggingData?.trackingProduct,
        pageProduct: { product_name: product?.slug || '' },
        visitorTrackingOpts: SoftManagerService(client).getUser(),
      });
    }
  }, [isLoading]);

  const handleHideCalculateFiscalCode = useCallback(() => {
    setShowRetrieveFiscalCode(false);
  }, []);
  const handleHowToModal = () => {
    setShowHowToModal(true);
  }
  const handleUpdateFiscalCode = (dataFiscalCode: IPersonalDataObject) => {
    localStorage.removeItem('recognizedUser');
    const changedData: IPersonalDataObject = {
      ...data,
      owningIndividual: {
        ...owningIndividual,
        ...dataFiscalCode.owningIndividual,
      },
      isValid: dataFiscalCode.isValid,
    };
    const shouldValidate = isPassed(changedData.owningIndividual);
    setValue('fiscalCode', changedData.owningIndividual.fiscalCode, {
      shouldValidate,
    });
    setValue('firstName', changedData.owningIndividual.firstName, {
      shouldValidate,
    });
    setValue('lastName', changedData.owningIndividual.lastName, {
      shouldValidate,
    });

    if (onChangeData) {
      onChangeData(changedData, isValid);
      setShowRetrieveFiscalCode(false);
    }
  };

  const changeData = () => {
    const inputs = getValues();
    const firstName: string = inputs.firstName || '';
    const lastName: string = inputs.lastName || '';
    const fiscalCode: string = inputs.fiscalCode || '';

    let changedData: IPersonalDataObject = { owningIndividual };
    const nameSurnameChanged =
      owningIndividual.firstName !== firstName || owningIndividual.lastName !== lastName;
    if (owningIndividual) {
      changedData = {
        owningIndividual: {
          ...owningIndividual,
          firstName: firstName || '',
          lastName: lastName || '',
          fiscalCode: nameSurnameChanged ? '' : fiscalCode || '',
        },
        isValid: nameSurnameChanged ? false : isValid,
      };
    }
    if (nameSurnameChanged) {
      clearErrors('fiscalCode');
    }

    if (firstName === '' && lastName === '') {
      setFullNameEmpty(true);
    } else {
      setFullNameEmpty(false);
      if (
        isValidCFLength(changedData.owningIndividual.fiscalCode) &&
        !isValidCF(firstName, lastName, fiscalCode)
      ) {
        setError('fiscalCode', { type: 'match', message: 'Inserire un codice fiscale valido' });
      }
    }

    if (onChangeData) {
      onChangeData(changedData, isValid);
    }
  };

  useEffect(() => {
    changeData();
  }, [isValid]);

  const getDisabled = () =>
    !!(
      errors.firstName?.message ||
      errors.lastName?.message ||
      fullNameEmpty ||
      isLoading ||
      isFormDisabled
    );

  return (
    <Wrapper>
      <Title>
        {isFormDisabled ? nameFiscalCodeCard?.titleReadOnly : nameFiscalCodeCard?.title}
      </Title>
      <Text>
        {isFormDisabled ? nameFiscalCodeCard?.descriptionReadOnly : nameFiscalCodeCard?.description}
      </Text>
      {!isLoggedUser && howToModalCard?.title && (
        <HowToLink isDisabled={isLoading || isFormDisabled}>
          <button
            type="button"
            disabled={isLoading || isFormDisabled}
            onMouseDown={handleHowToModal}
          >
            {howToModalCard?.title}
          </button>
        </HowToLink>
      )}
      <Div>
        <div className="textInput">
          <Form.TextInput
            label={nameFiscalCodeCard?.firstNameLable}
            disabled={isLoading || isFormDisabled}
            placeholder=" "
            autoComplete="off"
            error={errors.firstName?.message}
            {...register('firstName', {
              onChange: changeData,
            })}
          />
        </div>
        <div className="textInput">
          <Form.TextInput
            label={nameFiscalCodeCard?.lastNameLable}
            disabled={isLoading || isFormDisabled}
            placeholder=" "
            autoComplete="off"
            error={errors.lastName?.message}
            {...register('lastName', {
              onChange: changeData,
            })}
          />
        </div>
        <div className="textInput">
          <Form.TextInput
            label={nameFiscalCodeCard?.fiscalCodeLable}
            readOnly={getDisabled()}
            placeholder=" "
            autoComplete="off"
            autoCapitalize="characters"
            icon={
              isLoading ? (
                <ContentSpinner>
                  <LoadingSpinner />
                </ContentSpinner>
              ) : (
                <div />
              )
            }
            error={errors.fiscalCode?.message || errorFiscalCode}
            {...register('fiscalCode', {
              onChange: changeData,
            })}
          />
        </div>
      </Div>
      <LinkDiv isDisabled={isLoading || isFormDisabled}>
        <button
          type="button"
          disabled={isLoading || isFormDisabled}
          onMouseDown={handleShowCalculateFiscalCode}
        >
          {nameFiscalCodeCard?.buttonLabel}
        </button>
      </LinkDiv>
      <VfModal
        isOpen={showRetrieveFiscalCode}
        handleClose={handleHideCalculateFiscalCode}
        height={644}
      >
        <RetrieveFiscalCode
          data={retriveFiscalCode}
          defaultData={data}
          onSubmit={handleUpdateFiscalCode}
        />
      </VfModal>
      {
        howToModalCard &&
        <VfModal
        isOpen={showHowToModal}
        handleClose={() => setShowHowToModal(false)}
        height={598}
      >
        <HowToCard data={howToModalCard} />
      </VfModal>
      }
    </Wrapper>
  );
};

export default NameFiscalCode;

