import { QueryClient } from 'react-query';
import { IBanner, IStickyBannerHeaderCMS } from '@vfit/shared/models';
import { stickyBannerHeaderOrganize } from '@vfit/shared/data-access';

const getBannerConfig = (queryClient: QueryClient, bannerCustomConfig?: IBanner): IBanner => {
  const { htmlDesktop: customHtml, useGlobalBanner: customUseGlobalBanner } =
    bannerCustomConfig || {};
  const globalBannerCms = queryClient?.getQueryData('getStickyBannerCms') as IStickyBannerHeaderCMS;
  const globalStickyBannerConfig = stickyBannerHeaderOrganize(globalBannerCms);
    if (customHtml) {
      if (!customUseGlobalBanner) {
        return bannerCustomConfig || {};
      }
      return globalStickyBannerConfig;
    }
    return globalStickyBannerConfig;
};

export { getBannerConfig };
