import { ICarouselCMS } from '../../cms.models';

export const getLargeLargeSlider = (widget: ICarouselCMS) => ({
  slides: widget.elements?.map((s, indexSlide) => {
    if (indexSlide === 0) {
      return {
        ...s,
        action: {
          ...s.action,
          callMeNow: s.action?.callMeNow || s.action?.url || '',
          // @ts-ignore
          small: s.action?.small ? s.action.small === 'true' : true,
        },
      };
    }
    return {
      ...s,
      withMask: false,
    };
  }),
  topText: widget.title || '',
});

