import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Wraper = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(20, 18)}
  margin-top: 32px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints.tablet}) {
    ${pxToCssFont(14, 18)}
  }
`;

export const CheckboxDiv = styled.div`
  display: flex;
  width: 25px;
  margin-right: 10px;
`;

export const Checkbox = styled.div`
  input {
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;
    z-index: 3;
  }

  .checkmark::after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .checkmark {
    position: absolute;
    height: ${pxToRem(20)};
    width: ${pxToRem(20)};
    border-radius: ${pxToRem(3)};
    border: ${pxToRem(1)} solid ${colors.$bebebe};
  }

  &:hover input ~ .checkmark {
    border: ${pxToRem(1)} solid ${colors.$bebebe};
  }

  input:checked ~ .checkmark {
    background-color: ${colors.$00697c};
    border: none;
  }

  input:checked ~ .checkmark::after {
    display: block;
  }
`;

export const CheckboxText = styled.div`
  width: fit-content;
  text-align: start;
`;

export const ModalContent = styled.div`
  text-align: left;
`;
