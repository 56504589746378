import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToCssFontSize } from '@vfit/shared/themes';
import { ICSSProps } from './coverageTool.models';

export const ErrorMsg = styled.p<ICSSProps>`
  ${(props) => (props.isManual ? '' : 'display: none;')}

  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  color: ${colors.$e60000};
  ${pxToCssFont(14, 18)}
  font-family: ${fonts.regular};
  text-align: left;
`;

export const P = styled.p`
  ${pxToCssFont(18, 24)}
  font-family: ${fonts.light};
  text-align: center;
  color: #333;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;

export const Title = styled.h3`
  margin: 0;
  ${pxToCssFontSize(30)}
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(42)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFontSize(36)}
  }
`;

