import styled from 'styled-components';
import { breakpoints, fonts, getTypography, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div<{ isApp?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: ${({ isApp }) => (isApp ? 'inherit' : '40px 44px')};
  justify-content: center;
  align-content: center;
  text-align: left;
  .capitalize-first {
    text-transform: lowercase;
  }
  .capitalize-first::first-letter {
    text-transform: uppercase;
  }
  @media (min-width: ${breakpoints.tablet}) {
    gap: 24px;
    padding: 64px 40px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    gap: 24px;
    padding: 64px 109px;
  }
`;

export const SecondContainer = styled.div`
  text-align: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const SecondaryTitle = styled.div`
  margin-top: 24px;
  font-family: ${fonts.regular};
  font-weight: 700;
  ${getTypography('h5.bold')};
  margin-bottom: 16px;
`;

export const SecondaryDescription = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${getTypography('body2.regular')};
  ${pxToCssFont(24, 32)}
  margin-bottom: 24px;

  p {
    margin: 0;
  }
`;

export const CloseIcon = styled.button`
  all: unset; /* Resets all inherited and default styles */
  display: block; /* Block display like a div */
  cursor: pointer; /* Optional: To keep the pointer behavior */
`;
export const Title = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  ${getTypography('body2.bold')}/* @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  } */
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 300;
  /* margin-bottom: 32px; */
  ${getTypography('body1.regular')} /* @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 0;
  }
  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  } */

   p {
    margin: 0;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;
