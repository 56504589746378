export interface IOfferingServiceabilityRequest {
  serviceabilityAddress: {
    city: string;
    country: string;
    displayCountry: string;
    externalId: string;
    homeZone: string;
    istatCode: string;
    postalCode: string;
    stateOrProvince: string;
    displayStateOrProvince?: string;
    street: string;
    streetName: string;
    streetNumber: string;
  };
}

export interface IOfferingServiceabilityResponse {
  id: string;
  serviceabilityResult: string;
  offeringServiceabilityItem: IOfferingServiceabilityItem[];
  serviceabilityCharacteristic: IServiceabilityCharacteristic[];
  serviceabilityAddress: IServiceabilityAddress;
}

interface IOfferingServiceabilityItem {
  offering: IOffering;
}

interface IOffering {
  id: string;
  name: string;
}

export interface IServiceabilityCharacteristic {
  displayName: string;
  displayValue?: string;
}

export interface IServiceabilityAddress {
  city: string;
  country: string;
  displayCountry: string;
  postalCode: string;
  stateOrProvince: string;
  displayStateOrProvince?: string;
  street: string;
  externalId: string;
  streetNumber: string;
  homeZone: string;
  istatCode: string;
  districtCode?: string;
  latitude?: string;
  longitude?: string;
  streetName: string;
  streetType?: string;
}

export interface IBackupOffer {
  offering: {
    id: string;
    name: string;
  };
}

export interface ICharacteristic {
  displayName?: string;
  displayValue?: string;
}

export enum CoverageErrorType {
  KO,
  FWA,
  R2_LOGGED,
  ERROR,
}
