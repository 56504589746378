import React from 'react';
import { getButtonActionByActionType } from '@vfit/shared/data-access';
import { ExperiencesSlider as ExperiencesSliderCommon } from '@vfit/shared/components';
import { useRouter } from 'next/router';
import { IExperiencesProps } from './experiencesSlider.models';

const ExperiencesSlider = ({ experiences, divider, disclaimer }: IExperiencesProps) => {
  const { push } = useRouter();
  const { customAction } = experiences;

  return (
    <ExperiencesSliderCommon
      experiences={experiences}
      handleButtonClick={() => getButtonActionByActionType(customAction, push)}
      handleClickCard={(actionCard) => getButtonActionByActionType(actionCard, push)}
      divider={divider}
      disclaimer={disclaimer}
    />
  );
};

export default ExperiencesSlider;
