import { useEffect, useState } from 'react';
import {
  errorMock,
  GetCustomerResponse,
  IFindCallerResponse,
  useGetShoppingCartSilentUpdate,
  useValidateVoucher,
  useVoucher,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { DEFAULT_VOUCHER_DISCOUNT } from '../../../iBuyMobile.context.data';

export const useVoucherDiscount = () => {
  const [cartId, setCartId] = useState('');
  const { voucher, setVoucher } = useCheckout();
  const { voucherId, isApplyVoucher } = voucher;
  const VALID_VOUCHER_CODE = '0';
  const EXPIRED_VOUCHER_CODE = '1002';
  const {
    data: configShoppingCartResponse,
    isSuccess: isSuccessConfigShoppingCart,
    isLoading: isLoadingConfigShoppingCart,
    isError: isErrorConfigShoppingCart,
    error: errorConfigShoppingCart,
    refetch: refetchConfigShoppingCart,
  } = useGetShoppingCartSilentUpdate(cartId, {
    enabled: false,
    keyDependency: ['getConfigVoucherShoppingCart'],
  });
  const {
    data: validateVoucher,
    mutateAsync: validateVoucherMutate,
    isLoading: isLoadingValidateVoucher,
    isSuccess: isSuccessValidateVoucher,
    isError: isErrorValidateVoucher,
    error: errorValidateVoucher,
  } = useValidateVoucher();
  const {
    data: voucherResponse,
    mutateAsync: voucherMutate,
    isLoading: isLoadingVoucher,
    isSuccess: isSuccessVoucher,
    isError: isErrorVoucher,
    error: errorVoucher,
  } = useVoucher();
  const {
    data: shoppingCartResponse,
    isSuccess: isSuccessShoppingCart,
    isLoading: isLoadingShoppingCart,
    isError: isErrorShoppingCart,
    error: errorShoppingCart,
    refetch: refetchShoppingCart,
  } = useGetShoppingCartSilentUpdate(cartId, {
    enabled: false,
    keyDependency: ['getVoucherShoppingCart'],
  });

  // region Apply Voucher

  useEffect(() => {
    if (isLoadingShoppingCart) {
      setVoucher({
        ...voucher,
        shoppingCart: {
          ...voucher.shoppingCart,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorShoppingCart) {
      setVoucher({
        ...voucher,
        shoppingCart: {
          ...voucher.shoppingCart,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock(
            'shoppingCart',
            errorShoppingCart,
            undefined,
            undefined,
            'error get shopping cart for voucher',
            true,
            {
              voucher: voucherId || 'NA',
            }
          ),
        },
      });
    } else if (isSuccessShoppingCart) {
      setVoucher({
        ...voucher,
        isAppliedVoucher: true,
        shoppingCart: {
          ...voucher.shoppingCart,
          data: shoppingCartResponse,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
    }
  }, [isSuccessShoppingCart, isLoadingShoppingCart, isErrorShoppingCart]);

  useEffect(() => {
    if (isLoadingVoucher) {
      setVoucher({
        ...voucher,
        voucher: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorVoucher) {
      setVoucher({
        ...voucher,
        voucher: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('voucher', errorVoucher),
        },
      });
    } else if (isSuccessVoucher) {
      setVoucher({
        ...voucher,
        shoppingCart: {
          isSuccess: false,
          isError: false,
          isLoading: true,
        },
        voucher: {
          data: voucherResponse,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      refetchShoppingCart();
    }
  }, [isSuccessVoucher, isLoadingVoucher, isErrorVoucher]);

  useEffect(() => {
    if (isApplyVoucher && voucher?.validateVoucher?.data) {
      setVoucher({
        ...voucher,
        isApplyVoucher: false,
      });
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      voucherMutate({
        voucherId: voucher?.validateVoucher?.data?.voucherId,
        voucherStartDate: voucher?.validateVoucher?.data.startDate,
        voucherEndDate: voucher?.validateVoucher?.data.endDate,
        benefitBOCID: voucher?.validateVoucher?.data.benefitBoCID,
        parentBOId: voucher?.validateVoucher?.data.parentBoId,
        operationType: 'addVoucher',
        customerId: customerData?.[0]?.id || findCaller?.customerRef?.id || '',
        isShortFlow: false,
      });
    }
  }, [isApplyVoucher]);

  // endregion

  // region Validate voucher

  const callValidateVoucher = () => {
    if (voucherId) {
      const shoppingCart = getFromLocalStorageByKey('shoppingCart');
      setCartId(shoppingCart?.id);
      validateVoucherMutate({ voucherId });
    }
  };

  useEffect(() => {
    if (isLoadingValidateVoucher) {
      setVoucher({
        ...voucher,
        resetData,
        isExpiredVoucher: false,
        isInvalidVoucher: false,
        validateVoucher: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorValidateVoucher) {
      setVoucher({
        ...voucher,
        resetData,
        isExpiredVoucher: false,
        isInvalidVoucher: false,
        validateVoucher: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('validateVoucher', errorValidateVoucher),
        },
      });
    } else if (isSuccessValidateVoucher) {
      const isExpiredVoucher = validateVoucher?.returnedCode == EXPIRED_VOUCHER_CODE;
      const isInvalidVoucher = validateVoucher?.returnedCode != VALID_VOUCHER_CODE;
      setVoucher({
        ...voucher,
        resetData,
        isInvalidVoucher,
        isExpiredVoucher,
        validateVoucher: {
          data: validateVoucher,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
    }
  }, [isSuccessValidateVoucher, isLoadingValidateVoucher, isErrorValidateVoucher]);

  useEffect(() => {
    if (isLoadingConfigShoppingCart) {
      setVoucher({
        ...voucher,
        validateVoucher: {
          isError: false,
          isSuccess: false,
          isLoading: true,
        },
        configShoppingCart: {
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorConfigShoppingCart) {
      setVoucher({
        ...voucher,
        validateVoucher: {
          isError: false,
          isSuccess: false,
          isLoading: false,
        },
        configShoppingCart: {
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock('configShoppingCart', errorConfigShoppingCart),
        },
      });
    } else if (isSuccessConfigShoppingCart) {
      setVoucher({
        ...voucher,
        validateVoucher: {
          isError: false,
          isSuccess: false,
          isLoading: true,
        },
        configShoppingCart: {
          data: configShoppingCartResponse,
          isLoading: false,
          isSuccess: true,
          isError: false,
        },
      });
      callValidateVoucher();
    }
  }, [isSuccessConfigShoppingCart, isLoadingConfigShoppingCart, isErrorConfigShoppingCart]);

  useEffect(() => {
    if (voucherId) {
      if (voucher?.configShoppingCart?.isSuccess) {
        callValidateVoucher();
      } else {
        refetchConfigShoppingCart();
      }
    }
  }, [voucherId]);

  // endregion

  // region Reset

  const resetData = () => {
    setVoucher({
      configShoppingCart: voucher?.configShoppingCart || {
        isError: false,
        isSuccess: false,
        isLoading: false,
        data: undefined,
        error: undefined,
      },
      ...DEFAULT_VOUCHER_DISCOUNT,
      resetData,
    });
  };

  useEffect(() => {
    setVoucher({
      ...voucher,
      resetData,
    });
  }, []);

  // endregion

  return null;
};
