import {IPortabilityMobileCMS} from "@vfit/consumer/data-access";

const organizePortabilityNumberCard = (
  portabilityNumberCardCMS: IPortabilityMobileCMS,
  keyPortability = "portability"
) => ({
  title: portabilityNumberCardCMS?.[keyPortability]?.telephone?.title || '',
  description:
    portabilityNumberCardCMS?.[keyPortability]?.telephone?.description || '',
  label: portabilityNumberCardCMS?.[keyPortability]?.selectInput?.telephone || '',
});

const organizePortabilityError = (
  portabilityNumberCardCMS: IPortabilityMobileCMS
) => ({
  titleError: portabilityNumberCardCMS?.portabilitymobile?.errorModal?.title || '',
  messageError: portabilityNumberCardCMS?.portabilitymobile?.errorModal?.message || ''
});

export { organizePortabilityError, organizePortabilityNumberCard };
