import { useEffect, useState } from 'react';
import { API, getFromLocalStorageByKey, PAGES, setTrackView } from '@vfit/shared/data-access';
import {
  CMS_CONFIG,
  ICustomerReferenceDataResponse,
  IPersonalInfoCmsMobile,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { ICountryObject, IPersonalDataObject } from '@vfit/shared/models';
import { DocumentId } from '@vfit/consumer/components';
import { useTracking } from '@vfit/shared/data-access';
import { useCheckout } from '../../../iBuyMobile.context';
import { ICheckoutData } from '../../checkout.models';
import { ID_FLOWS } from '../../checkout.constants';
import { DEFAULT_CUSTOMER_REFERENCE, organizeDocumentMobile } from './documentCard.utils';
import { getTagging, getUserInfo } from '../../checkout.utils';

const DocumentIdCard = ({ handleOnChangeEnableGoNext }: ICheckoutData) => {
  const [isValidForm, setIsValidForm] = useState(false);
  const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
  const customerReference: ICustomerReferenceDataResponse =
    getFromLocalStorageByKey('customerReference') || DEFAULT_CUSTOMER_REFERENCE;
  const { owningData, findCaller, setCurrentStepKey, setOwningData } = useCheckout();
  const taggingMobile = getTagging('document');
  const { trackView } = useTracking({
    event: ['checkout_step2'],
    event_label: 'document',
    opts: taggingMobile?.opts,
    cartProduct: taggingMobile?.trackingProduct,
    pageProduct: taggingMobile?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackView(trackView);

  const documentIdCardCMS = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const isRecognized = owningData?.isLogged || findCaller?.customerHasActiveSubs;

  const onChangeData = (data: IPersonalDataObject) => {
    setOwningData(data);
  };

  useEffect(() => {
    setTimeout(() => handleOnChangeEnableGoNext?.(isValidForm), 1000);
  }, [isValidForm, owningData]);

  useEffect(() => {
    handleOnChangeEnableGoNext?.(false);
    const oldCF = owningData?.owningIndividual?.fiscalCode;
    localStorage.setItem('customerDataCf', oldCF);
    localStorage.setItem('name_cus', owningData.owningIndividual.firstName);
    localStorage.setItem('surname_cus', owningData.owningIndividual.lastName);
    setCurrentStepKey(ID_FLOWS.DOCUMENT_ID);
  }, []);

  return (
    <DocumentId
      configCms={{
        ...organizeDocumentMobile(documentIdCardCMS, isRecognized),
      }}
      data={owningData}
      onChangeData={onChangeData}
      identificationType={
        customerReference?.identificationType?.map((item: any) => item?.displayName) || ['']
      }
      nationality={(countries as ICountryObject[])?.map((item: any) => item?.displayName) || ['']}
      isLoading={false}
      setIsValidForm={(valid: boolean) => setIsValidForm(valid)}
    />
  );
};

export default DocumentIdCard;
