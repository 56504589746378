import { IPageProps, ITaggingCMS } from '@vfit/business-data-access';
import { PageLoadingContextProvider, TrackingPageContextProvider } from '@vfit/business-data-access';
import { Footer, HeaderLite } from '@vfit/shared/components';
import { useFooter, useHeader } from '@vfit/shared/data-access';
import { withProtected } from './hoc/withProtected';
import { PageLayout } from './layout/pageLayout.style';
import { FullPageLayout } from './layout/fullPageLayout.style';
import Widgets from '../Widgets/widgets';
import { HeaderWrapper } from './layout/fullPageHeader.style';

const SimActivation = ({ page, isApp }: IPageProps) => {
  const { elements, haveHeader, haveFooter } = page?.params || {};
  const { tagging } = useHeader(elements?.pageNavigation);
  const { footer } = useFooter(elements?.pageNavigation);

  return (
    // questo div serve per aggiungere padding a fondo pagina quando presente lo sticky
    <div>
      {haveHeader && !isApp && (
        <HeaderWrapper fixed>
          <HeaderLite isMva />
        </HeaderWrapper>
      )}
      <FullPageLayout>
        <PageLayout className="noPadding">
          <PageLoadingContextProvider>
            <TrackingPageContextProvider
              tagging={elements?.tagging as ITaggingCMS}
              layer="simActivationDataLayer"
            >
              <Widgets aemElements={elements ?? {}} />
            </TrackingPageContextProvider>
          </PageLoadingContextProvider>
        </PageLayout>
      </FullPageLayout>
      {haveFooter && !isApp && <Footer onTrack={`${tagging}_FOOTER`} footer={footer} />}
    </div>
  );
};

const BlankProtected = withProtected()(SimActivation);

export default BlankProtected;

