import {
  CMS_CONFIG,
  IDeliveryInformation,
  IGenericErrorCMS,
  productSpecificError,
  useCmsConfig,
} from '@vfit/consumer/data-access';
import { API, PAGES, requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import React, { useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { ShadowsGroup } from '@vfit/shared/components';
import { resetData, ITrackError } from '@vfit/shared/data-access';
import { useQueryClient } from 'react-query';
import { ICommonData, IValidAddressList } from '@vfit/shared/models';
import { useCheckout } from '../../../../../iBuyFixed.context';
import { TextLoaderEmpty, Container } from './postAddress.style';
import { useDeliveryPostFlow } from '../../../../hooks/UseDeliveryPostFlow/useDeliveryPostFlow';

const PostAddress = ({ handleOnChangeEnableGoNext }: ICommonData) => {
  const queryClient = useQueryClient();
  const {
    product,
    shippingAddress,
    pickupPoint,
    checkoutErrors,
    setSlidesGoBack,
    setIsDisabledBackButton,
    setIsNewShippingAddress,
    setIsStartDeliveryBase,
  } = useCheckout();
  const { validateAddress, deliveryEvolved, createAddress, postDelivery, individual, linkContact } =
    useDeliveryPostFlow({
      pickupPoint,
      shippingAddress: shippingAddress as unknown as IValidAddressList,
    });
  const deliveryInformation = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_DELIVERY_INFORMATION
  ) as IDeliveryInformation;
  const genericError = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;
  const { buttonGenericError } = productSpecificError(product);

  const isLoadingState = () =>
    validateAddress?.isLoading ||
    deliveryEvolved?.isLoading ||
    createAddress?.isLoading ||
    postDelivery.isLoading ||
    individual.isLoading ||
    linkContact.isLoading;

  const isErrorState = () =>
    validateAddress?.isError ||
    deliveryEvolved?.isError ||
    createAddress?.isError ||
    individual?.isError ||
    postDelivery?.isError ||
    linkContact?.isError;

  const getError = (): ITrackError | undefined => {
    if (validateAddress?.isError) return validateAddress?.error;
    if (deliveryEvolved?.isError) return deliveryEvolved?.error;
    if (createAddress?.isError) return createAddress?.error;
    if (individual?.isError) return individual?.error;
    if (postDelivery?.isError) return postDelivery?.error;
    if (linkContact?.isError) return linkContact?.error;
    return undefined;
  };

  const managePostState = () => {
    if (isLoadingState()) handleOnChangeEnableGoNext?.(false);

    setIsDisabledBackButton(isLoadingState());

    if (isErrorState()) {
      const error = getError();
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => setSlidesGoBack(2),
        error,
        deliveryInformation?.deliveryinformation?.errorCards?.addressResponse?.title ||
          'Ops! Qualcosa è andato storto.',
        deliveryInformation?.deliveryinformation?.errorCards?.addressResponse?.message ||
          'Non siamo stati in grado di recuperare i tuoi dati di spedizione e fatturazione.',
        () => setSlidesGoBack(2),
        buttonGenericError
      );
    }
  };

  useEffect(() => {
    if (postDelivery.isSuccess) {
      setIsNewShippingAddress(false);
      resetData(queryClient, ['deliveryDetailsIsBase']);
      setIsStartDeliveryBase(true);
    }
  }, [postDelivery.isSuccess]);

  useEffect(() => {
    managePostState();
  }, [deliveryEvolved, validateAddress, createAddress, postDelivery, individual, linkContact]);

  const renderPostAddress = () => {
    if (isLoadingState()) {
      if (product?.loaders?.yourAddressCard) {
        return (
          <Container>
            <TextLoaderEmpty />
            <Player
              src={requireStaticImageAtomComponent(product.loaders.yourAddressCard).src}
              className="player"
              loop
              autoplay
            />
          </Container>
        );
      }
      return <ShadowsGroup quantity={2} heights={90} />;
    }
    return null;
  };

  return renderPostAddress();
};

export default PostAddress;
