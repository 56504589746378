import { useTracking } from '@vfit/shared/data-access';
import { setTrackLink } from '@vfit/shared/data-access';
import { getTagging, getUserInfo } from '../../checkout.utils';

const ContactTagging = () => {
  const tagging = getTagging('contact', 'fixed');
  const { trackView } = useTracking({
    event: ['checkout_step3'],
    event_label: 'contacts',
    opts: tagging.opts,
    cartProduct: tagging.trackingProduct,
    pageProduct: tagging?.pageProductsInfo,
    visitorTrackingOpts: getUserInfo(),
  });
  setTrackLink(trackView, 'contacts');
  return null;
};

export default ContactTagging;
