import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  errorMock,
  GetCustomerResponse,
  IAuthorizeResponse,
  IBillingAccountResponse,
  IFindCallerResponse,
  IShoppingCartResponse,
  SELECTED_PAYMENT,
} from '@vfit/consumer/data-access';
import {
  EMethodCode,
  IAuthorizationResponse,
  IPayMeanServiceResponse,
  IPaymentServiceResponse,
} from '@vfit/shared/models';
import {
  IOrderItemPayments,
  IPaymentDistributionPayload,
  ISelectedPayMean,
} from './paymentDistribution.models';

/**
 * This method is used to call the paymentDistributionService.
 * Method: POST
 * SearchParams:
 * ...nextClient.searchParams,
 * siaOrderId,
 * @param cartId
 * @param siaOrderId
 * @param payload
 * @param customOptions
 * @returns
 */
export const paymentDistributionService = (
  cartId: string,
  siaOrderId: string,
  payload: IPaymentDistributionPayload,
  customOptions?: CustomOptions
): Promise<any> => {
  LoggerInstance.debug('paymentDistributionService PAYLOAD -------', payload);
  LoggerInstance.debug('paymentDistributionService siaOrderId -------', siaOrderId);
  LoggerInstance.debug('paymentDistributionService cartId -------', cartId);
  return nextClient.post(`${API.ORDER}/${cartId}/${API.PAYMENT_DISTRIBUTION}`, payload, {
    searchParams: {
      ...nextClient.searchParams,
      siaOrderId,
    },
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });
};

/**
 * Method to get the billingOfferId.
 * @param array
 * @param filter
 * @returns
 */
const findBillingOfferId = (array: any, filter: string) => {
  LoggerInstance.debug('findBillingOfferId : ', array);
  const orderItem = array?.filter((el: any) => el?.orderActionId === filter);
  return orderItem?.[0]?.orderItemOnBillPayment?.billingOfferIdForDistribution;
};

/**
 * Add the created object to the payload.
 * @param ids
 * @param selectedPayMean
 * @param orderItemsId
 */
const addPayloadObject = (ids: string[], selectedPayMean: ISelectedPayMean, orderItemsId: any) => {
  // eslint-disable-next-line array-callback-return
  LoggerInstance.debug('PAYMENT DISTRIBUTION', ids, selectedPayMean, orderItemsId);
  return ids
    ?.filter((el) => !!el)
    .map((el) => ({
      orderActionId: el,
      orderItemOnBillPayment: {
        selectedPayMean,
        billingOfferIdForDistribution: findBillingOfferId(orderItemsId, el),
      },
    }));
};

/**
 * This method handles the paymentDistrubution Service.
 * @returns
 */
export const usePaymentDistributionFixed = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'paymentDistribution',
    () => {
      LoggerInstance.debug('------PaymentDistribution');
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const authorizeCC: IAuthorizeResponse = getFromLocalStorageByKey('authorize');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const paymentData: IPaymentServiceResponse = getFromLocalStorageByKey('paymentData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const authorizationDataRecurring: IAuthorizationResponse =
        getFromLocalStorageByKey('authorizationRecurring');
      const authorizationDataDefault: IAuthorizationResponse =
        getFromLocalStorageByKey('authorization');
      const authorizationData: IAuthorizationResponse =
        authorizationDataRecurring || authorizationDataDefault;
      const orderItemPaymentsFull = paymentData?.orderPayment?.orderItemPayments;
      const siaOrderId = authorizeCC?.authorizationParameters?.siaOrderId;
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const ownerIndividualId =
        customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId;
      const cartId = shoppingCart?.id;
      const cartItems = shoppingCart?.cartItem?.[0];
      const dataSimId: string = cartItems?.dataSim?.[0]?.id;
      const fixedVoiceId = cartItems?.fixedVoice?.[0]?.id;
      const internetId = cartItems?.internet?.[0]?.id;

      const selectedPayMean: ISelectedPayMean = {
        object_type: authorizationData?.authorization?.payMean?.object_type,
        id: authorizationData?.authorization?.payMean?.id,
        type: authorizationData?.authorization?.payMean?.type,
        brand: authorizationData?.authorization?.payMean?.brand,
        displayBrand: authorizationData?.authorization?.payMean?.brand,
        holderName: ' ',
        lastFourDigits: authorizationData?.authorization?.payMean?.lastFourDigits,
        expiryMonth: authorizationData?.authorization?.payMean?.expiryMonth,
        expiryYear: authorizationData?.authorization?.payMean?.expiryYear,
        isVerified: true,
        isExpired: false,
        ppbId: authorizationData?.authorization?.payMean?.ppbId,
      };

      const orderItemPayments: IOrderItemPayments[] =
        addPayloadObject(
          [dataSimId, fixedVoiceId, internetId],
          selectedPayMean,
          orderItemPaymentsFull
        ) || [];

      const payload: IPaymentDistributionPayload = {
        orderPayment: {
          customerId,
          barId: billingAccount.id,
          ownerIndividualId,
          orderItemPayments,
        },
        flowType: 'fixed',
      };
      return paymentDistributionService(cartId, siaOrderId, payload);
    },
    {
      onSuccess: (data: any) => {
        localStorage.setItem('paymentDistribution', JSON.stringify(data));
        queryClient.setQueryData('paymentDistribution', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - paymentDistribution: `, error);
        queryClient.setQueryData('paymentDistribution', errorMock(`paymentDistribution`, error));
        localStorage.setItem(
          `paymentDistribution`,
          JSON.stringify(errorMock(`paymentDistribution`, error))
        );
      },
    }
  );
};

/**
 * This method handles the paymentDistrubutionMobile Service.
 * @returns
 */
export const usePaymentDistributionMobile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    'paymentDistribution',
    () => {
      LoggerInstance.debug('------PaymentDistribution');
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const authorizeCC: IAuthorizeResponse = getFromLocalStorageByKey('authorize');
      const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
      const billingAccount: IBillingAccountResponse = getFromLocalStorageByKey('billingAccount');
      const paymentData: IPaymentServiceResponse = getFromLocalStorageByKey('paymentData');
      const findCaller: IFindCallerResponse = getFromLocalStorageByKey('findCaller');
      const authorizationData: IAuthorizationResponse =
        getFromLocalStorageByKey('authorizationRecurring');
      const payMeanData: IPayMeanServiceResponse = getFromLocalStorageByKey('payMean');
      const lastSelectedType = localStorage.getItem(SELECTED_PAYMENT);
      const isWallet = lastSelectedType?.toLowerCase() === EMethodCode.WALLET.toLowerCase();
      const authorizationPayMean = authorizationData?.authorization?.payMean;
      const foundedPayMean = payMeanData?.payMeans?.find(
        (payMean) =>
          payMean.lastFourDigits === authorizationPayMean?.lastFourDigits &&
          payMean.type === authorizationPayMean?.type &&
          payMean.object_type === authorizationPayMean?.object_type
      );

      const orderItemPaymentsFull = paymentData?.orderPayment?.orderItemPayments;
      const siaOrderId = authorizeCC?.authorizationParameters?.siaOrderId;
      const customerId = customerData?.[0]?.id || findCaller?.customerRef?.id;
      const ownerIndividualId =
        customerData?.[0]?.owningIndividual?.id || findCaller?.individualRef?.contactId;
      const cartId = shoppingCart?.id;

      const cartItems = shoppingCart?.cartItem?.[0];

      let selectedPayMean: ISelectedPayMean;
      if (isWallet) {
        selectedPayMean = {
          type: EMethodCode.WALLET.toLowerCase(),
        };
      } else {
        selectedPayMean = {
          object_type: authorizationPayMean?.object_type,
          type: authorizationPayMean?.type,
          brand: authorizationPayMean?.brand,
          displayBrand: authorizationPayMean?.brand,
          holderName: ' ',
          lastFourDigits: authorizationPayMean?.lastFourDigits,
          expiryMonth: authorizationPayMean?.expiryMonth,
          expiryYear: authorizationPayMean?.expiryYear,
          isVerified: true,
          isExpired: false,
          ppbId: authorizationPayMean?.ppbId,
          // id: foundedPayMean?.id || authorizationPayMean?.id,
          ...(foundedPayMean?.id && { id: foundedPayMean.id }),
        };
      }

      const orderItemPayments: IOrderItemPayments[] =
        addPayloadObject([cartItems.id], selectedPayMean, orderItemPaymentsFull) || [];

      const payload: IPaymentDistributionPayload = {
        orderPayment: {
          customerId,
          barId: billingAccount.id,
          ownerIndividualId,
          orderItemPayments,
        },
      };

      LoggerInstance.debug('Payment distribution Payload', payload);
      return paymentDistributionService(cartId, siaOrderId, payload);
    },
    {
      onSuccess: (data: any) => {
        localStorage.setItem('paymentDistribution', JSON.stringify(data));
        queryClient.setQueryData('paymentDistribution', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - paymentDistribution: `, error);
        queryClient.setQueryData('paymentDistribution', errorMock(`paymentDistribution`, error));
        localStorage.setItem(
          `paymentDistribution`,
          JSON.stringify(errorMock(`paymentDistribution`, error))
        );
      },
    }
  );
};
