import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 0 20px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 40px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 54px;
  }

  display: flex;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
  text-align: center;
  font-weight: 400;
  font-family: ${fonts.exbold};
  color: ${colors.$262626};
`;

export const Title = styled.div`
  margin-top: 32px;
  font-weight: 400;
  color: ${colors.$262626};
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 60px;
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

