import { UseQueryResult } from 'react-query';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { IGetCountryResponse } from './country.models';

/**
 * This method is used retrieve the Available Countries' list from ApicWeb.
 * This in used for a FrontEnd dropdown.
 * Method: GET
 * SearchParams: salesChannel: selfService
 * @returns
 */
const countryService = (customOptions?: CustomOptions) =>
  nextClient.get(API.COUNTRY, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IGetCountryResponse[]>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the countryService
 * @param customOptions
 * @returns
 */
export const useGetCountry = (
  customOptions?: CustomOptions
): UseQueryResult<IGetCountryResponse[], INextError> => {
  const getCountrySaved = localStorage.getItem('getCountry');
  const getCountryDataFromStorage: boolean | IGetCountryResponse[] =
    !!getCountrySaved && getCountrySaved !== 'undefined' && JSON.parse(getCountrySaved as string);

  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'getCountry';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => countryService(customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled: !getCountryDataFromStorage,
      onSuccess: (data: IGetCountryResponse[]) => {
        if (data) localStorage.setItem('getCountry', JSON.stringify(data));
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - getCountry: `, error);
        localStorage.setItem(`getCountry`, JSON.stringify(errorMock(`getCountry`, error)));
      },
    },
  };
  return handleUseQuery(options) as UseQueryResult<IGetCountryResponse[], INextError>;
};
