import styled, { createGlobalStyle } from 'styled-components';

export const OverlayGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  header {
    display: none;
  }

  #sticky-offer-product {
    display: none;
  }
`;

export const CheckoutModalContainer = styled.div`
  position: fixed;
  overflow-y: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  top: 0;
  z-index: 3;
  width: 100%;
`;

export const LoadingPage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
