import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  border: 1px solid #d8d8d8;
  margin: 0 auto;
  border-radius: 5px;
  height: 162px;
  padding: 16px;
  padding-bottom: 20px;
  color: ${colors.$262626};
  text-align: left;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 24px;
    padding-top: 20px;
    height: 192px;
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(20, 26)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
    margin-bottom: 24px;
  }
`;

export const Info = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
  }

  p {
    margin: 0 auto;
  }
`;

export const Expiration = styled.div`
  display: flex;
  align-items: center;
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(14, 18)}

  svg {
    margin-right: 8px;
    height: 16px;
    width: 24px;
  }
`;

