import { ITermsAndCondition } from '@vfit/consumer/data-access';
import { IConsentConfig } from '@vfit/shared/models';

export const organizeTermsAndConditionConsentCard = (consentsCms: ITermsAndCondition) => ({
  title: consentsCms?.consents?.card?.title || '',
  description: consentsCms?.consents?.card?.description || '',
  subtitle: consentsCms?.consents?.card?.terms?.title || '',
  actionText: consentsCms?.consents?.card?.terms?.customLinkLabel || '',
});

export const organizeTermsAndConditionConsents = (
  consentsCmsContent: ITermsAndCondition
): IConsentConfig => ({
  title: consentsCmsContent?.consents?.card?.title || '',
  description: consentsCmsContent?.consents?.modal?.description || '',
  buttonText: consentsCmsContent?.consents?.modal?.buttonLabel || '',
  button: consentsCmsContent?.consents?.card?.terms?.customLinkLabel || '',
  acceptAll:
    consentsCmsContent?.consents?.card?.terms?.acceptAll ||
    'Desidero fornire il mio consenso rispetto a tutti i consensi',
});
