import { breakpoints, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';

export const Wraper = styled.div`
  text-align: left;

  p {
    font-size: ${pxToRem(20)};
    margin-top: 0;
  }
`;

export const Title = styled.div`
  margin-bottom: 32px;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(36, 45)}

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(30, 36)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const SubTitle = styled.div`
  font-weight: 700;
  font-size: ${pxToRem(20)};
  line-height: 26px;
  margin-bottom: 8px;
`;

export const SectionTitle = styled.div`
  font-weight: 700;
  ${pxToCssFont(18, 24)}
`;

export const Section = styled(SectionTitle)`
  font-weight: 400;
  margin-bottom: 16px;
`;
