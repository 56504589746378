import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 492px;
  gap: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 40px;
    height: 737px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 74px;
  }
`;

export const TextLoaderEmpty = styled.div`
  height: 30px;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  font-style: normal;
  text-align: center;
  ${pxToCssFont(35, 38)}
  color: #00697C;
`;

export const Subtitle = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  font-style: normal;
  text-align: center;
  ${pxToCssFont(24, 28)}
`;

