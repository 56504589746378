import { useCallback, useMemo } from 'react';
import {
  FieldError,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
  useForm,
  UseFormProps,
} from 'react-hook-form';

export const useFormExtended = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  extendedProps?: UseFormProps<TFieldValues, TContext> & {
    requiredFields?: Path<TFieldValues>[];
  }
) => {
  const { requiredFields, ...props } = extendedProps;
  const { defaultValues } = props;
  const hookReturn = useForm(props);

  const {
    setValue,
    formState: { errors },
    setError,
    clearErrors,
    trigger,
    register,
    watch,
  } = hookReturn;

  const allValues = watch();

  const registerSearchSelect = useCallback(
    (fieldName: Path<TFieldValues>, options?: RegisterOptions<TFieldValues,any>) => ({
      value: watch(fieldName),
      setValue: (v: PathValue<TFieldValues, Path<TFieldValues>>) => {
        setValue(fieldName, v);
      },
      errorMessage: errors?.[fieldName as string]?.message,
      setError: (error: FieldError) => {
        setError(fieldName, error);
      },
      clearErrors: () => {
        clearErrors(fieldName);
      },
      trigger: () => {
        trigger(fieldName);
      },
      ...register(fieldName, options),
    }),
    [clearErrors, errors, register, setError, setValue, trigger, watch]
  );

  const hasErrors = useMemo(
    () =>
      !(allValues && errors && Object.keys(errors).length === 0 && errors.constructor === Object),
    // allValues is necessary as dependency to trig update on every change
    [errors, allValues]
  );

  const isAllRequiredDirty = useMemo(() => {
    // Check if there is at least one field undefined or with the default value
    const checkFields = (fieldName: Path<TFieldValues>) => {
      if (!defaultValues) {
        // Inputs become all optionals and requiredFields are useless
        return false;
      }

      return (
        allValues[fieldName] === undefined ||
        allValues[fieldName] === defaultValues[fieldName as string]
      );
    };

    // Check only the required fields
    if (requiredFields) {
      return requiredFields?.length === 0 ? true : !requiredFields.find(checkFields);
    }

    // Check all fields
    return !Object.keys(allValues).find(checkFields);
  }, [allValues, defaultValues, requiredFields]);

  // use isAllRequiredValid instead isValid because
  // the isValid of react-hook-form does not watch custom errors:
  // infact setError has no effect on isValid formState,
  // isValid will always derived via the default form validation result.
  const isAllRequiredValid = useMemo(
    () => isAllRequiredDirty && !hasErrors,
    [hasErrors, isAllRequiredDirty]
  );

  return {
    hookReturn,
    registerSearchSelect,
    allValues,
    isAllRequiredValid,
    // isAllRequiredValid needs the setting of
    // defaultValues (mandatory) and requiredFields (optional)
  };
};

