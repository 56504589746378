import {
  INextError,
  LoggerInstance,
  nextClient,
  CustomOptions,
  handleUseQuery,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { API } from '@vfit/shared/data-access';
import { errorMock, getCurrentUserType, IUserType } from '@vfit/consumer/data-access';
import { UseQueryResult } from 'react-query';
import { IUserService } from './user.models';

/**
 * This method allows to retrieve the customerId from the logged user cookie and authentication token
 * API  authentication/v1/user
 * Method: GET
 * QueryString Params: salesChannel=selfService
 * @returns
 */
export const userService = (customOptions?: CustomOptions) =>
  nextClient.get(API.USER, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  }) as Promise<IUserService>;

/**
 * This method is used to instantiate a custom useQuery hook to handle the userService
 * @param customOptions
 * @returns
 */
export const useLoggedUser = (
  customOptions?: CustomOptions
): UseQueryResult<IUserService, INextError> => {
  const queryEnabler = getCurrentUserType() === IUserType.LOGGED_USER;
  const keysDependency: string | string[] =
    customOptions && customOptions.keyDependency ? customOptions.keyDependency : 'user';
  const options: UseQueryConfig = {
    queryKey: keysDependency,
    queryFn: () => userService(customOptions),
    options: {
      ...(customOptions && { ...customOptions }),
      enabled:
        customOptions?.enabled !== undefined ? queryEnabler && customOptions.enabled : queryEnabler,
      onSuccess: (data: IUserService) => {
        localStorage.setItem('user', JSON.stringify(data));
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - user: ', error);
        localStorage.setItem('user', JSON.stringify(errorMock('user')));
        customOptions?.onError?.(error);
      },
    },
  };

  return handleUseQuery(options) as UseQueryResult<IUserService, INextError>;
};
