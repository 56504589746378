import * as yup from 'yup';

// TODO: Re-evaluate validation when error designs are available

export const contactSchema = yup.object({
  phoneNumber: yup
    .string()
    .required('Campo obbligatorio')
    .min(9,'Inserire un numero valido')
    .max(10, 'Inserire un numero valido')
    .matches(/^(3)+[0-9]*$/, 'Inserire un numero valido'),
  emailAddress: yup
    .string()
    .required('Campo obbligatorio')
    .email('Inserire email nel formato corretto'),
});
